import React, { Component } from 'react';
class DATA1050View extends Component {

  constructor(props) {
    super(props);
    this.state = {
      json: 'loading your results...',
    }
  }

  componentDidMount() {
    this.getJSON();
    setTimeout(() => {
      if (this.state.json !== 'loading your results...') return;
      this.setState({ json: 'We don\'t seem to have your results. Reach out to Sam.'});
    }, 4000);
  }

  getJSON() {
    const { db, currentUser, projectId } = this.props;
    db.collection('users')
      .doc(currentUser.id)
      .collection('assignment-projects')
      .doc(projectId)
      .collection('results')
      .doc('results')
      .get()
      .then(doc => {
        const data = doc.data();
        if (data?.results) {
          this.setState({ json: JSON.parse(data.results) });
        }
      });
  }

  render() {
    return <pre>
      { JSON.stringify(this.state.json, null, 2) }
    </pre>
  }

}

export default DATA1050View;
