import React, { Component } from 'react';

class SeedMessageCard extends Component {

  render() {
    const { seedMessages=[], seedRenderFunction } = this.props;
    if (!seedMessages.length) return null;
    return (<div className="seed-message-container">
        { seedMessages.map( (message, messageIndex) => 
            seedRenderFunction(message, messageIndex)
          ) }
      </div>
    );
  }
}

export default SeedMessageCard;
