import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import handlebars from 'handlebars';
import 'react-quill/dist/quill.snow.css';
import SuperList from './SuperList';
import SuggestionSuperList from './SuggestionSuperList';
import QuillSuperList from './QuillSuperList';
import { now } from './utils';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';


function extractChamberIdFromUpdateString(updateString) {
  // so to start it'll be something like activeChamberId = "abc-234-cvjao"
  // so we'll split it and take the id
  let tmp = updateString.split(" ")[2];
  if (tmp[0] !== '"') return tmp;
  // okay, so now tmp is "abc-234-cvjao" and we just need to remove the quotes
  return tmp.substr(1, tmp.length - 2) || '';
}

function Rule(triggerCriteria=[], hocketRef=null) {
  return {
    hocketRef,
    triggerCriteria,
  }
}

handlebars.registerHelper('either', function(first, second, third) {
  return first || second || third;
});

handlebars.registerHelper('or', () => false);

class HocketForm extends Component {

  constructor(props) {
    super(props);
    this.hocketRef = null;
    this.courseRef = null;
    this.state = {
      hocket: null,
      tags: [],
      chambers: {},
      chamberExit: false,
      followupHocketIds: [],
      triggerCriteria: [],
      responses: [],
      suggestions: [],
      trainingPhrases: [],
      blackboardUpdates: [],
    };
  }
  
  componentDidMount() {
    this.setRefs(this.props);
  }

  //getDerivedStateFromProps(newProps) {
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setRefs(newProps);
  }

  setRefs(props) {
    const { db, projectId, hocketId } = props;
    if (!hocketId) return;
    //if (!currentUser) return;
    //if (!nav.match.params.id) return;
    //const hocketId = nav.match.params.id;
    this.courseRef = db.collection('projects').doc(projectId);
    this.hocketRef = this.courseRef.collection('hockets').doc(hocketId);
    this.courseRef.get()
      .then(snap => {
        const project = snap.data();
        if (!project) return console.log('no project with id ' + projectId);
        this.setState({ chambers: project.chambers || {} });
      }).catch(console.error);
    this.hocketRef
      .get()
      .then(snap => {
        const hocket = snap.data();
        if (!hocket) return;
        for (let i = 0; i < hocket.responses.length; i++) {
          if (/ops/.test(hocket.responses[i])) continue;
          if (!hocket.responses[i]) continue;
          hocket.responses[i] = JSON.stringify({ops: JSON.parse(hocket.responses[i])});
        }
        let blackboardUpdates;
        const { 
          responses = [], 
          suggestions = [],
          followupHocketIds = [],
          trainingPhrases = [],
          triggerCriteria = [],
        } = hocket;
        if (!Array.isArray(hocket.blackboardUpdates)) {
          blackboardUpdates = [];
        } else {
          blackboardUpdates = hocket.blackboardUpdates;
        }
        let blackboardUpdateActiveChamberId = '';
        for (let i = 0; i < blackboardUpdates.length; i++) {
          if (blackboardUpdates[i].startsWith('activeChamberId = ')) {
            // the problem is that this thing is going to actually be in quotes
            let updateString = blackboardUpdates.splice(i, 1)[0];
            let chamberId = extractChamberIdFromUpdateString(updateString);
            blackboardUpdateActiveChamberId = chamberId || '';
            break
          }
        }
        let triggerCriteriaActiveChamberId = '';
        for (let i = 0; i < triggerCriteria.length; i++) {
          if (triggerCriteria[i].startsWith('activeChamberId == ')) {
            let updateString = triggerCriteria.splice(i, 1)[0];
            let chamberId = extractChamberIdFromUpdateString(updateString);
            triggerCriteriaActiveChamberId = chamberId || '';
            break;
          }
        }
        const chamberExit = blackboardUpdateActiveChamberId === '"null"';
        this.setState({
          hocket,
          blackboardUpdates,
          blackboardUpdateActiveChamberId,
          chamberExit,
          responses,
          followupHocketIds,
          suggestions,
          trainingPhrases,
          triggerCriteria,
          triggerCriteriaActiveChamberId,
        });
      });
    this.getTags();
  }

  getTags() {
    this.props.db
      .collection('projects')
      .doc(this.props.projectId)
      .collection('meta')
      .doc('tags')
      .get()
      .then(snap => {
        let tags = snap.data();
        if (!tags) {
          tags = [];
        } else {
          tags = Object.keys(tags).map(tagId => tags[tagId]);
        }
        this.setState({ tags });
      }).catch( console.error );
  }

  updateCriteria(triggerCriteria) {
    // parser's going to need to go here
    this.setState({ triggerCriteria });
  }

  updateTrainingPhrases(trainingPhrases) {
    this.setState({ trainingPhrases });
  }

  updateResponses(responses) {
    // going to need to update this to return quill deltas 
    this.setState({ responses });
  }

  updateSuggestions(suggestions) {
    this.setState({ suggestions });
  }

  updateFollowupHocketIds(followupHocketIds) {
    // parser's going to need to go here
    this.setState({ followupHocketIds });
  }

  updateUpdates(blackboardUpdates) {
    // parser's going to need to go here
    this.setState({ blackboardUpdates });
  }

  toggleChamberExit() {
    this.setState({
      blackboardUpdateActiveChamberId: null,
      chamberExit: !this.state.chamberExit
    });
  }

  renderSwitch() {
    const { chamberExit } = this.state;
    const label = chamberExit ? 'Exit Chamber' : 'Exit Chamber';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ !!chamberExit }
              onChange={ () => this.toggleChamberExit() }
              />
            }
          label={ label }
        />
      </FormGroup>
    );
  }


  trainNlu() {
    const { db, projectId } = this.props;
    const { id } = this.state.hocket;
    if (!id) return;
    const lastTrained = now();
    db.collection('triggers')
      .doc('train-hocket')
      .set(
        { lastTrained, id, projectId },
        {merge: true}
      ).then(() => {
        this.hocketRef.set({ lastTrained }, { merge: true });
      }).catch(console.error);
  }

  saveHocket() {
    const { 
      blackboardUpdates = [],
      hocket,
      responses = [], 
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
      blackboardUpdateActiveChamberId = '',
      triggerCriteriaActiveChamberId = '',
    } = this.state;
    hocket.blackboardUpdates = blackboardUpdates;
    if (blackboardUpdateActiveChamberId) {
      hocket.blackboardUpdates.push('activeChamberId = "' + blackboardUpdateActiveChamberId + '"');
    }
    hocket.responses = responses;
    hocket.suggestions = suggestions;
    hocket.followupHocketIds = followupHocketIds;
    hocket.trainingPhrases = trainingPhrases;
    hocket.triggerCriteria = triggerCriteria;
    if (triggerCriteriaActiveChamberId) {
      hocket.triggerCriteria.push('activeChamberId == "' + triggerCriteriaActiveChamberId + '"');
    }
    hocket.timestamp = now();
    const rule = Rule(hocket.triggerCriteria, this.hocketRef);
    this.hocketRef.set(hocket, {merge: true});
    // add toast eventually
    if (triggerCriteria.length) {
      this.props.db
        .collection('projects')
        .doc(this.props.projectId)
        .collection('meta')
        .doc('rules')
        .set(
          {[hocket.id]: rule},
          {merge: true}
        );
    }
  }

  renderTriggerCriteriaChamberSelect() {
    let { chambers, triggerCriteriaActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={triggerCriteriaActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ triggerCriteriaActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }
  

  renderBlackboardUpdateChamberSelect() {
    let { chambers, blackboardUpdateActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={blackboardUpdateActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ blackboardUpdateActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }
  
  handleChange(event) {
    const { value } = event.target;
    const { hocket } = this.state;
    hocket.tags = value;
    this.setState({ hocket });
  };

  renderTags() {
    let { tags, hocket } = this.state;
    if (!tags || !hocket) return null;
    if (!hocket.tags) hocket.tags = [];
    const tagMap = tags.reduce((acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    }, {});
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-mutiple-chip-label">Tags</InputLabel>
        <Select
          id="demo-mutiple-chip"
          multiple
          value={hocket.tags}
          onChange={(e) => this.handleChange(e)}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => {
            if (!selected || !selected.map) return;
            return (
              <div>
                {selected.map(id => {
                  if (!tagMap[id]) return null;
                  return (
                    <Chip key={id} label={tagMap[id].displayName}/>
                  );
                }
                )}
              </div>
            );
            }
          }
        >
          {tags.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  

  render() {
    if (!this.state.hocket) return null;
    const { projectId } = this.props;
    return (
      <div className="">
        <div className="">
          <div className="">
            <p className="subtext">
              <Link to={"/projects/" + projectId + '/hockets/' + this.state.hocket.id }>
                { this.state.hocket.id }
              </Link>
            </p>
            <h2>Active Chamber</h2>
            { this.renderTriggerCriteriaChamberSelect() }
            <h2>Trigger Criteria</h2>
            <SuperList
              label="Trigger Criterion"
              values={ this.state.triggerCriteria }
              updateValues={ (triggerCriteria) => this.updateCriteria(triggerCriteria) }/>
            <h2>Trigger Phrases</h2>
            <SuperList
              label="Trigger Phrase"
              values={ this.state.trainingPhrases }
              updateValues={ (trainingPhrases) => this.updateTrainingPhrases(trainingPhrases) }/>
          </div>
          <div className="">
            <h2>Responses</h2>
            <QuillSuperList
              label="Response"
              values={ this.state.responses }
              updateValues={ (responses) => this.updateResponses(responses) }/>
            <h2>Suggestions</h2>
            <SuggestionSuperList
              label="Suggestion"
              limit={ 15 }
              projectId={ projectId }
              db={ this.props.db }
              values={ this.state.suggestions }
              updateValues={ (suggestions) => this.updateSuggestions(suggestions) }/>

          </div>
          <div className="">
            { this.renderSwitch() }
            { this.renderTags() }
            { this.state.chamberExit ? null : (
              <div>
                <h2>Update Active Chamber</h2>
                { this.renderBlackboardUpdateChamberSelect() }
              </div>
            ) }
            <h2>Blackboard Updates</h2>
            <SuperList
              label="Update"
              values={ this.state.blackboardUpdates }
              updateValues={ (blackboardUpdates) => this.updateUpdates(blackboardUpdates) }/>
            <h2>Obligate Followup</h2>
            <SuggestionSuperList
              label="Training Phrase"
              limit={ 1 }
              db={ this.props.db }
              projectId={ projectId }
              values={ this.state.followupHocketIds }
              updateValues={ (update) => this.updateFollowupHocketIds(update) }/>

          </div>
        </div>
        <div className="">
          { this.state.trainingPhrases.length ? 
            <Button
              variant="contained"
              color="primary"
              onClick={ () => this.trainNlu() } >Train NLU</Button>
            : null
          }
          <Button
            variant="contained"
            color="secondary"
            onClick={ () => this.saveHocket() } >Save</Button>
        </div>
      </div>
    );
  }

}

export default HocketForm;
