import uuid from 'uuid/v4';
import toPlaintext from 'quill-delta-to-plaintext';
import {
  now,
  removeTerminalNewlinesFromQuillDelta
} from './utils';
const ELIJAH_ID = '000-0000-000';

const pick = (arr) => arr[Math.random() * arr.length | 0];

export function text2message(textContent) {
  return {
    author: '000-0000-000',
    suggestions: [],
    id: uuid(),
    instructorVerified: false,
    parentQuestionId: null,
    parentChamberId: null,
    parentHocketRef: null,
    sharedFromClass: false,
    sharedWithClass: false,
    answered: false,
    followupId: null,
    score: null,
    quillDelta: JSON.stringify({ops: [{insert: textContent}]}),
    responseInProgress: false,
    respondedTo: false,
    textContent: textContent,
    timestamp: now(),
  }
}

export function Message(quillDelta, textContent, userId, timestamp) {
  return {
    author: userId,
    suggestions: [],
    id: uuid(),
    instructorVerified: false,
    parentQuestionId: null,
    parentChamberId: null,
    parentHocketRef: null,
    sharedFromClass: false,
    sharedWithClass: false,
    answered: false,
    followupId: null,
    score: null,
    quillDelta: JSON.stringify(quillDelta),
    responseInProgress: false,
    respondedTo: false,
    textContent: textContent,
    timestamp: timestamp || now(),
  }
}

export function hocket2Message(hocket, stripNewLines=false) {
  // this misses 3 really important things
  // 1. Handlebars string interpolation
  // 2. Blackboard updates from the hocket
  // 3. the parentHocketRef
  if (!hocket) return null;
  if (!hocket.responses.length) return null;
  let response = pick(hocket.responses);
  if (typeof response !== 'string') {
    return console.error('hocket response should be string', hocket);
  }
  let delta = JSON.parse(response);
  if (stripNewLines) {
    delta = removeTerminalNewlinesFromQuillDelta(delta);
  }
  let text = toPlaintext(delta.ops);
  let authorId = ELIJAH_ID;
  let message = Message(delta, text, authorId);
  message.suggestions = hocket.suggestions || [];
  if (hocket.openResponse) message.openResponse = hocket.openResponse;
  if (hocket.codeCell) message.codeCell = hocket.codeCell;
  if (hocket.jessieCode) message.jessieCode = hocket.jessieCode;
  if (hocket.tableData) message.tableData = hocket.tableData;
  if (hocket.aspectRatio) message.aspectRatio = hocket.aspectRatio;
  if (hocket.fabric) message.fabric = hocket.fabric;
  if (hocket.urlIFrame) message.urlIFrame = hocket.urlIFrame;
  if (hocket.heightIFrame) message.heightIFrame = hocket.heightIFrame;
  if (hocket.sentFromClusterBlock) message.sentFromClusterBlock = hocket.sentFromClusterBlock;
  if (hocket.sentFromMessageEntireClass) message.sentFromMessageEntireClass = hocket.sentFromMessageEntireClass;
  if (hocket.sentFromInstructorChatArea) message.sentFromInstructorChatArea = hocket.sentFromInstructorChatArea;
  if (hocket.followupHocketIds && hocket.followupHocketIds.length) {
    message.followupHocketId = hocket.followupHocketIds[0].relatedHocketId;
  } else {
    message.followupHocketId = null;
  }
  return message;
}
