import React, { Component } from 'react';
import uuid from 'uuid/v4';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import Chat from '@material-ui/icons/Chat';
import MessageForm from './MessageForm';
import FlagIcon from '@material-ui/icons/Flag';
import DeleteIcon from '@material-ui/icons/Delete';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CreateIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import LessonDrawer from './LessonDrawer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import HocketCard from '../HocketCard';
import SimpleAdminNav from '../SimpleAdminNav';
import ReadOnlyQuill from '../ReadOnlyQuill';
import InstructorChatArea from './InstructorChatArea';
import ClusterBlock from './ClusterBlock';
import Fabric from '../Fabric';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Timer from '../Timer';
import { safePhotoUrl } from '../profile-emojis';
import { hocket2Message, Message } from '../message';
import toPlaintext from 'quill-delta-to-plaintext';
import * as diff from "fast-array-diff";
import {
  now,
  last,
  updateTitleBar,
  isAdmin,
  mousetrapStopCallback,
  chunk,
  findLastIndex,
  hashString,
  svgUploader,
  splitGroups,
} from '../utils';
import {
  addClusterBlock,
  clearClusterBlock,
  messageCluster,
  messageClass,
  messageStudent,
  openLessonsDrawer,
  selectLessonFromDrawer,
  responseInProgress,
  instructorHelpScreen,
  peerResponseQuestion,
} from '../analytics';
import './style.css';
import * as Mousetrap from 'mousetrap';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#fff3d0',
      dark: '#ccc09f',
      contrastText: '#333',
    },
  },
});

const MessageCard = ({ message, chatCb }) => {
  let timestamp;
  try {
    timestamp = message.timestamp.toDate();
  } catch (err) {
    // console.log('old timestamp format');
    timestamp = message.timestamp;
  }
  const chatButton = (
      <IconButton
        className="chat-icon"
        onClick={ chatCb }>
        <Chat />
      </IconButton>
  );
  let classList = [];
  classList.push('message-card');
  if (message.sharedFromClass) {
    classList.push('shared-from-class');
  } else if (message.answered || message.respondedTo) {
    classList.push("answered");
  }
  if (!(message.answered || message.respondedTo) && message.textContent.includes('?')) classList.push('student-question');
  if (message.author === '000-0000-000') classList.push('instructor-answer');
  classList = classList.join(' ');
  return (
    <Tooltip
      title={ message.authorDisplayName }
      placement="top"
      enterDelay={ 500 }>
      <div
        className={ classList }>
        { message.author === '000-0000-000' ? null : chatButton }
        <p className="message-time-ago">{ moment(timestamp).fromNow() }</p>
        <p>{ message.textContent }</p>
      </div>
    </Tooltip>
  );
};

const ParticipantListEntry = ({ student, chatCb, typingDotsCb, numStars, numMessages, studentsRespondedLatestClusterBlock, startTime }) => {
  const chatButton = <img className="instructor-bubble img-chat-icon" alt="profile" src={ safePhotoUrl(student.photoUrl) } onClick={ chatCb }/>
  const timeAgo = student.lastActive ? moment(student.lastActive).fromNow() : "";
  return (
    <div className="participant-card">
      { chatButton }
      <Tooltip
        title={ numMessages + " messages sent" }
        enterDelay={ 600 }>
        <p className="total-message-count">
          { numMessages }
        </p>
      </Tooltip>
      <Tooltip
        title={ numStars + " starred messages" }
        enterDelay={ 600 }>
        <p className="star-count">
          { numStars }
        </p>
      </Tooltip>
      <div className="three-dots"
           onClick={ typingDotsCb }>
        { (student.typing && startTime && startTime < student.lastActive) ? "..." :
           (studentsRespondedLatestClusterBlock.has(student.id)
              ? <CheckIcon/> : null ) }
      </div>
      <Tooltip
        title={"Last messaged or loaded the chat page " + timeAgo}
        placement="top"
        enterDelay={ 1200 }>
        <div>
          <p className="participant-display-name" onClick={ chatCb }>
            { student.displayName || "No Name" }
          </p>
          <p className="participant-message-time-ago" onClick={ chatCb }>
            { timeAgo }
          </p>
        </div>
      </Tooltip>
    </div>
  );
}

class LiveClassroom extends Component {

  constructor(props) {
    super(props);
    this.db = null;
    this.selectedLessonMessageRef = React.createRef();
    this.writingContainerRef = React.createRef();
    this.clusterKey = 0;
    this.timeOffset = 0;
    this.messageClassRef = React.createRef();
    this.mainQuillRef = React.createRef();
    this.latestClusterBlockRef = React.createRef();
    this.sendHocketAsMessageToClass = this.sendHocketAsMessageToClass.bind(this);
    this.clearClusterBlock = this.clearClusterBlock.bind(this);
    this.setActiveStudentChatId = this.setActiveStudentChatId.bind(this);
    this.setStudentChatMessage = this.setStudentChatMessage.bind(this);
    this.setMessagesResponseInProgress = this.setMessagesResponseInProgress.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.shareMessageWithClass = this.shareMessageWithClass.bind(this);
    this.setStudentsRespondedLatestClusterBlock = this.setStudentsRespondedLatestClusterBlock.bind(this);
    this.updateDefaultClusterCount = this.updateDefaultClusterCount.bind(this);
    this.selectLesson = this.selectLesson.bind(this);
    this.setActiveHocketId = this.setActiveHocketId.bind(this);
    this.openLiveStudentTyping = this.openLiveStudentTyping.bind(this);
    this.setTyping = this.setTyping.bind(this);
    this.saveSketch = this.saveSketch.bind(this);
    this.setMessageLimit = this.setMessageLimit.bind(this);
    this.liveWritingId = null;
    this.currentSvgGroups = null;
    this.sessionId = uuid();
    this.unsub = {
      messages: null,
      hockets: null,
      participants: null,
      settings: null,
    };
    this.hydrated = false;
    this.state = {
      activeHocketId: null,
      activeStudentChatId: null,
      studentChatMessage: null,
      clusterBlocks: [],
      clusterBlockLimit: 3,
      drawerOpen: false,
      defaultClusterCount: 5,
      selectedHocketId: '',
      hocketFilter: '',
      messageFilter: '',
      messages: [],
      hockets: {},
      hocket: null,
      lastLessonMessageIndex: 0,
      tas: {},
      instructors: {},
      observers: {},
      selectedLessonId: '',
      participants: [],
      showParticipants: true,
      messageCounts: {},
      showHelp: false,
      studentsRespondedLatestClusterBlock: new Set(),
      hideMessagesHandledByOtherInstructors: false,
      fadeMessagesHandledByOtherInstructors: true,
      hideObservers: true,
      surfaceFlaggedMessages: true,
      autoSortMessages: true,
      hideInbox: false,
      drawerMode: true,
      clearLocally: true,
      manualClusterCreation: false,
      timeLimit: -1,
      timerStartDate: new Date(),
      currentDate: new Date(),
      cachedClusterBlock: null,
      lastMessageId: null,
      showTimeSelector: false,
      clusterBlockTime: null,
      useObservers: false,
      showLiveStudentTyping: false,
      showLiveWriting: false,
      whiteboardOnly: false,
      messageLimit: 20,
    };
  }

  componentDidMount() {
    this.getTimeOffset();
    this.subClassMessages();
    this.subStudentUserIds();
    this.getRoles();
    this.subProject();
    this.subLessons(this.props);
    this.subParticipants();
    this.subMessageCounts();
    this.subSettings();
    this.clearWhiteboard();
    this.setMousetrap();
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
  }

  setMousetrap() {
    Mousetrap.bind('shift+up', () => this.handleShiftUpPress());
    Mousetrap.bind('shift+down', () => this.handleShiftDownPress());
    Mousetrap.bind('shift+l', () =>
    this.toggleDrawer(!this.state.drawerOpen));
    Mousetrap.bind('shift+m', () => {
      setTimeout( () => {
        this.mainQuillRef.current.focus();
        const editor = this.mainQuillRef.current.editor;
        editor.setSelection(editor.getLength(), 0);
      }, 40);
    });
    Mousetrap.bind('enter', () => {
      this.messageClassRef.current.sendHocketMessage();
    });
    Mousetrap.bind('shift+r', () => {
      if (this.latestClusterBlockRef.current) {
        this.latestClusterBlockRef.current.clusterMessages();
      }
    });
    Mousetrap.bind('shift+s', () => {
      if (this.latestClusterBlockRef.current) {
        this.latestClusterBlockRef.current.summarize();
      }
    });
    Mousetrap.bind('shift+a', () => {
      if (this.latestClusterBlockRef.current) {
        this.latestClusterBlockRef.current.addMessagesToExistingClusters();
      }
    });
    Mousetrap.bind('shift+w', () => {
      this.setState({ showLiveWriting: !this.state.showLiveWriting });
    })
    Mousetrap.bind('shift+t', () => {
      const { showParticipants } = this.state;
      this.setState({ showParticipants: !showParticipants })
    });
    Mousetrap.bind('shift+u', () => {
      this.askMessageStats();
    });
    Mousetrap.bind('shift+d', () => {
      this.deleteLastMessage();
    });
    Mousetrap.bind('esc', () => {
      this.exitTempScreens();
      this.toggleDrawer(false);
      this.closeInstructorChatArea();
    });
    Mousetrap.bind('shift+p', () => this.toggleLiveStudentTyping());
    Mousetrap.bind(['mod+/', 'shift+/'], () => this.toggleHelp());
    Mousetrap.bind('shift+c', () => {
      const { hideCallOnMe } = this.state
      this.setState({ hideCallOnMe: !hideCallOnMe });
    });
    Mousetrap.bind('shift+o', () => {
      const { hideObservers } = this.state
      this.setState({ hideObservers: !hideObservers });
    });
    Mousetrap.bind('shift+f', () => {
      const { fadeMessagesHandledByOtherInstructors } = this.state
      this.setState({fadeMessagesHandledByOtherInstructors: !fadeMessagesHandledByOtherInstructors});
    });
    Mousetrap.bind('shift+h', () => {
      const { hideMessagesHandledByOtherInstructors } = this.state
      this.setState({hideMessagesHandledByOtherInstructors: !hideMessagesHandledByOtherInstructors});
    });
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  toggleHelp() {
    const { currentUser } = this.props;
    const { showHelp } = this.state;
    instructorHelpScreen(currentUser.id); // analytics
    this.setState({ showHelp: !showHelp});
  }

  toggleLiveStudentTyping() {
    this.setState({ showLiveStudentTyping: !this.state.showLiveStudentTyping });
  }

  setStudentsRespondedLatestClusterBlock(students) {
    this.setState({
      studentsRespondedLatestClusterBlock: students,
    });
  }

  handleClusterBlockTimeChange(date) {
    this.setState({ clusterBlockTime: date });
  }

  helpInfo() {
    return (<div className="help-info">
      <h1>Keyboard shortcuts</h1>

      <em>When a text box is active</em>
      <ul className="documentation-list">
      <li><tt>escape</tt> de-activate current text box </li>
      <li><tt>enter</tt> send message </li>
      <li><tt>shift+enter</tt> newline</li>
      <li><tt>shift+m</tt> activate the "message the class" box</li>
      </ul>

      <em>When no text box is active</em>
      <ul className="documentation-list">
        <li><tt>shift+r</tt> re-cluster messages in latest block</li>
        <li><tt>shift+a</tt> add inboxed messages to current clusters</li>
        <li><tt>shift+l</tt> toggle the lessons drawer</li>
        <li><tt>shift+up</tt> move up one lesson message</li>
        <li><tt>shift+down</tt> move down one lesson message </li>
        <li><tt>shift+s</tt> place summary of latest cluster block in "message the class" box</li>
        <li><tt>shift+u</tt> message personal stats to each student</li>
        <li><tt>shift+c</tt> clear "message the class" box</li>
        <li><tt>shift+t</tt> toggle between participants list and settings</li>
        <li><tt>shift+f</tt> toggle hidding messages marked "don't call on me"</li>
        <li><tt>shift+f</tt> toggle fading clustered messages which other instructors/TAs are replying to</li>
        <li><tt>shift+h</tt> toggle hiding clustered messages which other instructors/TAs are replying to</li>
        <li><tt>shift+d</tt> delete the last message sent or shared with the class </li>
        <li><tt>shift+?</tt> show this keyboard shortcut help page</li>
      </ul>

      <em>Markdown shortcuts</em>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>

      <em>Emoji features</em>
      <ul className="documentation-list">
        <li>Sending a message including the text <span role="img" aria-label="silhouette">👥</span> sends students' messages to each other anonymously and allows each student to respond directly to the person whose answer they see. Use <tt>\peer</tt> followed by tab to get the silhouette character.</li>
        <li>Sending a message including the text <span role="img" aria-label="clock">🕔90s</span> sets a 90-second timer (visible to you and to students). Use <tt>\clock</tt> or <tt>\timer</tt> followed by tab to get the clock character.</li>
        <li>Sending a message including the text <span role="img" aria-label="pencil">✏</span> opens each student's drawing tool and sets the image contained in the message as the background image. Use <tt>\draw</tt> followed by tab to get the pencil character.</li>
        <li>Sending a message including the text <span role="img" aria-label="registered">®</span> marks the question as required, meaning that it will be tracked as an open response question on the <em>Metrics</em> page. Use <tt>\RR</tt> or <tt>\required</tt> followed by tab to get the registered symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="pushpin">📌</span> will pin the message to the top of each student's window. Use <tt>\pin</tt> followed by tab to get the pushpin character.</li>
        <li>Sending a message including the text <span role="img" aria-label="recycle">♻</span> will clear every student's pinned messages. Use <tt>\clear</tt> followed by tab to get the recycling symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="otimes">⊗</span> will remove one pinned message. The character can be included more than once to remove multiple pinned messages. Use <tt>\unpin</tt> followed by tab to get the <span role="img" aria-label="otimes">⊗</span> symbol.</li>
      </ul>
    </div>);
  }

  getTimeOffset() {
    const { db, projectId } = this.props;
    const ref = db.collection('projects')
      .doc(projectId)
      .collection('meta')
      .doc('server-time');
    ref.set({ timestamp: now() })
      .then(() => {
        return ref.get().then(snap => {
          const data = snap.data() || {};
          if (!data) return null;
          if (data.timestamp && data.timestamp.toDate) {
            const serverNow = data.timestamp.toDate();
            const localNow = new Date();
            // usually, the local computer will be a smidge ahead of the server
            // so timeOffset will be positive, like maybe 3000 or something
            this.timeOffset = localNow - serverNow;
          }
        }).catch(console.error);
      }).catch(console.error);
  }

  subLessons(props) {
    if (this.unsub.lessons) this.unsub.lessons();
    const { db, projectId } = props;
    this.unsub.lessons = db
      .collection('projects')
      .doc(projectId)
      .collection('lessons')
      .orderBy('timestamp', 'desc')
      .onSnapshot(snap => {
        if (!snap.docs) return;
        if (this.state.whiteboardOnly) return;
        const lessons = snap.docs.map(doc => doc.data());;
        this.setState({ lessons });
      });
  }

  subLesson(lessonId) {
    if (this.unsub.lesson) this.unsub.lesson();
    const { db, projectId } = this.props;
    if (!projectId || !lessonId) return;
    this.lessonRef =  db
      .collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId);
    this.unsub.lesson = this.lessonRef
      .onSnapshot(snap => {
        if (this.state.whiteboardOnly) return;
        const lesson = snap.data();
        if (!lesson) return;
        const { hockets=[] } = lesson;
        for (let i = 0; i < hockets.length; i++) {
          if (this.unsub[hockets[i].hocketId]) continue;
          this.subHocket(hockets[i].hocketId);
        }
        this.setState({ lesson });
      });
  }

  subMessageCounts() {
    const { db, projectId } = this.props;
    const { timeZoneOffset } = this.state;
    const dayId = moment()
                   .add(timeZoneOffset, 'hours')
                   .toISOString()
                   .slice(0, 10);
    db.collection('projects')
      .doc(projectId)
      .collection('metrics-daily-activity')
      .doc(dayId)
      .onSnapshot( snap => {
        const doc = snap.data();
        if (this.state.whiteboardOnly) return;
        if (doc && doc.counts) {
          this.setState({
            messageCounts: doc.counts,
            openResponseCount: doc.clusters ? Object.keys(doc.clusters).length : 0,
            multipleChoiceCount: doc.numberOfScoredQuestions || 0,
          });
        }
      });
  }

  getCounts(userId, field) {
    const { messageCounts } = this.state;
    if (!messageCounts[userId]) return 0;
    return messageCounts[userId][field] || 0;
  }

  subParticipants() {
    const db = this.db;
    const { projectId } = this.props;
    if (this.unsub.participants) this.unsub.participants();
    const twelveHoursBack = (moment()
          .subtract(12, 'hours')).toDate().toISOString();
    let participantsRef = db
        .collection('projects')
        .doc(projectId)
        .collection('realtime-activity')
        .where('lastActive', '>', twelveHoursBack);
    this.unsub.participants = participantsRef
      .onSnapshot( snap => {
        const { participants } = this.state;
        snap.forEach(doc => {
          const data = doc.data();
          if (!data) return null;
          let svgGroups = null, lastPatch = null;
          if (data.svgPatch) {
              try {
                if (participants?.[doc.id]?.lastPatch !== data.svgPatch) {
                  svgGroups = diff.applyPatch(participants?.[doc.id]?.svgGroups || [], data.svgPatch);
                  lastPatch = data.svgPatch;
                }
              } catch (err) {
                console.log(err);
              }
          }
          participants[doc.id] = {
            id: doc.id,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            lastActive: data.lastActive,
            lastAnswered: data.lastAnswered,
            typing: data.typing,
            currentQuillDelta: data.currentQuillDelta,
            svgGroups,
            lastPatch,
          };
        });
        this.setState({ participants });
      });
  }

  subHocket(hocketId) {
    const { db, projectId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets={} } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
      });
  }

  subProject() {
    const { db, projectId } = this.props;
    this.unsub.project = db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const project = snap.data();
        if (!project) return null;
        const { useObservers=false } = project;
        this.setState({ useObservers });
        const timeZoneOffset = project.timeZoneOffset || -5;
        this.setState({ timeZoneOffset });
        const oneDayBack = (moment().subtract(1, 'days')).toDate().toISOString();
        //const twoDaysBack = (moment().subtract(2, 'days')).toDate().toISOString();
        // limit to last 24 hours here
        if (!project.clusterBlocks || !project.clusterBlocks.length) {
          const block = {
            title: 'Messages from up to 24 hours ago.',
            startTime: oneDayBack,
          };
          db.collection('projects')
            .doc(projectId)
            .set({ clusterBlocks: [ block ] }, {merge:true});
          return this.setState({ clusterBlocks: [block] });
        }
        if (!project.clusterBlocks) return;
        this.setState({ 
          clusterBlocks: project.clusterBlocks,
        });
      });
  }

  getRoles() {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('instructors')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ instructors: data.userIds });
      }).catch(console.error);
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('tas')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ tas: data.userIds });
      }).catch(console.error);
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('observers')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ observers: data.userIds });
      }).catch(console.error);
  }


  subStudentUserIds() {
    const db = this.db = this.props.db;
    const { projectId } = this.props;
    this.unsub.studentUserIds = db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('students')
      .onSnapshot(snap => {
        const data = snap.data();
        if (!data) return null;
        if (!data.userIds) return null;
        const studentUserIds = [];
        for (let key in data.userIds) {
          studentUserIds.push(key);
        }
        this.setState({ studentUserIds });
      });
  }

  setMessageLimit(messageLimit) {
    if (messageLimit <= this.state.messageLimit) return;
    this.setState({ messageLimit });
  }

  subClassMessages() {
    const db = this.db = this.props.db;
    const { projectId } = this.props;
    if (this.unsub.classMessages) this.unsub.classMessages();
    this.unsub.classMessages = db.collection('projects')
      .doc(projectId)
      .collection('class-messages')
      .orderBy('timestamp', 'desc')
      .limit(this.state.messageLimit)
      .onSnapshot(snap => {
        if (this.state.whiteboardOnly) return;
        const classMessages = [];
        snap.forEach(doc => {
          classMessages.push(doc.data());
        });
        this.setState({ 
          classMessages, 
        });
        if (classMessages?.[0]?.id) {
          this.setState({ 
            lastMessageId: classMessages[0].id, 
            lastMessageIsWhiteboard: !!classMessages[0].svgPatch,
          });
          if (classMessages[0].id !== this.liveWritingId) {
            this.liveWritingId = null;
          }
        }
      });
  }


  componentWillUnmount() {
    for (let key in this.unsub) {
      if (typeof this.unsub[key] === 'function') this.unsub[key]();
    }
    Mousetrap.unbind('shift+up');
    Mousetrap.unbind('shift+down');
    Mousetrap.unbind('shift+l');
    Mousetrap.unbind('shift+m');
    Mousetrap.unbind('shift+r');
    Mousetrap.unbind('shift+s');
    Mousetrap.unbind('shift+d');
    Mousetrap.unbind('shift+a');
    Mousetrap.unbind('shift+u');
    Mousetrap.unbind('enter');
    Mousetrap.unbind('shift+h');
    Mousetrap.unbind('shift+/');
    Mousetrap.unbind('mod+/');
    Mousetrap.unbind('shift+p');
    Mousetrap.unbind('shift+t');
    Mousetrap.unbind('shift+f');
    Mousetrap.unbind('shift+c');
    Mousetrap.unbind('shift+o');
    Mousetrap.unbind('shift+w');
    Mousetrap.unbind('esc');
  }

  setMessagesResponseInProgress(messages, inProgress) {
    const { db, projectId } = this.props;
    if (messages.every(message => message.respondedTo)) return Promise.resolve();
    if (messages.every(message => message.responseInProgress === responseInProgress)) return Promise.resolve();
    const batch = db.batch();
    for (let message of messages) {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('messages')
          .doc(message.id),
        { responseInProgress: inProgress },
        { merge: true },
      );
    }
    return batch.commit();
  }

  setActiveStudentChatId(activeStudentChatId) {
    this.setState({
      activeStudentChatId: null,
    }, () => {
      this.setState({activeStudentChatId});
    });
  }

  setStudentChatMessage(message) {
    this.setState({
      studentChatMessage: message
    });
  }

  incrementClusterBlockLimit() {
    let { clusterBlockLimit=3 } = this.state;
    clusterBlockLimit += 4;
    this.setState({ clusterBlockLimit });
  }

  turnOffTypingDots(id) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('realtime-activity')
      .doc(id)
      .set({ typing: false }, { merge: true });
  }

  closeInstructorChatArea() {
    const { studentChatMessage } = this.state;
    this.setState({
      activeStudentChatId: null,
    }, () => {
      if (studentChatMessage) {
        const ripRef = this.setMessagesResponseInProgress([studentChatMessage], false);
        if (ripRef) {
          ripRef
            .then( () => {
              this.setState({ studentChatMessage: null });
            }).catch(console.error);
        }
      }
    });
  }

  askMessageStats() {
    const answer = window.confirm("Message each student their personal statistics for today?");
    if (answer) {
      if (this.messageStats()) {
        window.alert("Messages sent!");
      }
    }
  }

  messageStats() {
    const { currentUser } = this.props;
    const {
      messageCounts,
      multipleChoiceCount,
      openResponseCount,
    } = this.state;
    const len = x => Object.keys(x).length;
    if (!messageCounts || (!messageCounts && !openResponseCount)) return;
    const userIdMap = new Map();
    for (let id in messageCounts) {
      const record = messageCounts[id];
      const delta = {
        ops: [
          {insert: "Personal stats summary: ", attributes: {bold: true}},
          {insert: `You have sent ${record.count} messages. `}
        ]
      };
      const pl = n => n === 1 ? "" : "s";
      if (multipleChoiceCount > 0) {
        delta.ops.push({insert: `You've answered ${record.scoredResponses}/${multipleChoiceCount} multiple choice question${pl(multipleChoiceCount)}`});
        if (openResponseCount === 0) {
          delta.ops.push({insert: "."});
        } else {
          delta.ops.push({insert: `, and you've answered ${len(record.clusters)}/${openResponseCount} open response question${pl(len(record.clusters))}`});
        }
      } else {
        if (openResponseCount > 0) {
          delta.ops.push({insert: `you've answered ${len(record.clusters)}/${openResponseCount} open response question${pl(len(record.clusters))}`});
        } else {
          window.alert("No stats to send!")
          return;
        }
      }
      const message = new Message(
        delta, toPlaintext(delta.ops), '000-0000-000'
      )
      message.authorDisplayName = currentUser.displayName || '';
      message.authorPhotoUrl = currentUser.photoUrl || '';
      message.answered = true;
      userIdMap.set(id, message);
    }
    this.sendMessagesToUsers(userIdMap);
    return true;
  }

  subSettings() {
    const { db, projectId, currentUser } = this.props;
    const { selectedLessonId } = this.state;
    const settingNames = [
      "hideMessagesHandledByOtherInstructors",
      "fadeMessagesHandledByOtherInstructors",
      "hideObservers",
      "surfaceFlaggedMessages",
      "selectedLessonId",
      "activeHocketId",
      "lastLessonMessageIndex",
      "autoSortMessages",
      "hideInbox",
      "drawerMode",
      "clearLocally",
    ]
    if (this.unsub.settings) this.unsub.settings();
    this.unsub.sendTrigger = db.collection('projects')
      .doc(projectId)
      .collection('settings')
      .doc(currentUser.id)
      .collection('triggers')
      .doc('triggerClassroomSend')
      .onSnapshot( snap => {
        if (this.state.whiteboardOnly) return;
        const doc = snap.data();
        if (!doc) return;
        if (doc.triggerClassroomSend) {
          if (!this.state.triggerLockout) {
            setTimeout( () => 
              this.messageClassRef.current.sendHocketMessage(),
            100);
            this.setState({ triggerLockout: true });
            setTimeout(() => {
              this.setState({ triggerLockout: false });
            }, 500)
          }
          db.collection('projects')
            .doc(projectId)
            .collection('settings')
            .doc(currentUser.id)
            .collection('triggers')
            .doc('triggerClassroomSend')
            .delete()
            .catch(console.error);
        }
      });
    this.unsub.settings = db.collection('projects')
      .doc(projectId)
      .collection('settings')
      .doc(currentUser.id)
      .onSnapshot( snap => {
        if (this.state.whiteboardOnly) return;
        const doc = snap.data();
        if (!doc) return;
        if (doc.selectedLessonId && doc.selectedLessonId !== selectedLessonId) {
          this.subLesson(doc.selectedLessonId);
        }
        let settingsUpdate = {};
        for (let setting of settingNames) {
          if (doc[setting] !== undefined) {
            settingsUpdate[setting] = doc[setting];
          }
        }
        this.setState(settingsUpdate);
      });
  }

  saveSetting(setting) {
    const { db, projectId, currentUser } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('settings')
      .doc(currentUser.id)
      .set({ userId: currentUser.id,
             ...setting }, {merge: true})
      .then( () => this.setState(setting) )
      .catch(console.error);
  }

  handleWhiteboardOnlySwitch(event) {
    this.setState({ whiteboardOnly: !this.state.whiteboardOnly });
  }

  handleHidingSwitch(event) {
    this.saveSetting({ hideMessagesHandledByOtherInstructors: event.target.checked });
  }

  handleObserverSwitch(event) {
    this.saveSetting({ hideObservers: event.target.checked });
  }

  handleCallOnMeSwitch(event) {
    this.setState({ hideCallOnMe: event.target.checked });
  }

  handleFadingSwitch(event) {
    this.saveSetting({ fadeMessagesHandledByOtherInstructors: event.target.checked });
  }

  handleSurfaceFlaggedMessagesSwitch(event) {
    this.saveSetting({ surfaceFlaggedMessages: event.target.checked });
  }

  handleAutoSortSwitch(event) {
    this.saveSetting({ autoSortMessages: event.target.checked });
  }

  handleHideInbox(event) {
    this.saveSetting({ hideInbox: event.target.checked });
  }

  handleDrawerMode(event) {
    this.saveSetting({ drawerMode: event.target.checked });
  }

  handleClearLocally(event) {
    this.saveSetting({ clearLocally : event.target.checked });
  }

  handleManualClusterCreation(event) {
    this.saveSetting({ manualClusterCreation : event.target.checked });
  }

  participants() {
    const { participants, observers, hideObservers } = this.state;
    return Object.values(participants).filter(s => !hideObservers || !observers[s.id]);
  }

  renderLeftPanel({fullWidth=false}={}) {
    const { db, storage, projectId } = this.props;
    const {
      classMessages,
      activeHocketId=null,
      drawerOpen,
      participants,
      showParticipants,
      activeStudentChatId,
      studentsRespondedLatestClusterBlock,
      hideMessagesHandledByOtherInstructors,
      fadeMessagesHandledByOtherInstructors,
      surfaceFlaggedMessages,
      autoSortMessages,
      hideInbox,
      drawerMode,
      clearLocally,
      manualClusterCreation,
      hideObservers,
    } = this.state;
    const participantCount = this.participants().length;
    let participationStatus = participantCount + " participant" +
    (participantCount === 1 ? "" : "s");
    if (hideObservers && participantCount < Object.values(participants).length) {
      const numObservers = Object.values(participants).length - participantCount;
      const plural = numObservers === 1 ? "" : "s";
      participationStatus += ` (${numObservers} observer${plural})`;
    }
    return (
      <div
        className={"message-card-area" + (fullWidth ? " mobile" : "")}>
        <div className="padded">
          <MessageForm
            key={ 'all-class-' + projectId }
            sendHocketAsMessage={ this.sendHocketAsMessageToClass }
            projectId={ projectId }
            hocketId={ activeHocketId }
            ref={ this.messageClassRef }
            mainQuillRef = { this.mainQuillRef }
            db={ db }
            storage={ storage }
            placeholder="Message the class..."/>
          <ThemeProvider theme={ theme }>
            <Tooltip
              title="Open lessons drawer [shift L]"
              placement="top"
              enterDelay={ 2500 }>
              <Button
                className="show-lessons-button"
                style={{ marginTop: '-30px' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={ (event) => {
                  event.stopPropagation();
                  this.toggleDrawer(!drawerOpen);
                } }>
                Lessons
              </Button>
            </Tooltip>
          </ThemeProvider>
          <Tooltip
            title={ showParticipants ? "Settings and Message Log [shift T]" : "See who's been active in the last day [shift T]" }
            placement="top"
            enterDelay={ 1200 }>
            <Button
                variant="outlined"
                style={{ marginTop: '10px', marginBottom: '8px' }}
                onClick={ () => this.setState({ showParticipants: !showParticipants, activeStudentChatId: null }) }
                fullWidth>
                { showParticipants ? "Classroom Settings" : "Participants" }
            </Button>
          </Tooltip>
        </div>
          { activeStudentChatId ? this.renderInstructorChatArea() : null}
          {
            (!activeStudentChatId && showParticipants) ?
            <Tooltip
              title="Click to message personal stats to each student [shift u]"
              placement="top"
              enterDelay={ 300 }>
              <p
                className="participant-count"
                onClick={() => this.askMessageStats() }>{ participationStatus }</p>
            </Tooltip>
            : null
          }
          {
              (!activeStudentChatId && showParticipants) ?
              <div className="padded">
                {
                  Object.values(participants).filter(s => 
                    !hideObservers || !this.state.observers[s.id]
                  ).sort( (s,t) => {
                    if (s.displayName < t.displayName) {
                      return -1;
                    } else {
                      return 1;
                    }
                  }).map( (s,i)  => {
                    return (<ParticipantListEntry
                      student={s}
                      key={i}
                      chatCb={ () => this.setActiveStudentChatId(s.id)}
                      typingDotsCb = { () => this.turnOffTypingDots(s.id) }
                      numStars={ this.getCounts(s.id, 'stars') }
                      numMessages={ this.getCounts(s.id, 'count') }
                      studentsRespondedLatestClusterBlock={ studentsRespondedLatestClusterBlock }
                      startTime={ last(this.state.clusterBlocks)?.startTime }/>
                    );
                  })
                }
              </div>
            : ( activeStudentChatId ? null :
            <div className="padded">
              <div className="settings-table">
                { this.state.useObservers ? <div className="settings-label">
                    <div className="settings-cell">
                        Hide observers
                    </div>
                    <Tooltip
                      title="Hide messages from observers [shift o]"
                      enterDelay={ 200 }
                      className="settings-cell">
                      <Switch
                        checked={ !!this.state.hideObservers }
                        onChange={ (event) => this.handleObserverSwitch(event) }
                        name="observerSwitch"
                        color="primary"
                        inputProps={{ 'aria-label': "hide messages from observers" }}
                      />
                    </Tooltip>
                  </div> : null }
                <div className="settings-label">
                  <div className="settings-cell">
                      Hide "don't call on me" messages
                  </div>
                  <Tooltip
                    title="hide messages that say 'don't call on me' [shift c]"
                    enterDelay={ 200 }
                    className="settings-cell">
                    <Switch
                      checked={ this.state.hideCallOnMe }
                      onChange={ (event) => this.handleCallOnMeSwitch(event) }
                      name="callOnMeSwitch"
                      color="primary"
                      inputProps={{ 'aria-label': "hide messages that say don't call on me" }}
                    />
                  </Tooltip>
                </div>
              <div className="settings-label">
                <div className="settings-cell">
                  Whiteboard only
                </div>
                <Tooltip
                  title="use this classroom window only for the whiteboard feature"
                  enterDelay={ 200 }
                  className="settings-cell">
                  <Switch
                    checked={ !!this.state.whiteboardOnly }
                    onChange={ (event) => this.handleWhiteboardOnlySwitch(event) }
                    name="hidingSwitch"
                    color="primary"
                    inputProps={{ 'aria-label': 'hide messages responded to by other instructors' }}
                  />
                </Tooltip>
                </div>
              <div className="settings-label">
                <div className="settings-cell">
                  Fade on TA response
                </div>
                <Tooltip
                  title="fade cluster block messages responded to by other instructors [shift f]"
                  enterDelay={ 200 }
                  className="settings-cell">
                  <Switch
                    checked={ !!fadeMessagesHandledByOtherInstructors }
                    onChange={ (event) => this.handleFadingSwitch(event) }
                    name="fadingSwitch"
                    color="primary"
                    inputProps={{ 'aria-label': 'fade messages responded to by other instructors' }}
                  />
                </Tooltip>
              </div>
              <div className="settings-label">
                <div className="settings-cell">
                  Hide on TA response
                </div>
                <Tooltip
                  title="hide cluster block messages responded to by other instructors [shift h]"
                  enterDelay={ 200 }
                  className="settings-cell">
                  <Switch
                    checked={ !!hideMessagesHandledByOtherInstructors }
                    onChange={ (event) => this.handleHidingSwitch(event) }
                    name="hidingSwitch"
                    color="primary"
                    inputProps={{ 'aria-label': 'hide messages responded to by other instructors' }}
                  />
                </Tooltip>
                </div>
                <div className="settings-label">
                  <div className="settings-cell">
                    Surface when flagged
                  </div>
                  <Tooltip
                    title="bring flagged messages to the top of each column"
                    enterDelay={ 200 }
                    className="settings-cell">
                    <Switch
                      checked={ !!surfaceFlaggedMessages }
                      onChange={ (event) => this.handleSurfaceFlaggedMessagesSwitch(event) }
                      name="surfaceFlaggedMessagesSwitch"
                      color="primary"
                      inputProps={{ 'aria-label': 'bring flagged messages to the top' }}
                    />
                  </Tooltip>
                </div>
                <div className="settings-label">
                  <div className="settings-cell">
                    Automatically sort
                  </div>
                  <Tooltip
                    title="sort messages in column by time sent"
                    enterDelay={ 200 }
                    className="settings-cell">
                    <Switch
                      checked={ !!autoSortMessages }
                      onChange={ (event) => this.handleAutoSortSwitch(event) }
                      name="autosortSwitch"
                      color="primary"
                      inputProps={{ 'aria-label': 'automatically sort messages' }}
                    />
                  </Tooltip>
                </div>
                <div className="settings-label">
                  <div className="settings-cell">
                    Hide inbox messages
                  </div>
                  <Tooltip
                    title="Hide inboxed messages by default"
                    enterDelay={ 200 }
                    className="settings-cell">
                    <Switch
                      checked={ !!hideInbox }
                      onChange={ (event) => this.handleHideInbox(event) }
                      name="hideInboxSwitch"
                      color="primary"
                      inputProps={{ 'aria-label': 'hide inbox messages' }}
                    />
                  </Tooltip>
                </div>
                <div className="settings-label">
                  <div className="settings-cell">
                    Open tools below message
                  </div>
                  <Tooltip
                    title="Show message card tools below card (on hover)"
                    enterDelay={ 200 }
                    className="settings-cell">
                    <Switch
                      checked={ !!drawerMode }
                      onChange={ (event) => this.handleDrawerMode(event) }
                      name="handleDrawerMode"
                      color="primary"
                      inputProps={{ 'aria-label': 'open drawer below message' }}
                    />
                  </Tooltip>
                </div>
                <div className="settings-label">
                  <div className="settings-cell">
                    Clear messages locally
                  </div>
                  <Tooltip
                    title="Clear messages only for you (not for other instructors/TAs)"
                    enterDelay={ 200 }
                    className="settings-cell">
                    <Switch
                      checked={ !!clearLocally }
                      onChange={ (event) => this.handleClearLocally(event) }
                      name="clearMessagesLocally"
                      color="primary"
                      inputProps={{ 'aria-label': 'clear messages locally' }}
                    />
                  </Tooltip>
                </div>
                <div className="settings-label">
                  <div className="settings-cell">
                    Manually add cluster blocks
                  </div>
                  <Tooltip
                    title="Create new cluster block only when plus button is clicked"
                    enterDelay={ 200 }
                    className="settings-cell">
                    <Switch
                      checked={ !!manualClusterCreation }
                      onChange={ (event) => this.handleManualClusterCreation(event) }
                      name="manualClusterCreation"
                      color="primary"
                      inputProps={{ 'aria-label': 'manually add cluster blocks' }}
                    />
                  </Tooltip>
                </div>
              </div>
            <h4 className="centered" style={{color: "#555"}}>
              Message Log:
            </h4>
            {
              classMessages.map((m, i) => {
              return (
                <MessageCard
                  message={m}
                  key={i}
                  chatCb={ () => {
                    this.setActiveStudentChatId(m.author);
                  }}/>
              );
            })
            }
            </div>
          )
        }
      </div>
    );
  }

  renderInstructorChatArea() {
    const {
      activeStudentChatId, studentChatMessage, classMessages,
    } = this.state;
    const { db, currentUser, projectId } = this.props;
    if (!activeStudentChatId) return <h2>Instructor Chat Area</h2>;
    return (
      <InstructorChatArea
        buttonCallback={ () => this.closeInstructorChatArea()
        }
        shareCb={ (message) => this.shareMessageWithClass(message) }
        projectId={ projectId }
        removeMessageByIdFromCluster={(id) => this.removeMessageByIdFromCluster(id)}
        currentUser={ currentUser }
        db={ db }
        studentId={ activeStudentChatId }
        clickedMessage={ studentChatMessage }
        classMessages={ classMessages }
        setMessageLimit={ this.setMessageLimit }
      />
    );
  }

  selectHocket(selectedHocketId) {
    if (!selectedHocketId) return null;
    this.setState({ selectedHocketId });
  }

  renderHocketCards() {
    const { hockets } = this.state;
    return (
      <div className="hocket-cards">
        { hockets.map((h, i) => <HocketCard key={i} hocket={h} label="Show" cb={ () => this.selectHocket(h.id) } projectId={ this.props.projectId } />) }
      </div>
    );
  }

  addClusterBlock(flaggedOnly=false) {
    const { projectId, db } = this.props;
    const { clusterBlocks, cachedClusterBlock } = this.state;
    let startTime = moment().subtract(this.timeOffset, 'ms');
    let clusterBlock = null;
    if (cachedClusterBlock) {
      clusterBlock = cachedClusterBlock;
    } else {
      clusterBlock = {
        title: '',
        startTime: startTime.toISOString(),
      }
    };
    if (flaggedOnly) {
      clusterBlock.flaggedMessagesOnly = true;
      clusterBlock.title = "Flagged messages";
    }
    addClusterBlock(clusterBlock.startTime); // analytics
    clusterBlocks.push(clusterBlock);
    db.collection('projects')
      .doc(projectId)
      .set({ clusterBlocks }, {merge: true});
    this.setState({ clusterBlockTime: null, showTimeSelector: false });
  }

  cacheClusterBlock(clusterBlock) {
    this.setState({cachedClusterBlock: clusterBlock});
  }

  saveClusterBlock(clusterBlock) {
    const { projectId, db } = this.props;
    const { clusterBlocks } = this.state;
    if (this.state.studentsRespondedLatestClusterBlock.size === 0 || !clusterBlock) {
      clusterBlocks.pop();
    }
    if (clusterBlock) clusterBlocks.push(clusterBlock);
    db.collection('projects')
      .doc(projectId)
      .set({ clusterBlocks }, { merge: true })
      .then(() => {
        this.setState({ 
          clusterBlockTime: null, 
          showTimeSelector: false 
        });
      })
      .catch(console.error);
  }

  sendHocketAsMessageToClass(hocket) {
    // this sends to the entire class, not just the clusters
    const { manualClusterCreation } = this.state;
    const { currentUser={}, projectId, db } = this.props;
    if (!hocket) return null;
    const message = hocket2Message(hocket);
    message.authorDisplayName = currentUser.displayName || '';
    message.authorPhotoUrl = currentUser.photoUrl || '';
    let startTime = moment().subtract(this.timeOffset, 'ms');
    //Commenting this line to fix scrolling issue:
    //this.setState({ activeHocketId: null });
    message.answered = true;
    messageClass(message.id);
    const batch = db.batch();
    for (let collection of ['messages', 'class-messages']) {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection(collection)
          .doc(message.id), 
        message
      );
    }
    batch.commit().then(() => {
      const clusterBlock = {
        title: message.textContent || '',
        messageId: message.id || null,
        startTime: startTime.toISOString(),
        question: message.quillDelta || '{"ops":[{"insert":""}]}',
      };
      if (message.suggestions.length > 0) {
        clusterBlock.suggestions = message.suggestions.map(sug => {return {value: sug.value, score: sug.score}});
        if (message.suggestions.some(s => s.score)) {
          clusterBlock.multipleChoice = 'scored';
        } else {
          clusterBlock.multipleChoice = 'unscored';
        }
      }
      if (message.openResponse) {
        clusterBlock.openResponse = message.openResponse;
      }
      if (message.textContent.includes("👥")) {
        clusterBlock.sessionId = this.sessionId;
        peerResponseQuestion(); // analytics
      }
      if (manualClusterCreation) {
        this.cacheClusterBlock(clusterBlock);
      } else {
        this.saveClusterBlock(clusterBlock);
      };
    }).catch(console.error);
  }

  shareMessageWithClass(message) {
    // this is from the share button
    const { db, projectId } = this.props;
    if (!message) return null;
    message = Object.assign({}, message);
    const oldMessageId = message.id;
    message.id = uuid();
    message.timestamp = now();
    message.answered = true;
    message.sharedFromClass = true;
    messageClass(message.id);
    db.collection('users')
      .doc(message.author === '000-0000-000' ? message.recipient : message.author)
      .collection('chats')
      .doc(projectId)
      .collection('messages')
      .doc(oldMessageId)
      .set({ sharedWithClass: true }, { merge: true })
      .then( () => {
        db.collection('projects')
        .doc(projectId)
        .collection('class-messages')
        .doc(message.id)
        .set(message).catch(console.error);
      })
      .then( () => {
        return db.collection('projects')
        .doc(projectId)
        .collection('messages')
        .doc(message.id)
        .set(message);
      })
      .then( () => {
        return db.collection('projects')
          .doc(projectId)
          .collection('messages')
          .doc(oldMessageId)
          .set({ sharedWithClass: true }, {merge: true} );
      })
      .catch(console.error);
  }

  removeMessageByIdFromCluster(messageId) {
    const { clusteredMessages=[] } = this.state;
    for (let i = 0; i < clusteredMessages.length; i++) {
      if (!clusteredMessages[i]) continue;
      if (!clusteredMessages[i].length) continue;
      for (let j = 0; j < clusteredMessages[i].length; j++) {
        const message = clusteredMessages[i][j];
        if (message.id === messageId) {
          clusteredMessages[i].splice(j, 1);
          break;
        }
      }
    }
    this.setState({ clusteredMessages });
  }

  sendMessagesToUsers(userIdMap) {
    const { db, projectId } = this.props;
    const ids = Array(...userIdMap.keys());
    const chunks = chunk(ids, 50);
    let result;
    for (let id_chunk of chunks) {
      const batch = db.batch();
      id_chunk.forEach( (userId) =>
        batch.set(
          db.collection('users')
            .doc(userId)
            .collection('chats')
            .doc(projectId)
            .collection('messages')
            .doc(userId),
            userIdMap.get(userId), {merge: true})
      );
      result = batch.commit();
    }
    return result;
  }

  sendMessageToUsers(message, userIds) {
    const { db, projectId } = this.props;
    const text = message.textContent;
    if (text && text.includes('🕔')) {
        const secondsText = text.match(/🕔([0-9]*)s/);
        if (!secondsText) {
          console.log("not starting timer");
        } else {
          const numSeconds = parseInt(secondsText[1]);
          this.setState({
            timeLimit: numSeconds,
            timerStartDate: new Date(),
          });
        }
    }
    const chunkSize = Math.min(50,
      Math.round(1000000 / JSON.stringify(message).length)
    );
    const chunks = chunk(userIds, Math.max(1, chunkSize));
    let result;
    for (let id_chunk of chunks) {
      const batch = db.batch();
      id_chunk.forEach( userId => {
        batch.set(
          db.collection('users')
            .doc(userId)
            .collection('chats')
            .doc(projectId)
            .collection('messages')
            .doc(message.id),
              message, {merge: true}
        );
      });
      result = batch.commit();
    }
    this.clearWhiteboard();
    return result;
  }

  deleteLastMessage() {
    const { db, projectId } = this.props;
    const { lastMessageId, clusterBlocks } = this.state;
    const messageId = lastMessageId || null;
    if (!messageId) {
      NotificationManager.info("no message to delete");
      return;
    }
    let answer = window.confirm("Are you sure you want to delete the last message you sent to the class?");
    if (!answer) return;
    return db.collection('projects')
      .doc(projectId)
      .collection('class-messages')
      .doc(messageId)
      .delete()
      .then(() => {
        if (messageId === clusterBlocks[clusterBlocks.length - 1].messageId) this.saveClusterBlock(); // just pops a cluster block
      });
  }

  sendMessageToUser(message, userId) {
    const { db, projectId } = this.props;
    if (!userId) return null;
    if (!message) return null;
    messageStudent(message.id);
    db.collection('users')
      .doc(userId)
      .collection('chats')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set(message, {merge:true});
  }

  setMessageAsAnswered(message) {
    const { db, projectId } = this.props;
    return db.collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set({ answered: true }, { merge: true });
  }

  sendHocketAsMessage(hocket, clusterIndex) {
    // to a cluster
    const { currentUser={}, db, projectId } = this.props;
    const { clusteredMessages } = this.state;
    const messages = clusteredMessages[clusterIndex];
    let message = hocket2Message(hocket);
    //message.author = currentUser.id;
    if (message) {
      message.authorDisplayName = currentUser.displayName || '';
      message.authorPhotoUrl = currentUser.photoUrl || '';
    }
    const authors = new Set();
    for (let i = 0; i < messages.length; i++) {
      authors.add(messages[i].author);
    }
    this.sendMessagetoUsers(message, authors);
    messages.forEach(message => {
      this.setMessageAsAnswered(message);
    });
    if (!message) return;
    clusteredMessages.splice(clusterIndex, 1, []);
    message.answered = true;
    messageCluster(message.id);
    db.collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set(message);
    this.setState({ clusteredMessages });
  }

  getUnclusteredUnansweredMessages(messages) {
    const { clusteredMessages=[] } = this.state;
    const unansweredMessages = messages.filter(m => !m.answered);
    const clusteredMessageIds = new Set();
    for (let i = 0; i < clusteredMessages.length; i++) {
      for (let j = 0; j < clusteredMessages[i].length; j++) {
        clusteredMessageIds.add(clusteredMessages[i][j].id);
      }
    }
    return unansweredMessages.filter(m => !clusteredMessageIds.has(m.id));
  }

  clearClusterBlock(startTime) {
    const { clusterBlocks=[] } = this.state;
    const { db, projectId } = this.props;
    if (clusterBlocks.length < 2) return;
    for (let i = 0; i < clusterBlocks.length; i++) {
      if (clusterBlocks[i].startTime === startTime) {
        clusterBlocks.splice(i, 1);
      }
    }
    clearClusterBlock();
    db.collection('projects')
      .doc(projectId)
      .set({ clusterBlocks }, { merge: true });
  }

  openLiveStudentTyping() {
    this.setState({ showLiveStudentTyping: true });
  }

  updateDefaultClusterCount(defaultClusterCount) {
    this.setState({ defaultClusterCount });
  }

  renderClusterBlocks() {
    const { clusterBlockLimit=3,
            timeZoneOffset, hideMessagesHandledByOtherInstructors, hideCallOnMe, hideObservers,
            fadeMessagesHandledByOtherInstructors, surfaceFlaggedMessages, autoSortMessages, hideInbox, drawerMode, clearLocally, clusterBlockTime } = this.state;
    if (this.state.whiteboardOnly) return null;
    const { clusterBlocks=[] } = this.state;
    const elements = [];
    let start = clusterBlocks.length - clusterBlockLimit;
    if (clusterBlockTime) {
      start = findLastIndex(clusterBlocks, 
        block => block.startTime <= clusterBlockTime.toISOString()
      ) - clusterBlockLimit;
    }
    if (start < 0) start = 0;
    const end = Math.min(clusterBlocks.length, start + clusterBlockLimit);
    for (let i = start; i < end; i++) {
      let startTime = clusterBlocks[i].startTime;
      let stopTime = null;
      if (clusterBlocks[i + 1]) {
        stopTime = clusterBlocks[i + 1].startTime || null;
      }
      let title = clusterBlocks[i].title || '';
      let question = clusterBlocks[i].question || '{"ops":[{"insert":""}]}';
      let suggestions = clusterBlocks[i].suggestions || null;
      let multipleChoice = clusterBlocks[i].multipleChoice || false;
      let openResponse = clusterBlocks[i].openResponse || null;
      let flaggedMessagesOnly = clusterBlocks[i].flaggedMessagesOnly || false;
      let sessionId = clusterBlocks[i].sessionId || null;
      let messageId = clusterBlocks[i].messageId || null;
      let refProp = {};
      let respondedProp = {};
      if (i === clusterBlocks.length - 1) {
        refProp.ref = this.latestClusterBlockRef
        respondedProp.setStudentsRespondedLatestClusterBlock = this.setStudentsRespondedLatestClusterBlock;
      }
      if (startTime) {
        elements.push(
          <ClusterBlock
            NotificationManager={ NotificationManager }
            hideInbox={ hideInbox }
            title={ title }
            messageId={ messageId }
            question={ question }
            multipleChoice={ multipleChoice }
            openResponse={ openResponse }
            flaggedMessagesOnly={ flaggedMessagesOnly }
            peerResponseActive={ sessionId === this.sessionId }
            suggestions={ suggestions }
            defaultClusterCount={ this.state.defaultClusterCount }
            mainQuillRef={ this.mainQuillRef }
            key={ startTime }
            {...refProp}
            {...respondedProp}
            shareCb={ this.shareMessageWithClass }
            clearClusterBlock={ this.clearClusterBlock }
            setActiveStudentChatId={ this.setActiveStudentChatId }
            setStudentChatMessage={ this.setStudentChatMessage }
            setMessagesResponseInProgress={ this.setMessagesResponseInProgress }
            updateDefaultClusterCount={ this.updateDefaultClusterCount }
            hideMessagesHandledByOtherInstructors={ hideMessagesHandledByOtherInstructors }
            fadeMessagesHandledByOtherInstructors={ fadeMessagesHandledByOtherInstructors }
            hideObservers={ hideObservers }
            observers={ this.state.observers }
            hideCallOnMe={ hideCallOnMe }
            drawerMode={ drawerMode }
            clearLocally={ clearLocally }
            surfaceFlaggedMessages={ surfaceFlaggedMessages }
            autoSortMessages={ autoSortMessages }
            startTime={ startTime }
            stopTime={ stopTime }
            timeZoneOffset={ timeZoneOffset }
            messageClassRef={ this.messageClassRef }
            db={ this.props.db }
            storage={ this.props.storage }
            currentUser={ this.props.currentUser }
            router={ this.props.router }
            projectId={ this.props.projectId }
            openLiveStudentTyping={ this.openLiveStudentTyping }/>
        );
      }
    }
    if (start + clusterBlockLimit < clusterBlocks.length) {
      elements.push(
        <Button
          key="later-messages-button"
          className="cluster-block-later-button"
          fullWidth
          variant="contained"
          onClick={() => this.setState({
            clusterBlockTime: start + clusterBlockLimit + 1 === clusterBlocks.length ? null : new Date(clusterBlocks[start + clusterBlockLimit + 1].startTime),
          })}>
          Later messages
        </Button>
      );
    }
    return (
      <>{ elements.reverse() }</>
    );
  }

  toggleDrawer(drawerOpen=false) {
    const {
      lastLessonMessageIndex=0,
      lesson={hockets: []}
    } = this.state;
    const { hockets } = lesson;
    const scrollMode = (
      hockets.length - lastLessonMessageIndex < 7 ?
      "end" : "middle"
    );
    if (drawerOpen) openLessonsDrawer();
    this.setState({ drawerOpen }, () => {
      if (!drawerOpen) return;
      if (!this.state.selectedLessonId) return;
      if (!this.state.activeHocketId) return;
      // if (this.lessonScroller && 
      //       this.selectedLessonMessageRef.current ) {
      //   this.lessonScroller.intoView(this.selectedLessonMessageRef.current);
      // }
      setTimeout(() => {
        try {
          if (scrollMode === "middle") {
            this.selectedLessonMessageRef.current.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
          }
          else {
            this.selectedLessonMessageRef.current.parentElement.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
          }
        } catch (err) {
          console.log(err);
        }
      }, 0);
    });
  }

  selectLesson(event) {
    const selectedLessonId = event.target.value;
    const { db, projectId, currentUser } = this.props;
    if (selectedLessonId === this.state.selectedLessonId) {
      return null;
    }
    db.collection('projects')
      .doc(projectId)
      .collection('settings')
      .doc(currentUser.id)
      .set({ selectedLessonId }, {merge: true});
    this.subLesson(selectedLessonId);
    const lastLessonMessageIndex = 0;
    selectLessonFromDrawer();
    this.setState({selectedLessonId, lastLessonMessageIndex});
  }

  setActiveHocketId(hocketId, i) {
    const { db, projectId, currentUser } = this.props;
    const { activeHocketId } = this.state;
    if (activeHocketId === hocketId) {
      this.setState({ activeHocketId: null }, () =>
        this.setState({ activeHocketId: hocketId, lastLessonMessageIndex: i })
      );
    } else {
      this.setState({ activeHocketId: hocketId, lastLessonMessageIndex: i });
      db.collection('projects')
        .doc(projectId)
        .collection('settings')
        .doc(currentUser.id)
        .set({ activeHocketId: hocketId, lastLessonMessageIndex: i}, {merge: true})
        .catch(console.error);
    }
  }

  handleShiftUpPress() {
    const { lesson={} } = this.state;
    let { lastLessonMessageIndex=0 } = this.state;
    if (!lesson.hockets || !lesson.hockets.length) return null;
    if (lastLessonMessageIndex === 0) return;
    lastLessonMessageIndex -= 1;
    const activeHocketId = lesson.hockets[lastLessonMessageIndex].hocketId;
    this.setActiveHocketId(activeHocketId, lastLessonMessageIndex);
  }

  handleShiftDownPress() {
    const { lesson={} } = this.state;
    let { lastLessonMessageIndex=0 } = this.state;
    if (!lesson.hockets || !lesson.hockets.length) return null;
    if (lastLessonMessageIndex ===
          lesson.hockets.length - 1) return;
    lastLessonMessageIndex += 1;
    const activeHocketId = lesson.hockets[lastLessonMessageIndex].hocketId;
    this.setActiveHocketId(activeHocketId, lastLessonMessageIndex);
  }

  renderDrawer() {
    const { drawerOpen, lessons, selectedLessonId, lesson, hockets, lastLessonMessageIndex } = this.state;
    return <LessonDrawer
      drawerOpen={ drawerOpen }
      lessons={ lessons }
      selectedLessonId={ selectedLessonId }
      lesson={ lesson }
      hockets={ hockets }
      lastLessonMessageIndex={ lastLessonMessageIndex }
      selectedLessonMessageRef={ this.selectedLessonMessageRef }
      setActiveHocketId={ this.setActiveHocketId }
      selectLesson={ this.selectLesson }
    />;
  }

  openTimeSelector() {
    this.setState({ showTimeSelector: true });
  }

  hide() {
    const { currentUser={} } = this.props;
    const { instructors={}, tas={} } = this.state;
    if (isAdmin(currentUser.id)) return false;
    if (instructors[currentUser.id]) return false;
    if (tas[currentUser.id]) return false;
    return true;
  }

  renderLiveWriting() {
    return <div ref={this.writingContainerRef} className="live-writing-container">
      <Fabric
        throttleTime={ 500 }
        getEmptyCanvas
        parentRef={ this.writingContainerRef }
        saveSketch={ this.saveSketch }
        height={ Math.round(0.8 * window.document.body.clientHeight - 100) }
        width={ Math.round(0.8 * window.document.body.clientWidth) }
        setTyping={ this.setTyping }
        rawSvg
        setMousetrap={ () => this.setMousetrap() }/>
    </div>;
  }

  saveSketch(svg) {
    if (!this.liveWritingId) return false;
    const liveWritingId = this.liveWritingId;
    const { db, projectId, storage, currentUser } = this.props;
    this.setState({ showLiveWriting: false });
    svgUploader(storage, currentUser.id, svg).then(url => {
      const quillDelta = {ops: [{insert: {image: url}}]};
      const message = Message(quillDelta, '', currentUser.id);
      message.author = '000-0000-000';
      message.authorDisplayName = currentUser.displayName || '';
      message.authorPhotoUrl = currentUser.photoUrl || '';
      message.sentFromMessageEntireClass = true;
      message.id = liveWritingId;
      message.svgPatch = null;
      this.clearWhiteboard();
      db.collection('projects')
        .doc(projectId)
        .collection('class-messages')
        .doc(message.id)
        .set(message)
        .catch(console.error);
    }).catch(console.error);
    this.liveWritingId = null;
  }

  setTyping({ svg }) {
    const { currentUser } = this.props;
    let quillDelta, svgPatch, message;
    if (this.currentSvgGroups && this.liveWritingId) {
      const svgGroups = splitGroups(svg);
      svgPatch = diff.getPatch(this.currentSvgGroups, svgGroups);
      this.currentSvgGroups = svgGroups;
      this.setWhiteboard({
        id: this.liveWritingId,
        svgPatch,
        firstWhiteboardPatch: false,
      }, this.state.studentUserIds);
      return;
    }
    if (!this.liveWritingId) {
      quillDelta = {ops: [{insert: '\n'}]};
      message = Message(quillDelta, '', '000-0000-000');
      message.authorDisplayName = currentUser.displayName || '';
      message.authorPhotoUrl = currentUser.photoUrl || '';
      message.sentFromMessageEntireClass = true;
      const groups = splitGroups(svg);
      message.svgPatch = diff.getPatch([], groups);
      message.firstWhiteboardPatch = true;
      this.liveWritingId = message.id;
      this.currentSvgGroups = groups;
      // db.collection('projects').doc(projectId).collection('class-messages')
      //   .doc(message.id).set(message).catch(console.error);
      return this.setWhiteboard(message);
    }
  }

  setWhiteboard(message) {
    const { db, projectId } = this.props; 
    return db.collection('projects').doc(projectId)
             .collection('whiteboard').doc('whiteboard')
             .set(message, {merge: true}).catch(console.error);
  }

  clearWhiteboard() {
    const { db, projectId } = this.props;
    this.currentSvgGroups = null;
    this.liveWritingId = null;
    db.collection('projects').doc(projectId).collection('whiteboard').doc('whiteboard').delete();
  }

  renderLiveStudentTyping() {
    const { participants } = this.state;
    const [clusterBlock] = this.state.clusterBlocks.slice(-1);
    return <div className="live-student-typing-container">
      { Object.values(participants)
        .map(s => ({...s, hash: hashString(clusterBlock.startTime + s.id)}))
        .sort((s1, s2) => {
          return (s1.hash < s2.hash) ? 1 : -1;
        })
        .map(s => {
            const thisBlock = s.lastActive > clusterBlock.startTime;
            let delta = s.currentQuillDelta;
            let groups = "";
            if (!thisBlock) {
              delta = {ops: [{insert: "\n"}]};
            } else if (s.svgGroups) {
              groups = s.svgGroups;
            } else if (!delta) {
              if (s.typing) {
                delta = {ops: [{insert: "...\n"}]};
              } else {
                delta = {ops: [{insert: "\n"}]};
              }
            }
            return <div 
              className={"live-student-typing-card" + ((s.lastAnswered > clusterBlock.startTime) ? " answered-typing-card": "")}
              key={"live-typing" + s.id}>
              { groups?.length === 0 ? <ReadOnlyQuill
                quillDelta={typeof delta === 'string' ? JSON.parse(delta) : delta}/> : <img style={{maxWidth: "100%", maxHeight: "100%", marginLeft: "auto", marginRight: "auto"}} alt='user-sketch' src={"data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(groups.join(''))))}/>
              }
          </div>
        })}
    </div>;
  }

  exitTempScreens(event) {
    if (event && this.state.whiteboardOnly && 
          this.state.showLiveWriting && 
          event.clientY > 0.5 * document.documentElement.clientHeight) return;
    this.clearWhiteboard();
    this.setState({ 
      showHelp: false, 
      showLiveStudentTyping: false, showLiveWriting: false, 
    });
  }

  render() {
    updateTitleBar('Classroom');
    const { db, router, currentUser={}, projectId="main" } = this.props;
    const { showHelp, showTimeSelector, drawerOpen, showLiveWriting, 
            showLiveStudentTyping, timeLimit, timerStartDate, 
            clusterBlockTime } = this.state;
    if (this.hide()) return null;
    const blur = showHelp ? " blur" : "";
    const maskBehindLesson = drawerOpen ? <div className="masking-cover"></div> : null;
    if (window.document.body.clientWidth < 750) {
      return (
        <div className="live-classroom-view">
          <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
          { this.renderLeftPanel({fullWidth: true}) }
        </div>
      );
    }
    const liveClassroomView = (
      <div className={"live-classroom-view" + blur}>
        <Timer
          onClick={ () => this.setState({ timeLimit: -1 })}
          timeLimit={ timeLimit }
          timerStartDate={ timerStartDate }/>
        <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
        <div className="frame flex-container">
          { this.renderLeftPanel() }
          <div className="y-scrollable grey cluster-block-container">
            <div className="padded cluster-block-area" id="cluster-block-area" style={{position: 'relative', height: 'calc(100% - 16px)'}}>
              { this.renderDrawer() }
              { maskBehindLesson }
              <div className="add-button-container">
              <Tooltip
                title="Add a new cluster block"
                enterDelay={ 100 }
                className="flex-child">
                <Button
                  className="add-cluster-button"
                  onClick={ () => this.addClusterBlock() }
                  variant="contained">
                  +
                </Button>
              </Tooltip>
              <Tooltip
                title="Cluster block time travel"
                enterDelay={ 100 }
                className={"flex-none" + (showTimeSelector ? " block-time-selector" : "")}>
                { showTimeSelector ? <div style={{paddingBottom: 0}}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div style={{marginLeft: "5px", marginBottom: "0"}}>
                    <KeyboardDateTimePicker
                      id="homework-deadline"
                      ampm={ false }
                      value={ clusterBlockTime }
                      disableFuture
                      onChange={(date) => this.handleClusterBlockTimeChange(date)}
                    />
                  </div>
                </MuiPickersUtilsProvider></div>:
                <Button
                  className="add-flagged-cluster-button"
                  onClick={ () => this.openTimeSelector() }
                  variant="contained">
                  <QueryBuilderIcon style={{ fontSize: 18 }}/>
                </Button> }
              </Tooltip>
              <Tooltip
                title="Add cluster block of all flagged messages"
                enterDelay={ 100 }
                className="flex-none">
                <Button
                  className="add-flagged-cluster-button"
                  onClick={ () => this.addClusterBlock(true) }
                  variant="contained">
                  <FlagIcon style={{ fontSize: 18 }}/>
                </Button>
              </Tooltip>
              <Tooltip
                title="Delete last message"
                enterDelay={ 100 }
                className="flex-none">
                <Button
                  className="add-flagged-cluster-button"
                  onClick={ () => this.deleteLastMessage() }
                  variant="contained">
                  <DeleteIcon style={{ fontSize: 18 }}/>
                </Button>
              </Tooltip>
              <Tooltip
                title="Whiteboard"
                enterDelay={ 100 }
                className="flex-none">
                <Button
                  className="add-flagged-cluster-button"
                  onClick={ () => this.setState({ showLiveWriting: true }) }
                  variant="contained">
                  <CreateIcon style={{ fontSize: 18 }}/>
                </Button>
              </Tooltip>
            </div>
              { this.renderClusterBlocks() }
              <Button
                fullWidth
                className="increment-cluster-block-limit-button"
                onClick={ () => this.incrementClusterBlockLimit() }
                variant="contained">
                Load older messages
              </Button>
              </div>
            </div>
          </div>
        </div>
    );
    const maskCover = (showHelp || showLiveStudentTyping || showLiveWriting) ? <div
      className="masking-cover"
      onClick={ (event) => this.exitTempScreens(event) }>
    </div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    const typingCard = showLiveStudentTyping ? this.renderLiveStudentTyping() : null;
    const liveWriting = showLiveWriting ? this.renderLiveWriting() : null;
    return (
      <>
        { maskCover }
        { liveWriting || typingCard || helpCard }
        { liveClassroomView }
        <NotificationContainer/>
      </>
    );
  }
}

export default LiveClassroom;
