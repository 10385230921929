import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import BlankView from '../blank-view/';
import './style.css';

class EmailFollowup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      didntComeFromEmail: false,
    }
  }

  componentDidMount() {
    const { firebaseAuth } = this.props;
    let email = '';
    if (firebaseAuth
        .isSignInWithEmailLink(window.location.href)) {
      email = window.localStorage.getItem('emailForSignIn');
    } else {
      this.setState({ didntComeFromEmail: true });
    }
    this.setState({ email });
  }

  submit() {
    const { updateUser, firebaseAuth,
            setCurrentUser, router } = this.props;
    const { email } = this.state;
    if (email === '') {
      window.confirm('Please confirm your email address')
      return;
    }
    return firebaseAuth
      .signInWithEmailLink(email, window.location.href)
      .then( (result) => {
        window.localStorage.removeItem('emailForSignIn');
        return updateUser(result.user);
      })
      .then( (currentUser) => {
        setCurrentUser(currentUser, () => {
          if (currentUser.photoUrl && currentUser.displayName) {
            router.history.push('/chat');
          } else {
            router.history.push('/profile');
          }
        }, 'email');
      })
      .catch(console.error);
  }

  render() {
    const { email, didntComeFromEmail } = this.state;
    const { router } = this.props;
    const style = {
      opacity: 0,
      fontWeight: '400',
      textAlign: 'center',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    if (didntComeFromEmail) {
      return <BlankView
        message="It looks like you didn't get here from the sign-in link in your email!"
        router={ router }
        pageNotFound
        />
    }
    return (
      <div style={ style } className="email-followup">
        <img className="logo-image" src="/prismia-logo-simple.svg" alt="logo"/>
        <TextField
          key='email-input'
          value={ email || '' }
          fullWidth
          className='email-input'
          variant="outlined"
          placeholder="Confirm Email Address..."
          onChange = { (e) => {
            this.setState({ email: e.target.value });
          }}
          />
        <Button
          className='submit-button'
          variant='outlined'
          onClick={ () => this.submit() }>
          Sign in
        </Button>
      </div>
    );
  }

}

export default EmailFollowup;
