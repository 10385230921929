import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import { CollapsibleJupyterCell } from '../JupyterCell';
import DataTable from '../DataTable'
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CodeIcon from '@material-ui/icons/Code';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CommentIcon from '@material-ui/icons/Comment';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import SuggestionSuperList from '../SuggestionSuperList';
import toPlaintext from 'quill-delta-to-plaintext';
import { updateTitleBar, isRegistered, pingBinderKernel, tryUntilSuccess, markdownToDelta, mousetrapStopCallback, arrayUnion, isInViewport, isNote, unique } from '../utils';
import Hocket from '../hocket';
import MessageForm from '../MessageForm';
import CardContents from '../CardContents';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import 'react-quill/dist/quill.snow.css';
import './style.css';
import * as Mousetrap from 'mousetrap';
import uuid from 'uuid/v4';
import { mobileThreshold } from '../constants';

// for message virutalization: only render a
// window of 50 messages, stepping in increments
// of 10 when the window scroll position gets
// near the top or bottom:
const SOLUTION_RENDER_MAX = 50;
const SOLUTION_RENDER_STEP = 5;

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

const FlexButton = withStyles({
  root: {
    flex: 'none',
    color: 'black',
  },
})(IconButton);

const getItemStyle = (clusterStyle={}) => ({
    background: clusterStyle.background,
});

const settingsThemeColor = '#FFF';
const theme = createMuiTheme({
  palette: {
    common: { black: settingsThemeColor, white: settingsThemeColor },
    primary: { main: settingsThemeColor, dark: '#000', light: settingsThemeColor },
    text: { primary: settingsThemeColor, secondary: '#000' }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        }
      }
    },
    MuiIconButton: {
      root: { color: 'white' },
    },
    MuiPickersBasePickers: {
      container:
        { color: '#CCC' },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: '#237882',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#333'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#333',
      }
    },
    MuiPaper: {
      root: { color: '#333',
              backgroundColor: 'white' }
    },
    MuiButton: {
      textPrimary: { color: '#333'}
    },
  }
});

class AssignmentGradingView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      assignment: null,
      project: null,
      settings: null,
    };
    this.assignmentRef = {};
    this.mainQuillRef = React.createRef();
    this.juniperRef = React.createRef();
    this.resultsTableRef = React.createRef();
    this.activeStudentCardRef = React.createRef();
    this.hocketsAreaRef = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.publishFeedback = this.publishFeedback.bind(this);
    this.state = {
      collaborators: {},
      hockets: {},
      answers: {},
      regradeRequests: {},
      assignment: null,
      instructors: {},
      tas: {},
      exportFormat: 'HTML',
      activeHocketId: null,
      hocketSelectionRange: null,
      showHelp: false,
      showSettings: false,
      locked: false,
      juniperOpen: false,
      juniperHasRendered: false,
      linkClicked: false,
      published: false,
      lastBinderPing: new Date(),
      currentProblem: 0,
      currentProblemId: null,
      editRubricItem: null,
      comments: {},
      studentIds: null,
      cardLimit: SOLUTION_RENDER_MAX,
      openProblems: new Set(),
      submissionViewStudentId: null,
      rubricChangesPending: false,
      resultsTable: false,
      resultsView: 'points',
      students: {},
      activeStudentId: null,
      tempRubric: [],
      openSolutions: {},
      showProblem: false,
      showSolution: false,
      deadlines: {},
      ignoreDeadlines: false,
      submittingRegradeRequest: false,
      rubricMap: {},
      showStudents: false,
      hideGraded: false,
      groupAssignment: false,
      groupAssignments: [],
    };
  }

  componentDidMount() {
    this.subAssignment(this.props);
    this.subRubricMap();
    this.setMousetrap();
    this.setActiveHocketId(null);
    this.subStudents();
    this.subCollaborators();
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
    setTimeout(() => this.handleProblemUrl(), 1000);
  }

  hocketIndex(hocketId) {
    const { assignment } = this.state;
    const { publishedHockets=[] } = assignment;
    return publishedHockets.findIndex(hocket => hocket.hocketId === hocketId);
  }

  adjustActiveHocket(increment) {
    const { activeHocketId, assignment } = this.state;
    const { publishedHockets=[] } = assignment;
    const currentIndex = this.hocketIndex(activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < publishedHockets.length)) {
      this.setActiveHocketId(publishedHockets[newIndex].hocketId);
    }
  }

  nextSolution(increment) {
    const { activeStudentId, currentProblemId, studentIds, cardLimit, openSolutions, answers } = this.state;
    const currentStudentIds = studentIds.filter( id => 
      answers?.[currentProblemId]?.[id] && !this.shouldSkip(id, currentProblemId)
    )
    const currentIndex = currentStudentIds.indexOf(activeStudentId);
    let newIndex = currentIndex + increment;
    if (newIndex < 0) newIndex = 0;
    if (newIndex > currentStudentIds.length - 1) newIndex = currentStudentIds.length - 1;
    const newStudentId = currentStudentIds[newIndex];
    // close previous solution and open the new one, but don't close if everything
    // is open
    if (openSolutions[currentProblemId]?.size < studentIds.length) openSolutions[currentProblemId].delete(activeStudentId);
    if (!openSolutions[currentProblemId]) openSolutions[currentProblemId] = new Set();
    openSolutions[currentProblemId].add(newStudentId);
    this.setState({ activeStudentId: newStudentId, }, () => {
      setTimeout( () => {
        const element = document.getElementById(newStudentId);
        if (element) element.scrollIntoView();
      }, 75);
      setTimeout( () => {
        const element = document.getElementById(newStudentId);
        if (element) element.scrollIntoView();
      }, 250);
    });
    if (increment < 0) {
      if (newIndex < cardLimit - SOLUTION_RENDER_MAX) {
        this.setState({ cardLimit: cardLimit - 1});
      }
    } else {
      if (cardLimit - newIndex < SOLUTION_RENDER_STEP) {
        this.setState({ cardLimit: cardLimit + SOLUTION_RENDER_MAX});
      }
    }
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }

  handleGroupAssignmentChange(studentId, event) {
    const { 
      db, 
      projectId, 
      assignmentId, 
    } = this.props;
    const collaborators = (
      this.state.collaborators[studentId] || []
    );
    const newCollaborators = unique(collaborators
      .concat(event.target.value));
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('students')
      .doc(studentId)
      .set({
        collaborators: newCollaborators,
      }, {merge: true})
      .catch(console.error);
  }

  adjustSelectionEnd(increment) {
    const { activeHocketId,
            assignment,
            hocketSelectionRange
    } = this.state;
    const { publishedHockets=[] } = assignment;
    const currentIndex = publishedHockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < publishedHockets.length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  handleScroll(event) {
    const { cardLimit } = this.state;
    const node = event.target;
    const problemCard = document.getElementById("problem-statement");
    const problemCardHeight = problemCard ? problemCard.clientHeight : 0;
    const scrollTopFraction = (node.scrollTop - problemCardHeight) / node.scrollHeight;
    const scrollBottomFraction = (node.scrollTop + node.clientHeight) / node.scrollHeight;
    if (scrollBottomFraction > 0.9) {
      const numCards = this.numStudentCards();
      this.setState({
        cardLimit: Math.min(numCards, cardLimit + SOLUTION_RENDER_STEP)
      });
    } else if (scrollTopFraction < 0.2 && cardLimit - SOLUTION_RENDER_MAX > 0) {
      this.setState({ cardLimit: cardLimit - SOLUTION_RENDER_STEP });
    }
  }

  paneDidMount(node) {
    if (node) {
      this.unsub.scrollListener = () => {
        node.removeEventListener("scroll", this.handleScroll);
      }
      node.addEventListener("scroll", this.handleScroll);
    }
  }

  nextProblem(increment=1) {
    const { currentProblem, registeredProblems } = this.state;
    const newProblemIdx = Math.min(currentProblem + increment, registeredProblems.length - 1);
    this.setState({
      currentProblem: newProblemIdx,
      currentProblemId: registeredProblems[newProblemIdx],
      cardLimit: SOLUTION_RENDER_MAX,
    }, () => {
      this.subStudentAnswers();
    });
    this.setState({ activeStudentId: null });
  }

  prevProblem() {
    const { currentProblem, registeredProblems } = this.state;
    const newProblemIdx = Math.max(currentProblem - 1, 0);
    this.setState({
      currentProblem: newProblemIdx,
      currentProblemId: registeredProblems[newProblemIdx],
      cardLimit: SOLUTION_RENDER_MAX,
    }, () => {
      this.subStudentAnswers();
    });
    this.setState({ activeStudentId: null });
  }

  setMousetrap() {
    for (let i = 0; i < 10; i++) {
      Mousetrap.bind(String(i), () => this.shortcutMarkRubricItem(i));
    }
    Mousetrap.bind("left", () => this.prevProblem());
    Mousetrap.bind("right", () => this.nextProblem());
    Mousetrap.bind("down", () => this.nextSolution(1));
    Mousetrap.bind("up", () => this.nextSolution(-1));
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind("ctrl+j", () => this.toggleJuniper());
    Mousetrap.bind("esc", () => {
      if (this.state.locked) this.setState({ activeHocketId: null });
      this.setState({
        hocketSelectionRange: null,
        showHelp: false,
        submissionViewStudentId: null,
        resultsTable: false,
      });
    });
    Mousetrap.bind('enter', () => {
      setTimeout( () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.focus();
          const editor = this.mainQuillRef.current.editor;
          editor.setSelection(editor.getLength(), 0);
        }
      }, 40);
    });
    Mousetrap.bind("shift+m", () => this.convertEditorContents());
    Mousetrap.bind(["mod+/", "shift+/"], () => this.toggleHelp());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  exitHelpOrSettings() {
    this.setState({
      showHelp: false,
      showSettings: false,
      submissionViewStudentId: null,
      resultsTable: false
    });
  }

  toggleHelp() {
    const { showHelp } = this.state;
    this.setState({ showHelp: !showHelp});
  }

  setCodeCell(language) {
    this.assignmentRef.set({ codeCell: language }, {merge: true});
  }

  setLang(lang) {
    this.setState({ codeCell: lang });
  }

  setDisplayMode(setting) {
    this.assignmentRef.set({ published: setting }, {merge: true});
  }

  helpInfo() {
    return (<div className="help-info">
      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move active solution up one</li>
      <li><tt>down</tt> move active solution down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia assignments or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>

      <h1>Emoji features</h1>
      <ul className="documentation-list">
        <li>Sending a message including the text <span role="img" aria-label="silhouette">👥</span> sends students' messages to each other anonymously and allows each student to respond directly to the person whose answer they see. Use <tt>\peer</tt> followed by tab to get the silhouette character.</li>
        <li>Sending a message including the text <span role="img" aria-label="clock">🕔90s</span> sets a 90-second timer (visible to you and to students). Use <tt>\clock</tt> or <tt>\timer</tt> followed by tab to get the clock character.</li>
        <li>Sending a message including the text <span role="img" aria-label="pencil">✏</span> opens each student's drawing tool and sets the image contained in the message as the background image. Use <tt>\draw</tt> followed by tab to get the pencil character.</li>
        <li>Sending a message including the text <span role="img" aria-label="registered">®</span> marks the question as required, meaning that it will be tracked as an open response question on the <em>Metrics</em> page. Use <tt>\RR</tt> or <tt>\required</tt> followed by tab to get the registered symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="pushpin">📌</span> will pin the message to the top of each student's window. Use <tt>\pin</tt> followed by tab to get the pushpin character.</li>
        <li>Sending a message including the text <span role="img" aria-label="recycle">♻</span> will clear every student's pinned messages. Use <tt>\clear</tt> followed by tab to get the recycling symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="otimes">⊗</span> will remove one pinned message. The character can be included more than once to remove multiple pinned messages. Use <tt>\unpin</tt> followed by tab to get the <span role="img" aria-label="otimes">⊗</span> symbol.</li>
      </ul>
    </div>);
  }

  mousetrapUnset() {
    Mousetrap.unbind("left");
    Mousetrap.unbind("right");
    Mousetrap.unbind("up");
    Mousetrap.unbind("down");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("shift+/");
    Mousetrap.unbind("mod+/");
    Mousetrap.unbind("ctrl+j");
    for (let i = 0; i < 10; i++) {
      Mousetrap.unbind(String(i));
    }
  }

  convertEditorContents() {
    if (!this.mainQuillRef) return;
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);
    const editor = this.mainQuillRef.current.editor;
    const text = toPlaintext(editor.getContents());
    const ops = text.split("\n---\n").map(cellText => markdownToDelta(cellText).concat([{insert: {hr: true}}]));
    editor.setContents({ops: flatten(ops).slice(0, -1)});
    setTimeout( () => this.mainQuillRef.current.focus(), 50 );
  }

  subStudentIds() {
    const { db, projectId, assignmentId } = this.props;
    return db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('students')
      .onSnapshot(snap => {
        const studentIds = snap.docs.map(doc => doc.id).filter(id => id !== 'students');
        this.setState({ studentIds });
        this.subStudentAnswers(studentIds);
        this.subDeadlines(studentIds);
      });
  }

  subDeadlines(studentIds) {
    const { db, projectId, assignmentId } = this.props;
    for (let studentId of studentIds) {
      db.collection('projects')
        .doc(projectId)
        .collection('assignments')
        .doc(assignmentId)
        .collection('students')
        .doc(studentId)
        .onSnapshot( snap => {
          const data = snap.data();
          if (data.deadline) {
            const { deadlines } = this.state;
            deadlines[studentId] = data.deadline;
            this.setState({ deadlines });
          }
        });
    }
  }

  subStudentAnswers(studentIds, problemIds) {
    const { db, projectId, assignmentId } = this.props;
    if (!studentIds) studentIds = this.state.studentIds || [];
    if (!problemIds) {
      const { currentProblemId } = this.state;
      problemIds = currentProblemId ? [currentProblemId] : [];
    }
    this.studentAnswerRef = db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId);
    for (let studentId of studentIds) {
      for (let problemId of problemIds) {
      const key = studentId + problemId;
      if (this.unsub[key]) {
        continue;
      }
      this.unsub[key] = this.studentAnswerRef
        .collection('students')
        .doc(studentId)
        .collection('problems')
        .doc(problemId)
        .onSnapshot(snap => {
          const { answers, comments, regradeRequests } = this.state;
          const doc = snap.data();
          if (!doc) return;
          if (!answers[problemId]) answers[problemId] = {};
          if (!comments[problemId]) comments[problemId] = {};
          if (!regradeRequests[problemId]) regradeRequests[problemId] = {};
          if (doc.hockets) answers[problemId][studentId] = doc.hockets;
          if (doc.comments || doc.comments === null) comments[problemId][studentId] = doc.comments;
          if (doc.regradeRequestThread) regradeRequests[problemId][studentId] = doc.regradeRequestThread;
          this.setState({ answers, comments, regradeRequests });
        });
      }
    }
  }

  subAssignment(props) {
    const { db, currentUser, projectId, assignmentId } = props;
    if (this.unsub.assignment) return this.unsub.assignment();
    if (!currentUser) return console.log(
      'no current user',
      currentUser,
      props.currentUser
    );
    this.assignmentRef = db
      .collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId);
    this.unsub.assignment = this.assignmentRef
      .onSnapshot(snap => {
        const assignment = snap.data();
        if (!assignment) return;
        const { publishedHockets=[] } = assignment;
        for (let i = 0; i < publishedHockets.length; i++) {
          if (this.unsub[publishedHockets[i].hocketId]) continue;
          this.subHocket(publishedHockets[i].hocketId, () =>
            this.setRegisteredProblems(assignment)
          );
        }
        this.setState({ assignment });
        this.setState({ codeCell: assignment.codeCell || null });
        this.setState({ published: assignment.published || null});
      });
  }

  subRubricMap() {
    this.unsub.rubricMap = this.assignmentRef
      .collection('rubric-map')
      .onSnapshot(snap => {
        snap.docs.forEach(doc => {
          const problem = doc.data();
          this.subRubricProblem(problem.id)
        })
      })
  }

  subRubricProblem(problemId) {
    this.unsub['rubric-map-' + problemId] = this.assignmentRef
      .collection('rubric-map')
      .doc(problemId)
      .collection('students')
      .onSnapshot(snap => {
        snap.docs.forEach(doc => {
          const student = doc.data();
          this.subRubricStudent(problemId, student.id)
        })
      })
  }

  subRubricStudent(problemId, studentId) {
    this.unsub['rubric-map-' + problemId + studentId] = this.assignmentRef
      .collection('rubric-map')
      .doc(problemId)
      .collection('students')
      .doc(studentId)
      .collection('rubric-items')
      .onSnapshot(snap => {
        const { rubricMap } = this.state;
        snap.docs.forEach(doc => {
          const rubricItem = doc.data();
          if (!rubricMap[problemId]) rubricMap[problemId] = {};
          if (!rubricMap[problemId][studentId]) {
            rubricMap[problemId][studentId] = {}
          }
          rubricMap[problemId][studentId][rubricItem.id] = rubricItem.marked;
        });
        this.setState({ rubricMap });
      })
  }

  subHocket(hocketId, Cb) {
    const { db, projectId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets }, () => {
          if (Cb) Cb();
        });
      });
    return this.unsub[hocketId];
  }

  isRegistered(id) {
    const { hockets } = this.state;
    if (!hockets || !hockets[id] || !hockets[id].responses ||
        !hockets[id].responses[0]) return false;
    return isRegistered(hockets[id]);
  }

  setRegisteredProblems(assignment, Cb) {
    const { hockets } = this.state;
    if (this.state.registeredProblems &&
            this.state.registeredProblems.length > 0) return;
    if (!assignment || !assignment.publishedHockets) return;
    const ids = assignment
      .publishedHockets
      .map(hocketStub => hocketStub.hocketId);
    for (let id of ids) {
      if (!hockets[id]) return;
    }
    const registeredProblems = ids.filter((id) => this.isRegistered(id));
    this.setState({ registeredProblems }, () => {
      const openSolutions = {};
      for (let id of registeredProblems) {
        openSolutions[id] = new Set();
      }
      this.setState({ 
        currentProblemId: this.state.registeredProblems[0],
        openSolutions,
      });
      this.subStudentIds();
    });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    this.mousetrapUnset();
  }

  setActiveHocketId(activeHocketId) {
    this.setState({ activeHocketId,
                    hocketSelectionRange: null }, () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.blur();
        }
    });
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, answers, currentProblemId } = this.state;
    const { hockets } = answers[currentProblemId];
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  renderAssignmentArea(classNames='') {
    const { assignment={}, currentProblem, registeredProblems } = this.state;
    if (!assignment) return null;
    const { title='', description='' } = assignment;
    return (
      <div
        ref={ node => {
            this.messagesAreaRef = node;
            this.paneDidMount(node);
        }}
        className={"y-scrollable " + classNames}>
        <h1
          className="assignment-title centered">
          { title || '' }
        </h1>
        <div
          className="centered description-text">
          { description || '' }
        </div>
          <div
            className="flex-container align-center justify-center centered">
            <FlexButton
              onClick={ () => this.prevProblem() }
              disabled={ currentProblem === 0 }>
              <ArrowBackIcon/>
            </FlexButton>
            <span className="flex-none" style={{fontSize: 28, marginLeft: "20px", marginRight: "20px"}}> {  (this.state.currentProblem + 1) } </span>
            <FlexButton
              onClick={ () => this.nextProblem() }
              disabled={ !registeredProblems ||
                currentProblem === registeredProblems.length - 1 }>
              <ArrowForwardIcon/>
            </FlexButton>
          </div>
        { this.renderHocketsArea() }
      </div>
    );
  }

  isInSelectionRange(hocketId) {
    const { answers, hocketSelectionRange, currentProblemId } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = answers[currentProblemId];
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  renderHocketCard(hocket, index) {
    if (!hocket) return null;
    const { activeHocketId } = this.state;
    const style = {background: '#fff'};
    let className = "assignment-card";
    if (index === -1) {
      className += " problem-message";
      return <div
        className={ className }
        key={ hocket.id }
        style={ style }
        onClick={ (e) => {
            if (e.shiftKey) {
              if (activeHocketId) {
                this.setSelectionEnd(hocket.id);
              } else {
                this.setActiveHocketId(hocket.id);
              }
            } else if (activeHocketId) {
              this.setActiveHocketId(hocket.id);
            }
        } }>
        <CardContents 
          db={ this.props.db } 
          projectId={ this.props.projectId } 
          hocket={ hocket }
          codeCell={ this.state.codeCell }
          showIndex
          stripMarkers
          setLang={(lang) => this.setLang(lang)}/>
      </div>
    }
    if (activeHocketId === hocket.id) {
      return <div
        style={ style }
        key={ hocket.id } >
          { this.renderHocketForm() }
        </div>;
    }
    return (
      <div
        className="assignment-card"
        key={ hocket.id }
        style={ getItemStyle(style) }
        onClick={ (e) => {
            if (this.hocketsAreaRef) {
              this.hocketsAreaRef.focus()
            }
        } }>
        <CardContents 
          db={ this.props.db } 
          projectId={ this.props.projectId } 
          hocket={ hocket}
          codeCell={ this.state.codeCell }
          showIndex
          stripMarkers
          setLang={(lang) => this.setLang(lang)}/>
      </div>
    );
  }

  renderSuggestions(suggestions) {
    return <div className="padded-sides"><SuggestionSuperList
      includeSuggestionReplies
      hidePlaceholder={ true }
      label="Suggestion"
      showHocketField={ false }
      readOnly={ true }
      hideLast={ true }
      limit={ 15 }
      projectId={ this.props.projectId }
      db={ this.props.db }
      values={ suggestions }
      updateValues={ (suggestions) => null }/></div>;
  }

  renderMessageForm(studentId, hocketId, singleForm = false,
                    regradeRequest = false, problemId = null) {
    const { db, storage, projectId, currentUser, assignmentId } = this.props;
    const { codeCell, currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    const hocketRef = db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('students')
      .doc(studentId)
      .collection('problems')
      .doc(problemId);
    return (
      <div
        className="message-form-container"
        key={ hocketId }>
        <MessageForm
          db={ db }
          currentUser={ currentUser }
          storage={ storage }
          singleForm={ singleForm }
          regradeRequest={ regradeRequest }
          inCommentsField
          projectId={ projectId }
          hocketId={ hocketId }
          studentId={ studentId }
          assignmentId={ assignmentId }
          problemId={ problemId }
          clearActiveHocketId={ () => this.setActiveHocketId(null) }
          deleteMessages={ () => this.deleteMessages(studentId, problemId, regradeRequest) }
          mainQuillRef={ this.mainQuillRef }
          toggleJuniper={ () => this.toggleJuniper() }
          codeCell={ codeCell }
          setMousetrap={ () => this.setMousetrap() }
          hocketRef={ hocketRef }
          />
      </div>
    );
  }

  deleteMessages(studentId, problemId, regradeRequest = false) {
    if (regradeRequest) {
      const { activeHocketId, regradeRequests } = this.state;
      const idx = regradeRequests[problemId][studentId].map(hocket => hocket.id).indexOf(activeHocketId);
      regradeRequests[problemId][studentId].splice(idx, 1);
      this.setState( { regradeRequests }, () => {
        this.studentAnswerRef
        .collection('students')
        .doc(studentId)
        .collection('problems')
        .doc(problemId)
        .set({ regradeRequestThread: regradeRequests[problemId][studentId] }, {merge: true})
        .catch(console.error);
      })
    } else {
      const { activeHocketId } = this.state;
      if (!activeHocketId) return;
      this.studentAnswerRef
        .collection('students')
        .doc(studentId)
        .collection('problems')
        .doc(problemId)
        .set({ comments: null }, {merge: true})
        .catch(console.error)
        .then();
    }
  }

  toggleJuniper() {
    const { juniperOpen } = this.state;
    this.setState({ juniperOpen: !juniperOpen }, () => {
      //if (juniperOpen) this.juniperRef.current.codeMirror.focus();
    });
  }

  renderJuniper() {
    const { juniperOpen, juniperHasRendered, codeCell } = this.state;
    const style = {};
    if (!juniperOpen && !juniperHasRendered) return null;
    if (!juniperHasRendered) this.setState({ juniperHasRendered: true });
    if (!juniperOpen) style.display = "none";
    return <tr className="assignment-row juniper-row" style={ style }>
            <td>
              <div className="assignment-view-juniper-container juniper-container">
                <CollapsibleJupyterCell
                  ref={ this.juniperRef }
                  language={ codeCell }/>
              </div>
            </td>
          </tr>;
  }

  toggleEdit(studentId, {addNew=false}={}) {
    const { editRubricItem, assignment, currentProblemId } = this.state;
    const { rubric=[] } = assignment;
    const problemId = currentProblemId
    const thisRubric = rubric[problemId] || [];
    // deep copy:
    const newRubric = thisRubric.map(item => {return {...item};});
    if (editRubricItem) {
      this.setState({ editRubricItem: null, tempRubric: [] });
    } else {
      this.setState({ editRubricItem: studentId });
      this.setState({ tempRubric: newRubric });
      if (addNew && thisRubric.length === 0) {
        this.addRubricItem(studentId, problemId);
      }
    }
  }

  addRubricItem(studentId, problemId) {
    const { editRubricItem, currentProblemId } = this.state;
    let { tempRubric } = this.state;
    if (!problemId) problemId = currentProblemId;
    if (!editRubricItem) this.setState({editRubricItem: studentId});
    if (!tempRubric) tempRubric = [];
    tempRubric.push({id: uuid(), text: ""});
    this.setState({ tempRubric });
  }

  saveRubricItems(problemId = null, toggle = true) {
    const { assignment, tempRubric, currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    const problemRubric = tempRubric.filter(item => item.text.length);
    if (assignment.rubric) {
      assignment.rubric[problemId] = problemRubric;
    } else {
      assignment.rubric = {[problemId]: problemRubric};
    }
    return this.assignmentRef.set(assignment, {merge: true}).then( () => {
      if (toggle) {
        this.toggleEdit();
      }
      this.setState({ rubricChangesPending: false });
    }).catch(console.error);
  }

  updateRubricItem(id, text, studentId, problemId = null) {
    const { tempRubric=[], currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    for (let k = 0; k < tempRubric.length; k++) {
      const item = tempRubric[k];
      if (item.id === id) {
        tempRubric[k].text = text;
        break;
      }
    }
    this.setState({ tempRubric, rubricChangesPending: true });
  }

  renderRubricLabel(itemId, text, studentId, problemId) {
    const { currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    if (this.state.editRubricItem === studentId) {
      return <Input
        fullWidth
        placeholder="new rubric item"
        value={ text }
        onChange={ (e) => this.updateRubricItem(itemId, e.target.value, studentId, problemId) } />
    } else {
      return text;
    }
  }

  shortcutMarkRubricItem(i) {
    const { rubricMap, activeStudentId, assignment,
            submissionViewStudentId, currentProblemId } = this.state;
    if (submissionViewStudentId || !activeStudentId) return;
    const problemId = currentProblemId;
    if (!assignment.rubric || !assignment.rubric[problemId]) return;
    if (i > assignment.rubric[problemId].length) return;
    const itemId = assignment.rubric[problemId][i-1].id;
    this.setRubricItem(
      currentProblemId,
      activeStudentId,
      itemId,
      !rubricMap?.[problemId]?.[activeStudentId]?.[itemId]
    );
  }

  setRubricItem(problemId, studentId, itemId, marked) {
    const { db } = this.props;
    const { rubricMap } = this.state;
    const batch = db.batch();
    if (!rubricMap?.[problemId]) batch.set(
      this.assignmentRef
        .collection('rubric-map')
        .doc(problemId),
        { id: problemId }
    )
    if (!rubricMap?.[problemId]?.[studentId]) batch.set(
      this.assignmentRef
        .collection('rubric-map')
        .doc(problemId)
        .collection('students')
        .doc(studentId),
        { id: studentId }
    )
    batch.set(
      this.assignmentRef
        .collection('rubric-map')
        .doc(problemId)
        .collection('students')
        .doc(studentId)
        .collection('rubric-items')
        .doc(itemId), {
          id: itemId,
          marked
        }
    );
    batch.commit().catch(console.error);
  }

  renderRubricItem(studentId, itemId, text, problemId=null) {
    const { rubricMap = {}, currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    return <FormControlLabel
      key={ problemId + studentId + itemId }
      control={ <Checkbox checked={ !!(rubricMap[problemId] && rubricMap[problemId][studentId] && rubricMap[problemId][studentId][itemId]) } color="primary" onClick={e => e.stopPropagation()} onChange={() => this.rubricCheckUpdate(problemId, studentId, itemId)} inputProps={{ 'aria-label': 'Select rubric item' }}/> }
      label={ this.renderRubricLabel(itemId, text, studentId) }/>;
  }

  rubricCheckUpdate(problemId, studentId, itemId) {
    const { rubricMap = {}, rubricChangesPending } = this.state;
    let promise = Promise.resolve();
    if (rubricChangesPending) {
      promise = this.saveRubricItems(null, false);
    }
    promise.then( () => {
      this.setRubricItem(problemId, studentId, itemId,
        !rubricMap?.[problemId]?.[studentId]?.[itemId]
      )
    });
  }

  viewSubmission(studentId) {
    this.subStudentAnswers(
      [studentId],
      this.state.assignment.publishedHockets.map(hocket => hocket.hocketId),
    );
    this.setState({ submissionViewStudentId: studentId });
  }

  renderSubmission() {
    const { answers, submissionViewStudentId, hockets,
            openProblems, registeredProblems } = this.state;
    if (!submissionViewStudentId) return;
    const answerCards = [];
    for (let [idx, problemId] of registeredProblems.entries()) {
      if (answers[problemId] && answers[problemId][submissionViewStudentId]) {
        answerCards.push(<h3
          className="centered"
          style={{cursor: "pointer"}}
          key={"problem-" + idx + "-header"}
          onClick={() => {
            const { openProblems } = this.state;
            if (openProblems.has(problemId)) {
              openProblems.delete(problemId);
            } else {
              openProblems.add(problemId);
            }
            this.setState({ openProblems });
          }}>
          {(openProblems.has(problemId) ? "▾ " : "▸ " ) + "Problem " + (idx + 1)}
        </h3>);
        if (openProblems.has(problemId)) {
          answerCards.push(this.renderHocketCard(hockets[problemId], -1));
        }
        answerCards.push(
          this.renderStudentCard(submissionViewStudentId, "", problemId)
        );
      }
    }
    return (<div className="student-submission-card">
      { answerCards }
      <div style={{textAlign: "center"}}>
        <Button 
          style={{marginTop: "20px"}}
          variant="outlined"
          onClick={() => this.publishOne(submissionViewStudentId)}
          >
            Publish Feedback for This Student
        </Button>
      </div>
    </div>);
  }

  addComments(studentId, problemId=null) {
    const { comments, currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    if (comments[problemId][studentId]) {
      this.setActiveHocketId(comments[problemId][studentId].id);
      return;
    }
    const hocket = Hocket();
    this.studentAnswerRef
      .collection('students')
      .doc(studentId)
      .collection('problems')
      .doc(problemId)
      .set({comments: hocket}, {merge: true})
      .then(() => this.setActiveHocketId(comments[problemId][studentId].id))
      .catch(console.error);
  }

  getPoints(studentId, problemId = null) {
    const { currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    const points = (text) => {
      const regex = /\[(-?[0-9.]*?)\]/;
      const result = regex.exec(text);
      if (!result) return 0;
      try {
        return parseFloat(result[1])
      } catch {
        return 0;
      }
    }
    const selected = (item) => {
      if (!rubricMap) return false;
      if (!rubricMap[problemId]) return false;
      if (!rubricMap[problemId][studentId]) return false;
      return rubricMap[problemId][studentId][item.id];
    }
    const { assignment={}, rubricMap } = this.state;
    let { rubric } = assignment;
    if (!rubric) rubric = [];
    const currentRubric = rubric[problemId] || [];
    const pointsScored = currentRubric
      .filter(selected)
      .map(item => points(item.text))
      .reduce((a,b) => a + b, 0);
    const totalPoints = currentRubric
      .map( item => points(item.text) )
      .reduce((a, b) => a + Math.max(0, b), 0);
    const anyMarked = currentRubric.filter(selected).length > 0;
    return {
      pointsScored,
      totalPoints,
      anyMarked
    };
  }

  renderRubricArea(studentId, problemId=null) {
    const { assignment={}, editRubricItem, tempRubric, currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    let { rubric } = assignment;
    if (!rubric) rubric = [];
    let currentRubric;
    if (editRubricItem === studentId) {
      currentRubric = tempRubric || [];
    } else {
      currentRubric = rubric[problemId] || [];
    }
    const { pointsScored, totalPoints } = this.getPoints(studentId, problemId);
    return (
      <div key={studentId + problemId + "-rubric-area"}>
        <div className="rubric-tool">
          <Tooltip enterDelay={250} title="all of student's solutions">
            <OpenWithIcon
              style={{transform: "scale(0.7)"}}
              onClick={ () => this.viewSubmission(studentId) }
              size="small"/>
          </Tooltip>
        </div>
        <div className="rubric-tool">
          <Tooltip
            enterDelay={250}
            title="add comment">
            <CommentIcon
              style={{transform: "scale(0.7)"}}
              onClick={ () => this.addComments(studentId, problemId) }
              size="small"/>
          </Tooltip>
        </div>
        <div className="rubric-tool">
          <Tooltip enterDelay={250} title="edit rubric">
            <EditIcon
              style={{transform: "scale(0.7)"}}
              onClick={ e => this.toggleEdit(studentId, {addNew: true}) }
              size="small"/>
          </Tooltip>
        </div>
        { totalPoints ? <div className="rubric-points">{ `${pointsScored}/${totalPoints}`}</div> : null }
        <FormGroup className="rubric-group" style={{marginLeft: "10px", marginTop: "12px", marginBottom: "8px"}}>
        { currentRubric.map(item => this.renderRubricItem(studentId, item.id, item.text, problemId)) }
        </FormGroup>
        {editRubricItem === studentId || currentRubric.length === 0 ? <Tooltip title="add rubric item" enterDelay={ 250 }><Button onClick={ () => this.addRubricItem(studentId, problemId) } variant="outlined" style={{marginTop: "8px", marginBottom: "8px", marginRight: "8px"}}>
          +
        </Button></Tooltip> : null }
        {editRubricItem === studentId ? <Button onClick={ () => this.saveRubricItems(problemId) } variant="outlined" style={{marginTop: "8px", marginBottom: "8px"}}>
          Save
        </Button> : null }
      </div>
    );
  }

  createRegradeResponseHocket(studentId, problemId=null) {
    const { currentProblemId } = this.state;
    if (!problemId) problemId = currentProblemId;
    const hocket = Hocket([], [], true);
    // ^ 'safe' hocket, which can go in an array because it uses
    // the correct 'now' function
    hocket.fromInstructionalStaff = true;
    this.studentAnswerRef
      .collection('students')
      .doc(studentId)
      .collection('problems')
      .doc(problemId)
      .set({regradeRequestThread: arrayUnion(hocket)}, {merge: true})
      .then(() => this.setActiveHocketId(hocket.id))
      .catch(console.error);
  }

  numStudentCards() {
    const { studentIds, answers={}, currentProblemId } = this.state;
    const problemId = currentProblemId;
    return studentIds.filter( studentId =>
      answers[problemId] && answers[problemId][studentId]
    ).length;
  }

  shouldSkip(studentId, problemId) {
    const { openSolutions } = this.state;
    const submitted = (thread) => {
      if (!thread || thread.length === 0) return false;
      const [lastMessage] = thread.slice(-1);
      return lastMessage.fromInstructionalStaff && lastMessage.submitted;
    }
    const hideBecauseGraded = () => this.state.hideGraded && 
            !openSolutions[problemId]?.has(studentId) &&
            this.getPoints(studentId, problemId).anyMarked;
    const hideBecauseRegrade = () => this.state.showOnlyRegrades && 
            !this.state.regradeRequests?.[problemId]?.[studentId];
    const hideBecauseResolved = () => this.state.showOnlyOpenRegrades && 
          submitted(this.state.regradeRequests[problemId][studentId]);
    return ( hideBecauseGraded() || 
             hideBecauseRegrade() || 
             hideBecauseResolved() );
  }

  renderStudentCard(studentId, index, problemId = null) {
    const { submissionViewStudentId, activeStudentId,
            openSolutions, currentProblemId } = this.state;
    let onSubmissionCard = true;
    if (!problemId) {
      onSubmissionCard = false;
      problemId = currentProblemId;
    }
    const { answers={}, regradeRequests,
            comments, activeHocketId } = this.state;
    if (this.shouldSkip(studentId, problemId)) return null;
    if (!answers[problemId] || !answers[problemId][studentId]) return null;
    const hocketCards = [];
    for (let i = 0; i < answers[problemId][studentId].length; i++) {
      const hocket = answers[problemId][studentId][i];
      hocketCards.push(this.renderHocketCard(
        hocket, i
      ));
    }
    hocketCards.push(this.renderRubricArea(studentId, problemId));
    const commentHocket = comments[problemId][studentId];
    if (commentHocket) {
      if (activeHocketId === commentHocket.id &&
        (onSubmissionCard || submissionViewStudentId === null)
      ) {
        hocketCards.push(this.renderMessageForm(studentId, commentHocket.id, true, false, problemId));
      } else {
        hocketCards.push(
          <Tooltip
            title={ "Feedback" }
            enterDelay={ 250 }
            placement="top"
            key={ commentHocket.id }>
            <div
              className={"assignment-card"}
              style={ getItemStyle() }
              onClick={ (e) => {
                this.setActiveHocketId(commentHocket.id);
                if (this.hocketsAreaRef) {
                  this.hocketsAreaRef.focus()
                }
              } }>
              <CardContents 
                db={ this.props.db } 
                projectId={ this.props.projectId } 
                codeCell={ this.state.codeCell }
                hocket={ commentHocket }
                showIndex
                setLang={(lang) => this.setLang(lang)}/>
            </div>
          </Tooltip>
        );
      }
    }
    const regradeRequestHockets = regradeRequests[problemId][studentId] || [];
    if (regradeRequestHockets.length) {
      hocketCards.push(...regradeRequestHockets.map(regradeRequestHocket => {
        if (regradeRequestHocket.fromInstructionalStaff) {
          if (regradeRequestHocket.id === activeHocketId) {
            return this.renderMessageForm(studentId, regradeRequestHocket.id, true, true, problemId);
          }
          return (
            <Tooltip
              title={ "regrade request response from instructional staff" }
              enterDelay={ 250 }
              key={ regradeRequestHocket.id }
              placement="top">
                <div
                className="assignment-card grading-from-instructional-staff"
                style={ getItemStyle() }
                onClick={ (e) => {
                  this.setActiveHocketId(regradeRequestHocket.id);
                  if (this.hocketsAreaRef) {
                    this.hocketsAreaRef.focus()
                  }
                } }>
                <CardContents 
                  db={ this.props.db } 
                  projectId={ this.props.projectId } 
                  hocket={ regradeRequestHocket }
                  codeCell={ this.state.codeCell }
                  setLang={(lang) => this.setLang(lang)}/>
                <div className="regrade-bubble instructional-staff">
                  I
                </div>
              </div>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={ "regrade request message from student" }
              enterDelay={ 250 }
              placement="top"
              key={ regradeRequestHocket.id }>
              <div
                className="assignment-card grading-from-student"
                style={ getItemStyle() }>
                <CardContents 
                  db={ this.props.db } 
                  projectId={ this.props.projectId } 
                  hocket={ regradeRequestHocket }
                  showIndex
                  setLang={(lang) => this.setLang(lang)}/>
                <div className="regrade-bubble student-message-bubble">
                  S
                </div>
              </div>
            </Tooltip>
          );
        }
      }));
      const [regradeRequestHocket] = regradeRequestHockets.filter(hocket => hocket.fromInstructionalStaff).slice(-1);
      hocketCards.push(
        <div key={ studentId + problemId + "regrade-area" } style={{textAlign: "center"}}>
          <Tooltip
            title="add regrade request response"
            enterDelay={ 250 }
            key={ studentId + problemId + "add-button" }>
            <Button
              onClick={ () => this.createRegradeResponseHocket(studentId, problemId) }
              variant="outlined"
              size="small"
              style={{marginTop: "15px", marginRight: "8px"}}>
              +
            </Button>
          </Tooltip>
          { regradeRequestHocket && <Button
            color="primary"
            disabled={ !regradeRequestHocket || regradeRequestHocket.submitted || this.state.submittingRegradeRequest }
            variant="contained"
            style={{ marginTop: "15px" }}
            size="small"
            key={ studentId + problemId + "submit-button"}
            onClick={ () => this.sendRegradeResponse(studentId) }>
            { (!regradeRequestHocket || regradeRequestHocket.submitted) ?
                "Submitted" : (
                this.state.submittingRegradeRequest ? "Submitting..." : "Submit"
              ) }
          </Button> }
        </div>
      );
    }
    const active = ( studentId === activeStudentId ? " active" : "");
    return (
      <div
        className={"student-answer-card" + active}
        onClick={() => this.setState({ activeStudentId: studentId })}
        key={ studentId + problemId }
        style={{position: "relative"}}>
        <div
          id={ studentId }
          style={{position: "absolute", top: "-10px"}}
        />
        <Tooltip
          title={ this.getField(studentId, 'displayName') }
          enterDelay={ 6000 }
          placement="top">
          <h3 style={{color: "gray", cursor: "pointer", marginTop: "0px", marginBottom: "12px"}}
            onClick={ () => this.toggleSolution(studentId, problemId) }>
            { (openSolutions[problemId]?.has(studentId) ? "▾ " : "▸ " ) + "Solution" + 
              (onSubmissionCard ? "" : " " + (index+1)) }
          </h3>
        </Tooltip>
        { (this.state.groupAssignment && studentId === activeStudentId) ? 
          <div className="group-assignment-select">
            <Select
              multiple
              value={this.state.collaborators?.[activeStudentId] || []}
              onChange={(event) => this.handleGroupAssignmentChange(studentId, event)}>
              { [...Object.keys(this.state.students)]
                .sort((s1, s2) => {
                  if (this.state.students[s1]?.displayName < 
                        this.state.students[s2]?.displayName ) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map( studentId => {
                return <MenuItem 
                    value={studentId}
                    key={studentId}>
                  { this.state.students[studentId]?.displayName }
                </MenuItem>
              })}
            </Select>
          </div> 
          : null }
        { openSolutions[problemId]?.has(studentId) ? hocketCards : null }
      </div>
    );
  }

  toggleSolution(studentId, problemId) {
    const { openSolutions } = this.state;
    if (openSolutions[problemId].has(studentId)) {
      openSolutions[problemId].delete(studentId);
    } else if (openSolutions[problemId]) {
      openSolutions[problemId].add(studentId);
    }
    this.setState({ openSolutions });
  }

  renderSettings() {
    return (<ThemeProvider theme={ theme }>
        <div className="help-info">
          <h1>Settings and Tools</h1>
          <ul>
            <li> By default, you cannot see the submission of any student who has an extended deadline which has not yet passed. You can select this box to override that setting and see all submissions. </li>
              <FormGroup>
              <FormControlLabel
                control={ <WhiteCheckbox checked={ !!(this.state.ignoreDeadlines) } color="primary" inputProps={{ 'aria-label': 'Ignore deadlines' }} onChange={ e => this.setState({ ignoreDeadlines: e.target.checked })}/> }
                label={ "Ignore deadlines" }/>
              </FormGroup>
            <li> To see only ungraded solutions </li>
              <FormGroup>
              <FormControlLabel
                control={ <WhiteCheckbox checked={ !!(this.state.hideGraded) } color="primary" inputProps={{ 'aria-label': 'Hide graded' }} onChange={ e => this.setState({ hideGraded: e.target.checked })}/> }
                label={ "Hide graded solutions" }/>
              </FormGroup>
             <li> To see only open regrade requests </li>
              <FormGroup>
              <FormControlLabel
                control={ <WhiteCheckbox checked={ !!(this.state.showOnlyRegrades) } color="primary" inputProps={{ 'aria-label': 'Hide graded' }} onChange={ e => this.setState({ showOnlyRegrades: e.target.checked })}/> }
                label={ "Show only regrade requests" }/>
              {this.state.showOnlyRegrades ? <FormControlLabel
                control={ <WhiteCheckbox checked={ !!(this.state.showOnlyOpenRegrades) } color="primary" inputProps={{ 'aria-label': 'Hide graded' }} onChange={ e => this.setState({ showOnlyOpenRegrades: e.target.checked })}/> }
                label={ "Show only open regrade requests" }/> : null }
              </FormGroup>                         
            <li> To give multiple students credit for the same submission </li>
            <FormGroup>
            <FormControlLabel
              control={ <WhiteCheckbox checked={ !!(this.state.groupAssignment) } color="primary" inputProps={{ 'aria-label': 'Group Assignmnt' }} onChange={ e => this.setState({ groupAssignment: e.target.checked })}/> }
              label={ "Group assignment" }/>
            </FormGroup>              
            <li> To send all marked rubric items and comments to students:
            </li>
          </ul>
          <div style={{textAlign: "center"}}>
            <Button
              color="primary"
              variant="outlined"
              onClick={ () => this.publishFeedback() }>
              Publish
            </Button>
          </div>
          <ul>
            <li> To find a particular student's submission:
            </li>
          </ul>
          <div style={{textAlign: "center"}}>
            <Button
              color="primary"
              variant="outlined"
              style={{marginBottom: "20px"}}
              onClick={ () => this.setState({ showStudents: !this.state.showStudents }) }>
              Find Student Submission
            </Button>
            { this.state.showStudents ? 
              this.state.studentIds
              .sort( (id1, id2) => this.state.students[id1].displayName < this.state.students[id2].displayName ? -1 : 1 )
              .map( id => (
                <Button 
                  key={"student-settings-" + id}
                  style={{marginLeft: "30px", display: "block"}}
                  onClick={() => this.viewSubmission(id)}>
                  { this.state.students[id].displayName }
                </Button>
              ))
              : null
            }
          </div>
        </div>
      </ThemeProvider>
    );
  }

  problemHockets(problemId) {
    const { assignment, hockets={} } = this.state;
    if (!problemId) problemId = this.currentProblemId();
    const assignmentHocketIds = assignment.publishedHockets.map(
      hocketStub => hocketStub.hocketId
    );
    const registeredIds = assignmentHocketIds.filter((id) => this.isRegistered(id));
    const problemIndexRegistered = registeredIds.indexOf(problemId);
    const problemIndex = assignmentHocketIds.indexOf(problemId);
    const prevIndex = (problemIndexRegistered === 0 ? 0 :
      assignmentHocketIds.indexOf(registeredIds[problemIndexRegistered - 1]) + 1
    );
    let j = prevIndex;
    while (isNote(hockets[assignmentHocketIds[j]])) j++;
    const firstProblemStatementIndex = j;
    let k = problemIndex + 1;
    while (isNote(hockets[assignmentHocketIds[k]])) k++;
    const solutionEndIndex = k;
    return {
      problemHockets: assignmentHocketIds
                        .slice(firstProblemStatementIndex, problemIndex + 1)
                        .map( id => hockets[id] ),
      solutionHockets: assignmentHocketIds
                        .slice(problemIndex + 1, solutionEndIndex)
                        .map( id => hockets[id] ),
    };
  }

  toggleOpenAll() {
    const { studentIds, openSolutions, currentProblemId } = this.state;
    if (openSolutions[currentProblemId].size > 0) {
      openSolutions[currentProblemId] = new Set();
    } else {
      openSolutions[currentProblemId] = new Set(studentIds);
    }
    this.setState({ openSolutions });
  }

  renderHocketsArea() {
    const { studentIds, cardLimit, answers, ignoreDeadlines, deadlines,
            showProblem, showSolution, currentProblemId } = this.state;
    if (!studentIds) return null;
    const { problemHockets, solutionHockets } = this.problemHockets(currentProblemId);
    const problemCard = <div id="problem-statement" className="problem-statement" key="problem-statement">
      { problemHockets.map( hocket =>
          this.renderHocketCard(hocket, -1) ) }
    </div>;
    const solutionCard = solutionHockets.length ? <div 
        className="problem-statement" 
        key="solution-statement">
        { solutionHockets.map( hocket =>
            this.renderHocketCard(hocket, -1) ) }
      </div> : null;
    let numGraded = 0;
    let numSubmissions = 0;
    if (answers[currentProblemId]) {
      numSubmissions = Object.keys(answers[currentProblemId]).length;
      numGraded = Object.keys(answers[currentProblemId])
        .filter( studentId => this.getPoints(studentId, currentProblemId).anyMarked)
        .length;
    }
    const studentCards = [];
    let idx = 0;
    const now = new Date();
    for (let studentId of studentIds) {
      if (!ignoreDeadlines && deadlines[studentId] && deadlines[studentId].toDate() > now) {
        continue;
      }
      const studentCard = this.renderStudentCard(studentId, idx);
      if (studentCard) {
        studentCards.push(studentCard);
        idx++;
      }
    }
    return (
      <div
        className="hockets-area"
        tabIndex={ -1 }
        ref={ (node) => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
        <h3 className="centered" style={{color: "grey", cursor: "pointer"}}
          onClick={ () => this.setState({
            showProblem: !showProblem
          }) }>
        { (showProblem ? "▾ " : "▸ " ) + "Problem" } </h3>
        { showProblem ? problemCard : null }
        { solutionCard ? 
            <h3 
              className="centered" 
              style={{marginTop: "60px", color: "grey"}} 
              onClick={ () => 
                  this.setState({showSolution: !this.state.showSolution}) 
              }>
                { (showSolution ? "▾ " : "▸ " ) + "Reference Solution" } 
            </h3> : null }
        { showSolution ? solutionCard : null }
        <h3 className="centered" style={{color: "grey", cursor: "pointer", marginTop: "60px"}} onClick={() => this.toggleOpenAll() }> {"Solutions"  + (numSubmissions > 0 ? ` (${numGraded}/${numSubmissions} graded)` : "")} </h3>
        <div className="pad-bottom">
          { cardLimit - SOLUTION_RENDER_MAX > 0 ?
            <div className="centered big-pad-top big-pad-bottom">
            <Button
              onClick={ () => this.setState({
                cardLimit: Math.max(0, cardLimit - SOLUTION_RENDER_STEP)
              }) }
              variant="outlined">
              Previous submissions
            </Button>
          </div> : null }
          { studentCards.slice(
              Math.max(0, cardLimit - SOLUTION_RENDER_MAX),
              cardLimit
          ) }
          <div className="centered big-pad-top big-pad-bottom">
            <Button
              onClick={ () => this.setState({cardLimit: cardLimit + SOLUTION_RENDER_STEP}) }
              disabled={ cardLimit >= studentCards.length }
              variant="outlined">
              More submissions
            </Button>
          </div>
        </div>
      </div>
    );
  }

  showResultsTable() {
    this.setState({ resultsTable: true });
  }

  subCollaborators() {
    const { db, projectId, assignmentId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('students')
      .onSnapshot(snap => {
        const collaborators = {};
        snap.docs.map(doc => {
          const data = doc.data();
          if (data?.collaborators) {
            collaborators[data.id] = data.collaborators;
          }
        })
        this.setState({ collaborators });
      });
  }

  subStudents() {
    const { db, projectId } = this.props;
    return db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('students')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        const studentIds = data.userIds;
        for (let key in studentIds) {
          this.unsub[key] = db
            .collection('users')
            .doc(key)
            .onSnapshot(snap => {
              const student = snap.data();
              if (!student) return null;
              const { students } = this.state;
              students[student.id] = student;
              this.setState({ students });
            });
        }
      }).catch(console.error);
  }

  getField(studentId, field) {
    const { students } = this.state;
    if (students[studentId] && students[studentId][field]) {
      return students[studentId][field];
    }
    return "";
  }

  compilePointsData() {
    const { rubricMap, students, registeredProblems } = this.state;
    if (!Object.keys(students).length) this.subStudents();
    if (!students) return;
    const totalMap = {};
    const rows = [];
    for (let problemId in rubricMap)  {
      for (let studentId in rubricMap[problemId]) {
        const { pointsScored, totalPoints } = this.getPoints(studentId, problemId);
        const problemNumber = registeredProblems.indexOf(problemId) + 1;
        rows.push({
          problem: problemNumber,
          studentName: this.getField(studentId, 'displayName'),
          studentId: this.getField(studentId, 'studentId'),
          prismiaId: studentId,
          scored: pointsScored,
          total: totalPoints,
        });
        totalMap[problemNumber] = totalPoints;
      }
    }
    const sortedRows = [...rows].sort((a,b) => {
      if (typeof a.problem === 'number' && typeof b.problem === 'string') {
        return -1;
      } else if (typeof b.problem === 'number' && typeof a.problem === 'string') {
        return 1;
      }
      if (typeof a.problem === 'number') {
        if (a.problem < b.problem) {
          return -1;
        } else if (a.problem > b.problem)
          return 1;
      }
      if (a.studentName < b.studentName) {
        return -1;
      } else if (a.studentName > b.studentName) {
        return 1;
      }
      return 0;
    });
    return sortedRows;
  }

  compileRubricData() {
    const { rubricMap, assignment, registeredProblems } = this.state;
    const rows = [];
    for (let problemId in rubricMap)  {
      for (let studentId in rubricMap[problemId]) {
        for (let rubricId in rubricMap[problemId][studentId]) {
          if (rubricMap[problemId][studentId][rubricId]) {
            const problemNumber = registeredProblems.indexOf(problemId) + 1;
            let rubricDescription = '';
            try {
              rubricDescription = assignment.rubric[problemId].find(r => r.id === rubricId).text;
            }
            catch {}
            if (rubricDescription) {
              rows.push({
                problem: problemNumber,
                studentName: this.getField(studentId, 'displayName'),
                studentId: this.getField(studentId, 'studentId'),
                prismiaId: studentId,
                rubricItem: rubricDescription,
              });
            }
          }
        }
      }
    }
    return rows;
  }

  renderResultsTable() {
    const { resultsView } = this.state;
    const rowData = resultsView === 'points' ? this.compilePointsData() : this.compileRubricData();
    const columnDefs = [{
      headerName: "Problem",
      field: "problem",
      type: "numericColumn",
    }, {
      headerName: "Student",
      field: "studentName",
    },
    {
      headerName: "ID",
      field: "studentId",
      hide: true,
    }];
    const pointsColumnDefs = [...columnDefs,
      {
        headerName: "Points",
        field: "scored",
        type: "numericColumn",
      }, {
        headerName: "Out of",
        field: "total",
        type: "numericColumn",
      }
    ]
    const rubricColumnDefs = [...columnDefs,
      {
        headerName: "Rubric Item",
        field: 'rubricItem',
      }
    ];
    const pointsExportColumnKeys = ['problem', 'studentName', 'studentId', 'scored', 'total'];
    const rubricExportColumnKeys = ['problem', 'studentName', 'studentId', 'rubricItem'];
    return <div className="results-table">
      <h3 className="centered">Results</h3>
        <div className='centered'>
          <ButtonGroup variant='outlined'>
            <Button
              onClick={() => this.setState({resultsView: 'points'}, () => 
                  setTimeout(() => this.resultsTableRef.current.updateRows(), 2000))
                }>
                Points
            </Button>
            <Button
              onClick={() => this.setState({resultsView: 'rubric'}, () => 
                  setTimeout(() => this.resultsTableRef.current.updateRows(), 2000))
                }>
                Rubric items
            </Button>
          </ButtonGroup>
        </div>
      { this.state.resultsView === 'points' ?
      <DataTable
        ref={ this.resultsTableRef }      
        exportColumnKeys={ pointsExportColumnKeys }
        columnDefs={ pointsColumnDefs }
        rowData={ rowData }
        height={"calc(100% - 160px)"}
        sizeToFit
      /> : 
      <DataTable
        ref={ this.resultsTableRef }
        exportColumnKeys={ rubricExportColumnKeys }
        columnDefs={ rubricColumnDefs }
        rowData={ rowData }
        height={"calc(100% - 160px)"}
        sizeToFit
      />
      }
    </div>;
  }

  sendRegradeResponse(studentId, problemId=null) {
    const { db, projectId, assignmentId, functions } = this.props;
    const { regradeRequests, currentProblemId } = this.state;
    this.setState({ submittingRegradeRequest: true });
    if (!problemId) problemId = currentProblemId;
    const sendRegradeResponse = functions
        .httpsCallable('sendRegradeResponse');
    const data = { projectId, assignmentId, studentId, problemId };
    if (!regradeRequests || !regradeRequests[problemId] || !regradeRequests[problemId][studentId].length) return console.log('no messages to send');
    sendRegradeResponse(data).then(() => {
      this.studentAnswerRef
        .collection('students')
        .doc(studentId)
        .collection('problems')
        .doc(problemId)
        .set({ regradeRequestThread: regradeRequests[problemId][studentId].map(hocket => {
          return {...hocket, submitted: true}
        })}, {merge: true})
        .then(() => this.setState({ submittingRegradeRequest: false }))
        .then(() => {
          db.collection('projects')
            .doc(projectId)
            .collection('assignments')
            .doc(assignmentId)
            .collection('regrade-requests')
            .doc(studentId + '--' + problemId)
            .set({ resolved: true }, { merge: true })
            .catch(console.error);
        })
        .catch(console.error);
    });
  }

  handleProblemUrl() {
    const { location } = this.props;
    if (location.pathname.endsWith('/regrades')) {
      this.setState({ 
        showOnlyRegrades: true, 
        showOnlyOpenRegrades: true 
      });
      return;
    }
    let pathComponents = location.pathname.split('/problems/')
    if (pathComponents && pathComponents.length > 1) {
      tryUntilSuccess(() => {
        const [problemId, studentId] = pathComponents[1].split('/students/');
        try {
          this.setState({ 
            currentProblemId: problemId,
            currentProblem: this.state.registeredProblems.indexOf(problemId),
          });
          if (studentId) {
            const { openSolutions } = this.state;
            openSolutions[problemId].add(studentId);
            this.setState({openSolutions});
            const element = document.getElementById(studentId);
            if (element) {
              element.scrollIntoView();
              return isInViewport(element);
            }
          } else {
            return true;
          }
        } catch {
          return false;
        }
      }, {wait: 250});
    }
  }

  publishFeedback() {
    const { projectId, assignmentId, functions } = this.props;
    const { studentIds } = this.state;
    const publishFeedback = functions
        .httpsCallable('publishFeedbackStudentNew');
    this.setState({ showSettings: false });
    NotificationManager.info("Publishing...");
    const promises = [];
    for (let studentId of studentIds) {
      const data = { 
        projectId, 
        assignmentId, 
        studentId 
      };
      promises.push(publishFeedback(data));
    }
    Promise.all(promises).then(() => NotificationManager.success("Feedback Published!"));
  }

  async publishOne(studentId) {
    const { projectId, assignmentId, functions } = this.props;
    const publishFeedback = functions
        .httpsCallable('publishFeedbackStudentNew');
    this.setState({ showSettings: false });
    NotificationManager.info("Publishing...");
    const data = { projectId, assignmentId, studentId };
    await publishFeedback(data);
    NotificationManager.success("Feedback Published!");
  }

  render() {
    updateTitleBar('Assignments');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings, resultsTable, codeCell, submissionViewStudentId } = this.state;
    const blur = showHelp || showSettings || resultsTable || submissionViewStudentId ? " blur" : "";
    const classes = showHelp || showSettings ? "full-height blur" : "full-height";
    const tools = [{
      icon: <HelpOutlineOutlinedIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help screen",
      disabled: false,
      hide: false,
    }, {
      icon: <SettingsIcon/>,
      onClick: () => this.setState({ showSettings: true }),
      tooltipTitle: "Show tools and settings",
      disabled: false,
      hide: false,
    }, {
      icon: <AssessmentOutlinedIcon/>,
      onClick: () => this.showResultsTable(),
      tooltipTitle: "Results table",
      disabled: false,
      hide: false,
    }, {
      icon: <CodeIcon/>,
      onClick: () => this.toggleJuniper(),
      tooltipTitle: "Toggle code cell",
      disabled: false,
      hide: !codeCell,
    }];
    const navHeight = (width) => 62 + (width < mobileThreshold ? 48 : 0);
    const table = (width, blur) => (<div className={classes + (blur ? " blur" : "")}>
      <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
      <div className="flow-root">
        <SidebarButtonPanel
          mobile={ width < mobileThreshold }
          tools={ tools }/>
      </div>
        <div style={{position: "relative", height: `calc(100% - ${navHeight(width)}px)`}}>
          { this.renderAssignmentArea(width < mobileThreshold ? "border-top" : "") }
          { this.renderJuniper() }
        </div>
    </div>);
    const helpCard = showHelp ? this.helpInfo() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    const submissionCard = submissionViewStudentId ? this.renderSubmission() : null;
    const resultsTableCard = resultsTable ? this.renderResultsTable() : null;
    const maskCover = (showHelp || submissionCard || showSettings || resultsTableCard) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({ width }) => {
            return <div className={"assignment-grading-view"}>
              { maskCover }
              { helpCard }
              { settingsCard }
              { submissionCard }
              { resultsTableCard }
              { table(width, blur) }
              <NotificationContainer/>
            </div>;
        }}
      </ReactResizeDetector>
    );
  }

}

export default AssignmentGradingView;
