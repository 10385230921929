import React, { Component } from 'react'; 

import ReactQuill from 'react-quill';
import { CollapsibleJupyterCell } from './JupyterCell';
import InteractiveGraph from './InteractiveGraph';
import { hashString } from './utils';
import CsvTable from './CsvTable';
import { extractCodeBlock } from './utils';
import Tooltip from '@material-ui/core/Tooltip';
import SuggestionSuperList from './SuggestionSuperList';
import OpenResponseList from './OpenResponseList';

class CardContents extends Component {

  renderSuggestions(suggestions) {
    return <div className="padded-sides"><SuggestionSuperList
      includeSuggestionReplies
      hidePlaceholder={ true }
      label="Suggestion"
      showHocketField={ false }
      readOnly={ true }
      hideLast={ true }
      limit={ 15 }
      projectId={ this.props.projectId }
      db={ this.props.db }
      values={ suggestions }
      updateValues={ () => null }/></div>;
  }

  renderOpenResponse(openResponse) {
    return <div className="padded-sides">
      <OpenResponseList
        label="Open Response"
        hidePlaceholder
        readOnly
        hideLast
        limit={ 15 }
        values={ openResponse }
        updateValues={ (intents) => null }/>
    </div>;
  }

  renderTable(data) {
    return <CsvTable data={ data }/>
  }

  renderJSXGraph(hocketId, jessieCode, aspectRatio) {
    return (
      <InteractiveGraph
        key={ 'interactive-graph-' + hashString(hocketId + jessieCode + aspectRatio) }
        code={ jessieCode }
        width={ "90%" }
        ratio={ aspectRatio || "100%" }/>
    );
  }

  setLang(lang) {
    if (this.props.setLang) {
      this.props.setLang(lang);
    }
  }

  render() {
    const { hocket, index, showIndex } = this.props;
    const { codeCell } = this.props;
    const quillDelta = hocket.responses[0] ? JSON.parse(hocket.responses[0]) : "";
    for (let marker of ['📝', '®']) {
      if (this.props.stripMarkers && quillDelta.ops?.[0]?.insert?.trim && 
        quillDelta.ops[0].insert.trim().startsWith(marker)) {
     quillDelta.ops[0].insert = quillDelta
       .ops[0]
       .insert
       .replace(marker, '')
   }
    }
    return (<div className="message-card-contents">
      { index && showIndex ? <Tooltip title="message numbers appear only in this view" enterDelay={ 250 }>
        <div className="message-card-index">{ index + 1 }</div>
      </Tooltip> : null }
      <ReactQuill
        readOnly
        modules={ { toolbar: false } }
        value={ quillDelta }>
      </ReactQuill>
      { (hocket.tableData?.length || hocket.tableData?.string?.length) ? this.renderTable(typeof hocket.tableData === 'string' ? {string: hocket.tableData} : hocket.tableData) : null }
      { hocket.jessieCode && hocket.jessieCode.length ?
          this.renderJSXGraph(hocket.id, hocket.jessieCode, hocket.aspectRatio) : null }
      { hocket.codeCell ?
          <div
            className="on-card-code-cell"
            onClick={ e => e.stopPropagation() }>
            <CollapsibleJupyterCell
              content={ extractCodeBlock(quillDelta) }
              language={ codeCell }
              setLang={(lang) => this.setLang(lang)}/>
          </div> : null }
      { hocket.urlIFrame ? <iframe frameborder="0" width="100%" src={hocket.urlIFrame} height={(hocket.heightIFrame || 0) + 'px'}></iframe> : null }
      { this.props.showSuggestions && hocket.suggestions ? this.renderSuggestions(hocket.suggestions) : null }
      { this.props.showOpenResponse && hocket.openResponse ? this.renderOpenResponse(hocket.openResponse) : null }
    </div>);
  }
}

export default CardContents;