import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { getDocumentButtonListStyle, getDocumentButtonStyle, DocumentButton } from '../mui-themes';
import { updateTitleBar, reorder } from '../utils';
import { Lesson } from '../lesson';
import { createLesson } from '../analytics';
import './style.css';

const settingsThemeColor = '#FFF';
const theme = createMuiTheme({
  palette: {
    common: { black: settingsThemeColor, white: settingsThemeColor },
    primary: { main: settingsThemeColor, dark: settingsThemeColor, light: settingsThemeColor },
    text: { primary: settingsThemeColor, secondary: settingsThemeColor }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        }
      }
    }
  }
});

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

class LessonsView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      lessons: null,
    };
    this.state = {
      instructors: {},
      tas: {},
      hideMode: false,
      infoMode: false,
      showHelp: false,
      newLessonBoxOpen: false,
    };
  }

  componentDidMount() {
    this.subProject(this.props);
    this.subLessonOrder(this.props);
    this.subLessons(this.props);
  }

  subProject(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const doc = snap.data();
        if (doc.courseLanguage) this.setState({ codeCell: doc.courseLanguage });
      });
  }

  subLessons(props, Cb) {
    if (this.unsub.lessons) this.unsub.lessons();
    const { lessonOrder } = this.state;
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.lessons = db
      .collection('projects')
      .doc(projectId)
      .collection('lessons')
      .orderBy("timestamp", "desc")
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const lessons = snap.docs.map(doc => doc.data());
        if (lessonOrder) {
          lessons.sort( (lesson1, lesson2) => {
            if (lessonOrder.indexOf(lesson1.id) < lessonOrder.indexOf(lesson2.id)) {
              return -1;
            } else {
              return 1;
            }
          });
        }
        this.setState({ lessons });
        if (Cb) Cb(lessons);
      });
  }

  subLessonOrder(props) {
    if (this.unsub.lessonOrder) this.unsub.lessonOrder();
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.lessonOrder = db
      .collection('projects')
      .doc(projectId)
      .collection('lesson-order')
      .doc('ids')
      .onSnapshot(doc => {
        const ids = doc.data();
        if (!ids || !ids.lessonOrder) {
          this.subLessons(this.props, (lessons) =>
          this.setLessonOrder(lessons.map(l => l.id)));
        } else {
          this.setState({ lessonOrder: ids.lessonOrder }, () => this.subLessons(this.props));
        }
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
  }

  nameLesson(lessonTitle='') {
    this.setState({ lessonTitle });
  }

  describeLesson(lessonDescription) {
    this.setState({ lessonDescription });
  }

  createLesson() {
    const { db, projectId, currentUser } = this.props;
    const { lessonDescription, lessonTitle, lessonOrder } = this.state;
    const creator = currentUser || {};
    const lesson = Lesson(lessonTitle, lessonDescription, '', creator);
    if (this.state.codeCell) lesson.codeCell = this.state.codeCell;
    createLesson(lesson.id);
    db.collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lesson.id)
      .set(lesson, {merge: true})
      .then(() => {
        this.setState({
          lessonTitle: '',
          lessonDescription: '',
        });
        this.setLessonOrder([lesson.id].concat(lessonOrder));
      }).catch(console.error);
    this.setState({ newLessonBoxOpen: false });
  }

  hideLesson(lessonId, hide=true) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId)
      .set({ hidden: hide }, {merge: true})
      .catch(console.error);
  }

  renderLessonCard(lesson, index) {
    const { projectId, router } = this.props;
    const { hideMode, infoMode } = this.state;
    const link = '/projects/' + projectId + '/lessons/' + lesson.id;
    return (
      <Draggable
        key={lesson.id}
        draggableId={lesson.id}
        index={index}>
        {(provided, snapshot) => (
            <DocumentButton
              key={"list-item-" + lesson.id}
              onClick={ () => router.history.push(link) }
              button
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getDocumentButtonStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}>
              {( hideMode ?
                <Button
                  style={{color: "rgb(35, 120, 130)"}}
                  onClick={ (event) => {
                    event.stopPropagation();
                    this.hideLesson(lesson.id, !lesson.hidden);
                  }}>
                  { lesson.hidden ? "Unhide" : "Hide" }
                </Button> : null)}
              <ListItemText
                primary={ lesson.title }
                secondary={ lesson.description + " " }
                className= "lesson-card-text" />
              { infoMode ? <div className="card-notes">
                <div className="message-count">{ lesson.hockets.length + " messages" } </div>
                { lesson.shared ? <div className="shared-link"> { "shared: " +  lesson.sharedLessonId } </div> : null }
              </div> : null }
            </DocumentButton>)
        }
      </Draggable>
    );
  }

  onDragEnd(result) {
    const { lessons, lessonOrder, hideMode } = this.state;
    // dropped outside the list
    if (!result.destination || !lessonOrder) {
      return;
    }
    const visibleLessons = hideMode ? lessons : lessons.filter(l => !l.hidden);
    const indexMap = (i) => lessonOrder.indexOf(visibleLessons[i].id);
    const start = indexMap(result.source.index);
    const end = indexMap(result.destination.index);
    const newLessons = reorder(lessons, start, end);
    this.setState({ lessons: newLessons }, () =>
      this.setLessonOrder(newLessons.map( l => l.id ))
    );
  }

  setLessonOrder(lessonOrder) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('lesson-order')
      .doc('ids')
      .set({ lessonOrder })
      .catch(console.error)
  }

  renderLessonCards() {
    const { hideMode } = this.state;
    const { lessons=[] } = this.state;
    const cards = lessons.filter(
        l => hideMode || !l.hidden
      ).map( (l, i) => this.renderLessonCard(l, i));
    return (
        <DragDropContext onDragEnd={ result => this.onDragEnd(result) }>
          <Droppable droppableId="droppable">
          { (provided, snapshot) => (
              <List
                ref={provided.innerRef}
                style={getDocumentButtonListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}>
                { cards }
                { provided.placeholder }
              </List>
            ) }
          </Droppable>
        </DragDropContext>
    );
  }

  renderNewLessonCreationArea() {
    const { lessonTitle='', lessonDescription='' } = this.state;
    return (
      <div className='lesson-creation-area'>
        <h2 className='centered'>Create new Lesson</h2>
        <p className="lesson-creation-note">
          Note: If you want to start a lesson with an introduction to Prismia for your students, feel free to copy <a href="https://prismia.chat/shared/prismia-introduction">this shared lesson</a> into your class.
        </p>
        <p className="subtext">Lesson Title:</p>
        <Input
          fullWidth
          className="lesson-title"
          placeholder="lesson title"
          value={ lessonTitle || '' }
          onChange={ (e) => this.nameLesson(e.target.value) } />
        <p className="subtext">Description:</p>
        <Input
          fullWidth
          className="lesson-description"
          placeholder="description"
          value={ lessonDescription || '' }
          onChange={ (e) => this.describeLesson(e.target.value) } />
        <Button
          variant="contained"
          color="secondary"
          style={{marginLeft: "50%", transform: "translate(-50%, 0)"}}
          onClick={() => this.createLesson() }
          >
          Create Lesson
        </Button>
      </div>
    );
  }

  renderLessonsArea() {
    const { newLessonBoxOpen } = this.state;
    const plusButton = <Tooltip title="Create a new lesson" enterDelay={ 200 } placement="top">
      <Button
        fullWidth
        variant="outlined"
        style={{marginTop: "20px", marginBottom: "0"}}
        onClick={ () => {
          this.setState({ newLessonBoxOpen: true });
        }}>
        +
      </Button>
    </Tooltip>;
    return (
      <div className="y-scrollable">
        <div className="lesson-buttons-area extra-padded">
          <h1 className="lessons-title centered padded-bottom">Lessons</h1>
          { newLessonBoxOpen ? this.renderNewLessonCreationArea() : plusButton }
          { this.renderLessonCards() }
        </div>
      </div>
    );
  }

  renderSettings() {
    const { lesson={} } = this.state;
    if (!lesson) return null;
    return (
      <ThemeProvider theme={ theme }>
        <div className="help-info">
          <h1>Settings</h1>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={
                <Tooltip
                  title="Show lesson information on each lesson button"
                  enterDelay={ 400 }>
                  <WhiteCheckbox
                    className="code-cell-setting"
                    checked={this.state.infoMode}
                    color="default"
                    onChange={ (e) => this.setState({ infoMode: !this.state.infoMode }) } inputProps={{ 'aria-label': 'lesson info' }}/>
                </Tooltip>
              }
              label="Show lesson information"/>
            <FormControlLabel
              control={ <Tooltip
              title="Show/hide lessons"
              enterDelay={ 400 }>
              <WhiteCheckbox
                className="code-cell-setting"
                checked={this.state.hideMode}
                color="default"
                onChange={(e) => this.setState({ hideMode: !this.state.hideMode })}
                inputProps={{ 'aria-label': 'show/hide lesson' }}/>
              </Tooltip>
            }
            label="Show/hide lessons"/>
          </FormGroup>
          </div>
    </ThemeProvider>);
  }

  renderHelp() {
    return (<div className="help-info">
      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move selected message up one</li>
      <li><tt>down</tt> move selected message down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia lessons or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>

      <h1>Emoji features</h1>
      <ul className="documentation-list">
        <li>Sending a message including the text <span role="img" aria-label="silhouette">👥</span> sends students' messages to each other anonymously and allows each student to respond directly to the person whose answer they see. Use <tt>\peer</tt> followed by tab to get the silhouette character.</li>
        <li>Sending a message including the text <span role="img" aria-label="clock">🕔90s</span> sets a 90-second timer (visible to you and to students). Use <tt>\clock</tt> or <tt>\timer</tt> followed by tab to get the clock character.</li>
        <li>Sending a message including the text <span role="img" aria-label="pencil">✏</span> opens each student's drawing tool and sets the image contained in the message as the background image. Use <tt>\draw</tt> followed by tab to get the pencil character.</li>
        <li>Sending a message including the text <span role="img" aria-label="registered">®</span> marks the question as required, meaning that it will be tracked as an open response question on the <em>Metrics</em> page. Use <tt>\RR</tt> or <tt>\required</tt> followed by tab to get the registered symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="pushpin">📌</span> will pin the message to the top of each student's window. Use <tt>\pin</tt> followed by tab to get the pushpin character.</li>
        <li>Sending a message including the text <span role="img" aria-label="recycle">♻</span> will clear every student's pinned messages. Use <tt>\clear</tt> followed by tab to get the recycling symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="otimes">⊗</span> will remove one pinned message. The character can be included more than once to remove multiple pinned messages. Use <tt>\unpin</tt> followed by tab to get the <span role="img" aria-label="otimes">⊗</span> symbol.</li>
      </ul>
    </div>);
  }

  exitHelpOrSettings() {
    this.setState({ showHelp: false, showSettings: false });
  }

  render() {
    updateTitleBar('Lessons');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings } = this.state;
    const tools = [{
      icon: <HelpOutlineOutlinedIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help screen",
      disabled: false,
      hide: false,
    }, {
      icon: <SettingsIcon/>,
      onClick: () => this.setState({ showSettings: true }),
      tooltipTitle: "Settings",
      disabled: false,
      hide: false,
    }];
    const maskCover = (showHelp || showSettings) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    const helpCard = showHelp ? this.renderHelp() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    const blur = showHelp || showSettings ? " blur" : "";
    return (
      <>
        { maskCover }
        { helpCard }
        { settingsCard }
        <div className={ "lessons-view" + blur }>
        <SimpleAdminNav 
          currentUser={ currentUser }
          projectId={ projectId }
          db={ db }
          router={ router }/>
        <SidebarButtonPanel
          tools={ tools }/>
        { this.renderLessonsArea() }
        </div>
      </>
    );
  }

}

export default LessonsView;
