import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import { JupyterCell } from '../JupyterCell';
import CommentsArea from '../comments-area/CommentsArea';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReorderIcon from '@material-ui/icons/Reorder';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import CodeIcon from '@material-ui/icons/Code';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import SuggestionSuperList from '../SuggestionSuperList';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import toPlaintext from 'quill-delta-to-plaintext';
import { updateTitleBar, splitQuillDeltaOnHorizontalRule,
         touchUpMarkdown, peelOffSuggestions,
         extractCodeBlock, markdownToDelta, now, ensureSmall,
         mousetrapStopCallback, isNote, isRegistered,
         isValidDelta, pingBinderKernel } from '../utils';
import { binderKernels } from '../jupyter';
import { mobileThreshold } from '../constants';
import { exportJupyterNotebook, cells2hockets } from '../jupyter';
import Hocket from '../hocket';
import MessageForm from '../MessageForm';
import CardContents from '../CardContents';
import { SharedLesson } from '../lesson';
import {
  manuallySortAssignmentMessage
} from '../analytics';
import 'react-quill/dist/quill.snow.css';
import './style.css';
import * as Mousetrap from 'mousetrap';
import { settingsTheme, settingsClockTheme } from '../mui-themes';

// for message virutalization: only render a
// window of 40 messages, stepping in increments
// of 10 when the window scroll position gets
// near the top or bottom:
const MESSAGE_RENDER_MAX = 40;
const MESSAGE_RENDER_STEP = 10;

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#efd' : '#fff',
});

const getItemStyle = (isDragging, draggableStyle, clusterStyle={}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#ffe' : clusterStyle.background,
    // styles we need to apply on draggables
    ...draggableStyle
});

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

class AssignmentCreationView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      assignment: null,
      project: null,
      settings: null,
    };
    this.assignmentRef = {};
    this.commentRef = {};
    this.timeLastScrolled = new Date();
    this.mainQuillRef = React.createRef();
    this.juniperRef = React.createRef();
    this.jupyterInputRef = React.createRef();
    this.deleteMessages = this.deleteMessages.bind(this);
    this.hocketsAreaRef = null;
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      manualScroll: navigator.vendor === "Apple Computer, Inc.",
      hockets: {},
      assignment: null,
      instructors: {},
      tas: {},
      activeHocketId: null,
      hocketSelectionRange: null,
      showHelp: false,
      showSettings: false,
      locked: false,
      reOrder: false,
      juniperOpen: false,
      juniperHasRendered: false,
      published: false,
      solutionsPublished: false,
      lastBinderPing: new Date(),
      deadline: new Date(),
      latestExtensionDate: new Date(),
      timeZone: null,
      messageLimit: MESSAGE_RENDER_MAX,
      activeCommentHocket: null,
      showComments: true,
    };
  }

  componentDidMount() {
    this.getTimeZone().then( () => {
      this.subAssignment(this.props);
    });
    this.subComments(this.props);
    this.setMousetrap();
    this.setActiveHocketId(null, true); // true means do update db
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
  }

  toggleReorder() {
    this.setState({ reOrder: !this.state.reOrder });
  }

  hocketIndex(hocketId) {
    const { assignment } = this.state;
    const { hockets=[] } = assignment;
    return hockets.findIndex(hocket => hocket.hocketId === hocketId);
  }

  adjustActiveHocket(increment) {
    const { activeHocketId, assignment } = this.state;
    const { hockets=[] } = assignment;
    const currentIndex = this.hocketIndex(activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < hockets.length)) {
      this.setActiveHocketId(hockets[newIndex].hocketId);
    }
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }

  adjustSelectionEnd(increment) {
    const { activeHocketId,
            assignment,
            hocketSelectionRange
    } = this.state;
    const { hockets=[] } = assignment;
    const currentIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < hockets.length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  splitActiveCell() {
    const { db, projectId } = this.props;
    const { assignment={}, activeHocketId } = this.state;
    const { hockets=[] } = assignment;
    const idx = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    if (idx === -1) return;
    const delta = JSON.parse(this.state.hockets[hockets[idx].hocketId].responses[0]);
    const newDeltas = splitQuillDeltaOnHorizontalRule(delta);
    const answer = window.confirm(`Split current messages into ${newDeltas.length} new messages?`);
    if (!answer) return;
    const newHockets = newDeltas.map((delta) => {
      const hocket = Hocket();
      hocket.responses.push(JSON.stringify(delta));
      return peelOffSuggestions(hocket);
    });
    const batch = db.batch();
    newHockets.forEach( (hocket) => {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocket.id),
          hocket,
          {merge: true});
    });
    batch
      .commit()
      .then( () => {
        const newHocketStubs = newHockets.map(
          (hocket) => { return { hocketId: hocket.id }; }
        );
        hockets.splice(idx, 1, ...newHocketStubs);
        this.assignmentRef.set({ hockets }, {merge: true});
        return newHocketStubs;
      })
      .then( (hockets) => {
        const [lastHocket] = hockets.slice(-1);
        this.setState({activeHocketId: lastHocket.hocketId});
      })
      .catch(console.error);
  }

  setMousetrap() {
    Mousetrap.bind("down", () => this.adjustActiveHocket(1));
    Mousetrap.bind("up", () => this.adjustActiveHocket(-1));
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+s", () => this.splitActiveCell());
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind("shift+=", () => this.createHocket());
    Mousetrap.bind("ctrl+j", () => this.toggleJuniper());
    Mousetrap.bind("shift+r", () => this.toggleReorder());
    Mousetrap.bind(["del", "backspace"], () => {
      this.deleteMessages();
    });
    Mousetrap.bind("esc", () => {
      if (this.state.locked) this.setState({ activeHocketId: null });
      this.setState({
        hocketSelectionRange: null,
        showHelp: false,
        showSettings: false,
      });
    });
    Mousetrap.bind('enter', () => {
      setTimeout( () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.focus();
          const editor = this.mainQuillRef.current.editor;
          editor.setSelection(editor.getLength(), 0);
        }
      }, 40);
    });
    Mousetrap.bind("shift+m", () => this.convertEditorContents());
    Mousetrap.bind(["mod+/", "shift+/"], () => this.toggleHelp());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  exitHelpOrSettings() {
    this.setState({ showHelp: false, showSettings: false});
  }

  toggleHelp() {
    const { showHelp } = this.state;
    this.setState({ showHelp: !showHelp});
  }

  toggleSettings() {
    const { showSettings } = this.state;
    this.setState({ showSettings: !showSettings });
  }

  setCodeCell(language) {
    this.assignmentRef.set({ codeCell: language }, {merge: true});
  }

  publish(problems, solutions) {
    const { db, currentUser } = this.props;
    const { assignment, hockets } = this.state;
    const hocketsMap = hockets;
    // if we unpublish the problems, we have to 
    // unpublish the solutions too
    if (!problems && this.state.solutionsPublished) {
      solutions = false;
    }
    if (problems) {
      const { hockets } = assignment;
      const publishedHockets = solutions ? hockets.slice() : hockets.filter(hocketId => !isNote(hocketsMap[hocketId.hocketId]));
      const batch = db.batch();
      batch.set(this.assignmentRef, {
        publishedHockets,
        published: true,
        solutionsPublished: solutions || false,
      }, {merge: true});
      batch.set(this.assignmentPubRef, {
        hocketIds: publishedHockets,
        title: assignment.title || '',
        description: assignment.description || '',
        codeCell: assignment.codeCell || null,
        published: true,
        deadline: assignment.deadline || null,
        latestExtensionDate: assignment.latestExtensionDate || null,
        released: assignment.released || false,
        exam: assignment.exam || null,
        examStartDate: assignment.examStartDate || null,
        examEndDate: assignment.examEndDate || null,
        examMinutes: assignment.examMinutes || null,
        timestamp: assignment.timestamp || now(),
        id: assignment.id,
      });
      batch
        .commit()
        .then(() => {
          const batch = db.batch();
          const timestamp = (new Date()).toISOString();
          batch.set(this.assignmentRef
            .collection('publication-history')
            .doc(timestamp),
            { 
              timestamp: new Date(),
              userId: currentUser.id,
              displayName: currentUser.displayName,
              published: publishedHockets.map(stub => stub.hocketId),
              solutionsPublished: solutions || false,
              hockets: hockets.map((hocketStub, index) => {
                const id = hocketStub.hocketId;
                return {
                  id,
                  index,
                  content: ensureSmall(this.state.hockets[id])
                };
              }),
            }
          );
          batch.commit();
        }).then(() => 
          NotificationManager.success("Assignment published!")
        ).catch(console.error);
    } else {
      const batch = db.batch();
      batch.set(this.assignmentRef, 
        { publishedHockets: [],
          published: false,
          solutionsPublished: solutions || false,
        }, {merge: true}
      );
      batch.set(this.assignmentPubRef, {
        hocketIds: [],
        title: assignment.title || '',
        description: assignment.description || '',
        codeCell: assignment.codeCell || null,
        published: false,
        deadline: assignment.deadline || null,
        latestExtensionDate: assignment.latestExtensionDate || null,
        timestamp: assignment.timestamp || now(),
        id: assignment.id,
        deleting: true,
      });
      batch.commit().then(() => NotificationManager.success("Assignment unpublished!")).catch(console.error);
      setTimeout(() => this.assignmentPubRef.delete(), 3000);
    }
  }

  getTimeZone() {
    const { db, projectId } = this.props;
    return db.collection('projects')
      .doc(projectId)
      .get()
      .then(snap => {
        const timeZone = snap.data().timeZone;
        this.setState( { timeZone }, () => moment.tz.setDefault(timeZone) );
      })
      .catch(console.error);
  }

  handleDeadlineChange(date) {
    const { db, assignmentId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ deadline: date.toDate() },
           { merge:true })
      .catch(console.error);
  }

  handleLatestExtensionDateChange(date) {
    const { db, assignmentId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ latestExtensionDate: date.toDate() },
           { merge:true })
      .catch(console.error);
  }

  markExam(setting) {
    const { db, assignmentId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ exam: setting },
           { merge:true })
      .catch(console.error);
  }

  handleExamStartDate(date) {
    const { db, assignmentId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ examStartDate: date.toDate() },
           { merge:true })
      .catch(console.error);
  }

  toggleReleased() {
    const { db, assignmentId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ released: !this.state.released },
           { merge:true })
      .catch(console.error);
  }

  handleExamEndDate(date) {
    const { db, assignmentId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ examEndDate: date.toDate() },
           { merge:true })
      .catch(console.error);
    if (this.state.examStartDate && date) {
      this.handleExamMinutes((date - this.state.examStartDate)/60000);
    }
  }

  handleExamMinutes(minutes) {
    const minutesFloat = parseFloat(minutes);
    if (isNaN(minutesFloat)) {
      window.confirm("Number of minutes should be a number");
      return;
    }
    this.setState({ examMinutes: minutes});
    const { db, assignmentId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ examMinutes: minutesFloat },
           { merge:true })
      .catch(console.error);
  }

  handleJupyterImport(file) {
    const { db, projectId } = this.props;
    const { assignment } = this.state;
    const { codeCell='code' } = assignment;
    file.text().then(text => {
      const jupyterDocument = JSON.parse(text);
      const prismiaHockets = this.assignmentHockets();
      const { hockets, newHockets } = cells2hockets(
        jupyterDocument.cells,
        prismiaHockets,
        codeCell
      );
      const batch = db.batch();
      for (let hocket of hockets) {
        if (newHockets.has(hocket.id)) {
          batch.set(
            db.collection('projects')
              .doc(projectId)
              .collection('hockets')
              .doc(hocket.id),
            hocket
          );
        }
      }
      batch.set(this.assignmentRef, { hockets: hockets.map(h => {return {hocketId: h.id};}) }, {merge: true});
      batch.commit().catch(console.error);
    });
    this.setState({ showSettings: false });
  }

  renderSettings() {
    const { assignment={}, deadline, latestExtensionPickerOpen } = this.state;
    if (!assignment) return null;
    const { title='', description=''} = assignment;
    return (
      <ThemeProvider theme={ settingsTheme }>
        <div className="help-info">
          <h1>Settings</h1>
          <h2>Title and Description</h2>
          <div className="save-title-description-container">
            <Input
              key={ 'title-' + assignment.id }
              fullWidth
              className="assignment-creation-title"
              placeholder="Assignment Title"
              value={ title || '' }
              onChange={ (e) => this.nameAssignment(e.target.value) } />
            <Input
              key={ 'description-' + assignment.id }
              fullWidth
              className="assignment-description"
              placeholder="description"
              value={ description || '' }
              onChange={ (e) => this.describeAssignment(e.target.value) } />
          </div>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.saveAssignmentDetails() }>
              Save Title and Description
            </Button>
          </div>
          <h2>Publish</h2>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={ <Tooltip
              title="Publish assignment"
              enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!!this.state.published} color="default" onChange={(e) => this.publish(!this.state.published, this.state.solutionsPublished)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
            label="Problems published"/>
            { this.state.published ? <FormControlLabel
              control={ <Tooltip
              title="Publish solutions"
              enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!!this.state.solutionsPublished} color="default" onChange={(e) => this.publish(this.state.published, !this.state.solutionsPublished)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
            label="Solutions published"/> : null }
          </FormGroup>
          { this.state.published ? <Button
            className="save-title-description"
            style={{marginLeft: '20px'}}
            size="small"
            color="primary"
            variant="outlined"
            onClick={ () => this.publish(true, this.state.solutionsPublished) }>
            Update
          </Button> : null }
          { this.state.exam ? null : <><h2>Deadline</h2>
          <ThemeProvider theme={ settingsClockTheme }>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="margin-below" style={{marginLeft: "20px"}}>
                <KeyboardDateTimePicker
                  id="homework-deadline"
                  ampm={ false }
                  value={ deadline }
                  onChange={(date) => this.handleDeadlineChange(date)}
                />
              </div>
            </MuiPickersUtilsProvider>
          <div style={{marginLeft: "20px", marginTop: "0px"}}>
          <Tooltip
            title="Students cannot extend beyond this time">
              <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={
                () => {
                  this.setState({ latestExtensionPickerOpen: true })
                }
              }>
              Choose latest extension date
            </Button>
          </Tooltip>
          { latestExtensionPickerOpen ? <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="margin-below" style={{marginTop: "16px", marginLeft: "20px"}}>
              <KeyboardDateTimePicker
                id="homework-deadline"
                ampm={false}
                value={ this.state.latestExtensionDate }
                onChange={(date) => this.handleLatestExtensionDateChange(date)}
              />
            </div>
          </MuiPickersUtilsProvider> : null }
          </div>
          </ThemeProvider></>}
          <h2>Exam</h2>
          <ThemeProvider theme={ settingsClockTheme }>
            <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
              <FormControlLabel
                control={ <Tooltip
                title="Make this an exam"
                enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!!this.state.exam} color="default" onChange={(e) => this.markExam(!this.state.exam)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
              label="Make this an exam"/>
              {this.state.exam ? <FormControlLabel
                control={ <Tooltip
                title="Release exam (after administration is complete)"
                enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!!this.state.released} color="default" onChange={() => this.toggleReleased()} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
              label="Release exam (after administration is complete)"/> : null }
              { this.state.exam ? <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="margin-below" style={{marginTop: "16px", marginLeft: "20px"}}>
                <KeyboardDateTimePicker
                  label="Exam Start Time"
                  id="exam-start"
                  ampm={false}
                  value={ this.state.examStartDate }
                  onChange={(date) => this.handleExamStartDate(date)}
                />
              </div>
            </MuiPickersUtilsProvider> : null }
            { this.state.exam ? <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="margin-below" style={{marginTop: "16px", marginLeft: "20px"}}>
                <KeyboardDateTimePicker
                  label="Exam End Time"
                  id="exam-end"
                  ampm={false}
                  value={ this.state.examEndDate }
                  onChange={(date) => this.handleExamEndDate(date)}
                />
              </div>
            </MuiPickersUtilsProvider> : null }
            { this.state.exam ? <FormControlLabel
                  style={{marginLeft: "20px", marginTop: "16px"}}
                  control={ <Tooltip
                  title="Number of minutes allowed"
                  enterDelay={ 400 }><Input className="code-cell-setting" value={this.state.minutes} onChange={(e) => this.setState({minutes: e.target.value})} onBlur={(e) => this.handleExamMinutes(this.state.minutes)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
                label="minutes"/> : null
              }
            </FormGroup>
          </ThemeProvider>
          <h2>Jupyter</h2>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.exportJupyterNotebook() }>
              Export
            </Button>
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              style={{marginLeft: "12px"}}
              onClick={ () => this.jupyterInputRef.current.click() }>
              Import
            </Button>
            <input
              type="file"
              ref={ this.jupyterInputRef }
              onChange={ (e) => this.handleJupyterImport(e.target.files[0]) }
              onClick={ e => {e.target.value = null} }
              multiple={ false }
              style={{opacity: 0, tabIndex: -1, position: "absolute"}}/>
          </div>
          <h2> Message settings </h2>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={ <WhiteCheckbox checked={ !!(this.state.locked) } color="primary" inputProps={{ 'aria-label': 'Lock messages' }} onChange={ e => this.setState({ locked: e.target.checked })}/> }
              label={ "Lock messages" }/>
            <FormControlLabel
              control={ <WhiteCheckbox checked={ !!(this.state.showComments) } color="primary" inputProps={{ 'aria-label': 'Show comments' }} onChange={ e => this.setState({ showComments: e.target.checked })}/> }
              label={ "Show comments" }/>
          </FormGroup>
          <h2>Code cells</h2>
          <FormGroup style={{marginLeft: "20px"}}>
            { 
              Object.keys(binderKernels).map(lang => 
                <FormControlLabel
                  key={lang + 'checkbox'}
                  control={ <Tooltip
                    title={`Use ${binderKernels[lang]?.name} for code cells in this assignment`}
                    enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={this.state.codeCell === lang} color="default" onChange={(e) => this.setCodeCell(lang)} inputProps={{ 'aria-label': 'code cells' }}/></Tooltip> }
                label={binderKernels[lang]?.name}/>
              )
            }
            <FormControlLabel
              control={ <Tooltip
              title="Don't use code cells in this assignment"
              enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!this.state.codeCell} color="default" onChange={(e) => this.setCodeCell(null)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
            label="none"/>
          </FormGroup>
          <h2>Autoscrolling</h2>
          <FormGroup style={{marginLeft: "20px"}}>
              <FormControlLabel
                control={ <Tooltip
                  title="Toggle autoscrolling on this page"
                  enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!this.state.manualScroll} color="default" onChange={(e) => this.setState({ manualScroll: !this.state.manualScroll })} inputProps={{ 'aria-label': 'Autoscrolling' }}/></Tooltip> }
                label="Render more messages automatically when scroll bar reaches bottom"/>
          </FormGroup>
          <h2>Danger zone</h2>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className="delete-assignment-message-button"
            onClick={ () => this.deleteAssignment() }>
            Delete Assignment
          </Button>
        </div>
    </ThemeProvider>);
  }

  helpInfo() {
    return (<div className="help-info">
      <h1>Writing Problems</h1>

      <ul className="documentation-list">
      <li> An exercise statement may span multiple messages. The last message must begin with ® (<tt>\required + [tab]</tt>) </li>
      <li> Each exercise statement should be followed by any number of solution messages. Each must begin with <span role="img" aria-label="note">📝</span> (<tt>\note + [tab]</tt>).</li>
      </ul>

      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move selected message up one</li>
      <li><tt>down</tt> move selected message down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia assignments or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>

      <h1>Emoji features</h1>
      <ul className="documentation-list">
        <li>Sending a message including the text <span role="img" aria-label="silhouette">👥</span> sends students' messages to each other anonymously and allows each student to respond directly to the person whose answer they see. Use <tt>\peer</tt> followed by tab to get the silhouette character.</li>
        <li>Sending a message including the text <span role="img" aria-label="clock">🕔90s</span> sets a 90-second timer (visible to you and to students). Use <tt>\clock</tt> or <tt>\timer</tt> followed by tab to get the clock character.</li>
        <li>Sending a message including the text <span role="img" aria-label="pencil">✏</span> opens each student's drawing tool and sets the image contained in the message as the background image. Use <tt>\draw</tt> followed by tab to get the pencil character.</li>
        <li>Sending a message including the text <span role="img" aria-label="registered">®</span> marks the question as required, meaning that it will be tracked as an open response question on the <em>Metrics</em> page. Use <tt>\RR</tt> or <tt>\required</tt> followed by tab to get the registered symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="pushpin">📌</span> will pin the message to the top of each student's window. Use <tt>\pin</tt> followed by tab to get the pushpin character.</li>
        <li>Sending a message including the text <span role="img" aria-label="recycle">♻</span> will clear every student's pinned messages. Use <tt>\clear</tt> followed by tab to get the recycling symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="otimes">⊗</span> will remove one pinned message. The character can be included more than once to remove multiple pinned messages. Use <tt>\unpin</tt> followed by tab to get the <span role="img" aria-label="otimes">⊗</span> symbol.</li>
      </ul>
    </div>);
  }

  mousetrapUnset() {
    Mousetrap.unbind("down");
    Mousetrap.unbind("up");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("shift+s");
    Mousetrap.unbind("shift+r");
    Mousetrap.unbind("shift+=");
    Mousetrap.unbind("del");
    Mousetrap.unbind("backspace");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("shift+/");
    Mousetrap.unbind("mod+/");
    Mousetrap.unbind("ctrl+j");
  }

  convertEditorContents() {
    if (!this.mainQuillRef) return;
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);
    const editor = this.mainQuillRef.current.editor;
    const text = toPlaintext(editor.getContents());
    const ops = text.split("\n---\n").map(cellText => markdownToDelta(cellText).concat([{insert: {hr: true}}]));
    editor.setContents({ops: flatten(ops).slice(0, -1)});
    setTimeout( () => this.mainQuillRef.current.focus(), 50 );
  }

  subComments(props) {
    if (this.unsub.comments) this.unsub.comments();
    const { db, projectId, assignmentId } = props;
    this.commentRef = db
      .collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('comments');
    this.unsub.comments = this.commentRef
      .where('archived', '==', false)
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const docs = snap.docs.map(doc => doc.data());
        const comments = {};
        for (let doc of docs) {
           if (comments[doc.parentId]) {
             comments[doc.parentId].push(doc);
           } else {
             comments[doc.parentId] = [doc];
           }
        }
        this.setState({ comments });
      });
  }

  subAssignment(props) {
    if (this.unsub.assignment) this.unsub.assignment();
    const { db, currentUser, projectId, assignmentId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.assignmentRef =  db
      .collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId);
    this.assignmentPubRef = db
      .collection('projects')
      .doc(projectId)
      .collection('published-assignments')
      .doc(assignmentId);
    this.unsub.assignment = this.assignmentRef
      .onSnapshot(snap => {
        const assignment = snap.data();
        if (!assignment) return;
        const { hockets=[] } = assignment;
        for (let i = 0; i < hockets.length; i++) {
          if (this.unsub[hockets[i].hocketId]) continue;
          this.subHocket(hockets[i].hocketId);
        }
        this.setState({ assignment });
        let deadline = new Date();
        let latestExtensionDate;
        if (assignment.deadline) deadline = assignment.deadline.toDate();
        if (assignment.latestExtensionDate) latestExtensionDate = assignment.latestExtensionDate.toDate();
        this.setState({ exam: !!assignment.exam });
        for (let key of ['examStartDate', 'examEndDate']) {
          if (assignment[key]) {
            this.setState({ [key]: assignment[key].toDate() });
          }
        }
        if (assignment.examMinutes) {
          this.setState({ minutes: assignment.examMinutes });
        }
        this.setState({ released: assignment.released || false });
        this.setState({ deadline, latestExtensionDate });
        this.setState({ codeCell: assignment.codeCell || null });
        this.setState({ published: assignment.published || null}, () => {
          if (this.state.published && !this.state.initialLockChecked) {
            this.setState({ locked: true });
          }
          this.setState({ initialLockChecked: true });
        });
        this.setState({ solutionsPublished: assignment.solutionsPublished || null});
      });
  }

  subHocket(hocketId) {
    const { db, projectId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    window.removeEventListener('copy', this.handleCopy);
    window.removeEventListener('paste', this.handlePaste);
    this.mousetrapUnset();
  }

  nameAssignment(title='') {
    const { assignment } = this.state;
    assignment.title = title;
    this.setState({ assignment });
  }

  describeAssignment(description='') {
    const { assignment } = this.state;
    assignment.description = description;
    this.setState({ assignment });
  }

  saveAssignmentDetails() {
    const { db, assignmentId, projectId } = this.props;
    const { assignment } = this.state;
    const { title, description } = assignment;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ title, description },
           {merge:true})
      .then(() => this.toggleSettings())
      .catch(console.error);
  }

  setActiveHocketId(activeHocketId, dbUpdate=true) {
    const { db, projectId, currentUser, assignmentId } = this.props;
    this.setState({ activeHocketId,
                    hocketSelectionRange: null }, () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.blur();
        }
    });
    // update this info on the database, so it can be reflected on the
    // classroom side if desired
    if (dbUpdate) {
    const selectedAssignmentId = assignmentId;
    const lastAssignmentMessageIndex = activeHocketId === null ? null : this.hocketIndex(activeHocketId);
      setTimeout(() => db.collection('projects')
        .doc(projectId)
        .collection('settings')
        .doc(currentUser.id)
        .set({ selectedAssignmentId, activeHocketId, lastAssignmentMessageIndex }, {merge: true}));
    }
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, assignment } = this.state;
    const { hockets } = assignment;
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  exportAssignment() {
    const { exportedAssignment } = this.state;
    this.setState({ exportedAssignment: !exportedAssignment});
  }

  deleteAssignment() {
    const { projectId, router } = this.props;
    let answer = window.confirm("Are you sure you want to delete the whole Assignment?")
    if (answer) {
      this.assignmentRef.delete();
      router.history.push("/projects/" + projectId + "/assignments");
    }
  }

  deleteMessages() {
    const {
      assignment={},
      activeHocketId,
      hocketSelectionRange,
    } = this.state;
    const { hockets=[], publishedHockets } = assignment;
    if (!activeHocketId) {
      console.log("No hocket selected");
      return null;
    }
    let range, answer, currentIndex;
    if (hocketSelectionRange) {
      answer = window.confirm("Are you sure you want to delete the selected messages?")
      range = [...hocketSelectionRange];
    } else {
      answer = window.confirm("Are you sure you want to delete the selected message?")
      currentIndex = hockets.findIndex(hocket => hocket.hocketId === activeHocketId);
      range = [currentIndex, currentIndex];
    }
    range = range.sort((a,b) => a-b);
    if (answer) {
      let answer2;
      let conflictHockets = [];
      if (publishedHockets) {
          conflictHockets = hockets.filter((hocket, i) => {
          const inRange = (range[0] <= i) && (i <= range[1]);
          const id = hocket.hocketId;
          const published = publishedHockets.map(stub => stub.hocketId).includes(id);
          return inRange && published && isRegistered(this.state.hockets[id]);
        });
        if (conflictHockets.length > 0) {
          answer2 = window.prompt(`You're attempting to delete ${conflictHockets.length} message${conflictHockets.length > 1 ? "s" : ""} which have been published and are marked as required. If you proceed, students might lose work. If you are sure, type the word delete`);
        }
      }
      if (answer2 === "delete" || conflictHockets.length === 0) {
        const remainingHockets = hockets.filter((hocket, i) => {
          return (i < range[0]) || (i > range[1]);
        });
        return this.assignmentRef.set(
            { hockets: remainingHockets },
            { merge: true }
        );
      }
    }
  }

  exportJupyterNotebook() {
    const { hockets, assignment, codeCell='code' } = this.state;
    exportJupyterNotebook(
      assignment.hockets.map(hocketStub => hockets[hocketStub.hocketId]),
      codeCell,
      assignment.title && assignment.title.length > 0 ? assignment.title : "prismia",
    );
  }

  handleCopy(event) {
    const { locked, hocketSelectionRange } = this.state;
    if (hocketSelectionRange ||
          (locked && document.activeElement === this.hocketsAreaRef)) {
      const { json, html, md } = this.copyHockets();
      event.clipboardData.setData('application/json',
      json);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', md);
      event.preventDefault();
    }
  }

  handlePaste(event) {
    const clipText = event.clipboardData.getData('application/json');
    if (document.activeElement === this.hocketsAreaRef ||
        document.activeElement.tagName.toLowerCase() === 'body') {
      this.pasteHockets(clipText);
      event.preventDefault();
    }
  }

  copyHockets(all = false) {
    const {
      activeHocketId,
      hockets,
      hocketSelectionRange,
      assignment,
    } = this.state;
    const hocketIds = assignment.hockets;
    const messagesToCopy = [];
    const jupyterCells = [];
    let start, stop;
    let html = "";
    let md = "";
    if (hocketSelectionRange || all) {
      if (all) {
        start = 0;
        stop = hocketIds.length - 1;
      } else {
        [start, stop] = [...hocketSelectionRange].sort((a,b) => a-b);
      }
      for (let i = start;
               i <= stop;
               i++) {
        const currentHocket = hockets[hocketIds[i].hocketId];
        if (currentHocket && currentHocket.responses.length > 0) {
          const message = currentHocket.responses[0];
          const suggestions = currentHocket.suggestions;
          const jessieCode = currentHocket.jessieCode;
          const aspectRatio = currentHocket.aspectRatio || "100%";
          const codeCell = currentHocket.codeCell;
          const tableData = currentHocket.tableData;
          const urlIFrame = currentHocket.urlIFrame;
          const heightIFrame = currentHocket.heightIFrame;
          messagesToCopy.push({ message, suggestions, tableData, jessieCode, aspectRatio, codeCell, urlIFrame, heightIFrame });

          const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
          html += converter.convert();

          const quillDelta = JSON.parse(message);
          const thisCellMarkdown = touchUpMarkdown(deltaToMarkdown(quillDelta.ops));
          md += thisCellMarkdown + "\n---\n\n";

          jupyterCells.push({
            cell_type: "markdown",
            metadata: {},
            source: [thisCellMarkdown],
          })
          if (codeCell) {
            jupyterCells.push({
              cell_type: "code",
              metadata: {},
              source: [extractCodeBlock(quillDelta)],
              execution_count: null,
              outputs: [],
            })
          }
        }
      }
      md = md.slice(0, md.length - 7); // 7 is the length of \n\n---\n\n
    } else {
      const activeHocket = hockets[activeHocketId];
      if (activeHocket && activeHocket.responses.length > 0) {
        const message = activeHocket.responses[0];
        const suggestions = activeHocket.suggestions;
        const jessieCode = activeHocket.jessieCode || '';
        const aspectRatio = activeHocket.aspectRatio || "100%";
        messagesToCopy.push({ message, suggestions, jessieCode, aspectRatio });

        const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
        html += converter.convert();

        md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
      }
    }
    const json = JSON.stringify(messagesToCopy);
    const ipynb = JSON.stringify({
      metadata: {},
      nbformat: 4,
      nbformat_minor: 0,
      cells: jupyterCells,
    });
    return {ipynb, json, html, md};
  }

  pasteHockets(clipText) {
    const { db, projectId } = this.props;
    const { assignment={}, activeHocketId } = this.state;
    const { hockets=[] } = assignment;
    let messages;
    try {
      console.log(clipText);
      messages = JSON.parse(clipText);
      console.log(messages);
    } catch (err) {
      console.error("Only messages from other Prismia assignments can be pasted");
      return
    }
    messages
      .forEach( (message, loopCounter) => {
        const hocket = Hocket();
        if (!('message' in message) ||
            !isValidDelta(message.message) ||
            !('suggestions' in message)) {
          console.error("Only messages from other Prismia assignments can be pasted");
          return;
        }
        hocket.responses.push(message.message);
        hocket.suggestions = message.suggestions;
        hocket.jessieCode = message.jessieCode || '';
        hocket.aspectRatio = message.aspectRatio || '';
        hocket.codeCell = message.codeCell || false;
        hocket.tableData = message.tableData || {string: ''};
        hocket.urlIFrame = message.urlIFrame || '';
        hocket.heightIFrame = message.heightIFrame || 0;
        const hocketStub = {
          hocketId: hocket.id,
        };
        const idx = (activeHocketId ?
            hockets.findIndex(
              hocket => hocket.hocketId === activeHocketId
            ) : hockets.length - 1);
        hockets.splice(idx + loopCounter + 1, 0, hocketStub);
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocket.id)
          .set(hocket, {merge: true})
          .catch(console.error);
      });
      this.assignmentRef
        .set({ hockets }, { merge: true });
    }


  createHocket() {
    const { db, projectId } = this.props;
    const { assignment={}, activeHocketId } = this.state;
    const { hockets=[] } = assignment;
    const hocket = Hocket();
    const hocketStub = {
      hocketId: hocket.id,
    };
    const idx = (activeHocketId ?
        hockets.findIndex(
          hocket => hocket.hocketId === activeHocketId
        ) : hockets.length - 1);
    hockets.splice(idx + 1, 0, hocketStub);
    db.collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.assignmentRef
          .set({ hockets }, { merge: true });
      }).catch(console.error);
    this.pingBinderKernel();
  }

  onDragEnd(res) {
    const { db, projectId, assignmentId } = this.props;
    const { destination, source } = res;
    if (!destination || !source) return;
    const { assignment={} } = this.state;
    const { hockets=[] } = assignment;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const hocketStub = hockets.splice(sourceIndex, 1)[0];
    hockets.splice(destinationIndex, 0, hocketStub);
    assignment.hockets = hockets;
    this.setState({ assignment });
    manuallySortAssignmentMessage();
    db
      .collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set(assignment, {merge:true});
  }

  handleScroll(event) {
    if (this.state.manualScroll) return;
    const { messageLimit } = this.state;
    const { assignment={} } = this.state;
    const { hockets=[] } = assignment;
    const node = event.target;
    const scrollFractionTop = node.scrollTop / node.scrollHeight;
    const scrollFractionBottom = (node.scrollTop + node.clientHeight) / node.scrollHeight
    if (scrollFractionBottom > 0.85) {
      this.setState({ messageLimit: Math.min(
        hockets.length,
        messageLimit + MESSAGE_RENDER_STEP 
      )});
    } else if (scrollFractionTop < 0.15) {
      this.setState({ messageLimit: Math.max(MESSAGE_RENDER_MAX, messageLimit - MESSAGE_RENDER_STEP) });
    }
  }

  paneDidMount(node) {
    if (node && !this.state.manualScroll) {
      this.unsub.scrollListener = () => {
        node.removeEventListener("scroll", this.handleScroll);
      }
      node.addEventListener("scroll", this.handleScroll);
    }
  }

  renderAssignmentArea(width) {
    const { assignment={} } = this.state;
    const classNames = width < mobileThreshold ? "border-top" : "";
    if (!assignment) return null;
    const { title='', description='' } = assignment;
    return (
      <div
        ref={ node => {
            this.messagesAreaRef = node;
            this.paneDidMount(node);
        }}
        className={"y-scrollable x-contained " + classNames}>
        <h1
          className="assignment-title centered"
          onClick={() => this.toggleSettings()}>
          { title || '' }
        </h1>
        <div
          className="centered description-text"
          onClick={() => this.toggleSettings()}>
          { description || '' }
        </div>
        { this.renderHocketsArea(width) }
      </div>
    );
  }

  isInSelectionRange(hocketId) {
    const { assignment, hocketSelectionRange } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = assignment;
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  setActiveCommentHocket(id) {
    this.setState({ activeCommentHocket: id })
  }

  renderHocketCard(hocket, index, width) {
    if (!hocket) return null;
    const { db, currentUser, projectId } = this.props;
    const { activeHocketId, comments, showComments, activeCommentHocket, codeCell } = this.state;
    const style = {background: '#fff'};
    if (this.state.reOrder && hocket.id === activeHocketId) {
      style.background = 'rgb(169, 216, 225)'; // light teal
    }
    if (this.isInSelectionRange(hocket.id)) {
      style.background = '#fff3a8' // light yellow
    }
    const commentsArea = showComments ? <CommentsArea
      db={ db }
      width={ width }
      currentUser={ currentUser }
      comments={ comments[hocket.id] }
      parentId={ hocket.id }
      commentRef={ this.commentRef }
      activeCommentHocket={ activeCommentHocket }
      setActiveCommentHocket={ (id) => this.setActiveCommentHocket(id) }
      /> : null;
    const card = (<div
              className="assignment-card"
              key={ hocket.id }
              style={ style }
              onClick={ (e) => {
                  if (e.shiftKey) {
                    if (activeHocketId) {
                      this.setSelectionEnd(hocket.id);
                    } else {
                      this.setActiveHocketId(hocket.id);
                    }
                  } else {
                    this.setActiveHocketId(hocket.id);
                  }
              } }>
      { commentsArea }
      <CardContents 
        db={ db }
        projectId={ projectId } 
        hocket={ hocket }
        codeCell={ codeCell }
        showIndex
        showSuggestions
        showOpenResponse
        setLang={(lang) => this.setLang(lang)}/>
    </div>);
    if (!this.state.locked && !this.state.reOrder && activeHocketId === hocket.id) {
      return <div
        style={ style }
        key={ hocket.id }
        className={ 'message-form-card' }>
          { commentsArea }
          { this.renderHocketForm() }
        </div>;
    }
    if (!this.state.reOrder) return card;
    return (
      <Draggable
        draggableId={ hocket.id }
        index={ index }
        key={ hocket.id }>
      {(provided, snapshot) => {
        return (
            <div
              ref={ provided.innerRef }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="assignment-card"
              key={ hocket.id }
              style={ getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                style
              )}
              onClick={ (e) => {
                  if (this.hocketsAreaRef) {
                    this.hocketsAreaRef.focus()
                  }
                  if (e.shiftKey) {
                    this.setSelectionEnd(hocket.id);
                  } else {
                    this.setActiveHocketId(hocket.id);
                  }
              } }>
              <CardContents 
                db={ db } 
                projectId={ projectId } 
                hocket={ hocket }
                codeCell={ codeCell }
                showIndex
                showSuggestions
                showOpenResponse
                setLang={(lang) => this.setLang(lang)}/>
            </div>
        );
      }}
      </Draggable>
    );
  }

  shareAssignment() {
    const { db, projectId, currentUser, router } = this.props;
    const { hockets, assignment, codeCell, published } = this.state;
    const { description, title, id } = assignment;
    const hocketIds = assignment.hockets;
    let { sharedAssignmentId=null } = assignment;

    if (Object.keys(hockets).length === 0) {
      window.confirm("Add at least one message to share the assignment");
      return;
    }

    const assignmentRef = db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(id);
    assignmentRef.set({ shared: true }, { merge: true });

    //-----------------------------
    const newHockets = [];
    for (let idObj of hocketIds) {
      const id = idObj.hocketId;
      if (!hockets[id]) continue;
      let hocket = Hocket([], hockets[id].responses);
      //hocket.parentAssignment = sharedAssignmentId;
      hocket.id = id;
      if (hockets[id].suggestions) {
        hocket.suggestions = [...hockets[id].suggestions];
      }
      if (hockets[id].jessieCode) {
        hocket.jessieCode = hockets[id].jessieCode;
      }
      if (hockets[id].codeCell) {
        hocket.codeCell = hockets[id].codeCell;
      }
      if (hockets[id].tableData) {
        hocket.tableData = hockets[id].tableData;
      }
      if (hockets[id].aspectRatio) {
        hocket.aspectRatio = hockets[id].aspectRatio;
      }
      if (hockets[id].note) {
        hocket.note = hockets[id].note;
      }
      newHockets.push(hocket);
    }
    newHockets.forEach(hocket => {
      db.collection('projects')
        .doc(projectId)
        .collection('shared-hockets')
        .doc(hocket.id)
        .set(hocket);
    });
    const newHocketIds = newHockets.map(hocket => {
      const hocketStub = { hocketId: hocket.id };
      if (hocket.note) {
        hocketStub.note = true;
      }
      return hocketStub;
    });

    const creator = currentUser || {};
    const newAssignment = SharedLesson(
      title,
      description,
      creator,
      projectId,
      newHocketIds,
      sharedAssignmentId,
      codeCell,
      published
    );
    db.collection('projects')
      .doc(projectId)
      .collection('shared-assignments')
      .doc(newAssignment.id)
      .set(newAssignment);
    db.collection('meta')
      .doc('assignments-table')
      .set({[newAssignment.id]: projectId}, { merge: true });
    router.history.push("/shared/" + newAssignment.id);
  }
 
  renderSuggestions(suggestions) {
    return <div className="padded-sides"><SuggestionSuperList
      includeSuggestionReplies
      hidePlaceholder={ true }
      label="Suggestion"
      showHocketField={ false }
      readOnly={ true }
      hideLast={ true }
      limit={ 15 }
      projectId={ this.props.projectId }
      db={ this.props.db }
      values={ suggestions }
      updateValues={ (suggestions) => null }/></div>;
  }

  renderHocketForm() {
    const { db, storage, projectId, currentUser } = this.props;
    const { activeHocketId, codeCell } = this.state;
    if (!activeHocketId) return <></>;
    const hocketRef = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(activeHocketId);
    return (
      <div
        className="message-form-container"
        key={ activeHocketId }>
        <MessageForm
          db={ db }
          currentUser={ currentUser }
          storage={ storage }
          projectId={ projectId }
          hocketId={ activeHocketId }
          clearActiveHocketId={ () => this.setActiveHocketId(null) }
          deleteMessages={ this.deleteMessages }
          mainQuillRef={ this.mainQuillRef }
          hideSuggestedMessages
          hideOpenResponse
          toggleJuniper={ () => this.toggleJuniper() }
          splitActiveCell={ () => this.splitActiveCell() }
          createHocket={ () => this.createHocket() }
          codeCell={ codeCell }
          setMousetrap={() => this.setMousetrap() }
          hocketRef={ hocketRef }
          />
      </div>
    );
  }

  toggleJuniper() {
    const { juniperOpen } = this.state;
    this.setState({ juniperOpen: !juniperOpen }, () => {
      //if (juniperOpen) this.juniperRef.current.codeMirror.focus();
    });
  }

  renderJuniper() {
    const { juniperOpen, juniperHasRendered, codeCell } = this.state;
    const style = {};
    if (!juniperOpen && !juniperHasRendered) return null;
    if (!juniperHasRendered) this.setState({ juniperHasRendered: true });
    if (!juniperOpen) style.display = "none";
    return <div className="juniper-container" style={ style }>
      <JupyterCell
        ref={ this.juniperRef }
        language={ codeCell }
        setLang={(lang) => this.setLang(lang)}/>
    </div>;
  }

  setLang(lang) {
    this.setState({ codeCell: lang });
  }

  assignmentHockets() {
    const { assignment={}, hockets={} } = this.state;
    let hocketStubs = assignment.hockets;
    return hocketStubs.map(stub => hockets[stub.hocketId]);
  }

  renderHocketsArea(width) {
    const { assignment={}, hockets={}, messageLimit } = this.state;
    let hocketStubs = assignment.hockets;
    const numMessages = hocketStubs.length;
    const hocketCards = [];
    let idx = 0;
    let numExtraCards = 0;
    let numExtraHiddenCards = 0;
    let start = Math.max(0, messageLimit - MESSAGE_RENDER_MAX);
    let messagesSinceNoteOrRegistered = 1;
    for (let i = 0; i < hocketStubs.length; i++) {
      const hocket = hockets[hocketStubs[i].hocketId];
      hocketCards.push(this.renderHocketCard(hocket, i, width));
      if (isRegistered(hocket)) {
        idx++;
        numExtraCards++;
        if (i < start) numExtraHiddenCards++;
        hocketCards.splice(-messagesSinceNoteOrRegistered, 0,
          <h3 className="centered" style={{color: "gray"}} key={`problem-title-${idx}`}>
            {"Problem " + idx}
          </h3>
        );
      }
      if (isNote(hocket) || isRegistered(hocket)) {
        messagesSinceNoteOrRegistered = 1;
      } else {
        messagesSinceNoteOrRegistered += 1;
      }
    }
    start += numExtraHiddenCards;
    const stop = Math.min(messageLimit, hocketCards.length) + numExtraCards;
    return (
      <div
        className="hockets-area"
        tabIndex={ -1 }
        ref={ (node) => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
          {this.state.manualScroll && messageLimit > MESSAGE_RENDER_MAX ? <Tooltip
          title="Show earlier messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px",
              marginTop: "30px",
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.max(MESSAGE_RENDER_MAX, messageLimit - MESSAGE_RENDER_STEP)
              });
            }}
            >
            Earlier Messages
          </Button>
        </Tooltip> : null }
        <DragDropContext
          onDragEnd={res => this.onDragEnd(res)}>
          <Droppable
            droppableId={'list'}
            key={ 'droppable' }>
          { (provided, snapshot) => (
            <div
              className="pad-bottom"
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.innerProps}
                >
            { hocketCards.slice(start, stop) }
            { provided.placeholder }
            </div>
          )}
          </Droppable>
        </DragDropContext>
        {this.state.manualScroll && messageLimit < numMessages ? <Tooltip
          title="Show more messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px"
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.min(numMessages, messageLimit + MESSAGE_RENDER_STEP)
              });
            }}
            >
            More Messages...
          </Button>
        </Tooltip> : null }
        <Tooltip
          title="Add a new message after the currently selected message [shift +]"
          enterDelay={ 500 }>
          <Button
            fullWidth
            variant="outlined"
            className="add-hocket-button"
            onClick={() => this.createHocket()}
            >
            +
          </Button>
        </Tooltip>
      </div>
    );
  }

  render() {
    updateTitleBar('Assignments');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings, codeCell, reOrder } = this.state;
    const classes = showHelp || showSettings ? "full-height blur" : "full-height";
    const tools = [{
      icon: <HelpOutlineOutlinedIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help screen",
      disabled: false,
      hide: false,
    }, {
      icon: <SettingsIcon/>,
      onClick: () => this.setState({ showSettings: true }),
      tooltipTitle: "Settings",
      disabled: false,
      hide: false,
    }, {
      icon: reOrder ? <ReorderIcon style={{color: "orange"}}/> : <ReorderIcon/>,
      onClick: () => this.setState({ reOrder: !reOrder }),
      tooltipTitle: reOrder ? "Done reordering [shift r]" : "Reorder messages [shift r]",
      disabled: false,
      hide: false,
    }, {
      icon: <CodeIcon/>,
      onClick: () => this.toggleJuniper(),
      tooltipTitle: "Toggle code cell",
      disabled: false,
      hide: !codeCell,
    }, {
      icon: this.state.locked ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>,
      onClick: () => this.setState({locked: !this.state.locked}),
      tooltipTitle: this.state.locked ? "Unlock messages" : "Lock messages",
      disabled: false,
      hide: !this.state.locked,
    }];
    const navHeight = (width) => 62 + (width < mobileThreshold ? 48 : 0);
    const table = width => (<div className={classes}>
        <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
        <div className="flow-root">
          <SidebarButtonPanel
            mobile={ width < mobileThreshold }
            tools={ tools }/>
        </div>
          <div style={{position: "relative", height: `calc(100% - ${navHeight(width)}px)`}}>
          { this.renderAssignmentArea(width) }
          { this.renderJuniper() }
        </div>
      </div>
    );
    const maskCover = (showHelp || showSettings) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({ width }) => {
            return <div className="assignment-creation-view">
              { maskCover }
              { helpCard }
              { settingsCard }
              { table(width) }
              <NotificationContainer/>
            </div>;
        }}
      </ReactResizeDetector>
    );
  }

}

export default AssignmentCreationView;
