import uuid from 'uuid/v4';
import { now } from './utils';
import { shortUID } from './utils'

export function Lesson(title='', description='', youTubeId, creator={}, projectId=null, hockets=[]) {
  return {
    id: uuid(),
    sharedLessonId: shortUID(),
    creatorId: creator.id,
    creatorDisplayName: creator.displayName,
    creatorPhotoUrl: creator.photoUrl,
    description: description,
    youTubeId: youTubeId,
    hockets: hockets,
    timestamp: now(),
    title: title,
  }
}

export function SharedLesson(
  title='',
  description='',
  youTubeId='',
  creator={},
  projectId=null,
  hockets=[],
  id=null,
  codeCell=null,
  displayMode=false,
  lectureDate=null,
  outline=null,
  minHeaderDepth=null,
) {
  if (!id) {
    id = uuid();
  }
  return {
    id: id,
    creatorId: creator.id,
    creatorDisplayName: creator.displayName,
    creatorPhotoUrl: creator.photoUrl,
    description: description,
    youTubeId: youTubeId,
    hockets: hockets,
    timestamp: now(),
    title: title,
    codeCell: codeCell,
    displayMode: displayMode,
    lectureDate: lectureDate,
    outline: outline,
    minHeaderDepth: minHeaderDepth,
  }
}
