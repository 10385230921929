import React, { Component } from 'react';
import zenscroll from 'zenscroll';
import ReactResizeDetector from 'react-resize-detector';
import ReactQuill from 'react-quill';
import { CollapsibleJupyterCell } from '../JupyterCell';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BuildIcon from '@material-ui/icons/Build';
import FlagIcon from '@material-ui/icons/Flag';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SidebarButtonPanel from '../sidebar-buttons';
import SimpleAdminNav from '../SimpleAdminNav';
import moment from 'moment';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import toPlaintext from 'quill-delta-to-plaintext';
import { updateTitleBar, hashString, mousetrapStopCallback, extractCodeBlock, removeTerminalNewlinesFromQuillDelta, copyTextToClipboard, extractCodeText, now } from '../utils';
import { withStyles } from '@material-ui/core/styles';
import { safePhotoUrl } from '../profile-emojis';
import uuid from 'uuid/v4';
import InteractiveGraph from '../InteractiveGraph';
import ChatWidget from '../ChatWidget';
import './style.css';
import * as Mousetrap from 'mousetrap';

function d2html(deltaOps) {
  if (!deltaOps)  return '';
  if (typeof deltaOps === 'string') {
    deltaOps = JSON.parse(deltaOps);
  }
  if (deltaOps.ops) {
    deltaOps = deltaOps.ops;
  }
  const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
  const html = converter.convert();
  return html;
}

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

class MessageBoardThreadView extends Component {

  constructor(props) {
    super(props);
    this.clusterKey = 0;
    this.openHamburgerMenu = this.openHamburgerMenu.bind(this);
    this.handleHamburgerClose = this.handleHamburgerClose.bind(this);
    this.unsub = {
      thread: null,
      project: null
    };
    this.threadRef = {};
    this.quillRef = React.createRef();
    this.chatWidgetRef = React.createRef();
    this.messagesAreaRef = null;
    this.state = {
      messages: [],
      thread: null,
      menuOpenRef: null,
      hamburgerMenuOpenRef: null,
      messageSelectionRange: null,
      activeMessageId: null,
      showHelp: false,
      copyMessage: null,
      copiedMessageId: null,
      currentMessageId: null,
      automaticReplies: new Map(),
      showEditorTool: true,
      cursorPosition: 0,
      codeCell: 'julia',
      displayMode: false,
      anonymousMode: false,
      viewLogged: false,
      isStudent: true,
      resolved: true,
    };
  }

  setAnonymousMode(setting) {
    this.setState({ anonymousMode: setting });
  }

  componentDidMount() {
    const { currentUser, projectId } = this.props;
    const { viewLogged } = this.state;
    this.subChat(projectId, this.props);
    this.getProjectDetails(this.props);
    this.subResolved(this.props);
    // this will error if not an instructor; that's fine
    this.checkInstructorStatus();
    this.setMousetrap();
    if (currentUser && !viewLogged) {
      this.setState({ viewLogged: true });
    }
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
  }

  subResolved(props) {
    if (this.unsub.resolved) this.unsub.resolved();
    const { db, currentUser, projectId, threadId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.resolved = db
      .collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(threadId)
      .onSnapshot(snap => {
        const data = snap.data();
        this.setState({ resolved: data.resolved });
      });
  }

  async toggleResolved() {
    const { db, projectId, threadId } = this.props;
    await db.collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(threadId)
      .set({resolved: !this.state.resolved}, {merge: true})
      .catch(console.error);
  }

  getProjectDetails(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const project = snap.data() || {};
        const { displayName='', courseLanguage } = project;
        this.setState({ displayName, codeCell: courseLanguage });
      });
  }

  componentDidUpdate(prevProps) {
    const { projectId } = this.state;
    if (projectId) this.subChat(projectId);
  }

  setMousetrap() {
    Mousetrap.bind("esc", () => {
      this.setState({showHelp: false});
    });
    Mousetrap.bind("mod+z", () => this.handleUndo());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  mousetrapUnset() {
    Mousetrap.unbind("esc");
    Mousetrap.unbind("mod+z");
  }

  scrollDown(delay = 350, Cb) {
    if (this.messagesAreaRef && this.messagesAreaRef.scrollHeight) {
      setTimeout(() => {
        if (this.messagesAreaRef && 
              this.messagesAreaRef.scrollHeight && 
              this.chatScroller) {
          this.chatScroller.toY(this.messagesAreaRef.scrollHeight);
          if (Cb) Cb();
        }
      }, delay);
    }
  }

  checkInstructorStatus() {
    const { db, currentUser, projectId } = this.props;
    for (let role of ['instrutors', 'owners', 'tas']) {
      db.collection('meta')
        .doc('roles')
        .collection(role)
        .doc(currentUser.id)
        .get()
        .then( snap => {
          const doc = snap.data();
          if (role === 'owners') {
            if (doc && doc.projects && 
                  doc.projects.indexOf(projectId) > -1) {
              this.setState({ isStudent: false });
            }
          } else {
            if (doc && doc[projectId]) this.setState({ isStudent: false });
          }
        });
    }
  }

  subChat(projectId, props) {
    if (this.unsub.thread) this.unsub.thread();
    if (this.unsub.messages) this.unsub.messages();
    const { db, threadId } = props;
    try {
      this.threadRef = db
        .collection('projects')
        .doc(projectId)
        .collection('message-board-threads')
        .doc(threadId);
    } catch (err) {
      alert("Missing thread!");
      console.log(err);
      return;
    }
    this.unsub.thread = this.threadRef
      .onSnapshot(snap => {
        const thread = snap.data();
        this.setState({ thread });
      });
    this.unsub.messages = this.threadRef
      .collection('messages')
      .orderBy('timestamp')
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const messages = snap.docs
          .map(doc => doc.data())
          .filter(message => !message.archived)
        for (let message of messages) {
          message.quillDelta = JSON.parse(message.quillDelta);
        }
        this.setState({ messages }, () => this.scrollDown(100));
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    this.mousetrapUnset();
  }

  nameThread(title='') {
    const { thread } = this.state;
    thread.title = title;
    this.setState({ thread });
  }

  handleUndo() {
    const { currentUser } = this.props;
    const { messages } = this.state;
    const authorMessages = messages.filter(m => m.authorId === currentUser.id);
    const [lastMessage] = authorMessages.slice(-1);
    if (!lastMessage) return;
    const response = window.confirm("Do you want to delete the message \"" + toPlaintext(lastMessage.quillDelta.ops) + "\"");
    if (response) {
      this.threadRef
        .collection('messages')
        .doc(lastMessage.id)
        .set({archived: true}, {merge: true});
      setTimeout(() => 
        this.chatWidgetRef.current.setQuillDelta(lastMessage.quillDelta),
        50
      );
      this.setState({showHelp: false});
    }
  }

  emailLast(toThread=true) {
    const { messages } = this.state;
    const [lastMessage] = messages.slice(-1);
    if (!lastMessage) return;
    if (toThread) {
      const recipients = [...(new Set(messages
        .map(m => m.authorId)))
      ];
      this.emailMessage(lastMessage, recipients)
          .then(() => NotificationManager.success('Email sent!'));
    } else {
      this.emailMessage(lastMessage)
          .then(() => NotificationManager.success('Email sent!'));
    }
    this.setState({ showHelp: false });
  }

  notify() {
    const { messages } = this.state;
    const authors = messages.map(author => author.id);
    const numAuthors = (new Set(authors)).size;
    if (numAuthors === 0) return;
    if (numAuthors === 1) {
      this.emailLast(false); 
      // email to whole class if you're an instructor or instructors if a student
    } else {
      this.emailLast();
    }
  }

  notifyTooltip() {
    const { thread, messages, isStudent } = this.state;
    const authors = messages.map(message => message.authorId);
    const numAuthors = (new Set(authors)).size;
    if (numAuthors === 0) return '';
    if (numAuthors === 1) {
      if (isStudent || thread.privateThread) {
        return 'Notify instructors by email';
      } else {
        return 'Notify the whole class by email';
      }
    } else {
      return 'Notify everyone on this thread by email';
    }
  }

  helpInfo() {
    const { isStudent, thread } = this.state;
    const mobile = window.document.body.clientWidth < 500;
    const UndoButton = withStyles({
      root: {
        color: "white",
        border: "1px solid white",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px",
        display: "block",
      }
    })(Button);
    const undoButton = <UndoButton
        onClick={ () => this.handleUndo()}
        variant="outlined">
        Undo
    </UndoButton>;
    const emailButton = <UndoButton
      onClick={ () => {
        const { messages } = this.state;
        const [lastMessage] = messages.slice(-1);
        if (!lastMessage) return;
        this.emailMessage(lastMessage).then(() => NotificationManager.success('Email sent!'));
        this.setState({showHelp: false});
      }}
      variant="outlined">
      {isStudent || thread.privateThread ? "Email Instructors" : "Email Class"}
    </UndoButton>;
    const emailThreadButton = <UndoButton
      onClick={ () => this.emailLast() }
      variant="outlined">
      Email Thread
    </UndoButton>;
    const style = {};
    if (mobile) {
      style.minWidth = "auto";
      style.left = "10%";
      style.transform = "translate(-5%, 0%)";
      style.top = "50px";
    }
    return (<div className="help-info" style={ style }>
      <h1>Message board tools</h1>
      <p>Welcome to this message board thread! You can use the text box at the bottom of the screen to add messages to the thread. </p>
      <h2>Anonymity</h2>
      <p>Check this box to post anonymously.</p>
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Send messages without your identifying information"
          enterDelay={ 400 }><WhiteCheckbox checked={this.state.anonymousMode} color="default" onChange={(e) => this.setAnonymousMode(!this.state.anonymousMode)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
        label="Anonymous mode"/>
      </FormGroup>
      <h2>Message deletion</h2>
      <p>You can retract your last message by doing ⌘+z (on Mac) or ctrl+z (on PC). If your browser doesn't allow the app to use that keyboard shortcut, you can use this button instead.</p>
      { undoButton }
      <h2>Email</h2>
      { isStudent || thread.privateThread ? <p>Trigger an email which will send the last message in this thread to the instructional staff (instructors and assistants) </p> :
      <p>Trigger an email which will send the last message in this thread to everyone in the class: </p>
      }
      { emailButton }
      <p>Email the last message to everyone on this thread</p>
      { emailThreadButton }
    </div>);
  }

  renderJSXGraph(messageId, jessieCode, ratio) {
    return (
      <InteractiveGraph
        key={ 'interactive-graph-' + hashString(messageId + jessieCode) }
        code={ jessieCode }
        width={ "90%" }
        ratio={ ratio || "100%"}/>
    );
  }

  renderMessageCard(message, index) {
    const { currentUser } = this.props;
    const { codeCell, copyMessage, copiedMessageId } = this.state;
    if (!message) return null;
    const style = { background: '#fff' };
    const quillDelta = message.quillDelta;
    const bubbleClass = "instructor-bubble";
    let displayInitial = <img className={ bubbleClass } alt="profile" src={ safePhotoUrl(message.authorPhotoUrl) } />;
    let className = "message-bubble";
    if (currentUser.id === message.authorId) className += " student-message";
    let time = '';
    if (message.timestamp && message.timestamp.seconds) {
      const timestamp = moment( 1000 * message.timestamp.seconds)
      time += ' (' + timestamp.fromNow() + ')';
    }
    return (
        <React.Fragment
          key={ message.id }>
          <div
            style={ style }
            className="thread-card">
            <ReactQuill
             readOnly
             modules={ { toolbar: false } }
             value={ quillDelta }/>
            { message.jessieCode && message.jessieCode.length ?
                this.renderJSXGraph(message.id, message.jessieCode, message.aspectRatio) : null }
            { message.codeCell ?
              <div className="on-card-code-cell"> <CollapsibleJupyterCell content={ extractCodeBlock(quillDelta)} language={ codeCell || 'julia'}/></div> : null }
          </div>
          <Tooltip title={ copiedMessageId === message.id ? copyMessage : (message.authorDisplayName + time || '')} >
            <div className={ className }
              onClick={ () => {
                  copyTextToClipboard(extractCodeText(message, (code) => this.setState({copyMessage: code ? "copied code" : "copied"})));
                  this.setState({ copiedMessageId: message.id });
              } }>
            { displayInitial }
          </div>
          </Tooltip>
        </React.Fragment>
    );
  }

  renderMessagesArea() {
    const { messages } = this.state;
    const messageCards = [];
    let idx = 0;
    for (let i = 0; i < messages.length; i++) {
      messageCards.push(this.renderMessageCard(messages[i], idx));
      idx++;
    }
    return (
      <div
        className="message-thread-area"
        tabIndex={ -1 }>
        { messageCards }
      </div>
    );
  }

  async emailMessage(message, recipients=null) {
    const { db, projectId, threadId } = this.props;
    const { thread, isStudent } = this.state;
    if (typeof message === 'string') message = JSON.parse(message);
    if (!message) return;
    if (!message.id) return;
    let body = "Message Board Update from " + message.authorDisplayName + "\n\n"
    body += d2html(message.quillDelta);
    body += "[<a href='https://prismia.chat/projects/" + projectId + '/message-board-threads/' + threadId + "'>view thread on prismia</a>]";
    const payload = {
      id: message.id,
      subject: 'Prismia: Message Board Update',
      body,
      timestamp: now(),
      projectId: projectId,
    };
    if (recipients) {
      payload.recipients = recipients;
    } else if (isStudent || thread.privateThread) {
      payload.instructorsOnly = true;
    }
    return db.collection('triggers')
      .doc('email-class')
      .collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set(payload)
      .catch(console.error);
  }

  addMessage(quillDelta, clear = false) {
    const { messages, anonymousMode } = this.state;
    const { currentUser } = this.props;
    if (typeof quillDelta === 'string') quillDelta = {ops: [{insert: quillDelta}]};
    quillDelta = removeTerminalNewlinesFromQuillDelta(quillDelta);
    const newMessage = {
      id: uuid(),
      authorId: currentUser.id,
      authorDisplayName: anonymousMode ? "Anonymous" : currentUser.displayName,
      authorPhotoUrl: anonymousMode ? null : currentUser.photoUrl,
      quillDelta: quillDelta,
      timestamp: now(),
    };
    this.threadRef
      .set({ updatedTimestamp: now() }, {merge: true});
    messages.push(newMessage);
    const newMessageForDb = {...newMessage};
    newMessageForDb.quillDelta = JSON.stringify(newMessageForDb.quillDelta);
    this.threadRef.collection('messages')
      .doc(newMessage.id)
      .set(newMessageForDb, {merge: true})
      .then(() => this.scrollDown(100))
      .catch(console.error);
  }

  logIn() {
    const { currentUser, threadId, router } = this.props;
    const url = "/shared/" + threadId;
    if (!currentUser) {
      this.props.login(url);
    } else {
      router.history.push("/chat");
    }
  }

  openHamburgerMenu(event) {
    this.setState({ hamburgerMenuOpenRef: event.currentTarget });
  };

  handleHamburgerClose () {
    this.setState({ hamburgerMenuOpenRef: null });
  }

  renderHamburgerMenu(navButtons) {
    const { hamburgerMenuOpenRef } = this.state;
    const { currentUser } = this.props;
    return (
      <Menu
        open={ Boolean(hamburgerMenuOpenRef) }
        onClose={ this.handleHamburgerClose }
        anchorEl={ hamburgerMenuOpenRef }>
        <MenuItem onClick={ () => { this.logIn(); this.handleHamburgerClose(); }}>
          {currentUser ? "My Courses" : "Sign In"}
        </MenuItem>
        <MenuItem onClick={ () => { this.setState({showHelp: true}); this.handleHamburgerClose(); }}>
          Settings
        </MenuItem>
        <MenuItem onClick={ () => { this.importButtonAction(); this.handleHamburgerClose(); }}>
          Import
        </MenuItem>
      </Menu>
    );
  }

  render() {
    const { thread={}, showHelp, showEditorTool, codeCell } = this.state;
    const { db, projectId, router, currentUser, storage } = this.props;
    const mobileThreshold = 912;
    if (!thread) return null;
    const { title='' } = thread;
    let classes = "frame"
    let backAction = {}
    if (showHelp) {
      classes += " blur";
      backAction.onClick = () => this.setState({ showHelp: false });
    }
    const tools = [{
      icon: <BuildIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help and tools",
      disabled: false,
      hide: false,
    }, {
      icon: this.state.resolved ? <FlagOutlinedIcon/> : <FlagIcon/>,
      onClick: () => this.toggleResolved(),
      tooltipTitle: this.state.resolved ? "Currently marked as resolved. Click to switch." : "Currently marked as unresolved. Click to switch.",
      disabled: false,
      hide: false,
    }, {
      icon: <MailOutlineIcon/>,
      onClick: () => this.notify(),
      tooltipTitle: this.notifyTooltip(),
      disabled: false,
      hide: !this.state.messages.length,
    }];
    const mainpage = width => {
      return (
        <div 
          className={ "message-board-thread-view " + classes }
          {...backAction}>
          <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } studentView={ true }/>
            <div className="flex-vertical" style={{height: "calc(100vh - 67px)"}}>
            <SidebarButtonPanel
              mobile={ width < mobileThreshold }
              tools={ tools }/>
            <div className={"y-scrollable-no-height flex-child" + (width < mobileThreshold ? " border-top" : "")} ref={ node => {
                this.messagesAreaRef = node;
                const defaultDuration = 500;
                const edgeOffset = 30;
                this.chatScroller = zenscroll.createScroller(node, defaultDuration, edgeOffset);
              }}>
              <div className="extra-padded-left">
                <h1
                  className="thread-title centered">
                  { title || '' }
                </h1>
              </div>
                { this.renderMessagesArea() }
              </div>
            { this.state.displayMode ? null :
              <ChatWidget
                ref={ this.chatWidgetRef }
                quillRef={ this.quillRef }
                codeCell={ codeCell }
                showEditorTool={ showEditorTool }
                addMessageFromSendButton={ (delta) => this.addMessage(delta) }
                addMessage={ (delta) => this.addMessage(delta) }
                storage={ storage }
                currentUser={ currentUser }
              />
              }
            </div>
        </div>
    );};
    const maskCover = ( showHelp ) ? <div className="masking-cover" onClick={ () => this.setState({ showHelp: false }) }></div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    updateTitleBar("Shared Thread");
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({ width }) => {
           return <>
            { mainpage(width) }
            { maskCover }
            { helpCard }
            { this.renderHamburgerMenu() }
            <NotificationContainer/>
           </>;
        } }
      </ReactResizeDetector>
    );
  }

}

export default MessageBoardThreadView;
