import React, { Component } from 'react';

export default class ChatLinkRenderer extends Component {

  render() {
    const [name, link] = this.props.value.split("///");
    return (
      <a href={ "https://prismia.chat" + link }>{ name }</a>
    );
  }
}