import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

class SuperList extends Component {

  renderValueInput(value='', i) {
    if (i === undefined) return null;
    const { label='', values, updateValues } = this.props;
    return (
      <TextField
        fullWidth
        key={ i }
        label={ label }
        margin="normal"
        onChange={(e) => {
          const val = e.target.value;
          values[i] = val;
          updateValues(values.filter(val => !!val));
        }}
        value={ value }
      />
    );
  }

  render() {
    const { values=[], limit=Infinity } = this.props;
    const valueInputs = [];
    for (var i = 0; i < values.length; i++) {
      if (i >= limit) {
        break;
      }
      if (!values[i].trim()) continue; 
      valueInputs.push(this.renderValueInput(values[i], i));
    }
    if (i <= limit - 1) {
      valueInputs.push( this.renderValueInput('', values.length) );
    }
   
    return (
      <div className="super-list">
        <form noValidate autoComplete="off">
          { valueInputs }
        </form>
      </div>
    );
  }

}

export default SuperList;
