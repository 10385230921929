import React, { Component } from 'react';
import SimpleAdminNav from '../SimpleAdminNav';
import DataTable from '../DataTable'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { updateTitleBar, isAdmin } from '../utils';
import "./style.css";

class MetricsView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      metrics: null,
    };
    this.state = {
      singleTable: false,
      daysLoaded: 3,
      rowsDisplayed: -1,
    };
    this.setRefs(this.props);
    this.getRoles();
  }

  getRoles() {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('instructors')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ instructors: data.userIds });
      }).catch(console.error);
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('tas')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ tas: data.userIds });
      }).catch(console.error);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setRefs(newProps);
  }

  setRefs(props) {
    this.subMetrics(props);
  }

  subMetrics(props) {
    if (this.unsub.metrics) this.unsub.metrics();
    const { db, projectId } = props;
    this.unsub.metrics = db
      .collection('projects')
      .doc(projectId)
      .collection('metrics-daily-activity')
      .orderBy('id', 'desc')
      .onSnapshot(snap => {
        const docs = snap.docs.map(doc => doc.data());
        const dailyCounts = {};
        for (let i = 0; i < docs.length; i++) {
          if (!docs[i].id || !docs[i].counts) continue;
          dailyCounts[docs[i].id] = docs[i].counts;
          for (let id in docs[i].counts) {
            if (docs[i].numberOfScoredQuestions) {
              dailyCounts[docs[i].id][id].numberOfScoredQuestions = docs[i].numberOfScoredQuestions || 0;
            }
            if (docs[i].clusters) {
              dailyCounts[docs[i].id][id].numberOfScoredOpenResponse = Object.keys(docs[i].clusters).length || 0;
            }
          }
        }
        const allStudents = {};
        for (let students of Object.values(dailyCounts)) {
          for (let student of Object.keys(students)) {
            allStudents[student] = true;
          }
        }
        const allStudentRecords = {};
        Promise.all(Object.keys(allStudents)
          .map( student =>
            db.collection('users')
              .doc(student)
              .get()
              .then( snap => {
                const studentRecord = snap.data();
                allStudentRecords[student] = studentRecord;
              }).catch(console.error)
          )
        ).then( () => {
            const fields = ['displayName', 'photoUrl', 'studentId'];
            for (let day of Object.keys(dailyCounts)) {
              for (let student of Object.keys(dailyCounts[day])){
                for (let field of fields) {
                  if (allStudentRecords[student] && allStudentRecords[student][field]) {
                    dailyCounts[day][student][field] = allStudentRecords[student][field];
                  }
                }
              }
            }
            this.setState({ dailyCounts });
        });
      });
  }

  componentWillUnmount() {
    if (this.unsub.user) this.unsub.user();
  }

  backfill() {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('messages')
      .get()
      .then(snap => {
        const data = snap.docs.reduce((acc, doc) => {
          const message = doc.data();
          if (!message) return acc;
          if (!message.timestamp) return acc;
          if (!message.author) return acc;
          if (!message.authorDisplayName) return acc;
          if (message.author === '000-0000-000') return acc;
          if (!message.timestamp.toDate) return acc;
          const day = message.timestamp.toDate().toISOString().slice(0, 10);
          if (!acc[day]) acc[day] = {};
          if (!acc[day][message.author]) {
            acc[day][message.author] = {
              count: 0,
              displayName: message.authorDisplayName,
              photoUrl: message.authorPhotoUrl,
              userId: message.author,
            };
          }
          acc[day][message.author].count++;
          return acc;
        }, {});
        return data;
      }).then(data => {
        for (let day in data) {
          const rec = {
            counts: data[day],
            id: day,
          };
          db.collection('projects')
            .doc(projectId)
            .collection('metrics-daily-activity')
            .doc(day)
            .set(rec);
        }
      }).catch(console.error);
  }

  hide() {
    const { currentUser={} } = this.props;
    const { instructors={}, tas={} } = this.state;
    if (isAdmin(currentUser.id)) return false;
    if (instructors[currentUser.id]) return false;
    if (tas[currentUser.id]) return false;
    return true;
  }

  compileRowData() {
    const { dailyCounts={} } = this.state;
    const days = Object.keys(dailyCounts);
    const length = o => {
      if (!o) return 0;
      return Object.keys(o).length;
    }
    const rows = [];
    days.forEach((day, dayIdx) => {
      if (!dailyCounts[day]) return null;
      const studentIds = Object.keys(dailyCounts[day]);
      for (let i = 0; i < studentIds.length; i++) {
        const rec = dailyCounts[day][studentIds[i]];
        let openResponseStars = 0;
        if (rec.clusters) {
          openResponseStars = Object.values(rec.clusters).filter( s => s === "starred").length;
        }
        rows.push({
          date: day,
          displayName: rec.displayName || "",
          studentId: rec.studentId || "",
          count: rec.count || 0,
          stars: rec.stars || 0,
          score: rec.score || 0,
          scoredResponses: rec.scoredResponses || 0,
          numberOfScoredQuestions: rec.numberOfScoredQuestions || 0,
          openResponseAnswers: length(rec.clusters) || 0,
          openResponseStars,
          numberOfScoredOpenResponse: rec.numberOfScoredOpenResponse || 0,
          photoUrl: rec.photoUrl,
        });
        //if (i === 0 && dayIdx > 0) {
        //  rows[rows.length-1].firstInGroup = true;
        //}
      }
    });
    return rows;
  }

  render() {
    updateTitleBar('Metrics');
    const { db, router, projectId, currentUser={} } = this.props;
    const activityData = {
      columnDefs: [{
        headerName: 'Date',
        field: 'date',
      },
      {
        headerName: '',
        field: 'photoUrl',
        cellRenderer: 'imageCellRenderer',
        width: 30,
        maxWidth: 30,
        cellStyle: {padding: 0},
      },
      {
        headerName: 'Name',
        field: 'displayName',
        cellStyle: {marginLeft: "2px"}
      },
      {
        headerName: 'Student ID',
        field: 'studentId',
        hide: true,
      }, {
        headerName: 'Messages',
        field: 'count',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      }, {
        headerName: 'Stars',
        field: 'stars',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Multiple\nChoice\nCorrect',
        field: 'score',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Multiple\nChoice\nAnswered',
        field: 'scoredResponses',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Multiple\nChoice\nAsked',
        field: 'numberOfScoredQuestions',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Open\nResponse\nStars',
        field: 'openResponseStars',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Open\nResponse\nAnswered',
        field: 'openResponseAnswers',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Open\nResponse\nAsked',
        field: 'numberOfScoredOpenResponse',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
      }],
      rowData: this.compileRowData(),
    }
    const exportColumnKeys = ['date', 'displayName',
                 'studentId', 'count', 'stars',
                 'score', 'scoredResponses',
                 'numberOfScoredQuestions',
                 'openResponseStars',
                 'openResponseAnswers',
                 'numberOfScoredOpenResponse'];
    return (
      <div className="metrics-view full-height">
        <SimpleAdminNav
          currentUser={ currentUser }
          projectId={ projectId }
          db={ db }
          router={ router } />
        <h2 className="title centered">Class Metrics</h2>
        <DataTable
          exportColumnKeys={ exportColumnKeys }
          columnDefs={ activityData.columnDefs }
          rowData={ activityData.rowData }
          height={ "calc(100% - 150px)" }/>
      </div>
    );
  }

}

export default MetricsView;
