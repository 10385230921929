import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

const quillModules = {
  toolbar: false,
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
  markdownShortcuts: {},
  blotFormatter: {},
};

function Suggestion(value='') {
  return {
    value,
    score: 0,
  };
}

class OpenResponseList extends Component {

  shouldComponentUpdate(nextProps) {
    const propsToWatch = ["storage" ,"label", "noToolbar", "limit", "values", "fullEditor"];
    for (let prop of propsToWatch) {
      if (nextProps[prop] !== this.props[prop]) {
        return true;
      }
    }
    return false;
  }

  renderValueInput(suggestion=null, i) {
    const { readOnly=false, keyDownCb } = this.props;
    if (i === undefined) return null;
    if (!suggestion) suggestion = Suggestion();
    const { label='', values, updateValues } = this.props;
    const suggestions = values;

    return (
      <div
        key={ i }
        className="flex-container v-centered suggestion-input-card">
        <div className="suggestion-input flex-child">
          <Tooltip
            title="System will attempt to respond based on intent expressed by student"
            multiline
            enterDelay={ 1200 }
            placement="top">
            <TextField
              label={ label }
              fullWidth
              InputLabelProps={{
                className: "suggestion-placeholder-text",
              }}
              InputProps = {{
                className: "suggestion-actual-text suggested-response-tab-focus",
                readOnly: readOnly
              }}
              margin="normal"
              onChange={(e) => {
                const val = e.target.value;
                if (typeof suggestions[i] === 'string') {
                  suggestions[i] = Suggestion(suggestions[i]);
                }
                if (!suggestions[i]) {
                  suggestions[i] = Suggestion(val);
                } else {
                  suggestions[i].value = val;
                }
                updateValues(suggestions.filter(suggestion => !!suggestion.value));
              }}
              value={ suggestion.value }
              onKeyDown={ event => {
                if (event.keyCode === 27) {
                  event.target.blur();
                  if (keyDownCb) {
                    keyDownCb();
                  }
                }
              }}
            />
          </Tooltip>
        </div>
        { suggestions[i] && !this.props.hideSwitches ? (
          <div className="v-switch-container">
            <Tooltip
              title="Mark as correct"
              tabIndex={ -1 }>
              <Switch
                className="suggestion-switch"
                color="primary"
                inputProps={{ 'aria-label': 'mark as correct' }}
                onChange={(e) => {
                  const score = e.target.checked ? 1 : 0;
                  if (typeof suggestions[i] === 'string') {
                    suggestions[i] = Suggestion(suggestions[i]);
                  }
                  suggestions[i].score = score;
                  updateValues(suggestions.filter(suggestion => !!suggestion.value));
                }}
                checked={ !!suggestions[i].score }
              />
            </Tooltip>
          </div>
        ) : null }
        { i < values.length ?
            <div className="suggested-response-reply-container">
              <ReactQuill
                modules={ quillModules }
                value={ values[i] && values[i].reply ? JSON.parse(values[i].reply) : {ops: []} }
                onChange={ (content, delta, source, editor) => {
                  suggestions[i].reply = JSON.stringify(editor.getContents());
                  updateValues(suggestions);
                } }
                placeholder={ this.props.hidePlaceholder ? "" : "Suggested reply..."}/>
            </div> : null }
        </div>
    );
  }

  render() {
    const { values=[], limit=Infinity, hideLast=false } = this.props;
    const valueInputs = [];
    for (var i = 0; i < values.length; i++) {
      if (i >= limit) {
        break;
      }
      if (typeof values[i] === 'string') values[i] = Suggestion(values[i]);
      if (!values[i].value.trim()) continue;
      valueInputs.push(this.renderValueInput(values[i], i));
    }
    if (i <= limit - 1) {
      valueInputs.push( this.renderValueInput(null, values.length) );
    }

    return (
      <div className="super-list">
        <form noValidate autoComplete="off">
          { (hideLast ?
             valueInputs.slice(0, valueInputs.length - 1) :
             valueInputs) }
        </form>
      </div>
    );
  }

}

export default OpenResponseList;
