import React, { Component } from 'react';
import DataTable from './DataTable';
import * as CSV from 'csv-string';
import Editor from 'react-simple-code-editor';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class CsvTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
    }
  }

  setData(data, Cb) {
    if (this.props.setData) {
      this.props.setData(data, Cb);
    }
  }

  toggleToolbar() {
    const { data } = this.props;
    data.noToolbar = !data.noToolbar;
    this.setData(data);
  }

  toggleSizeToFit() {
    const { data } = this.props;
    data.sizeToFit = !data.sizeToFit;
    this.setData(data);
  }

  setString(string) {
    const { data } = this.props;
    data.string = string
    this.setData(data);
  }

  renderEditor() {
    return <Editor
      className="jessiecode-editor"
      value={ this.props.data.string }
      onValueChange={ string => this.setString(string) }
      highlight={ code => code }
      padding={10}
      style={{
        fontFamily: '"Menlo", "Monaco", "Fira code", "Fira Mono", monospace',
        fontSize: 14,
      }}/>;
  }

  renderTable() {
    let { data } = this.props;
    if (typeof data === 'string') {
      data = {
        string: data,
        noToolbar: false,
        sizeToFit: false,
      }
    }
    let rows, columnDefs, rowData;
    try {
      rows = CSV.parse(data.string);
      columnDefs = rows[0].map((name, idx) => {
        const def = {
          headerName: name,
          field: name,
        };
        if (rows.slice(1).every(row => !isNaN(row[idx]))) {
          def.type = 'numericColumn';
          def.filter = 'agNumberColumnFilter';
        }
        return def;
      });
      // convert each row from an array to an object
      rowData = rows.slice(1).map(row => 
        row.map((field, i) => [rows[0][i], columnDefs[i]?.type ? parseFloat(field) : field])
          .reduce((acc, curr) => {acc[curr[0]] = curr[1]; return acc}, {})
      );
    } catch (err) {
      console.error(err);
      return <div>Problem with your table data. Separate entries with commas or tabs, and separate your rows with newlines.</div>
    }
    return <DataTable
      columnDefs={ columnDefs }
      rowData={ rowData }
      noToolbar={ !!data.noToolbar }
      sizeToFit={ !!data.sizeToFit }
    />;
  }

  renderButtonPanel() {
    return <FormGroup row style={{marginTop: "1em", justifyContent: 'center'}}>
    <Button
      variant="contained"
      color="primary"
      style={{marginRight: "20px"}}
      onClick={() => this.setState({showEditor: !this.state.showEditor}) }>
      { this.state.showEditor ? "View Table" : "Table Editor" }
    </Button>
    { !this.state.showEditor && this.props.hideTable ? <Button
      variant="outlined"
      color="secondary"
      onClick={() => this.setData({string: ''}, () => this.props.hideTable())}>
      <DeleteOutlinedIcon
        style={ {color: "rgb(249 117 108)"} }
      />
    </Button> : null }
    { this.state.showEditor ? <FormControlLabel
      control={
        <Switch
          color='primary'
          checked={ !this.props?.data?.noToolbar }
          onChange={ () => this.toggleToolbar() }
          />
        }
      label="Toolbar"
    /> : null }
    { this.state.showEditor ? <FormControlLabel
      control={
        <Switch
          color='primary'
          checked={ !!this.props?.data?.sizeToFit }
          onChange={ () => this.toggleSizeToFit() }
          />
        }
      label="Size Columns to Fit"
      /> : null }
    </FormGroup>
  }

  render() {
    return <>
      { this.props.setData ? this.renderButtonPanel() : null }
      { this.state.showEditor ? this.renderEditor() : this.renderTable() }
    </>;
  }
}

export default CsvTable;
