import React, { Component } from 'react';
import SimpleAdminNav from '../SimpleAdminNav';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { updateTitleBar, reorder } from '../utils';
import moment from 'moment-timezone';
import { getDocumentButtonStyle, DocumentButton } from '../mui-themes';
import './style.css';


class DrillsView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      drills: null,
    };
    this.state = {
      instructors: {},
      tas: {},
      hideMode: false,
    };
  }

  componentDidMount() {
    this.subProject(this.props);
    this.subDrillOrder(this.props);
    this.subDrills(this.props);
    this.subProject(this.props);
  }

  subProject(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const doc = snap.data();
        if (doc.timeZone) moment.tz.setDefault(doc.timeZone);
        this.setState({ project: doc });
      });
  }

  subDrills(props, Cb) {
    if (this.unsub.drills) this.unsub.drills();
    const { drillOrder } = this.state;
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.drills = db
      .collection('projects')
      .doc(projectId)
      .collection('drills')
      .orderBy("timestamp", "desc")
      .onSnapshot(snap => {
        if (!snap.docs) return;
        let drills = snap.docs.map(doc => doc.data());
        drills = drills.filter(a => a.published);
        if (drillOrder) {
          drills.sort( (drill1, drill2) => {
            if (drillOrder.indexOf(drill1.id) < drillOrder.indexOf(drill2.id)) {
              return -1;
            } else {
              return 1;
            }
          });
        }
        this.setState({ drills });
        if (Cb) Cb(drills);
      });
  }

  subDrillOrder(props) {
    if (this.unsub.drillOrder) this.unsub.drillOrder();
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.drillOrder = db
      .collection('projects')
      .doc(projectId)
      .collection('drill-order')
      .doc('ids')
      .onSnapshot(doc => {
        const ids = doc.data();
        if (!ids || !ids.drillOrder) {
          this.subDrills(this.props, (drills) =>
          this.setDrillOrder(drills.map(l => l.id)));
        } else {
          this.setState({ drillOrder: ids.drillOrder }, () => this.subDrills(this.props));
        }
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
  }

  nameDrill(drillTitle='') {
    this.setState({ drillTitle });
  }

  describeDrill(drillDescription) {
    this.setState({ drillDescription });
  }

  hideDrill(drillId, hide=true) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .set({ hidden: hide }, {merge: true})
      .catch(console.error);
  }

  renderDrillCard(drill, index) {
    const { projectId, router } = this.props;
    const link = '/projects/' + projectId + '/drills/' + drill.id;
    return (
      <DocumentButton
        key={"list-item-" + drill.id}
        onClick={ () => router.history.push(link) }
        button
        style={getDocumentButtonStyle()}>
        <ListItemText
          primary={ drill.title }
          secondary={ drill && drill.description ? drill.description : null }
          className= "assignment-card-text" />
      </DocumentButton>
    );
  }

  onDragEnd(result) {
    const { drills, drillOrder, hideMode } = this.state;
    // dropped outside the list
    if (!result.destination || !drillOrder) {
      return;
    }
    const visibleDrills = hideMode ? drills : drills.filter(l => !l.hidden);
    const indexMap = (i) => drillOrder.indexOf(visibleDrills[i].id);
    const start = indexMap(result.source.index);
    const end = indexMap(result.destination.index);
    const newDrills = reorder(drills, start, end);
    this.setState({ drills: newDrills }, () =>
      this.setDrillOrder(newDrills.map( l => l.id ))
    );
  }

  setDrillOrder(drillOrder) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('drill-order')
      .doc('ids')
      .set({ drillOrder })
      .catch(console.error)
  }

  renderDrillCards() {
    const { hideMode } = this.state;
    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? "lightblue" : "white",
      marginTop: "40px",
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    });
    const { drills=[] } = this.state;
    const cards = drills.filter(
        l => hideMode || !l.hidden
      ).map( (l, i) => this.renderDrillCard(l, i));
    return (
      <List
        style={getListStyle(false)}>
        { cards }
      </List>
    );
  }

  renderDrillsArea() {
    return (
      <div>
        <div className="padded">
          { this.renderDrillCards() }
        </div>
      </div>
    );
  }

  render() {
    updateTitleBar('Drills');
    const { db, router, projectId, currentUser={} } = this.props;
    const { project } = this.state;
    return (
      <div className="assignments-view">
        <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } studentView={ true }/>
        <div className="y-scrollable">
          <div className="assignment-buttons-area"> 
            <h1
              className="assignment-title centered">
              { project && project.displayName ? project.displayName : null }
            </h1>
            { project && project.description && project.description.length ? <h2
              className="description-text centered">
              { project.description }
            </h2> : null }
            <h3 className="centered" style={{marginTop: "40px"}}>
              Practice Exercises
            </h3>
            { this.renderDrillsArea() }
          </div>
        </div>
      </div>
    );
  }

}

export default DrillsView;
