import uuid from 'uuid/v4';

export function Analytics() {
  const instructorId = '000-0000-000';
  const automatedMessageIds = new Set();
  const studentMessageIds = new Set();
  return function(action, message) {
    if (!message) return;
    if (!action) return;
    const category = message.author === instructorId ? 'automated-message' : 'student-message';
    let id = null;
    if (category === 'automated-message') {
      id = message.parentHocketRef ? message.parentHocketRef.id : null;
      if (automatedMessageIds.has(id)) return;
      automatedMessageIds.add(id);
    }
    if (category === 'student-message') {
      id = message.id;
      if (studentMessageIds.has(id)) return;
      studentMessageIds.add(id);
    }
    if (!id) return;
    const opts = {
      event_category: category,
      event_label: id,
    }
    window.gtag('event', action, opts);
  }
}

function logAction(category, action, id) {
  if (!action || !id || !category) return;
  const opts = {
    event_category: category,
    event_label: id,
  }
  window.gtag('event', action, opts);
}

function logStudentAction(action, id) {
  logAction('student-action', action, id);
}

function logInstructorAction(action, id) {
  logAction('instructor-action', action, id);
}

export function joinCourse(id) {
  logStudentAction('join-course', id);
}

export function cookieError(id) {
  logStudentAction('cookieError', id);
}

export function pinMessage(id) {
  logStudentAction('pin-message', id);
}

export function typingOn(id=uuid()) {
  logStudentAction('typing-on', id);
}

export function typingOff(id=uuid()) {
  logStudentAction('typing-off', id);
}

export function sharedLessonNext(userId='', lessonId='') {
  const opts = { userId, lessonId };
  window.gtag('event', 'shared-lesson-next', opts);
}

export function sharedLessonView(userId='', lessonId='') {
  const opts = { userId, lessonId };
  window.gtag('event', 'shared-lesson-view', opts);
}

export function studentHelpScreen(id=uuid()) {
  logStudentAction('student-help-screen', id);
}

export function instructorHelpScreen(id) {
  logInstructorAction('instructor-help-screen', id);
}

export function addClusterBlock(id) {
  logInstructorAction('add-cluster-block', id);
}

export function messageStudent(id) {
  logInstructorAction('message-student', id);
}

export function messageCluster(id) {
  logInstructorAction('message-cluster', id);
}

export function messageClass(id) {
  logInstructorAction('message-class', id);
}

export function manuallySortLessonMessage(id=uuid()) {
  logInstructorAction('manually-sort-lesson-message', id);
}

export function manuallySortAssignmentMessage(id=uuid()) {
  logInstructorAction('manually-sort-assignment-message', id);
}

export function manuallySortDrillMessage(id=uuid()) {
  logInstructorAction('manually-sort-drill-message', id);
}

export function manuallySortClusteredMessage(id=uuid()) {
  logInstructorAction('manually-sort-clustered-message', id);
}

export function peerResponseQuestion(id=uuid()) {
  logInstructorAction('peer-response-question', id);
}

export function peerResponseSent(id=uuid()) {
  logStudentAction('peer-response-sent', id);
}

export function clearClusterBlock(id=uuid()) {
  logInstructorAction('clear-cluster-block', id);
}

export function openCoursesDrawer(id=uuid()) {
  logInstructorAction('open-courses-drawer', id);
}

export function openLessonsDrawer(id=uuid()) {
  logInstructorAction('open-lessons-drawer', id);
}

export function selectLessonFromDrawer(id=uuid()) {
  logInstructorAction('select-lesson-from-drawer', id);
}

export function responseInProgress(id=uuid()) {
  logInstructorAction('response-in-progress', id);
}

export function clusterMessages(id=uuid()) {
  logInstructorAction('cluster-messages', id);
}

export function openMicroChat(id=uuid()) {
  logInstructorAction('open-micro-chat', id);
}

export function deleteCourse(id) {
  logInstructorAction('delete-course', id);
}

export function toggleOnStudent(id=uuid()) {
  logInstructorAction('toggle-on-student', id);
}

export function toggleOffStudent(id=uuid()) {
  logInstructorAction('toggle-off-student', id);
}

export function toggleOnObserver(id=uuid()) {
  logInstructorAction('toggle-on-observer', id);
}

export function toggleOffObserver(id=uuid()) {
  logInstructorAction('toggle-off-observer', id);
}

export function toggleOnTa(id=uuid()) {
  logInstructorAction('toggle-on-ta', id);
}

export function toggleOffTa(id=uuid()) {
  logInstructorAction('toggle-off-ta', id);
}

export function toggleOnInstructor(id=uuid()) {
  logInstructorAction('toggle-on-instructor', id);
}

export function toggleOffInstructor(id=uuid()) {
  logInstructorAction('toggle-off-instructor', id);
}

export function createLesson(id) {
  logInstructorAction('create-lesson', id);
}

export function createAssignment(id) {
  logInstructorAction('create-assignment', id);
}

export function createDrill(id) {
  logInstructorAction('create-drill', id);
}

export function saveAssignmentMessage(id=uuid()) {
  logStudentAction('save-assignment-message', id);
}

export function saveDrillMessage(id=uuid()) {
  logStudentAction('save-drill-message', id);
}

export function saveLessonMessage(id=uuid()) {
  logInstructorAction('save-lesson-message', id);
}

export function createCourseFromTopOfLandingPage(id) {
  logInstructorAction('create-course-from-top-of-landing-page', id);
}

export function createCourse(id) {
  logInstructorAction('create-course', id);
}

export function loginToCreateCourse(id=uuid()) {
  logInstructorAction('login-to-create-course', id);
}
