import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import { projectSortFunc } from './utils';

class SimpleAdminNav extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      achievements: null,
    };
    this.openMenu = this.openMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openHamburgerMenu = this.openHamburgerMenu.bind(this);
    this.handleHamburgerClose = this.handleHamburgerClose.bind(this);
    this.state = {
      achievements: [],
      instructorOf: {},
      projectsOwned: {},
      taOf: {},
      hiddenCourses: new Set(),
      menuOpenRef: null,
      hamburgerMenuOpenRef: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsToCheck = ['currentUser', 'projectId', 'db', 'router', 'children']
    for (let prop of propsToCheck) {
      if (nextProps[prop] !== this.props[prop]) {
        return true;
      }
    }
    if (nextState !== this.state) {
      return true;
    }
    // const statesToCheck = ['projectOf', 'taOf']
    // for (let state of statesToCheck) {
    //   if (nextState[state] !== this.state[state]) {
    //     return true;
    //   }
    // }
    return false;
  }

  componentDidMount() {
    this.subCourses();
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
  }
  // subCourses
  subCourses() {
    this.subOwned();
    this.subStudentOf();
    this.subTaOf();
    this.subInstructorOf();
  }

  subOwned() {
    const { db, projectId, currentUser } = this.props;
    if (!db || !currentUser) return null;
    if (this.unsub.coursesOwned) this.unsub.coursesOwned();
    this.unsub.coursesOwned = db
      .collection('meta')
      .doc('roles')
      .collection('owners')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || {};
        const projects = data.projects || [];
        for (let i = 0; i < projects.length; i++) {
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { projectsOwned } = this.state;
                projectsOwned[project.id] = project;
                this.setState({ projectsOwned });
                if (project.id === projectId) this.setState({ displayName: project.displayName });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  subStudentOf() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) return null;
    const { hiddenCourses } = this.state;
    if (this.unsub.studentOf) this.unsub.studentOf();
    this.unsub.studentOf = db
      .collection('meta')
      .doc('roles')
      .collection('students')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        let data = snap.data() || [];
        const projects = Object.keys(data);
        for (let i = 0; i < projects.length; i++) {
          if (data[projects[i]] === "hide") {
            hiddenCourses.add(projects[i]);
          }
        }
      }).catch(console.error);
    this.setState({ hiddenCourses });
  }

  subTaOf() {
    const { db, projectId, currentUser } = this.props;
    if (!db || !currentUser) return null;
    if (this.unsub.taOf) this.unsub.taOf();
    this.unsub.taOf = db
      .collection('meta')
      .doc('roles')
      .collection('tas')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        let data = snap.data() || [];
        const projects = Object.keys(data);
        for (let i = 0; i < projects.length; i++) {
          if (!data[projects[i]]) continue;
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { taOf } = this.state;
                taOf[project.id] = project;
                this.setState({ taOf });
                if (project.id === projectId) this.setState({ displayName: project.displayName });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  subInstructorOf() {
    const { db, projectId, currentUser } = this.props;
    if (!db || !currentUser) return null;
    if (this.unsub.instructorOf) this.unsub.instructorOf();
    this.unsub.instructorOf = db
      .collection('meta')
      .doc('roles')
      .collection('instructors')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || [];
        const projects = Object.keys(data);
        for (let i = 0; i < projects.length; i++) {
          if (!data[projects[i]]) continue;
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { instructorOf } = this.state;
                instructorOf[project.id] = project;
                this.setState({ instructorOf });
                if (project.id === projectId) this.setState({ displayName: project.displayName });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  renderProjectCard(project, owner=false) {
    const { router } = this.props;
    if (!project.id) return null;
    let smartCompletion = this.props.studentView ? 'chat' : '';
    if (router && router.match && router.match.url) {
      if ((/classroom/).test(router.match.url)) {
        smartCompletion = 'classroom';
      } else if ((/lessons/).test(router.match.url)) {
        smartCompletion = 'lessons';
      } else if ((/students/).test(router.match.url)) {
        smartCompletion = 'students';
      } else if (/message-board/.test(router.match.url)) {
        smartCompletion = 'message-board';
      } else if (
          (/edit-assignment/).test(router.match.url) ||
            (/grade-assignment/).test(router.match.url)
        ) {
        smartCompletion = 'edit-assignments';
      } else if (
        (/assignments/).test(router.match.url)
      ) {
        smartCompletion = 'assignments';
      } else if ((/student-metrics/).test(router.match.url)) {
        smartCompletion = 'student-metrics';
      } else if ((/edit-drills/).test(router.match.url)) {
        smartCompletion = 'edit-drills';
      } else if ((/edit-drill/).test(router.match.url)) {
        smartCompletion = 'edit-drill';
      } else if ((/drills/).test(router.match.url)) {
        smartCompletion = 'drills';
      } else if ((/metrics/).test(router.match.url)) {
        smartCompletion = 'metrics';
      } else if ((/reports/).test(router.match.url)) {
       smartCompletion = 'class-reports';
      } else if ((/settings/).test(router.match.url)) {
        smartCompletion = 'settings';
      }
    }
    const url = '/projects/' + project.id + '/' + smartCompletion;
    return (
      <a href={ url } key={project.id} className="menu-link">
        <MenuItem
          value={ project.id }
          key={ project.id }>
          { project.displayName }
        </MenuItem>
      </a>
    );
  }

  openMenu(event) {
    this.setState({ menuOpenRef: event.target });
  };

  handleMenuItemClick(event, index) {
    this.setState({ selectedIndex: index, menuOpenRef: null });
  };

  handleClose() {
    this.setState({ menuOpenRef: null });
  };

  openHamburgerMenu(event) {
    this.setState({ hamburgerMenuOpenRef: event.target });
  };

  handleHamburgerClose () {
    this.setState({ hamburgerMenuOpenRef: null });
  }

  renderNavDropdownItem(buttonData) {
    return (
      <a href={ buttonData.url }
         key={ buttonData.name }
         className="menu-link">
       <MenuItem
         key={ buttonData.name }>
        { buttonData.name }
       </MenuItem>
      </a>
     );
  }

  renderHamburgerMenu(navButtons) {
    const { hamburgerMenuOpenRef } = this.state;
    return (
      <Menu
        open={ Boolean(hamburgerMenuOpenRef) }
        anchorEl={ hamburgerMenuOpenRef }
        onClose={ this.handleHamburgerClose }>
        { navButtons.map( (button) => this.renderNavDropdownItem(button)) }
      </Menu>
    );
  }

  renderProjectSelect(width) {
    const { projectId } = this.props;
    let { menuOpenRef, taOf={}, instructorOf={}, projectsOwned={}, hiddenCourses } = this.state;
    let activeProject = {};
    let instructed = {};
    let taed = {};
    for (let key in taOf) {
      if (key === projectId) activeProject = taOf[key];
      if (!taOf[key]) continue;
      if (!projectsOwned[key] && !instructorOf[key]) taed[key] = taOf[key];
    }
    for (let key in instructorOf) {
      if (key === projectId) activeProject = instructorOf[key];
      if (!instructorOf[key]) continue;
      if (!projectsOwned[key]) instructed[key] = instructorOf[key];
    }
    projectsOwned = Object.keys(projectsOwned).map(id => {
      if (id === projectId) activeProject = projectsOwned[id];
      return projectsOwned[id];
    });
    instructorOf = Object.keys(instructed).map(id => instructed[id]);
    taOf = Object.keys(taed).map(id => taed[id]);
    let button = null;
    let displayName = activeProject.displayName ?? '';
    if (displayName.length > 40) {
      displayName = displayName.slice(0, 40);
    }
    if (9 * displayName.length + 160 > width) {
      const cutoff = Math.floor((width - 160)/9);
      displayName = displayName.slice(0, cutoff);
    }
    if (activeProject && activeProject.displayName) {
      button = (
        <span className="project-select">
        <Button
          onClick={ (e) => this.openMenu(e) }>
          { displayName }
        </Button>
        </span>
      );
    }
    const hide = project => project.archived || hiddenCourses.has(project.id);
    const allProjects = {};
    for (let project of projectsOwned) {
      if (hide(project)) continue;
      allProjects[project.id] = project;
    }
    for (let project of instructorOf) {
      if (hide(project)) continue;
      allProjects[project.id] = project;
    }
    for (let project of taOf) {
      if (hide(project)) continue;
      allProjects[project.id] = project;
    }
    const projectList = Object.values(allProjects);
    projectList.sort(projectSortFunc);
    return (
      <>
        { button }
        <Menu
          open={ Boolean(menuOpenRef) }
          onClose={ this.handleClose }
          anchorEl={ menuOpenRef }
          id={ projectId }>
          { projectList.map(
              project => this.renderProjectCard(project, true)
          )}
        </Menu>
      </>
    );
  }

  renderNavButton(buttonData) {
    return (<Button id={ buttonData.name } key={ buttonData.name }>
        <Link to={ buttonData.url }>
          { buttonData.name }
        </Link>
      </Button>
    );
  }

  render() {
    const { projectId, studentView=false } = this.props;
    const { projectsOwned, instructorOf, studentOf, taOf } = this.state;
    const baseLink = name => '/projects/' + projectId + '/' + name;
    const navButtons = (studentView ? [
      { url: "/chat/" + projectId,
        name: "Chat" }, 
      { url: baseLink("message-board/"),
        name: "Message Board",
        key: "messageBoard" },
      { url: baseLink("assignments"),
        name: "Assignments",
        key: "assignments" },
      { url: baseLink("drills"),
        name: "Drills",
        key: "drills" },
      ] : [
      { url: "/chat/" + projectId,
        name: "Chat" },
      { url: baseLink("classroom"),
        name: "Classroom" },
      { url: baseLink("lessons"),
        name: "Lessons" },
      { url: baseLink("edit-assignments"),
        name: "Assignments",
        key: "assignments" },
      { url: baseLink("edit-drills"),
        name: "Drills",
        key: "drills" },
      { url: baseLink("students"),
        name: "Students" },
      { url: baseLink("metrics"),
        name: "Metrics" },
      { url: baseLink("class-reports"),
        name: "Reports" },
      { url: baseLink(""),
        name: "Settings" },
    ]).filter(button => {
      if (!button.key) return true;
      for (let projectsObj of [projectsOwned, instructorOf, taOf, studentOf]) {
        if (!projectsObj) continue;
        if (projectsObj[projectId] && 
             !projectsObj[projectId][button.key]) {
            return false;
        }
      }
      return true;
    });
    const imageStyle = {
      width: '36px',
      height: '36px',
      'float': 'left',
      marginRight: '7px',
      marginTop: '-2px',
    };
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({width, height}) => {
          const name = this.state.displayName || '';
          const mobile = width < 850 + 9 * Math.min(40, name.length);
          const className = width < 1080 ? "admin-nav mobile" : "admin-nav";
          return <div className={ className }>
          {
            !mobile ?
            <>
              <a href="/"><img src="/prism-only.svg" style={ imageStyle } alt="logo"/></a>
              { this.renderProjectSelect(width) }
              { navButtons.map(this.renderNavButton) }
            </> :
            <>
              <a href="/"><img src="/prism-only.svg" style={ imageStyle } alt="logo"/></a>
              { this.renderProjectSelect(width) }
              <MenuIcon
                style={{
                  color: "#fff",
                  float: "right",
                  fontSize: "35px",
                }}
                onClick={ (e) => this.openHamburgerMenu(e) }
              />
              { this.renderHamburgerMenu(navButtons) }
            </>
          }
        </div> } }
      </ReactResizeDetector>
    );
  }

}

export default SimpleAdminNav;
