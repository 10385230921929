import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import { JupyterCell } from '../JupyterCell';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CodeIcon from '@material-ui/icons/Code';
import ReorderIcon from '@material-ui/icons/Reorder';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SuggestionSuperList from '../SuggestionSuperList';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import toPlaintext from 'quill-delta-to-plaintext';
import { updateTitleBar, splitQuillDeltaOnHorizontalRule, 
         isRegistered, tryUntilSuccess, now,
         touchUpMarkdown, peelOffSuggestions, 
         markdownToDelta, mousetrapStopCallback, pingBinderKernel } from '../utils';
import Hocket from '../hocket';
import MessageForm from '../MessageForm';
import CardContents from '../CardContents';
import DrillProposal from './DrillProposal';
import { isValidDelta } from '../utils';
import 'react-quill/dist/quill.snow.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// import 'react-notifications/lib/notifications.css';
import './style.css';
import * as Mousetrap from 'mousetrap';
import { mobileThreshold } from '../constants';

const pick = (arr) => arr[Math.random() * arr.length | 0];

const scoreMessages = {
  'Room for improvement': [
    'No worries! Let\'s keep practicing!',
    'That\'s all right! Good job keeping at it.',
    'Cool... next one!'
  ],
  'Pretty good': [
    'Nice job!',
    'Good!',
    'All right!!'
  ],
  'Solid': [
    'Great job!',
    'Excellent!',
    'Terrific!'
  ]
};

const FlexButton = withStyles({
  root: {
    flex: 'none',
    color: 'black',
  },
})(IconButton);


const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

const settingsThemeColor = '#FFF';
const theme = createMuiTheme({
  palette: {
    common: { black: settingsThemeColor, white: settingsThemeColor },
    primary: { main: settingsThemeColor, dark: '#000', light: settingsThemeColor },
    text: { primary: settingsThemeColor, secondary: '#000' }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        }
      }
    },
    MuiIconButton: {
      root: { color: 'white' },
    },
    MuiPickersBasePickers: {
      container:
        { color: '#CCC' },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: '#237882',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#333'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#333',
      }
    },
    MuiPaper: {
      root: { color: '#333',
              backgroundColor: 'white' }
    },
    MuiButton: {
      textPrimary: { color: '#333'}
    },
  }
});

const SettingsButton = withStyles({
  root: {
    color: "white",
    border: "1px solid white",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    display: "block",
  }
})(Button);

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#efd' : '#fff',
});

const getItemStyle = (isDragging, draggableStyle, clusterStyle={}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#ffe' : clusterStyle.background,
    // styles we need to apply on draggables
    ...draggableStyle
});

class DrillView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      drill: null,
      project: null,
      settings: null,
    };
    this.drillRef = {};
    this.mainQuillRef = React.createRef();
    this.proposalRef = React.createRef();
    this.juniperRef = React.createRef();
    this.jupyterInputRef = React.createRef();
    this.deleteMessages = this.deleteMessages.bind(this);
    this.hocketsAreaRef = null;
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.state = {
      hockets: {},
      answerHockets: {},
      answers: {},
      drill: null,
      instructors: {},
      tas: {},
      exportFormat: 'HTML',
      activeHocketId: null,
      hocketSelectionRange: null,
      showHelp: false,
      locked: false,
      reOrder: false,
      juniperOpen: false,
      juniperHasRendered: false,
      linkClicked: false,
      published: false,
      lastBinderPing: new Date(),
      currentProblem: 0,
      regradeRequestIdMap: {},
      submitted: true,
      codeCell: null,
      overrideSubmitted: false,
      requestingOverride: false,
      latestExtensionDate: null,
      maxProblem: 0,
      submittedSolutions: new Set(),
      scores: {},
      showNav: false,
      answersSubbed: new Set(),
      proposal: false,
    };
  }

  setRefs() {
    const { db, currentUser, projectId } = this.props;
    this.projectRef = db.collection('users')
    .doc(currentUser.id)
    .collection('drill-projects')
    .doc(projectId);
  }

  componentDidMount() {
    this.setRefs();
    this.subDrill(this.props);
    this.setMousetrap();
    this.setActiveHocketId(null);
    this.handleProblemUrl();
    // this line shouldn't be necessary, but just in 
    // case the usually fullyLoaded mechanism doesn't work
    setTimeout( () => this.setState({ fullyLoaded: true}) , 3000);
    tryUntilSuccess(() => {
      if (this.state.drill?.drillProblems && this.state.answersSubbed && Object.keys(this.state.drill.drillProblems).length === this.state.answersSubbed.size) {
        this.setState({ fullyLoaded: true });
      }
    }, { wait: 100 });
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
  }

  setSubmittedStatus(status) { // status is boolean
    const { drillId } = this.props;
    this.projectRef
      .set({ [drillId]: status }, {merge: true})
      .catch(console.error);
  }

  hocketIndex(hocketId) {
    const { drillProblems } = this.state;
    return Object.keys(drillProblems).findIndex(id => id === hocketId);
  }

  handleProblemUrl() {
    const { location } = this.props;
    let pathComponents = location.pathname.split('/problems/')
    if (pathComponents && pathComponents.length > 1) {
      tryUntilSuccess(() => {
        const problemId = pathComponents[1];
        try {
          this.setState({ 
            currentProblem: Object.keys(this.state.drill.drillProblems).indexOf(problemId),
          });
          return true;
        } catch {
          return false;
        }
      }, {wait: 250});
    }
  }

  adjustActiveHocket(increment) {
    const { activeHocketId, drill } = this.state;
    const { drillProblems=[] } = drill;
    const currentIndex = this.hocketIndex(activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < drillProblems.length)) {
      this.setActiveHocketId(drillProblems[newIndex]);
    }
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }


  adjustSelectionEnd(increment) {
    const { activeHocketId,
            drill,
            hocketSelectionRange
    } = this.state;
    const { drillProblems =[] } = drill;
    const currentIndex = drillProblems.findIndex(
      x => x === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < Object.keys(drillProblems).length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  splitActiveCell() {
    const { db } = this.props;
    const { answers={}, activeHocketId } = this.state;
    const { hockets=[] } = answers;
    const idx = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    if (idx === -1) return;
    const delta = JSON.parse(this.state.hockets[hockets[idx].hocketId].responses[0]);
    const newDeltas = splitQuillDeltaOnHorizontalRule(delta);
    const answer = window.confirm(`Split current cell into ${newDeltas.length} new cells?`);
    if (!answer) return;
    const newHockets = newDeltas.map((delta) => {
      const hocket = Hocket();
      hocket.responses.push(JSON.stringify(delta));
      return peelOffSuggestions(hocket);
    });
    const batch = db.batch();
    newHockets.forEach( (hocket) => {
      batch.set(
        this.answerRef.doc(this.currentProblemId()).collection('hockets').doc(hocket.id),
        hocket, {merge: true}
      );
    });
    batch
      .commit()
      .then( () => {
        const newHocketStubs = newHockets.map(
          (hocket) => { return { hocketId: hocket.id }; }
        );
        hockets.splice(idx, 1, ...newHocketStubs);
        this.answerRef.doc(this.currentProblemId()).set({ hockets }, {merge: true});
        return newHocketStubs;
      })
      .then( (hockets) => {
        const [lastHocket] = hockets.slice(-1);
        this.setState({activeHocketId: lastHocket.hocketId});
      })
      .catch(console.error);
  }

  nextProblem() {
    const { currentProblem, maxProblem } = this.state;
    this.setState({ currentProblem: Math.min(currentProblem + 1, Object.keys(this.state.drill.drillProblems).length, maxProblem )});
  }

  prevProblem() {
    const { currentProblem } = this.state;
    this.setState({ currentProblem: Math.max(currentProblem - 1, 0)});
  }

  setMousetrap() {
    Mousetrap.bind("left", () => this.prevProblem());
    Mousetrap.bind("right", () => this.nextProblem());
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+s", () => this.splitActiveCell());
    Mousetrap.bind("shift+r", () => this.setState({ reOrder: !this.state.reOrder }));
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind("shift+=", () => this.createHocket());
    Mousetrap.bind("ctrl+j", () => this.toggleJuniper());
    Mousetrap.bind(["del", "backspace"], () => {
      this.deleteMessages();
    });
    Mousetrap.bind("esc", () => {
      if (this.state.locked) this.setState({ activeHocketId: null });
      this.setState({
        hocketSelectionRange: null,
        showHelp: false,
      });
    });
    Mousetrap.bind('enter', () => {
      setTimeout( () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.focus();
          const editor = this.mainQuillRef.current.editor;
          editor.setSelection(editor.getLength(), 0);
        }
      }, 40);
    });
    Mousetrap.bind("shift+m", () => this.convertEditorContents());
    Mousetrap.bind(["mod+/", "shift+/"], () => this.toggleHelp());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  exitHelpOrSettings() {
    this.setState({ showHelp: false, showSettings: false });
  }

  toggleHelp() {
    const { showHelp } = this.state;
    this.setState({ showHelp: !showHelp});
  }

  setCodeCell(language) {
    this.drillRef.set({ codeCell: language }, {merge: true});
  }

  setDisplayMode(setting) {
    this.drillRef.set({ published: setting }, {merge: true});
  }

  renderSettings() {
    return (<ThemeProvider theme={ theme }>
        <div className="help-info">
          <h1>Settings and Tools</h1>
          <ul>
            <li> Lock the messages so they don't open or highlight when clicked: </li>
              <FormGroup>
              <FormControlLabel
                control={ <WhiteCheckbox checked={ !!(this.state.locked) } color="primary" inputProps={{ 'aria-label': 'Lock messages' }} onChange={ e => this.setState({ locked: e.target.checked })}/> }
                label={ "Lock messages" }/>
              </FormGroup>
          </ul>
          <ul>
            <li> Show arrow buttons to navigate through previous exercises </li>
              <FormGroup>
              <FormControlLabel
                control={ <WhiteCheckbox checked={ !!(this.state.showNav) } color="primary" inputProps={{ 'aria-label': 'Show navigation arrows' }} onChange={ e => this.setState({ showNav: e.target.checked })}/> }
                label={ "Show navigation arrows" }/>
              </FormGroup>
          </ul>
          <ul>
            <li> Propose new exercises to add to this drill </li>
          </ul>
          { this.state.proposal ? <SettingsButton
            onClick={() => this.setProposal(false) }>
              Back to Practice
          </SettingsButton> : <SettingsButton
            onClick={() => this.setProposal(true) }>
              Propose New Exercises
          </SettingsButton> }
        </div>
      </ThemeProvider>
    );
  }

  helpInfo() {
    return (<div className="help-info">
      { this.state.proposal ? <><h1>Proposals</h1>

      <ul className="documentation-list">
      <li> An exercise statement may span multiple messages. The last message must begin with ® (<tt>\required + [tab]</tt>) </li>
      <li> Each exercise statement should be followed by any number of solution messages. Each must begin with <span role="img" aria-label="note">📝</span> (<tt>\note + [tab]</tt>). </li>
      <li> Click the <em>Propose</em> button to send all of your exercises to the instructor. You may edit any messages or add more and press the button again. Your instructor will get the latest version of every problem they have not already reviewed. </li>
      </ul>
      </> : null }

      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move selected message up one</li>
      <li><tt>down</tt> move selected message down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia drills or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      <li><tt>cmd/ctrl+j</tt> export this drill as a Jupyter notebook</li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>
    </div>);
  }

  unsetMousetrap() {
    Mousetrap.unbind("mod+j");
    Mousetrap.unbind("left");
    Mousetrap.unbind("right");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("shift+s");
    Mousetrap.unbind("shift+r");
    Mousetrap.unbind("shift+=");
    Mousetrap.unbind("del");
    Mousetrap.unbind("backspace");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("shift+/");
    Mousetrap.unbind("mod+/");
    Mousetrap.unbind("ctrl+j");
  }

  convertEditorContents() {
    if (!this.mainQuillRef) return;
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);
    const editor = this.mainQuillRef.current.editor;
    const text = toPlaintext(editor.getContents());
    const ops = text.split("\n---\n").map(cellText => markdownToDelta(cellText).concat([{insert: {hr: true}}]));
    editor.setContents({ops: flatten(ops).slice(0, -1)});
    setTimeout( () => this.mainQuillRef.current.focus(), 50 );
  }

  subDrill(props) {
    if (this.unsub.drill) this.unsub.drill();
    const { db, currentUser, projectId, drillId } = props;
    if (!currentUser) return console.log(
      'no current user',
      currentUser,
      props.currentUser
    );
    this.drillRef = db
      .collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId);
    this.unsub.drill = this.drillRef
      .onSnapshot(snap => {
        const drill = snap.data();
        if (!drill || !drill.drillProblems) return;
        drill.drillProblems = JSON.parse(drill.drillProblems);
        for (let [problemId, problem] of Object.entries(drill.drillProblems)) {
          this.subAnswer(problemId);
          for (let hocketId of (problem.statementHockets || [])) {
            if (this.unsub[hocketId]) continue;
            this.subHocket(hocketId);
          }
          for (let hocketId of (problem.solutionHockets || [])) {
            if (this.unsub[hocketId]) continue;
            this.subHocket(hocketId);
          }
        }
        this.setState({ drill });
        this.setState({ codeCell: drill.codeCell || null });
        this.setState({ published: drill.published || null});
      });
  }

  subAnswer(hocketId) {
    if (this.unsub && this.unsub[hocketId + '-answer']) {
      this.unsub[hocketId + '-answer']();
    }
    const { db, currentUser, projectId, drillId } = this.props;
    if (!currentUser) return console.log(
      'no current user', currentUser,
    );
    const { answersSubbed } = this.state;
    answersSubbed.add(hocketId)
    this.setState({ answersSubbed });
    this.answerRef = db
      .collection('users')
      .doc(currentUser.id)
      .collection('drill-projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .collection('problems');
    this.unsub[hocketId + '-answer'] = this.answerRef
      .doc(hocketId)
      .onSnapshot(snap => {
        const { answers, submittedSolutions, scores } = this.state;
        const answer = snap.data();
        if (!answer) return;
        const { hockets=[], submitted, score } = answer;
        if (submitted) {
          submittedSolutions.add(hocketId);
        }
        if (score) {
          scores[hocketId] = score;
        }
        if (submitted && score) {
          let newProblem = Math.max(this.state.maxProblem, 
            Object.keys(this.state.drill.drillProblems).indexOf(hocketId) + 1);
          setTimeout( () => 
            this.setState({ maxProblem: newProblem, currentProblem: newProblem }),
            this.updateFromScoreClick ? 1250 : 0
          );
        }
        const allHockets = hockets.slice();
        for (let i = 0; i < allHockets.length; i++) {
          if (this.unsub[allHockets[i].hocketId + '-answer']) continue;
          this.subAnswerHocket(hocketId, allHockets[i].hocketId);
        }
        answers[hocketId] = answer;
        this.setState({ answers, submittedSolutions, scores });
        this.updateFromScoreClick = false;
      });
  }

  subHocket(hocketId) {
    const { db, projectId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
      });
  }

  isRegistered(id) {
    const { hockets } = this.state;
    if (!hockets || !hockets[id] || !hockets[id].responses ||
        !hockets[id].responses[0]) return false;
    return isRegistered(hockets[id]);
  }

  currentProblemId() {
    const { drill, currentProblem } = this.state;
    return Object.keys(drill.drillProblems)[currentProblem];
  }

  subAnswerHocket(problemHocketId, hocketId) {
    const { db, projectId, currentUser, drillId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('users')
      .doc(currentUser.id)
      .collection('drill-projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .collection('problems')
      .doc(problemHocketId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { answerHockets } = this.state;
        answerHockets[hocket.id] = hocket;
        this.setState({ answerHockets });
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    window.removeEventListener('copy', this.handleCopy);
    window.removeEventListener('paste', this.handlePaste);
    clearInterval(this.timerID);
    this.unsetMousetrap();
  }

  setActiveHocketId(activeHocketId) {
    this.setState({ activeHocketId,
                    hocketSelectionRange: null }, () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.blur();
        }
    });
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, answers } = this.state;
    const answer = answers[this.currentProblemId()];
    if (!answer) return;
    const { hockets } = answer;
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  exportDrill() {
    const { exportedDrill } = this.state;
    this.setState({ exportedDrill: !exportedDrill});
  }

  deleteMessages() {
    const {
      answers={},
      activeHocketId,
      hocketSelectionRange,
    } = this.state;
    const { hockets=[] } = answers[this.currentProblemId()];
    if (!activeHocketId) {
      console.log("No hocket selected");
      return null;
    }
    let range, answer, currentIndex;
    if (hocketSelectionRange) {
      answer = window.confirm("Are you sure you want to delete the selected messages?")
      range = [...hocketSelectionRange];
    } else {
      answer = window.confirm("Are you sure you want to delete the selected message?")
      currentIndex = hockets.findIndex(hocket => hocket.hocketId === activeHocketId);
      range = [currentIndex, currentIndex];
    }
    range = range.sort((a,b) => a-b);
    if (answer) {
      let nextHocketId = null;
      if (currentIndex < hockets.length - 1) {
        nextHocketId = hockets[currentIndex + 1].hocketId;
      }
      const remainingHockets = hockets.filter((hocket, i) => {
        return ((i < range[0]) || (i > range[1]));
      });
      this.setState({ activeHocketId: nextHocketId });
      return this.answerRef.doc(this.currentProblemId()).set(
           { hockets: remainingHockets },
           { merge: true }
      );
    }
    this.setSubmittedStatus(false);
  }

  handleCopy(event) {
    const { locked, hocketSelectionRange, reOrder } = this.state;
    if (!this.state.proposal && (hocketSelectionRange ||
          ((locked || reOrder) && document.activeElement === this.hocketsAreaRef))) {
      let numMessages = 1;
      if (hocketSelectionRange) {
        numMessages = Math.abs(hocketSelectionRange[1] - hocketSelectionRange[0]) + 1;
      }
      const [jsonData, html, md] = this.copyHockets();
      event.clipboardData.setData('application/json',
      jsonData);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', md);
      NotificationManager.info(`${numMessages} message${numMessages === 1 ? "" : "s"} copied!`);
      event.preventDefault();
    }
  }

  handlePaste(event) {
    const clipText = event.clipboardData.getData('application/json');
    if (!this.state.proposal && (document.activeElement === this.hocketsAreaRef ||
        document.activeElement.tagName.toLowerCase() === 'body')) {
      this.pasteHockets(clipText);
      event.preventDefault();
    }
  }

  copyHockets() {
    const {
      activeHocketId,
      answerHockets,
      hocketSelectionRange,
      answers,
    } = this.state;
    const hocketIds = answers[this.currentProblemId()].hockets;
    const messagesToCopy = []
    let start, stop;
    let html = "";
    let md = "";
    if (hocketSelectionRange) {
      [start, stop] = [...hocketSelectionRange].sort((a,b) => a-b);
      for (let i = start;
               i <= stop;
               i++) {
        const currentHocket = answerHockets[hocketIds[i].hocketId];
        if (currentHocket && currentHocket.responses.length > 0) {
          const message = currentHocket.responses[0];
          const suggestions = currentHocket.suggestions;
          const jessieCode = currentHocket.jessieCode;
          const tableData = currentHocket.tableData || '';
          const aspectRatio = currentHocket.aspectRatio || "100%";
          const codeCell = currentHocket.codeCell;
          const urlIFrame = currentHocket.urlIFrame;
          const heightIFrame = currentHocket.heightIFrame;
          messagesToCopy.push({ message, suggestions, tableData, jessieCode, aspectRatio, codeCell, urlIFrame, heightIFrame });

          const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
          html += converter.convert();

          md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n---\n\n";
        }
      }
      md = md.slice(0, md.length - 7); // 7 is the length of \n\n---\n\n
    } else {
      const activeHocket = answerHockets[activeHocketId];
      if (activeHocket && activeHocket.responses.length > 0) {
        const message = activeHocket.responses[0];
        const suggestions = activeHocket.suggestions;
        const jessieCode = activeHocket.jessieCode || '';
        const aspectRatio = activeHocket.aspectRatio || "100%";
        messagesToCopy.push({ message, suggestions, jessieCode, aspectRatio });

        const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
        html += converter.convert();

        md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
      }
    }
    return [JSON.stringify(messagesToCopy), html, md];
  }

  pasteHockets(clipText) {
    const { answers={}, activeHocketId } = this.state;
    const currentProblemId = this.currentProblemId();
    const { hockets=[] } = answers[currentProblemId];
    let messages;
    try {
      messages = JSON.parse(clipText);
    } catch (err) {
      console.error("Only messages from other Prismia drills can be pasted");
      return
    }
    messages
      .forEach( (message, loopCounter) => {
        const hocket = Hocket();
        if (!('message' in message) ||
            !isValidDelta(message.message) ||
            !('suggestions' in message)) {
          console.error("Only messages from other Prismia drills can be pasted");
          return;
        }
        hocket.responses.push(message.message);
        hocket.suggestions = message.suggestions;
        hocket.jessieCode = message.jessieCode || '';
        hocket.aspectRatio = message.aspectRatio || '';
        hocket.codeCell = message.codeCell || false;
        hocket.tableData = message.tableData || '';
        hocket.urlIFrame = message.urlIFrame || '';
        hocket.heightIFrame = message.heightIFrame || 0;
        const hocketStub = {
          hocketId: hocket.id,
        };
        const idx = (activeHocketId ?
            hockets.findIndex(
              hocket => hocket.hocketId === activeHocketId
            ) : hockets.length - 1);
        hockets.splice(idx + loopCounter + 1, 0, hocketStub);
        this.answerRef
          .doc(currentProblemId)
          .collection('hockets')
          .doc(hocket.id)
          .set(hocket, {merge: true})
          .catch(console.error);
      });
    this.answerRef
      .doc(currentProblemId)
      .set({ hockets }, {merge: true});
    }

  createRegradeHocket() {
    const { regradeRequestIdMap } = this.state;
    const currentProblemId = this.currentProblemId();
    const regradeRequestThread = regradeRequestIdMap[currentProblemId] || [];
    if (!currentProblemId) return;
    const hocket = Hocket();
    regradeRequestThread.push(hocket.id);
    this.pingBinderKernel();
    return this.answerRef
      .doc(currentProblemId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.answerRef
          .doc(currentProblemId)
          .set({ regradeRequestThread }, {merge: true});
      })
      .catch(console.error);
  }

  createHocket() {
    let { answers={}, activeHocketId } = this.state;
    const currentProblemId = this.currentProblemId();
    if (!currentProblemId) return;
    const { hockets=[] } = answers[currentProblemId] || {hockets: []};
    const hocket = Hocket();
    const hocketStub = {
      hocketId: hocket.id,
    };
    const idx = (activeHocketId ?
        hockets.findIndex(
          hocket => hocket.hocketId === activeHocketId
        ) : hockets.length - 1);
    hockets.splice(idx + 1, 0, hocketStub);
    this.answerRef
      .doc(currentProblemId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.answerRef
          .doc(currentProblemId)
          .set({ hockets }, {merge: true});
      })
      .catch(console.error);
    this.pingBinderKernel();
  }

  onDragEnd(res) {
    const currentProblemId = this.currentProblemId();
    const { destination, source } = res;
    if (!destination || !source) return;
    const { answers={} } = this.state;
    const { hockets=[] } = answers[currentProblemId];
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const [hocket] = hockets.splice(sourceIndex, 1);
    hockets.splice(destinationIndex, 0, hocket);
    answers[currentProblemId].hockets = hockets;
    this.setState({ answers });
    this.answerRef.doc(currentProblemId).set({ hockets }, {merge: true});
    this.setSubmittedStatus(false);
  }

  setProposal(setting) {
    this.setState({ proposal: setting });
    if (setting) {
      this.unsetMousetrap();
      this.exitHelpOrSettings();
    } else {
      this.setMousetrap();
    }
  }

  renderDrillArea(classNames='') {
    const { drill={}, currentProblem, fullyLoaded } = this.state;
    if (!drill || !fullyLoaded) return null;
    const { title='', description='' } = drill;
    let hocketsArea;
    if ( currentProblem > Object.keys(drill.drillProblems).length - 1 ) {
      hocketsArea = <div style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        minWidth: '200px',
        fontSize: 24,
        marginTop: '10%',
      }}>
        Congratulations! You've finished all of the practice exercises in this drill.
        <Button
          variant="contained"
          color="primary"
          style={{ display: "block", marginLeft: 'auto', marginRight: 'auto', marginTop: '65px'}}
          onClick={() => this.setState({ 
            currentProblem: 0,
            showNav: true,
          })}>
          Review Exercises
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ display: "block", marginLeft: 'auto', marginRight: 'auto', marginTop: '45px'}}
          onClick={() => this.setProposal(true) }>
          Propose New Exercises!
        </Button>
      </div>;
    }
    return (
      <div className={"y-scrollable " + classNames}>
        <h1
          className="assignment-title centered">
          { title || '' }
        </h1>
        <div
          className="centered description-text">
          { description || '' }
        </div>
        { this.state.showNav && this.state.currentProblem < Object.keys(this.state.drill?.drillProblems).length ? <div
          className="flex-container align-center justify-center centered">
          <FlexButton
            onClick={ () => this.prevProblem() }
            disabled={ currentProblem === 0 }>
            <ArrowBackIcon/>
          </FlexButton>
          <span className="flex-none" style={{fontSize: 28, marginLeft: "20px", marginRight: "20px"}}> {  (this.state.currentProblem + 1) } </span>
          <FlexButton
            onClick={ () => this.nextProblem() }
            disabled={ currentProblem === this.state.maxProblem }>
            <ArrowForwardIcon/>
          </FlexButton>
        </div> : null }
        { hocketsArea ? hocketsArea : this.renderHocketsArea() }
      </div>
    );
  }

  isInSelectionRange(hocketId) {
    const { answers, hocketSelectionRange } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = answers[this.currentProblemId()];
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  renderHocketCard(hocket, index, clickable = true) {
    if (!hocket) {
      return null;
    }
    const { activeHocketId } = this.state;
    const style = {background: '#fff'};
    if (this.state.reOrder && hocket.id === activeHocketId) {
      style.background = 'rgb(169, 216, 225)'; // light teal
    }
    if (this.isInSelectionRange(hocket.id)) {
      style.background = '#fff3a8' // light yellow
    }
    let className = "assignment-card";
    if (index === -1) {
      className += " problem-message";
    }
    if (index === -2) {
      className += " feedback-card";
    }
    const card = (<div
       className={ className }
       key={ hocket.id }
       style={ style }
       onClick={ (e) => {
           if (!clickable) return;
           if (e.shiftKey) {
             if (activeHocketId) {
               this.setSelectionEnd(hocket.id);
             } else {
               this.setActiveHocketId(hocket.id);
             }
           } else {
             this.setActiveHocketId(hocket.id);
           }
       } }>
       <CardContents 
          db={ this.props.db } 
          projectId={ this.props.projectId } 
          hocket={ hocket }
          codeCell={ this.state.codeCell }
          setLang={(lang) => this.setLang(lang)}
          stripMarkers={index === -1}/>
    </div>);
    if (index < 0) {
      return card;
    }
    if (!this.state.locked && !this.state.reOrder && activeHocketId === hocket.id) {
      return <div
        style={ style }
        key={ hocket.id } >
          { this.renderHocketForm() }
        </div>;
    }
    if (!this.state.reOrder) return card;
    return (
      <Draggable
        draggableId={ hocket.id }
        index={ index }
        key={ hocket.id }>
      {(provided, snapshot) => {
        return (
            <div
              ref={ provided.innerRef }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="assignment-card"
              key={ hocket.id }
              style={ getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                style
              )}
              onClick={ (e) => {
                  if (!clickable) return;
                  if (this.hocketsAreaRef) {
                    this.hocketsAreaRef.focus()
                  }
                  if (e.shiftKey) {
                    this.setSelectionEnd(hocket.id);
                  } else {
                    this.setActiveHocketId(hocket.id);
                  }
              } }>
              <CardContents 
                db={ this.props.db } 
                projectId={ this.props.projectId } 
                hocket={ hocket }
                setLang={(lang) => this.setLang(lang)}
                codeCell={ this.state.codeCell }/>
            </div>
        );
      }}
      </Draggable>
    );
  }
  
  setLang(lang) {
    this.setState({ codeCell: lang });
  }

  renderSuggestions(suggestions) {
    return <div className="padded-sides"><SuggestionSuperList
      includeSuggestionReplies
      hidePlaceholder={ true }
      label="Suggestion"
      showHocketField={ false }
      readOnly={ true }
      hideLast={ true }
      limit={ 15 }
      projectId={ this.props.projectId }
      db={ this.props.db }
      values={ suggestions }
      updateValues={ (suggestions) => null }/></div>;
  }

  renderHocketForm(singleForm=false) {
    const { db, storage, projectId, currentUser, drillId } = this.props;
    const { activeHocketId, codeCell } = this.state;
    if (!activeHocketId) return <></>;
    const problemId = this.currentProblemId();
    const hocketRef = db
      .collection('users')
      .doc(currentUser.id)
      .collection('drill-projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .collection('problems')
      .doc(problemId)
      .collection('hockets')
      .doc(activeHocketId);
    return (
      <div
        className="message-form-container"
        key={ activeHocketId }>
        <MessageForm
          db={ db }
          currentUser={ currentUser }
          storage={ storage }
          singleForm={ singleForm }
          projectId={ projectId }
          hocketId={ activeHocketId }
          drillId={ drillId }
          problemId={ problemId }
          clearActiveHocketId={ () => this.setActiveHocketId(null) }
          deleteMessages={ this.deleteMessages }
          mainQuillRef={ this.mainQuillRef }
          toggleJuniper={ () => this.toggleJuniper() }
          splitActiveCell={ () => this.splitActiveCell() }
          createHocket={ () => this.createHocket() }
          codeCell={ codeCell }
          setSubmittedStatus={ (status) => this.setSubmittedStatus(status) }
          setMousetrap={() => this.setMousetrap() }
          hocketRef={ hocketRef }
          />
      </div>
    );
  }

  toggleJuniper() {
    const { juniperOpen } = this.state;
    this.setState({ juniperOpen: !juniperOpen }, () => {
      //if (juniperOpen) this.juniperRef.current.codeMirror.focus();
    });
  }

  renderJuniper() {
    const { juniperOpen, juniperHasRendered, codeCell } = this.state;
    const style = {};
    if (!juniperOpen && !juniperHasRendered) return null;
    if (!juniperHasRendered) this.setState({ juniperHasRendered: true });
    if (!juniperOpen) style.display = "none";
    return <div className="juniper-container" style={ style }>
      <JupyterCell
        ref={ this.juniperRef }
        language={ codeCell }/>
    </div>;
  }

  problemHockets(problemId) {
    const { drill, hockets={} } = this.state;
    try {
      return drill.drillProblems[problemId].statementHockets.map(id => hockets[id]);
    } catch (e) {
      return [];
    }
  }

  renderSolution() {
    const { drill, hockets } = this.state;
    const { drillProblems } = drill;
    const solutionIds = drillProblems[this.currentProblemId()].solutionHockets;
    const hocketCards = (solutionIds || []).map(id => this.renderHocketCard(hockets[id], -1));
    if (!hocketCards.length) return null;
    return <>
      <h3 className="centered" style={{marginTop: "60px", color: "grey"}}> Example solution </h3>
      { hocketCards }
    </>;
  }

  saveScore(score) {
    this.updateFromScoreClick = true;
    if (this.state.currentProblem < Object.keys(this.state.drill.drillProblems).length - 1) {
      NotificationManager.info(pick(scoreMessages[score]));
    } else {
      NotificationManager.info('That\'s all!');
    }
    this.answerRef.doc(this.currentProblemId()).set({ score }, {merge: true}).catch(console.error);
  }

  renderScore() {
    return <>
      <div className="centered" style={{marginBottom: "18px", marginTop: "36px"}}>How'd you do?</div>
      {
        Object.keys(scoreMessages).map(label => 
          <Button
            key={ label }
            color={this.state.scores[this.currentProblemId()] === label ? "primary" : "default" }
            variant={this.state.scores[this.currentProblemId()] === label ? "contained" : "outlined" }
            onClick={() => this.saveScore(label)}
            style={{marginLeft: 'auto', marginTop: '10px', width: '250px', 
                    marginRight: 'auto', display: 'block'}}>
              { label }
          </Button>
        )
      }
    </>;
  }

  renderHocketsArea() {
    const { answers={}, answerHockets } = this.state;
    const currentProblemId = this.currentProblemId();
    const problemCard = <div className="problem-statement" key="problem-statement">
      { this.problemHockets(currentProblemId).map( hocket =>
          this.renderHocketCard(hocket, -1) ) }
    </div>;
    const hocketCards = [];
    let hocketStubs = (answers[currentProblemId] || {hockets: []}).hockets;
    const clickable = !this.state.submittedSolutions.has(this.currentProblemId());
    if (hocketStubs) {
      for (let i = 0; i < hocketStubs.length; i++) {
        hocketCards.push(this.renderHocketCard(
          answerHockets[hocketStubs[i].hocketId], i, clickable
        ));
      }
    }
    return (
      <div
        className="hockets-area"
        tabIndex={ -1 }
        ref={ (node) => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
        <h3 className="centered" style={{color: "grey"}}> Problem </h3>
        { problemCard }
        <h3 className="centered" style={{marginTop: "60px", color: "grey"}}> Solution </h3>
        <DragDropContext
          onDragEnd={res => this.onDragEnd(res)}>
          <Droppable
            droppableId={'list'}
            key={ 'droppable' }>
          { (provided, snapshot) => (
            <div
              className="pad-bottom"
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.innerProps}
                >
            { hocketCards }
            { provided.placeholder }
            </div>
          )}
          </Droppable>
        </DragDropContext>
        { !this.state.submittedSolutions.has(this.currentProblemId()) ? <Tooltip
          title="Add a new cell after the currently selected cell [shift +]"
          enterDelay={ 500 }>
          <Button
            fullWidth
            variant="outlined"
            className="add-hocket-button"
            onClick={() => this.createHocket()}
            >
            +
          </Button>
        </Tooltip> : null }
        { !this.state.submittedSolutions.has(this.currentProblemId()) ? <Tooltip
          title="Submit solution and see answer"
          enterDelay={ 500 }>
          <Button
            variant="outlined"
            color="primary"
            className="add-hocket-button"
            style={{ display: "block", marginTop: "60px", marginLeft: "auto", marginRight: "auto" }}
            onClick={() => this.submitSolution()}
            >
            Submit
          </Button> 
        </Tooltip> : null }
        { this.state.submittedSolutions.has(this.currentProblemId()) ? <>
          { this.renderSolution() }
          { this.renderScore() }
        </> : null }
      </div>
    );
  }

  renderRubricItem(rubricMap, item) {
    const { id, text } = item;
    return <FormControlLabel
      control={ <Checkbox checked={ !!(rubricMap[id]) } color="primary" inputProps={{ 'aria-label': 'Rubric Item' }}/> }
      label={ text }
      key={ id }/>;
  }

  submitSolution() {
    const { maxProblem, currentProblem, drill } = this.state;
    this.answerRef.doc(this.currentProblemId()).set({ submitted: now() }, {merge: true}).catch(console.error);
    if (maxProblem === currentProblem && currentProblem < Object.keys(drill.drillProblems).length - 1) {
      this.setState({ maxProblem: maxProblem + 1 });
    }
  }

  render() {
    updateTitleBar('Drills');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings, codeCell, reOrder } = this.state;
    const classes = showHelp || showSettings ? "full-height blur" : "full-height";
    const tools = [{
      icon: <HelpOutlineOutlinedIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help screen",
      disabled: false,
      hide: false,
    }, {
      icon: <SettingsIcon/>,
      onClick: () => this.setState({ showSettings: true }),
      tooltipTitle: "Show tools and settings",
      disabled: false,
      hide: false,
    }, {
      icon: reOrder ? <ReorderIcon style={{color: "orange"}}/> : <ReorderIcon/>,
      onClick: () => {
        if (!this.state.proposal) {
          this.setState({ reOrder: !reOrder })
        } else {
          this.proposalRef.current.toggleReorder();
        }
      },
      tooltipTitle: reOrder ? "Done reordering" : "Reorder messages",
      disabled: false,
      hide: false,
    }, {
      icon: <CodeIcon/>,
      onClick: () => this.toggleJuniper(),
      tooltipTitle: "Toggle code cell",
      disabled: false,
      hide: !codeCell,
    }, {
      icon: this.state.locked ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>,
      onClick: () => this.setState({locked: !this.state.locked}),
      tooltipTitle: this.state.locked ? "Unlock messages" : "Lock messages",
      disabled: false,
      hide: !this.state.locked,
    }];
    const navHeight = (width) => 62 + (width < mobileThreshold ? 48 : 0);
    const table = width => (<div className={classes}>
      <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } studentView={ true }/>
        <div className="flow-root">
          <SidebarButtonPanel
            mobile={ width < mobileThreshold }
            tools={ tools }/>
        </div>
        { this.state.proposal ? 
          <DrillProposal
            ref={this.proposalRef}
            db={this.props.db}
            currentUser={this.props.currentUser}
            projectId={this.props.projectId}
            drillId={this.props.drillId}
            storage={this.props.storage}
            codeCell={null}
            drill={this.state.drill}
            submittedSolutions={this.state.submittedSolutions}
            hockets={this.state.hockets}
            width={width}
            reOrder={this.state.reOrder}
            setReOrder={ (setting) => this.setState({ reOrder: setting }) }
            NotificationManager={ NotificationManager }
            /> : <div style={{
          position: "relative", 
          height: `calc(100% - ${navHeight(width)}px)`}}>
          { this.renderDrillArea(width < mobileThreshold ? "border-top" : "") }
          { this.renderJuniper() }
        </div> }
    </div>);
    const maskCover = (showHelp || showSettings) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({width }) => {
            return <div className="assignment-view">
              { maskCover }
              { helpCard }
              { settingsCard }
              { table(width) }
              <NotificationContainer/>
            </div>;
        }}
      </ReactResizeDetector>
    );
  }

}

export default DrillView;
