import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import courseLibrary from '../course-library.js';

import { Project } from '../project';
import { createCourseFromTopOfLandingPage, loginToCreateCourse } from '../analytics';
import { renderCarousel, shortSlides } from '../carousel';
import './style.css';

const CourseCard = withStyles({
  root: {
    userSelect: "none",
    cursor: "pointer",
    "&:hover": { 
      boxShadow: "0px 4px 2px -4px rgba(35, 120, 130, 0.5), 0px 4px 4px 0px rgba(35, 120, 130, 0.21), 0px 2px 10px 0px rgba(35, 120, 130,0.16)",
      transform: "scale(1.02)",
      transition: "transform 0.25s",
    },
    borderRadius: "4px",
    fontFamily: "Source Sans Pro",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.07), 0px 1px 5px 0px rgba(0,0,0,0.06)",
  }
})(Card);


class LandingView extends Component {

  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { menuOpenRef: false };
  }

  logIn() {
    if (this.props.currentUser) {
      if (this.props.currentUser.activeProjectId) {
        let url = '/chat/' + this.props.currentUser.activeProjectId;
        return this.props.router.history.push(url);
      }
      return this.props.router.history.push('/chat');
    }
    this.props.router.history.push('/login');
  }

  createProject() {
    const { currentUser, db, router } = this.props;
    if (!currentUser) return console.log('no user');
    const project = Project(currentUser);
    const { id } = project;
    createCourseFromTopOfLandingPage(id);
    return db.collection('projects')
      .doc(id)
      .set(project)
      .then(() => {
        return db.collection('users')
          .doc(currentUser.id)
          .set({ activeProjectId: id }, {merge:true});
      }).then(() => {
        router.history.push('/projects/' + id);
      }).catch(console.error);
  }

  loginThenCreate(paymentPlan) {
    if (!this.props.login) return null;
    this.props.login(null)
      .then(() => {
        loginToCreateCourse();
        return this.createProject();
      }).catch(console.error);
  }

  createCourseNow() {
    const { currentUser } = this.props;
    if (!currentUser) {
      return this.loginThenCreate('free');
    }
    this.createProject('free');
  }

  renderSimpleChatForStudents() {
    return (
      <div className="flex-container flex-wrap centered expo">
        <div className="flex-child centered flex-two">
          <img
            alt="student chat ui"
            src="/landing-page-student-view.png" />
        </div>
        <div className="flex-child">
          <p className="centered">
            A simple interface for students.
          </p>
          <ul>
            <li><strong>Zero learning curve</strong>. It's just chat.</li>
            <li><strong>Confidence</strong>. Student messages go to instructors only.</li>
            <li><strong>Open discussion</strong>. Instructors can share student messages with the class.</li>
          </ul>
        </div>
      </div>

    );
  }

  renderDashboardForInstructors() {
    return (
      <div className="flex-container flex-wrap expo rtl">
        <div className="flex-child">
          <p className="centered">
            A powerful dashboard for teachers.
          </p>
          <ul>
            <li><strong>Clustering</strong>. Messages are grouped so you can respond to whole columns at once.</li>
            <li><strong>Efficiency</strong>. Prepare your questions in advance or type them on the fly.</li>
            <li><strong>Coordination</strong>. Different TAs can respond to different students.</li>
          </ul>
        </div>
        <div className="flex-child flex-three">
          <img
            id="teaching-dashboard"
            alt="teacher chat ui"
            src="/teaching-dashboard.png" />
        </div>
      </div>
    );
  }

  renderVideo() {
    // https://www.youtube.com/embed/dcgodkwBRQc
    return (
      <div className="videoWrapper" id="youtube-video">
        <p className="centered">
          Video Overview
        </p>
        <iframe
          title="demo"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dcgodkwBRQc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </div>
    );
  }

  renderHowItWorks() {
    return (
      <div className="expo">
        <h2 className="centered">How it works</h2>
        <div className="panel-container">
          <div className="flex-container flex-wrap">
            <div className="flex-child">
              <p className="centered">Ask a question</p>
              <img
                src="prismia-step-1.svg"
                alt="asking a question"
                className="list-image"/>
            </div>
            <div className="flex-child">
              <p className="centered">Responses are grouped</p>
              <img
                src="prismia-step-2.svg"
                alt="grouped responses"
                className="list-image"/>
            </div>
            <div className="flex-child">
              <p className="centered">Reply by group </p>
              <img
                src="prismia-step-3.svg"
                alt="respond in groups"
                className="list-image"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleClose() {
    this.setState({ menuOpenRef: null });
  };

  openMenu(event) {
    this.setState({ menuOpenRef: event.currentTarget });
  }

  renderTestimonial(testimonial, i) {
    return (
      <div className="testimonial-card-container" key={ i }>
        <div className="testimonial-card flex-child">
          <div className="testimonial-img-and-name flex-container">
            <img className="testimonial-photo" src={testimonial.photoUrl} alt={testimonial.name}/>
            <p className="testimonial-name">
              { testimonial.name }
            </p>
          </div>
          <p className="testimonial-text">
            { testimonial.text }
          </p>
        </div>
      </div>
    );
  }
  
  renderCourseCard(course, i) {
    const [r, g, b] = course.color;
    const k = 0.6;
    const k2 = 0.7;
    const k3 = 0.33;
    return <CourseCard 
            key={'course' + i}
            style={{
              background: `rgba(${r}, ${g}, ${b}, 0.04)`,
              border: `1px solid rgb(${k*r}, ${k*g}, ${k*b})`,
              color: `black`,
            }}
            onClick={() => this.props.router.history.push(course.url)}>
        <CardHeader 
          title={course.title}
          titleTypographyProps={{style: {fontSize: "1.1rem"}}}
          style={{
            background: `rgb(${k2*r}, ${k2*g}, ${k2*b})`,
            color: 'white',
          }}
          />
        <CardContent
          style={{
            color: `rgb(${k3*r}, ${k3*g}, ${k3*b})`,
          }}>
          <ul 
            className="section-list"
            style={{"--bullet-color": `rgb(${k*r}, ${k*g}, ${k*b})`}}>
            {course.sections ? course.sections.map(s => <li key={s}>{s}</li>) : null}
          </ul>
        </CardContent>
      </CourseCard>;
  }
  

  renderCourseCards() {
    return <div className="expo">
      <Link to="/shared/prismia-course-library"> <h2 id="course-library" className="centered">Course Library</h2> </Link>
      <div className="course-card-container">
        { courseLibrary.map( (course, i) => this.renderCourseCard(course, i) ) }
      </div>
    </div>
  }

  renderTestimonials() {
    const testimonials = [
      {
        photoUrl: "https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/testimonials%2FRoma%20Coffin.JPG?alt=media&token=dc3a094e-a34c-4c69-a346-04d5c7ca4636",
        text: "Prismia has revolutionized virtual learning for me. The interactive chat function provides me with custom questions and feedback throughout a lesson so I am able to participate effectively and stay engaged the whole time.",
        name: "Roma Coffin"
      },
      {
        photoUrl: "https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/testimonials%2FIMG_0848.png?alt=media&token=77660b08-3a3f-49e4-bb30-7b1343414559",
        text: "I love the one-on-one interactions that Prismia allows. It encourages me to work through the concepts with my professor, keeping me engaged and thinking critically about the material at hand.",
        name: "Jackson Brandberg",
      },
      {
        photoUrl: "https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/testimonials%2Fe933df83.png?alt=media&token=a091c9d9-8426-4013-9bfc-a751fbf574cf",
        text: "Prismia is such a good mate for lecture with Zoom!",
        name: "Shuya Zhang",
      },
      {
        photoUrl: "https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/testimonials%2F14f7aca9.jpeg?alt=media&token=07cdb6e8-3225-4436-8843-2c8cb81d294d",
        text: "At the onset of the Coronavirus pandemic I was concerned about the ability to learn efficiently and effectively in a remote setting.  How is it possible to have a learning experience with substantive and meaningful teacher-student interaction without in-person classes?  Prismia has played an essential role in quelling these concerns.  As someone who has been on the student side of the Prismia interface while also having a fair amount of virtual teaching and tutoring experience without the aid of Prismia, I can say that this technology greatly increases efficiency and overall quality of the learning experience for both teacher and student.",
        name: "Nathaniel Lanier",
      },
      {
        photoUrl: 'https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/app-images%2Fa3b9b03d.jpeg?alt=media&token=368b2e4d-1cad-486b-b61b-ddc9033121dd',
        text: 'Prismia makes it seamless to build a lesson that includes text, code, and high-level interactive visualizations. Prismia is so multifaceted that it allows a professor to accomplish what typically would span a lesson, lab, and a homework, in a single short lecture.',
        name: 'Christopher Rohlicek',
      },
      {
        photoUrl: 'https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/app-images%2Fff8debd9.jpeg?alt=media&token=51d0b5d4-7662-43c2-b425-023eb815f71e',
        text: 'Somehow, despite the remote setting and virtual constraints, this class felt more intimate and personalized than any in-person course I have taken before.',
        name: 'Griffin McCauley',
      }
    ];
    return (
      <div className="expo">
        <h2 className="centered">Reviews</h2>
        <div className="testimonial-container flex-container">
          { testimonials.map( (t,i) => this.renderTestimonial(t,i) ) }
        </div>
      </div>
    );
  }

  render() {
    const { router } = this.props;
    const { menuOpenRef } = this.state;
    const width = window.document.body.clientWidth;
    const mobile = width < 500;
    window.document.title = 'Prismia';
    const imageStyle = {
      width: '100%',
      maxWidth: "500px",
      height: '100%',
      float: 'left',
      marginTop: mobile ? '-20px' : '-5px',
      marginBottom: mobile ? '60px' : '10px',
    };
    const lastSlide = (
      <div
        className="slide-container dark-bg more-information-wrapper">
          <div className="more-information">
            <p style={{fontSize: "0.95em"}}>
              More Information:
            </p>
            <ul>
              <li><a href="/faq">FAQ</a> </li>
              <li><a href="/guide#walkthrough">Full Walkthrough</a> </li>
              <li><a href="/guide">Detailed Guide</a> </li>
              <li><a href="/blog/ml-with-mathlets">Example lesson</a> </li>
            </ul>
          </div>
      </div>
    );
    return (
      <div className="landing-view">
        <div className="landing-top-bar">
          <MenuIcon
            style={{
              float: "left",
              color: "#666",
              fontSize: "40px",
            }}
            onClick={ this.openMenu }/>
            <Menu
              open={ Boolean(menuOpenRef) }
              anchorEl={ menuOpenRef }
              onClose={ this.handleClose }>
              <MenuItem
                onClick={ () => router.history.push("/pricing")}>
                Create a new course
              </MenuItem>
              { mobile ?
                  <MenuItem
                    onClick={ () => this.logIn() }>
                    My Courses
                  </MenuItem>
                : null
              }
              <MenuItem
                onClick={ () => router.history.push("/faq")}>
                FAQ
              </MenuItem>
              <MenuItem
                onClick={ () => router.history.push("/mailing-list")}>
                Mailing List
              </MenuItem>
              <MenuItem
                onClick={ () => router.history.push("/guide")}>
                Guide
              </MenuItem>
            </Menu>
          { mobile ? null :
            <>
              <a href="#course-library">
                <Button>
                  Course Library
                </Button>
              </a>
              <Button onClick={() => this.logIn() }>
                { this.props.currentUser ? "My Courses" : "Sign In" }
              </Button>
            </>
           }
        </div>
        <div>
          <div className="flex-container splash expo flex-wrap middle">
            <div className="flex-child">
                <img src="/Prismia2.svg" style={ imageStyle } alt="logo"/>
            </div>
            <div className="flex-child">
              <img
                className="explainer-image"
                src="/prismia-explainer.svg"
                alt="classroom illustration"/>
            </div>
          </div>
          { this.renderHowItWorks() }
          { this.renderCourseCards() }          
          <div className="expo rtl">
            <div className="centered">
              <div className="margin-below">
                <h2
                  id="carousel"
                  key={ 100 }>
                  Overview
                  </h2>
                { renderCarousel(shortSlides, lastSlide) }
              </div>
            </div>
          </div>
          { this.renderTestimonials() }
          <div className="expo create-button-container">
            <h2 className="centered">Try it!</h2>
            <Button
              fullWidth
              className="create-project-button"
              onClick={ () => this.createCourseNow() }>
              Create a Course Now For Free
            </Button>
          </div>
        </div>
        <div className='footer'>
          <span>© 2020 Prismia</span>
          <span> | </span>
          <span><Link to='/privacy-policy'>privacy policy</Link></span>
        </div>
      </div>
    );
  }

}

export default LandingView;
