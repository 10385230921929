import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { hocket2Message } from '../message';
import { joinCourse } from '../analytics';
import { updateTitleBar } from '../utils';
import './style.css';

class JoinView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      project: null,
      chat: null,
    };
    this.state = {
      project: {},
      showWarning: '',
      alreadyInClass: false,
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser) return console.log('no current user');
    this.subProjects();
    this.subChat();
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props
    if ( prevProps.currentUser !== currentUser ) {
      this.subProjects();
      this.subChat();
    }
  }

  componentWillUnmount() {
    this.unsub.project();
    this.unsub.chat();
  }

  subProjects() {
    const { db, projectId } = this.props;
    this.unsub.project = db
      .collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const project = snap.data() || {};
        this.setState({ project });
      });
  }

  subChat() {
    const { db, projectId, currentUser } = this.props;
    if (this.unsub.chat) this.unsub.chat();
    this.unsub.chat = db.collection('users').doc(currentUser.uid || currentUser.id)
      .collection('chats').doc(projectId)
      .collection('messages')
      .limit(1)
      .onSnapshot( (snap) => {
        if (snap.docs.length) {
          this.setState({alreadyInClass : true});
        }
      });
  }

  copyClassMessages() {
    const { functions, projectId, currentUser } = this.props;
    const copyClassMessages = functions.httpsCallable('copyClassMessages');
    return copyClassMessages({ projectId, userId: currentUser.id }).catch(console.error);
  }

  joinClass(alreadyInClass) {
    const { db, projectId, currentUser, router } = this.props;
    const { project } = this.state;
    if (alreadyInClass) {
      router.history.push('/chat/' + projectId);
      return;
    }
    if (!currentUser) return;
    const batch = db.batch();
    db.collection('users')
      .doc(currentUser.id)
      .collection('meta')
      .doc('private')
      .get()
      .then(snap => {
        const privateData = snap.data();
        return privateData.email;
      })
      .then( email => {
        db.collection('projects')
          .doc(projectId)
          .get()
          .then(snap => {
            const projectDetails = snap.data();
            if (!projectDetails) {
              const warning = "No class found with this ID. Check your link.";
              this.setState({ showWarning: warning });
              throw warning;
            }
            const domRest = projectDetails.domainRestriction;
            if (domRest && !email.endsWith(domRest)) {
              const warning = ('Your email address must end in ' +
                               domRest + ' to join this class');
              this.setState({ showWarning: warning });
              throw warning;
            }
          })
          .then( () => {
            batch.set(db
              .collection('meta')
              .doc('roles')
              .collection('students')
              .doc(currentUser.id),
              { [projectId]: true }, {merge: true}
            );
            batch.set(db
              .collection('projects')
              .doc(projectId)
              .collection('roles')
              .doc('students'),
              { userIds: {[currentUser.id]: true }}, {merge:true}
            );
            batch.set(db
              .collection('users')
              .doc(currentUser.id),
              {activeProjectId: projectId}, {merge:true}
            );
            let messageWritePromise = Promise.resolve();
            if (project.welcomeHocketId) {
              messageWritePromise = db.collection('projects')
                .doc(projectId)
                .collection('hockets')
                .doc(project.welcomeHocketId)
                .get()
                .then(snap => {
                  const hocket = snap.data();
                  if (!hocket) return null;
                  return hocket;
                }).then(hocket => {
                  const message = hocket2Message(hocket);
                  if (!message) return null;
                  message.author = '000-0000-000';
                  message.authorDisplayName = project.creatorDisplayName || project.displayName || 'instructor';
                  message.authorPhotoUrl = project.creatorPhotoUrl || null;
                  batch.set(db
                    .collection('users')
                    .doc(currentUser.id)
                    .collection('chats')
                    .doc(projectId)
                    .collection('messages')
                    .doc(message.id),
                    message, {merge:true}
                  );
                }).catch(console.error);
            }
            joinCourse(projectId); // analytics
            messageWritePromise.then( () => {
              batch.commit().then(() => {
                // this.copyClassMessages();
                setTimeout(() => router.history.push('/chat/' + projectId), 100);
              }).catch(console.error);
            })
          })
        })
  }

  render() {
    updateTitleBar('Join');
    const { project={}, alreadyInClass, showWarning } = this.state;
    const { currentUser, logout, login, router } = this.props;
    const height = window.innerHeight;
    const style = {
      opacity: 0,
      padding: '40px',
      fontSize: '2em',
      fontWeight: '400',
      textAlign: 'center',
      marginTop: (0.05 * height) + 'px',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    const joinButton = (
        <div>
          <Button
            onClick={ () => this.joinClass(alreadyInClass) }
            variant="outlined"
            >
            { alreadyInClass ? "Enter class" : "Join Class" }
          </Button>
        </div>
    );
    const warningMessage = (
      <div className="login-area">
        <p className="join-warning">
          { showWarning }
        </p>
        <Button
          variant="outlined"
          className="login-button"
          onClick={ () => {
            window.localStorage.setItem(
              'followupUrl',
              window.location.href.split('prismia.chat')[1]
            );
            logout();
          }}>
          Sign out
        </Button>
        <Button
          className="login-button"
          variant="outlined"
          onClick={ () => router.history.push("/") }>
          Home
        </Button>
      </div>
    )
    const url = '/projects/' + this.props.projectId + '/join';
    const joinedText = (
      <div className="login-area">
        <p> Sign in to join this class. </p>
          <Button
            className="login-button"
            onClick={ () => login(url) }
            variant="outlined">
            Google
          </Button>
          <Button
            className="login-button"
            onClick={ () => login(url,'microsoft') }
            variant="outlined">
            Microsoft
          </Button>
          <Button
            className="login-button"
            onClick={ () => {
              window.localStorage.setItem(
                "followupUrl",
                url
              );
              router.history.push("/email-sign-in")
            }}
            variant="outlined">
            Email
          </Button>
      </div>
    );
    return (
      <div className="landing-view" style={ style }>
        <div className="centered">
          <img
            className="logo-image"
            src="/prismia-logo-simple.svg" alt="logo"
          />
        </div>
        <h2>{ project.displayName }</h2>
        { (showWarning === '') ?
            (currentUser ? joinButton : joinedText) :
          warningMessage }
      </div>
    );
  }

}

export default JoinView;
