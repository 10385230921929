import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { updateTitleBar } from './components/utils';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProjectView from './components/project-view/';
import CreateProjectView from './components/create-project-view/';
import PrivacyPolicyView from './components/privacy-policy-view/';
import AssignmentCreationView from './components/assignment-creation-view/';
import DrillCreationView from './components/drill-creation-view/';
import DrillsCreationView from './components/drills-creation-view/';
import DrillView from './components/drill-view/';
import DrillsView from './components/drills-view/';
import AssignmentView from './components/assignment-view/';
import AssignmentsCreationView from './components/assignments-creation-view/';
import AssignmentsView from './components/assignments-view/';
import AssignmentGradingView from './components/assignment-grading-view/';
import FrequentlyAskedQuestions from './components/frequently-asked-questions/';
import MetricsView from './components/metrics-view/';
import StudentMetricsView from './components/student-metrics-view/';
import AllMetricsView from './components/all-metrics-view/';
import ClassReportView from './components/class-report/';
import JoinView from './components/join-view/';
import PrivacyView from './components/privacy-view/';
import DownView from './components/down/';
import LandingView from './components/landing-view/';
import EmailSignIn from './components/email-sign-in-view/';
import EmailFollowup from './components/email-followup-view/';
import ProfileData from './components/profile-view/';
import MailingListView from './components/mailing-list-view/';
import DocumentationView from './components/documentation-view/';
import BlankView from './components/blank-view/';
import LiveClassroomClustering from './components/live-classroom-clustering/';
import DATA1010GalleryView from './components/data1010-gallery-view';
import DATA1050GradesView from './components/data1050';
import { JupyterCell } from './components/JupyterCell';
import ChatView from './components/chat-view/';
import InstructorChatView from './components/instructor-chat-view/';
import StudentsView from './components/students-view/';
import Fabric from './components/Fabric';
import MessageBoardView from './components/message-board-view/';
import MessageBoardThreadView from './components/message-board-thread-view/';
import LessonsView from './components/lessons-view/';
import LessonView from './components/lesson-view/';
import LessonSharingView from './components/lesson-sharing-view/';
import { cookieError } from './components/analytics';
import firebaseConfig from './config';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
const app = firebase.initializeApp(firebaseConfig);
const firebaseStorage = firebase.storage();
const firebaseFunctions = firebase.functions();

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#59a7b1',
      main: '#237882',
      dark: '#004c55',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7560',
      main: '#f93d35',
      dark: '#be000c',
      contrastText: '#fff',
    },
  },
});

class App extends Component {

  constructor(props) {
    super(props);
    firebase
      .auth()
      .onAuthStateChanged((currentUser) => {
        if (currentUser) {
          this.updateUser(currentUser)
            .then(userData => {
              this.setState({ currentUser: userData }, this.subUser);
            }).catch(console.error);
        } else {
          this.setState({ currentUser: null });
        }
      });
    this.db = app.firestore();
    this.unsub = {
      user: null,
    };
    this.state = {
      errorOpen: false,
      currentUser: null,
    };
  }

  renderEmailSignIn() {
    return (
      <EmailSignIn
        sendLoginEmail={(email) => this.sendLoginEmail(email)}
        />
    );
  }

  emailSignInRoute() {
    return (
      <Route
        path="/email-sign-in"
        exact
        render={() => {
          return this.renderEmailSignIn();
        }}
      />
    );
  }

  sendLoginEmail(email) {
    const actionCodeSettings = {
      url: 'https://prismia.chat/email-followup',
      handleCodeInApp: true,
    };
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
      }).catch(console.error);
  }

  faqRoute() {
    return (
      <Route
        path="/faq"
        exact
        render={(router) => {
          return this.renderFaq(router);
        }}
      />
    );
  }

  renderFaq() {
    return (
      <FrequentlyAskedQuestions/>
    );
  }

  mailingListRoute() {
    return (
      <Route
        path="/mailing-list"
        exact
        render={
          (router) => this.renderMailingList(router)
        }/>
    );
  }

  profileDataRoute() {
    const { currentUser } = this.state;
    return (
      <Route
        path="/profile"
        exact
        render={(router) => {
          if (!currentUser) return;
          return this.renderProfileData(router);
        }}
      />
    );
  }

  renderMailingList(router) {
    return (
      <MailingListView
        db={ this.db }
        router={ router }/>
    );
  }

  renderProfileData(router) {
    const { currentUser } = this.state;
    return (
      <ProfileData
        storage={ firebaseStorage }
        router={ router }
        firebaseAuth = { firebase.auth() }
        updateUser= { this.updateUser.bind(this) }
        setCurrentUser= { this.setCurrentUser.bind(this) }
        currentUser= { currentUser }
      />
    );
  }

  emailFollowUpRoute() {
    return (
      <Route
        path="/email-followup"
        exact
        render={(router) => {
          return this.renderEmailFollowup(router);
        }}
      />
    );
  }

  renderEmailFollowup(router) {
    return (
      <EmailFollowup
        storage={ firebaseStorage }
        router={ router }
        firebaseAuth = { firebase.auth() }
        updateUser= { this.updateUser.bind(this) }
        setCurrentUser= { this.setCurrentUser.bind(this) }
      />
    );
  }

  setCurrentUser(user, Cb) {
    this.setState({ currentUser: user }, Cb);
  }

  updateUser(profileData, override=false) {
    const { displayName=null,
            email,
            studentId=null,
            photoURL=null,
            uid,
            providerData=null, } = profileData;
    let { photoUrl=null } = profileData;
    if (photoURL && !photoUrl) {
      // Google uses different capitalization (URL not Url)
      photoUrl = photoURL;
    }
    let providerId=null;
    if (providerData && providerData.length && providerData[0].providerId) {
      providerId = providerData[0].providerId;
    }
    const userData = {
        id: uid,
        displayName,
        studentId,
        photoUrl,
        providerId,
      };
    const userRef = this.db.collection('users').doc(uid);
    // Give precedence to the database for the values
    // of photoUrl and displayName:
    return userRef.get()
      .then(snap => {
        const doc = snap.data();
        if (doc && (doc.id === userData.id ||
                    doc.uid === userData.id )) {
          if (doc.displayName && !override) {
            userData.displayName = doc.displayName;
          }
          if (doc.photoUrl && !override) {
            userData.photoUrl = doc.photoUrl;
          }
          if (doc.studentId) {
            userData.studentId = doc.studentId;
          }
          if (doc.providerId) {
            userData.providerId = doc.providerId;
          }
        }
        return userData;
      }).then( (userData) => {
        if (email) {
          userRef.collection('meta')
            .doc('private')
            .set({ email }, { merge: true });
        }
        return userRef.set(userData, { merge: true })
          .then( () => userRef.get() )
          .then( snap => snap.data() )
          .catch(console.error);
      });
  }

  componentDidMount() {
    this.subUser();
  }

  subUser() {
    const { db } = this;
    if (!db) return null;
    if (!this.state.currentUser) return null;
    if (this.unsub.user) this.unsub.user();
    const id = this.state.currentUser.id || this.state.currentUser.uid;
    if (!id) return console.log('no user id');
    this.unsub.user = db.collection('users').doc(id)
      .onSnapshot(snap => {
        const currentUser = snap.data();
        if (!currentUser) return console.log('no user');
        this.setState({ currentUser });
      });
  }

  login(router, followupUrl='', method='google') {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
    const provider = method === 'google' ? googleProvider : microsoftProvider;
    return firebase
      .auth().signInWithPopup(provider)
      .then((result) => {
        //const token = result.credential.accessToken;
        const user = result.user;
        return user;
      }).then(currentUser => {
        return this.updateUser(currentUser);
      }).then(currentUser => {
        this.setState({ currentUser }, () => {
          if (followupUrl === null) {
            return null;
          } else if (followupUrl) {
            router.history.push(followupUrl);
          } else if (!currentUser.displayName ||
                      !currentUser.photoUrl) {
            router.history.push('/profile');
          } else if (window.localStorage.getItem('followupUrl')) {
            const url = window.localStorage.getItem('followupUrl');
            window.localStorage.removeItem('followupUrl');
            router.history.push(url);
          } else {
            router.history.push('/chat');
          }
          return null;
        });
      }).catch((err) => {
        if (err.code && err.code === 'auth/web-storage-unsupported') {
          console.log(err);
          cookieError(uuid());
          this.setState({ errorOpen: true });
        }
        console.error(err);
      });
  }

  logout(router, url) {
    const { currentUser } = this.state;
    const { providerId } = currentUser;
    const signOutPopup = (logoutUrl, providerName) => {
      const newWindow = window.open(logoutUrl,'Disconnect from ' + providerName,'width=400,height=400,menubar=no,status=no,location=no,toolbar=no,scrollbars=no,top=100,left=100');
      setTimeout( () => {
        if (newWindow) newWindow.close();
        if (url) window.location = url;
      }, 3000);
    }
    if (providerId === "google.com") {
      const response = window.confirm("A pop-up will open with a message indicating that you are logging out of your Google account in this browser. After a few seconds, the pop up will close, and you will be automatically re-directed to the Prismia login page.");
      if (!response) return;
      signOutPopup('https://mail.google.com/mail/?logout', 'Google');
    } else if (providerId === "microsoft.com") {
      const response = window.confirm("If you want to switch to a different Microsoft account, click OK to log out of Prismia and pop open a new window to the URL https://account.microsoft.com, where you will need to sign out of your Microsoft account in this browser (click on the profile image in the top right corner, then click \"Sign Out\").");
      if (!response) return;
      window.open("https://account.microsoft.com/");
    }
    return firebase
      .auth().signOut()
      .then(() => {
        this.setCurrentUser(null);
      }).catch(function(error) {
        console.error(error);
      }).then( () => router.history.push('/login'));
  }

  renderLoginView(router) {
    return (
      <div className="login-area">
      <img className="logo-image" src="/prismia-logo-simple.svg" alt="logo"/>
      <p className="gray">Choose a sign-in option</p>
        <Button
          className="login-button"
          onClick={ () => this.login(router, '', 'google') }
          variant="outlined">
          Google
        </Button>
        <Button
          className="login-button"
          onClick={ () => this.login(router, '', 'microsoft') }
          variant="outlined">
          Microsoft
        </Button>
        <Button
          className="login-button"
          onClick={ () => router.history.push('/email-sign-in') }
          variant="outlined">
          Email
        </Button>
      </div>
    );
  }

  loginRoute() {
    const { currentUser } = this.state;
    return (
      <Route
        path="/login"
        exact
        render={(router) => {
          if (currentUser) {
            return router.history.push('/chat');
          }
          return this.renderLoginView(router);
        }}
      />
    );
  }

  microsoftLogoutRoute() {
    return (
      <Route
        path="/microsoftlogout"
        exact
        render={ (router) => {
            firebase
            .auth().signOut()
            .then(() => {
              this.setCurrentUser(null);
            }).catch(function(error) {
              console.error(error);
            }).then( () => router.history.push('/login'));
            return null;
        } }
      />
    );
  }

  logoutRoute() {
    return (
      <Route
        path="/logout"
        exact
        render={(router) => {
          return (
            <div className="login-area">
              <h1>Prismia</h1>
              <Button
                variant="outlined"
                onClick={ () => this.logout(router, '/') }>
                Logout
              </Button>
            </div>
          );
        }}
      />
    );
  }

  landingRoute() {
    return (
        <Route
          path="/landing"
          exact
          render={(router) => {
            const { currentUser } = this.state;
            return (
              <>
              <LandingView
                router={ router }
                db={ this.db }
                login={ (url) => this.login(router, url) }
                currentUser={ currentUser } />
              </>
            );
          }}
        />
    );
  }

  guideRoute() {
    return (
        <Route
          path="/guide"
          exact
          render={(router) => {
            const { currentUser } = this.state;
            return (
              <>
              <DocumentationView
                router={ router }
                db={ this.db }
                login={ (url) => this.login(router, url) }
                currentUser={ currentUser } />
              </>
            );
          }}
        />
    );
  }

  documentationRoute() {
    return (
        <Route
          path="/documentation"
          exact
          render={(router) => {
            updateTitleBar("Guide");
            const { currentUser } = this.state;
            return (
              <>
              <DocumentationView
                router={ router }
                db={ this.db }
                login={ (url) => this.login(router, url) }
                currentUser={ currentUser } />
              </>
            );
          }}
        />
    );
  }

  projectChatRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/chat/:id"
          render={(router) => {
            updateTitleBar("Chat");
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            const { id } = router.match.params;
            return (
              <ChatView
                firestore={ app.firestore }
                db={ this.db }
                storage={ firebaseStorage }
                currentUser={ currentUser }
                projectId={ id }
                router={ router }
                logout={ () => this.logout(router, '/login')}
              />
            );
          }}
        />
    );
  }

  projectGalleryRoute() {
    const { currentUser } = this.state;
    return (
      <Route
        path="/data1010-projects"
        exact
        render={() => {
          updateTitleBar("DATA 1010 Gallery");
          return <DATA1010GalleryView 
            db={this.db}
            currentUser={currentUser}/>
        }}/>
    );
  }

  lessonRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/lessons/:lessonId"
          exact
          render={(router) => {
            updateTitleBar("Lesson");
            const { projectId, lessonId } = router.match.params;
            if (!currentUser) return (
              <BlankView
                notLoggedIn
                router={ router }
              />
            );
            return (
              <LessonView
                firestore={ app.firestore }
                db={ this.db }
                storage={ firebaseStorage }
                currentUser={ currentUser }
                router={ router }
                lessonId={ lessonId }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  jupyterCellRoute() {
    return (
      <Route
        path="/jupyter-cell"
        render={(router) => {
          updateTitleBar('Jupyter Cells');
          return (
            <>
              <div style={{marginLeft: "auto", marginRight: "auto", marginTop: "4em", maxWidth: "650px"}}>
                <JupyterCell content={"#lang julia\n1:10"}/>
              </div>
              <div style={{marginLeft: "auto", marginRight: "auto", marginTop: "4em", maxWidth: "650px"}}>
                <JupyterCell/>
              </div>
            </>
          );
        }}
      />
  );
  }

  blogRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/blog/:lessonId"
          render={(router) => {
            updateTitleBar("Blog");
            const { lessonId } = router.match.params;
            return (
              <LessonSharingView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                lessonId={ lessonId }
                storage={ firebaseStorage }
                location={ router.location }
                login={ (url) => this.login(router, url) } />
            );
          }}
        />
    );
  }

  customGalleryRoute() {
    const { currentUser } = this.state;
    return (
      <Route
        path="/mathlets/:lessonId"
        render={(router) => {
          updateTitleBar("Mathlet Gallery");
          const { lessonId } = router.match.params;
          return (
            <LessonSharingView
              firestore={ app.firestore }
              db={ this.db }
              currentUser={ currentUser }
              router={ router }
              lessonId={ lessonId }
              storage={ firebaseStorage }
              location={ router.location }
              gallery={ true }
              login={ (url) => this.login(router, url) } />
          );
        }}
      />
    );
  }

  galleryRoute() {
    const { currentUser } = this.state;
    return (
      <Route
        path="/mathlets"
        render={(router) => {
          updateTitleBar("Mathlet Gallery");
          return (
            <LessonSharingView
              firestore={ app.firestore }
              db={ this.db }
              currentUser={ currentUser }
              router={ router }
              storage={ firebaseStorage }
              location={ router.location }
              gallery={ true }
              login={ (url) => this.login(router, url) } />
          );
        }}
      />
    );
  }

  lessonSharingRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/shared/:lessonId"
          render={(router) => {
            updateTitleBar("Shared Lesson");
            const { lessonId } = router.match.params;
            return (
              <LessonSharingView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                lessonId={ lessonId }
                storage={ firebaseStorage }
                location={ router.location }
                login={ (url) => this.login(router, url) } />
            );
          }}
        />
    );
  }

  lessonsRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/lessons"
          exact
          render={(router) => {
            updateTitleBar("Lessons");
            const { projectId } = router.match.params;
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <LessonsView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  messageBoardRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/message-board"
          exact
          render={(router) => {
            updateTitleBar("Message Board");
            const { projectId } = router.match.params;
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <MessageBoardView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                projectId={ projectId }/>
            );
          }}
        />
    );
  }

  messageBoardThreadRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/message-board-threads/:threadId"
          exact
          render={(router) => {
            updateTitleBar("Message Board");
            const { projectId, threadId } = router.match.params;
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <MessageBoardThreadView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                projectId={ projectId }
                threadId={ threadId }
                storage={ firebaseStorage }/>
            );
          }}
        />
    );
  }

  fabricRoute() {
    return <Route path="/fabric" exact render={router => <Fabric/>}/>;
  }

  studentsRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/students"
          exact
          render={(router) => {
            updateTitleBar("Student Roster");
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <StudentsView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                nav={ router }
                projectId={ router.match.params.projectId }  />
            );
          }}
        />
    );
  }

  instructorChatRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/instructor-chat-view/:id"
          exact
          render={(router) => {
            updateTitleBar("Instructor Chat View");
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <InstructorChatView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                nav={ router }
                projectId={ router.match.params.projectId } />
            );
          }}
        />
    );
  }

  classroomRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/classroom"
          exact
          render={(router) => {
            updateTitleBar("Classroom");
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <LiveClassroomClustering
                db={ this.db }
                currentUser={ currentUser }
                storage={ firebaseStorage }
                router={ router }
                projectId={ router.match.params.projectId } />
            );
          }}
        />
    );
  }

  createProjectRoute() {
    const { currentUser } = this.state;
    const style = {
      textAlign: 'center',
      paddingTop: '40px',
      margin: '0',
    };
    return (
        <Route
          path="/pricing"
          exact
          render={(router) => {
            updateTitleBar('Pricing');
            return (
              <div className="pricing-view">
              <h1 style={ style }>
                We're glad you want to create a Prismia course!
              </h1>
              <CreateProjectView
                router={ router }
                db={ this.db }
                login={ (url) => this.login(router, url) }
                currentUser={ currentUser } />
              </div>
            );
          }}
        />
    );
  }

  data1050Route() {
    const { currentUser } = this.state;
    const projectId = 'df0ad9ec-c39b-4e73-88aa-4823d7d553ee';
    return (
        <Route
          path="/data1050"
          exact
          render={(router) => {
            updateTitleBar('Final Grades');
            if (!currentUser) {
              return <BlankView router={ router } notLoggedIn />
            }
            return (
              <DATA1050GradesView
                router={ router }
                db={ this.db }
                projectId={ projectId }
                currentUser={ currentUser } />
            );
          }}
        />
    );
  }

  data1010Route() {
    const { currentUser } = this.state;
    const projectId = 'd685f682-068e-4f8d-b3b3-19fa6073ab23';
    return (
        <Route
          path="/data1010"
          exact
          render={(router) => {
            updateTitleBar('Final Grades');
            if (!currentUser) {
              return <BlankView router={ router } notLoggedIn />
            }
            return (
              <DATA1050GradesView
                router={ router }
                db={ this.db }
                projectId={ projectId }
                currentUser={ currentUser } />
            );
          }}
        />
    );
  }

  studentMetricsRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/student-metrics"
          exact
          render={(router) => {
            updateTitleBar('Metrics');
            if (!currentUser) {
              return <BlankView router={ router } notLoggedIn />
            }
            return (
              <StudentMetricsView
                router={ router }
                db={ this.db }
                projectId={ router.match.params.projectId }
                currentUser={ currentUser } />
            );
          }}
        />
    );
  }

  allMetricsRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/all-metrics"
          exact
          render={(router) => {
            updateTitleBar('Metrics');
            if (!currentUser) {
              return <BlankView router={ router } notLoggedIn />
            }
            return (
              <AllMetricsView
                router={ router }
                db={ this.db }
                projectId={ router.match.params.projectId }
                currentUser={ currentUser } />
            );
          }}
        />
    );
  }

  metricsRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/metrics"
          exact
          render={(router) => {
            updateTitleBar('Metrics');
            if (!currentUser) {
              return <BlankView router={ router } notLoggedIn />
            }
            return (
              <MetricsView
                router={ router }
                db={ this.db }
                projectId={ router.match.params.projectId }
                currentUser={ currentUser } />
            );
          }}
        />
    );
  }

  drillsRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/drills"
          exact
          render={(router) => {
            updateTitleBar("Drills");
            const { projectId } = router.match.params;
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <DrillsView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  assignmentsRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/assignments"
          exact
          render={(router) => {
            updateTitleBar("Assignments");
            const { projectId } = router.match.params;
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <AssignmentsView
                firestore={ app.firestore }
                db={ this.db}
                currentUser={ currentUser }
                router={ router }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  drillsCreationRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/edit-drills"
          exact
          render={(router) => {
            updateTitleBar("Drills");
            const { projectId } = router.match.params;
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <DrillsCreationView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  assignmentsCreationRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/edit-assignments"
          exact
          render={(router) => {
            updateTitleBar("Assignments");
            const { projectId } = router.match.params;
            if (!currentUser) return <BlankView router={ router } notLoggedIn />;
            return (
              <AssignmentsCreationView
                firestore={ app.firestore }
                db={ this.db }
                currentUser={ currentUser }
                router={ router }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  assignmentRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/assignments/:assignmentId"
          render={(router) => {
            updateTitleBar("Assignment");
            const { projectId, assignmentId } = router.match.params;
            if (!currentUser) return (
              <BlankView
                notLoggedIn
                router={ router }
              />
            );
            return (
              <AssignmentView
                firestore={ app.firestore }
                db={ this.db }
                storage={ firebaseStorage }
                currentUser={ currentUser }
                functions={ firebaseFunctions }
                router={ router }
                assignmentId={ assignmentId }
                projectId={ projectId }
                location={ router.location }/>
            );
          }}
        />
    );
  }

  drillRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/drills/:drillId"
          render={(router) => {
            updateTitleBar("Drills");
            const { projectId, drillId } = router.match.params;
            if (!currentUser) return (
              <BlankView
                notLoggedIn
                router={ router }
              />
            );
            return (
              <DrillView
                firestore={ app.firestore }
                db={ this.db }
                storage={ firebaseStorage }
                currentUser={ currentUser }
                functions={ firebaseFunctions }
                router={ router }
                drillId={ drillId }
                projectId={ projectId }
                location={ router.location }/>
            );
          }}
        />
    );
  }

  assignmentGradingRoute() {
    const { currentUser } = this.state;    return (
        <Route
          path="/projects/:projectId/grade-assignment/:assignmentId"
          render={(router) => {
            updateTitleBar("Assignment Grading");
            const { projectId, assignmentId } = router.match.params;
            if (!currentUser) return (
              <BlankView
                notLoggedIn
                router={ router }
              />
            );
            return (
              <AssignmentGradingView
                firestore={ app.firestore }
                db={ this.db }
                storage={ firebaseStorage }
                functions={ firebaseFunctions }
                currentUser={ currentUser }
                router={ router }
                assignmentId={ assignmentId }
                projectId={ projectId }  
                location={ router.location }/>
            );
          }}
        />
    );
  }

  drillCreationRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/edit-drill/:drillId"
          exact
          render={(router) => {
            updateTitleBar("Drills");
            const { projectId, drillId } = router.match.params;
            if (!currentUser) return (
              <BlankView
                notLoggedIn
                router={ router }
              />
            );
            return (
              <DrillCreationView
                firestore={ app.firestore }
                db={ this.db }
                storage={ firebaseStorage }
                currentUser={ currentUser }
                router={ router }
                drillId={ drillId }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  assignmentCreationRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/edit-assignment/:assignmentId"
          exact
          render={(router) => {
            updateTitleBar("Assignment");
            const { projectId, assignmentId } = router.match.params;
            if (!currentUser) return (
              <BlankView
                notLoggedIn
                router={ router }
              />
            );
            return (
              <AssignmentCreationView
                firestore={ app.firestore }
                db={ this.db }
                storage={ firebaseStorage }
                currentUser={ currentUser }
                router={ router }
                assignmentId={ assignmentId }
                projectId={ projectId }  />
            );
          }}
        />
    );
  }

  classReportRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/class-reports"
          exact
          render={(router) => {
            updateTitleBar('Class Report');
            if (!currentUser) {
              return <BlankView router={ router } notLoggedIn />
            }
            return (
              <ClassReportView
                router={ router }
                db={ this.db }
                projectId={ router.match.params.projectId }
                currentUser={ currentUser } />
            );
          }}
        />
    );
  }

  pageNotFound() {
    return (
      <Route
        path="*"
        render={(router) => {
          return <BlankView router={ router } pageNotFound/>
        }}
      />
    );
  }

  privacyRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/privacy"
          exact
          render={(router) => {
            updateTitleBar("Privacy Policy");
            if (!currentUser) {
              return <BlankView router={ router } notLoggedIn/>
            }
            return (
              <PrivacyView
                router={ router }
                db={ this.db }
                currentUser={ currentUser } />
            );
          }}
        />
    );
  }

  joinProjectRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/projects/:projectId/join"
          exact
          render={(router) => {
            updateTitleBar("Join Project");
            return (
              <JoinView
                router={ router }
                db={ this.db }
                login={ (url, method) => this.login(router, url, method) }
                projectId={ router.match.params.projectId }
                functions={ firebaseFunctions }
                currentUser={ currentUser }
                logout = { () => this.logout(router, '/login') }/>
            );
          }}
        />
    );
  }

  privacyPolicyRoute() {
    return (
      <Route
          path="/privacy-policy"
          exact
          render={(router) => {
            return (
              <PrivacyPolicyView
                router={ router } />
            );
          }}
        />
    );
  }

  projectSettingsRoute() {
    const { currentUser } = this.state;
    return (
      <Route
          path="/projects/:projectId"
          exact
          render={(router) => {
            updateTitleBar("Project Settings");
            if (!currentUser) return <BlankView router={ router } notLoggedIn/>;
            return (
              <ProjectView
                currentUser={ currentUser }
                projectId={ router.match.params.projectId }
                db={ this.db }
                router={ router } 
                storage={ firebaseStorage }/>
            );
          }}
        />
    );
  }

  chatRoute() {
    const { currentUser } = this.state;
    return (
        <Route
          path="/chat"
          exact
          render={(router) => {
            updateTitleBar("Chat");
            if (currentUser && currentUser.activeProjectId) {
              const route = '/chat/' + currentUser.activeProjectId;
              return router.history.push(route);
            }
            if (!currentUser) {
              return this.renderLoginView(router);
            }
              return (
                <ChatView
                  router={ router }
                  firestore={ app.firestore }
                  db={ this.db }
                  currentUser={ currentUser }
                  logout = { () => this.logout(router, '/login') }/>
              );
          }}
        />
    );
  }

  baseRoute() {
    const { currentUser } = this.state;
    window.document.title = 'Prismia';
    return (
        <Route
          path="/"
          exact
          render={(router) => {
            if (false) {
              if (currentUser.activeProjectId) {
                return router.history.push('/chat/' + currentUser.activeProjectId);
              }
              return router.history.push('/chat');
            }
            return (
              <>
              <LandingView
                router={ router }
                db={ this.db }
                login={ (url) => this.login(router, url) }
                currentUser={ currentUser }
                />
              </>
            );
          } }
        />
    );
  }

  renderWhenDown() {
    return <DownView />
  }

  handleCloseErrorDialog() {
    this.setState({ errorOpen: false });
  }

  renderErrorDialog() {
    const { errorOpen=false } = this.state;
    return (
      <Dialog
        open={errorOpen}
        onClose={ () => this.handleCloseErrorDialog() }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Have Cookies Disabled?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              We use Google authentication to sign you in, but if you've got third party
              cookies disabled, that won't work. If you happen to be using Chrome, you can
              look go to <b>chrome://settings/content/cookies</b> to enable them.
          </DialogContentText>
          <iframe
            title="enable cookies"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/5OaBNKqXA2M"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <DialogContentText id="alert-dialog-description">
              (You're going to have to copy that link and paste it in the address bar.
              Google won't let us link directly to the page)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => this.handleCloseErrorDialog() } color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderWhenUp() {
    return (
      <Router>
        <ThemeProvider theme={ theme }>
          <Switch>
            { this.baseRoute() }
            { this.chatRoute() }
            { this.documentationRoute() }
            { this.guideRoute() }
            { this.loginRoute() }
            { this.logoutRoute() }
            { this.microsoftLogoutRoute() }
            { this.emailSignInRoute() }
            { this.emailFollowUpRoute() }
            { this.profileDataRoute() }
            { this.mailingListRoute() }
            { this.faqRoute() }
            { this.projectSettingsRoute() }
            { this.joinProjectRoute() }
            { this.classroomRoute() }
            { this.instructorChatRoute() }
            { this.fabricRoute() }
            { this.studentsRoute() }
            { this.lessonsRoute() }
            { this.messageBoardRoute() }
            { this.messageBoardThreadRoute() }
            { this.lessonRoute() }
            { this.projectGalleryRoute() }
            { this.blogRoute() }
            { this.jupyterCellRoute() }
            { this.lessonSharingRoute() }
            { this.customGalleryRoute() }
            { this.galleryRoute() }
            { this.projectChatRoute() }
            { this.createProjectRoute() }
            { this.privacyPolicyRoute() }
            { this.privacyRoute() }
            { this.metricsRoute() }
            { this.studentMetricsRoute() }
            { this.data1010Route() }
            { this.data1050Route() }
            { this.allMetricsRoute() }
            { this.assignmentsRoute() }
            { this.assignmentsCreationRoute() }
            { this.assignmentCreationRoute() }
            { this.drillsCreationRoute() }
            { this.drillCreationRoute() }
            { this.drillRoute() }
            { this.drillsRoute() }
            { this.assignmentRoute() }
            { this.assignmentGradingRoute() }
            { this.classReportRoute() }
            { this.pageNotFound() }
            { this.landingRoute() }
          </Switch>
        </ThemeProvider>
      </Router>
    );
  }

  render() {
    return (
      <>
        { this.renderWhenUp() }
        { this.renderErrorDialog() }
      </>
    );
  }

}

export default App;
