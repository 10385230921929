import React from 'react';
import toPlaintext from 'quill-delta-to-plaintext';
import Button from '@material-ui/core/Button';

function HocketCard({ hocket, cb, label="Delete", projectId="main" }) {
  //if (!hocket.responses || !hocket.responses.length) console.log(hocket);
  if (!hocket) return null;
  if (!hocket.responses) return null;
  if (!hocket.responses.length) return null;
  const { id, trainingPhrases=[], triggerCriteria=[], responses=[] } = hocket;
  let numberOfTriggers = trainingPhrases.length + triggerCriteria.length;
  const trigger = trainingPhrases[0] || triggerCriteria[0] || 'No triggers';
  let response ='No responses';
  if (responses.length) {
    try {
      const justTheInserts = JSON.parse(responses[0]).ops.filter(op => !!op.insert); 
      response = toPlaintext(justTheInserts);
    } catch (error) {
      console.log(hocket, responses)
      console.error(error);
    }
  }
  let tfLabelClass = 'critical'; 
  if (numberOfTriggers > 1) tfLabelClass = 'warning';
  if (numberOfTriggers > 4) tfLabelClass = 'safe';
  let resLabelClass = 'critical'; 
  if (responses.length > 1) resLabelClass = 'warning';
  if (responses.length > 4) resLabelClass = 'safe';
  const link = '/projects/' + projectId + '/hockets/' + id;
  let blackBar = <div className="never-trained">never trained</div>;
  const fallbackBar = <div className="fallback-bar">FALLBACK</div>;
  const feedbackBar = <div className="feedback-bar">FEEDBACK</div>;
  const offTopicBar = <div className="off-topic-bar">OFF TOPIC</div>;
  if (!trainingPhrases.length) blackBar = null;
  if (hocket.lastTrained) blackBar = null;
  return (
    <div className="hocket-card">
      { id === 'fallback' ? fallbackBar : null }
      { id === 'feedback' ? feedbackBar : null }
      { id === 'off-topic' ? offTopicBar : null }
      { blackBar }
      <div className="training-phrases">
        <div className={tfLabelClass + " label"} >
          { numberOfTriggers }
        </div>
        <div className="content">
          { trigger }
        </div>
      </div>
      <div className="responses">
        <div className={resLabelClass + " label"} >
        { hocket.responses.length }
        </div>
        <div className="content">
          { response.length > 100 ? response.substr(0, 100) + '...' : response }
        </div> 
      </div>
        <Button 
          className="delete-button"
          variant="contained"
          onClick={ cb }
          >
          { label }
        </Button>
      <div className="link">
        <a href={ link }  rel="noopener noreferrer" target="_blank">link</a>
      </div>
    </div>
  );
}

export default HocketCard;
