import React, { Component } from 'react';

class ClusteredMessageCardList extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.changeDetectionString !== this.props.changeDetectionString;
  }

  render() {
    const { messages, seedMessages=[], 
            seedRenderFunction, renderFunction } = this.props;
    return <>
      { messages.length ? messages.map((message, messageIndex) =>
         renderFunction(message, messageIndex)) : null }
      {seedMessages.length ? <div className="seed-message-container">
        { seedMessages.map( (message, messageIndex) => seedRenderFunction(message, messageIndex)) }
      </div> : null}
    </>;
  }
}

export default ClusteredMessageCardList;
