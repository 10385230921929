export default function selectRule(rules={}, bb={}) {
  let bestRule = null;
  let bestRuleNumberOfCriteria = -Infinity;
  for (let key in rules) {
    const rule = rules[key];
    if (!rule.triggerCriteria) continue;
    if (bestRuleNumberOfCriteria > rule.triggerCriteria.length) continue;
    let match = true;
    for (let i = 0; i < rule.triggerCriteria.length; i++) {
      // if any of the matchers return false continue
      const criterion = rule.triggerCriteria[i];
      const exp = criterion.trim();
      const parts = exp.split(' ').filter(token => !!token);
      if (parts.length === 1) {
        match = matchSingletonRule(parts[0]);
      } else if (parts.length === 2) {
        match = matchPairRule(...parts);
      } else if (parts.length === 3) {
        match = matchTripleRule(...parts);
      } else if (parts.length > 3) {
        match = matchLongRule(parts[0], parts[1], parts.slice(2).join(' '));
      }
      if (!match) break;
    }
    // here we have all matches and more criteria
    // than the prior bestRule
    if (match) {
      // make sure you're not just triggering on activeChamberIds
      let activeChamberTriggerCount = 0;
      for (let i = 0; i < rule.triggerCriteria.length; i++) {
        let criteria = rule.triggerCriteria[i];
        if (criteria.startsWith('activeChamberId')) activeChamberTriggerCount++;
      }
      if (activeChamberTriggerCount === rule.triggerCriteria.length) continue;
      bestRuleNumberOfCriteria = rule.triggerCriteria.length;
      bestRule = rule;
    }
  }
  if (!bestRule) return null;

  return bestRule;
  function matchSingletonRule(part) {
    // examples:
    // - interestedInDesign
    // just check for truthyness
    return !!bb[part];
  }
  function matchPairRule(part1, part2) {
    // examples: ... do we have any?
    // - ! interestedInDesign... maybe?
    return false;
  }
  function matchTripleRule(key, comparitor, value) {
    // examples:
    // - courseSatisfaction < 0
    // hasCompletedProject1 != true
    if (comparitor === '==') {
      if (value === 'true') return bb[key] === true;
      if (value === 'false') return bb[key] === false;
      if (value === 'null') return bb[key] === null;
      if (value === 'undefined') return bb[key] === undefined;
      if (value === 'NaN') return isNaN(bb[key]);
      if (value.startsWith('"')) {
        return bb[key] === remainderString(value);
      }
      // otherwise, it's probably a number
      return bb[key] === parseFloat(value);
    } else if (comparitor === '!=') {
      if (value === 'true') return bb[key] !== true;
      if (value === 'false') return bb[key] !== false;
      if (value === 'null') return bb[key] !== null;
      if (value === 'undefined') return bb[key] !== undefined;
      if (value === 'NaN') return !isNaN(bb[key]);
      if (value.startsWith('"')) {
        return bb[key] !== remainderString(value);
      }
      // otherwise, it's probably a number
      return bb[key] !== parseFloat(value);
    } else if (comparitor === '>') {
      return bb[key] > parseFloat(value);
    } else if (comparitor === '<') {
      return bb[key] < parseFloat(value);
    } else if (comparitor === '>=') {
      return bb[key] >= parseFloat(value);
    } else if (comparitor === '<=') {
      return bb[key] <= parseFloat(value);
    }
    return false;
  }
  function matchLongRule(key, comparitor, rest) {
    // examples:
    // - name == "Luke Davis"
    if (rest.startsWith('"') && comparitor === '==') {
      return bb[key] === remainderString(rest);
    }   
    if (rest.startsWith('"') && comparitor === '!=') {
      return bb[key] !== remainderString(rest);
    }   
    return false;
  }
}

function remainderString(s) {
  if (s.startsWith('"') && s.endsWith('"')) {
    return s.slice(1, s.length - 1);
  }
}

