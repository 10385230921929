import React, { Component } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

class Students extends Component {

  constructor(props) {
    super(props);
    this.db = null;
    this.unsub = {
      users: null,
    };
    this.state = {
      globalControl: false,
      students: [],
    };
  }
  
  componentDidMount() {
    const db = this.db = this.props.db;
    this.unsub.users = db.collection('users')
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const students = snap.docs.map(doc => {
          return doc.data();
        });
        students.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
        this.setState({ students });
      });
  }
 
  componentWillUnmount() {
    if (this.unsub.users) this.unsub.users();
  }

  turnOffGlobalControl() {
    this.switchGlobalControl(false);
  }

  turnOnGlobalControl() {
    console.log('turn on')
    this.switchGlobalControl(true);
  }

  switchGlobalControl(globalControl=true) {
    const { students } = this.state;
    const { db } = this.props;
    const { displayName } = this.props.currentUser;
    if (!displayName) return console.log('no display name for current user');
    students.forEach(s => {
      if (!s.id) return;
      const instructorDriven = globalControl ? displayName : false;
      console.log(instructorDriven);
      db.collection('users').doc(s.id)
        .set(
          { instructorDriven },
          {merge:true},
        ).catch(console.error);
    });
    //this.setState({ globalControl: !globalControl });
  }
 
  toggleGlobalControl() {
    const { students, globalControl } = this.state;
    const { db } = this.props;
    const { displayName } = this.props.currentUser;
    if (!displayName) return console.log('no display name for current user');
    students.forEach(s => {
      if (!s.id) return;
      const instructorDriven = globalControl ? false : displayName;
      db.collection('users').doc(s.id)
        .set(
          { instructorDriven },
          {merge:true},
        ).catch(console.error);
    });
    this.setState({ globalControl: !globalControl });
  }

  
  toggleStudentRole(studentId) {
    const { students } = this.state;
    const { db, projectId } = this.props;
    const student = students.filter(s => s.id === studentId)[0];
    if (!student) return;
    let isStudent = true;
    // this could be like {courses: { "dataScience": { roles: { instructor: true}}}}
    // but I want to keep it simple for now
    if (student.roles && student.roles.student) {
      isStudent = false; 
    }
    db.collection('users').doc(studentId)
      .set({ roles: { student: isStudent }}, {merge:true})
      .then(() => {
        return db
          .collection('projects')
          .doc(projectId)
          .collection('roles')
          .doc('students')
          .set({ userIds: {[studentId]: isStudent}}, {merge:true});
      }).catch(console.error);
  }

  toggleInstructorRole(studentId) {
    const { students } = this.state;
    const { db } = this.props;
    const student = students.filter(s => s.id === studentId)[0];
    if (!student) return;
    let instructor = true;
    // this could be like {courses: { "dataScience": { roles: { instructor: true}}}}
    // but I want to keep it simple for now
    if (student.roles && student.roles.instructor) {
      instructor = false; 
    }
    db.collection('users').doc(studentId)
      .set({ roles: { instructor }}, {merge:true})
      .then(() => {
        return db.collection('courses').doc('data-science')
          .collection('roles').doc('instructors')
          .set({ userIds: {[studentId]: instructor}}, {merge:true});
      }).catch(console.error);
  }

  toggleUseLoquacious(studentId) {
    console.log('toggle useLoquacious', studentId);
    const { students } = this.state;
    const { db } = this.props;
    const student = students.filter(s => s.id === studentId)[0];
    if (!student) return;
    let useLoquacious = true;
    if (student.featureFlags && student.featureFlags.useLoquacious) {
      useLoquacious = false; 
    }
    db.collection('users').doc(studentId)
      .set({ featureFlags: {useLoquacious}}, {merge:true})
      .then(() => {
        return db.collection('feature-flags').doc('use-loquacious')
          .set({ userIds: {[studentId]: useLoquacious}}, {merge:true});
      }).catch(console.error);
  }


  toggleEarlyAccess(studentId) {
    console.log('toggle early access', studentId);
    const { students } = this.state;
    const { db } = this.props;
    const student = students.filter(s => s.id === studentId)[0];
    if (!student) return;
    let earlyAccess = true;
    if (student.featureFlags && student.featureFlags.earlyAccess) {
      earlyAccess = false; 
    }
    db.collection('users').doc(studentId)
      .set({ featureFlags: {earlyAccess}}, {merge:true})
      .then(() => {
        return db.collection('feature-flags').doc('early-access')
          .set({ userIds: {[studentId]: earlyAccess}}, {merge:true});
      }).catch(console.error);
  }

  renderStudentSwitch(student) {
    if (!student) return;
    let isStudent = false;
    if (student.roles && student.roles.student) {
      isStudent = true; 
    }
    const label = 'Student';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ isStudent }
              onChange={ () => this.toggleStudentRole(student.id) }
             />
            }
          label={ label }
        />
      </FormGroup>
    );
  }

  renderInstructorSwitch(student) {
    if (!student) return;
    let instructor = false;
    if (student.roles && student.roles.instructor) {
      instructor = true; 
    }
    const label = 'Instructor';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ instructor }
              onChange={ () => this.toggleInstructorRole(student.id) }
             />
            }
          label={ label }
        />
      </FormGroup>
    );
  }

  renderFeatureFlagSwitches(student) {
    if (!student) return;
    let earlyAccess = false;
    let useLoquacious = false;
    if (student.featureFlags && student.featureFlags.useLoquacious) {
      useLoquacious = true; 
    }
    if (student.featureFlags && student.featureFlags.earlyAccess) {
      earlyAccess = true; 
    }
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ earlyAccess }
              onChange={ () => this.toggleEarlyAccess(student.id) }
             />
            }
          label="Early Access"
        />
        <FormControlLabel
          control={
            <Switch
              checked={ useLoquacious }
              onChange={ () => this.toggleUseLoquacious(student.id) }
             />
            }
          label="Use Loquacious"
        />
      </FormGroup>
    );
  }

  renderGlobalControlArea() {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={ () => this.turnOnGlobalControl() }>
          Snatch
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={ () => this.turnOffGlobalControl() }>
          Unsnatch
        </Button>
      </div>
    );
  }

  renderStudentCard(student) {
    const { projectId } = this.props;
    const href = '/projects/' + projectId + '/instructor-chat-view/' + student.id;
    const className = student.instructorDriven ? 'instructor-driven' : '';
    return (
      <div key={ student.id } className={ "student-card " + className } >
        <table>
          <tbody>
            <tr>
              <td>
                <img alt="student" src={ student.photoUrl } />
                <h3>{ student.displayName }</h3>
                <a href={ href } >chat</a>
              </td>
              <td>
                { this.renderFeatureFlagSwitches(student) }
                { this.renderStudentSwitch(student) }
                { this.renderInstructorSwitch(student) }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { students } = this.state;
    const left = [];
    const center = [];
    const right = [];
    for (let i = 0; i < students.length; i++) {
      if ( i % 3 === 0 ) {
        left.push(students[i]);
      } else if ( i % 2 === 0 ) {
        center.push(students[i]);
      } else {
        right.push(students[i]);
      }
    }
    return (
      <table className="student-data-area">
        <tbody>
          <tr>
            <td colSpan="3" className="global-controls">
              { this.renderGlobalControlArea() }
            </td>
          </tr>
          <tr>
            <td>
              { left.map((student, i) => {
                  return this.renderStudentCard(student);
                })
              }
            </td>
            <td>
              { center.map((student, i) => {
                  return this.renderStudentCard(student);
                })
              }
            </td>
            <td>
              { right.map((student, i) => {
                  return this.renderStudentCard(student);
                })
              }
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

}

export default Students;
