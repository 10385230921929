import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: false,
};

function ReadOnlyQuill({ quillDelta }) {
  if (!quillDelta) return null;
  return (
      <ReactQuill
        modules={ modules }
        readOnly
        value={quillDelta}
      />
  );
}

export default ReadOnlyQuill;
