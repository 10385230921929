import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './style.css';

class BlankView extends Component {
 
  render() {
    const { pageNotFound, notLoggedIn, router, 
            message='Page not found!'} = this.props;
    const style = {
      opacity: 0,
      fontWeight: '400',
      textAlign: 'center',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    const pageNotFoundMessage = (
      <>
        <p style={{color: "#777"}}><strong>{ message }</strong></p>
        <Button 
          onClick={ () => router.history.push("/") }
          variant="outlined"
          style={{marginTop: "3em"}}>
          Home
        </Button>
      </>
    );
    const notLoggedInMessage = (
      <Button 
          onClick={ () => router.history.push("/login") }
          variant="outlined">
          Log in to view this page
      </Button>
    );
    return (
      <div style={ style } className="blank-view">
        <img className="logo-image" src="/prismia-logo-simple.svg" alt="logo"/> <br/>
          { pageNotFound ? pageNotFoundMessage : null }
          { notLoggedIn ? notLoggedInMessage : null }
      </div>
    );
  }

}

export default BlankView;
