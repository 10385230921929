import React, { Component } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

class StackedBarGraph extends Component {

  openLiveStudentTyping() {
    if (this.props.openLiveStudentTyping) {
      this.props.openLiveStudentTyping();
    }
  }

  render() {
    const {
      numberOfClusters,
      numberTyping,
      clusteredMessages,
      isLatestBlock,
      hueValues,
    } = this.props;

    let totalCount = 0;
    const sections = [];
    const messageCounts = [];
    for (let i = 0; i < clusteredMessages.length; i++) {
      messageCounts[i] = clusteredMessages[i].length;
      totalCount += clusteredMessages[i].length;
    }
    if (numberTyping && isLatestBlock) {
      messageCounts.push(numberTyping);
      totalCount += numberTyping;
    }
    for (let i = 0; i < messageCounts.length; i++) {
      if (messageCounts[i] === 0) continue;
      const hue = hueValues[i];
      const style = {
        width: ((messageCounts[i] * 100) / totalCount) + '%',
      };
      const innerBoxStyle = {
        background: "hsl(" + hue + ", 100%, 80%)",
      };
      let tooltipTitle = messageCounts[i];
      let isWriting = false;
      if (i >= numberOfClusters) {
        innerBoxStyle.background = "hsl(" + hue + ", 100%, 100%)";
      }
      if (numberTyping && isLatestBlock && i === messageCounts.length - 1) {
        tooltipTitle += " writing";
        isWriting = true;
      }
      sections.push(
        (
          <Tooltip title={ tooltipTitle }
                   key={ i }
                   placement='top'>
            <div className="flex-auto stacked-bar-container" style={ style } key={ i }>
              <div 
                onClick={() => this.openLiveStudentTyping()}
                className={"message-count-container" + (isWriting ? " striped" : "")} style={ innerBoxStyle }>
                { messageCounts[i]/totalCount > 0.07 ?
                  <div className={"message-count-label" + (isWriting ? " high-opacity" : "")}>
                    { messageCounts[i] }
                  </div> : null
                }
              </div>
            </div>
          </Tooltip>
        )
      );
    }
    return (
      <div className="flex-child flex-container align-center">
        { sections }
      </div>
    );
  }

}

export default StackedBarGraph;
