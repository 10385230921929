import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import './style.css';

class SidebarButtonPanel extends Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps.mobile !== this.props.mobile) {
      return true;
    }
    if (nextProps.tools.length !== this.props.tools.length) {
      return true;
    }
    for (let k = 0; k < nextProps.tools.length; k++) {
      const icon1 = nextProps.tools[k].icon;
      if (!icon1 || !icon1.type || !icon1.type.type || !icon1.type.type.render) return true;
      const icon2 = this.props.tools[k].icon;
      if (!icon2 || !icon2.type || !icon2.type.type || !icon2.type.type.render) return true;
      if (icon1.type.type.render.displayName !== icon2.type.type.render.displayName) return true;
      for (let prop of ["tooltipTitle", "disabled", "hide"]) {
        if (nextProps.tools[k][prop] !== this.props.tools[k][prop]) {
          return true;
        }
      }
    }
    return false;
  }

  renderTool(tool) {
    return <IconButton
      color="primary"
      className="sidebar-tool"
      onClick={ tool.onClick }
      disabled={ tool.disabled }
      key={ "sidebar-tool-" + tool.tooltipTitle }>
      <Tooltip
        title={ tool.tooltipTitle }
        enterDelay={ 400 }>
        { tool.icon }
      </Tooltip>
    </IconButton>;
  }

  render() {
    const { mobile, tools } = this.props;
    const toolButtons = tools.filter(tool => !tool.hide).map(tool => this.renderTool(tool));
    return <div className={"sidebar-button-panel " + (mobile ? "mobile" : "desktop")}>
      { toolButtons }
    </div>;
  }
}

SidebarButtonPanel.propTypes = {
  mobile: PropTypes.bool,
  tools: PropTypes.arrayOf(PropTypes.object),
};

SidebarButtonPanel.defaultProps = {
  mobile: false,
  tools: [],
};

export default SidebarButtonPanel;
