import React, { Component, Suspense } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorBoundary from './ErrorBoundary';
import ImageCellRenderer from './ImageCellRenderer';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import { safePhotoUrl } from './profile-emojis';
import { allSame } from './utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
const AgGridReact = React.lazy(() => import('ag-grid-react').then((module) => ({default: module.AgGridReact})));

function groupingKey(data, groupingVariables) {
  let key = ''
  for (let i = 0; i < groupingVariables.length; i++) {
    if (i > 0) key += '--';
    key += data[groupingVariables[i]];
  }
  return key;
}

const aggFuncs = [{
  name: 'Count', func: A => A.length,
}, {
  name: 'Mean', func: A => A.reduce((a, b) => a + b, 0) / A.length,
},{
  name: 'Median', func: A => {
    const sorted = A.slice().sort((a,b) => a - b);
    const middle = Math.floor(A.length/2);
    if (A.length % 2 === 0) return 0.5*(
      sorted[middle-1] + sorted[middle]
    );
    return sorted[middle];
  },
}, {
  name: 'Sum', func: A => A.reduce((a, b) => a + b, 0),
}, {
  name: 'Min', func: A => A.reduce((a, b) => Math.min(a, b)),
}, {
  name: 'Max', func: A => A.reduce((a, b) => Math.max(a, b)),
}];


class DataTable extends Component {

  // props: exportColumnKeys, gridOptions, columnDefs, rowData

  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.openAggMenu = this.openAggMenu.bind(this);
    this.openColMenu = this.openColMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setGrouping = this.setGrouping.bind(this);
    this.setAgg = this.setAgg.bind(this);    
    this.state = {
      rowsDisplayed: -1,
      menuOpenRef: null,
      aggMenuOpenRef: null,
      colMenuOpenRef: null,
      aggFunc: null,
      displayedColumns: null,
      groupingVariables: [],
    }
  }

  componentDidMount() {
    const displayedColumns = {};
    this.props.columnDefs.filter(def => !def.hide && def.headerName).forEach(def => {
      displayedColumns[def.field] = true;
    })
    this.setState({ 
      displayedColumns, 
    });
    if (this.props.rowData) {
      this.setState({ rowData: this.props.rowData.slice() });
    }
  }

  componentDidUpdate(nextProps)  {
    if (nextProps.rowData !== this.props.rowData) {
      if (!this.state.groupingVariables.length) {
        this.setState({rowData: nextProps.rowData.slice()});
      }
    }
  }

  toggleColumn(def) {
    const { displayedColumns } = this.state;
    displayedColumns[def.field] = !displayedColumns[def.field];
    this.gridColumnApi.setColumnVisible(
      def.field, 
      displayedColumns[def.field],
    );
    this.setState({ displayedColumns }); 
  }

  openMenu(event) {
    this.setState({ menuOpenRef: event.currentTarget });
  }

  openAggMenu(event) {
    this.setState({ aggMenuOpenRef: event.currentTarget });
  }

  openColMenu(event) {
    this.setState({ colMenuOpenRef: event.currentTarget });
  }

  handleClose() {
    this.setState({ 
      menuOpenRef: null, 
      aggMenuOpenRef: null,
      colMenuOpenRef: null,
    });
  }

  setAgg(aggFunc) {
    let alreadyAggregated = false;
    if (this.state.aggFunc && aggFunc
          && this.state.aggFunc.name !== aggFunc.name) {
            alreadyAggregated = true;
    }
    this.setState({ aggFunc }, () => {
      this.updateRows({ 
        aggFunc: aggFunc ? aggFunc : 'clear',
        alreadyAggregated,
     });
    });
    this.handleClose();
  }

  onFilterTextBoxChanged = () => {
    if (!this.gridApi) return;
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box').value);
    this.setState({rowsDisplayed: this.gridApi.getDisplayedRowCount()});
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.updateRows();
  };

  autoSizeAll = skipHeader => {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }

  exportCsv = () => {
    const { exportColumnKeys } = this.props;
    const dayId = (new Date()).toISOString().slice(0, 10);
    this.gridApi.exportDataAsCsv(
      { fileName: "prismia-metrics-" + dayId + ".csv",
        columnKeys: exportColumnKeys }
    );
  }

  setRowData(rowData) {
    this.setState({ rowData });
    this.gridApi.setRowData(rowData);
  }

  updateRows = ({ aggFunc=null, alreadyAggregated=false }={}) => {
    const { groupingVariables } = this.state;
    if (aggFunc === 'clear') {
      const { rowData } = this.props;
      this.setRowData(rowData);
    } else if (aggFunc) {
      const { columnDefs } = this.props;
      const { aggFunc } = this.state;
      const groups = {};

      function addToGroups(data) {
        const key = groupingKey(data, groupingVariables);
        if (!groups[key]) {
          groups[key] = {};
          for (let def of columnDefs.filter(def => !def.hide && def.field)) {
            groups[key][def.field] = [];
          }
        }
        for (let def of columnDefs.filter(def => !def.hide && def.field)) {
          groups[key][def.field].push(
            data[def.field]
          );
        }
      }

      if (alreadyAggregated) {
        const { rowData } = this.props;
        rowData.forEach(data => {
          addToGroups(data);
        });
      } else {
        // get the data from what's actually displayed
        // in the table:
        this.gridApi.forEachNodeAfterFilterAndSort(
          rowNode => addToGroups(rowNode.data)
        );
      }
      const aggregatedRows = Object.keys(groups).map(groupKey => {
        const row = {};
        for (let def of columnDefs.filter(def => !def.hide)) {
          if (groupingVariables.includes(def.field)) {
            row[def.field] = groups[groupKey][def.field][0];
          } else {
            if (def.cellRenderer === 'imageCellRenderer') {
              if (allSame(groups[groupKey][def.field])) {
                row[def.field] = groups[groupKey][def.field][0];
              } else {
                row[def.field] = safePhotoUrl();
              }
            } else if (def.type || aggFunc.name === 'Count') {
              row[def.field] = +aggFunc.func(groups[groupKey][def.field]).toFixed(4);
            } else if (allSame(groups[groupKey][def.field])) {
              row[def.field] = groups[groupKey][def.field][0]
            } else {
              row[def.field] = '-';
            }
          }
        }
        return row;
      });
      this.setRowData(
        aggregatedRows
      );
    } else if (!this.state.aggFunc) {
      const itemsToUpdate = [];
      let prevValue = ''
      this.gridApi.forEachNodeAfterFilterAndSort(
        function(rowNode, index) {
          const data = rowNode.data;
          data['firstInGroup'] = false;
          itemsToUpdate.push(data);
          if (groupingVariables.length && groupingKey(data, groupingVariables) !== prevValue && prevValue !== '') {
            data['firstInGroup'] = true;
          }
          prevValue = groupingKey(data, groupingVariables);
        }
      );
      this.gridApi.applyTransaction({ update: itemsToUpdate });
    }
    setTimeout(() => {
      this.gridApi.redrawRows()
      this.setState({ rowsDisplayed: this.gridApi.getDisplayedRowCount()} );
    }, 350);
  }

  setGrouping(groupingVariable) {
    let { groupingVariables } = this.state;
    if (groupingVariables.includes(groupingVariable)) {
      groupingVariables = groupingVariables.filter(variable => variable !== groupingVariable);
    } else if (groupingVariable === null) {
      groupingVariables = [];
    } else {
      groupingVariables.push(groupingVariable);
    }
    this.setState({ groupingVariables }, () => {
      if (!this.gridApi) return;
      const sort = this.gridApi.sortController.getSortModel();      
      if (groupingVariables.length) {
        for (let variable of groupingVariables) {
          sort.unshift({
            colId: variable,
            sort: "asc",
          });
        }
      } else {
        sort.shift();
        this.setAgg(null);
      }
      this.gridApi.setSortModel(sort);
      this.updateRows({ 
        aggFunc: this.state.aggFunc,
        alreadyAggregated: !!this.state.aggFunc,
      });
    });
    this.handleClose();
  }

  naturalHeight() {
    const { rowData } = this.state;
    let height = 0;
    if (!this.props.noToolbar) {
      height += 40;
    }
    if (rowData?.length) {
      height += 110; // height on first column
      if (this.props.setData) {
        height += 54; // height of input panel plus rows displayed
      }
      height += 51 * rowData.length // height per row
    }
    return (Math.min(height, 750) || 500) + 'px';
  }

  render() {
    const { columnDefs } = this.props;
    const { rowData } = this.state;
    const gridOptions = {
      sortable: true,
      resizable: true,
      filter: true,
      enableCellTextSelection: true,
    };
    const frameworkComponents = {
      imageCellRenderer: ImageCellRenderer
    }
    return (<div
      className="ag-theme-material"
      style={{
        position: 'relative',
        width: '90%',
        maxWidth: '1000px',
        height: this.props.height || this.naturalHeight(),
        margin: 'auto',
        marginTop: "3%"}}>
      {this.props.noToolbar ? null :           
      <div className="input-group">
          <div>
            <Button
              onClick={ this.exportCsv }
              className="btn">
              Export
            </Button>
          </div> 
          <div className="inside-button">
            <Button
              onClick={ this.openMenu }
              className="btn">
              Group
            </Button>
          </div>
          <Menu
            open={ Boolean(this.state.menuOpenRef) }
            anchorEl={ this.state.menuOpenRef }
            onClose={ this.handleClose }>
            { this.state.groupingVariables.length ? <MenuItem
              key="clear-grouping"
              onClick={ () => this.setGrouping(null) }>
              (Clear)
            </MenuItem> : null }
            { columnDefs.filter(def => !def.hide && def.headerName && this.state.displayedColumns?.[def.field]).map(def => {
              return (
                <MenuItem
                  key={def.field}
                  onClick={ () => this.setGrouping(def.field) }>
                  { def.headerName }
                  { (this.state.groupingVariables.includes(def.field) ? <CheckIcon/> : null )  }
                </MenuItem>
              );
            }) }
          </Menu>
          { this.state.groupingVariables.length ? <div className="inside-button">
            <Button
              onClick={ this.openAggMenu }
              className="btn">
              { this.state.aggFunc ? this.state.aggFunc.name : "Agg" }
            </Button>
          </div> : null }
          <Menu
            open={ Boolean(this.state.aggMenuOpenRef) }
            anchorEl={ this.state.aggMenuOpenRef }
            onClose={ this.handleClose }>
            { this.state.aggFunc ? <MenuItem
              key="clear-aggregation"
              onClick={ () => this.setAgg(null) }>
              (Clear)
            </MenuItem> : null }
            { aggFuncs.map(item => {
              return (
                <MenuItem
                  key={item.name}
                  onClick={ () => this.setAgg(item) }>
                  { item.name }
                </MenuItem>
              );
            }) }
          </Menu>
          <div className="inside-button">
            <Button
              onClick={ this.openColMenu }
              className="btn">
              Select
            </Button>
          </div>
          <Menu
            open={ Boolean(this.state.colMenuOpenRef) }
            anchorEl={ this.state.colMenuOpenRef }
            onClose={ this.handleClose }>
            { this.props.columnDefs.filter(def => !def.hide && def.headerName).map(def => {
              return (
                <MenuItem
                  key={def.field}
                  onClick={ () => this.toggleColumn(def) }>
                  { def.headerName }
                  { (this.state.displayedColumns && this.state.displayedColumns[def.field] ? <CheckIcon/> : null )  }
                </MenuItem>
              );
            }) }
          </Menu>
          <input type="text" id="filter-text-box" className={"form-control" + (this.props.noExport ? " no-export-button" : "")} placeholder="Filter..." onInput={ this.onFilterTextBoxChanged }/>
        </div>
      }
      {this.props.noToolbar ? null : <div className="rows-displayed">
        { this.state.rowsDisplayed > -1 ? (this.state.rowsDisplayed + " row" + (this.state.rowsDisplayed === 1 ? "" : "s") + " displayed") : null }
      </div> }
      <div style={{position: 'relative', height: `calc(100% - ${this.props.noToolbar ? "60" : "100"}px)`}}>
      <ErrorBoundary
          key={ 'error-boundary-table' }
          errorMessage="[a data table should be showing here, but there was either a network issue or an issue with the code that was written to generate it]"
          hideDetails>
          <Suspense fallback={<div>Loading...</div>}>
            <AgGridReact
              style={{height: '100%'}}
              defaultColDef = { gridOptions }
              columnDefs={ columnDefs }
              rowData={ rowData }
              onGridReady={ this.onGridReady }
              onFirstDataRendered={ () => {
                if (this.props.sizeToFit) {
                  this.sizeToFit();
                } else {
                  this.autoSizeAll(false);
                }
                setTimeout( () => this.setState({ rowsDisplayed: this.gridApi.getDisplayedRowCount() }), 500);
                setTimeout( () => this.updateRows(), 300 );
              }}
              frameworkComponents={ frameworkComponents }
              getRowStyle={ (params) => {
                if (params.data.firstInGroup) {
                  return {borderTop: '2px solid #7f7777'};
                }
              }}
              enableCellTextSelection
              animateRows
              onSortChanged={() => this.updateRows() }
              onFilterChanged={() => this.updateRows() }>
            </AgGridReact>
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
    )
  }

}

export default DataTable;
