//import Chance from 'chance';
import toPlaintext from 'quill-delta-to-plaintext';
import firebase from 'firebase/app';

// these are all for markdown to quill conversion:
import * as visit from "unist-util-visit";
import * as unified from "unified";
import * as markdown from "remark-parse";
import * as math from "remark-math";

// for the image uploader
import uuid from 'uuid/v4';

import { binderKernels } from './jupyter';

// for hashString
const hash = require('hash.js');

export function timestampOrder(t1, t2) {
  if (!t1) return 1;
  if (!t2) return -1;
  return t1 - t2;
}

export function tryJson(str) {
  let json;
  try {
    json = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return json;
}

export function getOutline(hockets) {
  // input should be a list of hockets
  let minDepth = 2;
  const headings = [];
  hockets
    .forEach((hocket, hocketIndex) => {
    if (!hocket?.responses?.[0]) return;
    const delta = JSON.parse(hocket.responses[0]);
    delta.ops.forEach((op, i) => {
      if (op?.attributes?.header) {
        if (op.attributes.header < minDepth) {
          minDepth = op.attributes.header;
        }
        if (i > 0 && typeof delta.ops[i-1].insert === 'string') {
          headings.push({
            depth: op.attributes.header,
            title: delta.ops[i-1].insert.split("\n").slice(-1).join(),
            id: hocket.id,
            index: hocketIndex,
          });
        }
      }
    });
  });
  if (headings.length && headings.findIndex(h => h.index === 0) === -1) {
    headings.unshift({
      depth: minDepth,
      title: "Introduction",
      id: hockets[0].id,
      index: 0,
    });
  }
  return {
    outline: headings, 
    minHeaderDepth: minDepth
  };
}

export const projectSortFunc = (proj1, proj2) => {
  if (proj1.priority && !proj2.priority) {
    return -1;
  } else if (!proj1.priority && proj2.priority) {
    return 1;
  } else if (proj1.priority && proj2.priority) {
    return proj1.priority - proj2.priority;
  } else {
    return proj2.timestamp - proj1.timestamp
  }
}

export function pingBinderKernel(lastBinderPing) {
    // five minutes:
  if ((new Date() - lastBinderPing) > 300000) {
    for (let lang of Object.keys(binderKernels)) {
      if (window['prismia-' + lang + '-kernel']) {
        try {
          window['prismia-' + lang + '-kernel'].requestExecute({code: '1'});
        } catch (err) { console.log(err) }
      }
    }
    return new Date();
  }
}

export function splitGroups(s) {
  const groups = [];
  let numOpen = 0, start = 0, prefix = '', lastGroupClose;
  for (let i = 0; i < s.length; i++) {
      if (s.slice(i, i+3) === '\n<g') {
          numOpen += 1;
          if (numOpen === 1) start = i + 1;
          if (!prefix.length) prefix = s.slice(0, i);
      }
      if (s.slice(i, i + 4) === '</g>') {
          numOpen -= 1;
          if (numOpen === 0) {
            groups.push(s.slice(start, i+4));
            lastGroupClose = i + 4;
          }
      }
  }
  if (!groups.length) {
    return [s];
  } else {
    groups.unshift(prefix);
    groups.push(s.slice(lastGroupClose, s.length));
  }
  return groups;
}

export function isRegistered(hocket) {
  if (!hocket || !hocket.responses || !hocket.responses[0]) return false
  const response = hocket.responses[0];
  return toPlaintext(JSON.parse(response).ops).includes("®");
}

export function isNote(hocket) {
  if (!hocket) return false;
  if (!hocket.responses || !hocket.responses[0]) return false;
  const delta = JSON.parse(hocket.responses[0]);
  if (!delta.ops || !delta.ops[0] || !delta.ops[0].insert) return false;
  if (!delta.ops[0].insert.startsWith) return false;
  const firstInsert = delta.ops[0].insert
  return firstInsert.startsWith('📝') || firstInsert.startsWith('#📝');
}

// chunk([1,2,3,4,5], 2) -> [[1,2],[3,4],[5]]
export function chunk(a, n) {
  return Array(Math.ceil(a.length/n)).fill().map((_,i) => a.slice(i*n,i*n+n))
}

export function extractBestImage(message) {
  if (message.fabric) {
    try {
      const { fabricJson } = JSON.parse(message.fabric);
      return { url: null, fabric: fabricJson };
    } catch (err) {
    }
  }
  const delta = JSON.parse(message.quillDelta);
  return { url: extractImageUrl(delta), fabric: null };
}

export function extractImageUrl(delta) {
  if (!delta.ops || !delta.ops.length) return null;
  for (let op of delta.ops) {
    if (op.insert && op.insert.image) {
      return op.insert.image;
    }
  }
  return null;
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const pad = (n, nDigits) => "0".repeat(nDigits - String(n).length) + n
export const timeString = (message) => pad(message.timestamp.seconds, 11) + "" + pad(message.timestamp.nanoseconds, 9);

export function getFigureCode(url, Cb) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = (event) => {
    Cb(xhr.response);
  };
  xhr.open('GET', url);
  xhr.send();
}

export function svgUploader(storage, userId, svgData) { 
  return new Promise( (resolve, reject) => {
    const newFileName = '00-' + userId + "-" + uuid() + ".svg";
    const imageRef = storage.ref().child('user-sketches').child(newFileName);
    imageRef.putString(svgData, 'raw', {contentType: 'image/svg+xml'}).then( () =>
      imageRef.getDownloadURL().then( (url) => resolve(url) )
    ).catch(
      (error) => reject("Problem with inserting image")
    );
  });
}

export async function getBase64FromUrl(url) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;   
      resolve(base64data);
    }
  });
}

export function sketchUploader(storage, userId, imgData) {
  return new Promise( (resolve, reject) => {
    const newFileName = userId + "-" + uuid() + ".png";
    const imageRef = storage
                      .ref()
                      .child('user-sketches')
                      .child(newFileName);

    imageRef.putString(imgData, 'base64', {contentType: 'image/png'}).then( () =>
      imageRef.getDownloadURL().then( (url) => resolve(url) )
    ).catch(
      (error) => reject("Problem with inserting image")
    );
  });
}

export async function slidesUploader(storage, file) {
  const fileNameParts = file.name.split(".");
  const newFileName = fileNameParts[0] + uuid() + ".pdf";

  const slidesRef = storage
    .ref()
    .child('user-slides')
    .child(newFileName);

  await slidesRef.put(file).catch(console.error);
  return 'user-slides/' + newFileName;
}



// doing Quill image uploads properly, through GCS instead of using base64
export function imageUploader(storage, Cb) {
  return {
    upload: file => {
      return new Promise((resolve, reject) => {

        // 2 MB file size limit:
        if (file.size > 2 * 1024 * 1024) {
          setTimeout( () => {
            window.alert("The maximum file size for images is 2 MB");
            reject("Image greater than 2 MB");
          } );
        }

        const fileNameParts = file.name.split(".");
        const [ext] = fileNameParts.slice(-1);
        const newFileName = fileNameParts[0] + "-" + uuid() + "." + ext;

        const imageRef = storage
          .ref()
          .child('user-images')
          .child(newFileName);

        imageRef.put(file).then(() => {
           imageRef.getDownloadURL().then( (url) => {
            if (Cb) Cb(url);
            resolve(url);
          })
        }).catch(error => {
          reject("Upload failed");
        });
      });
    }
  }
}

export const hashString = s => {
  return hash.sha256().update(s).digest('hex');
}

export const hasImage = (ops) => {
  for (let op of ops) {
    if (op.insert && op.insert.image) {
      return true;
    }
  }
  return false;
}

export function stringifyMessage(message) {
  const messagePropsToWatch = ["id", "answered", "responseInProgress", "starred", "respondedTo", "flagged", "sharedWithClass", "textContent"];
  return messagePropsToWatch.map(prop => String(message[prop])).join(' ');
}

export function mousetrapStopCallback(event, element, combo)  {
    // this line is for deleting blot images in lesson view
    if (element.tagName === 'BODY' && combo === "backspace") return true;
    if (element.tagName === 'INPUT' ||
        element.tagName === 'SELECT' ||
        element.tagName === 'TEXTAREA' ||
        (element.contentEditable && element.contentEditable === 'true')) {
      return !(combo.startsWith('mod') || combo.startsWith('esc') || combo.startsWith('ctrl'));
    } else {
      return false;
    }
  }

export const pickRandom = (arr) => arr[arr.length * Math.random() | 0];

function stripTerminalNewlines (str) {
  while(str[str.length - 1] === '\n') {
    str = str.slice(0, str.length - 1);
  }
  return str;
}

export function shortUID() {
  var firstPart = (Math.random() * 1679616) | 0;
  var secondPart = (Math.random() * 1679616) | 0;
  firstPart = ("0000" + firstPart.toString(36)).slice(-4);
  secondPart = ("0000" + secondPart.toString(36)).slice(-4);
  return (firstPart + '-' + secondPart).toUpperCase();
}

export function isAdmin(id='') {
  if (id === 'ZwcJiD73RSXPlyVz8se4ZW4QOWx2') return true;
  if (id === '9WbgyZQgrIP7QesqFwHU4kZQfdq1') return true;
  return false;
}

export function updateTitleBar(str) {
  window.document.title = 'Prismia: ' + str;
}

export function codeBlockDelta(text) {
  const ops = [];
  for (let line of text.trim().split("\n")) {
    if (line.length > 0) {
      ops.push({insert: line});
      ops.push({insert: "\n", attributes: {"code-block": true}});
    } else if (ops[ops.length - 2]?.insert) {
      ops[ops.length - 1].insert += "\n";
    }
  }
  return { ops };
}

export function last(arr) {
  return arr[arr.length - 1];
}

export function removeTerminalNewlinesFromQuillDelta(quillDelta) {
  if (!quillDelta.ops) return quillDelta;
  const lastOp = quillDelta.ops[quillDelta.ops.length - 1];
  const lastInsert = lastOp.insert;
  if (lastInsert) {
    quillDelta.ops[quillDelta.ops.length - 1].insert = stripTerminalNewlines(lastInsert);
  }
  if (lastOp.attributes && lastOp.attributes['code-block'] ) {
    quillDelta.ops[quillDelta.ops.length - 1].insert += '\n';
  }
  return quillDelta;
}

export const now = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};

export const arraySafeNow = () => {
  return firebase.firestore.Timestamp.now();
}

export const ensureSmall = (hocket, n=1) => {
  const stringified = JSON.stringify(hocket);
  if (stringified.length < 20000) {
    return stringified;
  } else {
    const smallerHocket = {...hocket};
    for (let key of Object.keys(smallerHocket)) {
      if (JSON.stringify(smallerHocket[key]).length > 2500/n) {
        smallerHocket[key] = null;
      }
    }
    return ensureSmall(smallerHocket, 2*n);
  }
}

export const allSame = (array) => {
  if (!array) return false;
  if (array.length < 2) return true;
  for (let i = 1; i < array.length; i++) {
    if (array[i] !== array[0]) {
      return false;
    }
  }
  return true;
}

export const deleteField = () => {
  return firebase.firestore.FieldValue.delete();
}

export const arrayUnion = (item) => {
  return firebase.firestore.FieldValue.arrayUnion(item);
}

export function parseQuestion(data) {
  const { id, path } = data.ref;
  const displayName = data.displayName || '';
  return { displayName, id, path };
}

/*
export function parseChambers(data) {
  const res = [];
  for (let key in data) {
    if (data[key].archived) continue;
    const { id, path } = data[key].ref;
    const displayName = data[key].displayName || '';
    const chance = new Chance(id);
    const color = chance.color({ format: 'hex' });
    res.push({ color, displayName, id, path });
  }
  return res;
}
*/

export const log = (...args) => {
  const debug = true;
  if (!debug) return;
  console.log(...args);
};

export const isValidDelta = (stringifiedHocket) => {
  if (!stringifiedHocket) return false;
  try {
    toPlaintext(JSON.parse(stringifiedHocket).ops);
    return true;
  } catch (err) {
    return false;
  }
}

export const extractTextFromStringifiedHocket = (stringifiedHocket) => {
  if (!stringifiedHocket) return '';
  if (typeof stringifiedHocket !== 'string') {
    try {
      return toPlaintext(stringifiedHocket.ops);
    } catch (err) {
      console.error(err);
    }
  }
  try {
    const justTheInserts = JSON.parse(stringifiedHocket).ops.filter(op => !!op.insert);
    return toPlaintext(justTheInserts);
  } catch (err) {
    console.error(err);
    return '';
  }
};

export function splitEvenly(arr, n) {
  const res = [];
  for (let i = 0; i < n; i++) {
    res.push([]);
  }
  for (let i = 0; i < arr.length; i++) {
    res[i % n].push(arr[i]);
  }
  return res;
};


export function randomProjectName() {
  const first = [
    'intro to',
    'introductory',
    'intermediate',
    'experimental',
    'advanced',
    'senior',
    'freshman',
    'sophomore',
    'junior',
  ];
  const middle = [
    'translunar',
    'future',
    'atemporal',
    'martian',
    'astral',
  ];
  const last = [
    'volcanology',
    'archaeoastronomy',
    'quantum theory',
    'paleoanthropology',
    'cybernetics',
    'ethnobotany',
    'mythology',
    'symbolism',
    'etymology',
    'rhetoric',
    'alchemy',
    'epistemology',
    'meta-ethics',
    'gastronomy',
    'econometrics',
    'psychopathology',
    'biophysics',
    'nutrition',
    'ecology',
    'virology',
    'geobiology',
    'femtochemistry',
    'photochemistry',
    'paleontology',
  ];
  return pickRandom(first) + ' ' +  pickRandom(middle) + ' ' + pickRandom(last);
}

export function extractCodeText(message, Cb) {
  if (!message) return '';
  const delta = typeof message.quillDelta === 'object' ? message.quillDelta :  JSON.parse(message.quillDelta);
  let code = '';
  for (let k = 1; k < delta.ops.length; k++) {
    if (delta.ops[k].attributes && delta.ops[k].attributes['code-block']) {
      if (delta.ops[k-1].insert) {
        const [lastLine] = delta.ops[k-1].insert.split("\n").slice(-1);
        code += lastLine + '\n'; // code blocks
      }
    } else if (delta.ops[k].attributes && delta.ops[k].attributes['code']) {
      code += delta.ops[k].insert + '\n'; // inline code
    }
  }
  if (Cb) Cb(code);
  return (code || message.textContent || toPlaintext(delta.ops)).trim();
}

export function extractCodeBlock(quillDelta) {
  if (quillDelta.responses) {
      // it's actually a hocket in this case; just some
      // extra flexibility
    const message = quillDelta.responses[0];
    if (!message) return "";
    quillDelta = JSON.parse(message);
  }
  const delta = typeof quillDelta === 'string' ? tryJson(quillDelta) : quillDelta;
  if (delta === false) {
    return '';
  }
  let code = '';
  let exited = 0; // whether we've exited the code environment since we last appended
  for (let k = 1; k < delta.ops.length; k++) {
    if (delta.ops[k].attributes && delta.ops[k].attributes['code-block']) {
      if (delta.ops[k-1]?.insert?.split) {
        const lines = delta.ops[k-1].insert.split("\n");
        const [lastLine] = lines.slice(-1);
        exited += lines.length - 1;
        if (exited > 1) code += '\n';
        exited = 0;
        code += lastLine + '\n'; // code blocks
        if (delta.ops[k].insert && delta.ops[k].insert.length > 1) {
          code += delta.ops[k].insert.slice(1);
        }
      }
    } else {
      exited += 1;
    }
  }
  return code.replace(/\u00A0/g, ' ').trim(); // replace non-breaking spaces with regular ones
}

function isCodeblock(line) {
    return line.slice(0, 3) === "```"
}

export function convertCodeBlocks(text, language='code') {
    let blockmode = false;
    let lines = [];
    for (const line of text.split("\n")) {
      if (blockmode) {
          if (isCodeblock(line)) {
             lines.push(line.slice(3, line.length));
          } else {
              lines.push('```')
              lines.push(line);
              blockmode = false;
          }
      } else {
          if (isCodeblock(line)) {
              lines.push('```' + language);
              lines.push(line.slice(3, line.length));
              blockmode = true;
          } else {
              lines.push(line);
          }
      }
    }
    if (blockmode) {
      lines.push('```');
    }
    return lines.join("\n");
}

export function convertDisplayedEquations(text) {
    const regex = /\$\$(.*?)\$\$/g;
    return text.replaceAll(regex, "\n$$$$\n$1\n$$$$");
}

export function touchUpMarkdown(text, language='code') {
  return convertDisplayedEquations(convertCodeBlocks(text, language));
}

export function splitQuillDeltaOnHorizontalRule(delta) {
  let newDeltas = [{ops: []}];
  for (let op of delta.ops) {
    if (op.insert && op.insert.hr) {
      newDeltas.push({ops: []});
    } else {
      newDeltas[newDeltas.length-1].ops.push(op);
    }
  }
  return newDeltas;
}

export function splitQuillDeltaOnDoubleNewline(delta) {
  let newDeltas = [{ops: []}];
  const codeblock = (op) =>
    (op.attributes && op.attributes["code-block"]);
  for (let op of delta.ops) {
    if ( !(op.insert) ||
         typeof op.insert !== "string" ||
         !(op.insert.includes("\n\n")) ||
         (codeblock(op)) ) {
      newDeltas[newDeltas.length-1].ops.push(op);
    } else {
      const newCells = op.insert.split("\n\n");
      for (let newCell of newCells) {
        newDeltas.push({ops: [{insert: newCell}]});
      }
    }
  }
  return newDeltas.filter( delta => !isEmpty(delta));
}

export const isWhitespace = (op) =>
  ('insert' in op &&
     typeof op.insert === "string" &&
     op.insert.trim() === "");

export const isEmpty = (delta) => delta.ops.map(isWhitespace).every(x => x);

export function formulaSplitOp(op) {
  if (!op.insert) return;
  const regex = /(\${1,2}.*?\${1,2})/;
  const texts = op.insert.split(regex);
  const ops = [];
  for (let text of texts) {
    if (text.startsWith("$$") && text.endsWith("$$")) {
      ops.push({insert: {formula: text.slice(2, text.length-2)}});
      ops.push({insert: "\n", attributes: {align: "center"}});
    } else if (text.startsWith("$") && text.endsWith("$")) {
      ops.push({insert: {formula: text.slice(1, text.length-1)}});
    } else {
      const newOp = {...op};
      newOp.insert = text;
      ops.push(newOp);
    }
  }
  return ops;
}

export function catDelta(delta1, delta2) {
  if (!delta1 || isEmpty(delta1)) return delta2;
  if (!delta2 || isEmpty(delta2)) return delta1;
  return {ops: delta1.ops.concat([{insert: '\n\n'}]).concat(delta2.ops)};
}

export function peelOffSuggestions(hocket) {
  hocket = {...hocket};
  const isListItemIndicator = (op) => {
    return op &&
            op.attributes &&
            op.attributes.list &&
            (op.attributes.list.endsWith("checked"));
  };
  const splitOnLastNewLine = (str) => {
    const pieces = str.split("\n");
    return [pieces.slice(0, -1).join("\n"),
            pieces.slice(-1)[0]];
  }
  if (hocket.responses.length === 0) return;
  const delta = JSON.parse(hocket.responses[0]);
  while (true) {
    const [lastOp] = delta.ops.slice(-1);
    if (isListItemIndicator(lastOp)) {
      delta.ops.pop(); // pull off indicator
      const lastItem = delta.ops.pop();
      const suggestion = {
        relatedHocketId: hocket.id,
        value: lastItem.insert,
        score: lastOp.attributes.list === "checked" ? 1 : 0,
      };
      hocket.suggestions.unshift(suggestion);
    } else {
      break;
    }
  }
  // sometimes the first suggestion chip ends up
  // including some content which precedes the
  // actual ordered list:
  if (hocket.suggestions.length > 0 && hocket.suggestions[0].value.includes("\n")) {
    const [lastPartOfResponse, suggestionChip] = splitOnLastNewLine(hocket.suggestions[0].value);
    delta.ops.push({insert: lastPartOfResponse});
    hocket.suggestions[0].value = suggestionChip;
  }
  hocket.responses = [JSON.stringify(delta)];
  return hocket;
}

export function tryUntilSuccess(callback, {wait=1000, limit=20}={}) {
  const success = callback();
  if (!success && limit > 0) {
    setTimeout(() => tryUntilSuccess(callback, {wait, limit: limit - 1}), wait);
  }
}

export function compileLaTeX(text, callback, {color="currentColor"}={}) {
  const div = document.createElement("div");
  div.style.position = "absolute";
  div.style.top = "-1000px";
  document.body.appendChild(div);
  const se = document.createElement("script");
  se.setAttribute("type", "math/tex");
  se.innerHTML = text;
  div.appendChild(se);
  window.MathJax.Hub.Process(se, () => {
      // When processing is done, remove from the DOM
      // Wait some time before doing that because MathJax calls this function before
      // actually displaying the output
      const display = () => {
          // Get the frame where the current Math is displayed
          const frame = document.getElementById(se.id + "-Frame");
          if (!frame) {
              setTimeout(display, 500);
              return;
          }
          
          // Load the SVG
          const svg = frame.getElementsByTagName("svg")[0];
          svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
          svg.setAttribute("version", "1.1");
          const height = svg.parentNode.offsetHeight;
          const width = svg.parentNode.offsetWidth;
          svg.setAttribute("height", height);
          svg.setAttribute("width", width);
          svg.removeAttribute("style");
          
          // Embed the global MathJAX elements to it
          const mathJaxGlobal = document.getElementById("MathJax_SVG_glyphs");
          svg.appendChild(mathJaxGlobal.cloneNode(true));
          
          // Create a data URL
          const svgSource = '<?xml version="1.0" encoding="UTF-8"?>\n<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n' + svg.outerHTML.replaceAll("currentColor", color);
          const retval = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgSource)));
          
          // Remove the temporary elements
          document.body.removeChild(div);
          
          // Invoke the user callback
          callback(retval, width, height);
      };
      setTimeout(display, 200);
  });
}

export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  if (rect.bottom < 0) return false;
  if (rect.top >= (window.innerHeight || document.documentElement.clientHeight)) return false;
  return true;
}

export function markdownToDelta(md) {
    const processor = unified().use(markdown).use(math);
    const tree = processor.parse(md);
    const ops = [];
    const addNewline = () => ops.push({ insert: "\n" });
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);
    const listVisitor = (node) => {
        if (node.ordered && node.start !== 1) {
            throw Error("Quill-Delta numbered lists must start from 1.");
        }
        visit(node, "listItem", listItemVisitor(node));
    };
    const listItemVisitor = (listNode) => (node) => {
        for (const child of node.children) {
            visit(child, "paragraph", paragraphVisitor());
            let listAttribute = "";
            if (listNode.ordered) {
                listAttribute = "ordered";
            }
            else if (node.checked) {
                listAttribute = "checked";
            }
            else if (node.checked === false) {
                listAttribute = "unchecked";
            }
            else {
                listAttribute = "bullet";
            }
            ops.push({ insert: "\n", attributes: { list: listAttribute } });
        }
    };
    const paragraphVisitor = (initialOp = {}) => (node) => {
        const { children } = node;
        const visitNode = (node, op) => {
            if (node.type === "text") {
                op = Object.assign({}, op, { insert: node.value });
            }
            else if (node.type === "strong") {
                op = Object.assign({}, op, { attributes: Object.assign({}, op.attributes, { bold: true }) });
                return visitChildren(node, op);
            }
            else if (node.type === "emphasis") {
                op = Object.assign({}, op, { attributes: Object.assign({}, op.attributes, { italic: true }) });
                return visitChildren(node, op);
            }
            else if (node.type === "delete") {
                op = Object.assign({}, op, { attributes: Object.assign({}, op.attributes, { strike: true }) });
                return visitChildren(node, op);
            }
            else if (node.type === "image") {
                op = { insert: { image: node.url } };
                if (node.alt) {
                    op = Object.assign({}, op, { attributes: { alt: node.alt } });
                }
            }
            else if (node.type === "link") {
                const text = visitChildren(node, op);
                op = Object.assign({}, text, { attributes: Object.assign({}, op.attributes, { link: node.url }) });
            } else if (node.type === "linkReference") {
              op = {insert: `[${node.label}]`};
            }
            else if (node.type === "inlineCode") {
                op = {
                    insert: node.value,
                    attributes: Object.assign({}, op.attributes, { code: true })
                };
            }
            else if (node.type === "inlineMath") {
              op = {
                insert: {formula: node.value}
              };
            }
            else {
                op = {
                    insert: node.value || ""
                };
            }
            return op;
        };
        const visitChildren = (node, op) => {
            const { children } = node;
            const ops = children.map((child) => visitNode(child, op));
            return ops.length === 1 ? ops[0] : ops;
        };
        for (const child of children) {
            const localOps = visitNode(child, initialOp);
            if (localOps instanceof Array) {
                flatten(localOps).forEach(op => ops.push(op));
            }
            else {
                ops.push(localOps);
            }
        }
    };
    for (let idx = 0; idx < tree.children.length; idx++) {
        const child = tree.children[idx];
        const nextType = idx + 1 < tree.children.length ? tree.children[idx + 1].type : "lastOne";
        if (child.type === "paragraph") {
            paragraphVisitor()(child);
            if (nextType === "paragraph" ||
                nextType === "heading") {
                addNewline();
                addNewline();
            }
            else if (nextType === "lastOne" ||
                     nextType === "list" ||
                     nextType === "code") {
                addNewline();
            }
        }
        else if (child.type === "list") {
            listVisitor(child);
            if (nextType === "list" || nextType === "paragraph") {
                addNewline();
            }
        }
        else if (child.type === "code") {
            for (let line of child.value.split("\n")) {
                ops.push({ insert: line.replace(/\u00A0/g, ' ') }); // replace non-breaking spaces
                ops.push({ insert: "\n", attributes: { "code-block": true } });
            }
            if (nextType === "paragraph" || nextType === "lastOne") {
                addNewline();
            }
        }
        else if (child.type === "heading") {
            paragraphVisitor()(child);
            ops.push({ insert: "\n", attributes: { "header": child.depth } });
        }
        else if (child.type === "math") {
          ops.push({insert: "\n"});
          ops.push({insert: {formula: child.value}});
          ops.push({insert: "\n", attributes: {align: "center"}});
        } else if (child.type === "blockquote") {
          for (const child2 of child.children) {
            visit(child2, "paragraph", paragraphVisitor());
          }
          ops.push({insert: "\n", attributes: {blockquote: true}});
        }
        else {
            console.warn(`Unsupported type: ${child.type}`);
        }
    }
    return ops;
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Copying to clipboard was successful!');
  }, function(err) {
    console.error('Could not copy text: ', err);
  });
}

export function capitalize(text) {
  const lower = text.toLowerCase();
  return text.charAt(0).toUpperCase() + lower.slice(1);
}

export function blockQuote(quillDelta) {
  const italicize = op => {
    const newOp = {...op};
    newOp.attributes = (op.attributes || {});
    newOp.attributes.italic = true;
    return newOp;
  };
  const ops = [{insert: "‟"}]; // this IS a double quote; just looks weird in Atom
  ops.push(...quillDelta.ops.map(italicize));
  if (ops.slice(-1)[0].insert && ops.slice(-1)[0].insert.endsWith("\n")) {
    ops.slice(-1)[0].insert = ops.slice(-1)[0].insert.replace(/~*$/, ''); // right-trim
  }
  ops.push({insert: "”\n\n"});
  return { ops };
}

export function unique(elems) {
  const uniqueElements = [];
  for (let elem of elems) {
    if (!uniqueElements.includes(elem)) {
      uniqueElements.push(elem);
    }
  }
  return uniqueElements;
}

export function findLastIndex(array, predicate) {
	for (let i = array.length - 1; i >= 0; --i) {
		const x = array[i];
		if (predicate(x)) {
			return i;
		}
	}
  return -1;
}

export function blockQuoteOld(quillDelta) {
  const blockQuoteIndicator = {insert: "\n", attributes: {blockquote: true}};
  const ops = [];
  for (let op of quillDelta.ops) {
    if (op.insert && typeof op.insert.endsWith === 'function' &&
          op.insert.endsWith("\n")) {
      op.insert = op.insert.slice(0, -1);
      ops.push(op);
      ops.push(blockQuoteIndicator);
    } else {
      ops.push(op);
    }
  }
  ops.push(blockQuoteIndicator);
  ops.push({insert: "\n"});
  return { ops };
}

export function browserCheck() {
  if (navigator && navigator.userAgent &&
        navigator.userAgent.includes &&
          navigator.userAgent.includes("Firefox")) {
          window.alert("Firefox is not supported. Please use Chrome or Safari for Prismia.");
        }
}

export function downloadTextFile(filename, data) {
    const blob = new Blob([data], {type: 'text/ipynb'});
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}
