const green     =  [40,  200,  100];
const orange    =  [255,  99,  55];
const purple    =  [144,  22,  191];
const fuchsia   =  [228,  45,  171];
const teal      =  [35,  120,  130];
const blue      =  [61,   53,  207];
const red       =  [184,  33,  33];
const lightblue =  [53,  164,  207];
const gold      =  [193, 160,  39];

const courseLibrary = [{
  title: 'Sets & Functions',
  url: 'shared/sets-and-functions',
  sections: [
    "Subsets",
    "Set Operations",
    "Lists",
    "Functions",
  ],
  color: purple,
}, {
  title: 'Programming in Python',
  url: 'shared/programming-in-python',
  sections: [
    "Types",
    "Conditionals",
    "Functions",
    "Packages",
    "Classes",
    "Lists and Tuples",
    "Sets and Dictionaries",
    "Iteration",
    "Projects",
  ],
  color: orange,
}, {
  title: 'The Data Science Pipeline',
  url: 'shared/data-science-pipeline',
  sections: [
    "Introduction",
    "Data Acquisition",
    "Data Wrangling",
    "Data Visualization",
    "Data Modeling",
    "Communication",
  ],
  color: green,
}, {
  title: 'Data Science Ethics',
  url: 'shared/data-ethics',
  sections: [
    "Introduction",
    "Fairness",
    "Equity",
    "Transparency",
    "Intepretability",
    "Privacy",
    "Conclusion",
  ],
  color: fuchsia,
}, {
  title: 'Linear Algebra',
  url: 'shared/linear-algebra',
  sections: [
    "Vectors",
    "Span",
    "Linear Independence",
    "Linear Transformations",
    "Matrices",
    "Dot Products",
    "Orthogonality",
  ],
  color: blue,
}, {
  title: 'Calculus',
  url: 'shared/calculus',      
  sections: [
    "Limits and Continuity",
    "Differentiation",
    "Taylor Polynomials",
    "Optimization",
    "Integration",
    "Multivariable Differentiation",
    "Sequences and Series",
  ],
  color: red,
}, {
  title: 'Probability',
  url: 'shared/probability',      
  sections: [
    "Counting",
    "Probability Models",
    "Random Variables",
    "Probability Distributions",
    "Conditional Probability",
    "Independence",
    "Expectation and Covariance",
    "Conditional Expectation",
    "Common Distributions",
    "Central Limit Theorem",
  ],
  color: lightblue,
}, {
  title: 'Python to Julia',
  url: 'shared/python-to-julia',
  sections: [
    "The Basics",
    "Types",
    "Control Flow",
    "Packages",
    "Composite Types",
    "Multidimensional Arrays",
    "Plotting",
  ],
  color: gold,
}, {
  title: 'Numerical Computation',
  url: 'shared/numerical-computation',
  sections: [
    'Introduction',
    'Machine Arithmetic',
    'Error',
    'Pseudorandom Number Generation',
    'Automatic Differentiation',
    'Optimization',
    'Parallel Computing',
  ],
  color: teal,
}, {
  title: 'Calculus: Part 2',
  url: 'shared/calculus-2',
  sections: [
    'Multivariable Optimization',
    'Second-order Differentiation',
    'Matrix Differentiation',
    'The Chain Rule',
    'Multivariable Integration',
  ],
  color: red,
}, {
  title: 'Linear Algebra: Part 2',
  url: 'shared/linear-algebra-2',
  sections: [
    'Eigenanalysis',
    'Positive Definite Matrices',
    'The Spectral Theorem',
    'Polar Decomposition',
    'The Singular Value Decomposition',
    'SVD Applications',
    'Determinants',
  ],
  color: blue,
}, {
  title: 'Statistics',
  url: 'shared/statistics',
  sections: [
    'Point Estimation',
    'Confidence Intervals',
    'Empirical CDF Convergence',
    'Bootstrapping',
    'Maximum Likelihood Estimation',
    'Hypothesis Testing',
    'Causal Inference',
  ],
  color: fuchsia,
}, {
  title: 'Machine Learning',
  url: 'shared/machine-learning',
  sections: [
    'Statistical Learning Theory',
    'Linear Regression',
    'Logistic Regression',
    'Support Vector Machines',
    'Decision Trees',
    'Ensemble Methods',
    'Neural Networks',
    'Dimension Reduction',
  ],
  color: green,
}, {
  title: 'Bayesian Inference',
  url: 'shared/bayesian',
  sections: [
    'Markov Chain Monte Carlo',
    'Bayesian Networks',
    'Expectation-Maximization',
    'Probabilistic Programming',
  ],
  color: purple,
}, {
  title: 'Epidemic Modeling',
  url: 'shared/epidemic-modeling',
  sections: [
    'A Simple Model for Disease Transmission',
    'Connections to Differential Equations',
    'Lessons for the Real World',
  ],
  color: orange,
}, {
  title: 'Data and Data Systems',
  url: 'shared/data-and-data-systems',
  sections: [
    'The Structure of Data',
    'Common Data Formats',
    'Data Systems',
    'Databases',
    'Hosting',
    'File Storage',
    'Dashboards',
    'Data Marts, Warehouses, and Lakes',
  ],
  color: blue,
}, {
  title: 'Computing Basics',
  url: 'shared/computing-basics',
  sections: [
    'CPU and RAM',
    'How Programs Run',
    'The Stack and the Heap',
    'Garbage Collection',
    'Making Python Fast',
    'The File System',
    'The Shell',
  ],
  color: teal
}, {
  title: 'Data Science Utilities',
  url: 'shared/data-science-utilities',
  sections: [
    'Jupyter Notebooks',
    'VS Code',
    'Virtual Environments with Conda',
    'Version Control with Git',
    'LaTeX',
    'Markdown',
    'Docker',
  ],
  color: red,
}, {
  title: 'Databases',
  url: 'shared/databases',
  sections: [
    'Relational Databases',
    'Relational Algebra',
    'Grouping, Aggregations, and Joins',
    'Advanced SQL Queries',
    'Document Databases',
    'Graph Databases',
  ],
  color: fuchsia,
}];

export default courseLibrary;