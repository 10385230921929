import React, { Component } from 'react';
import ReactQuill from 'react-quill';

class PinnedMessage extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      quillDelta: null,
    }
    this.populated = false;
  }

  componentDidMount() {
    this.setState({ quillDelta: JSON.parse(this.props.quillDelta) }, () => {
      this.populated = true;
    });
  }

  shouldComponentUpdate(nextProps) {
    return !this.populated || (this.props.quillDelta !== nextProps.quillDelta);
  }

  handleChange(content, delta, source, editor) {
    this.setState({
      quillDelta: editor.getContents(),
    });
  }

  render() {
    return <ReactQuill
      modules={{ toolbar: false, blotFormatter: {} }}
      value={ this.state.quillDelta }
      handleChange={ this.handleChange }
    />;
  }

}

export default PinnedMessage;
