import React, { Component } from 'react';

class Suggestions extends Component {

  render() {
    const {
      suggestions,
      active,
      onClick,
    } = this.props;
    if (suggestions.length === 0) return null;
    return (<div
      className="suggestion-chips">
        { suggestions.map((sug, i) => {
          let val = '';
          if (typeof sug === 'string') val = sug;
          if (sug.value) val = sug.value;
          if (!sug.value) return null;
          if (!sug.value.trim()) return null;
          return (<button
            className={ "no-outline" + (active ? "" : " inactive") }
            onClick={ onClick }
            key={ 'suggestion' + val + i }>
            { val }
          </button>); }) }
    </div>);
  }

}

export default Suggestions;
