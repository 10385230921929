import React, { Component } from 'react';
import SimpleAdminNav from '../SimpleAdminNav';
import DataTable from '../DataTable'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import moment from 'moment-timezone';

import { updateTitleBar, isRegistered, now } from '../utils';
import "./style.css";

class AllMetricsView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      metrics: null,
    };
    this.state = {
      assignments: {},
      assignmentScores: {},
      sharedLessons: {},
      sharedHockets: {},
      liveClassDays: {},
      sharedLessonMap: {},
      lectureTable: {},
      timeZoneOffset: -5,
      studentIds: [],
      studentMap: {},
      studentEmails: {},
    };
    this.setRefs(this.props);
    this.messageIds = {};
  }

  componentDidMount() {
    this.setRefs(this.props);
  }

  setRefs(props) {
    this.getStudentIds().then( () => {
      this.subProject(props, () => {
        this.getStudentData(props);
        this.requestData(props);
        this.subMetrics(props);
        this.subSharedChat(props);
        this.subAssignments(props);
        this.subLiveClass(props);
      });
    });
  }

  getStudentData(props) {
    const { db } = props;
    const { studentIds } = this.state;
    for (let userId of studentIds) {
      db.collection('users')
        .doc(userId)
        .get()
        .then(snap => {
          const studentData = snap.data();
          const { displayName, studentId } = studentData;
          const { studentMap } = this.state;
          studentMap[userId] = {displayName, studentId};
          this.setState({ studentMap });
        })
        .catch(console.error);
      db.collection('users')
        .doc(userId)
        .collection('meta')
        .doc('private')
        .get()
        .then(snap => {
          const studentData = snap.data();
          const { email } = studentData;
          const { studentEmails } = this.state;
          studentEmails[userId] = email;
          this.setState({ studentEmails });
        })
        .catch(console.error);
    }
  }

  subProject(props, Cb) {
    const { db, projectId } = props;
    this.unsub.project = db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const project = snap.data();
        if (!project) return null;
        const timeZoneOffset = project.timeZoneOffset || -5;
        this.setState({ timeZoneOffset }, Cb);
      });
  }

  getStudentIds() {
    const { db, projectId } = this.props;
    return db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('students')
      .get()
      .then(snap => {
        const data = snap.data();
        const userIds = Object.keys(data.userIds);
        this.setState({ studentIds: userIds.filter(id => data.userIds[id]) });
      })
      .catch(console.error);
  }

  subLiveClass(props) {
    const { db, projectId } = props;
    const { studentIds } = this.state;
    for (let studentId of studentIds) {
      db.collection('users')
        .doc(studentId)
        .collection('student-metrics')
        .doc(projectId)
        .collection('live-class')
        .onSnapshot(snap => {
          const { liveClassDays } = this.state;
          liveClassDays[studentId] = snap.docs.map(doc => doc.data());
          this.setState({ liveClassDays });
        })
    }
  }

  subSharedHocket(hocketId) {
    const { db, projectId } = this.props;
    if (this.unsub['shared-hocket-' + hocketId]) return;
    this.unsub['shared-hocket-' + hocketId] = db.collection('projects')
      .doc(projectId)
      .collection('shared-hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const data = snap.data();
        const { sharedHockets } = this.state;
        sharedHockets[hocketId] = data;
        this.setState({ sharedHockets });
      })
  }

  subSharedChat() {
    const { db, projectId } = this.props;
    this.unsub.sharedChats = db.collection('projects')
      .doc(projectId)
      .collection('shared-lessons')
      .onSnapshot(snap => {
        snap.docs.forEach(doc => {
          this.subSharedChatLesson(doc.id)
        })
      });
  }

  subSharedChatLesson(lessonId) {
    const { db, projectId } = this.props;
    const { studentIds } = this.state;
    if (!projectId || !lessonId) return;
    const { timeZoneOffset } = this.state;
    db.collection('projects')
      .doc(projectId)
      .collection('shared-lessons')
      .doc(lessonId)
      .get()
      .then(doc => {
        const data = doc.data();
        if (data.title) {
          const { sharedLessons, lectureTable } = this.state;
          const lectureDate = moment(data?.lectureDate?.toDate()).add(timeZoneOffset, 'hours').toISOString().slice(0, 10);
          sharedLessons[data.id] = {
            title: data.title,
            hockets: data.hockets.map(hocketStub => hocketStub.hocketId),
            lectureDate,
          };
          if (lectureDate) {
            lectureTable[lectureDate] = data.title;
          }
          for (let hocket of data.hockets) {
            this.subSharedHocket(hocket.hocketId);
          }
          this.setState({ sharedLessons, lectureTable });
        }
      });
    for (let studentId of studentIds) {
      this.unsub['shared-lesson-' + lessonId + studentId] = db.collection('users')
        .doc(studentId)
        .collection('shared-chats')
        .doc(projectId)
        .collection('lessons')
        .doc(lessonId)
        .collection('messages')
        .onSnapshot(messageRefs => {
          const { sharedLessonMap } = this.state;
          messageRefs.docs.map(snap => snap.data()).forEach(message => {
            if (!sharedLessonMap[studentId]) {
              sharedLessonMap[studentId] = {};
            }
            if (!sharedLessonMap[studentId][lessonId]) {
              sharedLessonMap[studentId][lessonId] = {};
            }
            if (!sharedLessonMap[studentId][lessonId].messages) {
              sharedLessonMap[studentId][lessonId].messages = [];
            }
            if (!this.messageIds[studentId]) this.messageIds[studentId] = new Set();
            if (!this.messageIds[studentId].has(message.parent)) {
              this.messageIds[studentId].add(message.parent);
              sharedLessonMap[studentId][lessonId].messages.push(message);
            }
          });
          this.setState({ sharedLessonMap });
        });
    }
  }

  requestData(props) {
    const { db, projectId, currentUser } = props;
    const { studentIds } = this.state;
    if (!db || !projectId || !currentUser) return;
    if (!this.requested) {
      this.requested = true;
      for (let studentId of studentIds) {
        db.collection('users')
          .doc(studentId)
          .collection('meta')
          .doc('triggers')
          .collection('student-metrics')
          .doc(projectId)
          .set({requestMetrics: true, timestamp: now()})
          .catch(console.error);
      }
    }
  }

  subMetrics(props) {
    if (this.unsub.metrics) this.unsub.metrics();
    const { db, projectId } = props;
    const { studentIds } = this.state;
    for (let studentId of studentIds) {
      this.unsub['assignmentMetrics' + studentId] = db
        .collection('users')
        .doc(studentId)
        .collection('student-metrics')
        .doc(projectId)
        .collection('assignments')
        .onSnapshot(snap => {
          const {assignmentScores} = this.state;
          if (!assignmentScores[studentId]) assignmentScores[studentId] = {};
          snap.docs.forEach(doc => {
            const data = doc.data();
            const { assignmentId, pointsScored, pointsAvailable } = data;
            assignmentScores[studentId][assignmentId] = [pointsScored, pointsAvailable];
          });
          this.setState({ assignmentScores });
        });
    }
  }

  subAssignments() {
    const { db, projectId } = this.props;
    this.unsub.assignments = db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .onSnapshot( snap => {
        const assignments = {};
        snap.docs.forEach(doc => {
          const { id, title, timestamp, deadline } = doc.data();
          assignments[id] = {title, timestamp, deadline};
        })
        this.setState({ assignments });
      })
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (typeof this.unsub[key] === 'function') this.unsub[key]();
    }
  }

  requiresResponse(hocket) {
    return hocket?.suggestions?.length || isRegistered(hocket);
  }

  lectureLookup(date) { 
    return this.state.lectureTable?.[date] || date;
  }

  rowData(studentId) {
    const {
      assignmentScores, 
      sharedLessonMap, 
      sharedLessons, 
      sharedHockets,
      liveClassDays,
      assignments,
      timeZoneOffset,
      studentMap,
      studentEmails,
    } = this.state;
    const getDeadline = id => {
      try {
        return moment(assignments[id].deadline.toDate()).add(timeZoneOffset, 'hours').toISOString().slice(0, 10);
      } catch {
        return '';
      }
    }
    const assignmentRowData = assignmentScores?.[studentId] ? 
      Object.keys(assignmentScores[studentId]).map(id => ({
        type: 'Assignment', 
        name: assignments[id]?.title || '',
        student: studentMap?.[studentId]?.displayName,
        studentId: studentMap?.[studentId]?.studentId,
        studentEmail: studentEmails?.[studentId],
        date: getDeadline(id) || '',
        pointsScored: assignmentScores[studentId][id]?.[0],
        pointsAvailable: assignmentScores[studentId][id]?.[1],
        proportion: Math.round(100 * assignmentScores[studentId][id]?.[0] / assignmentScores[studentId][id]?.[1]),
      }))
      .filter(row => row.pointsAvailable)
      .sort((row1, row2) => row1.date < row2.date ? -1 : 1) : [];
    const sharedLessonRowData = sharedLessonMap?.[studentId] ? Object.keys(sharedLessonMap[studentId]).map(id => {
      const pointsScored = sharedLessonMap[studentId][id]?.messages?.filter(message => { return this.requiresResponse(sharedHockets?.[message.parent])}).length || 0;
      const pointsAvailable = sharedLessons[id]?.hockets?.map(id => sharedHockets[id]).filter(this.requiresResponse).length || 0;
      const proportion = Math.round(100 * pointsScored / pointsAvailable);
      return {
        type: 'Shared Lesson',
        name: sharedLessons[id]?.title,
        student: studentMap?.[studentId]?.displayName,
        studentId: studentMap?.[studentId]?.studentId,
        studentEmail: studentEmails?.[studentId],
        date: sharedLessons[id]?.lectureDate,
        pointsScored,
        pointsAvailable,
        proportion,
      }
    })
    .filter(row => row.pointsAvailable)
    .sort((row1, row2) => row1.date < row2.date ? -1 : 1) : [];
    const liveClassRowData = liveClassDays?.[studentId] ? liveClassDays[studentId].map(record => ({
      type: "Live Class",
      name: this.lectureLookup(record.date),
      student: studentMap?.[studentId]?.displayName,
      studentId: studentMap?.[studentId]?.studentId,
      studentEmail: studentEmails?.[studentId],
      date: record.date,
      pointsScored: record.openResponseAnswered + record.multipleChoiceAnswered,
      pointsAvailable: record.openResponseAsked + record.multipleChoiceAsked,
    }))
    .map(row => ({...row,
      pointsScored: Math.min(row.pointsAvailable, 
        row.pointsScored + 3,
      ),
    }))
    .map(row => ({...row, 
      proportion: Math.round(100 * row.pointsScored/row.pointsAvailable)
    }))
    .filter(row => row.pointsAvailable)
    .sort((row1, row2) => row1.date < row2.date ? -1 : 1) : [];
    return [
      ...assignmentRowData, 
      ...sharedLessonRowData,
      ...liveClassRowData,
    ];
  }

  render() {
    updateTitleBar('Metrics');
    const { db, router, projectId, currentUser={} } = this.props;
    const { 
      studentIds,
    } = this.state;
    const columnDefs = [
      {
        headerName: 'Student Name',
        field: 'student',
      },
      {
        headerName: 'Student Email',
        field: 'studentEmail',
      },
      {
        headerName: 'Type',
        field: 'type',
      }, {
        headerName: 'Assignment Name',
        field: 'name',
      }, {
        headerName: 'Date',
        field: 'date',
      }, {
        headerName: 'Points scored',
        field: 'pointsScored',
      },{
        headerName: 'Points available',
        field: 'pointsAvailable',
      }, {
        headerName: 'Proportion',
        field: 'proportion',
      }];
    const rowData = studentIds.map(id => this.rowData( id )).flat();
    return (
      <div className="student-metrics-view full-height">
        <SimpleAdminNav
          currentUser={ currentUser }
          projectId={ projectId }
          db={ db }
          router={ router }
          studentView
        />
        <div style={{position: "relative", height: "calc(100% - 150)"}}>
          <h2 className="title centered">Class Metrics</h2>
          <DataTable
            exportColumnKeys={ columnDefs.map(col => col.field) }
            columnDefs={ columnDefs }
            rowData={ rowData }
            groupingVariable={ 'type' }
            sizeToFit/>
        </div>
      </div>
    );
  }

}

export default AllMetricsView;
