import { touchUpMarkdown, extractCodeBlock, markdownToDelta,
         downloadTextFile, codeBlockDelta } from './utils';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import Hocket from './hocket';

// General


// name is how a kernel is identified in this code base
// kernelName is what you give to Binder to request that specific kernel
// kernelLanguage and kernelDisplayName are needed for export, to write metadata into the notebook
// mode is for CodeMirror
// hashlang is so we can write #lang julia at the top of a code cell and override the default
// repoName and icon are self-explanatory

export const allBinderKernels = {
  julia: {
    name: 'Julia',
    repoName: 'sswatson/julia-binder',
    branch: 'main',
    kernelName: 'julia-1.6',
    kernelLanguage: 'language',
    kernelDisplayName: 'Julia 1.6',
    mode: 'julia',
    hashLang: ['julia', 'Julia'],
    codePrefix: null,
    icon: '/julia-dots.svg',
  },
  mlj: {
    name: 'MLJ',
    repoName: 'prismia-chat/mlj-binder',
    branch: 'main',
    kernelName: 'julia-1.6',
    kernelLanguage: 'language',
    kernelDisplayName: 'Julia 1.6',
    mode: 'julia',
    hashLang: ['mlj', 'MLJ'],
    codePrefix: null,
    icon: '/julia-dots.svg',
    hidden: true,
  },
  flux: {
    name: 'Julia with Flux',
    repoName: 'prismia-chat/flux-binder',
    branch: 'main',
    kernelName: 'julia-1.6',
    kernelLanguage: 'language',
    kernelDisplayName: 'Julia 1.6',
    mode: 'julia',
    hashLang: ['flux', 'Flux'],
    codePrefix: null,
    icon: '/julia-dots.svg',
    hidden: true,
  },
  python: {
    name: 'Python',
    repoName: 'sswatson/python-binder',
    branch: 'main',
    kernelName: 'python3',
    kernelLanguage: 'python',
    kernelDisplayName: 'Python 3',
    mode: 'python',
    hashLang: ['python', 'Python', 'python3'],
    codePrefix: null,
    icon: '/python-logo.svg',
  },
  pymongo: {
    name: 'PyMongo',
    repoName: 'prismia-chat/pymongo-binder',
    branch: 'master',
    kernelName: 'python3',
    kernelLanguage: 'python',
    kernelDisplayName: 'Python 3',
    mode: 'python',
    hashLang: ['pymongo', 'PyMongo'],
    codePrefix: null,
    icon: '/python-logo.svg',
    hidden: true,
  },
  py2neo: {
    name: 'Python with neo4j',
    repoName: 'prismia-chat/py2neo-binder',
    branch: 'main',
    kernelName: 'python3',
    kernelLanguage: 'python',
    kernelDisplayName: 'Python 3',
    mode: 'python',
    hashLang: ['neo4j', 'py2neo'],
    codePrefix: null,
    icon: '/python-logo.svg',
    hidden: true,
  },
  bigdata: {
    name: 'Python with Kafka',
    repoName: 'prismia-chat/big-data-binder',
    branch: 'main',
    kernelName: 'python3',
    kernelLanguage: 'python',
    kernelDisplayName: 'Python 3',
    mode: 'python',
    hashLang: ['bigdata', 'kafka', 'spark', 'pyspark'],
    codePrefix: null,
    icon: '/python-logo.svg',
    hidden: true,
  },
  r: {
    name: 'R',
    repoName: 'prismia-chat/r-binder',
    branch: 'main',
    kernelName: 'ir',
    kernelLanguage: 'R',
    kernelDisplayName: 'R',
    mode: 'r',
    hashLang: ['R', 'r'],
    codePrefix: null,
    icon: '/r-logo.svg',
  },
  sagemath: {
    name: 'SageMath',
    repoName: 'prismia-chat/sage-binder',
    branch: 'main',
    kernelName: 'sagemath',
    kernelLanguage: '',
    kernelDisplayName: 'SageMath',
    mode: 'python',
    hashLang: ['sage', 'Sage', 'sagemath', 'SageMath'],
    codePrefix: '%display latex\n',
    icon: '/sage-icon.svg',
  },
  coconut: {
    name: 'Coconut',
    repoName: 'prismia-chat/python-binder',
    branch: 'main',
    kernelName: 'coconut',
    kernelLanguage: 'coconut',
    kernelDisplayName: 'Coconut',
    mode: 'python',
    hashLang: ['coconut', 'Coconut'],
    codePrefix: null,
    icon: '/python-logo.svg',
    hidden: true,
  },
  cpp: {
    name: 'C++',
    repoName: 'prismia-chat/cpp-binder',
    branch: 'main',
    kernelName: 'xcpp17',
    kernelLanguage: '',
    kernelDisplayName: 'C++17',
    mode: 'text/x-c++src',
    hashLang: ['C++', 'c++', 'C', 'cpp', 'Cpp'],
    codePrefix: null,
    icon: '/cpp-icon.svg',
  },
  bash: {
    name: 'Bash',
    repoName: 'prismia-chat/bash-binder',
    branch: 'main',
    kernelName: 'bash',
    kernelLanguage: 'shell',
    kernelDisplayName: 'Bash',
    mode: 'shell',
    hashLang: ['bash', 'Bash', 'shell'],
    icon: null,
    codePrefix: null,
    hidden: true,
  },
}

export const binderKernels = Object.fromEntries(Object.keys(allBinderKernels).filter(id => !allBinderKernels[id].hidden).map(id => [id, allBinderKernels[id]]));

const md = (hocket, language) => {
  if (!hocket || !hocket.responses) return {};
  const message = hocket.responses[0];
  if (!message) return {};
  const quillDelta = JSON.parse(message);
  return {
    markdown: touchUpMarkdown(deltaToMarkdown(quillDelta.ops), language),
    delta: quillDelta,
  }
}

const toText = (text) => {
  if (typeof text === 'string') return text;
  if (text.join) return text.join('');
  console.log("returning empty string for ", text)
  return '';
}

// Importing

export function cell2hocket(jupyterCell) {
  const hocket = new Hocket();
  let ops;
  if (jupyterCell.cell_type === 'code') {
    ops = codeBlockDelta(toText(jupyterCell.source)).ops;
    hocket.codeCell = true;
  } else {
    ops = markdownToDelta(toText(jupyterCell.source));
  }
  hocket.responses = [JSON.stringify({ ops })];
  return hocket;
}

export function cells2hockets(jupyterCells, prismiaHockets, language='code') {
  const hocketMap = new Map();
  for (let hocket of prismiaHockets) {
    hocketMap.set(hocket.id, hocket);
  }
  const matches = (cell) => {
    return cell.metadata && cell.metadata.prismiaId &&
         hocketMap.has(cell.metadata.prismiaId) &&
         md(hocketMap.get(cell.metadata.prismiaId), language).markdown === toText(cell.source)
  }
  const isUnchangedChildCell = (cell) => {
    if (!cell.metadata) return false;
    const prismiaId = cell.metadata.prismiaParentId;
    if (!prismiaId) return false;
    const parentHocket = hocketMap.get(prismiaId);
    if (!parentHocket) return false;
    return extractCodeBlock(parentHocket) === toText(cell.source);
  }
  const hockets = [];
  const newHockets = new Set();
  for (let cell of jupyterCells) {
    if (cell.metadata && cell.metadata.prismiaHeader) {
      continue; // we ignore problem headers as they're computed dynamically
    } else if (matches(cell)) {
      hockets.push(hocketMap.get(cell.metadata.prismiaId));
    } else if (!(isUnchangedChildCell(cell))) {
      const hocket = cell2hocket(cell);
      hockets.push(hocket);
      newHockets.add(hocket.id);
    }
  }
  return { hockets, newHockets };
}

// Exporting

export function exportJupyterNotebook(hockets, language='code',
                                      fileBaseName='prismia', freezeProblemCells=false) {
  const jupyterCells = [];
  let problemNumber = 1;
  for (let hocket of hockets) {
    if (!hocket) {
      window.alert('Data is still loading; try again in a few seconds');
      return;
    }
    if (!hocket.responses && !hocket.problemMarker) {
      continue;
    }
    if (hocket.problemMarker) {
      jupyterCells.push({
        cell_type: "markdown",
        metadata: { prismiaHeader: true },
        source: [`### Problem ${problemNumber}`],
      });
      problemNumber += 1;
      continue;
    }
    const { delta, markdown } = md(hocket, language);
    const cell = {
      cell_type: "markdown",
      metadata: { prismiaId: hocket.id },
      source: [markdown],
    }
    if (freezeProblemCells && hocket.problemStatementCell) {
      cell.metadata.trusted = true;
      cell.metadata.editable = false;
      cell.metadata.deletable = false;
    }
    jupyterCells.push(cell);
    let code = '';
    if (delta) {
      code = extractCodeBlock(delta);
    }
    if (code.length > 0) {
      const codecell = {
        cell_type: "code",
        metadata: { prismiaParentId: hocket.id },
        source: [code],
        execution_count: null,
        outputs: [],
      };
      if (freezeProblemCells && hocket.problemStatementCell) {
        codecell.metadata.trusted = true;
        codecell.metadata.editable = false;
        codecell.metadata.deletable = false;
      }
      jupyterCells.push(codecell);
    }
  }
  const kernelspec = {
    display_name: binderKernels[language]?.kernelDisplayName,
    language: binderKernels[language]?.kernelLanguage,
    name: binderKernels[language]?.kernelName,
  };
  let metadata = kernelspec ? { kernelspec } : {};
  const ipynb = JSON.stringify({
    metadata,
    nbformat: 4,
    nbformat_minor: 0,
    cells: jupyterCells,
  });
  downloadTextFile(`${fileBaseName}.ipynb`, ipynb);
}
