import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import { JupyterCell } from '../JupyterCell';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CachedIcon from '@material-ui/icons/Cached';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CodeIcon from '@material-ui/icons/Code';
import CheckIcon from '@material-ui/icons/Check';
import ReorderIcon from '@material-ui/icons/Reorder';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { settingsClockTheme } from '../mui-themes';
import CardContents from '../CardContents';
import moment from 'moment-timezone';

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import Tooltip from '@material-ui/core/Tooltip';
import SuggestionSuperList from '../SuggestionSuperList';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import toPlaintext from 'quill-delta-to-plaintext';
import { updateTitleBar, splitQuillDeltaOnHorizontalRule, 
         isRegistered, tryUntilSuccess, isNote, pickRandom,
         touchUpMarkdown, peelOffSuggestions, arrayUnion, arraySafeNow,
         markdownToDelta, mousetrapStopCallback, 
         now, pingBinderKernel } from '../utils';
import { exportJupyterNotebook, cells2hockets } from '../jupyter';
import Hocket from '../hocket';
import MessageForm from '../MessageForm';
import { isValidDelta } from '../utils';
import 'react-quill/dist/quill.snow.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// import 'react-notifications/lib/notifications.css';
import './style.css';
import * as Mousetrap from 'mousetrap';
import { mobileThreshold } from '../constants';

const FlexButton = withStyles({
  root: {
    flex: 'none',
    color: 'black',
  },
})(IconButton);

const SettingsButton = withStyles({
  root: {
    color: "white",
    border: "1px solid white",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    display: "block",
  }
})(Button);

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

const settingsThemeColor = '#FFF';
const theme = createMuiTheme({
  palette: {
    common: { black: settingsThemeColor, white: settingsThemeColor },
    primary: { main: settingsThemeColor, dark: '#000', light: settingsThemeColor },
    text: { primary: settingsThemeColor, secondary: '#000' }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        }
      }
    },
    MuiIconButton: {
      root: { color: 'white' },
    },
    MuiPickersBasePickers: {
      container:
        { color: '#CCC' },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: '#237882',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#333'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#333',
      }
    },
    MuiPaper: {
      root: { color: '#333',
              backgroundColor: 'white' }
    },
    MuiButton: {
      textPrimary: { color: '#333'}
    },
  }
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#efd' : '#fff',
});

const getItemStyle = (isDragging, draggableStyle, clusterStyle={}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#ffe' : clusterStyle.background,
    // styles we need to apply on draggables
    ...draggableStyle
});

class AssignmentView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      assignment: null,
      project: null,
      settings: null,
    };
    this.assignmentRef = {};
    this.mainQuillRef = React.createRef();
    this.juniperRef = React.createRef();
    this.jupyterInputRef = React.createRef();
    this.deleteMessages = this.deleteMessages.bind(this);
    this.hocketsAreaRef = null;
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.state = {
      hockets: {},
      solutionHockets: new Set(),
      answerHockets: {},
      answers: {},
      assignment: null,
      instructors: {},
      tas: {},
      exportFormat: 'HTML',
      activeHocketId: null,
      hocketSelectionRange: null,
      showHelp: false,
      locked: false,
      reOrder: false,
      juniperOpen: false,
      juniperHasRendered: false,
      linkClicked: false,
      published: false,
      lastBinderPing: new Date(),
      currentProblem: 0,
      regradeRequestIdMap: {},
      deadline: null,
      pastDeadline: true,
      submitted: true,
      codeCell: null,
      overrideSubmitted: false,
      requestingOverride: false,
      overrideDeadline: null,
      latestExtensionDate: null,
      graceMinutes: 2*60,
      openProblems: new Set(),
      openSolutions: new Set(),
    };
  }

  setRefs() {
    const { db, currentUser, projectId } = this.props;
    this.projectRef = db.collection('users')
    .doc(currentUser.id)
    .collection('assignment-projects')
    .doc(projectId);
  }

  componentDidMount() {
    this.setRefs();
    this.subStartTime();
    this.subAssignment(this.props);
    this.subSubmitted();
    this.subOverride();
    this.setMousetrap();
    this.setActiveHocketId(null);
    this.handleProblemUrl();
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    this.timerID = setInterval(() => this.checkDeadline(), 60000);
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
    const messages = [
      "Submit your solutions by clicking the checkmark button before it disappears at the deadline. You can submit as many times as you want!",
      "Click the gear icon to file for an extension (if permitted)",
      "Tip: write long solutions as several small messages rather than one very large one!",
      "Tip: use a monospaced font for code with the \"code block\" or \"inline code\" tools in the toolbar",
    ];
    NotificationManager.info(pickRandom(messages));
  }

  checkExam(verbose=false) {
    const { exam, examStartDate, availabilityOverride } = this.state;
    if (!exam || availabilityOverride) return;
    const now = new Date();
    if (this.state.released) {
      this.setState({
        notCurrentlyAvailable: false,
        locked: true
      });
      return;
    };
    if (now < examStartDate || this.minutesLeft() <= 0) {
      NotificationManager.warning("This exam is not currently available")
      this.setState({notCurrentlyAvailable: true});
    } else if (this.state.startTime === 'unstarted') {
      this.setState({notCurrentlyAvailable: false});
      this.setStartTime();
      NotificationManager.info(`Starting exam! Clear the gear icon to see how much time you have left.`);
    } else if (this.state.startTime) {
      this.setState({notCurrentlyAvailable: false});
      if (verbose) NotificationManager.info(`You started the exam at ${this.state.startTime}. ${this.minutesLeftMessage()}`);
    } else {
      setTimeout(() => this.checkExam(verbose), 1000);
    }
  }

  checkDeadline() {
    const { exam, deadline, overrideDeadline } = this.state;
    if (exam) {
      this.checkExam();
      return;
    };
    if (!deadline) return;
    const now = new Date();
    this.setState({ pastDeadline: 
      (!overrideDeadline && now - deadline.toDate() > this.state.graceMinutes*60*1000) || 
      (overrideDeadline && overrideDeadline.toDate() < now)
    }, () => {
      if (this.state.pastDeadline) {
        this.setState({ locked: true });
      }
    });
  }

  setLang(lang) {
    this.setState({ codeCell: lang });
  }

  setSubmittedStatus(status) { // status is boolean
    const { assignmentId } = this.props;
    this.projectRef
      .set({ [assignmentId]: status }, {merge: true})
      .catch(console.error);
  }

  hocketIndex(hocketId) {
    const { assignment } = this.state;
    const { hocketIds=[] } = assignment;
    return hocketIds.findIndex(hocket => hocket.hocketId === hocketId);
  }

  handleProblemUrl() {
    const { location } = this.props;
    let pathComponents = location.pathname.split('/problems/')
    if (pathComponents && pathComponents.length > 1) {
      tryUntilSuccess(() => {
        const problemId = pathComponents[1];
        try {
          this.setState({ 
            currentProblem: this.registeredProblems().indexOf(problemId),
          });
          return true;
        } catch {
          return false;
        }
      }, {wait: 250});
    }
  }

  adjustActiveHocket(increment) {
    const { activeHocketId, assignment } = this.state;
    const { hocketIds=[] } = assignment;
    const currentIndex = this.hocketIndex(activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < hocketIds.length)) {
      this.setActiveHocketId(hocketIds[newIndex].hocketId);
    }
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }


  adjustSelectionEnd(increment) {
    const { activeHocketId,
            assignment,
            hocketSelectionRange
    } = this.state;
    const { hocketIds=[] } = assignment;
    const currentIndex = hocketIds.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < hocketIds.length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  splitActiveCell() {
    const { db } = this.props;
    const { answers={}, activeHocketId } = this.state;
    const { hockets=[] } = answers;
    const idx = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    if (idx === -1) return;
    const delta = JSON.parse(this.state.hockets[hockets[idx].hocketId].responses[0]);
    const newDeltas = splitQuillDeltaOnHorizontalRule(delta);
    const answer = window.confirm(`Split current cell into ${newDeltas.length} new cells?`);
    if (!answer) return;
    const newHockets = newDeltas.map((delta) => {
      const hocket = Hocket();
      hocket.responses.push(JSON.stringify(delta));
      return peelOffSuggestions(hocket);
    });
    const batch = db.batch();
    newHockets.forEach( (hocket) => {
      batch.set(
        this.answerRef.doc(this.currentProblemId()).collection('hockets').doc(hocket.id),
        hocket, {merge: true}
      );
    });
    batch
      .commit()
      .then( () => {
        const newHocketStubs = newHockets.map(
          (hocket) => { return { hocketId: hocket.id }; }
        );
        hockets.splice(idx, 1, ...newHocketStubs);
        this.answerRef.doc(this.currentProblemId()).set({ hockets }, {merge: true});
        return newHocketStubs;
      })
      .then( (hockets) => {
        const [lastHocket] = hockets.slice(-1);
        this.setState({activeHocketId: lastHocket.hocketId});
      })
      .catch(console.error);
  }

  nextProblem() {
    const { currentProblem } = this.state;
    this.setState({ currentProblem: Math.min(currentProblem + 1, this.registeredProblems().length - 1) });
  }

  prevProblem() {
    const { currentProblem } = this.state;
    this.setState({ currentProblem: Math.max(currentProblem - 1, 0)});
  }

  setMousetrap() {
    Mousetrap.bind("mod+j", () => this.exportJupyterNotebook());
    Mousetrap.bind("down", () => this.adjustActiveHocket(1));
    Mousetrap.bind("up", () => this.adjustActiveHocket(-1));
    Mousetrap.bind("left", () => this.prevProblem());
    Mousetrap.bind("right", () => this.nextProblem());
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+s", () => this.splitActiveCell());
    Mousetrap.bind("shift+r", () => this.setState({ reOrder: !this.state.reOrder }));
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind("shift+=", () => this.createHocket());
    Mousetrap.bind("ctrl+j", () => this.toggleJuniper());
    Mousetrap.bind(["del", "backspace"], () => {
      this.deleteMessages();
    });
    Mousetrap.bind("esc", () => {
      if (this.state.locked) this.setState({ activeHocketId: null });
      this.setState({
        hocketSelectionRange: null,
        showHelp: false,
      });
    });
    Mousetrap.bind('enter', () => {
      setTimeout( () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.focus();
          const editor = this.mainQuillRef.current.editor;
          editor.setSelection(editor.getLength(), 0);
        }
      }, 40);
    });
    Mousetrap.bind("shift+m", () => this.convertEditorContents());
    Mousetrap.bind(["mod+/", "shift+/"], () => this.toggleHelp());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  exitHelpOrSettings() {
    this.setState({ 
      showHelp: false, 
      showSettings: false,
      showSubmission: false,
    });
  }

  toggleHelp() {
    const { showHelp } = this.state;
    this.setState({ showHelp: !showHelp});
  }

  setCodeCell(language) {
    this.assignmentRef.set({ codeCell: language }, {merge: true});
  }

  setDisplayMode(setting) {
    this.assignmentRef.set({ published: setting }, {merge: true});
  }

  requestDeadlineOverride() {
    const { projectId, currentUser, assignmentId, functions } = this.props;
    const { overrideCode } = this.state;
    const requestDeadlineOverride = (
      functions.httpsCallable('requestDeadlineOverride')
    );
    requestDeadlineOverride(
      { projectId, assignmentId, userId: currentUser.id, overrideCode }
    ).then( result => {
      if (!result.data.success) {
        NotificationManager.warning("This code is either invalid or has already been used by another student");
      } else {
        NotificationManager.success("Your deadline has been updated using the override code");
        this.setState({ showOverrideInput: false });
      }
      this.setState({ requestingOverride: false });
    } );
  }

  minutesLeftMessage() {
    const min = Math.round(this.minutesLeft());
    if (isNaN(min)) return "";
    return " You have " + min + ` minute${min === 1 ? "" : "s"} remaining.`;
  }

  minutesLeft() {
    const currentTime = moment();
    return Math.min(
      moment.duration(
        moment(this.state.startTime)
          .add(this.state.examMinutes, 'minutes')
          .diff(currentTime)
      ).asMinutes(),
      moment.duration(
        moment(this.state.examEndDate).diff(currentTime)
      ).asMinutes()
    );
  }

  renderSettings() {
    const { overrideDeadline, deadline, latestExtensionDate } = this.state;
    let deadlineMessage = null;
    if (deadline && latestExtensionDate) {
      deadlineMessage = <ul><li>Your current deadline is <p style={{color: "white"}}><strong>{ String((overrideDeadline ? overrideDeadline : deadline).toDate()) }.</strong></p> To request a deadline extension, <span className="underlined" style={{cursor: "pointer"}} onClick={ () => this.setState({ showOverrideInput: true })}>click here</span>.</li></ul>
    } else if (this.state.exam) {
      deadlineMessage = <ul><li>{`This is an exam. You started at ${this.state.startTime} and have ${this.state.examMinutes} minutes in total or until ${this.state.examEndDate}. Therefore, you have about`} <font size="+2"><strong>{Math.round(this.minutesLeft())}</strong></font> minutes remaining.</li></ul>
    } else if (deadline) {
      deadlineMessage = <ul><li>The deadline for this assignment is <p style={{color: "white"}}><strong>{ String(deadline.toDate()) }.</strong></p> </li></ul>
    }
    return (<ThemeProvider theme={ theme }>
        <div className="help-info">
          <h1>Settings and Tools</h1>
          <ul>
            <li> View your submitted solutions </li>
          </ul>
          <SettingsButton
            onClick={() => this.getSubmission()}>
              View Submission
          </SettingsButton>
          <ul>
          <li> Export the problems and your current solutions to a Jupyter notebook. </li>
          </ul>
          <SettingsButton
            onClick={ () => this.exportJupyterNotebook() }>
            Jupyter Export
          </SettingsButton>
          <ul>
          <li> If you make edits to an exported Jupyter notebook, you can import them back to Prismia. Be sure to write your solutions in cells that you add <em>after</em> each tagged problem cell (the tagged problem cells are the ones containing the registered symbol (®). )</li>
          </ul>
          <SettingsButton
            onClick={ () => this.jupyterInputRef.current.click() }>
            Jupyter Import
          </SettingsButton>
          <input
            type="file"
            ref={ this.jupyterInputRef }
            onChange={ (e) => this.handleJupyterImport(e.target.files[0]) }
            onClick={ e => {e.target.value = null} }
            multiple={ false }
            style={{opacity: 0, tabIndex: -1, position: "absolute"}}/>
          <ul>
            <li> Lock the messages so they don't open or highlight when clicked: </li>
              <FormGroup>
              <FormControlLabel
                control={ <WhiteCheckbox checked={ !!(this.state.locked) } color="primary" inputProps={{ 'aria-label': 'Lock messages' }} onChange={ e => this.setState({ locked: e.target.checked })}/> }
                label={ "Lock messages" }/>
              </FormGroup>
            </ul>
            { deadlineMessage }
            { this.state.showOverrideInput ?
              <div className="centered">
                <ThemeProvider theme={ settingsClockTheme } >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className="margin-below" style={{marginLeft: "20px"}}>
                      <KeyboardDateTimePicker
                        id="homework-deadline"
                        ampm={ false }
                        disablePast={ true }
                        maxDate={ latestExtensionDate.toDate() }
                        value={ new Date() }
                        onChange={ (date) => this.handleDeadlineChange(date) }
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </div> : null
            }
          </div>
      </ThemeProvider>
    );
  }

  handleDeadlineChange(date) {
    const { db, projectId, assignmentId, currentUser } = this.props;
    const batch = db.batch();
    batch.set(this.projectRef
      .collection('assignments')
      .doc(assignmentId),
      { overrideDeadline: date.toDate() }, { merge: true });
    batch.set(db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('students')
      .doc(currentUser.id),
      { deadline: date.toDate() }, { merge: true });
    batch.commit().catch(console.error);
    this.setState({ showOverrideInput: false });
  }

  helpInfo() {
    return (<div className="help-info">
      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move selected message up one</li>
      <li><tt>down</tt> move selected message down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia assignments or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      <li><tt>cmd/ctrl+j</tt> export this assignment as a Jupyter notebook</li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>
    </div>);
  }

  mousetrapUnset() {
    Mousetrap.unbind("mod+j");
    Mousetrap.unbind("down");
    Mousetrap.unbind("up");
    Mousetrap.unbind("left");
    Mousetrap.unbind("right");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("shift+s");
    Mousetrap.unbind("shift+r");
    Mousetrap.unbind("shift+=");
    Mousetrap.unbind("del");
    Mousetrap.unbind("backspace");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("shift+/");
    Mousetrap.unbind("mod+/");
    Mousetrap.unbind("ctrl+j");
  }

  exportJupyterNotebook() {
    const { assignment, hockets, codeCell, answers, answerHockets } = this.state;
    const { hocketIds } = assignment;
    const exportHockets = [{problemMarker: true}];
    for (let hocketStub of hocketIds) {
      const hocket = {...hockets[hocketStub.hocketId]};
      hocket.problemStatementCell = true;
      exportHockets.push(hocket);
      if (answers[hocketStub.hocketId]) {
        for (let stub of answers[hocketStub.hocketId].hockets) {
          exportHockets.push(answerHockets[stub.hocketId]);
        }
      }
      if (isRegistered(hockets[hocketStub.hocketId])) exportHockets.push({problemMarker: true});
    }
    exportJupyterNotebook(
      exportHockets,
      codeCell,
      assignment.title && assignment.title.length > 0 ? assignment.title : "prismia",
      true,
    );
  }

  convertEditorContents() {
    if (!this.mainQuillRef) return;
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);
    const editor = this.mainQuillRef.current.editor;
    const text = toPlaintext(editor.getContents());
    const ops = text.split("\n---\n").map(cellText => markdownToDelta(cellText).concat([{insert: {hr: true}}]));
    editor.setContents({ops: flatten(ops).slice(0, -1)});
    setTimeout( () => this.mainQuillRef.current.focus(), 50 );
  }

  subAssignment(props) {
    if (this.unsub.assignment) this.unsub.assignment();
    const { db, currentUser, projectId, assignmentId } = props;
    if (!currentUser) return console.log(
      'no current user',
      currentUser,
      props.currentUser
    );
    this.assignmentRef = db
      .collection('projects')
      .doc(projectId)
      .collection('published-assignments')
      .doc(assignmentId);
    this.unsub.assignment = this.assignmentRef
      .onSnapshot(snap => {
        const assignment = snap.data();
        if (!assignment) return;
        let { hocketIds=[] } = assignment;
        for (let i = 0; i < hocketIds.length; i++) {
          if (this.unsub[hocketIds[i].hocketId]) continue;
          this.subHocket(hocketIds[i].hocketId);
          this.subAnswer(hocketIds[i].hocketId);
        }
        this.setState({ assignment });
        this.setState({ codeCell: assignment.codeCell || null });
        this.setState({ published: assignment.published || null});
        this.setState({ released: assignment.released || null });
        this.setState({
          deadline: assignment.deadline || null,
          latestExtensionDate: assignment.latestExtensionDate || null,
        },
          () => this.checkDeadline()
        );
        this.setState({
          exam: assignment.exam,
          examStartDate: assignment.examStartDate?.toDate(),
          examEndDate: assignment.examEndDate?.toDate(),
          examMinutes: assignment.examMinutes,
        }, () => this.checkExam(true) );
        if (assignment.deleting) {
          NotificationManager.warning("This assignment has just been unpublished by an instructor. Your work will be available again when it is re-published.");
        }
      });
  }

  subSubmitted() {
    const { assignmentId } = this.props;
    this.unsub.submitted = (
      this.projectRef
        .onSnapshot(snap => {
          const submittedAssignments = snap.data();
          this.setState({ submitted: submittedAssignments ? !!submittedAssignments[assignmentId] : false });
        })
    );
  }

  subOverride() {
    const { assignmentId } = this.props;
    this.unsub.override = (
      this.projectRef
        .collection('assignments')
        .doc(assignmentId)
        .onSnapshot(snap => {
          const data = snap.data();
          if (!data) return;
          this.setState({
            overrideSubmitted: data.overrideSubmitted || false,
            requestingOverride: false,
            overrideDeadline: data.overrideDeadline || null,
          }, () => {
            const now = new Date();
            if (data.overrideDeadline && (now < data.overrideDeadline.toDate())) {
              this.setState({ pastDeadline: false })
            } else if (data.overrideDeadline && (now > data.overrideDeadline.toDate())) {
              this.setState({ pastDeadline: true })
            }
          });
        })
    );
  }

  subAnswer(hocketId) {
    console.log(hocketId);
    if (this.unsub && this.unsub[hocketId + '-answer']) {
      this.unsub[hocketId + '-answer']();
    }
    const { db, currentUser, projectId, assignmentId } = this.props;
    if (!currentUser) return console.log(
      'no current user', currentUser,
    );
    this.answerRef = db
      .collection('users')
      .doc(currentUser.id)
      .collection('assignment-projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('problems');
    this.unsub[hocketId + '-answer'] = this.answerRef
      .doc(hocketId)
      .onSnapshot(snap => {
        const { answers, regradeRequestIdMap } = this.state;
        const answer = snap.data();
        if (!answer) return;
        const { hockets=[] } = answer;
        const allHockets = hockets.slice();
        if (answer.regradeRequestThread) {
          allHockets.push(...answer.regradeRequestThread.map(id => {
            return { hocketId: id };
          }));
        }
        for (let i = 0; i < allHockets.length; i++) {
          if (this.unsub[allHockets[i].hocketId + '-answer']) continue;
          this.subAnswerHocket(hocketId, allHockets[i].hocketId);
        }
        answers[hocketId] = answer;
        regradeRequestIdMap[hocketId] = answer.regradeRequestThread || null;
        this.setState({ answers, regradeRequestIdMap });
      });
  }

  subHocket(hocketId) {
    const { db, projectId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets, solutionHockets } = this.state;
        hockets[hocket.id] = hocket;
        if (isNote(hocket)) solutionHockets.add(hocketId);
        this.setState({ hockets, solutionHockets });
      });
  }

  subStartTime() {
    const { db, currentUser, projectId, assignmentId } = this.props;
    if (this.unsub.startTime) this.unsub.startTime();
    this.unsub.startTime = db.collection('users')
      .doc(currentUser.id)
      .collection('assignment-projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .onSnapshot(snap => {
        const data = snap.data();
        if (data?.startTime) {
          this.setState({ startTime: data.startTime.toDate() });
        } else {
          this.setState({ startTime: 'unstarted' });
        }
      });
  }

  setStartTime() {
    const { db, currentUser, projectId, assignmentId } = this.props;
    db.collection('users')
      .doc(currentUser.id)
      .collection('assignment-projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ startTime: now() }, {merge: true})
      .catch(console.error);
  }

  isRegistered(id) {
    const { hockets } = this.state;
    if (!hockets || !hockets[id] || !hockets[id].responses ||
        !hockets[id].responses[0]) return false;
    return isRegistered(hockets[id]);
  }

  registeredProblems() {
    const { assignment } = this.state;
    return assignment
      .hocketIds
      .map(hocketStub => hocketStub.hocketId)
      .filter((id) => this.isRegistered(id))
  }

  currentProblemId() {
    const { currentProblem } = this.state;
    return (
      this.registeredProblems()[currentProblem]
    );
  }

  subAnswerHocket(problemHocketId, hocketId) {
    const { db, projectId, currentUser, assignmentId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('users')
      .doc(currentUser.id)
      .collection('assignment-projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('problems')
      .doc(problemHocketId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { answerHockets } = this.state;
        answerHockets[hocket.id] = hocket;
        this.setState({ answerHockets });
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    window.removeEventListener('copy', this.handleCopy);
    window.removeEventListener('paste', this.handlePaste);
    clearInterval(this.timerID);
    this.mousetrapUnset();
  }

  setActiveHocketId(activeHocketId) {
    this.setState({ activeHocketId,
                    hocketSelectionRange: null }, () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.blur();
        }
    });
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, answers } = this.state;
    const answer = answers[this.currentProblemId()];
    if (!answer) return;
    const { hockets } = answer;
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  exportAssignment() {
    const { exportedAssignment } = this.state;
    this.setState({ exportedAssignment: !exportedAssignment});
  }

  deleteMessages() {
    const {
      answers={},
      activeHocketId,
      hocketSelectionRange,
      regradeRequestIdMap,
    } = this.state;
    const { hockets=[] } = answers[this.currentProblemId()];
    if (!activeHocketId) {
      console.log("No hocket selected");
      return null;
    }
    const currentProblemId = this.currentProblemId();
    const regradeRequestThread = regradeRequestIdMap[currentProblemId];
    if (regradeRequestThread && regradeRequestThread.includes(activeHocketId)) {
      const idx = regradeRequestThread.indexOf(activeHocketId);
      regradeRequestThread.splice(idx, 1);
      return this.answerRef
        .doc(currentProblemId)
        .set({ regradeRequestThread }, {merge: true})
        .then( () => {
          this.answerRef
            .doc(currentProblemId)
            .collection('hockets')
            .doc(activeHocketId)
            .delete();
        })
        .catch(console.error);
    }
    let range, answer, currentIndex;
    if (hocketSelectionRange) {
      answer = window.confirm("Are you sure you want to delete the selected messages?")
      range = [...hocketSelectionRange];
    } else {
      answer = window.confirm("Are you sure you want to delete the selected message?")
      currentIndex = hockets.findIndex(hocket => hocket.hocketId === activeHocketId);
      range = [currentIndex, currentIndex];
    }
    range = range.sort((a,b) => a-b);
    if (answer) {
      let nextHocketId = null;
      if (currentIndex < hockets.length - 1) {
        nextHocketId = hockets[currentIndex + 1].hocketId;
      }
      const remainingHockets = hockets.filter((hocket, i) => {
        return ((i < range[0]) || (i > range[1]));
      });
      this.setState({ activeHocketId: nextHocketId });
      return this.answerRef.doc(this.currentProblemId()).set(
           { hockets: remainingHockets },
           { merge: true }
      );
    }
    this.setSubmittedStatus(false);
  }

  handleCopy(event) {
    const { locked, hocketSelectionRange, reOrder } = this.state;
    if (hocketSelectionRange ||
          ((locked || reOrder) && document.activeElement === this.hocketsAreaRef)) {
      let numMessages = 1;
      if (hocketSelectionRange) {
        numMessages = Math.abs(hocketSelectionRange[1] - hocketSelectionRange[0]) + 1;
      }
      const [jsonData, html, md] = this.copyHockets();
      event.clipboardData.setData('application/json',
      jsonData);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', md);
      NotificationManager.info(`${numMessages} message${numMessages === 1 ? "" : "s"} copied!`);
      event.preventDefault();
    }
  }

  handlePaste(event) {
    const clipText = event.clipboardData.getData('application/json');
    if (document.activeElement === this.hocketsAreaRef ||
        document.activeElement.tagName.toLowerCase() === 'body') {
      this.pasteHockets(clipText);
      event.preventDefault();
    }
  }

  copyHockets() {
    const {
      activeHocketId,
      answerHockets,
      hocketSelectionRange,
      answers,
    } = this.state;
    const hocketIds = answers[this.currentProblemId()].hockets;
    const messagesToCopy = []
    let start, stop;
    let html = "";
    let md = "";
    if (hocketSelectionRange) {
      [start, stop] = [...hocketSelectionRange].sort((a,b) => a-b);
      for (let i = start;
               i <= stop;
               i++) {
        const currentHocket = answerHockets[hocketIds[i].hocketId];
        if (currentHocket && currentHocket.responses.length > 0) {
          const message = currentHocket.responses[0];
          const suggestions = currentHocket.suggestions;
          const jessieCode = currentHocket.jessieCode;
          const tableData = currentHocket.tableData || '';
          const aspectRatio = currentHocket.aspectRatio || "100%";
          const codeCell = currentHocket.codeCell;
          const urlIFrame = currentHocket.urlIFrame;
          const heightIFrame = currentHocket.heightIFrame;
          messagesToCopy.push({ message, suggestions, tableData, jessieCode, aspectRatio, codeCell, urlIFrame, heightIFrame });

          const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
          html += converter.convert();

          md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n---\n\n";
        }
      }
      md = md.slice(0, md.length - 7); // 7 is the length of \n\n---\n\n
    } else {
      const activeHocket = answerHockets[activeHocketId];
      if (activeHocket && activeHocket.responses.length > 0) {
        const message = activeHocket.responses[0];
        const suggestions = activeHocket.suggestions;
        const jessieCode = activeHocket.jessieCode || '';
        const aspectRatio = activeHocket.aspectRatio || "100%";
        messagesToCopy.push({ message, suggestions, jessieCode, aspectRatio });

        const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
        html += converter.convert();

        md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
      }
    }
    return [JSON.stringify(messagesToCopy), html, md];
  }

  pasteHockets(clipText) {
    const { answers={}, activeHocketId } = this.state;
    const currentProblemId = this.currentProblemId();
    const { hockets=[] } = answers[currentProblemId];
    let messages;
    try {
      messages = JSON.parse(clipText);
    } catch (err) {
      console.error("Only messages from other Prismia assignments can be pasted");
      return
    }
    messages
      .forEach( (message, loopCounter) => {
        const hocket = Hocket();
        if (!('message' in message) ||
            !isValidDelta(message.message) ||
            !('suggestions' in message)) {
          console.error("Only messages from other Prismia assignments can be pasted");
          return;
        }
        hocket.responses.push(message.message);
        hocket.suggestions = message.suggestions;
        hocket.jessieCode = message.jessieCode || '';
        hocket.aspectRatio = message.aspectRatio || '';
        hocket.codeCell = message.codeCell || false;
        hocket.tableData = message.tableData || '';
        hocket.urlIFrame = message.urlIFrame || '';
        hocket.heightIFrame = message.heightIFrame || 0;
        const hocketStub = {
          hocketId: hocket.id,
        };
        const idx = (activeHocketId ?
            hockets.findIndex(
              hocket => hocket.hocketId === activeHocketId
            ) : hockets.length - 1);
        hockets.splice(idx + loopCounter + 1, 0, hocketStub);
        this.answerRef
          .doc(currentProblemId)
          .collection('hockets')
          .doc(hocket.id)
          .set(hocket, {merge: true})
          .catch(console.error);
      });
    this.answerRef
      .doc(currentProblemId)
      .set({ hockets }, {merge: true});
    }

  createRegradeHocket() {
    const { regradeRequestIdMap } = this.state;
    const currentProblemId = this.currentProblemId();
    const regradeRequestThread = regradeRequestIdMap[currentProblemId] || [];
    if (!currentProblemId) return;
    const hocket = Hocket();
    regradeRequestThread.push(hocket.id);
    this.pingBinderKernel();
    return this.answerRef
      .doc(currentProblemId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.answerRef
          .doc(currentProblemId)
          .set({ regradeRequestThread }, {merge: true});
      })
      .catch(console.error);
  }

  createHocket() {
    let { answers={}, activeHocketId } = this.state;
    const currentProblemId = this.currentProblemId();
    if (!currentProblemId) return;
    const { hockets=[] } = answers[currentProblemId] || {hockets: []};
    const hocket = Hocket();
    const hocketStub = {
      hocketId: hocket.id,
    };
    const idx = (activeHocketId ?
        hockets.findIndex(
          hocket => hocket.hocketId === activeHocketId
        ) : hockets.length - 1);
    hockets.splice(idx + 1, 0, hocketStub);
    this.answerRef
      .doc(currentProblemId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.answerRef
          .doc(currentProblemId)
          .set({ hockets }, {merge: true});
      })
      .catch(console.error);
    this.pingBinderKernel();
  }

  onDragEnd(res) {
    const currentProblemId = this.currentProblemId();
    const { destination, source } = res;
    if (!destination || !source) return;
    const { answers={} } = this.state;
    const { hockets=[] } = answers[currentProblemId];
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const [hocket] = hockets.splice(sourceIndex, 1);
    hockets.splice(destinationIndex, 0, hocket);
    answers[currentProblemId].hockets = hockets;
    this.setState({ answers });
    this.answerRef.doc(currentProblemId).set({ hockets }, {merge: true});
    this.setSubmittedStatus(false);
  }

  renderAssignmentArea(classNames='') {
    const { notCurrentlyAvailable, availabilityOverride} = this.state;
    if (notCurrentlyAvailable && !availabilityOverride) {
      return <div 
                style={{maxWidth: "400px", marginTop: "160px",
                        marginLeft: "auto", marginRight: "auto"}}>
                Exam is available only between 
                <p>{String(this.state.examStartDate)}</p> 
                and 
                <p>{String(this.state.examEndDate)}</p>
                for a total of {String(this.state.examMinutes)} minutes.
        <div className='centered'>
          <Button
            variant="outlined"
            style={{marginTop: "40px"}}
            onClick={() => {
              const { db, projectId, assignmentId,
                      currentUser } = this.props;
              const result = window.confirm("Select OK to indicate that you understand that this action will be logged and may be reviewed by instructors, and that you want to proceed. Select cancel to exit.")
              if (result) {
                db.collection('projects')
                  .doc(projectId)
                  .collection('assignments')
                  .doc(assignmentId)
                  .collection('exam-overrides')
                  .doc(currentUser.id)
                  .set({requests: arrayUnion({
                    userId: currentUser.id,
                    userDisplayName: currentUser.displayName,
                    timestamp: arraySafeNow(),
                    startTime: this.state.startTime,
                  })}, {merge: true})
                  .then(() => {
                    if (this.state.startTime === 'unstarted') {
                      this.setStartTime();
                    }
                    this.setState({availabilityOverride: true});
                  })
                  .catch(console.error)
              }
            }}>
            See exam anyway
          </Button>
        </div>
      </div>;
    }
    const { assignment={}, currentProblem } = this.state;
    if (!assignment) return null;
    const { title='', description='' } = assignment;
    return (
      <div className={"y-scrollable " + classNames}>
        <h1
          className="assignment-title centered">
          { title || '' }
        </h1>
        <div
          className="centered description-text">
          { description || '' }
        </div>
        <div
          className="flex-container align-center justify-center centered">
          <FlexButton
            onClick={ () => this.prevProblem() }
            disabled={ currentProblem === 0 }>
            <ArrowBackIcon/>
          </FlexButton>
          <span className="flex-none" style={{fontSize: 28, marginLeft: "20px", marginRight: "20px"}}> {  (this.state.currentProblem + 1) } </span>
          <FlexButton
            onClick={ () => this.nextProblem() }
            disabled={ currentProblem === this.registeredProblems().length - 1 }>
            <ArrowForwardIcon/>
          </FlexButton>
        </div>
        { this.renderHocketsArea() }
      </div>
    );
  }

  isInSelectionRange(hocketId) {
    const { answers, hocketSelectionRange } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = answers[this.currentProblemId()];
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  handleJupyterImport(file) {
    const { db } = this.props;
    const { assignment, answerHockets } = this.state;
    const { codeCell='code', hocketIds } = assignment;
    file.text().then(text => {
      const jupyterDocument = JSON.parse(text);
      const problemIds = hocketIds.map(stub => stub.hocketId);
      const indexMap = new Map();
      const solutionCellMap = new Map();
      const problemCellIds = [];
      let currentProblemId;
      const isProblemCell = (cell) => {
        if (!cell.metadata || !cell.metadata.prismiaId) return false;
        return problemIds.includes(cell.metadata.prismiaId);
      }
      for (let [idx, cell] of jupyterDocument.cells.entries()) {
        if (cell.metadata.prismiaId) {
          const id = cell.metadata.prismiaId;
          indexMap.set(id, idx);
          if (problemIds.includes(id)) {
            if (problemCellIds.includes(id)) {
              NotificationManager.error("Duplicate problem statement cells detected");
              return;
            }
            currentProblemId = id;
            problemCellIds.push(currentProblemId);
            solutionCellMap.set(id, []);
          }
        }
        if (!isProblemCell(cell) && currentProblemId) {
          solutionCellMap.get(currentProblemId).push(cell);
        }
      }
      for (let [idx, problemId] of problemIds.entries()) {
        if (problemId !== problemCellIds[idx]) {
          NotificationManager.error("There was an issue identifying problem statement cells")
          return;
        }
      }
      const prismiaHockets = Object.values(answerHockets);
      for (let problemId of problemIds) {
        const batch = db.batch();
        const { hockets, newHockets } = cells2hockets(
          solutionCellMap.get(problemId),
          prismiaHockets,
          codeCell,
        );
        for (let hocket of hockets) {
          if (newHockets.has(hocket.id)) {
            batch.set(
              this.answerRef
                .doc(problemId)
                .collection('hockets')
                .doc(hocket.id),
              hocket
            );
          }
        }
        batch.set(
          this.answerRef.doc(problemId),
          { hockets: hockets.map(h => {return {hocketId: h.id};}) },
        );
        batch.commit().catch(console.error);
      }
    });
    this.setState({ showHelp: false });
  }

  renderHocketCard(hocket, index, clickable = true) {
    const { currentUser } = this.props;
    if (!hocket) return null;
    const { activeHocketId, codeCell } = this.state;
    const style = {background: '#fff'};
    if (this.state.reOrder && hocket.id === activeHocketId) {
      style.background = 'rgb(169, 216, 225)'; // light teal
    }
    if (this.isInSelectionRange(hocket.id)) {
      style.background = '#fff3a8' // light yellow
    }
    let className = "assignment-card";
    if (index === -1) {
      className += " problem-message";
    }
    if (index === -2) {
      className += " feedback-card";
    }
    let title;
    let messageBubble = null;
    if (hocket.fromInstructionalStaff && index === -3) {
      className += " regrade-from-instructional-staff";
      title = "response from instructional staff"
      messageBubble = <div className="regrade-bubble instructional-staff">
        I
      </div>;
    } else if (index === -3) {
      className += " regrade-from-student";
      title = "regrade request message from you";
      messageBubble = <div className="regrade-bubble student-message-bubble">
        <img 
          src={currentUser.photoUrl} 
          alt="student profile">
        </img>
      </div>;
    }
    const card = (<div
       className={ className }
       key={ hocket.id }
       style={ style }
       onClick={ (e) => {
           if (!clickable) return;
           if (e.shiftKey) {
             if (activeHocketId) {
               this.setSelectionEnd(hocket.id);
             } else {
               this.setActiveHocketId(hocket.id);
             }
           } else {
             this.setActiveHocketId(hocket.id);
           }
       } }>
       <CardContents 
          db={ this.props.db } 
          projectId={ this.props.projectId } 
          hocket={ hocket }
          codeCell={ codeCell }
          stripMarkers
          setLang={(lang) => this.setLang(lang)}/>
       { messageBubble }
    </div>);
    if (index < 0) {
      if (index === -3) {
        return <Tooltip
                title={ title }
                enterDelay={ 250 }>
               { card }
        </Tooltip>
      } else {
        return card;
      }
    }
    if (!this.state.locked && !this.state.reOrder && activeHocketId === hocket.id) {
      return <div
        style={ style }
        key={ hocket.id } >
          { this.renderHocketForm() }
        </div>;
    }
    if (!this.state.reOrder) return card;
    return (
      <Draggable
        draggableId={ hocket.id }
        index={ index }
        key={ hocket.id }>
      {(provided, snapshot) => {
        return (
            <div
              ref={ provided.innerRef }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="assignment-card"
              key={ hocket.id }
              style={ getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                style
              )}
              onClick={ (e) => {
                  if (!clickable) return;
                  if (this.hocketsAreaRef) {
                    this.hocketsAreaRef.focus()
                  }
                  if (e.shiftKey) {
                    this.setSelectionEnd(hocket.id);
                  } else {
                    this.setActiveHocketId(hocket.id);
                  }
              } }>
              <CardContents 
                db={ this.props.db } 
                projectId={ this.props.projectId } 
                hocket={ hocket }
                codeCell={ codeCell }
                stripMarkers
                setLang={(lang) => this.setLang(lang)}/>
            </div>
        );
      }}
      </Draggable>
    );
  }

  renderSuggestions(suggestions) {
    return <div className="padded-sides"><SuggestionSuperList
      includeSuggestionReplies
      hidePlaceholder={ true }
      label="Suggestion"
      showHocketField={ false }
      readOnly={ true }
      hideLast={ true }
      limit={ 15 }
      projectId={ this.props.projectId }
      db={ this.props.db }
      values={ suggestions }
      updateValues={ (suggestions) => null }/></div>;
  }

  renderHocketForm(singleForm=false) {
    const { db, storage, projectId, currentUser, assignmentId } = this.props;
    const { activeHocketId, codeCell } = this.state;
    if (!activeHocketId) return <></>;
    const problemId = this.currentProblemId();
    const hocketRef = db
      .collection('users')
      .doc(currentUser.id)
      .collection('assignment-projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('problems')
      .doc(problemId)
      .collection('hockets')
      .doc(activeHocketId);
    return (
      <div
        className="message-form-container"
        key={ activeHocketId }>
        <MessageForm
          db={ db }
          currentUser={ currentUser }
          storage={ storage }
          singleForm={ singleForm }
          projectId={ projectId }
          hocketId={ activeHocketId }
          clearActiveHocketId={ () => this.setActiveHocketId(null) }
          deleteMessages={ this.deleteMessages }
          mainQuillRef={ this.mainQuillRef }
          splitActiveCell={ () => this.splitActiveCell() }
          createHocket={ () => this.createHocket() }
          codeCell={ codeCell }
          setSubmittedStatus={ (status) => this.setSubmittedStatus(status) }
          setMousetrap={ () => this.setMousetrap() }
          hocketRef={ hocketRef }
          hideSuggestedMessages
          hideOpenResponse
          studentView
          />
      </div>
    );
  }

  toggleJuniper() {
    const { juniperOpen } = this.state;
    this.setState({ juniperOpen: !juniperOpen }, () => {
      //if (juniperOpen) this.juniperRef.current.codeMirror.focus();
    });
  }

  renderJuniper() {
    const { juniperOpen, juniperHasRendered, codeCell } = this.state;
    const style = {};
    if (!juniperOpen && !juniperHasRendered) return null;
    if (!juniperHasRendered) this.setState({ juniperHasRendered: true });
    if (!juniperOpen) style.display = "none";
    return <div className="juniper-container" style={ style }>
      <JupyterCell
        ref={ this.juniperRef }
        language={ codeCell }
        setLang={ (lang) => this.setLang(lang) }/>
    </div>;
  }

  problemHockets(problemId) {
    const { assignment, hockets={} } = this.state;
    if (!problemId) problemId = this.currentProblemId();
    const assignmentHocketIds = assignment.hocketIds.map(
      hocketStub => hocketStub.hocketId
    );
    const registeredIds = assignmentHocketIds.filter((id) => this.isRegistered(id));
    const problemIndexRegistered = registeredIds.indexOf(problemId);
    const problemIndex = assignmentHocketIds.indexOf(problemId);
    const prevIndex = (problemIndexRegistered === 0 ? 0 :
      assignmentHocketIds.indexOf(registeredIds[problemIndexRegistered - 1]) + 1
    );
    let j = prevIndex;
    while (isNote(hockets[assignmentHocketIds[j]])) j++;
    const firstProblemStatementIndex = j;
    let k = problemIndex + 1;
    while (isNote(hockets[assignmentHocketIds[k]])) k++;
    const solutionEndIndex = k;
    return {
      problemHockets: assignmentHocketIds
                        .slice(firstProblemStatementIndex, problemIndex + 1)
                        .map( id => hockets[id] ),
      solutionHockets: assignmentHocketIds
                        .slice(problemIndex + 1, solutionEndIndex)
                        .map( id => hockets[id] ),
    };
  }

  renderSubmission() {
    const { submittedSolutions, openProblems, openSolutions } = this.state;
    const answerCards = [];
    const registeredProblems = this.registeredProblems();
    if (!submittedSolutions || Object.keys(submittedSolutions).length === 0) {
      return <div className="student-submission-card">
      <h1 className="centered">Your submitted solutions</h1>
        <div style={{textAlign: "center"}}>
          [No submitted solutions found]
        </div>
    </div>
    }
    for (let [idx, problemId] of registeredProblems.entries()) {
      if (!submittedSolutions?.[problemId]) continue;
      answerCards.push(<h3
        className="centered"
        style={{cursor: "pointer"}}
        key={"problem-" + idx + "-header"}
        onClick={() => {
          const { openProblems } = this.state;
          if (openProblems.has(problemId)) {
            openProblems.delete(problemId);
          } else {
            openProblems.add(problemId);
            openSolutions.add(problemId);
          }
          this.setState({ openProblems });
        }}>
        {(openProblems.has(problemId) ? "▾ " : "▸ " ) + "Problem " + (idx + 1)}
      </h3>);
      if (openProblems.has(problemId)) {
        const { problemHockets } = this.problemHockets(problemId);
        for (let hocket of problemHockets) {
          answerCards.push(this.renderHocketCard(hocket, -1));
        }
      }
      answerCards.push(<h3 
        key={"solution-header-" + problemId} 
        style={{cursor: "pointer"}}
        className="centered"
        onClick={() => {
          const { openSolutions } = this.state;
          if (openSolutions.has(problemId)) {
            openSolutions.delete(problemId);
          } else {
            openSolutions.add(problemId);
          }
          this.setState({ openSolutions });
        }}>
          {(openSolutions.has(problemId) ? "▾ " : "▸ " ) + "Solution"}
      </h3>);
      if (openSolutions.has(problemId)) {
        for (let hocket of submittedSolutions[problemId].hockets) {
          answerCards.push(
            this.renderHocketCard(hocket, -1)
          );
        }
      }
    }
    return <div className="student-submission-card">
      <h1 className="centered">Your submitted solutions</h1>
        { answerCards }
    </div>
  }

  renderHocketsArea() {
    const { answers={}, answerHockets } = this.state;
    const currentProblemId = this.currentProblemId();
    const {problemHockets, solutionHockets} = this.problemHockets(currentProblemId);
    const problemCard = <div className="problem-statement" key="problem-statement">
      { problemHockets.map( hocket =>
          this.renderHocketCard(hocket, -1) ) }
    </div>;
    const solutionCard = solutionHockets.length ? <div 
      className="problem-statement" 
      key="solution-statement">
      { solutionHockets.map( hocket =>
          this.renderHocketCard(hocket, -1) ) }
    </div> : null;
    const hocketCards = [];
    let hocketStubs = (answers[currentProblemId] || {hockets: []}).hockets;
    const feedback = (answers[currentProblemId] || {feedback: null}).feedback;
    for (let i = 0; i < hocketStubs.length; i++) {
      hocketCards.push(this.renderHocketCard(
        answerHockets[hocketStubs[i].hocketId], i
      ));
    }
    return (
      <div
        className="hockets-area"
        tabIndex={ -1 }
        ref={ (node) => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
        <h3 className="centered" style={{color: "grey"}}> Problem </h3>
        { problemCard }
        { solutionCard ? <h3 className="centered" style={{marginTop: "60px", color: "grey"}}> Reference Solution </h3> : null }
        { solutionCard }
        <h3 className="centered" style={{marginTop: "60px", color: "grey"}}> Solution </h3>
        <DragDropContext
          onDragEnd={res => this.onDragEnd(res)}>
          <Droppable
            droppableId={'list'}
            key={ 'droppable' }>
          { (provided, snapshot) => (
            <div
              className="pad-bottom"
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.innerProps}
                >
            { hocketCards }
            { provided.placeholder }
            </div>
          )}
          </Droppable>
        </DragDropContext>
        <Tooltip
          title="Add a new cell after the currently selected cell [shift +]"
          enterDelay={ 500 }>
          <Button
            fullWidth
            variant="outlined"
            className="add-hocket-button"
            onClick={() => this.createHocket()}
            >
            +
          </Button>
        </Tooltip>
        { feedback ? <h3 className="centered" style={{color: "grey"}}> Feedback </h3> : null }
        { feedback ? this.renderRubricArea(feedback) : null }
      </div>
    );
  }

  getPoints(feedback) {
    let { rubric, rubricMap } = feedback;
    if (!rubric) rubric = [];
    if (!rubricMap) rubricMap = {};
    const points = (text) => {
      const regex = /\[(-?[0-9.]*?)\]/;
      const result = regex.exec(text);
      if (!result) return 0;
      try {
        return parseFloat(result[1])
      } catch {
        return 0;
      }
    }
    const pointsScored = rubric
      .filter(item => rubricMap[item.id])
      .map(item => points(item.text))
      .reduce((a, b) => a + b, 0);
    const totalPoints = rubric
      .map( item => points(item.text))
      .reduce((a, b) => a + Math.max(0, b), 0);
    return [pointsScored, totalPoints];
  }

  renderRubricItem(rubricMap, item) {
    const { id, text } = item;
    return <FormControlLabel
      control={ <Checkbox checked={ !!(rubricMap[id]) } color="primary" inputProps={{ 'aria-label': 'Rubric Item' }}/> }
      label={ text }
      key={ id }/>;
  }

  renderRegradeRequest() {
    const { db, projectId, assignmentId, currentUser } = this.props;
    const { regradeRequestIdMap, activeHocketId } = this.state;
    const regradeRequestThread = regradeRequestIdMap[this.currentProblemId()];
    if (!regradeRequestThread) return null;
    return (<>
      { regradeRequestThread.map( (regradeRequestId, i) => {
        if (regradeRequestId === activeHocketId) {
          return this.renderHocketForm(true); // singleForm
        } else if (regradeRequestId) {
          const { answerHockets } = this.state;
          const hocket = answerHockets[regradeRequestId];
          if (hocket) return (<div key={ hocket.id } style={{textAlign: "center"}}>
            { this.renderHocketCard(hocket, -3, !hocket.submitted) }
            { i < regradeRequestThread.length - 1 ? null : <Tooltip title="add regrade request message" enterDelay={ 250 }><Button onClick={ () => this.createRegradeHocket() } variant="outlined" size="small" style={{marginTop: "15px", marginRight: "8px"}}>
              +
            </Button></Tooltip> }
            { (i < regradeRequestThread.length - 1 || hocket.fromInstructionalStaff) ? null :
              <Button
                color="primary"
                disabled={ hocket.submitted }
                variant="contained"
                style={{ marginTop: "15px" }}
                size="small"
                onClick={ () => {
                  const thread = regradeRequestThread.map(id => answerHockets[id]);
                  db.collection('projects')
                    .doc(projectId)
                    .collection('assignments')
                    .doc(assignmentId)
                    .collection('students')
                    .doc(currentUser.id)
                    .collection('problems')
                    .doc(this.currentProblemId())
                    .set({ regradeRequestThread: thread }, {merge: true})
                    .then( () => {
                      const batch = db.batch();
                      for (let submittedHocket of thread) {
                        if (!submittedHocket.fromInstructionalStaff) {
                          batch.set(
                            this.answerRef
                              .doc(this.currentProblemId())
                              .collection('hockets')
                              .doc(submittedHocket.id),
                              { submitted: true }, {merge: true});
                        }
                      }
                      // this part is just for triggering notifications
                      const problemId = this.currentProblemId();
                      batch.set(
                        db.collection('projects')
                          .doc(projectId)
                          .collection('assignments')
                          .doc(assignmentId)
                          .collection('regrade-requests')
                          .doc(currentUser.id + '--' + problemId), {
                            timestamp: now(),
                            projectId,
                            assignmentId,
                            problemId,
                            userId: currentUser.id,
                            userDisplayName: currentUser.displayName,
                            userPhotoUrl: currentUser.photoUrl,
                            resolved: false,
                          }, { merge: true }
                      );
                      batch.commit();
                    })
                    .catch(console.error);
                }}>
                { hocket.submitted ? "Submitted" : "Submit" }
              </Button>
            }
          </div>);
        }
        return null;
      })
      }
    </>);
  }

  openRegradeRequest() {
    const { regradeRequestIdMap, answerHockets } = this.state;
    const currentProblemId = this.currentProblemId();
    const regradeRequestThread = regradeRequestIdMap[currentProblemId] || [];
    if (!regradeRequestThread.length) this.createRegradeHocket();
    const regradeRequestId = regradeRequestThread.slice(-1);
    const hocket = answerHockets[regradeRequestId];
    if (hocket && hocket.submitted) return;
    if (regradeRequestId) {
      this.setState({
        activeHocketId: regradeRequestId,
      });
    }
  }

  renderRubricArea(feedback) {
    let { rubric, rubricMap } = feedback;
    if (!rubric) rubric = [];
    if (!rubricMap) rubricMap = {};
    const [pointsScored, totalPoints] = this.getPoints(feedback);
    return (
      <div className="student-answer-card feedback-card">
        <div className="regrade-request"> <Tooltip title="request regrade"><CachedIcon onClick={ () => this.openRegradeRequest() }/></Tooltip> </div>
        { totalPoints ? <div className="rubric-points">{ `${pointsScored}/${totalPoints}`}</div> : null }
        <FormGroup className="rubric-group" style={{marginLeft: "10px", marginBottom: "8px"}}>
        { rubric.map(item => this.renderRubricItem(rubricMap, item)) }
        </FormGroup>
        { feedback.comments ? <CardContents 
          db={ this.props.db } 
          setLang={(lang) => this.setLang(lang)}
          projectId={ this.props.projectId } 
          hocket={ feedback.comments }/> : null }
        { this.renderRegradeRequest() }
      </div>
    );
  }

  getSubmission() {
    if (this.state.showSubmission) {
      this.setState({ showSubmission: false });
      return;
    }
    const { db, projectId, assignmentId, currentUser } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .collection('students')
      .doc(currentUser.id)
      .collection('problems')
      .get()
      .then(snap => {
        for (let doc of snap.docs) {
          const { submittedSolutions={} } = this.state;
          submittedSolutions[doc.id] = doc.data();
          this.setState({ submittedSolutions });
        }
      })
      .then(() => {
        this.setState({ showSubmission: true });
      })
      .catch(console.error);
  }

  submitAnswers() {
    const { db, projectId, currentUser, assignmentId } = this.props;
    const { assignment, answers, answerHockets,
            overrideDeadline, deadline } = this.state;
    const { hocketIds=[] } = assignment;
    const batch = db.batch();
    let thisDeadline = overrideDeadline || deadline
    if (thisDeadline?.toDate) {
      thisDeadline = thisDeadline.toDate();
    } else {
      thisDeadline = null;
    }
    for (let hocketStub of hocketIds) {
      const answer = answers[hocketStub.hocketId];
      if (!answer) continue;
      const fullAnswers = answer.hockets.map(
        stub => answerHockets[stub.hocketId]
      ).filter(Boolean);
        db.collection('projects')
          .doc(projectId)
          .collection('assignments')
          .doc(assignmentId)
          .collection('students')
          .doc(currentUser.id)
          .collection('problems')
          .doc(hocketStub.hocketId).set(
        { hockets: fullAnswers,
          userId: currentUser.id,
          deadline: thisDeadline,
          timestamp: now(), }
        )
        .catch(console.error);

    }
    batch.set(
      this.projectRef,
      { [assignmentId]: true }, {merge: true}
    );
    batch.set(
      db.collection('projects')
        .doc(projectId)
        .collection('assignments')
        .doc(assignmentId)
        .collection('students')
        .doc(currentUser.id),
      { id: currentUser.id }
    ); // <- ensures doc is nonempty so it shows up in query from grading view
    batch.commit()
      .then(() => NotificationManager.success("All solutions submitted!"))
      .catch(console.error);
  }

  render() {
    updateTitleBar('Assignments');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings, showSubmission, codeCell, submitted, reOrder } = this.state;
    const classes = showHelp || showSettings ? "full-height blur" : "full-height";
    const tools = [{
      icon: <HelpOutlineOutlinedIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help screen",
      disabled: false,
      hide: false,
    }, {
      icon: <SettingsIcon/>,
      onClick: () => this.setState({ showSettings: true }),
      tooltipTitle: "Show tools and settings",
      disabled: false,
      hide: false,
    }, {
      icon: <CheckIcon/>,
      onClick: () => this.submitAnswers(),
      tooltipTitle: submitted ? "All solutions submitted" : "Submit solutions",
      disabled: false,
      hide: this.state.released || (!this.state.exam && this.state.pastDeadline),
    }, {
      icon: reOrder ? <ReorderIcon style={{color: "orange"}}/> : <ReorderIcon/>,
      onClick: () => this.setState({ reOrder: !reOrder }),
      tooltipTitle: reOrder ? "Done reordering" : "Reorder messages",
      disabled: false,
      hide: false,
    }, {
      icon: <CodeIcon/>,
      onClick: () => this.toggleJuniper(),
      tooltipTitle: "Toggle code cell",
      disabled: false,
      hide: !codeCell,
    }, {
      icon: this.state.locked ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>,
      onClick: () => this.setState({locked: !this.state.locked}),
      tooltipTitle: this.state.locked ? "Unlock messages" : "Lock messages",
      disabled: false,
      hide: !this.state.locked,
    }];
    const navHeight = (width) => 62 + (width < mobileThreshold ? 48 : 0);
    const table = width => (<div className={classes}>
      <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } studentView={ true }/>
        <div className="flow-root">
          <SidebarButtonPanel
            mobile={ width < mobileThreshold }
            tools={ tools }/>
        </div>
        <div style={{
          position: "relative", 
          height: `calc(100% - ${navHeight(width)}px)`}}>
          { this.renderAssignmentArea(width < mobileThreshold ? "border-top" : "") }
          { this.renderJuniper() }
        </div>
    </div>);
    const maskCover = (showHelp || showSettings) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    const submissionModal = showSubmission ? this.renderSubmission() : null;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({width }) => {
            return <div className="assignment-view">
              { maskCover }
              { helpCard }
              { settingsCard }
              { submissionModal }
              { table(width) }
              <NotificationContainer/>
            </div>;
        }}
      </ReactResizeDetector>
    );
  }

}

export default AssignmentView;
