import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { safePhotoUrl } from '../profile-emojis';
import { hashString, stringifyMessage, extractImageUrl } from '../utils';
import './style.css';

const sanitize = (text) =>
      text.split(" ").map(word => word.length < 15 ? word :
          [...word].map((letter, index) =>
            letter + (index % 13 === 12 ? " " : "")
          ).join("")
        ).join(" ");

const getContainerStyle = (isDragging, draggableStyle, clusterStyle={}, otherStyles={}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#a5ff9a' : clusterStyle.background,
    // styles we need to apply on draggables
    color: clusterStyle.color,
    ...draggableStyle,
    ...otherStyles,
    ...(isDragging ? {border: '1px solid #AAA', borderRadius: "8px"} : {}),
});

const getItemStyle = (isDragging, draggableStyle, clusterStyle={}, otherStyles={}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#a5ff9a' : clusterStyle.background,
    // styles we need to apply on draggables
    color: clusterStyle.color,
    ...draggableStyle,
    ...otherStyles,
    ...(isDragging ? {border: 'none', borderRadius: "12px"} : {}),
});

const getVisibility = (message, fadeMessagesHandledByOtherInstructors, currentUser) => {
  if (fadeMessagesHandledByOtherInstructors) {
    if (message.respondedTo) {
      return "greyed-out";
    } else if (message.responseInProgress &&
               message.responseInProgress !== currentUser.id) {
      return "partially-greyed-out";
    }
  } else {
    return "full";
  }
}


const getStatusStyle = (currentUser, message, columnIndex, hue, numberOfClusters, fadeMessagesHandledByOtherInstructors) => {
  const visibility = getVisibility(message, fadeMessagesHandledByOtherInstructors, currentUser);
  let lightness = "";
  if (visibility === "greyed-out") {
    lightness = "98%";
  } else if (visibility === "partially-greyed-out") {
    lightness = "95%";
  } else
  {
    lightness = "89%";
  }
  const style = {
    "background": "hsl(" + hue + ", 100%," + lightness + ")",
    "background-clip": "padding-box"
  }
  if (visibility === "partially-greyed-out") {
    style.color = "rgb(140, 140, 140)"; // medium gray
  } else if (visibility === "greyed-out") {
    style.color = "rgb(210, 210, 210)"; // light gray
  }
  if (columnIndex >= numberOfClusters) {
    style.background = "hsl(" + hue + ", 100%, 100%)";
  }
  return style;
}

const getBorderStyle = (visibility) => {
  if (visibility === "partially-greyed-out") {
    return '1px dashed rgb(140, 140, 140)';
  } else if (visibility === "greyed-out") {
    return '1px solid rgb(210, 210, 210)';
  } else {
    return '1px solid rgb(50,50,50)';
  }
}

class ClusteredMessageCardContents extends Component {

  shouldComponentUpdate(nextProps) {
    const propsToWatch = ["message", "columnIndex", "clearLocally",
          "numberOfClusters", "currentUser", "fadeMessagesHandledByOtherInstructors", "hue"];
    if (this.props.seedMessage) return false;
    for (let prop of propsToWatch) {
      if (nextProps[prop] !== this.props[prop]) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {
      message,
      tooltipTitle,
      setPrivateChat,
      flagMessage,
      starMessage,
      shareMessage,
      clearMessage,
      clearLocally,
      clearMessageLocally,
    } = this.props;
    const starIcon = <img className="inline-star"
                          src="/black-star-filled-white.svg"
                          alt="star"
                          style={{marginBottom: "-1px"}}/>
    const flagIcon = <FlagIcon
                        style={{fontSize: 14, marginBottom: "-3px", color: "#237881"}}/>
    const profilePic = <img className="brick-profile-pic" alt="profile" src={ safePhotoUrl(message.authorPhotoUrl) }/>
    const messageImageUrl = extractImageUrl(JSON.parse(message.quillDelta));
    let img = null;
    if (messageImageUrl) {
      img = <img className="student-submitted"
                 src={ messageImageUrl }
                 alt={"message from " + message.authorDisplayName }/>
    }
    const flagStyle = {
      padding: 0,
      minWidth: "1em",
      marginRight: "0px",
      minHeight: "23pt",
      fontSize: "1.25em",
      transform: "translate(0, 3%)"
    };
    return (
          <Tooltip
          title={ tooltipTitle.responder ? <div>{ tooltipTitle.title }<br/>{ "[" + tooltipTitle.responder + "]" }</div> : <div>{ tooltipTitle.title }</div>}
          enterDelay={ 750 }
          placement="top"
          className="message-card-tooltip"> 
          <div>
            <p
              className="clustered-message-card"
              onClick={ () => setPrivateChat(message) }>
              { profilePic }
              { message.flagged ? flagIcon : "" }
              { message.starred ? starIcon : "" }
              { sanitize(message.textContent) }
              { img }
            </p>
            <div
              className="shelf"
              onClick={ () => setPrivateChat(message) }>
              { message.sharedWithClass ? null :
                <Tooltip title="share with class" enterDelay= { 1000 }>
                  <IconButton
                    onClick={ (event) => {
                      event.stopPropagation();
                      shareMessage(message);
                    }}
                    style={ {padding: 0, minWidth: "1em", minHeight: "23pt", marginLeft: "2px"} }>
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              }
              { clearLocally ? <Tooltip title="clear message" enterDelay={ 1000 }>
                <IconButton
                  className="float-right"
                  onClick={ (event) => {
                    event.stopPropagation();
                    clearMessageLocally(message);
                  } }
                  style={{padding: 0, minWidth: "1em", minHeight: "23pt", marginRight: "2px"}}
                  >
                  <HighlightOffIcon />
                </IconButton>
                </Tooltip> :
                <Tooltip title="delete message" enterDelay={ 1000 }>
                  <IconButton
                    className="float-right"
                    onClick={ (event) => {
                      event.stopPropagation();
                      clearMessage(message);
                    } }
                    style={{padding: 0, minWidth: "1em", minHeight: "23pt", marginRight: "2px"}}
                    >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
              { message.starred ? null :
                <Tooltip title="star message" enterDelay={ 1000 }>
                  <IconButton
                    className="float-right"
                    onClick={ (event) => {
                      event.stopPropagation();
                      starMessage(message)
                    }}
                    style={ {padding: 0, minWidth: "1em", minHeight: "23pt", marginLeft: "1px" } }>
                    <img className="drawer-star" src="/white-star.svg" alt="star"/>
                  </IconButton>
                </Tooltip> }
              { message.flagged ?
                <Tooltip title="unflag message" enterDelay={ 1000 }>
                  <IconButton
                  className="float-right"
                  onClick={ (event) => {
                    event.stopPropagation();
                    flagMessage(message, false);
                  } }
                  style={ flagStyle }>
                    <FlagIcon/>
                  </IconButton>
                </Tooltip> :
                <Tooltip title="flag message" enterDelay={ 1000 }>
                  <IconButton
                  className="float-right"
                  onClick={ (event) => {
                    event.stopPropagation();
                    flagMessage(message);
                  } }
                  style={ flagStyle }>
                    <FlagOutlinedIcon/>
                  </IconButton>
                </Tooltip> }
            </div>
          </div>
        </Tooltip>
    );
  }
}


class ClusteredMessageCard extends Component {

  shouldComponentUpdate(nextProps) {
    if (this.props.seedMessage) return false;
    const propsToWatch = ["columnIndex", "messageIndex", "numberOfClusters", "clearLocally",
      "currentUser", "fadeMessagesHandledByOtherInstructors", "hue", "drawerMode"];
    for (let prop of propsToWatch) {
      if (nextProps[prop] !== this.props[prop]) {
        return true;
      }
    }
    const messagePropsToWatch = ["id", "answered", "responseInProgress", "starred", "respondedTo", "flagged", "sharedWithClass"]
    for (let prop of messagePropsToWatch) {
      if (nextProps.message[prop] !== this.props.message[prop]) {
        return true;
      }
    }
    if (nextProps.tooltipTitle.responder !== this.props.tooltipTitle.responder) return true;
    return false;
  }

  render() {
    const {
      message,
      columnIndex,
      messageIndex,
      numberOfClusters,
      currentUser,
      fadeMessagesHandledByOtherInstructors,
      hue,
      tooltipTitle,
      setPrivateChat,
      flagMessage,
      starMessage,
      shareMessage,
      clearMessage,
      clearLocally,
      clearMessageLocally,
      drawerMode,
      seedMessage,
    } = this.props;
    if (seedMessage) {
      return <div>
        <p className="clustered-message-card">
          <em>{ sanitize(message.textContent) }</em>
        </p>
      </div>;
    }
    const style = getStatusStyle(currentUser, message, columnIndex, hue, numberOfClusters, fadeMessagesHandledByOtherInstructors);
    const visibility = getVisibility(message, fadeMessagesHandledByOtherInstructors, currentUser);
    const borderStyle = { 
      border: getBorderStyle(visibility),
    };
    return (
      <Draggable
        draggableId={ message.id }
        index={ messageIndex }
        key={ hashString(stringifyMessage(message)) }>
      { (provided, snapshot) => {
          return (
            <div
              ref={ provided.innerRef }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              key={ message.id }
              style={ getContainerStyle(
                snapshot.isDragging, 
              provided.draggableProps.style,
              )  }
              className={"brick-container"}>
              <div
              style={getItemStyle(
                snapshot.isDragging,
                {},
                style,
              borderStyle,
            )}
              className={"brick brick-transition" + (drawerMode ? " downmode" : "")}
            >
              <ClusteredMessageCardContents
                message={ message }
                key={ 'contents-' + message.id }
                columnIndex={ columnIndex }
                numberOfClusters={ numberOfClusters }
                currentUser={ currentUser }
                fadeMessagesHandledByOtherInstructors={ fadeMessagesHandledByOtherInstructors }
                hue={ hue }
                tooltipTitle={ tooltipTitle }
                setPrivateChat={ setPrivateChat }
                flagMessage={ flagMessage }
                starMessage={ starMessage }
                shareMessage={ shareMessage }
                clearMessage={ clearMessage }
                clearLocally={ clearLocally }
                clearMessageLocally={ clearMessageLocally }
              />
            </div>
            </div>
          );
        }
      }
      </Draggable>
    );
  }
}

export default ClusteredMessageCard;
