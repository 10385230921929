import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleAdminNav from '../SimpleAdminNav';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MessageForm from '../MessageForm';
import Hocket from '../hocket';
import moment from 'moment-timezone';
import { updateTitleBar, copyTextToClipboard, capitalize } from '../utils';
import { deleteCourse } from '../analytics';
import './style.css';

class ProjectView extends Component {

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.messageFormRef = React.createRef();
    this.unsub = {
      project: null,
    };
    this.state = {
      instructors: {},
      tas: {},
      timeZoneOffset: null,
      timeZone: null,
      welcomeHocketId: null,
      linkClicked: false,
      domainRestriction: '',
      homepageUrl: '',
      courseLanguage: '',
      messageBoard: false,
      assignments: false,
      drills: false,
      useObservers: false,
      menuOpenRef: null,
      useRealtimeFeatures: false,
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser) return console.log("no user");
    this.sub(this.props);
  }

  routeIdMatchesOldRouteId(newProps) {
    const { router } = this.props;
    return newProps.router.match.params.id === router.match.params.id;
  }

  sub(props) {
    this.subProject();
  }

  getTimeZoneOffset() {
    const { db, projectId } = this.props;
    const getUserTimezoneOffset = () => {
      return -(new Date()).getTimezoneOffset()/60;
    }
    if (!projectId) {
      this.setState({
        timeZoneOffset: getUserTimezoneOffset(),
      })
      return;
    }
    db.collection('projects')
      .doc(projectId)
      .get()
      .then( snap => {
        const doc = snap.data();
        if (doc.timeZoneOffset) {
          this.setState({
            timeZoneOffset: doc.timeZoneOffset,
          });
        } else {
          this.setState({
            timeZoneOffset: getUserTimezoneOffset(),
          });
        }
      }).catch(console.error);
  }

  subProject() {
    const { db, projectId } = this.props;
    if (this.unsub.project) this.unsub.project();
    this.unsub.project = db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const project = snap.data() || {};
        let { displayName='', description='', messageBoard, 
                timeZone, assignments, drills, useRealtimeFeatures,
                domainRestriction='', homepageUrl='',
                courseLanguage='', useObservers } = project;
        if (!timeZone) {
          const city = moment.tz.guess();
          this.saveTimezone(city);
        }
        this.setState({ 
          project, displayName, messageBoard, drills,
          useRealtimeFeatures, timeZone, useObservers, assignments,
          courseLanguage: capitalize(courseLanguage),
          description, domainRestriction, homepageUrl }, () => {
            this.setWelcomeMessage(project);
          });
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key]) this.unsub[key]();
    }
  }

  nameProject(displayName) {
    this.setState({ displayName });
  }

  describeProject(description) {
    this.setState({ description });
  }

  saveProjectDetails() {
    const { db } = this.props;
    const { project, displayName, description } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ displayName, description }, { merge: true });
  }

  setWelcomeMessage(project) {
    if (!project) return null;
    if (project.welcomeHocketId) {
      this.setState({ welcomeHocketId: project.welcomeHocketId });
    } else {
      const defaultWelcomeMessage = {ops: [{
        insert:
        "Welcome to this Prismia course! When you send messages here, " +
        "they will appear in your message thread and will go to the instructors, but " +
        "they won't go to other students in the class. The instructors can respond " +
        "to you, star your message, or share your message with the whole class. During class you can " +
        "send questions, comments, or responses to questions asked by the instructor. If you'd " +
        "like to see more details, check out Prismia "
      }, {
        insert: "guide",
        attributes: {link: "https://prismia.chat/guide#students"}
      }, {
        insert: "!"
      }, {
        insert: "\n"
      }]}
      const hocket = Hocket([], [JSON.stringify(defaultWelcomeMessage)]);
      this.saveWelcomeHocket(hocket).then(() => {
        this.setState({ welcomeHocketId: hocket.id });
      });
    }
  }

  saveDomainRestriction() {
    const { db } = this.props;
    const { project, domainRestriction } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ domainRestriction }, { merge: true });
  }

  saveRealtime() {
    const { db } = this.props;
    const { project, useRealtimeFeatures } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ useRealtimeFeatures }, { merge: true });
  }

  saveMessageBoard() {
    const { db } = this.props;
    const { project, messageBoard } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ messageBoard }, { merge: true });
  }

  saveObservers() {
    const { db } = this.props;
    const { project, useObservers } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ useObservers }, { merge: true });
  }

  saveAssignment() {
    const { db } = this.props;
    const { project, assignments } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ assignments }, { merge: true });
  }

  saveDrills() {
    const { db } = this.props;
    const { project, drills } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ drills }, { merge: true });
  }

  saveHomepageUrl() {
    const { db } = this.props;
    const { project, homepageUrl } = this.state;
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ homepageUrl }, { merge: true });
  }

  saveCourseLanguage() {
    const { db } = this.props;
    const { project } = this.state;
    let { courseLanguage } = this.state;
    courseLanguage = courseLanguage.toLowerCase();
    if (!(['julia', 'python', 'r', 'c++', 'sagemath', ''].includes(courseLanguage))) {
      window.alert(
        "Language should be Julia, Python, R, SageMath, or C++ (or left blank)"
      );
      return;
    }
    if (!project.id) return;
    db.collection('projects')
      .doc(project.id)
      .set({ courseLanguage }, { merge: true })
      .catch(console.error);
  }

  saveTimezone(city) {
    const { db, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .set({ timeZone: city, timeZoneOffset: moment.tz(city).utcOffset()/60 }, { merge: true })
      .catch(console.error);
  }

  saveTimezoneOffset() {
    const { db } = this.props;
    const { project, timeZoneOffset } = this.state;
    const timeZoneOffsetFloat = parseFloat(timeZoneOffset);
    if (!project.id) return;
    if (isNaN(timeZoneOffsetFloat)) {
      window.alert("Time zone offset should be a number");
      return;
    }
    db.collection('projects')
      .doc(project.id)
      .set({ timeZoneOffset }, { merge: true });
  }

  deleteCourse() {
    const { db, currentUser, projectId, router } = this.props;
    deleteCourse();
    db.collection('projects')
      .doc(projectId)
      .set({ archived: true }, {merge:true})
      .then(() => {
        return db.collection('users')
          .doc(currentUser.id)
          .set({ activeProjectId: null }, {merge: true});
      }).then(() => {
        router.history.push('/chat');
      }).catch(console.error);
  }

  saveWelcomeHocket(hocket) {
    const { db, projectId } = this.props;
    const welcomeHocketId = hocket.id;
    return db.collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge:true})
      .then(() => {
        return db
          .collection('projects')
          .doc(projectId)
          .set({ welcomeHocketId }, {merge:true});
      }).catch(console.error);
  }

  renderWelcomeMessageArea() {
    const { db, projectId } = this.props;
    const { welcomeHocketId } = this.state;
    if (!welcomeHocketId) return null;
    const hocketRef =  db
    .collection('projects')
    .doc(projectId)
    .collection('hockets')
    .doc(welcomeHocketId);
    return (
      <div className="welcome-message-area">
        <h2>Add a Welcome Message</h2>
        <p>
          The welcome message will be sent to all new
          students of your course as soon as they join.
        </p>
        <MessageForm
          db={ db }
          ref={ this.messageFormRef }
          projectId={ projectId }
          hocketId={ welcomeHocketId }
          storage= {this.props.storage}
          hocketRef={ hocketRef }
          placeholder={ "Enter your welcome message here..." }
          hideButtons
          hideOpenResponse
          />
        <Button
          onClick={ () => this.messageFormRef.current.saveHocket() }
          color="primary"
          variant="contained"
          style={{ marginTop: "20px" }}>
          Save
        </Button>
      </div>
    );
  }

  renderWhitelistArea() {
    const { project, domainRestriction } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Domain restriction</h2>
      <p>
        If you'd like to require students joining your course to have an email address associated with a particular domain, you can enter it here. For example, if an instructor wanted to require students to join their class using their <tt>@brown.edu</tt> email address, they would enter <tt>@brown.edu</tt> in the field below.
      </p>
      <Input
        fullWidth
        value = { domainRestriction || '' }
        placeholder={ "Enter an email domain..."}
        onChange={ e => this.setState({domainRestriction: e.target.value})}
      />
      <Button
        onClick={ () => this.saveDomainRestriction() }
        color="primary"
        variant="contained"
        style={{ marginTop: "20px" }}>
        Save
      </Button>
      </div>
    );
  }

  renderLanguageSelection() {
    const { project, courseLanguage } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Language</h2>
      <p>
        If you plan to use the interactive code cells in your course, you can specify your language here.
      </p>
      <Input
        fullWidth
        value = { courseLanguage || '' }
        placeholder={ "Julia, Python, R, SageMath, or C++"}
        onChange={ e => this.setState({courseLanguage: e.target.value})}
      />
      <Button
        onClick={ () => this.saveCourseLanguage() }
        color="primary"
        variant="contained"
        style={{ marginTop: "20px" }}>
        Save
      </Button>
      </div>
    );
  }

  renderHomepageArea() {
    const { project, homepageUrl } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Homepage</h2>
      <p>
        If you list your course homepage URL here, students can access it from their chat window. You can use a shared lesson for this purpose if you don't have an external course homepage.
      </p>
      <Input
        fullWidth
        value = { homepageUrl || '' }
        placeholder={ "Enter a URL..."}
        onChange={ e => this.setState({homepageUrl: e.target.value})}
      />
      <Button
        onClick={ () => this.saveHomepageUrl() }
        color="primary"
        variant="contained"
        style={{ marginTop: "20px" }}>
        Save
      </Button>
      </div>
    );
  }

  renderObserverSelection() {
    const { project } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Observers</h2>
      <p>
        If you want to be able to designate some of the students in your class as observers, check the box. This will allow you to (optionally) focus on students who are non-observers in the classroom.
      </p>
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Designate some students as observers"
          enterDelay={ 400 }><Checkbox checked={this.state.useObservers} color="primary" onChange={(e) => this.setState({useObservers: !this.state.useObservers}, () => this.saveObservers(!this.state.useObservers))} inputProps={{ 'aria-label': 'observers' }}/></Tooltip>}
        label="Use observers"/>
      </FormGroup>
      </div>
    );
  }

  renderRealtimeSelection() {
    const { project } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Optional Classroom Features</h2>
      <p>
        Prismia's classroom can let you know which students are currently typing, and it can display an anonymous list of the partial messages which have not yet been sent. If you want to use these features, check the box.
      </p>
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Use optional classroom features"
          enterDelay={ 400 }><Checkbox checked={this.state.useRealtimeFeatures} color="primary" onChange={(e) => this.setState({useRealtimeFeatures: !this.state.useRealtimeFeatures}, () => this.saveRealtime())} inputProps={{ 'aria-label': 'optional classroom features' }}/></Tooltip>}
        label="Use optional classroom features"/>
      </FormGroup>
      </div>
    );
  }

  renderMessageBoardSelection() {
    const { project } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Message Board</h2>
      <p>
        If you want Prismia's message board feature to be available to students and instructors in this course, check the box.
      </p>
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Use message board for this class"
          enterDelay={ 400 }><Checkbox checked={this.state.messageBoard} color="primary" onChange={(e) => this.setState({messageBoard: !this.state.messageBoard}, () => this.saveMessageBoard())} inputProps={{ 'aria-label': 'message board option' }}/></Tooltip>}
        label="Use message board"/>
      </FormGroup>
      </div>
    );
  }

  renderAssignmentSelection() {
    const { project } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Assignments</h2>
      <p>
        If you want to create assignments through Prismia, check the box.
      </p>
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Use assignments for this class"
          enterDelay={ 400 }><Checkbox checked={this.state.assignments} color="primary" onChange={(e) => this.setState({assignments: !this.state.assignments}, () => this.saveAssignment())} inputProps={{ 'aria-label': 'assignment option' }}/></Tooltip>}
        label="Use assignments"/>
      </FormGroup>
      </div>
    );
  }

  renderDrillSelection() {
    const { project } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Drills</h2>
      <p>
        If you want to create drills through Prismia, check the box.
      </p>
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Use drills for this class"
          enterDelay={ 400 }><Checkbox checked={this.state.drills} color="primary" onChange={(e) => this.setState({drills: !this.state.drills}, () => this.saveDrills())} inputProps={{ 'aria-label': 'drill option' }}/></Tooltip>}
        label="Use drills"/>
      </FormGroup>
      </div>
    );
  }

  openMenu(event) {
    this.setState({ menuOpenRef: event.currentTarget });
  }

  handleClose() {
    this.setState({ menuOpenRef: null });
  }

  renderTimezoneSelector() {
    const { project, timeZone, menuOpenRef } = this.state;
    if (!project) return null;
    return (
      <div className="welcome-message-area">
      <h2>Time Zone</h2>
      <p>
        Select the primary time zone for your course.
      </p>
      <div style={{marginLeft: "8px", marginBottom: "8px"}}><em>{ timeZone ? timeZone : null }</em></div>
      <Button
        onClick={ this.openMenu }>
        { timeZone ? "Change" : "Select" }
      </Button>
      <Menu
        open={ Boolean(menuOpenRef) }
        anchorEl={ menuOpenRef }
        onClose={ this.handleClose }>
        { moment.tz.names().map(name => {
          return (<MenuItem
                   key={name}
                   onClick={() => {
                     this.saveTimezone(name);
                     this.handleClose();
                   }}>
                   { name }
                 </MenuItem>);
        }) }
      </Menu>
      </div>
    );
  }

  render() {
    updateTitleBar('Settings');
    const { project, displayName, description, linkClicked } = this.state;
    const { currentUser={}, projectId, db, router } = this.props;
    if (!project) return null;
    const dangerZone = (
      <div className="welcome-message-area danger-zone">
        <h2>Danger Zone</h2>
        <div className="padded delete-project-area">
          <h3>Delete this Course</h3>
          <p>Once you delete this course, there's no going back. Please be certain.</p>
          <Button
            onClick={ () => this.deleteCourse() }
            variant="contained"
            color="secondary"
            className="delete-course">
            Delete this Course
          </Button>
        </div>
      </div>
    );
    return (
      <div className="project-view">
        <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
        <div style={{
          position: "relative",
          height: "calc(100% - 190px)",
        }}>
          <div className="extra-padded y-scrollable">
          <Button
            variant="contained"
            color="primary"
            className="go-to-classroom centered">
            <Link to={'/projects/' + project.id + '/classroom'}>
              Go to your Classroom
            </Link>
          </Button>
          <div className="welcome-message-area">
            <p className="subtext">Course Title:</p>
            <Input
              key="title"
              fullWidth
              className="project-display-name"
              placeholder="Name"
              value={ displayName || '' }
              onChange={ (e) => this.nameProject(e.target.value) } />
            <p className="subtext">Description:</p>
            <Input
              fullWidth
              className="project-description"
              placeholder="description"
              value={ description || '' }
              onChange={ (e) => this.describeProject(e.target.value) } />
            <div className="save-button-container">
              <Button
                variant="outlined"
                onClick={ () => this.saveProjectDetails() }>
                Save Title and Description
              </Button>
            </div>
          </div>
          <div className="welcome-message-area">
            <h2>Join Link</h2>
            <p>Share this link with your students:
              <Tooltip
                title={ linkClicked ? "copied!": "copy link to clipboard"}
                placement="top">
                <Button
                  color="primary"
                  style={ {marginBottom: "5px"} }
                  onClick={ () => {
                    copyTextToClipboard(`https://prismia.chat/projects/${project.id}/join`);
                    this.setState({ linkClicked: true });
                  } }>
                  { linkClicked ? <CheckIcon/> : <FileCopyIcon size="small"/>}
                </Button>
              </Tooltip>
            </p>
            <div className="shareable-link">
              <tt>https://prismia.chat/projects/{project.id}/join</tt>
            </div>
          </div>
          { this.renderWelcomeMessageArea() }
          { this.renderWhitelistArea() }
          { this.renderHomepageArea() }
          { this.renderRealtimeSelection() }
          { this.renderMessageBoardSelection() }
          { this.renderAssignmentSelection() }
          { this.renderDrillSelection() }
          { this.renderLanguageSelection() }
          { this.renderTimezoneSelector() }
          { this.renderObserverSelection() }
          { currentUser && currentUser.id === project.creatorId ? dangerZone : null }
        </div>
        </div>
      </div>
    );
  }

}

export default ProjectView;
