
const standardToolbar = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],
  ['link', 'image'],
  ['formula'],
];

const sparseToolbar = [
  ['link', 'image', 'video', {align: []}, 'code-block'],
  ['formula', 'code'],
];


// This will overwrite the default binding also named 'tab'
const tab = {
  key: 9,
  handler: function () {
    const currentNode = this.quill.root;
    const allElements = document.getElementsByClassName('ql-editor');
    const currentIndex = [...allElements].findIndex(el => {
      return currentNode.isEqualNode(el);
    });
    const next = allElements[currentIndex + 1];
    if (next && next.focus) next.focus();
  }
};

export const instructorChatQuillModules = {
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
  toolbar: standardToolbar,
}

export const sparseFormQuillModules = {
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
  toolbar: sparseToolbar,
  markdownShortcuts: {},
  blotFormatter: {},
};

export const hocketFormQuillModules = {
  keyboard: {
    bindings: {
      tab,
    },
  },
  toolbar: standardToolbar,
};

export const allQuillModules = {
  toolbar: [
    ['formula'],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ]
};

export const noToolbar = {
  toolbar: false,
};

export const mdShortcutsOnly = {
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
  toolbar: false,
  markdownShortcuts: {},
  blotFormatter: {},
}

export const chatWidgetConfig = {
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
  toolbar: ['link'],
  markdownShortcuts: {},
  blotFormatter: {},
}

export const noToolbarModules = {
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
  toolbar: false,
  markdownShortcuts: {},
  blotFormatter: {},
};
