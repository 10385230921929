import React, { Component } from 'react';
import Students from './Students';
import SimpleAdminNav from '../SimpleAdminNav';
import { updateTitleBar, isAdmin } from '../utils';

class StudentsView extends Component {

  constructor(props) {
    super(props);
    this.studentId = null;
    this.keysPressed = {};
    this.synth = null;
    this.chatRef = null;
    this.unsub = {
      chat: null,
      user: null,
    };
    this.state = {
      instructorDriven: false,
      student: null,
      studentId: null,
      hocketId: null,
      messages: [],
      messageValue: '',
      quillDelta: null,
      instructors: {},
      tas: {},
      observers: {},
    };
  }
  
  componentDidMount() {
    this.setRefs(this.props);
    this.getRoles();
  }
 
  getRoles() {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('instructors')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ instructors: data.userIds });
      }).catch(console.error);
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('tas')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ tas: data.userIds });
      }).catch(console.error);
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('observers')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ observers: data.userIds });
      }).catch(console.error);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { nav } = this.props;
    if (!newProps.nav.match.params.id) return console.log('no nav match id', nav);
    if (newProps.nav.match.params.id === nav.match.params.id) return;
    this.setRefs(newProps);
  }

  setRefs(props) {
    if (this.unsub.chat) this.unsub.chat();
    if (this.unsub.user) this.unsub.user();
    const { db, currentUser, nav } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    if (!nav.match.params.id) return console.log('no nav match id', nav);
    const studentId = nav.match.params.id;
    this.studentId = studentId;
    const ELI_CHAT_ID = '000-0000-000';
    this.chatRef = db
      .collection('users').doc(studentId)
      .collection('chats').doc(ELI_CHAT_ID)
      .collection('messages');
    this.unsub.user = db.collection('users').doc(studentId)
      .onSnapshot(doc => {
        const student = doc.data();
        const { instructorDriven } = student;
        this.setState({ instructorDriven, student, studentId });
      });
  }

  componentWillUnmount() {
    if (this.unsub.user) this.unsub.user();
  }

  renderInfoArea() {
    const { currentUser, projectId } = this.props;
    return (
      <div className="y-scrollable students-view">
        <Students currentUser={ currentUser } db={ this.props.db } projectId={ projectId } />
      </div>
    );
  }

  hide() {
    const { currentUser={} } = this.props;
    const { instructors={}, tas={} } = this.state;
    if (isAdmin(currentUser.id)) return false;
    if (instructors[currentUser.id]) return false;
    if (tas[currentUser.id]) return false;
    return true;
  }

  render() {
    updateTitleBar('Students');
    const { db, nav, projectId, currentUser={} } = this.props;
    if (this.hide()) return null;
    return (
      <div className="students-view">
      <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ nav } /> 
      <div style={{position: "relative", height: "calc(100% - 60px)"}}>
        { this.renderInfoArea() }
      </div>
      </div>
    );
  }

}

export default StudentsView;
