import Hocket from './hocket';

export function serializeHocket(hocket) {
  const h = Object.assign(Hocket(), hocket);
  let tf = [];
  for (let i = 0; i < h.trainingPhrases.length; i++) {
    if (!h.trainingPhrases[i].trim()) continue;
    tf.push(h.trainingPhrases[i].trim());
  }
  h.trainingPhrases = tf;
  let bbUpdates = {};
  // blackboard updates gets transmuted from an array to an object
  for (let i = 0; i < h.blackboardUpdates.length; i++) {
    console.log(h.blackboardUpdates[i]);
    if (!h.blackboardUpdates[i].key.trim()) continue;
    if (!h.blackboardUpdates[i].value.trim()) continue;
    bbUpdates[h.blackboardUpdates[i].key.trim()] = h.blackboardUpdates[i].value.trim();
  }
  h.blackboardUpdates = bbUpdates;
  let res = [];
  for (let i = 0; i < h.responses.length; i++) { 
    if (typeof h.responses[i] === 'string' && h.responses[i].trim() === '""') continue;
    if (typeof h.responses[i] === 'object') {
      h.responses[i] = JSON.stringify(h.responses[i]);
    }
    res.push(h.responses[i]);
  }
  h.responses = res;
  return h;
}

export function deserializeHocket(hocket) {
  let h = Object.assign(Hocket(), hocket);
  const res = [];
  for (let i = 0; i < h.responses.length; i++) { 
    if (!h.responses[i]) continue;
    try {
      res.push(JSON.parse(h.responses[i]));
    } catch (err) {
      console.error(err);
    }
  }
  h.responses = res;
  h.blackboardUpdates = [];
  for (let key in hocket.blackboardUpdates) {
    h.blackboardUpdates.push({ key, value: hocket.blackboardUpdates[key]}); 
  }
  return h;
}

export function serializeQuestion(question) {
  const q = Object.assign({}, question);
  q.questionText = JSON.stringify(q.questionText);
  q.fallback = JSON.stringify(q.fallback);
  for (let key in q.answers) {
    q.answers[key] = serializeHocket(q.answers[key]);
  }
  for (let key in q.support) {
    q.support[key] = serializeHocket(q.support[key]);
  }
  return q;
}

export function deserializeQuestion(question) {
  const q = Object.assign({}, question);
  q.questionText = JSON.parse(q.questionText);
  q.fallback = JSON.parse(q.fallback);
  for (let key in q.answers) {
    q.answers[key] = deserializeHocket(q.answers[key]);
  }
  for (let key in q.support) {
    q.support[key] = deserializeHocket(q.support[key]);
  }
  return q;
}

