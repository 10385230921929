export default function blackboardUpdates(bb={}, updates=[]) {
  for (let i = 0; i < updates.length; i++) {
    const exp = updates[i].trim();
    const parts = exp.split(' ').filter(token => !!token);
    if (parts.length === 1) {
      matchSingletonRule(parts[0]);
    } else if (parts.length === 2) {
      matchPairRule(parts);
    } else if (parts.length === 3) {
      matchTripleRule(...parts);
    } else if (parts.length > 3) {
      matchLongRule(parts[0], parts[1], parts.slice(2).join(' '));
    }
  }
  return bb;
  // helper functions below
  function matchSingletonRule(part) {
    if (part.endsWith('++')) {
      let key = part.substr(0, part.length - 2);
      if (!bb[key]) bb[key] = 0;
      return bb[key] = bb[key] + 1;
    }
    if (part.endsWith('--')) {
      let key = part.substr(0, part.length - 2);
      if (!bb[key]) bb[key] = 0;
      return bb[key] = bb[key] - 1;
    }
    bb[part] = true;
  }
  function matchPairRule(parts) {
    if (!bb[parts[0]]) bb[parts[0]] = false;
    if (parts[1] === '++') {
      if (!bb[parts[0]]) bb[parts[0]] = 0;
      bb[parts[0]] = parseFloat(bb[parts[0]]) + 1;
    }
    if (parts[1] === '--') {
      if (!bb[parts[0]]) bb[parts[0]] = 0;
      bb[parts[0]] = parseFloat(bb[parts[0]]) - 1;
    }
  }
  function matchTripleRule(key, operator, value) {
    if (operator === '=') {
      assignment(key, value);
    } 
  }
  function matchLongRule(key, operator, remainder) {
    if (remainder.startsWith('"') && operator === '=') {
      bb[key] = remainderString(remainder);
    }
  }
  function assignment(key, value) {
    // first coerce
    if (value === 'false') return bb[key] = false;
    if (value === 'true') return bb[key] = true;
    if (value === 'null') return bb[key] = null;
    if (value === 'NaN') return bb[key] = NaN;
    if (value === 'undefined') return bb[key] = undefined;
    if (value.startsWith('"')) return bb[key] = remainderString(value);
    // if all else fails, it's probably a number
    if (bb[value] !== undefined) return bb[key] = bb[value]; 
    return bb[key] = parseFloat(value);
  }
}
                                                                                   
function remainderString(s) {                                                      
  if (s.startsWith('"') && s.endsWith('"')) {                                      
    return s.slice(1, s.length - 1);                                               
  }                                                                                
}                                                                                  
