import React, { Component } from 'react';
import zenscroll from 'zenscroll';
import ReactResizeDetector from 'react-resize-detector';
import ReactQuill from 'react-quill';
import LinkIcon from '@material-ui/icons/Link';
import { Link } from "react-router-dom";
import { CollapsibleJupyterCell } from '../JupyterCell';
import CsvTable from '../CsvTable';
import CommentsArea from '../comments-area/CommentsArea';
import YouTube from 'react-youtube';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import toPlaintext from 'quill-delta-to-plaintext';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { isRegistered, updateTitleBar, touchUpMarkdown, hashString, mousetrapStopCallback, extractCodeBlock, extractBestImage, tryUntilSuccess, isInViewport, removeTerminalNewlinesFromQuillDelta, copyTextToClipboard, pingBinderKernel, getOutline, isNote } from '../utils';
import { exportJupyterNotebook } from '../jupyter';
import { withStyles } from '@material-ui/core/styles';
import InteractiveGraph from '../InteractiveGraph';
import ChatWidget from '../ChatWidget';
import { Lesson } from '../lesson';
import courseLibrary from '../course-library';
import { sharedLessonNext, sharedLessonView} from '../analytics';
import './style.css';
import * as Mousetrap from 'mousetrap';

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

class LessonSharingView extends Component {

  constructor(props) {
    super(props);
    this.clusterKey = 0;
    this.unsub = {
      lesson: null,
      project: null
    };
    this.lessonRef = {};
    this.quillRef = React.createRef();
    this.chatWidgetRef = React.createRef();
    this.hocketsAreaRef = null;
    this.messagesAreaRef = React.createRef();
    this.handleCopy = this.handleCopy.bind(this);
    this.handleSvg = this.handleSvg.bind(this);
    this.state = {
      hockets: {},
      lesson: null,
      menuOpen: false,
      menuOpenRef: React.createRef(),
      hocketSelectionRange: null,
      activeHocketId: null,
      projectsOwned: {},
      instructorOf: {},
      taOf: {},
      showHelp: false,
      hamburgerMenuOpen: false,
      currentMessageId: null,
      userMessages: new Map(),
      automaticReplies: new Map(),
      showEditorTool: true,
      cursorPosition: 0,
      codeCell: 'julia',
      displayMode: null,
      lastBinderPing: new Date(),
      copyMode: false,
      scrollHeight: 0,
      viewLogged: false,
      videoPlayer: null,
      hideVideo: false,
      currentSection: null,
      outline: [],
      minHeaderDepth: null,
      activeCommentHocket: null,
      showComments: false,
      comments: {},
    };
  }

  subComments(props, projectId) {
    if (this.unsub.comments) this.unsub.comments();
    const { db, currentUser, lessonId } = props;
    if (!currentUser || !lessonId) return;
    this.commentRef = db
      .collection('projects')
      .doc(projectId)
      .collection('shared-chats')
      .doc(lessonId)
      .collection('users')
      .doc(currentUser.id)
      .collection('comments');
    this.unsub.comments = this.commentRef
      .where('archived', '==', false)
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const docs = snap.docs.map(doc => doc.data());
        const comments = {};
        for (let doc of docs) {
           if (comments[doc.parentId]) {
             comments[doc.parentId].push(doc);
           } else {
             comments[doc.parentId] = [doc];
           }
        }
        this.setState({ comments });
      });
  }

  setCopyMode(setting) {
    if (!setting) {
      this.setActiveHocketId(null);
      this.setState({ hocketSelectionRange: null });
    }
    this.setState({ copyMode: setting });
  }

  setHideVideoMode(setting) {
    this.setState({ hideVideo: setting });
    setTimeout(() => this.paneDidMount(this.messagesAreaRef), 100)
  }

  lookupProjectId() {
    const { db, lessonId } = this.props;
    return db.collection('meta')
      .doc('lessons-table')
      .collection('ids')
      .where('id', '==', lessonId)
      .get()
      .then(snap => {
        const data = snap.docs?.[0].data();
        if (data) {
          this.setState({ projectId: data.project });
          return data.project;
        }
      })
      .catch(console.error);
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }


  componentDidMount() {
    const { currentUser, lessonId } = this.props;
    const { viewLogged } = this.state;
    if (this.props.gallery) {
      this.getMathletMessages();
      this.setMousetrap();
      this.setState({ displayMode: true });
      window.addEventListener('copy', this.handleCopy);
      if (currentUser && !viewLogged) {
        sharedLessonView(currentUser.id, 'mathlet-gallery'); // analytics
        this.setState({ viewLoged: true });
      }
      return;
    }
    this.lookupProjectId().then( (projectId) => {
        this.subComments(this.props, projectId);
        return this.subLesson(projectId, this.props);
      }).then( () => {
        this.handleMessageUrl();
        this.setMousetrap();
      }).catch(console.error);
    this.subCourses();
    window.addEventListener('copy', this.handleCopy);
    if (currentUser && !viewLogged) {
      sharedLessonView(currentUser.id, lessonId); // analytics
      this.setState({ viewLogged: true });
    }
    this.checkPCL();
  }

  checkPCL() {
    const { lessonId } = this.props;
    let isPCL = false;
    for (let course of courseLibrary) {
      if (course.url === 'shared/' + lessonId) {
        isPCL = true;
      }
    }
    this.setState({ isPCL });
  }

  paneDidMount(ref) {
    if (!ref.current) return;
    const defaultDuration = 600;
    const edgeOffset = 100;
    this.chatScroller = zenscroll.createScroller(
      ref.current, defaultDuration, edgeOffset
    );
  }

  handleMessageUrl() {
    const { location } = this.props;
    let pathComponents = location.pathname.split('/shared/')?.[1]?.split('/');
    if (!pathComponents) pathComponents = location.pathname.split('/blog/')?.[1]?.split('/');
    if (pathComponents && pathComponents.length > 1) {
      const urlMessageId = pathComponents[1];
      this.setState({ 
        showComments: true, // usually we'll want comments with this feature
        urlMessageId,
      });
      this.subHocket(urlMessageId);
      tryUntilSuccess(() => {
        const { lesson } = this.state;
        let urlMessageId = pathComponents[1];
        if (urlMessageId.endsWith('-reply')) {
          urlMessageId = urlMessageId.slice(0, -6);
        }
        let urlMessageIndex = -1;
        if (lesson && !this.incremented && this.state.displayMode === false) {
          urlMessageIndex = lesson.hockets
            .findIndex(stub => stub.hocketId === urlMessageId);
          if (0 < urlMessageIndex && 
              this.messageIndex() < urlMessageIndex) {
            // if we're not there yet, just show them all. You'll need 
            // to reset anyway
            this.incrementMessageIndex(true, false, false);
            if (this.userChatRef) { this.incremented = true };
          }
        }
        if (urlMessageIndex > -1) {
          const correctSection = this.getCurrentTopHeader(urlMessageIndex);
          if (correctSection.id) this.setState({ currentSection: correctSection });
        }
        const element = document.getElementById(pathComponents[1]);
        if (!this.chatScroller) this.paneDidMount(this.messagesAreaRef);
        if (this.chatScroller && element) {
          this.chatScroller.center(element);
        }
        return element && isInViewport(element) && window.loadingPrismiaMessages === false;
      }, {wait: 250});
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    const { projectId } = this.state;
    if (this.props.gallery) return false;
    if ( currentUser && !this.userChatRef) {
      if (projectId) {
        this.subChat(projectId);
      } else {
        this.lookupProjectId().then( (projectId) => {
          this.subChat(projectId) 
        });
      }
    }
    if ( currentUser && !this.commentRef) {
      if (projectId) {
        this.subComments(this.props, projectId);
      } else {
        this.lookupProjectId().then( (projectId) => {
          this.subComments(this.props, projectId);
        });
      }
    }
  }

  adjustActiveHocket(increment) {
    const { activeHocketId, lesson } = this.state;
    const { hockets=[] } = lesson;
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < hockets.length)) {
      this.setActiveHocketId(hockets[newIndex].hocketId);
    }
  }

  adjustSelectionEnd(increment) {
    const { activeHocketId,
            lesson,
            hocketSelectionRange
    } = this.state;
    const { hockets=[] } = lesson;
    const currentIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < hockets.length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  setMousetrap() {
    Mousetrap.bind("down", () => this.incrementMessageIndex());
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind('esc', () => {
      this.setState({showHelp: false});
      this.setActiveHocketId(null);
    });
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  mousetrapUnset() {
    Mousetrap.unbind("up");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("esc");
  }

  getMathletMessages() {
    const { db, lessonId } = this.props;
    console.log(lessonId)
    const PCL_ID = '682b9146-131e-4a39-8577-0a708cc6c2ac';
    db.collection("projects")
      .doc(lessonId || PCL_ID)
      .collection('shared-hockets')
      .where('jessieCode', '!=', '')
      .get()
      .then(snap => {
        const hockets = {};
        for (let doc of snap.docs) {
          const data = doc.data();
          if (data.galleryExclude) continue;
          hockets[data.id] = data;
        }
        const promises = [];
        if (!lessonId) {
          for (let course of courseLibrary) {
            promises.push(db.collection('projects')
            .doc(PCL_ID)
            .collection('shared-lessons')
            .doc(course.url.split("/")[1])
            .get()
            .then(snap => {
              const data = snap.data();
              return { 
                title: course.title, 
                url: course.url,
                hocketIds: data.hockets.map(stub => stub.hocketId),
              };
            })
            .catch(console.error));
          }
        } else {
          promises.push(db.collection('projects')
            .doc(lessonId)
            .collection('shared-lessons')
            .orderBy('title')
            .get()
            .then(snap => {
              const results = [];
              for (let doc of snap.docs) {
                const data = doc.data();
                results.push({ 
                  title: data.title, 
                  url: data.id,
                  hocketIds: data.hockets.map(stub => stub.hocketId) || [],
                });
              }
              return results;
            })
            .catch(console.error));
        }
        Promise.all(promises).then((courses) => {
          if (!courses.length) return;
          if (lessonId) courses = courses[0];
          const lesson = { hockets: [] };
          const outline = [];
          let hocketCount = 0;
          let veryFirstMessageId = null;
          for (let course of courses) {
            let firstHocket = null;
            for (let hocketId of course.hocketIds) {
              if (hockets[hocketId]) {
                if (!veryFirstMessageId) {
                  veryFirstMessageId = hocketId;
                }
                if (!firstHocket) {
                  firstHocket = hocketId;
                  outline.push({
                    title: course.title,
                    id: firstHocket,
                    depth: 1,
                    index: hocketCount,
                  });
                }
                lesson.hockets.push({ hocketId });
                const hocket = hockets[hocketId];
                const quillDelta = JSON.parse(hocket.responses[0]);
                quillDelta.ops.push({
                  insert: "\n",
                });
                quillDelta.ops.push({
                  insert: "[link to original message]",
                  attributes: { link: 
                    `https://prismia.chat/${course.url}/${hocket.id}`,
                  },
                });
                quillDelta.ops.push({
                  insert: "\n",
                  attributes: { align: "center"},
                });
                hocket.responses = [JSON.stringify(quillDelta)];
                hockets[hocketId] = hocket;
                hocketCount++;
              }
            }
          }
          lesson.title = "Mathlet Gallery";
          lesson.description = "All of the mathlet messages in the Prismia Course Library";
          if (lessonId) {
            lesson.description = "Mathlet messages";
          }
          this.setState({ 
            hockets,
            lesson,
            outline,
            minHeaderDepth: 1,
            currentMessageId: veryFirstMessageId,
          });
        })
      })
      .catch(console.error);
  }

  subLesson(projectId, props) {
    if (this.unsub.lesson) this.unsub.lesson();
    const { db, lessonId } = props;
    try {
      this.lessonRef = db
        .collection('projects')
        .doc(projectId)
        .collection('shared-lessons')
        .doc(lessonId);
    } catch (err) {
      alert("We don't seem to have that shared lesson");
      console.log(err);
      return;
    }
    this.unsub.lesson = this.lessonRef
      .onSnapshot(snap => {
        const sourceLesson = snap.data();
        if (!sourceLesson) {
          return;
        };
        const lesson = {...sourceLesson};
        lesson.hockets = sourceLesson.hockets.filter( hocket => !hocket.note );
        this.setState({ lesson,
                        codeCell: lesson.codeCell || null,
                        displayMode: lesson.displayMode || false }, () => {
          if (this.state.displayMode) {
            const [lastHocket] = this.state.lesson.hockets.slice(-1);
            this.setState({ currentMessageId: lastHocket.hocketId });
          } else if (!this.state.currentMessageId) {
            const [firstHocket] = this.state.lesson.hockets.slice(0);
            this.setState({ currentMessageId: firstHocket.hocketId });
          }
        });
      });
  }

  subHocket(hocketId) {
    const { db } = this.props;
    const { projectId } = this.state;
    if (!projectId || !hocketId) return;
    if (this.unsub[hocketId]) return;
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('shared-hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
        this.getOutline();
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    window.removeEventListener('copy', this.handleCopy);
    this.mousetrapUnset();
  }

  setActiveHocketId(activeHocketId) {
    this.setState({ activeHocketId,
                    hocketSelectionRange: null});
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, lesson } = this.state;
    const { hockets } = lesson;
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  handleCopy(event) {
    const { copyMode, hocketSelectionRange } = this.state;
    if (copyMode && document.activeElement === this.hocketsAreaRef) {
      let numMessages = 1;
      if (hocketSelectionRange) {
        numMessages = Math.abs(hocketSelectionRange[1] - hocketSelectionRange[0]) + 1;
      }
      const [jsonData, html, md] = this.copyHockets();
      event.clipboardData.setData('application/json',
      jsonData);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', md);
      NotificationManager.info(`${numMessages} message${numMessages === 1 ? "" : "s"} copied!`);
      event.preventDefault();
    }
  }

  copyHockets() {
    const {
      activeHocketId,
      hockets,
      hocketSelectionRange,
      lesson,
    } = this.state;
    const hocketIds = lesson.hockets;
    const messagesToCopy = []
    let start, stop;
    let html = '';
    let md = '';
    if (hocketSelectionRange) {
      [start, stop] = [...hocketSelectionRange].sort((a,b) => a-b);
      for (let i = start;
               i <= stop;
               i++) {
        const currentHocket = hockets[hocketIds[i].hocketId];
        if (currentHocket && currentHocket.responses.length > 0) {
          const message = currentHocket.responses[0];
          const suggestions = currentHocket.suggestions;
          const openResponse = currentHocket.openResponse; 
          const tableData = currentHocket.tableData || {string: ''};
          const jessieCode = currentHocket.jessieCode || '';
          const aspectRatio = currentHocket.aspectRatio || "100%";
          const urlIFrame = currentHocket.urlIFrame;
          const heightIFrame = currentHocket.heightIFrame;
          messagesToCopy.push({ message, suggestions, openResponse, tableData, jessieCode, aspectRatio, urlIFrame, heightIFrame });

          const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
          html += converter.convert();

          md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
        }
      }
    } else {
      const activeHocket = hockets[activeHocketId];
      if (activeHocket && activeHocket.responses.length > 0) {
        const message = activeHocket.responses[0];
        const suggestions = activeHocket.suggestions;
        const openResponse = activeHocket.openResponse;
        const jessieCode = activeHocket.jessieCode || '';
        const aspectRatio = activeHocket.aspectRatio || "100%";
        messagesToCopy.push({ message, suggestions, openResponse, jessieCode, aspectRatio });

        const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
        html += converter.convert();

        md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
      }
    }
    return [JSON.stringify(messagesToCopy), html, md];
  }

  openMenu() {
    this.setState({ menuOpen: true});
  };

  handleClose() {
    this.setState({ menuOpen: false });
  };

  subChat(projectId) {
    const { db, currentUser, lessonId, gallery } = this.props;
    if (gallery) return;
    if (this.unsub.chat) this.unsub.chat();
    this.userChatRef = db
      .collection('users').doc(currentUser.uid || currentUser.id)
      .collection('shared-chats').doc(projectId)
      .collection('lessons').doc(lessonId);
    this.lessonChatRef = db
       .collection('projects').doc(projectId)
       .collection('shared-chats').doc(lessonId);
    this.unsub.currentMessage = this.userChatRef
      .onSnapshot(snap => {
        const data = snap.data()
        if (data?.currentMessageId) {
          this.setState({ currentMessageId: this.messageIndex(data.currentMessageId) >= 0 ? data.currentMessageId : this.state.lesson.hockets[0].hocketId})
        }
      });
    this.unsub.chat = this.userChatRef
      .collection('messages')
      .onSnapshot(snap => {
        const userMessages = new Map();
        const automaticReplies = new Map();
        snap.forEach(doc => {
          const dc = doc.data();
          if (dc.currentMessageIndex) { // legacy
            const currentMessage = this.state.lesson.hockets[dc.currentMessageIndex];
            if (currentMessage) {
              const currentMessageId = currentMessage.hocketId;
              this.setState({ currentMessageId });
            } else {
              this.setState({
                currentMessageId: this.state.lesson.hockets[0].hocketId
              });
            }
          } else {
            if (dc.reply) automaticReplies.set(dc.parent, JSON.parse(dc.reply));
            if (dc.parent && dc.quillDelta) {
              userMessages.set(dc.parent, JSON.parse(dc.quillDelta));
            }
          }
        });
        this.setState({ userMessages, automaticReplies });
    });
  }

  subCourses() {
    this.subOwned();
    this.subTaOf();
    this.subInstructorOf();
  }

  subOwned() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) {
      return null;
    }
    this.unsub.coursesOwned = db
      .collection('meta')
      .doc('roles')
      .collection('owners')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || {};
        const projects = data.projects || [];
        for (let i = 0; i < projects.length; i++) {
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { projectsOwned } = this.state;
                projectsOwned[project.id] = project;
                this.setState({ projectsOwned });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  subTaOf() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) return null;
    this.unsub.taOf = db
      .collection('meta')
      .doc('roles')
      .collection('tas')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        let data = snap.data() || [];
        const projects = Object.keys(data);
        for (let i = 0; i < projects.length; i++) {
          if (!data[projects[i]]) continue;
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { taOf } = this.state;
                taOf[project.id] = project;
                this.setState({ taOf });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  subInstructorOf() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) return null;
    this.unsub.instructorOf = db
      .collection('meta')
      .doc('roles')
      .collection('instructors')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || [];
        const projects = Object.keys(data);
        for (let i = 0; i < projects.length; i++) {
          if (!data[projects[i]]) continue;
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { instructorOf } = this.state;
                instructorOf[project.id] = project;
                this.setState({ instructorOf });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  renderProjectCard(project, owner=false) {
    return (
      <MenuItem
        value={ project.id }
        key={project.id}
        onClick={ () => this.copyIntoProject(project) }>
        { project.displayName }
      </MenuItem>
    );
  }

  helpInfo(hasVideo) {
    const mobile = window.document.body.clientWidth < 500;
    const AboutButton = withStyles({
      root: {
        color: "white",
        border: "1px solid white",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        marginBottom: "20px",
      }
    })(Button);
    const button = <AboutButton
        className="centered"
        onClick={ () => this.setState({showHelp: false})}
        variant="outlined">
        Back
    </AboutButton>;
    const style = {height: "72%"};
    if (mobile) {
      style.minWidth = "auto";
      style.left = "10%";
      style.transform = "translate(-5%, 0%)";
      style.top = "50px";
    }
    return (<div className="help-info" style={ style }>
      <h1>Prismia</h1>
      <p>Welcome! Prismia is a chat app designed for the classroom. This page is a public view of a <em>lesson</em> (a script of prepared messages) which was shared by the person who created it.</p>
       { this.state.displayMode ? null : <><p>Work through this lesson one message at a time, answering questions text box at the bottom of the screen (or the buttons that pop up, for multiple choice). The lesson author will be able to review your answers. Just like with an ordinary chat conversation, messages you send are committed to the thread. You can also make side comments which are visible to the instructor and which can be deleted.</p> 
       <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Show side comments"
          enterDelay={ 400 }><WhiteCheckbox checked={this.state.showComments} color="default" onChange={(e) => this.setState({ showComments: e.target.checked})} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
        label="Show side comments"/>
       </FormGroup>
      </>}
      <p>You can copy messages from this page, edit them, and use them in your own class. Click the Import button in the menu to get started, or check the box below so you can copy messages using your browser's copy action (command-C or Edit {'>'} Copy). </p>
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Make messages copyable"
          enterDelay={ 400 }><WhiteCheckbox checked={this.state.copyMode} color="default" onChange={(e) => this.setCopyMode(!this.state.copyMode)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
        label="Copy Mode"/>
      </FormGroup>
      { hasVideo ? <p>To hide the video, check the box below.</p> : null }
      { hasVideo ?
      <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
        <FormControlLabel
          control={ <Tooltip
          title="Hide the video"
          enterDelay={ 400 }><WhiteCheckbox checked={this.state.hideVideo} color="default" onChange={(e) => this.setHideVideoMode(!this.state.hideVideo)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
        label="Hide Video"/>
      </FormGroup> : null }
      <p>To use the copy-paste approach, click a message and then shift-click a second message to highlight a range of messages. Copied messages may be pasted as Markdown into a text editor or pasted into Prismia on the lesson editing page.</p>
      <p>Still another option is to export the messages on this page as a Jupyter notebook:</p>
        <AboutButton
          variant="outlined"
          size="small"
          onClick={ () => this.exportJupyterNotebook() }>
          Jupyter Export
        </AboutButton>
      <p>To learn more about Prismia, visit the <a href="/">homepage</a>!</p>
      { button }
    </div>);
  }

  copyIntoProject(project) {
    const { db, router, currentUser } = this.props;
    const { lesson } = this.state;
    const { title='', description='', hockets=[], youTubeId='' } = lesson;
    // Copy hockets from state into
    // viewing user's database:
    Object.values(this.state.hockets).forEach( hocket => {
      db.collection('projects')
        .doc(project.id)
        .collection('hockets')
        .doc(hocket.id)
        .set(hocket);
    })
    const creator = currentUser || {};
    const newLesson = Lesson(title, description, youTubeId, creator,
                             project.id, hockets);
    db.collection('projects')
      .doc(project.id)
      .collection('lessons')
      .doc(newLesson.id)
      .set(newLesson);
    router.history.push("/projects/" + project.id + "/lessons");
  }

  renderProjectSelect() {
    let { menuOpen=false, taOf={}, instructorOf={}, projectsOwned={}, projectId } = this.state;
    let instructed = {};
    let taed = {};
    for (let key in taOf) {
      if (!taOf[key]) continue;
      if (!projectsOwned[key] && !instructorOf[key]) taed[key] = taOf[key];
    }
    for (let key in instructorOf) {
      if (!instructorOf[key]) continue;
      if (!projectsOwned[key]) instructed[key] = instructorOf[key];
    }
    projectsOwned = Object.keys(projectsOwned).map(id => {
      return projectsOwned[id];
    });
    instructorOf = Object.keys(instructed).map(id => instructed[id]);
    taOf = Object.keys(taed).map(id => taed[id]);
    return (
      <Menu
        open={ menuOpen }
        onClose={ () => this.handleClose() }
        anchorEl={ document.getElementsByClassName("window-top-right")[0] }
        id={ projectId }>
        { projectsOwned.map(project => {
            if (project.archived) return null;
            return this.renderProjectCard(project, true);
          })
        }
        { taOf.map(project => {
            if (project.archived) return null;
            return this.renderProjectCard(project, true);
          })
        }
        { instructorOf.map(project => {
            if (project.archived) return null;
            return this.renderProjectCard(project, true);
          })
        }
      </Menu>
    );
  }

  isInSelectionRange(hocketId) {
    const { lesson, hocketSelectionRange } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = lesson;
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  renderTable(data) {
    return <CsvTable data={data}/>;
  }

  renderJSXGraph(hocketId, jessieCode, ratio) {
    return (
      <InteractiveGraph
        key={ 'interactive-graph-' + hashString(hocketId + jessieCode) }
        code={ jessieCode }
        width={ "90%" }
        ratio={ ratio || "100%"}
        handleSvg={ this.handleSvg }/>
    );
  }

  handleSvg(svg) {
    if (this.chatWidgetRef.current) {
      this.chatWidgetRef.current.saveSketch(svg, true);
    }
  }

  renderSuggestions(suggestions, active) {
    if (suggestions.length === 0) return null;
    return <div className="suggestion-chips">
        { suggestions.map((sug, i) => {
          let val = '';
          if (typeof sug === 'string') val = sug;
          if (sug.value) val = sug.value;
          if (!sug.value) return null;
          if (!sug.value.trim()) return null;
          return (<button
            className={ "no-outline" + (active ? "" : " inactive") }
            onClick={ () => {
              if (active) {
                this.addMessage(val);
                this.goToNextMessage(sug.reply);
              }
            } }
            key={ 'suggestion' + val + i }>
            { val }
          </button>); }) }
      </div>;
  }

  setActiveCommentHocket(id) {
    this.setState({ activeCommentHocket: id })
  }

  renderHocketCard(hocketId, width) {
    const { db, currentUser } = this.props;
    const { currentMessageId, automaticReplies, activeHocketId, hockets,
            codeCell, copyMode, activeCommentHocket, comments, showComments } = this.state;    
    if (!hocketId) return null;
    if (!hockets[hocketId]) {
      this.subHocket(hocketId);
      return null;
    }
    const hocket = hockets[hocketId];
    const style = {background: '#fff'};
    if (this.state.urlMessageId === hocket.id) {
      style.border = '3px solid teal';
    }
    if (copyMode && hocket.id === activeHocketId) {
      style.background = 'rgb(169, 216, 225)'; // light teal
    }
    if (this.isInSelectionRange(hocket.id)) {
      style.background = '#fff3a8' // light yellow
    }
    const quillDelta = hocket.responses[0] ? JSON.parse(hocket.responses[0]) : "";
    let onClickProp = {onClick: (e) => {
      if (e.shiftKey) {
        this.setSelectionEnd(hocket.id);
      } else {
        this.setActiveHocketId(hocket.id);
      }
    }};
    if (!this.state.copyMode) onClickProp = {};
    const commentsArea = showComments ? <CommentsArea
      db={ db }
      width={ width }
      currentUser={ currentUser }
      comments={ comments?.[hocket.id] }
      parentId={ hocket.id }
      commentRef={ this.commentRef }
      activeCommentHocket={ activeCommentHocket }
      setActiveCommentHocket={ (id) => this.setActiveCommentHocket(id) }
    /> : null;
    const linkIcon = <div 
        className={"message-link" + (this.state.linkCopied === hocket.id ? " copied": "")} 
        onClick={ () => {
          copyTextToClipboard("https://prismia.chat" + this.props.location.pathname.split("/").slice(0, 3).join("/") + "/" + hocket.id );
          this.setState({linkCopied: hocket.id});
      }}>
        { this.state.linkCopied === hocket.id ? "copied!" : <LinkIcon fontSize="small"/> }
      </div>;
    return (
        <React.Fragment
          key={ hocket.id }>
          <div
            style={ style }
            id={ hocket.id }
            className="shared-lesson-card"
            {...onClickProp}>
            { commentsArea }
            { linkIcon }
            <ReactQuill
             readOnly
             modules={ { toolbar: false } }
             value={ quillDelta }/>
            { hocket.jessieCode && hocket.jessieCode.length ?
                this.renderJSXGraph(hocket.id, hocket.jessieCode, hocket.aspectRatio) : null }
            { (hocket.tableData?.length > 0 || hocket.tableData?.string?.length) ? this.renderTable(typeof hocket.tableData === 'string' ? {string: hocket.tableData} : hocket.tableData) : null }
            { hocket.codeCell ?
              <div className="on-card-code-cell"> <CollapsibleJupyterCell content={ extractCodeBlock(quillDelta)} language={ codeCell || 'julia'} setLang={ (lang) => this.setState({ codeCell: lang })}/></div> : null }
           </div>
           { hocket.suggestions && !this.props.gallery ? this.renderSuggestions(hocket.suggestions,
             currentMessageId === hocket.id && !automaticReplies.has(currentMessageId) ) : null }
        </React.Fragment>
    );
  }

  messageIndex(msgId) {
    const { lesson, currentMessageId } = this.state;
    if (!lesson) return 0;
    const { hockets=[] } = lesson;
    if (!msgId) msgId = currentMessageId;
    return hockets.findIndex(hocket => hocket.hocketId === msgId);
  }

  goToNextMessage(reply) {
    const { currentMessageId } = this.state;
    if (!reply) {
      this.incrementMessageIndex();
    } else {
      setTimeout( () => {
        const { automaticReplies } = this.state;
        automaticReplies.set(currentMessageId, JSON.parse(reply));
        this.setState({ scrollHeight: this.messagesAreaRef.current.scrollHeight }, () => {
          if (!this.userChatRef) {
            this.setState({ automaticReplies }, () => {
              this.scrollBottom();
            });
          } else {
            this.userChatRef.collection('messages').doc(currentMessageId).set(
              { reply }, { merge: true }).then( () => {
              this.scrollBottom();
            }).then().catch(console.log);
          }
        });
      }, 600 );
    }
    this.pingBinderKernel();
  }

  scrollCurrentMessage(all, whichtry=0) {
    if (!this.chatScroller) this.paneDidMount(this.messagesAreaRef);
    if (all) return;
    setTimeout( () => {
      const { currentMessageId } = this.state;
      if (this.chatScroller) {
        const currentMessage = document.getElementById(currentMessageId);
        if (!currentMessage) {
          whichtry++;
          if (whichtry < 3) this.scrollCurrentMessage(all, whichtry);
          return;
        }
        this.chatScroller.to(currentMessage);
        // followup scroll to allow for slow-loading images:
        setTimeout(() => this.chatScroller.to(currentMessage), 600);
      }
    }, 80);
  }

  scrollBottom(all) {
    if (all) return;
    if (!this.chatScroller) this.paneDidMount(this.messagesAreaRef);
    setTimeout( () => {
      if (this.chatScroller) {
        console.log(this.messagesAreaRef);
        this.chatScroller.toY(this.messagesAreaRef.current.scrollHeight)
      }
    }, 80);
  };

  incrementMessageIndex(all = false, restart = false, scroll = true) {
    const { currentUser, lessonId } = this.props;
    const { lesson, currentSection } = this.state;
    if (currentSection && !restart && !all) return;
    const { hockets=[] } = lesson;
    const currentMessageIndex = this.messageIndex();
    this.setState({ allShown: all });
    let idx = all ? hockets.length - 1 : Math.min(currentMessageIndex + 1, hockets.length - 1);
    if (all && restart) {
      this.setState({allShown: false, currentSection: null});
      idx = 0;
    } else if (all) {
      const currentSection = this.getCurrentTopHeader(currentMessageIndex);
      if (currentSection.id) this.setState({ currentSection });
    }
    // load one message ahead from the database, for performance:
    if (currentMessageIndex < hockets.length - 1) {
      const nextHocketId = hockets[currentMessageIndex + 1].hocketId;
      if (!this.state.hockets[nextHocketId]) {
        this.subHocket(nextHocketId);
      }
    }
    if (!this.userChatRef) {
      this.setState({ scrollHeight: this.messagesAreaRef.current.scrollHeight }, () => {
        this.setState({ currentMessageId: hockets[idx].hocketId }, () => {
          this.scrollCurrentMessage(all);
          this.checkAutoOpenCanvas();
        });
      })
    } else {
      this.setState({ scrollHeight: this.messagesAreaRef.scrollHeight }, () => {
        this.userChatRef.set({
          currentMessageId: hockets[idx].hocketId,
        }, {merge: true}).then( () => {
          if (scroll) {
            this.scrollCurrentMessage(all);
            this.checkAutoOpenCanvas();
          }
        }).catch(console.error);
      });
    }
    if (currentUser) sharedLessonNext(currentUser.id, lessonId); // analytics
  }

  checkAutoOpenCanvas() {
    const { currentMessageId, hockets } = this.state;
    const currentHocket = hockets[currentMessageId];
    if (!currentHocket) return;
    const quillDelta = JSON.parse(currentHocket.responses[0]);
    const text = toPlaintext(quillDelta.ops);
    if (text.includes('✏')) {
      const img = extractBestImage(currentHocket);
      if (img) {
        if (this.chatWidgetRef.current) {
          setTimeout( () => {
            this.chatWidgetRef.current.kickOpenCanvas(img);
          }, 4000);
        }
      }
    }
  }

  scrollToMessage(messageId, delay) {    
    setTimeout( () => {
      if (!this.chatScroller) this.paneDidMount(this.messagesAreaRef);
      const message = document.getElementById(messageId);
      if (message) {
        this.chatScroller.to(message);
      }
    }, delay);
  }

  renderHocketsArea(width) {
    const { db, currentUser } = this.props;
    const { lesson={}, hockets={}, userMessages, automaticReplies, outline, currentSection, comments, showComments, activeCommentHocket, currentMessageId } = this.state;
    let hocketStubs = lesson.hockets;
    const hocketCards = [];
    let currentMessageIndex = this.messageIndex(currentMessageId);
    const currentTopHeader = this.getCurrentTopHeader(currentMessageIndex);
    const combinedCurrentSection = currentSection ? currentSection : currentTopHeader;
    const outlineTop = outline.filter(h => h.depth === this.state.minHeaderDepth);
    let startingIndex = combinedCurrentSection.index;
    let stoppingIndex = this.messageIndex() + 1;
    if (currentSection) {
      stoppingIndex = outlineTop[outlineTop.findIndex(h => h.id === currentSection.id) + 1]?.index;
    }
    const numDisplayedSections = outlineTop.findIndex(h => h.id === currentTopHeader.id);
    const outlineArea = ((this.state.displayMode || outlineTop.length < 2) && !this.props.gallery) ? null : <div className="lesson-outline">
        <ul>
          { 
            outlineTop
              .map((h, i) => 
                <li 
                  key={ h.id }
                  className={ h.id === combinedCurrentSection.id ? "active" : ( i > numDisplayedSections ? "not-there-yet" : "" )}
                  onClick={() => {
                    if ( h.id === combinedCurrentSection.id ) {
                      return;
                    }
                    if (i < numDisplayedSections) {
                      this.setState({
                        currentSection: {
                          id: h.id,
                          index: h.index, 
                        }
                      }, () => {
                        const newMessageId = this.state?.lesson?.hockets?.[h.index]?.hocketId;
                        this.scrollToMessage(newMessageId, 400);
                      });
                    } else if (i === numDisplayedSections) {
                      this.setState({ currentSection: null }, () => {
                        this.scrollToMessage(this.state.currentMessageId, 400);
                      });
                    } else {
                      const newMessageId = this.state?.lesson?.hockets?.[h.index]?.hocketId;
                      if (newMessageId) {
                        this.setState({ 
                          currentSection: null,
                          currentMessageId: newMessageId 
                        }, () => { this.scrollToMessage(newMessageId, 400) });
                      }
                    }
                   }}> 
                  { h.title }
                </li>
            )
          }
        </ul>
    </div>;
    if (!outlineArea) startingIndex = 0;
//    if (this.props.gallery) stoppingIndex = this.state.lesson.hockets.length;
    let loadingPrismiaMessages = false;
    for (let i = startingIndex; i < stoppingIndex; i++) {
      if (!hockets[hocketStubs[i].hocketId]) loadingPrismiaMessages = true;
      hocketCards.push(this.renderHocketCard(hocketStubs[i].hocketId, width));
      if (userMessages.has(hocketStubs[i].hocketId)) {
        const parentId = hocketStubs[i].hocketId + '-reply';
        const commentsArea = showComments ? <CommentsArea
          db={ db }
          width={ width }
          currentUser={ currentUser }
          comments={ comments?.[parentId] }
          parentId={ parentId }
          commentRef={ this.commentRef }
          activeCommentHocket={ activeCommentHocket }
          setActiveCommentHocket={ (id) => this.setActiveCommentHocket(id) }
          /> : null;
        hocketCards.push(
          <div
            className="shared-lesson-card from-student"
            key={ hocketStubs[i].hocketId + 'response' }
            id={ hocketStubs[i].hocketId + '-reply' }>
            { commentsArea }
            <ReactQuill
              readOnly
              modules={ { toolbar: false } }
              value={ userMessages.get(hocketStubs[i].hocketId) }/>
          </div>);
      }
      if (automaticReplies.has(hocketStubs[i].hocketId)) {
        hocketCards.push(
          <div
            className="shared-lesson-card"
            key={ hocketStubs[i].hocketId + 'automatic-reply' }>
            <ReactQuill
              readOnly
              modules={ { toolbar: false } }
              value={ automaticReplies.get(hocketStubs[i].hocketId) }/>
          </div>);
      }
    }
    window.loadingPrismiaMessages = loadingPrismiaMessages;
    const NextButton = withStyles({
      root: {
        color: "#777",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "25px",
        marginTop: "25px",
        display: "block",
      },
    })(Button);
    let nextButton = null;
    if (currentSection && !this.state.displayMode) {
      nextButton = <NextButton
        variant="outlined"
        className="next-button"
        onClick={ () => {
          const { currentMessageId } = this.state;
          const nextSection = outlineTop[outlineTop.findIndex(section => section.id === currentSection.id) + 1];
          let currentMessageIndex = this.messageIndex(currentMessageId);
          const currentTopHeader = this.getCurrentTopHeader(currentMessageIndex);
          const numDisplayedSections = outlineTop.findIndex(h => h.id === currentTopHeader.id);
          const nextSectionIndex = outlineTop.findIndex(section => section.id === nextSection.id);
          if (nextSectionIndex < numDisplayedSections) {
            this.setState({currentSection: 
              {
                id: nextSection.id,
                index: nextSection.index,
              }
            });
          } else {
            this.setState({ currentSection: null });
          }
          if (!this.chatScroller) this.paneDidMount(this.messagesAreaRef);
          if (this.chatScroller) {
            this.chatScroller.toY(0);
          } 
        }}>
        { "Next Section" }
      </NextButton>;
    } else if (currentMessageIndex < hocketStubs.length - 1) {
      const lastSectionMessage = outlineTop.findIndex(section => section.id === hocketStubs[currentMessageIndex + 1].hocketId) > -1;
      nextButton = <NextButton
        variant="outlined"
        className="next-button"
        onClick={ () => this.incrementMessageIndex() }>
        {"Next" + (lastSectionMessage ? " Section" : "")}
      </NextButton>;
    }
    const hasSuggestionChips = hockets?.[currentMessageId]?.suggestions?.length;
    const alreadyResponded = userMessages.has(currentMessageId);
    if (hasSuggestionChips && !alreadyResponded) {
      nextButton = null;
    }
    return (
      <div
        className="hockets-area"
        tabIndex={ -1 }
        ref={ node => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
        { outlineArea }
        { hocketCards }
        { nextButton }
      </div>
    );
  }

  addMessage(quillDelta, clear = false) {
    const cat = (delta1, delta2) => {
      if (delta1) {
        return {ops: delta1.ops.concat([{insert: '\n\n'}]).concat(delta2.ops)};
      } else {
        return delta2;
      }
    };
    const { userMessages, hockets } = this.state;
    let { currentMessageId } = this.state;
    const { currentUser } = this.props;
    if (typeof quillDelta === 'string') quillDelta = {ops: [{insert: quillDelta}]};
    quillDelta = removeTerminalNewlinesFromQuillDelta(quillDelta);
    if (!currentMessageId) [currentMessageId] = Object.keys(hockets).slice(-1);
    const parentHocket = hockets[currentMessageId];
    const parentQuill = parentHocket?.responses?.[0] || {ops: [{insert: "\n"}]};
    quillDelta = clear ? quillDelta : cat(userMessages.get(currentMessageId), quillDelta);
    const registered = isRegistered(parentQuill);
    const multipleChoiceOptions = parentHocket.suggestions || [];
    if (!this.userChatRef) {
      userMessages.set(currentMessageId, quillDelta);
      this.setState({ userMessages }, () => this.scrollBottom());
    } else {
      quillDelta = JSON.stringify(quillDelta);
      const message = {
        parent: currentMessageId, 
        parentQuill,
        isRegistered: registered,
        multipleChoiceOptions,
        quillDelta,
      };
      this.userChatRef.collection('messages').doc(currentMessageId)
          .set(message).catch(console.error);
      this.lessonChatRef.collection('users').doc(currentUser.id).collection('messages').doc(currentMessageId).set(
        {user: currentUser.displayName,
         photoUrl: currentUser.photoUrl,
         parent: currentMessageId,
         quillDelta,
         isRegistered: registered,
         parentQuill,
         multipleChoiceOptions,
        }
      ).then(() => this.scrollBottom()).catch(console.error);
    }
  }

  logIn() {
    const { currentUser, lessonId, router } = this.props;
    const url = "/shared/" + lessonId;
    if (!currentUser) {
      this.props.login(url);
    } else {
      router.history.push("/chat");
    }
  }

  openHamburgerMenu(event) {
    this.hamburgerMenuOpenRef = event.currentTarget;
    this.setState({ hamburgerMenuOpen: true });
  };

  handleHamburgerClose () {
    this.setState({ hamburgerMenuOpen: false });
  }

  getCurrentTopHeader(idx) {
    const { outline } = this.state;
    const [ headingMessage ] = outline.filter(h => h.depth === this.state.minHeaderDepth && h.index <= idx).slice(-1);
    if (!headingMessage) {
      return {index: 0, id: null};
    }
    return {
      index: headingMessage.index,
      id: headingMessage.id,
    };
  }

  getOutline() {
    const { lesson, hockets } = this.state;
    if (lesson.outline && lesson.minHeaderDepth) {
      if (this.state.outline.length) return;
      const { outline, minHeaderDepth } = lesson;
      this.setState({ outline, minHeaderDepth });
    } else {
      if (Object.keys(hockets).length < lesson.hockets.length) {
        if (!this.state.getAllHocketsTriggered) {
          lesson.hockets.forEach(hocketStub => {
            this.subHocket(hocketStub.hocketId);
          });
          this.setState({getAllHocketsTriggered: true});
        }
        return;
      }
      const hocketSequence = lesson.hockets.map(hocketStub => hockets[hocketStub.hocketId]);
      const { outline, minHeaderDepth } = getOutline(hocketSequence.filter(hocket => !isNote(hocket)));
      this.setState({ outline, minHeaderDepth });
    }
  }

  renderHamburgerMenu(navButtons) {
    const { hamburgerMenuOpen } = this.state;
    const { currentUser, router } = this.props;
    return (
      <Menu
        open={ hamburgerMenuOpen }
        onClose={ () => this.handleHamburgerClose() }
        anchorEl={ this.hamburgerMenuOpenRef }>
        { this.state.isPCL ?
            <MenuItem onClick={() => router.history.push("/#course-library")}>
              Course Library
            </MenuItem>
        : <MenuItem 
            onClick={ () => { this.logIn(); this.handleHamburgerClose(); }}>
            {currentUser ? "My Courses" : "Sign In"}
          </MenuItem> }
        <MenuItem onClick={ () => { this.setState({showHelp: true}); this.handleHamburgerClose(); }}>
          About
        </MenuItem>
        <MenuItem onClick={ () => { this.importButtonAction(); this.handleHamburgerClose(); }}>
          Import
        </MenuItem>
      </Menu>
    );
  }

  exportJupyterNotebook() {
    const { hockets, lesson, codeCell='code', currentMessageId } = this.state;
    let currentPosition = lesson.hockets.map(hocketStub => hocketStub.hocketId).indexOf(currentMessageId) + 1;
    if (currentPosition === 0) currentPosition = lesson.lockets.length;
    exportJupyterNotebook(
      lesson.hockets.slice(0, currentPosition).map(hocketStub => hockets[hocketStub.hocketId]),
      codeCell,
      lesson.title && lesson.title.length > 0 ? lesson.title : "prismia",
    );
  }

  importButtonAction() {
    const { projectsOwned, taOf, instructorOf } = this.state;
    const { currentUser } = this.props;
    if (currentUser) {
      if (Object.keys(projectsOwned).length +
          Object.keys(taOf).length +
          Object.keys(instructorOf).length === 0) {
            this.subCourses();
      }
      this.openMenu();
    } else {
      this.logIn();
    }
  }

  renderProgressBar() {
    if (this.state.displayMode) return null;
    const { lesson } = this.state;
    let progress = 0;
    let title = 'progress bar';
    if (lesson) {
      const { hockets=[] } = lesson;
      const current = this.messageIndex();
      const total = hockets.length;
      progress = 100 * (current + 1) / total;
      title = `${current + 1} message${current === 0 ? "" : "s"} out of ${total} total`
    }
    return <Tooltip title={title} enterDelay={ 150 }>
      <div className="progress-bar-container">
        <div className="progress-bar-outline">
          <div className="progress-bar" style={{width: `${progress}%`}}/>
        </div>
      </div>
    </Tooltip>;
  }

  render() {
    const { lesson={}, showHelp, showEditorTool, codeCell, hideVideo, displayMode } = this.state;
    const { currentUser, storage } = this.props;
    if (!lesson) return null;
    const { title='', description='', youTubeId='' } = lesson;
    const imageStyle = {
      width: '160px',
      height: '40px',
      marginTop: '-2px',
      float: 'left',
    };
    let importButton = (
      <Button
        className={ "window-top-right" }
        style={{color: "white"}}
        onClick={ () => this.importButtonAction() }>
        Import
      </Button>
    );
    let docsButton = (
      <Button
        style={{color: "white"}}
        onClick={ () => this.setState({showHelp: true}) }>
        About
      </Button>
    );
    let classes = "full-height lesson-sharing-view"
    const backAction = {};
    if (showHelp) {
      classes += " blur";
      backAction.onClick= () => this.setState({ showHelp: false});
    }
    if (this.state.copyMode) {
      classes += " copy-mode";
    }
    const hasVideo = youTubeId.length > 0;
    const scrollProp = { ref: this.messagesAreaRef };
    const mainPage = width => {
      const mobile = width < 867;
      const videoTop = mobile && hasVideo && !hideVideo;
      const singleCol = !hasVideo || mobile || hideVideo;
      return (
        <>
        { videoTop ? <div className={"youtube-float" + (showHelp ? " blur" : "")}>
          <YouTube
            className="youtube-embed-mobile"
            videoId={ youTubeId }
            onReady={ (event) => {
              this.setState({ videoPlayer: event.target });
            }}
          />
        </div> : null }
        <div className={ classes } {...backAction}>
          <div 
            className="landing-top-bar"
            style={{height: "40px"}}>
            <a href="/">
              <img src="/prismia-logo-lesson-sharing.svg" style={ imageStyle } alt="logo"/>
            </a>
            { mobile ? null : importButton }
            { mobile ? null : docsButton }
            { !mobile ? null :
              <MenuIcon
                className={"window-top-right"}
                style={{
                  color: "#fff",
                  float: "right",
                  fontSize: "35px",
                }}
                onClick={ (e) => this.openHamburgerMenu(e) }
              /> }
              {mobile ? null : (  this.state.isPCL ? 
              <Link to="/#course-library">
                <Button style={{color: 'white'}}>
                  Course Library
                </Button>
              </Link>
            : <Button onClick={() => this.logIn() }
                style={{color: "white"}}>
                {currentUser ? "My Courses" : "Sign In"}
              </Button>)}
          </div>
          { this.renderProgressBar() }
          <div style={{height: `calc(100% - ${displayMode ? 65 : 86}px)`}} className="flex-vertical">
            <div className={"flex-child " + (singleCol ? "y-scrollable-no-height" : "no-height")}
              {...(singleCol ? scrollProp : {})}>
              <div 
                className={"flex-container" + (singleCol ? "" : " full-height")}>
                { singleCol ? null :
                  <div className="youtube-column">
                    <div className="youtube-container">
                      <YouTube
                        className="youtube-embed"
                        videoId={ youTubeId }
                        onReady={ (event) => {
                          this.setState({ videoPlayer: event.target });
                        }}
                      />
                    </div>
                  </div>
                }
                <div 
                  className={"flex-child " + (singleCol ? "" : "y-scrollable")}
                  {...(singleCol ? {} : scrollProp)}>
                    { this.state.displayMode ? null : 
                      <div
                        className={"show-all-button" + (videoTop ? " youtube-space-above" : "")}
                        onClick={ () => {
                          this.incrementMessageIndex(true, this.state.allShown);
                        } }>
                        {this.state.allShown ? "[reset]" : "[show all]"}
                      </div> }
                      <div className="extra-padded-left">
                        <h1
                          className={"lesson-title centered" + (videoTop ? " youtube-space-above" : "")}>
                          { title || '' }
                        </h1>
                        <div className="centered description-text">
                          { description || '' }
                        </div>
                      </div>
                      { this.renderHocketsArea(width) }
                </div>
              </div>
            </div>
            { this.state.displayMode ? null : 
              <ChatWidget
                ref={ this.chatWidgetRef }
                quillRef={ this.quillRef }
                codeCell={ codeCell }
                setLang={ lang => this.setState({ codeCell: lang}) }
                showEditorTool={ showEditorTool }
                addMessageFromSendButton={ (delta) => this.addMessage(delta) }
                addMessage={ (delta) => this.addMessage(delta) }
                storage={ storage }
                currentUser={ currentUser }/>
            }
          </div>
        </div>
      </>
    );};
    const maskCover = showHelp ? <div className="masking-cover" onClick={ () => this.setState({showHelp: false}) }></div> : null;
    const helpCard = showHelp ? this.helpInfo(hasVideo) : null;
    updateTitleBar("Shared Lesson");
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({ width }) => {
           return <>
            { mainPage(width) }
            { maskCover }
            { helpCard }
            { this.renderProjectSelect() }
            { this.renderHamburgerMenu() }
            <NotificationContainer/>
           </>;
        } }
      </ReactResizeDetector>
    );
  }

}

export default LessonSharingView;
