import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import InteractiveGraph from '../InteractiveGraph';
import Suggestions from './Suggestions';
import { CollapsibleJupyterCell } from '../JupyterCell';
import Tooltip from '@material-ui/core/Tooltip';
import { extractCodeBlock, hashString } from '../utils';
import './style.css';

class MessageCard extends Component {

  render() {
    const {
      id,
      style,
      classes,
      quillDelta,
      jessieCode,
      aspectRatio,
      codeCell,
      suggestions,
      suggestionsActive,
      photoUrl,
      language,
      authorDisplayName,
      commentsArea,
      photoPosition="bottom-left",
      ...otherProps
    } = this.props;
    return (
      <>
        <div
          style={ style }
          className={ "message-card" + (classes ? (" " + classes) : "") }
          {...otherProps}>
          { commentsArea }
          { photoUrl &&
            <Tooltip
              title={ authorDisplayName || 'no display name' }
              enterDelay={ 100 }>
                <img
                  className={"message-card-photo" + (photoPosition ? (" " + photoPosition) : null)}
                  src={ photoUrl }
                  alt="user profile"
                />
            </Tooltip> }
          <ReactQuill
           readOnly
           modules={ { toolbar: false } }
           value={ quillDelta }/>
          { jessieCode && jessieCode.length &&
              <InteractiveGraph
                key={ 'interactive-graph-' + hashString(id + jessieCode) }
                code={ jessieCode }
                width={ "90%" }
                ratio={ aspectRatio || "100%"}/> }
          { codeCell &&
            <div className="on-card-code-cell">
              <CollapsibleJupyterCell
                content={ extractCodeBlock(quillDelta)}
                language={ language || 'python' }/>
            </div> }
         </div>
         { suggestions && <Suggestions
           suggestions={ suggestions }
           suggestionsActive={ suggestionsActive }/> }
      </>
    );
  }
}

export default MessageCard;
