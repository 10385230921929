import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import RichTextEditor from './RichTextEditor';
import { MarkdownShortcuts } from 'quill-markdown-shortcuts';
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import { noToolbarModules, sparseFormQuillModules } from './quill-config';
import { imageUploader } from './utils';
const defaultDelta = "{\"ops\":[{\"insert\":\"\\n\"}]}";
Quill.register('modules/markdownShortcuts', MarkdownShortcuts);
Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register("modules/imageUploader", ImageUploader);

// BEGIN patch to allow image alignment styles
const ImageFormatAttributesList = [
  'alt',
  'height',
  'style'
];

const BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

const VideoFormatAttributesList = [
  'alt',
  'width',
  'height',
  'style',
];

const BaseVideoFormat = Quill.import('formats/video');
class VideoFormat extends BaseVideoFormat {
  static formats(domNode) {
    return VideoFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (VideoFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
Quill.register(VideoFormat, true);
// END allow image alignment styles

// pick up here
class QuillSuperList extends Component {

  constructor(props) {
    super(props);
    this.count = 0;
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  renderValueInput(value, i) {
    const { noToolbar=false, placeholder=null, imageCb=null,
            fontSize="large", readOnly=false, storage=null,
            fullEditor=false } = this.props;
    if (i === undefined) return null;
    let val;
    try {
      val = JSON.parse(value);
    } catch (e) {
      console.error(e);
      val = {ops: []};
    }
    const { quillRef } = this.props;
    let className = "tab-focus"
    if (fontSize === "small") {
      className += " quill-small-font"
    }
    const toolbarModules = Object.assign(noToolbar ? noToolbarModules : sparseFormQuillModules, { imageUploader: imageUploader(storage, imageCb ? imageCb : () => null)});
    if (!fullEditor) { return (
      <ReactQuill
        ref={ quillRef }
        key={ i }
        value={ val }
        className={ className }
        tabIndex={ 1 }
        modules={ toolbarModules }
        onChange={ this.handleChange(i) }
        onFocus={ this.onFocus }
        onBlur={ this.onBlur }
        onKeyUp={ this.onKeyUp }
        onKeyDown={ this.onKeyDown }
        placeholder={ placeholder }
        readOnly={ readOnly }
      />
    ) } else { return (
      <RichTextEditor
        storage={ storage }
        imageCb={ imageCb }
        key={ i }
        value={ val }
        quillRef={ quillRef }
        quillClasses={ className }
        quillOnChange={ this.handleChange(i) }
        quillOnFocus={ this.onFocus }
        quillOnBlur={ this.onBlur }
        quillOnKeyUp={ this.onKeyUp }
        quillOnKeyDown={ this.onKeyDown }
        placeholder={ placeholder }
        readOnly={ readOnly }
        toggleCanvas={ this.props.toggleCanvas }
        toggleMathlet={ this.props.toggleMathlet }
        toggleTable={ this.props.toggleTable }
        toggleSuggestedMessages={ this.props.toggleSuggestedMessages }
        toggleOpenResponse={ this.props.toggleOpenResponse }
        toggleEquationEditor={ this.props.toggleEquationEditor }
        toggleCalculator={ this.props.toggleCalculator }
        codeCellChecked={ this.props.codeCellChecked }
        handleCheckChange={ this.props.handleCheckChange }
        studentView={ this.props.studentView }/>
    ); }
  }

  onFocus(range, source, editor) {
    if (this.props.onFocus) {
      this.props.onFocus();
  }}

  onBlur(previousRange, source, editor) {
    if (this.props.onBlur) {
      this.props.onBlur(previousRange);
    }
  }

  handleChange(i) {
    const { values, updateValues } = this.props;    
    return (content, delta, source, editor) => {
      //console.log(editor.getContents());
      values[i] = JSON.stringify(editor.getContents());
      updateValues(values.filter(v => v !== defaultDelta));
    }
  }

  onKeyUp(e) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  }

  onKeyDown(e) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  render() {
    const { values=[], limit=Infinity } = this.props;
    const valueInputs = [];
    for (var i = 0; i < values.length; i++) {
      if (i >= limit) {
        break;
      }
      if (values[i].trim() === defaultDelta) continue;
      valueInputs.push(this.renderValueInput(values[i], i));
    }
    if (i <= limit - 1) {
      valueInputs.push( this.renderValueInput(defaultDelta, values.length) );
    }

    return (
      <div className="quill-super-list">
        <form noValidate autoComplete="off">
          { valueInputs }
        </form>
      </div>
    );
  }

}

export default QuillSuperList;
