import React, { Component, Suspense } from 'react';
import ErrorBoundary from '../ErrorBoundary';
import Button from '@material-ui/core/Button';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SwitchRenderer from '../SwitchRenderer';
import ImageCellRenderer from '../ImageCellRenderer';
import ChatLinkRenderer from '../ChatLinkRenderer';
import {
  toggleOnStudent,
  toggleOffStudent,
  toggleOnObserver,
  toggleOffObserver,
  toggleOnTa,
  toggleOffTa,
  toggleOnInstructor,
  toggleOffInstructor
} from '../analytics';
import './style.css';
const AgGridReact = React.lazy(() => import('ag-grid-react').then((module) => ({default: module.AgGridReact})));

class Students extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      users: null,
    };
    this.state = {
      globalControl: false,
      students: [],
      rowsDisplayed: -1,
      studentRoles: {},
      instructorRoles: {},
      taRoles: {},
      observerRoles: {},
      useObservers: 'unset',
    };
  }

  componentDidMount() {
    this.subProject();
    this.subStudents();
    this.subRoles();
  }

  subProject() {
    const { db, projectId } = this.props;
    return db.collection('projects')
      .doc(projectId)
      .get()
      .then(snap => {
        const data = snap.data();
        const { useObservers } = data;
        this.setState({ useObservers });
      })
      .catch(console.error);
  }

  subRoles() {
    const { db, projectId } = this.props;
    this.unsub.studentRoles = db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('students')
      .onSnapshot(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return;
        this.setState({ studentRoles: data.userIds });
      });
    this.unsub.taRoles = db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('tas')
      .onSnapshot(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return;
        this.setState({ taRoles: data.userIds });
      });
    this.unsub.instructorRoles = db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('instructors')
      .onSnapshot(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return;
        this.setState({ instructorRoles: data.userIds });
      });
    this.unsub.observerRoles = db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('observers')
      .onSnapshot(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return;
        this.setState({ observerRoles: data.userIds });
      });
  }

  subStudents() {
    const { db, projectId } = this.props;
    return db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('students')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        const studentIds = data.userIds;
        for (let key in studentIds) {
          this.unsub[key] = db
            .collection('users')
            .doc(key)
            .onSnapshot(snap => {
              const student = snap.data();
              if (!student) return null;
              const { students } = this.state;
              students[student.id] = student;
              this.setState({ students });
            });
        }
      }).catch(console.error);
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (typeof this.unsub[key] !== 'function') continue;
      this.unsub[key]();
    }
  }

  toggleStudentRole(studentId) {
    const { students, studentRoles } = this.state;
    const { db, projectId } = this.props;
    const student = students[studentId];
    if (!student) return;
    let isStudent = true;
    if (studentRoles && studentRoles[student.id]) {
      isStudent = false;
    }
    isStudent ? toggleOnStudent() : toggleOffStudent();
    return db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('students')
      .set({ userIds: {[studentId]: isStudent}}, {merge:true})
      .then(() => {
        return db
          .collection('meta')
          .doc('roles')
          .collection('students')
          .doc(studentId)
          .set({ [projectId]: isStudent }, {merge:true});
      }).catch(console.error);
  }

  toggleObserverRole(studentId) {
    const { students, observerRoles } = this.state;
    const { db, projectId } = this.props;
    const student = students[studentId];
    if (!student) return;
    let isObserver = true;
    if (observerRoles && observerRoles[student.id]) {
      isObserver = false;
    }
    isObserver ? toggleOnObserver() : toggleOffObserver();
    return db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('observers')
      .set({ userIds: {[studentId]: isObserver}}, {merge:true})
    .then(() => {
        return db
          .collection('meta')
          .doc('roles')
          .collection('observers')
          .doc(studentId)
          .set({ [projectId]: isObserver }, {merge:true});
      }).catch(console.error);
  }

  toggleTaRole(studentId) {
    const { students, taRoles } = this.state;
    const { db, projectId } = this.props;
    const student = students[studentId];
    if (!student) return;
    let ta = true;
    if (taRoles && taRoles[student.id]) {
      ta = false;
    }
    ta ? toggleOnTa() : toggleOffTa();
    return db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('tas')
      .set({ userIds: {[studentId]: ta}}, {merge:true})
      .then(() => {
        return db
          .collection('meta')
          .doc('roles')
          .collection('tas')
          .doc(studentId)
          .set({ [projectId]: ta }, {merge:true});
      }).catch(console.error);
  }

  toggleInstructorRole(studentId) {
    const { students, instructorRoles } = this.state;
    const { db, projectId } = this.props;
    const student = students[studentId];
    if (!student) return;
    let instructor = true;
    if (instructorRoles && instructorRoles[student.id]) {
      instructor = false;
    }
    instructor ? toggleOnInstructor() : toggleOffInstructor();
    return db
      .collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('instructors')
      .set({ userIds: {[studentId]: instructor}}, {merge:true})
      .then(() => {
        return db
          .collection('meta')
          .doc('roles')
          .collection('tas')
          .doc(studentId)
          .set({ [projectId]: instructor }, {merge:true});
      }).catch(console.error);
  }

  renderStudentSwitch(student) {
    const { studentRoles } = this.state;
    if (!student) return;
    let isStudent = false;
    if (studentRoles && studentRoles[student.id]) {
      isStudent = true;
    }
    const label = 'Student';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ isStudent }
              onChange={ () => this.toggleStudentRole(student.id) }
             />
            }
          label={ label }
        />
      </FormGroup>
    );
  }

  onFilterTextBoxChanged = () => {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box').value);
    this.setState({rowsDisplayed: this.gridApi.getDisplayedRowCount()});
  };

  exportCsv = () => {
    const dayId = (new Date()).toISOString().slice(0, 10);
    this.gridApi.exportDataAsCsv(
      {fileName: "prismia-roster-" + dayId + ".csv",
       columnKeys: ['studentName','studentId','studentStatus','taStatus', 'instructorStatus']}
    );
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = skipHeader => {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }

  compileRowData() {
    const rows = [];
    const { projectId } = this.props;
    const { students, studentRoles, observerRoles,
            taRoles, instructorRoles } = this.state;
    for (let key of Object.keys(students)) {
      const isStudent = (key in studentRoles && studentRoles[key]);
      const isTa = (key in taRoles && taRoles[key]);
      const isInstructor = key in instructorRoles && instructorRoles[key];
      const isObserver = key in observerRoles && observerRoles[key];
      const href = '/projects/' + projectId + '/instructor-chat-view/' + key;
      rows.push({
        photoUrl: students[key].photoUrl,
        displayName: students[key].displayName + "///" + href,
        studentName: students[key].displayName,
        studentId: students[key].studentId,
        student: isStudent + "," + key + ",student",
        ta: isTa + "," + key + ",ta",
        instructor: isInstructor + "," + key + ",instructor",
        observer: isObserver + "," + key + ",observer",
        studentStatus: isStudent,
        taStatus: isTa,
        instructorStatus: isInstructor,
        observerStatus: isObserver,
      });
    }
    return rows;
  }

  render() {
    let { students } = this.state;
    if (this.state.useObservers === 'unset') return null;
    students = Object.keys(students).map(studentId => students[studentId]);
    const gridOptions = {
      sortable: true,
      resizable: true,
      filter: true,
    };
    const frameworkComponents = {
      switchRenderer: SwitchRenderer,
      imageCellRenderer: ImageCellRenderer,
      chatLinkRenderer: ChatLinkRenderer,
    }
    const studentData = {
      columnDefs: [
        {
          headerName: '',
          field: 'photoUrl',
          cellRenderer: 'imageCellRenderer',
          width: 30,
          maxWidth: 30,
          cellStyle: {padding: 0},
        },
        {
          headerName: 'Name',
          field: 'displayName',
          cellRenderer: 'chatLinkRenderer'
        },
        { headerName: 'Student ID',
          field: 'studentId',
          hide: true,
        },
        {
          headerName: 'Name',
          field: 'studentName',
          hide: true,
        },
        {
          headerName: 'Student',
          field: 'student',
          cellRenderer: 'switchRenderer',
        },
        {
          headerName: 'Student',
          field: 'studentStatus',
          hide: true,
        },
        {
          headerName: 'Assistant',
          field: 'ta',
          cellRenderer: 'switchRenderer',
        },
        {
          headerName: 'Assistant',
          field: 'taStatus',
          hide: true,
        },
        {
          headerName: 'Instructor',
          field: 'instructor',
          cellRenderer: 'switchRenderer',
        },
        {
          headerName: 'Instructor',
          field: 'instructorStatus',
          hide: true,
        },
        {
          headerName: 'Observer',
          field: 'observer',
          cellRenderer: 'switchRenderer',
          hide: !this.state.useObservers,
        },
        {
          headerName: 'Observer',
          field: 'observerStatus',
          hide: true,
        },
      ],
      rowData: this.compileRowData(),
    }
    return (
      <>
      <h2 className="title centered">Class Roster</h2>
      <div
        className="ag-theme-material"
        style={{
          width: '80%',
          maxWidth: '700px',
          height: "calc(100% - 200px)",
          margin: 'auto',
          marginTop: "3%"}}>
        <div className="input-group">
          <div className="input-group-prepend">
            <Button
              onClick={ this.exportCsv }
              className="btn">
              Export to CSV
            </Button>
          </div>
          <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={ this.onFilterTextBoxChanged }/>
        </div>
        <div className="rows-displayed">
          { this.state.rowsDisplayed > -1 ? (this.state.rowsDisplayed + " row" + (this.state.rowsDisplayed === 1 ? "" : "s") + " displayed") : null }
        </div>
        <ErrorBoundary
          key={ 'error-boundary-table' }
          errorMessage="[a data table should be showing here, but there was either a network issue or an issue with the code that was written to generate it]"
          hideDetails>
          <Suspense fallback={<div>Loading...</div>}>
            <AgGridReact
            defaultColDef = { gridOptions }
            columnDefs={ studentData.columnDefs }
            rowData={ studentData.rowData }
            onGridReady={ this.onGridReady }
            onFirstDataRendered={ () => {
              if (window.document.body.clientWidth > 830) {
                this.sizeToFit();
              } else {
                this.autoSizeAll(false);
              }
              setTimeout( () => this.setState({rowsDisplayed: this.gridApi.getDisplayedRowCount()}), 500);
            } }
            context={ {
              toggleStudent: (id) => this.toggleStudentRole(id),
              toggleTa: (id) => this.toggleTaRole(id),
              toggleInstructor: (id) => this.toggleInstructorRole(id),
              toggleObserver: (id) => this.toggleObserverRole(id),
            } }
            frameworkComponents={ frameworkComponents }
            getRowStyle={ (params) => {
              if (params.data.firstOnDate) {
                return {borderTop: '2px solid #7f7777'};
              }
            }}
            enableCellTextSelection
            animateRows>
          </AgGridReact>
          </Suspense>
        </ErrorBoundary>
      </div>
      </>
    );
  }

}

export default Students;
