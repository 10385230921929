import React, { Component } from 'react';
import { safePhotoUrl } from './profile-emojis';

export default class ImageCellRenderer extends Component {

  render() {
    const imageStyle = {
      float: "right",
      width: "25px",
      height: "25px",
      border: "1px solid #000",
      margin: "auto",
      marginLeft: "2px",
      borderRadius: "20px",
    };
    return <img alt="logo" src={ safePhotoUrl(this.props.value) } style={ imageStyle }/>;
  }
}