import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';

class Timer extends Component {

  constructor(props) {
    super(props);
    this.tick = this.tick.bind(this);
    this.state = {
      currentDate: new Date(),
    }
  }

  componentDidMount() {
    this.timerID = setInterval(
      this.tick,
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      currentDate: new Date(),
    });
  }

  formatTimer(currentDate, timerStartDate, timeLimit) {
    const ms = timeLimit - (currentDate - timerStartDate)/1000;
    return Math.round(ms);
  }

  render() {
    const { currentDate } = this.state;
    const { timeLimit, timerStartDate } = this.props;
    const fabStyle = {
      margin: "auto",
      top: "7px",
      right: "8px",
      position: "fixed",
      backgroundColor: "#E6E6FA",
      color: "#888",
      border: "1px solid #888",
      opacity: 1,
      zIndex: 10000,
    };
    const numSeconds = this.formatTimer(currentDate, timerStartDate, timeLimit);
    if (numSeconds < 0) {
      return null;
    }
    if (numSeconds < 11) {
      fabStyle.backgroundColor = "#f93d35";
      fabStyle.opacity = 0.85;
      fabStyle.color = "#efefef";
      fabStyle.border = "1px solid #ddd";
    }
    return <Fab
       style={ fabStyle }
       size="medium"
       color="secondary">
       { numSeconds }
    </Fab>;
  }
}

export default Timer;
