import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from 'moment';
import { Link } from "react-router-dom";
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import BuildIcon from '@material-ui/icons/Build';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FlagIcon from '@material-ui/icons/Flag';
import IconButton from '@material-ui/core/IconButton';
import SimpleAdminNav from '../SimpleAdminNav';
import { Thread } from '../thread';
import { updateTitleBar, reorder } from '../utils';
import './style.css';
import { getDocumentButtonListStyle, getDocumentButtonStyle, DocumentButton } from '../mui-themes';
import MessageCard from '../messages/MessageCard';
import { NotificationContainer, NotificationManager } from 'react-notifications';

class MessageBoardView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      threads: null,
    };
    this.state = {
      showHelp: false,
      hideMode: false,
      newThreadBoxOpen: false,
      newThreadPrivate: false,
      threadLimit: 40,
      searchMode: false,
      searchText: '',
      instructionalStaff: {},
      messages: {},
    };
  }

  componentDidMount() {
//    this.subThreadOrder(this.props);
    this.subThreads(this.props);
    this.getProjectDetails(this.props);
    this.getInstructors(this.props);
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
  }

  isInstructionalStaff(user) {
    if (!user) user = this.state.currentUser;
    if (!user) return false;
    return this.state.instructionalStaff[user.id] || false;
  }

  isVisible(thread) {
    const { currentUser } = this.props;
    const { hideMode } = this.state;
    return (hideMode || !thread.hidden) &&
      (!thread.privateThread || 
       thread.creatorId === currentUser.id ||
       this.isInstructionalStaff(currentUser));
  }

  increaseThreadLimit() {
    const { threadLimit } = this.state;
    this.setState({threadLimit: threadLimit + 40}, () => {
      this.subThreads(this.props);
    });
  }
  
  subThread(threadId, Cb) {
    const key = 'threads-' + threadId;
    if (this.unsub[key]) this.unsub[key]();
    const { db, projectId } = this.props;
    this.unsub[key] = db
      .collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(threadId)
      .collection('messages')
      .orderBy('timestamp')
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const threadMessages = snap.docs
          .map(doc => doc.data())
          .filter(message => !message.archived);
        for (let message of threadMessages) {
          if (message.quillDelta) {
            message.quillDelta = JSON.parse(message.quillDelta);
          }
        }
        const { messages } = this.state;
        messages[threadId] = threadMessages;
        this.setState({ messages });
        if (Cb) Cb(messages[threadId]);
      });
  }

  subThreads(props, Cb) {
    if (this.unsub.threads) this.unsub.threads();
    const { threadLimit } = this.state;
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.threads = db
      .collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .where('archived', '==', false)
      .orderBy('timestamp', 'desc')
      .limit(threadLimit)
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const threads = snap.docs
          .map(doc => doc.data())
          .filter(thread => !thread.archived)
        if (this.state.notifications && this.state.threads) {
          for (let thread of threads) {
            if (!thread.resolved) {
              this.subThread(thread.id, (messages) => {
                if (messages.length > 0) {
                  new Notification(`Message in thread "${ thread.title }" on ${ this.state.displayName } Prismia message board`);
                }
              });
            }
          }
        }
        this.setState({ threads });
        if (Cb) Cb(threads);
      });
  }

  getProjectDetails(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const project = snap.data() || {};
        const { displayName='' } = project;
        this.setState({ displayName });
      });
  }

  async getInstructors(props) {
    const { db, projectId } = props;
    let instructionalStaff = {};
    for (let role of ['instructors', 'tas']) {
      await db.collection('projects')
        .doc(projectId)
        .collection('roles')
        .doc(role)
        .get()
        .then(snap => {
          const data = snap.data();
          for (let userId in data.userIds) {
            if (data.userIds[userId]) instructionalStaff[userId] = true;
          }
        });
    }
    this.setState({ instructionalStaff });
  }

  subThreadOrder(props) {
    if (this.unsub.threadOrder) this.unsub.threadOrder();
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.threadOrder = db
      .collection('projects')
      .doc(projectId)
      .collection('thread-order')
      .doc('ids')
      .onSnapshot(doc => {
        const ids = doc.data();
        if (!ids || !ids.threadOrder) {
          this.subThreads(this.props, (threads) =>
          this.setThreadOrder(threads.map(l => l.id)));
        } else {
          this.setState({ threadOrder: ids.threadOrder }, () => this.subThreads(this.props));
        }
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
  }

  nameThread(threadTitle='') {
    this.setState({ threadTitle });
  }

  logIn() {
    const { currentUser, lessonId, router } = this.props;
    const url = "/shared/" + lessonId;
    if (!currentUser) {
      this.props.login(url);
    } else {
      router.history.push("/chat");
    }
  }

  createThread() {
    const { db, projectId, currentUser } = this.props;
    const { threadTitle, newThreadPrivate } = this.state;
    const creator = currentUser || {};
    const thread = Thread(threadTitle, creator, [], newThreadPrivate);
    db.collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(thread.id)
      .set(thread, {merge: true})
      .then(() => {
        this.setState({
          threadTitle: '',
          newThreadBoxOpen: false,
        });
        // this.setThreadOrder([thread.id].concat(threadOrder));
      }).catch(console.error);
  }

  canHide(thread) {
    const { currentUser } = this.props;
    if (thread.creatorId === currentUser.id) return true;
    if (this.isInstructionalStaff(currentUser)) return true;
    return false;
  }

  hideThread(threadId, hide=true) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(threadId)
      .set({ hidden: hide }, {merge: true})
      .catch(console.error);
  }

  archiveThread(threadId) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(threadId)
      .set({archived: true}, {merge: true})
      .catch(console.error);
    // this.setThreadOrder(threadOrder.filter(id => id !== threadId));
  }

  async resolveThread(threadId) {
    const { db, projectId } = this.props;
    await db.collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(threadId)
      .set({resolved: true}, {merge: true})
      .catch(console.error);
  }

  async makePublic(threadId) {
    const answer = window.confirm("Are you sure you want to make this thread public?");
    if (!answer) return;
    const { db, projectId } = this.props;
    await db.collection('projects')
      .doc(projectId)
      .collection('message-board-threads')
      .doc(threadId)
      .set({privateThread: false}, {merge: true})
      .catch(console.error);
  }

  renderThreadPreview(thread) {
    const { projectId } = this.props;
    const link = '/projects/' + projectId + '/message-board-threads/' + thread.id;
    return <>
      <div className="preview-container">
        { (this.state.messages[thread.id] || []).map( message => {
          return <div
                    key={message.id}
                    className="preview-message">
              <MessageCard
                id={ message.id }
                quillDelta={ message.quillDelta }
                photoUrl={ message.authorPhotoUrl }
                authorDisplayName={ message.authorDisplayName }
            />
          </div>
        }) }
        <Link to={link} target="_blank" rel="noopener noreferrer">
          <Button 
            variant="outlined"
            style={{flex: "auto", marginTop: "10px"}}>
              Open
          </Button>
        </Link>        
      </div>
    </>;
  }

  renderThreadCard(thread, index) {
    const { hideMode } = this.state; 
    let time = '';
    if (thread.timestamp && thread.timestamp.toDate) {
      time += moment(thread.timestamp.toDate()).fromNow();
    }
    const eyeIcon = <Tooltip 
      title="this thread visible only to its creator and the instructional staff">
      <VisibilityOffIcon style={{
        position: 'absolute', 
        top: '50%', 
        left: '12px',
        cursor: 'help',
        opacity: 0.5,
        transform: "translate(0, -50%)",
      }}
      onClick={(event) => {
        this.makePublic(thread.id);
        event.stopPropagation();
      }}/>
    </Tooltip>;
    const flagIcon = <Tooltip 
      title="this thread is unresolved">
      <FlagIcon style={{
        position: 'absolute', 
        top: '5px', 
        right: '6px',
        cursor: 'help',
        opacity: 0.9,
      }}
      onClick={(event) => {
        this.resolveThread(thread.id);
        event.stopPropagation();
      }}/>
    </Tooltip>;
    const badges = <>
      { thread.privateThread && !hideMode ? eyeIcon : null }
      { !thread.resolved ? flagIcon : null }
    </>;
    return (
      <Draggable
        key={thread.id}
        draggableId={thread.id}
        index={index}
        isDragDisabled={ true }>
        {(provided, snapshot) => (
          <>
            <DocumentButton
              key={"list-item-" + thread.id}
              onClick={ () => {
                if (this.state.threadOpen === thread.id) {
                  this.setState({threadOpen: null});
                } else {
                  const key = 'threads' + thread.id;
                  if (!this.unsub[key]) {
                    this.subThread(thread.id);
                  }
                  this.setState({threadOpen: thread.id});
                }
              }}
              button
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getDocumentButtonStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}>
              {( hideMode && this.canHide(thread) ?
                <Button
                  style={{color: "rgb(35, 120, 130)"}}
                  onClick={ (event) => {
                    event.stopPropagation();
                    this.hideThread(thread.id, !thread.hidden);
                  }}>
                  { thread.hidden ? "Unhide" : "Hide" }
                </Button> : null)}
              {( hideMode && this.canHide(thread) ?
                <Button
                  style={{color: "rgb(35, 120, 130)"}}
                  onClick={ (event) => {
                    event.stopPropagation();
                    this.archiveThread(thread.id);
                  }}>
                  Delete
                </Button> : null)}
              <ListItemText
                style={{
                  maxWidth: "calc(100% - 80px)",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                primary={ thread.title }
                secondary={ time }
                className= "thread-card-text" />
              { badges }
            </DocumentButton>
            { this.state.threadOpen === thread.id ? this.renderThreadPreview(thread) : null}
            </>
           )
        }
      </Draggable>
    );
  }

  onDragEnd(result) {
    const { threads, threadOrder } = this.state;
    // dropped outside the list
    if (!result.destination || !threadOrder) {
      return;
    }
    const visibleThreads = threads.filter(thread => this.isVisible(thread));
    const loadedThreadIds = threads.map(thread => thread.id)
    const indexMap = (i) => loadedThreadIds.indexOf(visibleThreads[i].id);
    const start = indexMap(result.source.index);
    const end = indexMap(result.destination.index);
    const newThreads = reorder(threads, start, end);
    const allIndexMap = (i) => threadOrder.indexOf(visibleThreads[i].id);
    const allStart = allIndexMap(result.source.index);
    const allEnd = allIndexMap(result.destination.index);
    const newThreadOrder = reorder(threadOrder, allStart, allEnd);
    this.setState({ threads: newThreads }, () =>
      this.setThreadOrder(newThreadOrder)
    );
  }

  setThreadOrder(threadOrder) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('thread-order')
      .doc('ids')
      .set({ threadOrder })
      .catch(console.error)
  }

  hasSearchText(thread) {
    if (this.state.searchText.length === 0) return true;
    if (thread.title.toLowerCase().includes(this.state.searchText.toLowerCase())) return true;
    return JSON.stringify(thread.messages).toLowerCase().includes(this.state.searchText.toLowerCase());
  }

  renderThreadCards() {
    const { threads=[] } = this.state;
    const cards = threads.filter(
        thread => this.isVisible(thread) && this.hasSearchText(thread)
      ).map( (l, i) => this.renderThreadCard(l, i));
    return (
        <DragDropContext onDragEnd={ result => this.onDragEnd(result) }>
          <Droppable droppableId="droppable">
          { (provided, snapshot) => (
              <List
                ref={provided.innerRef}
                style={getDocumentButtonListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}>
                { cards }
                { provided.placeholder }
              </List>
            ) }
          </Droppable>
          { this.renderHideButton() }          
          { this.renderNotificationButton() }
        </DragDropContext>
    );
  }

  handleThreadPrivacyChange() {
    this.setState({newThreadPrivate: !this.state.newThreadPrivate});
  }

  renderNewThreadCreationArea() {
    const { threadTitle='' } = this.state;
    return (
      <div className='thread-creation-area'>
        <h2 className='centered'>New Thread</h2>
        <p className="subtext">Thread title:</p>
        <Input
          fullWidth
          className="thread-title"
          placeholder="Title"
          value={ threadTitle || '' }
          onChange={ (e) => this.nameThread(e.target.value) } />
        <Tooltip title="make this thread visible to instructors, TAs, and you">
          <FormControlLabel
            control={
              <Checkbox
                className="code-cell-checkbox"
                size="small"
                style={{ marginBottom: "-5px" }}
                checked={ this.state.newThreadPrivate }
                color="default"
                onChange={ () => this.handleThreadPrivacyChange() }
                inputProps={{ 'aria-label': 'make thread visible to only instructors, TAs, and you' }}/>
            }
            label="Private"
          />
        </Tooltip>
        <Button
          variant="contained"
          color="secondary"
          style={{display: "block", marginTop: "20px", marginLeft: "50%", transform: "translate(-50%, 0)"}}
          onClick={() => this.createThread() }
          >
          Start thread
        </Button>
      </div>
    );
  }

  renderNotificationButton() {
    const { hideMode, notifications } = this.state;
    if (!hideMode) return null;
    if (notifications) return null;
    return (
        <Button
          variant="contained"
          style={{
            marginTop: "30px",
            marginBottom: "20px",
            marginLeft: "50%",
            transform: "translate(-50%, 0)",
          }}
          onClick={ () => {
            Notification.requestPermission().then(() => {
              NotificationManager.info("You will receive browser notifications when threads are marked unresolved or when new threads are added.");
            }).catch(console.error);
            this.setState({ notifications: true });
          }}>
          Turn on Notifications
        </Button>
    );
  }

  renderHideButton() {
    const { hideMode } = this.state;
    if (!hideMode) return null;
    return (
        <Button
          variant="contained"
          style={{
            marginTop: "30px",
            marginBottom: "20px",
            marginLeft: "50%",
            transform: "translate(-50%, 0)",
          }}
          onClick={ () =>
            this.setState({ hideMode: !hideMode })
          }>
          Done
        </Button>
    );
  }

  renderThreadsArea() {
    const { newThreadBoxOpen, threads, threadLimit } = this.state;
    const plusButton = <Tooltip title="Create a new thread">
      <Button
        fullWidth
        variant="outlined"
        onClick={ () => {
          this.setState({ newThreadBoxOpen: true });
        }}>
        +
      </Button>
    </Tooltip>;
    const moreThreadsButton = <Tooltip title="Create a new thread">
      <Button
        fullWidth
        variant="outlined"
        onClick={ () => this.increaseThreadLimit() }>
        Check for more threads
      </Button>
    </Tooltip>;
    return (
      <div className="y-scrollable">
        { this.renderSearchAndSettings() }
        <div className="message-threads-area extra-padded">
          <h1 className="thread-title centered">Message board</h1>
          <h2 className="thread-title centered padded-bottom">{ this.state.displayName || ''}</h2>
          { newThreadBoxOpen ? this.renderNewThreadCreationArea() : plusButton }
          { this.renderThreadCards() }
          { moreThreadsButton }
        </div>
      </div>
    );
  }

  setSearchText(searchText) {
    this.setState({ searchText });
  }

  renderSearchAndSettings() {
    return <>
      <IconButton
          style={{
            zIndex: 1,
            position: "absolute",
            top: "13px",
            right: "10px",
            color: 'teal',
          }}
        onClick={ () => this.setState({ hideMode: !this.state.hideMode }) }>
        <BuildIcon/>
      </IconButton>
      <div style={{
          zIndex: 1,
          position: "absolute",
          top: "0",
          left: "0",
          color: 'teal',
          backgroundColor: 'white',
          padding: '8px',
        }}>
      <IconButton
        onClick={ () => this.setState({ searchMode: !this.state.searchMode }) }>
        <SearchIcon/>
      </IconButton>
      { !this.state.searchMode ? null : <Input
        onChange={(event) => this.setSearchText(event.target.value) }/> }
      </div>
    </>;
  }

  render() {
    const { currentUser, projectId, db, 
            router } = this.props;
    updateTitleBar('Message Board');
    return (
      <div className="message-board-view full-height">
        <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } studentView={ true }/>
        <div style={{position: "relative", height: "calc(100% - 60px)"}}>
          { this.renderThreadsArea() }
        </div>
        <NotificationContainer/>
      </div>
    );
  }

}

export default MessageBoardView;
