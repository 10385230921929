import React, { Component } from 'react';
import zenscroll from 'zenscroll';
import ReactResizeDetector from 'react-resize-detector';
import toPlaintext from 'quill-delta-to-plaintext';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import 'react-quill/dist/quill.snow.css';
import ReadOnlyQuill from '../ReadOnlyQuill';
import InteractiveGraph from '../InteractiveGraph';
import CoursesArea from '../courses-area/';
import { CollapsibleJupyterCell } from '../JupyterCell';
import ChatWidget from '../ChatWidget';
import PinnedMessage from './PinnedMessage';
import Timer from '../Timer';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import * as diff from "fast-array-diff";
import { imageUploader, extractBestImage, isEmpty, tryUntilSuccess,
         catDelta, pingBinderKernel, codeBlockDelta, splitGroups,
         hashString, extractCodeText, extractCodeBlock } from '../utils';
import { binderKernels } from '../jupyter';
import { mdShortcutsOnly } from '../quill-config';
import {
  Analytics,
  openCoursesDrawer,
  pinMessage,
  typingOn,
  typingOff,
  studentHelpScreen,
  peerResponseSent,
} from '../analytics';
import { safePhotoUrl } from '../profile-emojis';
import { Message } from '../message';
import CsvTable from '../CsvTable';
import { removeTerminalNewlinesFromQuillDelta, updateTitleBar,
         copyTextToClipboard, mousetrapStopCallback} from '../utils';
import * as Mousetrap from 'mousetrap';
import './style.css';

const ELI_CHAT_ID = '000-0000-000';
const SAM_CHAT_ID = 's21KCg2SxWX1WFOhEize5iDr5qQ2';
const BOT_CHAT_ID = '000-0000-000';
const BOT_PHOTO_URL = 'https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/app-images%2Fprismia-bot.svg?alt=media&token=b8b14de6-23cc-43cc-bfb4-f677e2684fe8';

const debug = () => !!window.debug;
const analytics = Analytics();

const TIPS = [
  "press enter for a new line and shift-enter to send a message!",
  "you can change your display name and profile image under \"Update my Profile\" in the menu!",
  "type three backticks and hit space to create a code block environment!",
  "you can type inline code by surrounding the code in backticks and hitting the space bar afterwards",
  "you can drop or paste images into your text box!",
  "you can get Greek characters or emoijis by typing something like \\lambda or \\:hand: and then hitting the tab key",
  "You can drag-and-drop or paste an image in your text box. You can even draw on the image if you open up your drawing tool afterwards!",
];

const pick = arr => arr[arr.length * Math.random() | 0];

class ChatView extends Component {
  constructor(props) {
    super(props);
    this.chatRef = null;
    this.messagesAreaRef = null;
    this.firstTime = true;
    this.hasBeenScrolled = false;
    this.loadingHistory = false;
    this.quillRef = React.createRef();
    this.chatWidgetRef = React.createRef();
    this.setTranscriptMode = this.setTranscriptMode.bind(this);
    this.getTranscriptMode = this.getTranscriptMode.bind(this);
    this.flipBackground = this.flipBackground.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSvg = this.handleSvg.bind(this);
    this.quillDeltaCurrentQuestion = null;
    this.count = 0;
    this.unsub = {
      chat: null,
      user: null,
    };
    this.timeOfLastTypingUpdate = new Date();
    this.state = {
      allowingMessageLimitIncrease: true,
      pinnedMessages: [],
      achievements: {},
      drawerOpen: false,
      messages: [],
      messageLimit: 20,
      quillDelta: null,
      selectedTheatre: 'courses',
      typing: false,
      lightBackground: false,
      showHelp: false,
      copiedMessageId: null,
      copyMessage: null,
      timeLimit: -1,
      currentDate: new Date(),
      timerStartDate: new Date(),
      lastBinderPing: new Date(),
      multipleChoiceOpen: false,
      showEditorTool: false,
      codeCell: false,
      hasMounted: false,
      homepageUrl: null,
      singleColumnMode: true,
      instructorOf: {},
      projectsOwned: {},
      projectsAttended: {},
      taOf: {},
      hiddenCourses: new Set(),
      callOnMe: false,
      whiteboard: null,
    };
  }

  componentDidMount() {
    let { currentUser, firestore, projectId="main" } = this.props;
    if (!currentUser) return;
    this.firestore = firestore;
    this.mostRecentMessageId = null;
    this.subChat(projectId);
    this.subClassMessages(projectId);
    this.subWhiteboard(projectId);
    this.barkPromptTimeout = null;
    const currentTime = (new Date()).toISOString();
    this.activityRef.doc(currentUser.id).set({
      displayName: currentUser.displayName,
      photoUrl: currentUser.photoUrl,
      typing: false,
      currentQuillDelta: null,
      lastActive: currentTime,
      svgPatch: null,
    }, { merge: true });
    // wait for page to load and then scroll down:
    setTimeout( () => {
      tryUntilSuccess(() => {
        if (!this.chatScroller) return false;
        this.scrollDown(0, () => this.setState({hasMounted: true}));
        return this.messagePaneScrolledDown(0);
      }, {wait: 400, limit: 5});
    }, 1000 );
    this.getProjectDetails().then((courseLanguage) => {
      this.getEquationEditorPreference(courseLanguage);
      this.getCallOnMe();
    });
    //this.setBarkPromptTimeout(3);
    this.setMousetrap(currentUser);
    this.subCourses();
    setTimeout(
      () => NotificationManager.info("Tip: " + pick(TIPS)),
      3000
    );
  }

  setMousetrap(currentUser) {
    Mousetrap.bind(["mod+/", "shift+/"], () => {
      const { showHelp } = this.state;
      this.setState({ showHelp: !showHelp });
      if (showHelp)
        studentHelpScreen(currentUser.id);
    });
    Mousetrap.bind("esc", () => this.setState({ showHelp: false }));
    // stop the callback unless the combo starts with mod:
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  // subCourses
  subCourses() {
    this.subOwned();
    this.subStudentOf();
    this.subTaOf();
    this.subInstructorOf();
  }

  subOwned() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) return null;
    if (this.unsub.coursesOwned) this.unsub.coursesOwned();
    this.unsub.coursesOwned = db
      .collection('meta')
      .doc('roles')
      .collection('owners')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || {};
        const projects = data.projects || [];
        for (let i = 0; i < projects.length; i++) {
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { projectsOwned } = this.state;
                projectsOwned[project.id] = project;
                this.setState({ projectsOwned });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  subStudentOf() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) return null;
    if (this.unsub.coursesAttended) this.unsub.coursesAttended();
    this.unsub.coursesAttended = db
      .collection('meta')
      .doc('roles')
      .collection('students')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        let projects = snap.data() || [];
        const memberships = Object.values(projects);
        projects = Object.keys(projects);
        for (let i = 0; i < projects.length; i++) {
          if (memberships[i] === "hide") {
            let { hiddenCourses } = this.state;
            hiddenCourses.add(projects[i]);
            this.setState({ hiddenCourses });
          }
          if (memberships[i]) {
            db.collection('projects')
              .doc(projects[i])
              .get()
              .then(snap => {
                const project = snap.data() || {};
                if (project.archived) return;
                if (project.id) {
                  let { projectsAttended } = this.state;
                  projectsAttended[project.id] = project;
                  this.setState({ projectsAttended });
                }
              }).catch(console.error);
          }
        }
      }).catch(console.error);
  }

  hideCourse(projectId, hide) {
    const { db, currentUser } = this.props;
    const { hiddenCourses} = this.state
    db.collection('meta')
      .doc('roles')
      .collection('students')
      .doc(currentUser.id)
      .set({ [projectId]: hide ? "hide" : true}, { merge: true });
    if (hide) {
      hiddenCourses.add(projectId);
    } else {
      hiddenCourses.delete(projectId);
    }
    this.setState({ hiddenCourses });
  }

  subTaOf() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) return null;
    if (this.unsub.taOf) this.unsub.taOf();
    this.unsub.taOf = db
      .collection('meta')
      .doc('roles')
      .collection('tas')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        let data = snap.data() || [];
        const projects = Object.keys(data);
        for (let i = 0; i < projects.length; i++) {
          if (!data[projects[i]]) continue;
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { taOf } = this.state;
                taOf[project.id] = project;
                this.setState({ taOf });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  subInstructorOf() {
    const { db, currentUser } = this.props;
    if (!db || !currentUser) return null;
    if (this.unsub.instructorOf) this.unsub.instructorOf();
    this.unsub.instructorOf = db
      .collection('meta')
      .doc('roles')
      .collection('instructors')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || [];
        const projects = Object.keys(data);
        for (let i = 0; i < projects.length; i++) {
          if (!data[projects[i]]) continue;
          db.collection('projects')
            .doc(projects[i])
            .get()
            .then(snap => {
              const project = snap.data() || {};
              if (project.archived) return;
              if (project.id) {
                let { instructorOf } = this.state;
                instructorOf[project.id] = project;
                this.setState({ instructorOf });
              }
            }).catch(console.error);
        }
      }).catch(console.error);
  }

  allProjects() {
    let {
      projectsAttended={},
      projectsOwned={},
      instructorOf={},
      taOf={},
      hiddenCourses=new Set(),
    } = this.state;
    return {
      projectsAttended,
      projectsOwned,
      instructorOf,
      taOf,
      hiddenCourses,
    };
  }

  pinMessage(message) {
    const { currentUser } = this.props;
    const { pinnedMessages } = this.state;
    const messageIdx = pinnedMessages.findIndex( m => m.id === message.id);
    if (messageIdx === -1) {
      pinnedMessages.push(message);
    } else {
      pinnedMessages.splice(messageIdx, 1);
    }
    pinMessage(currentUser.id); // analytics
    this.setState({ pinnedMessages });
  }

  flipBackground(setting) {
    if (setting === "dark") {
      this.setState({ lightBackground: false });
    } else {
      this.setState({ lightBackground: true });
    }
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }


  toggleEquationEditorVisibility() {
    const { showEditorTool } = this.state;
    this.setEquationEditorPreference(!showEditorTool);
    this.setState({showEditorTool: !showEditorTool});
  }

  changeCodeCellOption(lang) {
    this.setCodeCellPreference( lang );
    this.setState({ codeCell: lang });
  }

  toggleSingleColumnOption() {
    const { singleColumnMode } = this.state;
    this.setSingleColumnPreference(!singleColumnMode);
  }

  setTranscriptMode(mode) {
    let { selectedTheatre } = this.state;
    // toggle behavior
    if (mode === undefined) {
      if (selectedTheatre === "courses") {
        selectedTheatre = "transcript";
      } else if (selectedTheatre === "transcript") {
        selectedTheatre = "courses";
      }
    } else {
      selectedTheatre = mode ? "transcript" : "courses";
    }
    this.setState({ selectedTheatre });
  }

  getTranscriptMode() {
    const { selectedTheatre } = this.state;
    return selectedTheatre === "transcript";
  }

  questionCloser(message) {
    return message.sentFromMessageEntireClass || message.isMultipleChoiceResponse;
  }

  subWhiteboard(projectId) {
    const { db } = this.props;
    this.unsub.whiteboard = db
      .collection('projects')
      .doc(projectId)
      .collection('whiteboard')
      .doc('whiteboard')
      .onSnapshot((snap) => {
        const message = snap.data();
        if (!message) {
          this.setState({ whiteboard: null });
          return;
        };
        if (message.svgPatch) {
          const whiteboard = {
            id: message.id,
          }
          let groups = this.state.whiteboard?.groups || [];
          if (message.firstWhiteboardPatch) groups = [];
          whiteboard.groups = diff.applyPatch(groups, message.svgPatch);
          whiteboard.message = message;
          const messagePaneScrolledDown = this.messagePaneScrolledDown();
          this.handleNewMessageBelow(
            this.state.whiteboard === null && messagePaneScrolledDown,
            this.state.whiteboard === null && !messagePaneScrolledDown,
          );
          this.setState({ whiteboard });
        } else {
          this.setState({ whiteboard: null });
        }
      });
  }

  messagePaneScrolledDown(tolerance = 40) {
    return (this.messagesAreaRef &&
      this.messagesAreaRef.scrollHeight - this.messagesAreaRef.scrollTop - this.messagesAreaRef.clientHeight < tolerance);
  }

  subClassMessages(projectId) {
    const { db } = this.props;
    if (this.unsub.classMessages) this.unsub.classMessages();
    this.unsub.classMessages = db.collection('projects')
      .doc(projectId)
      .collection('class-messages')
      .orderBy('timestamp', 'desc')
      .limit(this.state.messageLimit)
      .onSnapshot(snap => {
        const classMessages = {};
        snap.forEach(doc => {
          const message = doc.data();
          classMessages[message.id] = message;
        })
        this.setState({ classMessages }, () => this.handleMessages());
      });
  }

  subChat(projectId="main") {
    let { db, currentUser } = this.props;
    if (projectId === 'main' && currentUser.activeProjectId) {
      projectId = currentUser.activeProjectId;
    }
    if (this.unsub.chat) this.unsub.chat();
    this.activityRef = db
        .collection('projects')
        .doc(projectId)
        .collection('realtime-activity');
    this.chatRef = db
      .collection('users').doc(currentUser.uid || currentUser.id)
      .collection('chats').doc(projectId)
      .collection('messages');
    this.unsub.chat = this.chatRef
      .orderBy('timestamp', 'desc')
      .limit(this.state.messageLimit)
      .onSnapshot(snap => {
        const myMessages = {};
        snap.forEach(doc => {
          const message = doc.data();
          if (this.skipMessage(message)) return;
          myMessages[message.id] = message;
        });
        this.setState({ myMessages }, () => this.handleMessages());
      });
  }

  skipMessage(message) {
    const { currentUser } = this.props;
    if (message.sharedFromClass &&
        (message.author === currentUser.id ||
         message.recipient === currentUser.id)) return true;
    if (message.responseSuggestion) return true;
    if (message.promptBark) return true;
    return false;
  }

  handleMessages() {
    const { currentUser } = this.props;
    const { classMessages, myMessages } = this.state;
    let { pinnedMessages } = this.state;
    const allMessages = Object.values({...classMessages, ...myMessages}).sort( 
      (m1, m2) => m1.timestamp < m2.timestamp ? 1 : -1
    )
    let i = this.state.messageLimit - 1, j = this.state.messageLimit - 1;
    if (classMessages && Object.values(classMessages).length > 0) {
      const [lastClassMessage] = Object.values(classMessages).slice(-1);
      i = allMessages.indexOf(lastClassMessage);
    }
    if (myMessages && Object.values(myMessages).length > 0) {
      const [lastMessage] = Object.values(myMessages).slice(-1);
      j = allMessages.indexOf(lastMessage);
    }
    let limit = Math.max(this.state.messageLimit, Math.min(i, j) + 1);
    const clearLimit = allMessages.findIndex(message => message.id === this.state.clearMessageId);
    if (clearLimit > -1) limit = Math.min(clearLimit, limit);
    const messages = allMessages.slice(0, limit);
    const sentByCurrentUser = messages[0] && messages[0].author === currentUser.id;
    if (!sentByCurrentUser) this.quillDeltaCurrentQuestion = null;
    let newMessageBelow = false;
    let writingProblem = false;
    if (messages[0] && messages[0].id !== this.mostRecentMessageId) {
      // this.mostRecentMessageId ensures we only run this part if 
      // we genuinely got a new message
      this.mostRecentMessageId = messages[0].id;
      newMessageBelow = true; // this is for the "new messages" floating action button
      let pinnedUpdate = false;
      const text = messages[0].textContent;
      for (let message of messages) {
        if (message.suggestions && message.suggestions.length) {
          this.setState({ multipleChoiceOpen: message.id });
          break;
        } else if (this.questionCloser(message)) {
          this.setState({ multipleChoiceOpen: false})
          break;
        }
      }
      if (text.includes('♻')) {
        pinnedMessages = [];
        pinnedUpdate = true;
      } else if (text.includes('⊗️') || text.includes('⨂') || text.includes('⊗')) {
        const numCancels = (text.match(/⊗️|⨂|⊗/g) || []).length;
        pinnedMessages.splice(-numCancels);
        pinnedUpdate = true;
      }
      if (text.includes('📎') || text.includes('📌')) {
        pinnedMessages.push(messages[0]);
        pinnedUpdate = true;
      }
      if (pinnedUpdate) {
        this.setState({ pinnedMessages });
      }
      if (text.includes('✏')) {
        const img = extractBestImage(messages[0]);
        if (img) {
          if (this.chatWidgetRef.current) {
            this.chatWidgetRef.current.kickOpenCanvas(img);
          }
        }
        writingProblem = true;
      }
      if (text.includes('🕔')) {
          try {
            const numSeconds = parseInt(text.match(/🕔([0-9]*)s/)[1]);
            if (numSeconds) {
              this.setState({
                timeLimit: numSeconds,
                timerStartDate: new Date(),
              });
            }
          }
          catch {
            console.log("not starting timer");
          }
      }
      //const messageText = toPlaintext(JSON.parse(messages[0].quillDelta).ops);
      //const secondsToNextBark = getTimoutSecondsFromTextLength(messageText);
      //this.setBarkPromptTimeout(secondsToNextBark);
    }
    messages.reverse();
    if (!this.firstTime) {
      const lastMessage = messages[messages.length - 1];
      analytics('chat-messages', lastMessage);
    }
    const { loadingHistory } = this;
    const messagePaneScrolledDown = this.messagePaneScrolledDown();
    this.setState({ messages }, () => {
      this.hasBeenScrolled = true;
      this.firstTime = false;
    });
    this.handleNewMessageBelow(
      newMessageBelow && (sentByCurrentUser || messagePaneScrolledDown),
      !loadingHistory && newMessageBelow && !writingProblem,
    );
    setTimeout(() => {this.loadingHistory = false}, 200);
    this.pingBinderKernel();
  }

  handleNewMessageBelow(scrollCondition, fabCondition) {
    if (!this.state.hasMounted) return;
    if (scrollCondition) {
      this.scrollDown(200);
    } else if (fabCondition) {
      this.setState({ showFab: true });
    }
  }

  setActiveProjectId(activeProjectId) {
    const { db, currentUser } = this.props;
    if (!currentUser.id) return;
    db.collection('users')
      .doc(currentUser.id)
      .set({ activeProjectId }, { merge: true });
    this.getProjectDetails();
  }

  setEquationEditorPreference(setting) {
    const { db, currentUser } = this.props;
    if (!currentUser.id) return;
    db.collection('users')
      .doc(currentUser.id)
      .set({ showEquationEditor: setting }, { merge: true });
  }

  setCodeCellPreference(setting) {
    const { db, currentUser } = this.props;
    if (!currentUser.id) return;
    db.collection('users')
      .doc(currentUser.id)
      .set({ codeCell: setting }, { merge: true })
      .then( () => {
        this.getEquationEditorPreference(setting);
      })
      .catch(console.error);
  }

  setCallOnMe(setting) {
    const { db, currentUser } = this.props;
    if (!currentUser.id) return;
    db.collection('users')
      .doc(currentUser.id)
      .set({ callOnMe: setting }, { merge: true })
      .then( () => {
        this.getCallOnMe(setting);
      })
      .catch(console.error); 
  }


  setSingleColumnPreference(setting) {
    const { db, currentUser } = this.props;
    if (!currentUser.id) return;
    db.collection('users')
      .doc(currentUser.id)
      .set({ singleColumn: setting }, { merge: true })
      .then( () => {
        this.getEquationEditorPreference();
      })
      .catch(console.error);
  }

  getCallOnMe() {
    const { db, currentUser } = this.props;
    if (!currentUser.id) return;
    db.collection('users')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || { callOnMe: true };
        this.setState({ callOnMe: data.callOnMe || false });
      })
      .catch(console.error);
  }

  getEquationEditorPreference(courseLanguage) {
    const { db, currentUser } = this.props;
    if (!currentUser.id) return;
    db.collection('users')
      .doc(currentUser.id)
      .get()
      .then(snap => {
        const data = snap.data() || { showEquationEditor: false, codeCell: 'none', singleColumnMode: false };
        this.setState({ showEditorTool: data.showEquationEditor });
        if (data.codeCell && ( data.codeCell === 'none' ||
            !(Object.keys(binderKernels).includes(courseLanguage)) )
          ) {
          console.log('setting code cell language from user preferences');
          this.setState({ codeCell: data.codeCell });
        } else if (courseLanguage) {
          console.log('setting code cell language from course');
          this.setState({ codeCell: courseLanguage });
        }
        this.setState({ singleColumnMode: data.singleColumn });
      })
      .catch(console.error);
  }

  getProjectDetails() {
    const { db, projectId } = this.props;
    if (!projectId) return Promise.resolve();
    return db.collection('projects')
      .doc(projectId)
      .get()
      .then(snap => {
        const projectDetails = snap.data();
        const homepageUrl = projectDetails.homepageUrl;
        if (homepageUrl) {
          this.setState({ homepageUrl });
        } else {
          this.setState({ homepageUrl: null });
        }
        const courseLanguage = projectDetails.courseLanguage;
        const messageBoard = projectDetails.messageBoard;
        const assignments = projectDetails.assignments;
        const drills = projectDetails.drills;
        const useRealtimeFeatures = projectDetails.useRealtimeFeatures;
        this.setState({ 
          messageBoard: !!messageBoard, 
          assignments: !!assignments,
          drills: !!drills,
          useRealtimeFeatures: !!useRealtimeFeatures
        });
        return courseLanguage;
      })
  }

  componentDidUpdate(prevProps) {
    if (!this.props.router.match.params.id) return console.log('no nav match id');
    //if (this.routeIdMatchesOldRouteId(newProps)) return console.log('route ids match');
    // so if you're here, the id is new
    const { currentUser } = prevProps;
    const activeProjectId = this.props.router.match.params.id;
    if (currentUser.activeProjectId !== activeProjectId) {
      this.setActiveProjectId(activeProjectId);
      this.subChat(activeProjectId);
      this.subClassMessages(activeProjectId);
    }
  }

  scrollDown(delay = 350, Cb) {
    const node = this.messagesAreaRef;
    if (!node || !node.scrollHeight || !this.chatScroller) return;
    setTimeout(() => {
      if (node && node.scrollHeight) {
        if ((node.scrollTop + node.clientHeight) / node.scrollHeight < 1) {
          this.chatScroller.toY(node.scrollHeight);
        }
        if (Cb) Cb();
      }
    }, delay);
  }

  routeIdMatchesOldRouteId(newProps) {
    const { router } = this.props;
    return newProps.router.match.params.id === router.match.params.id;
  }


  componentWillUnmount() {
    if (this.unsub.chat) this.unsub.chat();
    window.clearTimeout(this.barkPromptTimeout);
    Mousetrap.unbind('mod+/');
    Mousetrap.unbind('mod+shift+u');
    Mousetrap.unbind('ctrl+m');
    Mousetrap.unbind('ctrl+j');
    Mousetrap.unbind('shift+/');
    Mousetrap.unbind('esc');
  }

  toggleDrawer(drawerOpen=false) {
    if (drawerOpen) openCoursesDrawer(); // analytics
    this.setState({ drawerOpen, selectedTheatre: "courses" });
  }

  setBarkPromptTimeout(seconds=12) {
    if (debug()) console.log('set bark prompt timeout called with ' + seconds + ' seconds');
    if (this.barkPromptTimeout) {
      window.clearTimeout(this.barkPromptTimeout);
      if(debug()) console.log('bark prompt timeout cleared');
    }
    this.barkPromptTimeout = window.setTimeout(() => {
      if (debug()) console.log('bark prompt ' + this.count + ' called');
      this.count++;
      const userId = this.props.currentUser.uid || this.props.currentUser.id;
      if (!userId) return;
      const defaultDelta = "{\"ops\":[{\"insert\":\"\\n\"}]}";
      const message = Message(defaultDelta, '', userId);
      // set the timestamp to the beginning of time (1970) so
      // it won't be posted in the chat
      message.timestamp = (new Date(0)).toISOString();
      message.promptBark = true;
      // here?
      //this.setBarkPromptTimeout(12);
      this.chatRef.doc(message.id).set(message);
    }, seconds * 1000);
    if (debug()) console.log('aaaand set again?', this.barkPromptTimeout, seconds * 1000);
  }

  peerRecipient(textContent='') {
    const { currentUser } = this.props;
    const { messages } = this.state;
    const instructorPeerOrSelf = (message) => {
      return ((message.author === "000-0000-000" &&
               !message.sentFromInstructorChatArea &&
               !message.sentFromClusterBlock) ||
              message.author === currentUser.id ||
              message.peerAnswer);
    };
    const isPeer = (message) => {
      return message.peerAnswer || false;
    }
    if (textContent.includes('👥')) {
      const [lastMessage] = messages.filter(isPeer).slice(-1);
      if (!lastMessage) return false;
      return lastMessage.author || false;
    } else {
      const [lastMessage] = messages.filter(instructorPeerOrSelf).slice(-1);
      if (!lastMessage) return false;
      return lastMessage.peerAnswer ? lastMessage.author : false;
    }
  }

  addMessageFromSendButton(quillDelta) {
    const { currentUser } = this.props;
    const userId = currentUser.uid || currentUser.id;
    if (!userId) return console.log('no current user id', this.props.currentUser);
    if (!quillDelta) return console.log('no message');
    if (quillDelta.ops.length === 1 && quillDelta.ops?.[0]?.insert === "\n")
    return console.log('empty message');
    quillDelta = removeTerminalNewlinesFromQuillDelta(quillDelta);
    const textContent = toPlaintext(quillDelta.ops);
    const message = Message(quillDelta, textContent, userId);
    if (textContent === 'clear messages') {
      const { messages } = this.state;
      const lastMessage = messages[messages.length - 1];
      this.setState({ 
        clearMessageId: lastMessage.id,
      });
      this.handleMessages();
      return;
    }
    message.authorDisplayName = currentUser.displayName;
    message.authorPhotoUrl = currentUser.photoUrl;
    message.callOnMe = this.state.callOnMe || false;
    const peer = this.peerRecipient(textContent);
    if (peer) {
      message.peerRecipient = peer;
      peerResponseSent(); // analytics
    }
    this.setTyping({
      status: false, 
      setTimestamp: true, 
      answered: true,
      quillDelta,
    });
    this.chatRef.doc(message.id)
      .set(message)
      .then(snap => {
        // always scroll to bottom when message sent by
        // student:
        this.scrollDown()
      }).catch(console.error);
  }

  addSuggestedMessage(textContent='', followupId=null, score, reply) {
    const { currentUser } = this.props;
    const userId = currentUser.uid || currentUser.id;
    if (!userId) return console.log('no current user', this.props.currentUser);
    // just need to make the quill delta from the string
    let fakeDelta = {ops: [{insert: textContent}]};
    let quillDelta = removeTerminalNewlinesFromQuillDelta(fakeDelta);
    let message = Message(quillDelta, textContent, userId);
    message.authorDisplayName = currentUser.displayName;
    message.authorPhotoUrl = currentUser.photoUrl;
    message.callOnMe = this.state.callOnMe || false;
    message.followupId = followupId;
    message.score = score;
    message.isMultipleChoiceResponse = true;
    let responseMessage;
    if (reply) {
      const responseDelta = JSON.parse(reply);
      let responseTextContent = toPlaintext(responseDelta.ops);
      if (responseTextContent.trim()) {
        responseMessage = Message(
          responseDelta,
          responseTextContent,
          BOT_CHAT_ID,
        );
        responseMessage.authorDisplayName = "Prismia Bot";
        responseMessage.authorPhotoUrl = BOT_PHOTO_URL;
        // timestamp comes from now() from utils, which uses server time
        // which is fine because we wait a bit to send it
      }
    }
    this.chatRef.doc(message.id)
      .set(message)
      .then(snap => {
        this.activityRef.doc(currentUser.id)
          .set({ 
            lastActive: (new Date()).toISOString(), 
          }, { merge: true })
          .catch(console.error);
        this.setState({ messageValue: null, quillDelta: null });
      }).then(() => {
        this.scrollDown();
        if (responseMessage) {
          setTimeout(() => {
            this.chatRef.doc(responseMessage.id)
                .set(responseMessage).catch(console.error);
          }, 750);
        }
      }).catch(console.error);
  }

  setMessageFeedback(message, value=0) {
    if (!message.id) return;
    message.feedback = message.feedback === value ? 0 : value;
    this.chatRef.doc(message.id).set(message, {merge: true});
    const { currentUser, db } = this.props;
    if (!currentUser) return;
    db.collection('users').doc(currentUser.uid || currentUser.id)
      .collection('chats').doc(SAM_CHAT_ID)
      .collection('messages').doc(message.id)
      .set(message);
  }

  previousMessageFollowupId() {
    const { messages } = this.state;
    // so maybe we shouldn't just look at whether the previous thing has a followup Id
    // but whether it happened in the last, I don't know, hour?
    const lastMessage = messages[messages.length - 1];
    if (!lastMessage) return null;
    if (lastMessage.author === ELI_CHAT_ID && lastMessage.followupHocketId) {
      return lastMessage.followupHocketId;
    }
    return null;
  }

  checkThenSetTyping({isEmpty, quillDelta, svg, rebound=false}={}) {
    if (!this.state.useRealtimeFeatures) return;
    const { typing=false } = this.state;
    const now = new Date();
    if (rebound && !this.rebound) return;
    if (rebound) {
      isEmpty = this.rebound.isEmpty;
      quillDelta = this.rebound.quillDelta;
      svg = this.rebound.svg;
    }
    const timeSinceLastUpdate = now - this.timeOfLastTypingUpdate;
    // we space out typing updates for cost reasons, and 
    // we also rebound updates which are slightly stale, 
    // so we don't just get one letter
    if ((3000 < timeSinceLastUpdate && 
                timeSinceLastUpdate < 10000) || rebound) {
      this.timeOfLastTypingUpdate = now;
      this.rebound = null;
      this.setTyping({
        status: !isEmpty,
        setTimestamp: true, 
        quillDelta,
        svg,
      });
    } else if (!typing && !isEmpty) {
      // only update typing status; not actual text
      this.setTyping({ status: true, setTimestamp: false });
      if (this.rebound) this.rebound.isEmpty = false;
    } else if (typing && isEmpty) {
      // only update typing status; not actual text
      this.setTyping({ status: false, setTimestamp: false });
      if (this.rebound) this.rebound.isEmpty = true;
    }
    if (!rebound) {
      if (!this.rebound) {
        setTimeout( () => {
          this.checkThenSetTyping({rebound: true});
        }, 3050);
      }
      this.rebound = {isEmpty, quillDelta, svg};
    }
  }

  setTyping({status=true,
             quillDelta=null,
             svg=null,
             setTimestamp=true,
             answered=false}={}) {
    const { currentUser } = this.props;
    const userId = currentUser.uid || currentUser.id;
    quillDelta = catDelta(this.quillDeltaCurrentQuestion, quillDelta);
    let update = { typing: status };
    this.setState(update);
    const currentTime = (new Date()).toISOString();
    if (setTimestamp) {
      update.lastActive = currentTime;
      if (answered) {
        update.lastAnswered = currentTime;
      }
    }
    if (answered) {
      this.quillDeltaCurrentQuestion = quillDelta;
    }
    // send contents of chat input
    if (quillDelta && !isEmpty(quillDelta)) {
      update.currentQuillDelta = JSON.stringify(quillDelta);
    }
    if (svg) {
      const svgGroups = splitGroups(svg);
      const svgPatch = diff.getPatch(this.currentSvgGroups || [], svgGroups);
      this.currentSvgGroups = svgGroups;
      update.svgPatch = svgPatch;
    } else {
      this.currentSvgGroups = [];
      update.svgPatch = null;
    }
    update.currentSvg = null;
    if (status) {
      typingOn(userId); // analytics
    } else {
      typingOff(userId); // analytics
    }
    this.activityRef
        .doc(userId)
        .set(update, {merge: true});
  }

  addMessage(quillDelta) {
    const { currentUser } = this.props;
    const userId = currentUser.uid || currentUser.id;
    if (!userId) return console.log('no current user', this.props.currentUser);
    quillDelta = removeTerminalNewlinesFromQuillDelta(quillDelta);
    this.setTyping({
      status: false, 
      setTimestamp: true, 
      answered: true,
      quillDelta,
    });
    const textContent = toPlaintext(quillDelta.ops);
    const message = Message(quillDelta, textContent, userId);
    if (textContent === 'clear messages') {
      const { messages } = this.state;
      const lastMessage = messages[messages.length - 1];
      this.setState({ 
        clearMessageId: lastMessage.id,
      });
      this.handleMessages();
      return;
    }
    message.authorDisplayName = currentUser.displayName;
    message.authorPhotoUrl = currentUser.photoUrl;
    message.callOnMe = this.state.callOnMe || false;
    // if the previous message has a followupHocketId set that as the followupId
    message.followupId = this.previousMessageFollowupId() || null;
    const peer = this.peerRecipient(textContent);
    if (peer) {
      message.peerRecipient = peer;
      peerResponseSent(); // analytics
    }

    this.chatRef.doc(message.id)
      .set(message)
      .then( () => {
        // always scroll to bottom when message sent by
        // student:
        this.scrollDown()
      }).catch(console.error);
  }

  increaseMessageLimit() {
    const { messageLimit } = this.state;
    this.loadingHistory = true;
    this.setState({ messageLimit: messageLimit + 4}, () => {
      this.subChat(this.props.projectId);
      this.subClassMessages(this.props.projectId);
    });
  }

  paneDidMount(node) {
    if (node) {
      this.unsub.scrollListener = () => {
        node.removeEventListener("scroll", this.handleScroll);
      }
      node.addEventListener("scroll", this.handleScroll);
      const defaultDuration = 500;
      const edgeOffset = 30;
      this.chatScroller = zenscroll.createScroller(node, defaultDuration, edgeOffset);
    }
  }

  handleScroll(event) {
    const { showFab=false, allowingMessageLimitIncrease=true } = this.state;
    let node = event.target;
    const top = node.scrollTop === 0;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (showFab && bottom) {
      this.setState({ showFab: false });
    } else if (top && allowingMessageLimitIncrease) {
      this.setState( { allowingMessageLimitIncrease : false }, () =>
        this.increaseMessageLimit()
      )
      setTimeout(() => this.setState({ allowingMessageLimitIncrease : true }), 1000);
    }
  }

  triggerMessage(messageId) {
    if (!messageId) return;
    const { db } = this.props;
    if (messageId) {
      db.collection('hockets')
        .doc(messageId)
        .get()
        .then(snap => {
          const hocket = snap.data();
          if (!hocket) return console.log('no hocket with id: ', messageId);
          if (!hocket.trainingPhrases) return;
          if (!hocket.trainingPhrases[0]) return;
          this.addSuggestedMessage(hocket.trainingPhrases[0], hocket.id);
        }).catch(console.error);
    }
  }

  renderTable(data) {
    return <CsvTable data={data}/>;
  }

  renderIFrame(urlIFrame, heightIFrame) {
    return <iframe frameborder="0" width="100%" src={urlIFrame} height={(heightIFrame || 0) + 'px'}></iframe>
  }

  renderJSXGraph(jessieCode, ratio) {
    return (
      <InteractiveGraph
        key={ 'interactive-graph-' + hashString(jessieCode) }
        code={ jessieCode }
        ratio={ ratio || "100%" }
        handleSvg={ this.handleSvg }
        setTyping={({isEmpty, quillDelta, svg}) => this.checkThenSetTyping({isEmpty, quillDelta, svg})}
        hideDetails/>
    );
  }

  handleSvg(svg) {
    if (this.chatWidgetRef.current) {
      this.chatWidgetRef.current.saveSketch(svg, true);
    }
  }

  createWhiteboardDelta() {
    const { whiteboard } = this.state;
    if (!whiteboard) return null;
    const { groups } = whiteboard;
    const delta = {ops: [{insert: {image: 'data:image/svg+xml;base64,' + btoa(groups.join(''))}}]};
    return delta;
  }

  renderMessage(message, showSuggestionChips=false, i, width, {pinned=false}={}) {
    if (!message) return null;
    const { currentUser={} } = this.props;
    const { messages, copiedMessageId, copyMessage, whiteboard } = this.state;
    const ml = messages.length || 0;
    const {author, id } = message;
    let { quillDelta } = message;
    if (id === whiteboard?.id) {
      quillDelta = this.createWhiteboardDelta();
    }
    if (!quillDelta || quillDelta === "{\"ops\":[{\"insert\":\"\\n\"}]}") {
      return null;
    }
    let className = 'student-message';
    let suggestions = null;
    let displayInitial = <img className="instructor-bubble" alt="profile" src={ safePhotoUrl(message.authorPhotoUrl) } />;
    let starBadge = <></>;
    if (author !== currentUser.id) {
      className = 'instructor-message';
      displayInitial = <img className="instructor-bubble" alt="profile" src={ safePhotoUrl(message.authorPhotoUrl) } />;
    } else if (message.starred) {
      starBadge = (
        <Tooltip title={ message.sharedWithClass ?
            "message starred and shared" :
            "message starred"
          }>
          <img className={ message.sharedWithClass ?
              "starred-and-shared expand-from-center" :
              "star-badge expand-from-center"
            }
            src={message.sharedWithClass ? "/shared-and-starred.svg" :"/badge-star.png"} alt="star badge"/>
        </Tooltip>
      );
    }
    if (message.suggestions && message.suggestions.length && showSuggestionChips) {
      const questionScored = message.suggestions.some(s => s.score);
      const nullScore = questionScored ? 0 : null;
      const { multipleChoiceOpen } = this.state;
      const active = multipleChoiceOpen === message.id;
      suggestions = (
        <div className="suggestion-chips">
        { message.suggestions.map((sug, i) => {
              let val = '';
              if (typeof sug === 'string') val = sug;
              if (sug.value) val = sug.value;
              const score = sug.score || nullScore;
              if (!sug.value) return null;
              if (!sug.value.trim()) return null;
              const followupId = sug.relatedHocketId || null;
              const reply = sug.reply || null;
              let sugChipClass = 'no-outline';
              if (currentUser && currentUser.hockets && currentUser.hockets[followupId]) {
                sugChipClass += ' visited';
              }
              if (!active) sugChipClass += ' inactive';
              return (
                <button
                  className={ sugChipClass }
                  onClick={ active ? () => {
                    this.addSuggestedMessage(val, followupId, score, reply);
                  } : () => null }
                  key={ i }>
                  { val }
                </button>
              );
            }
          )
        }
        </div>
      );
    }
    const delayFloor = (x) => {
      if (x > 15) {
        return 8 * Math.floor((x - 15) / 8) + 15;
      } else {
      return 0;
      }
    }
    let style = {};
    if (!(i === undefined)) {
      style = {
        animationDelay: (0.05 + 0.15*(ml - delayFloor(ml) - i )) + 's',
        animationName: 'appear',
        animationDuration: '0.25s',
        animationFillMode: 'both',
        animationTimingFunction: 'ease-in-out',
      };
    }
    if (message.sharedFromClass && message.author !== '000-0000-000') {
      className = className + ' shared-from-class-quill';
    }
    let sharedBadge;
    if (message.sharedWithClass) {
      sharedBadge = (
        <Tooltip title="message shared with the class">
          <ShareIcon
            fontSize="small"
            className="shared-badge expand-from-center"
            style={{
              color: "#3c838a",
              border: "1px solid black",
            }}/>
        </Tooltip>
      );
    }
    const pinIcon = (author === currentUser.id) ? null : (
      <Tooltip
        title={ (pinned ? "unpin" : "pin") + " message" }
        enterDelay={ 300 }>
        <IconButton
          className="pin-icon"
          onClick={ () => this.pinMessage(message) }
          style={{
            padding: "2px",
            marginTop: "-2pt",
            marginRight: "-1em",
            float: "right",
            color: (pinned ? "#AAA" : "#DDD")
          }}>
          { pinned ? <HighlightOffIcon fontSize="small"/> : <AddCircleOutlineIcon fontSize="small"/> }
        </IconButton>
      </Tooltip>
    );
    let QuillComponent = <ReadOnlyQuill quillDelta={ typeof quillDelta === 'string' ? JSON.parse(quillDelta) : quillDelta } />;
    if (pinned) QuillComponent = <PinnedMessage
      quillDelta={ quillDelta }
    />;
    return (
      <div
        key={ id + (pinned ? "-pinned" : "") }
        style={ style }
        className={ className } >
        { message.starred ? starBadge : (sharedBadge ? sharedBadge : null) }
        { pinIcon }
        <div className="quill-wrapper">
          { QuillComponent }
          { (message.tableData?.length > 0 || message.tableData?.string?.length) ? this.renderTable(typeof message.tableData === 'string' ? {string: message.tableData} : message.tableData) : null }
          { message.urlIFrame ? this.renderIFrame(message.urlIFrame, message.heightIFrame) : null }
          { message.jessieCode ? this.renderJSXGraph(message.jessieCode, message.aspectRatio) : null }
          { message.codeCell && this.state.codeCell && author !== currentUser.id ?
            <CollapsibleJupyterCell 
              content={extractCodeBlock(message.quillDelta)} 
              language={ this.state.codeCell }
              setTyping={(code) => {
                const isEmpty = code.length === 0;
                const quillDelta = isEmpty ? null : codeBlockDelta(code);
                this.checkThenSetTyping({isEmpty, quillDelta, svg: null});
              }}
              setLang={
                (lang) => this.setState({ codeCell: lang })
              }/> : null }
        </div>
        <Tooltip title={ copiedMessageId === message.id ? copyMessage : (message.authorDisplayName || '')} >
          <div
            className="message-bubble"
            onClick={ () => {
                copyTextToClipboard(extractCodeText(message, (code) => this.setState({copyMessage: code ? "copied code" : "copied"})));
                this.setState({ copiedMessageId: message.id });
            } }>
            { displayInitial }
          </div>
        </Tooltip>
        { suggestions || null }
      </div>
    );
  }

  selectCourses() {
    return this.setState({ selectedTheatre: 'courses' });
  }

  selectAchievements() {
    return this.setState({ selectedTheatre: 'achievements' });
  }

  renderTheatreNav() {
    const { selectedTheatre } = this.state;
    return (
      <div className="theatre-nav">
        <span
          className={ (selectedTheatre === 'achievements' ? 'active' : '') + " select" }
          onClick={ () => this.selectAchievements() }>
          Achievements
        </span>
        <span
          className={ (selectedTheatre === 'courses' ? 'active' : '') + " select" }
          onClick={ () => this.selectCourses() }>
          Courses
        </span>
      </div>
    );
  }

  renderFloatingActionButton(left) {
    const { showFab=false, chatWidgetHeight=87} = this.state;
    if (showFab) {
      const fabStyle = {
        left: left,
        margin: "auto",
        bottom: (chatWidgetHeight + 13) + 'px',
        position: "fixed",
        transform: "translate(-50%, 0%)",
        backgroundColor: "rgb(200,0,0)",
        opacity: 0.85
      };
      return <Fab variant="extended"
           onClick={ () => {
              this.scrollDown(0)
              this.setState({ showFab: false });
            }}
           style={fabStyle}
           size="medium"
           color="secondary">
        New Messages
        <ArrowDownwardIcon />
      </Fab>;
    } else {
      return null;
       
    }
  }

  logout() {
    this.props.logout();
  }

  setChatWidgetHeight(height) {
    this.setState({ chatWidgetHeight: height });
  }
  
  setLang(lang) {
    this.setState({ codeCell: lang });
  }

  renderMobile(width) {
    const { storage, currentUser } = this.props;
    const { messages, pinnedMessages, showHelp, codeCell, timeLimit, timerStartDate, showEditorTool } = this.state;
    let classes = "mobile full-width full-height chat-view";
    if (showHelp) classes += " blur";
    mdShortcutsOnly.imageUploader = imageUploader(storage);
    return (
      <>
      <Timer
        onClick={ () => this.setState({ timeLimit: -1 })}
        timeLimit={ timeLimit }
        timerStartDate={ timerStartDate }/>
        { this.renderDrawer() }
      <div className={ classes }>
        <div className="flex-vertical" style={{height: "100%"}}>
          <div className="height-30 teal-backsplash mobile-header">
            <IconButton
            onClick={() => this.toggleDrawer(true)}
            style={{
              marginTop: "2px",
              textAlign: "left",
              color: "white"
            }}
            size="small">
            <KeyboardArrowRightIcon/>
            </IconButton>
          </div>
          <div className="y-scrollable-no-height flex-child" 
            ref={ node => {
              this.messagesAreaRef = node;
              this.paneDidMount(node);
              }}>
            <div className="messages-area">
              <div className="message-container">
              { messages.map((message, i) => this.renderMessage(message, true, i, width)) }
              { this.renderMessage(this.state.whiteboard?.message, true, messages.length, width) }
              { this.renderFloatingActionButton("50%") }
              { pinnedMessages.length > 0 ?
                <div className="pinned-messages mobile-view-pinned width-100">
                { pinnedMessages.map(message => this.renderMessage(message, false, undefined, width, {pinned: true})) }
                </div> :
                null }
              </div>
            </div>
          </div>
          <ChatWidget
            ref={ this.chatWidgetRef }
            quillRef={ this.quillRef }
            codeCell={ codeCell }
            setLang={ (lang) => this.setLang(lang) }
            showEditorTool={ showEditorTool }
            addMessageFromSendButton={ (delta) => this.addMessageFromSendButton(delta) }
            addMessage={ (delta) => this.addMessage(delta) }
            storage={ storage }
            currentUser={ currentUser }
            setTyping={ ({isEmpty, quillDelta, svg}) => this.checkThenSetTyping({isEmpty, quillDelta, svg}) }
            scrollDown={ () => this.scrollDown(500) }
            setWidgetHeight={ (height) => this.setChatWidgetHeight(height) }
            setMousetrap={() => this.setMousetrap()}/>
          </div>
        </div>
        <NotificationContainer/>
      </>
    );
  }

  renderDrawer() {
    const { drawerOpen=false, homepageUrl, messageBoard, singleColumnMode, drills, 
            assignments, codeCell, showEditorTool, callOnMe } = this.state;
    const { db, router, currentUser } = this.props;
    const projectId = router.match.params.id || null;
    const bb = currentUser.blackboard || {};
    return (
      <React.Fragment>
        <Drawer
          anchor={ 'left' }
          open={ drawerOpen }
          onClose={() => this.toggleDrawer(false)}>
          <CoursesArea
            db={ db }
            router={ router }
            projectId={ projectId }
            {...this.allProjects()}
            currentUser={ currentUser }
            afterSelect={ () => {
              this.setState({ messageBoard: false, assignments: false, drills: false });
              setTimeout(() => this.getProjectDetails().then((courseLanguage) => {
                this.getEquationEditorPreference(courseLanguage);
              }), 500)
            }}
            toggleDrawer={() => this.toggleDrawer()}
            hideCourse={ (projectId, hide) => this.hideCourse(projectId, hide) }
            blackboard={ bb }
            toggleEquationEditorVisibility={ () => this.toggleEquationEditorVisibility()}
            showEditorTool={ showEditorTool }
            toggleSingleColumnOption={ () => this.toggleSingleColumnOption() }
            singleColumnMode={ singleColumnMode }
            changeCodeCellOption={ (lang) => this.changeCodeCellOption(lang) }
            setTranscriptMode={ () => this.setTranscriptMode() }
            getTranscriptMode={ () => this.getTranscriptMode() }
            showHelp={ () => {studentHelpScreen(); this.setState({ showHelp: true});} }
            flipBackground={ this.flipBackground }
            triggerMessage={ (achievement) => this.triggerMessage(achievement.trailheadId) }
            homepageUrl={ homepageUrl }
            showMessageBoard={ messageBoard }
            showAssignments={ assignments }
            showDrills={ drills }
            codeCell={ codeCell }
            callOnMe={ callOnMe }
            setCallOnMe={ (setting) => this.setCallOnMe(setting) }
            logout={ () => this.logout() }
          />
        </Drawer>
      </React.Fragment>
    );
  }

  helpInfo() {
    const BackButton = withStyles({
      root: {
        color: "white",
        border: "1px solid white",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
      }
    })(Button);
    const button = <BackButton
        className="centered"
        onClick={ () => this.setState({ showHelp: false} )}
        variant="outlined">
        Back
    </BackButton>;
    return (<div className="help-info">
      <h1>Prismia Help</h1>

      <p>Welcome to Prismia! When you send a message, it will only go to your instructors. Your chat thread will show your messages, messages from your instructors, and messages from other students which the instructors have chosen to share with the class.</p>

      <p> Using options in the menu in the top left corner, you can drop or hide a course, change your profile information, or get a transcript of class messages over a specified date range. The gray ⊕ icon near the corner of each instructor message allows you to pin that message to the top of your window. </p>

      <p>You can add a <em>note</em> (a message which is not sent into the instructor's classroom view) by beginning the message with the note emoji <span role="img" aria-label="note">📝</span>. You can produce this emoji by typing <tt>\note</tt> and then pressing the tab key. </p>

      <p>For more detailed information, see the <a href="/guide" target="_blank" rel="noopener noreferrer">Prismia Guide</a>.</p>

      { button }

      <p><strong>Markdown shortcuts</strong>. While it is perfectly fine to use plain text for all of your Prismia messages, you can use shortcuts to format your messages if you prefer:</p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
      </ul>

      <p>Press space after typing the appropriate shortcut to apply the formatting. For help with typesetting mathematical expressions, see <a href="https://math-on-quora.surge.sh" target="_blank" rel="noopener noreferrer"><em>Beautiful Math on Quora</em></a> (starting with the second section).</p>

      <p>You can enter Unicode characters by typing its abbreviation and pressing the tab key. For a complete list of characters, see <a href="https://docs.julialang.org/en/v1/manual/unicode-input/" target="_blank" rel="noopener noreferrer">this list</a>.</p>
      <ul className="documentation-list">
        <li><tt>\pi</tt> becomes π</li>
        <li><tt>\bbZ</tt> becomes ℤ</li>
        <li><tt>\infty</tt> becomes ∞</li>
        <li><tt>\approx</tt> becomes ≈</li>
        <li><tt>\scrA</tt> becomes 𝒜</li>
        <li><tt>\frakA</tt> becomes 𝔄</li>
        <li><tt>\trademark</tt> becomes ™</li>
        <li><tt>\rightarrow</tt> becomes →</li>
        <li><tt>\:hand:</tt> becomes <span role="img" aria-label="hand">✋</span></li>
        <li><tt>\:tada:</tt> becomes <span role="img" aria-label="tada">🎉</span></li>
        <li><tt>\:smile:</tt> becomes <span role="img" aria-label="smiley face">😄</span></li>
        <li><tt>\:confused:</tt> becomes <span role="img" aria-label="confused face">😕</span></li>
        <li><tt>\:laughing:</tt> becomes <span role="img" aria-label="laughing face">😆</span></li>
        <li><tt>\idk</tt> becomes <span role="img" aria-label="shrugging person">🤷</span>. You can use this emoji to indicate to the instructor that you aren't able to answer the quesion.</li>
        <li><tt>\dgt</tt> becomes <span role="img" aria-label="clock">🕔</span>. You can use this emoji to indicate that you weren't able to answer the question in the time allowed.</li>
      </ul>
      { button }
    </div>);
  }

  render() {
    const { showHelp } = this.state;
    updateTitleBar('Chat');
    const maskCover = showHelp ? <div onClick={() => this.setState({ showHelp: false })} className="masking-cover"></div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    const pageContents = (
      <ReactResizeDetector handleWidth handleHeight>
        {
          ({ width }) => this.renderMobile(width)
        }
      </ReactResizeDetector>
    );
    return (
      <>
        { maskCover }
        { helpCard }
        { pageContents }
      </>
    );
  }

}

export default ChatView;
