import uuid from 'uuid/v4';
import { now } from './utils';

export function Project(creator) {
  return {
    id: uuid(),
    archived: false,
    timestamp: now(),
    displayName: '',
    creatorId : creator.id,
    creatorDisplayName : creator.displayName,
    creatorPhotoUrl: creator.photoUrl,
  };
}


