import React, { Component, Suspense } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { boardAttributes } from './jsx-config';
import { tryJson, hashString } from './utils';
import Button from '@material-ui/core/Button';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
const JXGBoard = React.lazy(() => import('@sswatson/jsxgraph-react-js'));
const Plot = React.lazy(() => import('react-plotly.js'));

class InteractiveGraph extends Component {

  constructor(props) {
    super(props);
    this.jxgRef = React.createRef();
    this.boardInit = this.boardInit.bind(this);
    this.lastUpdate = new Date();
    this.state = { 
      data: [], 
      layout: {}, 
      frames: [], 
      config: {}, 
      renderEngine: null
    };
  }

  componentDidMount() {
    const { code } = this.props;
    const plotly_code = tryJson(code);
    if (plotly_code) {
      if (plotly_code.layout) {
        delete plotly_code.layout.width;
        //delete plotly_code.layout.height;
        // (leaving height can make the image bigger, in a good way)
      }
      if (!plotly_code.config) {
        plotly_code.config = {displayModeBar: false};
      } else if (!plotly_code.config.displayModeBar) {
        plotly_code.config.displayModeBar = false;
      }
      this.setState({data: plotly_code.data || [],
                     layout: plotly_code.layout || {},
                     frames: plotly_code.frames || [],
                     config: plotly_code.config || {},
                     renderEngine: 'plotly'});
    } else {
      this.setState({ renderEngine: 'jsxgraph' });
    }
  }

  boardInit(board) {
    if (this.props.setTyping) {
      // wait 100 ms because otherwise the object you were just dragging
      // will still have the highlight color, which doesn't look as good
      board.on('up', () => setTimeout( () => this.checkThenUpdateTyping(), 100 ));
    }
  }

  checkThenUpdateTyping({rebound=false}={}) {
    if (rebound && !this.rebound) return;
    const now = new Date();
    if (now - this.lastUpdate < 6000) {
      setTimeout(() => this.checkThenUpdateTyping({rebound: true}), 6050);
      this.rebound = true;
      return;
    }
    this.updateTyping();
    this.lastUpdate = now;
    this.rebound = false;
  }
  
  updateTyping() {
    const svg = new XMLSerializer().serializeToString(this.jxgRef.current.state.board.renderer.svgRoot);
    this.props.setTyping({ svg, isEmpty: false });
  }

  render() {
    const { data, layout, frames, config, renderEngine } = this.state;
    const { code, hideDetails, width, ratio="100%" } = this.props;
    const codehash = hashString(code);
    let jessieCode = true, logic = code;
    if (code.includes('brd.')) {
      jessieCode = false;
      logic = brd => eval(code);
    }
    const style = {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "6px",
      marginBottom: "12px",
      width: "95%",
    }
    if (renderEngine === 'plotly') {
      return (
        <ErrorBoundary
          key={ 'error-boundary-' + codehash }
          errorMessage="[an interactive figure should be showing here, but there was either a network issue or an issue with the code that was written to generate it]"
          hideDetails={ hideDetails }>
          <Suspense fallback={<div>Loading...</div>}>
            <Plot
              data = { data }
              layout = { layout }
              frames = { frames }
              config = { config }
              onInitialized={(figure) => this.setState(figure)}
              onUpdate={(figure) => this.setState(figure)}
              useResizeHandler
              style={ style }
              />
          </Suspense>
        </ErrorBoundary>
      );
    } else if (renderEngine === 'jsxgraph') {
      const style = {
        margin: "6px auto",
        maxWidth: 500,
        position: 'relative',
      }
      if (width) {
        style.width = width;
      }
      const innerStyle = {width: "100%", height: 0, paddingBottom: "100%"};
      if (ratio) {
        innerStyle.paddingBottom = ratio;
      }
      return (
        <ErrorBoundary
          key={ 'error-boundary-' + codehash }
          errorMessage="[a manipulative should be showing here, but there was either a network issue or an issue with the code that was written to generate it]"
          hideDetails={ hideDetails }>
          <Suspense fallback={<div>Loading...</div>}>
            <div style={ style }>
              {this.props.handleSvg ? <Button
                style={{position: 'absolute', top: 3, right: 3, zIndex: 10, padding: "2px", minWidth: "25px"}}
                onClick={() => {
                  const svg = new XMLSerializer().serializeToString(this.jxgRef.current.state.board.renderer.svgRoot);
                  this.props.handleSvg(svg);
                }}><PhotoCameraIcon style={{color: '#777', transform: 'scale(0.5)'}}/></Button> : null }
              <JXGBoard
                ref={ this.jxgRef }
                key={ codehash }
                logic={ logic }
                jessieCode={ jessieCode }
                jxgInit={ JXG => {
                  JXG.Options.text.cssStyle = 'font-family: Source Sans Pro';
                  JXG.Options.text.fontSize = 12;
                  JXG.Options.axis.ticks.majorHeight = 20;
                  JXG.Options.axis.ticks.minorHeight = 10;
                } }
                boardInit={ this.boardInit }
                boardAttributes = { boardAttributes }
                style={ innerStyle }
                />
            </div>
          </Suspense>
        </ErrorBoundary>
      );
    } else {
      return null;
    }
  }
}

export default InteractiveGraph;
