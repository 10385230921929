import React, { Component } from 'react';
import toPlaintext from 'quill-delta-to-plaintext';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import HocketForm from './HocketForm';
import HocketCard from './HocketCard';
import { hocket2Message } from './message';
import blackboardUpdates from './blackboard-updates';
import fuzzy from 'fuzzy';

const extractTextFromStringifiedHocket = (stringifiedHocket) => {
  if (!stringifiedHocket) return '';
  if (typeof stringifiedHocket !== 'string') {
    try {
      return toPlaintext(stringifiedHocket.ops);
    } catch (err) {
      console.error(err);
    }
  }
  try {
    const justTheInserts = JSON.parse(stringifiedHocket).ops.filter(op => !!op.insert); 
    return toPlaintext(justTheInserts);
  } catch (err) {
    console.error(err);
    return '';
  }
};

class HocketsFilterArea extends Component {

  constructor(props) {
    super(props);
    this.db = null;
    this.unsub = {
      hockets: null,
    };
    this.state = {
      activeStudentChatId: null,
      selectedHocketId: null,
      hocketFilter: '',
      hockets: [],
    };
  }
  
  componentDidMount() {
    this.subHockets();
  }

  updateStudentBlackboard(updates, studentUserId) {
    if (!updates) return;
    if (!updates.length) return;
    if (!studentUserId) return;
    const blackboard = blackboardUpdates({}, updates);
    this.props.db
      .collection('users').doc(studentUserId)
      .collection('blackboards')
      .doc(this.props.projectId)
      .set({ blackboard }, {merge: true});
  }

  subHockets() {
    console.log('subbing hockets');
    const db = this.db = this.props.db;
    this.unsub.hockets = db
      .collection('projects')
      .doc(this.props.projectId)
      .collection('hockets')
      .onSnapshot(snap => {
        const hockets = [];
        snap.forEach(doc => {
          const hocket = doc.data();
          hockets.push(hocket);
        });
        hockets.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);
        const distilledHockets = this.distillHockets(hockets);
        this.setState({ distilledHockets, hockets });
      });
  }

  distillHockets(hockets) {
    if (!hockets) return;
    const distilledHockets = hockets.map(h => {
      let text = '';
      if (h.responses) {
        for (let i = 0; i < h.responses.length; i++) {
          text += ' ' + extractTextFromStringifiedHocket(h.responses[i]) + ' ';
        }
      }
      if (h.triggerCriteria) {
        for (let i = 0; i < h.triggerCriteria.length; i++) {
          text += ' ' + h.triggerCriteria[i] + ' ';
        }
      }
      if (h.trainingPhrases) {
        for (let i = 0; i < h.trainingPhrases.length; i++) {
          text += ' ' + h.trainingPhrases[i] + ' ';
        }
      }
      return { id: h.id, text };
    });
    return distilledHockets;
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (typeof this.unsub[key] === 'function') this.unsub[key]();
    }
  }

  setActiveStudentChatId(activeStudentChatId) {
    this.setState({ activeStudentChatId });
  }

  selectHocket(selectedHocketId) {
    if (!selectedHocketId) return null;
    this.setState({ selectedHocketId });
  }

  renderHocketCards() {
    const { hockets } = this.state;
    return (
      <div className="hocket-cards">
        { hockets.map((h, i) => <HocketCard key={i} hocket={h} label="Show" cb={ () => this.selectHocket(h.id) } />) }
      </div>
    );
  }

  // this needs to be rewritten
  filterHockets(hocketFilter) {
    let { hockets, distilledHockets } = this.state;
    const opts = {
      extract(el) {
        return el.text
      }
    };
    const filtered = fuzzy.filter(hocketFilter, distilledHockets, opts);
    let head = [];
    for (let i = 0; i < filtered.length; i++) {
      for (let j = 0; j < hockets.length; j++) {
        if (filtered[i].original.id === hockets[j].id) {
          head = head.concat(hockets.splice(j, 1));
          j--;
        }
      }
    }
    this.setState({ hocketFilter, hockets: head.concat(hockets) });
  }

  renderSearchArea() {
    return (
      <div>
        <Input
          fullWidth
          placeholder="Sort Hockets"
          onChange={ (e) => this.filterHockets(e.target.value) } />
      </div>
    );
  }

  renderHocketForm() {
    const { selectedHocketId } = this.state;
    if (!selectedHocketId) return null;
    return (
      <div className="selected-hocket-area">
        <HocketForm hocketId={ selectedHocketId } projectId={ this.props.projectId } db={ this.props.db } />
      </div>
    );
  }

  sendMessageToActiveStudent(selectedHocketId) {
    const { db, activeStudentChatId } = this.props;
    const { hockets } = this.state;
    if (!activeStudentChatId) return null;
    if (!selectedHocketId) return null;
    const hocket = hockets.filter(h => h.id === selectedHocketId)[0] || null; 
    if (!hocket) return null;
    const message = hocket2Message(hocket);
    db.collection('users')
      .doc(activeStudentChatId)
      .collection('chats')
      .doc('000-0000-000')
      .collection('messages')
      .doc(message.id)
      .set(message, {merge:true});
      // ugh this makes me feel weird, I don't like manually updating people's
      // blackboards like this
      this.updateStudentBlackboard(hocket.blackboardUpdates, activeStudentChatId);
  }


  renderControlArea() {
    const {
      selectedHocketId,
    } = this.state;
    if (!selectedHocketId) return <h2>Hocket Form Area</h2>;
    const messageSingleStudentButton = (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={ () => this.sendMessageToActiveStudent(selectedHocketId) }>
          Message Single User 
        </Button> 
    );
    return (
      <div className="padded">
        { messageSingleStudentButton }
      </div>
    );
  }

  render() {
    const { selectedHocketId } = this.state;
    let body;
    let header;
    if (selectedHocketId) {
      header = this.renderControlArea();
      body = this.renderHocketForm(); 
    } else {
      header = this.renderSearchArea();
      body = this.renderHocketCards();
    }
    return (
        <table className="frame">
          <tbody>
            <tr>
              <td className="search-area">
                { header }
              </td>
            </tr>
            <tr>
              <td>
                { body }
              </td>
            </tr>
          </tbody>
        </table>
    );
  }

}

export default HocketsFilterArea;
