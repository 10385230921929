import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

const settingsThemeColor = '#FFF';

export const settingsTheme = createMuiTheme({
  palette: {
    common: { black: settingsThemeColor, white: settingsThemeColor },
    primary: { main: settingsThemeColor, dark: settingsThemeColor, light: settingsThemeColor },
    text: { primary: settingsThemeColor, secondary: settingsThemeColor }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        }
      },
    }
  }
});

export const settingsClockTheme = createMuiTheme({
  palette: {
    common: { black: settingsThemeColor, white: settingsThemeColor },
    primary: { main: settingsThemeColor, dark: settingsThemeColor, light: settingsThemeColor },
    text: { primary: settingsThemeColor, secondary: settingsThemeColor }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        }
      }
    },
    MuiIconButton: {
      root: { color: 'white' },
    },
    MuiPickersBasePickers: {
      container:
        { color: '#CCC' },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: '#237882',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#333'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#333',
      },
      current: {
        color: '#333',
      },
      daySelected: {
        backgroundColor: '#237882',
        color: 'white',
        '&:hover': { 
          color: '#111',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
      }
    },
    MuiPaper: {
      root: { color: '#333',
              backgroundColor: 'white' }
    },
    MuiButton: {
      textPrimary: { color: '#333' },
    },
  }
});

export const getDocumentButtonListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#fffcf177" : "white",
  marginTop: "16px",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
});

export const DocumentButton = withStyles({
  root: {
    userSelect: "none",
    cursor: "pointer",
    padding: 4 * 2,
    margin: `0 0 4px 0`,
    border: "2px solid rgb(35, 120, 130)",
    backgroundColor: "rgba(35, 120, 130, 0.03)",
    "&:hover": { 
      backgroundColor: "rgba(35, 120, 130, 0.07)",
      boxShadow: "0px 4px 2px -4px rgba(35, 120, 130, 0.5), 0px 4px 4px 0px rgba(35, 120, 130, 0.21), 0px 2px 10px 0px rgba(35, 120, 130,0.16)",
    },
    borderRadius: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    color: "rgb(35, 120, 130)",
    fontWeight: "500",
    marginBottom: "16px",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.07), 0px 1px 5px 0px rgba(0,0,0,0.06)",
  }
})(ListItem);

export const getDocumentButtonStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  ...(isDragging ? {background: "lightgreen"} : {}),
  // styles we need to apply on draggables
  ...draggableStyle
});