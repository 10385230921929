import React, { Component } from 'react';
import SimpleAdminNav from '../SimpleAdminNav';
import DataTable from '../DataTable'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import moment from 'moment-timezone';

import { updateTitleBar, isRegistered, now } from '../utils';
import "./style.css";

class StudentMetricsView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      metrics: null,
    };
    this.state = {
      assignments: {},
      assignmentScores: {},
      sharedLessons: {},
      sharedHockets: {},
      liveClassDays: [],
      sharedLessonMap: {},
      lectureTable: {},
      timeZoneOffset: -5,
    };
    this.setRefs(this.props);
    this.messageIds = new Set();
  }

  componentDidMount() {
    this.setRefs(this.props);
  }

  setRefs(props) {
    this.subProject(props, () => {
      this.requestData(props);
      this.subMetrics(props);
      this.subSharedChat(props);
      this.subAssignments(props);
      this.subLiveClass(props);
    });
  }

  subProject(props, Cb) {
    const { db, projectId } = props;
    this.unsub.project = db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const project = snap.data();
        if (!project) return null;
        const timeZoneOffset = project.timeZoneOffset || -5;
        this.setState({ timeZoneOffset }, Cb);
      });
  }

  subLiveClass(props) {
    const { db, currentUser, projectId } = props;
    this.unsub['live-class'] = db.collection('users')
      .doc(currentUser.id)
      .collection('student-metrics')
      .doc(projectId)
      .collection('live-class')
      .onSnapshot(snap => {
        this.setState({ liveClassDays: 
          snap.docs.map(doc => doc.data())
        })
      })
  }

  subSharedHocket(hocketId) {
    const { db, projectId } = this.props;
    this.unsub['shared-hocket-' + hocketId] = db.collection('projects')
      .doc(projectId)
      .collection('shared-hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const data = snap.data();
        const { sharedHockets } = this.state;
        sharedHockets[hocketId] = data;
        this.setState({ sharedHockets });
      })
  }

  subSharedChat() {
    const { db, projectId } = this.props;
    this.unsub.sharedChats = db.collection('projects')
      .doc(projectId)
      .collection('shared-lessons')
      .onSnapshot(snap => {
        snap.docs.forEach(doc => {
          this.subSharedChatLesson(doc.id)
        })
      });
  }

  subSharedChatLesson(lessonId) {
    const { db, currentUser, projectId } = this.props;
    if (!projectId || !lessonId) return;
    const { timeZoneOffset } = this.state;
    db.collection('projects')
      .doc(projectId)
      .collection('shared-lessons')
      .doc(lessonId)
      .get()
      .then(doc => {
        const data = doc.data();
        if (data.title) {
          const { sharedLessons, lectureTable } = this.state;
          const lectureDate = data?.lectureDate ? moment(data?.lectureDate?.toDate()).add(timeZoneOffset, 'hours').toISOString().slice(0, 10) : null;
          sharedLessons[data.id] = {
            title: data.title,
            hockets: data.hockets.map(hocketStub => hocketStub.hocketId),
            lectureDate,
          };
          if (lectureDate && !data?.title?.startsWith("Pre-class")) {
            lectureTable[lectureDate] = data.title;
          }
          for (let hocket of data.hockets) {
            this.subSharedHocket(hocket.hocketId);
          }
          this.setState({ sharedLessons, lectureTable });
        }
      });
    this.unsub['shared-lesson-' + lessonId] = db.collection('users')
      .doc(currentUser.id)
      .collection('shared-chats')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId)
      .collection('messages')
      .onSnapshot(messageRefs => {
        const { sharedLessonMap } = this.state;
        messageRefs.docs.map(snap => snap.data()).forEach(message => {
          if (!sharedLessonMap[lessonId]) {
            sharedLessonMap[lessonId] = {};
          }
          if (!sharedLessonMap[lessonId].messages) {
            sharedLessonMap[lessonId].messages = [];
          }
          if (!this.messageIds.has(message.parent)) {
            this.messageIds.add(message.parent);
            sharedLessonMap[lessonId].messages.push(message);
          }
        });
        this.setState({ sharedLessonMap });
      });
  }

  requestData(props) {
    const { db, projectId, currentUser } = props;
    if (!db || !projectId || !currentUser) return;
    if (!this.requested) {
      this.requested = true;
      console.log('requesting data');
      db.collection('users')
        .doc(currentUser.id)
        .collection('meta')
        .doc('triggers')
        .collection('student-metrics')
        .doc(projectId)
        .set({requestMetrics: true, timestamp: now()})
        .catch(console.error);
    }
  }

  subMetrics(props) {
    if (this.unsub.metrics) this.unsub.metrics();
    const { db, projectId, currentUser } = props;
    this.unsub.assignmentMetrics = db
      .collection('users')
      .doc(currentUser.id)
      .collection('student-metrics')
      .doc(projectId)
      .collection('assignments')
      .onSnapshot(snap => {
        const assignmentScores = {};
        snap.docs.forEach(doc => {
          const data = doc.data();
          const { assignmentId, pointsScored, pointsAvailable } = data;
          assignmentScores[assignmentId] = [pointsScored, pointsAvailable];
        });
        this.setState({ assignmentScores });
      });
  }

  subAssignments() {
    const { db, projectId } = this.props;
    this.unsub.assignments = db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .onSnapshot( snap => {
        const assignments = {};
        snap.docs.forEach(doc => {
          const { id, title, timestamp, deadline } = doc.data();
          assignments[id] = {title, timestamp, deadline};
        })
        this.setState({ assignments });
      })
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (typeof this.unsub[key] === 'function') this.unsub[key]();
    }
  }

  requiresResponse(hocket) {
    return hocket?.suggestions?.length || isRegistered(hocket);
  }

  lectureLookup(date) { 
    return this.state.lectureTable?.[date] || date;
  }

  render() {
    updateTitleBar('Metrics');
    const { db, router, projectId, currentUser={} } = this.props;
    const { 
      assignments, 
      assignmentScores, 
      sharedLessonMap, 
      sharedLessons, 
      sharedHockets,
      liveClassDays,
      timeZoneOffset,
    } = this.state;
    const columnDefs = [{
      headerName: 'Type',
      field: 'type',
    }, {
      headerName: 'Name',
      field: 'name',
    }, {
      headerName: 'Date',
      field: 'date',
    }, {
      headerName: 'Points scored',
      field: 'pointsScored',
    },{
      headerName: 'Points available',
      field: 'pointsAvailable',
    }, {
      headerName: 'Percentage',
      field: 'percentage',
    }];
    const getDeadline = id => {
      try {
        return moment(assignments[id].deadline.toDate()).add(timeZoneOffset, 'hours').toISOString().slice(0, 10);
      } catch {
        return '';
      }
    }
    const assignmentRowData = assignmentScores ? 
      Object.keys(assignmentScores).map(id => ({
        type: 'Assignment', 
        name: assignments[id]?.title || '', 
        date: getDeadline(id) || '',
        pointsScored: assignmentScores[id][0],
        pointsAvailable: assignmentScores[id][1],
        percentage: Math.round(100 * assignmentScores[id][0] / assignmentScores[id][1]),
      }))
      .filter(row => row.pointsAvailable)
      .sort((row1, row2) => row1.date < row2.date ? -1 : 1) : [];
    const sharedLessonRowData = sharedLessonMap ? Object.keys(sharedLessonMap).map(id => {
      const pointsScored = sharedLessonMap[id]?.messages?.filter(message => this.requiresResponse(sharedHockets?.[message.parent])).length || 0;
      const pointsAvailable = sharedLessons[id]?.hockets?.map(id => sharedHockets[id]).filter(this.requiresResponse).length || 0;
      const percentage = Math.round(100 * pointsScored / pointsAvailable);
      return {
        type: 'Shared Lesson',
        name: sharedLessons[id]?.title,
        date: sharedLessons[id]?.lectureDate,
        pointsScored,
        pointsAvailable,
        percentage,
      }
    })
    .filter(row => row.pointsAvailable)
    .sort((row1, row2) => row1.date < row2.date ? -1 : 1) : [];
    const liveClassRowData = liveClassDays.map(record => ({
      type: "Live Class",
      name: this.lectureLookup(record.date),
      date: record.date,
      pointsScored: record.openResponseAnswered + record.multipleChoiceAnswered,
      pointsAvailable: record.openResponseAsked + record.multipleChoiceAsked,
    }))
    .map(row => ({...row,
      pointsScored: Math.min(row.pointsAvailable, 
        row.pointsScored + 3,
      ),
    }))
    .map(row => ({...row, 
      percentage: Math.round(100 * row.pointsScored/row.pointsAvailable)
    }))
    .filter(row => row.pointsAvailable)
    .sort((row1, row2) => row1.date < row2.date ? -1 : 1);
    const rowData = [
      ...assignmentRowData, 
      ...sharedLessonRowData,
      ...liveClassRowData,
    ];
    return (
      <div className="student-metrics-view full-height">
        <SimpleAdminNav
          currentUser={ currentUser }
          projectId={ projectId }
          db={ db }
          router={ router }
          studentView
        />
        <div style={{position: "relative", height: "calc(100% - 150px)"}}>
          <h2 className="title centered">Class Metrics</h2>
          <DataTable
            exportColumnKeys={ columnDefs.map(col => col.field) }
            columnDefs={ columnDefs }
            rowData={ rowData }
            groupingVariable={ 'type' }
            sizeToFit/>
        </div>
      </div>
    );
  }

}

export default StudentMetricsView;
