import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import ReactResizeDetector from 'react-resize-detector';
import toPlaintext from 'quill-delta-to-plaintext';
import handlebars from 'handlebars';
import moment from 'moment';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/Check';
import 'react-quill/dist/quill.snow.css';
import Hocket from '../hocket';
import ReadOnlyQuill from '../ReadOnlyQuill';
import Students from '../Students';
import StudentData from './StudentData';
import HocketForm from '../HocketForm';
import HocketsFilterArea from '../HocketsFilterArea';
import SimpleAdminNav from '../SimpleAdminNav';
import { serializeHocket } from '../serialization';
import { now, removeTerminalNewlinesFromQuillDelta } from '../utils';
import { sparseFormQuillModules } from '../quill-config';
import { safePhotoUrl } from '../profile-emojis';
import { Message } from '../message';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const defaultDelta = "{\"ops\":[{\"insert\":\"\\n\"}]}";

function d2html(deltaOps) {
  console.log(deltaOps);
  if (!deltaOps)  return '';
  if (typeof deltaOps === 'string') {
    deltaOps = JSON.parse(deltaOps);
  }
  if (deltaOps.ops) {
    deltaOps = deltaOps.ops;
  }
  const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
  const html = converter.convert();
  console.log(html);
  console.log(typeof html);
  return html;
}

function CombinationButton({combine, student}) {
  const classNames = "combine-messages " + (student ? "student-side" : '');
  return (
    <div className={ classNames } >
      <div onClick={ combine } className="combine-button">
        combine
      </div>
    </div>
  );
}


handlebars.registerHelper('either', function(first, second, third) {
  return first || second || third;
});

handlebars.registerHelper('or', () => false);

const ELI_CHAT_ID = '000-0000-000';

function processResponse(quillDelta, blackboard={}) {
  const response = quillDelta.ops.map(insertion => {
    if (typeof insertion.insert !== 'string') return insertion;
    const template = handlebars.compile(insertion.insert);
    insertion.insert = template(blackboard);
    return insertion;
  });
  return { ops: response };
}

class InstructorChatView extends Component {
  constructor(props) {
    super(props);
    this.studentId = null;
    this.keysPressed = {};
    this.synth = null;
    this.chatRef = null;
    this.quillRef = React.createRef();
    this.unsub = {
      chat: null,
      user: null,
    };
    this.state = {
      instructorDriven: true,
      student: null,
      studentId: null,
      hocketId: null,
      messages: [],
      messageLimit: 3,
      messageValue: '',
      quillDelta: null,
      showHocketsFilterArea: false,
    };
  }

  componentDidMount() {
    this.setRefs(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { nav } = this.props;
    if (!newProps.nav.match.params.id) return console.log('no nav match id', nav);
    if (newProps.nav.match.params.id === nav.match.params.id) return;
    this.setRefs(newProps);
  }

  setRefs(props) {
    if (this.unsub.chat) this.unsub.chat();
    if (this.unsub.user) this.unsub.user();
    const { db, currentUser, firestore, nav, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    if (!nav.match.params.id) return console.log('no nav match id', nav);
    const studentId = nav.match.params.id;
    this.studentId = studentId;
    this.firestore = firestore;
    /*
    this.bbRef = db
      .collection('users').doc(studentId)
      .collection('blackboards').doc(projectId);
    */
    this.chatRef = db
      .collection('users').doc(studentId)
      .collection('chats').doc(projectId)
      .collection('messages');
    this.subChat();
    //this.subBlackboard();
    this.unsub.user = db.collection('users').doc(studentId)
      .onSnapshot(doc => {
        const student = doc.data();
        const { instructorDriven } = student;
        this.setState({ instructorDriven, student, studentId });
      });
  }

  subChat() {
    if (this.unsub.chat) this.unsub.chat();
    this.unsub.chat = this
      .chatRef
      .orderBy('timestamp', 'desc')
      .limit(this.state.messageLimit)
      .onSnapshot(snap => {
        const messages = [];
        snap.forEach(doc => {
          messages.push(doc.data());
        });
        messages.reverse();
        this.setState({ messages });
      });
  }

  subBlackboard() {
    if (this.unsub.blackboard) this.unsub.blackboard();
    this.unsub.blackboard = this
      .bbRef
      .onSnapshot(snap => {
        const blackboard = snap.data() || {};
        this.setState({ blackboard });
      });
  }

  increaseMessageLimit() {
    const { messageLimit } = this.state;
    this.setState({ messageLimit: messageLimit + 6 }, () => {
      this.subChat();
    });
  }

  componentWillUnmount() {
    if (this.unsub.chat) this.unsub.chat();
    if (this.unsub.user) this.unsub.user();
  }

  toggleInstructorDriven() {
    let { studentId, instructorDriven } = this.state;
    if (instructorDriven) {
      instructorDriven = false;
    } else {
      instructorDriven = this.props.currentUser.displayName || true;
    }
    this.props.db.collection('users').doc(studentId)
      //.collection('chats').doc(ELI_CHAT_ID)
      .set({ instructorDriven }, { merge: true }).then(() => {
        this.setState({ instructorDriven });
      }).catch(console.error);
  }

  renderSwitch() {
    const { instructorDriven } = this.state;
    const label = instructorDriven ? 'Instructor Driven' : 'SamBot Driven';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ !!instructorDriven }
              onChange={ () => this.toggleInstructorDriven() }
              value="instructorDriven" />
            }
          label={ label }
        />
      </FormGroup>
    );
  }

  handleChange(content, delta, source, editor) {
    this.setState({
      quillDelta: editor.getContents(),
      messageValue: content,
    });
  }

  addMessage(textContent) {
    const { currentUser={} } = this.props;
    let { quillDelta } = this.state;
    quillDelta = removeTerminalNewlinesFromQuillDelta(quillDelta);
    const userId = ELI_CHAT_ID;
    const message = Message(
      processResponse(quillDelta, {}),
      textContent,
      userId);
    message.authorPhotoUrl = currentUser.photoUrl || '';
    message.authorDisplayName = currentUser.displayName || '';
    message.instructorVerified = true;
    this.chatRef.doc(message.id)
      .set(message)
      .then(snap => {
        this.setState({ messageValue: null, quillDelta: null });
      }).catch(console.error);
  }

  keyChecker(e) {
    this.keysPressed[e.keyCode] = e.type === 'keydown';
  }

  keyUp(e) {
    this.keyChecker(e);
  }

  keyDown(e, quillRef) {
    this.keyChecker(e);
    if (e.keyCode === 27) { // escape key
      quillRef.current.blur();
      return;
    }
    if (e.keyCode !== 13) return;
    // if we're at this point, we know 13 has been pressed
    // so we check if shift has been pressed too
    if (this.keysPressed[16]) return;
    // if it's just enter and not enter + shift, we add the message
    if (!e.target.textContent.trim()) return;
    const editor = quillRef.current.editor;
    const cursorPosition = editor.getSelection().index;
    editor.deleteText(cursorPosition - 1, 1);
    this.addMessage(e.target.textContent.trim());
  }

  deleteMessage(id) {
    this.chatRef.doc(id).delete();
  }

  addSuggestedMessage(id) {
    this.chatRef
      .doc(id)
      .set({
        responseSuggestion: false,
        instructorVerified: true,
      }, {merge: true}).then(() => {
      }).catch(console.error);
  }

  getMessageBefore(id) {
    let { messages } = this.state;
    if (!messages.length) return;
    if (messages[0].id === id) return;
    let priorMessage = messages[0];
    for (let i = 0; i < messages.length; i++) {
      if (i === 0) continue;
      if (messages[i].id === id) {
        if (priorMessage.author === ELI_CHAT_ID) return;
        return priorMessage;
      }
      priorMessage = messages[i];
    }
  }

  emailMessage(message) {
    const { db, projectId } = this.props;
    const { student } = this.state;
    if (!message) return;
    if (!message.id) return;
    if (!student) return;
    const priorMessage = this.getMessageBefore(message.id);
    let body = "You've received a message from an instructor on Prismia.";
    if (priorMessage) {
      body += "<h2>You:</h2>";
      body += d2html(priorMessage.quillDelta);
    }
    body += "<h2>Your instructor:</h2>";
    body += d2html(message.quillDelta);
    body += "[<a href='https://prismia.chat/chat/" + projectId +  "'>link to chat</a>]";
    const payload = {
      id: message.id,
      studentId: student.id,
      subject: 'Prismia: private message from instructor',
      body,
      timestamp: now(),
    };
    db.collection('triggers')
      .doc('email-student')
      .collection('messages')
      .doc(message.id)
      .set(payload);
    return this.chatRef.doc(message.id)
      .set({hasBeenEmailed: true}, {merge: true});
  }

  createHocket(tfMessage, resMessage) {
    const { db } = this.props;
    const trainingPhrases = [];
    if (tfMessage) trainingPhrases.push(tfMessage.textContent);
    const responses = [];
    if (resMessage) responses.push(JSON.parse(resMessage.quillDelta));
    const hocket = Hocket(trainingPhrases, responses);
    const parentHocketRef = db
      .collection('hockets')
      .doc(hocket.id);
    parentHocketRef.set(serializeHocket(hocket))
      .then(() => {
        if (tfMessage && tfMessage.id) {
          this.chatRef.doc(tfMessage.id)
            .set(
              { parentHocketRef },
              { merge: true });
        }
        if (resMessage && resMessage.id) {
          this.chatRef.doc(resMessage.id)
            .set(
              { parentHocketRef },
              { merge: true });
        }
      }).catch(console.error);
  }

  combineMessages(m1, m2) {
    if (!m1 || !m2) return console.log('missing a message');
    if (!m1.quillDelta || !m2.quillDelta) return console.log('missing a delta');
    //if (m1.parentHocketRef || m2.parentHocketRef) return console.log('no parents allowed');
    // the quill deltas start stringified, so we need to undo that
    let parsedM1Delta = JSON.parse(m1.quillDelta);
    let parsedM2Delta = JSON.parse(m2.quillDelta);
    // then we'll add a newline in the middle.
    parsedM1Delta.ops = parsedM1Delta.ops.concat(JSON.parse(defaultDelta).ops);
    // then add the second message to the first
    parsedM1Delta.ops = parsedM1Delta.ops.concat(parsedM2Delta.ops);
    // get the new plaintext
    m1.textContent = toPlaintext(parsedM1Delta.ops);
    // and re-stringify the results
    m1.quillDelta = JSON.stringify(parsedM1Delta);
    // and finally, save the updated first message
    this.chatRef.doc(m1.id).set(m1, { merge: true })
      .then(() => {
        // and delete the second
        return this.chatRef.doc(m2.id).delete();
      }).catch(console.error);
  }

  addTrainingPhraseToHocket(tfMessage, resMessage) {
    if (!resMessage || !tfMessage) return;
    if (!resMessage.parentHocketRef) return this.createHocket(tfMessage, resMessage);
    // okay with all those guard clauses out of the way, now we can do the thing
    const { db } = this.props;
    const tf = toPlaintext(JSON.parse(tfMessage.quillDelta).ops);
    this.chatRef.doc(tfMessage.id).set(
      { parentHocketRef: resMessage.parentHocketRef}, { merge: true });
    db.collection('hockets').doc(resMessage.parentHocketRef.id)
      .update({ trainingPhrases: firebase.firestore.FieldValue.arrayUnion(tf) });
  }

  renderAddTrainingPhraseToHocket(tfMessage, resMessage, index) {
    const addIcon = <AddIcon className="add-icon" onClick={ () => this.addTrainingPhraseToHocket(tfMessage, resMessage) } />;
    const checkIcon = <CheckIcon className="check-icon"/>;
    let connected = false;
    if (tfMessage.parentHocketRef && resMessage.parentHocketRef) {
      connected = tfMessage.parentHocketRef.id === resMessage.parentHocketRef.id;
    }
    return (
      <div className="add-training-phrase-to-hocket-button" key={ index } >
        { connected ? checkIcon : addIcon }
      </div>
    );
  }

  displayStudentDataInSidebar() {
    // the default is to show the student data
    this.setState({ hocketId: null });
  }

  displayHocketFormInSidebar(hocketId) {
    if (!hocketId) return;
    this.setState({ hocketId });
  }

  renderMessage(message) {
    let initial = <img className="instructor-bubble" alt="sambot" src={ safePhotoUrl(message.authorPhotoUrl) } />;
    let initialBubble = null;
    let questionBadge = null;
    let fromStudent = '';
    const className = message.responseSuggestion ? 'suggestion' : null;
    if (message.author === ELI_CHAT_ID) {
      const hocketClassNames = ['bottom-badge', 'hocket-badge'];
      if (message.parentHocketRef) {
        hocketClassNames.push('active');
      } else {
        hocketClassNames.push('inactive');
      }
      if (message.hasBeenEmailed) {
        questionBadge = <div className="bottom-badge question-badge active"><EmailIcon /></div>;
      } else {
        questionBadge = (
          <div
            className="bottom-badge question-badge inactive"
            onClick={ () => this.emailMessage(message) }>
            <EmailIcon />
          </div>
        );
      }
      fromStudent =  'instructor-message';
      initial = <img className="instructor-bubble" alt="sambot" src="/instructor-bubble.svg" />;
      if (message.authorPhotoUrl) {
        initial = <img className="instructor-bubble" alt="sambot" src={ safePhotoUrl(message.authorPhotoUrl) } />;
      }
      initialBubble = (
        <div className="message-bubble">
          { initial }
        </div>
      );
    } else {
      fromStudent = 'student-message';
      initialBubble = (
        <div onClick={ () => this.displayStudentDataInSidebar() } className="message-bubble">
          { initial }
        </div>
      );
    }
    let timestamp;
    try {
      timestamp = message.timestamp.toDate();
    } catch (err) {
      // console.log('old timestamp format');
      timestamp = message.timestamp;
    }
    return (
      <div key={ message.id } className={ className + ' ' + fromStudent}>
        <p className="message-time-ago">{ moment(timestamp).fromNow() }</p>
        <div className="quill-wrapper" >
          { <ReadOnlyQuill quillDelta={ JSON.parse(message.quillDelta) } /> }
        </div>
        { initialBubble }
        { questionBadge }
        { className ? <Button variant="contained" color="primary" onClick={ () => this.addSuggestedMessage(message.id) }>Accept Suggested Message</Button> : null }
        { className ? <Button variant="contained" color="secondary" onClick={ () => this.deleteMessage(message.id) }>Delete</Button> : null }
      </div>
    );
  }

  renderChatArea(width, height) {
    const { currentUser } = this.props;
    const { instructorDriven, messages } = this.state;
    let control = instructorDriven ? 'You are posing as SamBot' : null;
    if (instructorDriven && currentUser.displayName !== instructorDriven) {
      control = instructorDriven + ' is posing as SamBot';
    }
    const renderedMessages = [];
    let priorMessageIsFromStudent = false;
    let combinationButton = null;
    for (let i = 0; i < messages.length; i++) {
      const isEli = messages[i].author === ELI_CHAT_ID;
      if (priorMessageIsFromStudent && isEli) {
        //renderedMessages.push(this.renderAddTrainingPhraseToHocket(tfMessage, resMessage, i));
      } else if (priorMessageIsFromStudent && !isEli) {
        // two student messages
        combinationButton = <CombinationButton student combine={() => this.combineMessages(messages[i - 1], messages[i]) } key={ i + 'combo' } />;
        renderedMessages.push(combinationButton);
      } else if (i > 0 && !priorMessageIsFromStudent && isEli) {
        // two Elijah messages
        combinationButton = <CombinationButton combine={() => this.combineMessages(messages[i - 1], messages[i]) } key={ i + 'combo' } />;
        renderedMessages.push(combinationButton);
      }
      renderedMessages.push(this.renderMessage(messages[i]));
      priorMessageIsFromStudent = messages[i].author !== ELI_CHAT_ID;
    }
    const messageAreaStyle = {
      height: window.innerHeight - 64,
    }
    return (
      <div style={messageAreaStyle} className="instructor-chat-area flex-child">
        <div className="centered">
          <Button
            variant="contained"
            onClick={ () => this.increaseMessageLimit() }
            >
            Load More Messages
          </Button>
        </div>
        { renderedMessages }
        <div className="instructor-chat-input-area">
          <div className="control">
            <h2>{ control }</h2>
          </div>
          <ReactQuill
            value={this.state.messageValue}
            modules={ sparseFormQuillModules }
            ref={ this.quillRef }
            onChange={(...args) => this.handleChange(...args)}
            onKeyUp={ e => this.keyUp(e) }
            onKeyDown={ e => this.keyDown(e, this.quillRef) }
          />
        </div>
      </div>
    )

  }

  renderInfoArea() {
    const { currentUser } = this.props;
    return (
      <Students currentUser={ currentUser } db={ this.props.db } />
    );
  }

  renderHocketForm() {
    const { hocketId } = this.state;
    if (!hocketId) return null;
    return <HocketForm hocketId={ hocketId } db={ this.props.db } />
  }

  renderHocketsFilterArea() {
    const { studentId } = this.state;
    return (
      <>
        <Button
          onClick={ () => this.setState({ showHocketsFilterArea : false }) }
          >
          Hide Hocket Filter
        </Button>
        <HocketsFilterArea
          activeStudentChatId={ studentId }
          db={ this.props.db }
        />
      </>
    );
  }

  renderStudentData() {
    const { studentId } = this.state;
    const { projectId } = this.props;
    if (!studentId) return null;
    return (
        <StudentData studentId={ studentId } db={ this.props.db } projectId={ projectId } />
    );
  }

  renderMobile(width, height) {
    const { student } = this.state;
    return (
      <div className="instructor-chat mobile">
        <div className="flex-container">
          <ReactResizeDetector handleWidth handleHeight>
          { ({width, height}) => {
            return student ?
              this.renderChatArea(width, height) :
              this.renderInfoArea(width, height);
            }
          }
          </ReactResizeDetector>
        </div>
      </div>
    );
  }

  renderDesktop(width, height) {
    return (
      <div 
        style={{position: "relative"}}
        className="instructor-chat-view full-height">
        <SimpleAdminNav projectId={ this.props.projectId } />
        <div 
          style={{position: "relative", height: "calc(100% - 60px)"}}
          className="flex-container">
          <div className="y-scrollable flex-child">
            { this.renderStudentData() }
          </div>
          <div 
            style={{
              borderLeft: "1px solid #777",
            }}
            className="instructor-chat flex-four">
            <div className="y-scrollable padded">
              { this.renderChatArea() }
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({width, height}) => {
            if (width > 500) return this.renderDesktop(width, height);
            return this.renderMobile(width, height);
          }
        }
      </ReactResizeDetector>
    );
  }

}

export default InstructorChatView;
