import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NestedMenuItem from "material-ui-nested-menu-item";
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CsvTable from '../CsvTable';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import InteractiveGraph from '../InteractiveGraph';
import { CollapsibleJupyterCell } from '../JupyterCell';
import { binderKernels } from '../jupyter';
import DateFnsUtils from '@date-io/date-fns';
import { projectSortFunc, extractCodeBlock } from '../utils';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import moment from 'moment';
import './style.css';

const CloseButton = withStyles({
  root: {
    flex: 'none',
    color: 'white',
    opacity: 0.5,
  },
})(IconButton);

class CoursesArea extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      achievements: null,
    };
    this.openMenu = this.openMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      achievements: [],
      dropMode: false,
      transcriptDateSelector: null,
      transcriptStartDate: new Date(),
      transcriptEndDate: new Date(),
      transcriptMessages: null,
      transcriptMode: false,
      hiddenCourses: new Set(),
      menuOpenRef: null,
    };
  }

  goToStudentMetrics(projectId) {
    const { router } = this.props;
    router.history.push('/projects/' + projectId + '/student-metrics');
  }

  goToClassroom(projectId) {
    const { router } = this.props;
    router.history.push('/projects/' + projectId + '/classroom');
  }

  goToAssignments(projectId) {
    const { router } = this.props;
    router.history.push('/projects/' + projectId + '/assignments');
  }

  goToDrills(projectId) {
    const { router } = this.props;
    router.history.push('/projects/' + projectId + '/drills');
  }

  goToMessageBoard(projectId) {
    const { router } = this.props;
    router.history.push('/projects/' + projectId + '/message-board');
  }

  openTranscriptDateSelector(projectId) {
    this.setState( {transcriptDateSelector: projectId} );
  }

  getTranscript(projectId) {
    const { db, currentUser, flipBackground } = this.props;
    const { transcriptStartDate, transcriptEndDate } = this.state;
    db.collection('users').doc(currentUser.uid || currentUser.id)
     .collection('chats').doc(projectId)
     .collection('messages')
     .where('timestamp', '>', transcriptStartDate)
     .where('timestamp', '<', transcriptEndDate)
     .orderBy('timestamp')
     .limit(250)
     .get()
     .then( (snap) => {
       const messages = {};
       snap.docs.forEach( doc => {
         messages[doc.id] = doc.data();
       });
       return messages;
     })
     .then( (messages) => {
      db.collection('projects').doc(projectId).collection('class-messages')
      .where('timestamp', '>', transcriptStartDate)
      .where('timestamp', '<', transcriptEndDate)
      .orderBy('timestamp')
      .limit(250)
      .get()
      .then( (snap) => {
        snap.docs.forEach( doc => {
          messages[doc.id] = doc.data();
        });
        const messagesArray = Object.values(messages).sort( 
          (m1, m2) => m1.timestamp < m2.timestamp ? -1 : 1
        ).slice(0, 250);
        this.setState( { transcriptMessages: messagesArray } );
        flipBackground();
      })
     })
     .catch(console.error);
  }

  hideCourse(projectId, hide) {
    if (this.props.hideCourse) this.props.hideCourse(projectId, hide);
  }

  dropCourse(projectId) {
    const { db, currentUser } = this.props;
    const batch = db.batch();
    batch.set(db.collection('meta')
      .doc('roles')
      .collection('students')
      .doc(currentUser.id),
      { [projectId]: false }, { merge: true });
    batch.set(db.collection('meta')
      .doc('roles')
      .collection('tas')
      .doc(currentUser.id),
      { [projectId]: false }, { merge: true });
    batch.set(db.collection('meta')
      .doc('roles')
      .collection('instructors')
      .doc(currentUser.id),
      { [projectId]: false }, { merge: true });
    batch.commit().catch(console.error);
  }

  handleStartDateChange(date) {
    this.setState( {transcriptStartDate: date} );
  }

  handleEndDateChange(date) {
    this.setState( {transcriptEndDate: date} );
  }

  renderSuggestions(suggestions) {
    return <div className="suggestion-chips">
      { suggestions.map((sug, i) => <button
          className={ "no-outline" }
          key={ i }>
          { sug.value || sug } 
        </button>)
      }
    </div>;
  }

  renderProjectCard(project) {
    const { projectId='main', router, showMessageBoard, taOf, instructorOf,
            hiddenCourses, showAssignments, showDrills, projectsOwned } = this.props;
    const owner = !!projectsOwned[project.id];
    const classroomAccess = (projectsOwned[project.id] ||
                                taOf[project.id] || instructorOf[project.id]);
    const { dropMode,
            transcriptMode,
            transcriptDateSelector,
            transcriptStartDate,
            transcriptEndDate } = this.state;
    const activeProjectId = projectId;
    if (!project.id) return null;
    let cssClasses = "project-card ";
    let active = false;
    if (activeProjectId === project.id) {
      active = true;
      cssClasses += 'active-project';
    }
    const subtext = (
      <p className="subtext active">
        Currently Active Class
      </p>
    );
    const goToClassroomButton = (
      <div
        className="go-to-classroom-button">
        <Button
          onClick={() => this.goToClassroom(project.id)}
          variant="outlined">
            Classroom
        </Button>
      </div>
    );
    const assignmentsButton = (
      <div
        className="go-to-classroom-button">
        <Button
          onClick={() => this.goToAssignments(project.id)}
          variant="outlined">
            Assignments
        </Button>
      </div>
    );
    const drillsButton = (
      <div
        className="go-to-classroom-button">
        <Button
          onClick={() => this.goToDrills(project.id)}
          variant="outlined">
            Drills
        </Button>
      </div>
    );
    const messageBoardButton = (
      <div
        className="go-to-classroom-button">
        <Button
          onClick={() => this.goToMessageBoard(project.id)}
          variant="outlined">
            Message Board
        </Button>
      </div>
    );
    const hideButton = (
      <Button
        className="drop-button project-card-action-button"
        variant="contained"
        color="primary"
        onClick={ () => this.hideCourse(project.id, !hiddenCourses.has(project.id))}>
        {hiddenCourses.has(project.id) ? "Unhide" : "Hide" }
      </Button>
    );
    const dropButton = (
      <Button
        className="drop-button project-card-action-button"
        variant="contained"
        color="secondary"
        onClick={ () => this.dropCourse(project.id)}>
        Drop
      </Button>
    );
    const transcriptButton = (transcriptDateSelector === project.id) ? (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="margin-below">
          <KeyboardDateTimePicker
            id="transcript-start-date"
            ampm={false}
            label="Start Time"
            value={ transcriptStartDate }
            onChange={(date) => this.handleStartDateChange(date)}
          />
        </div>
        <div className="margin-below">
          <KeyboardDateTimePicker
            id="transcript-end-date"
            ampm={ false }
            label="End Time"
            value={ transcriptEndDate }
            onChange={ (date) => this.handleEndDateChange(date) }
          />
        </div>
        <div>
          <Button
            variant="contained"
            className="project-card-action-button"
            onClick={ () => this.getTranscript(project.id) }>
            Go
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    ) : (
      <Button
        className="project-card-action-button"
        variant="contained"
        color="primary"
        onClick={ () => this.openTranscriptDateSelector(project.id) }>
        Transcript
      </Button>
    );
    return (
      <div className={ cssClasses } key={project.id}>
        <Button
          fullWidth
          onClick={ () => {
            router.history.push("/chat/" + project.id);
            if (this.props.afterSelect) {
              this.props.afterSelect();
            }
          }}>
          <Typography
            component='span'
            style={{
              textTransform: "none",
            }}>
            <h2>{ project.displayName }</h2>
              { active ? subtext : null }
            <p className={ active ? "active" : ""}>
              { project.description }
            </p>
          </Typography>
        </Button>
        { active ? <div className="chat-button-container">
          { (classroomAccess && !dropMode && !transcriptMode) ? goToClassroomButton : null }
          { (showMessageBoard && !dropMode && !transcriptMode) ? messageBoardButton : null }
          { (showAssignments && !dropMode && !transcriptMode) ? assignmentsButton : null }
          { (showDrills && !dropMode && !transcriptMode) ? drillsButton : null }
        </div> : null }
        { dropMode ? hideButton : null }
        { (!owner && dropMode) ? dropButton : null }
        { transcriptMode ? transcriptButton : null }
      </div>
    );
  }

  renderTranscriptCard(message) {
    const { currentUser } = this.props;
    let cssClasses = "transcript-message-card";
    if (message.sharedFromClass) {
      cssClasses += " shared-from-class";
    } else if (message.author === currentUser.id) {
      cssClasses += " sent-by-current-user";
    }
    return (
      <div
        className={ cssClasses }
        key={ message.id }>
        <div className="transcript-timestamp float-right">
          { moment(message.timestamp.toDate()).format('MMMM Do YYYY, HH:mm:ss') }
        </div>
        <div className="transcript-author-name">
          { message.authorDisplayName }
        </div>
        <ReactQuill
          readOnly
          modules={ { toolbar: false } }
          value={ JSON.parse(message.quillDelta) }
        />
        { message.jessieCode ? this.renderJSXGraph(message.jessieCode, message.aspectRatio) : null }
        { (message.tableData?.length || message.tableData?.string?.length) ? this.renderTable(typeof message.tableData === 'string' ? {string: message.tableData} : message.tableData) : null }
        { message.urlIFrame ? 
          <iframe 
            frameborder="0" 
            width="100%" 
            src={message.urlIFrame} 
            height={(message.heightIFrame || 0) + 'px'}>   
          </iframe> : null }
        { message.codeCell ?
            <div
              className="on-card-code-cell"
              onClick={ e => e.stopPropagation() }>
              <CollapsibleJupyterCell
                content={ extractCodeBlock(message.quillDelta) }
                language={ this.props.codeCell || 'python' }/>
            </div> : null }
      { message.suggestions ? this.renderSuggestions(message.suggestions) : null }
      </div>
    );
  }

  renderTable(data) {
    return <CsvTable data={ data }/>
  }

  renderJSXGraph(jessieCode, ratio) {
    return (
      <InteractiveGraph
        code={ jessieCode }
        ratio={ ratio }
        hideDetails/>
    );
  }

  renderTranscriptMessages() {
    const { transcriptMessages,
            transcriptStartDate,
            transcriptEndDate } = this.state;
    const { flipBackground } = this.props;
    return (
      <>
      <h3 className="centered">Class Transcript</h3>
      <p className="centered">
       { moment(transcriptStartDate).format('MMMM Do YYYY, HH:mm:ss') } to <br/>{ moment(transcriptEndDate).format('MMMM Do YYYY, HH:mm:ss') }
      </p>
      { transcriptMessages.length >= 250 ? <p className="centered">(250 message limit reached)</p> : null }
      { transcriptMessages.length ? transcriptMessages.map((message) => this.renderTranscriptCard(message)) : <div className="centered no-messages-warning"><p>No messages were sent in this class in that time period.</p></div>}
      <div className="small-padded">
        <Button
          variant="contained"
          fullWidth
          className="project-card-action-button"
          onClick={ () => {
            const { setTranscriptMode } = this.props;
            this.setState({ transcriptMessages: null,
                            transcriptMode: false });
            setTranscriptMode(false);
            flipBackground('dark');
          }}>
          Back
        </Button>
      </div>
      </>
    );
  }

  handleClose() {
    this.setState({ menuOpenRef: null });
  };

  openMenu(event) {
    this.setState({ menuOpenRef: event.currentTarget });
  }

  getProjects() {
    let {
      projectsAttended={},
      projectsOwned={},
      instructorOf={},
      taOf={},
    } = this.props;
    let instructed = {};
    let taed = {};
    let attended = {};
    const allProjects = {};
    for (let key in projectsAttended) {
      if (!projectsOwned[key] && !instructorOf[key] && !taOf[key]) {
        attended[key] = projectsAttended[key];
        allProjects[key] = projectsAttended[key];
      }
    }
    for (let key in taOf) {
      if (!taOf[key]) continue;
      if (!projectsOwned[key] && !instructorOf[key]) taed[key] = taOf[key];
      allProjects[key] = taOf[key];
    }
    for (let key in instructorOf) {
      if (!instructorOf[key]) continue;
      if (!projectsOwned[key]) instructed[key] = instructorOf[key];
      allProjects[key] = instructorOf[key];
    }
    for (let key in projectsOwned) {
      if (!projectsOwned[key]) continue;
      allProjects[key] = projectsOwned[key];
    }
    const projectList = Object.values(allProjects);
    projectList.sort(projectSortFunc);
    return projectList;
  }

  logout() {
    this.props.logout();
  }

  render() {
    let { transcriptMessages, menuOpenRef,
          dropMode, transcriptMode } = this.state;
    const { router, homepageUrl, codeCell,
            hiddenCourses, callOnMe } = this.props;
    if (transcriptMessages) {
      return (
        <div className="y-scrollable transcript-area">
          { this.renderTranscriptMessages() }
        </div>
      );
    }
    const backButton = (
      <Button
        variant="contained"
        style={{marginTop: "8px", marginBottom: "8px"}}
        onClick={() => {
          const { setTranscriptMode } = this.props;
          setTranscriptMode(false); // turn off in parent
          this.setState({ dropMode: false});
          this.setState({ transcriptMode: false });
        }}>
        Done
      </Button>
    );
    const hide = (project) => project.archived || (hiddenCourses.has(project.id) && !dropMode)
    const checkIcon = <CheckIcon style={{marginLeft: "3px"}} fontSize="small"/>;
    return (
      <div className="y-scrollable position-relative">
        <div className="courses-area centered">
          <div className="courses-top-bar">
            <MenuIcon
              style={{
                float: "left",
                color: "white",
                marginLeft: "12px",
                marginTop: "12px",
                fontSize: "40px",
                textAlign: "right",
              }}
              onClick={ this.openMenu }/>
            <Menu
              open={ Boolean(menuOpenRef) }
              anchorEl={ menuOpenRef }
              onClose={ this.handleClose }>
              <MenuItem
                onClick={ () => {
                  if (this.props.showHelp) {
                    this.props.showHelp();
                  }
                  if (this.props.toggleDrawer) {
                    this.props.toggleDrawer();
                  }
                  this.handleClose();
                }}>
                Help
              </MenuItem>
              { homepageUrl ? <MenuItem
                onClick={ () => {
                  window.location = homepageUrl;
                }}>
                Course homepage
              </MenuItem> : null }
              <NestedMenuItem
                label={ "Customize" }
                parentMenuOpen={ true }
              >
                <NestedMenuItem
                  label={ '"Call on me" preference' }
                  parentMenuOpen={ true }
                >
                  <MenuItem
                    onClick={ () => {
                      this.props.setCallOnMe(true);
                    }}>
                    Call on Me
                    { callOnMe ? checkIcon : null }
                  </MenuItem>
                  <MenuItem
                    onClick={ () => {
                      this.props.setCallOnMe(false);
                    }}>
                    Don't call on Me
                    { !callOnMe ? checkIcon : null }
                  </MenuItem>
                </NestedMenuItem>
                <MenuItem
                  onClick={ () => {
                    const { dropMode } = this.state;
                    const { setTranscriptMode } = this.props;
                    if (!dropMode) setTranscriptMode(false);
                    this.setState({ dropMode: !dropMode,
                                    transcriptMode: false});
                    this.handleClose();
                  }}>
                  Drop/hide/unhide courses
                </MenuItem>
                <NestedMenuItem
                  label={ "Equation editor" }
                  parentMenuOpen={ true }
                >
                  <MenuItem
                    onClick={ () => {
                      this.props.toggleEquationEditorVisibility();
                    }}>
                    { this.props.showEditorTool ? "Hide" : "Show" }
                  </MenuItem>
                </NestedMenuItem>
                <NestedMenuItem
                  label={"Code cells" }
                  parentMenuOpen={ true }
                >
                  { Object.keys(binderKernels).map(lang => 
                    <MenuItem
                      key={ lang }
                      onClick={ () => {
                        this.props.changeCodeCellOption(lang);
                      }}>
                      { binderKernels[lang].name }
                      { codeCell === lang ? checkIcon : null }
                    </MenuItem>
                  ) }
                  <MenuItem
                    onClick={ () => {
                      this.props.changeCodeCellOption("none");
                    }}>
                    none
                    { codeCell === "none" ? checkIcon : null }
                  </MenuItem>
                </NestedMenuItem>
              </NestedMenuItem>
              <MenuItem
                onClick={ () => {
                  const { setTranscriptMode } = this.props;
                  const { transcriptMode } = this.state;
                  this.setState({ dropMode: false,
                                  transcriptMode: !transcriptMode});
                  setTranscriptMode(); // toggle in parent too
                  this.handleClose();
                }}>
                Get a transcript from class
              </MenuItem>
              <MenuItem
                onClick={ () => {
                  router.history.push("/pricing");
                  this.handleClose();
                }}>
                Create a new course
              </MenuItem>
              <MenuItem
                onClick={ () => {
                  const win = window.open("/profile", "_blank");
                  win.focus();
                  this.handleClose();
                }}>
                Update my profile
              </MenuItem>
              <MenuItem
                onClick={ () => {
                  this.goToStudentMetrics(this.props.projectId);
                }}>
                Metrics
              </MenuItem>
              <MenuItem
                onClick={ () => {
                  this.logout();
                  this.handleClose();
                }}>
                Logout
              </MenuItem>
              {this.props.toggleDrawer ?
                <MenuItem
                  onClick={ () => {
                    this.props.toggleDrawer()
                    this.handleClose();
                  }}>
                  Close
                </MenuItem>
              : null }
            </Menu>
            <div
              style={{
                marginRight: "7%",
              }}
              className="courses-panel-title">
              Courses
            </div>
            <div className="courses-close-icon">
              <Tooltip
                title={"close courses " + (this.props.toggleDrawer ? "drawer" : "panel")}>
                <CloseButton
                  onClick={ () => {
                    if (this.props.toggleDrawer) {
                      this.props.toggleDrawer();
                    } else if (this.props.toggleSingleColumnOption) {
                      this.props.toggleSingleColumnOption();
                    }
                  }}
                  size="small" >
                  <CloseIcon/>
                </CloseButton>
              </Tooltip>
            </div>
          </div>
          { this.getProjects().map(project => {
              if (hide(project)) return null;
              return this.renderProjectCard(project);
            })
          }
          { dropMode || transcriptMode ? backButton : null}
        </div>
      </div>
    );
  }

}

export default CoursesArea;
