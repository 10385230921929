import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { mousetrapStopCallback } from './utils';
import * as Mousetrap from 'mousetrap';

// import MarkdownShortcuts from 'quill-markdown-shortcuts';
// import BlotFormatter from "quill-blot-formatter";
// import ImageUploader from "quill-image-uploader";
import { imageUploader } from './utils';

import TimerIcon from '@material-ui/icons/Timer';
import CreateIcon from '@material-ui/icons/Create';
import CommentIcon from '@material-ui/icons/Comment';
import GestureIcon from '@material-ui/icons/Gesture';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import ListIcon from '@material-ui/icons/List';
import FunctionsIcon from '@material-ui/icons/Functions';
import IsoIcon from '@material-ui/icons/Iso';
import GroupIcon from '@material-ui/icons/Group';
import EditIcon from '@material-ui/icons/Edit';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';

let BlockEmbed = Quill.import('blots/block/embed');

class DividerBlot extends BlockEmbed { }
DividerBlot.blotName = 'hr';
DividerBlot.tagName = 'hr';

Quill.register(DividerBlot);

const PeerButton = () => <span><GroupIcon/></span>;
const RequireButton = () => <span>®</span>;
const ClockButton = () => <span><TimerIcon/></span>;
const DrawButton = () => <span><CreateIcon/></span>;
const NoteButton = () => <span><CommentIcon/></span>;
const GestureButton = () => <span><GestureIcon/></span>;
const AddToQueueButton = () => <span><AddToQueueIcon/></span>;
const OpenResponseButton = () => <span><RecordVoiceOverIcon/></span>;
const ListButton = () => <span><ListIcon/></span>;
const FunctionsButton = () => <span><FunctionsIcon/></span>;
const IsoButton = () => <span><IsoIcon/></span>;

function makePeer() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "👥");
  this.quill.setSelection(cursorPosition + "👥".length);
}

function makeRequired() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "®");
  this.quill.setSelection(cursorPosition + "®".length);
}

function startTimer() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "🕔60s");
  this.quill.setSelection(cursorPosition + "🕔60".length);
}

function markDrawing() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "✏");
  this.quill.setSelection(cursorPosition + "✏".length);
}

function insertNote() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(0, "📝");
  this.quill.setSelection(cursorPosition + "📝".length);
}

function divider() {
  const range = this.quill.getSelection(true);
  this.quill.insertText(range.index, '\n');
  this.quill.insertEmbed(range.index + 1, 'hr', true);
  this.quill.setSelection(range.index + 2);
}

class CustomToolbar extends Component {

  render() {
    let {
      toggleCanvas,
      toggleMathlet,
      toggleSuggestedMessages,
      toggleOpenResponse,
      toggleCalculator,
      codeCellChecked,
      handleCheckChange,
      studentView=false,
    } = this.props;
    return (
      <div id="toolbar">
        <span className="ql-formats">
          { !studentView && toggleSuggestedMessages ? <Tooltip title="Multiple choice [ctrl+s]">
            <button
              className="ql-toggleSuggestedMessages no-outline">
              <ListButton/>
            </button>
          </Tooltip> : null }
          { !studentView && toggleOpenResponse ? <Tooltip title="Open Response">
            <button
              className="ql-toggleOpenResponse no-outline">
              <OpenResponseButton/>
            </button>
          </Tooltip> : null }
          {toggleCanvas ? <Tooltip title="Drawing canvas [ctrl+d]">
            <button
              className="ql-toggleCanvas no-outline">
              <GestureButton/>
            </button>
          </Tooltip> : null }
          { toggleMathlet ? <Tooltip title="Interactive graph [ctrl+g]">
            <button
              className="ql-toggleMathlet no-outline">
              <AddToQueueButton/>
            </button>
          </Tooltip> : null }
          { toggleCalculator ? <Tooltip title="Calculator [ctrl+l]">
            <button
              className="ql-toggleCalculator no-outline">
              <IsoButton/>
            </button>
          </Tooltip> : null }
        </span>
        <span className="ql-formats">
          <Tooltip title="Headers (##)">
            <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
              <option value="0" />
              <option value="1" />
              <option value="2" />
              <option value="3" />
              <option value="4" />
            </select>
          </Tooltip>
          <Tooltip title="Alignment">
            <select className="ql-align">
              <option />
              <option value="center"/>
              <option value="right"/>
            </select>
          </Tooltip>
        </span>
        <span className="ql-formats">
          <Tooltip title="Insert image">
            <button className="ql-image" />
          </Tooltip>
          <Tooltip title="Insert video">
            <button className="ql-video" />
          </Tooltip>
          <Tooltip title="Insert code block (```)">
            <button className="ql-code-block" />
          </Tooltip>
        </span>
        <span className="ql-formats">
          <Tooltip title="Insert link">
            <button className="ql-link" />
          </Tooltip>
          <Tooltip title="Insert formula [ctrl+m]">
            <button
              className="ql-toggleEquationEditor no-outline">
              <FunctionsButton/>
            </button>
          </Tooltip>
          <Tooltip title="edit formula (put cursor after formula) [ctrl+u]">
            <button
              className="ql-editFormula no-outline">
              <EditIcon/>
            </button>
          </Tooltip>
          <Tooltip title="Insert inline code (``)">
            <button className="ql-code" />
          </Tooltip>
          <Tooltip title="Insert table">
            <button className="ql-toggleTable">tbl</button>
          </Tooltip>
          <Tooltip title="Insert horizontal divider">
            <button
              style={{transform: "translate(-2px, -3px) scale(1,2)"}}
              className="ql-divider">
              —
            </button>
          </Tooltip>
        </span>
        { studentView ? null :
          <span className="ql-formats">
            <Tooltip title="Mark question for peer responses">
              <button className="ql-makePeer"> <PeerButton/> </button>
            </Tooltip>
            <Tooltip title="Mark question as required">
              <button className="ql-makeRequired"> <RequireButton/> </button>
            </Tooltip>
            <Tooltip title="Attach a timer">
              <button className="ql-startTimer"> <ClockButton/> </button>
            </Tooltip>
            <Tooltip title="Mark as drawing question">
              <button className="ql-markDrawing"> <DrawButton/> </button>
            </Tooltip>
            <Tooltip title="Insert 📝 at beginning to make message a note">
              <button className="ql-insertNote">
                <NoteButton/>
              </button>
            </Tooltip>
          </span>
        }
        { handleCheckChange ? <Tooltip
          title="include an executable code cell with this message">
          <Checkbox
            className="code-cell-checkbox"
            size="small"
            style={{ marginTop: "-4px", marginBottom: "-9px" }}
            checked={ codeCellChecked }
            color="default"
            onChange={ handleCheckChange }
            inputProps={{ 'aria-label': 'include code cell with message' }}/>
        </Tooltip> : null}
      </div>
    );
  }
}

class RichTextEditor extends Component {

  constructor(props) {
    super(props);
    const { toggleTable, toggleEquationEditor, toggleCalculator, toggleSuggestedMessages, toggleOpenResponse,
            toggleCanvas, toggleMathlet } = this.props;
    this.quillRef = this.props.quillRef || React.createRef();
    const editFormula = () => this.editFormula();
    this.modules = {
      toolbar: {
        container: "#toolbar",
        handlers: {
          makePeer,
          makeRequired,
          startTimer,
          markDrawing,
          insertNote,
          divider,
          toggleSuggestedMessages,
          toggleOpenResponse,
          toggleEquationEditor,
          toggleTable,
          editFormula,
          toggleCalculator,
          toggleMathlet,
          toggleCanvas,
        },
      },
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler: function () {
              return true;
            },
          },
        },
      },
      markdownShortcuts: {},
      blotFormatter: {},
      imageUploader: imageUploader(
        this.props.storage,
        this.props.imageCb ? this.props.imageCb : () => null
      ),
    }
  }

  componentDidMount() {
    Mousetrap.bind('ctrl+u', () => this.editFormula());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  componentWillUnmount() {
    Mousetrap.unbind('ctrl+u');
  }

  editFormula() {
    const editor = this.quillRef.current.editor;
    const cursorPosition = editor.getSelection().index;
    const { ops } = editor.getContents(0, cursorPosition);
    const formulaText = (op) => {
      if (!op || !op.insert) return false;
      return op.insert.formula;
    }
    const [lastOp] = ops.slice(-1);
    if (formulaText(lastOp)) editor.deleteText(cursorPosition-1, 1);
    const formulas = ops.map(formulaText).filter(Boolean);
    const [lastFormula] = formulas.slice(-1);
    if (lastFormula) {
      editor.insertText(cursorPosition-1, "$" + lastFormula + "$");
    }
  }

  render() {
    const {
      value,
      quillClasses,
      quillOnChange,
      quillOnFocus,
      quillOnBlur,
      quillOnKeyUp,
      quillOnKeyDown,
      placeholder,
      readOnly,
      toggleCanvas,
      toggleMathlet,
      toggleSuggestedMessages,
      toggleOpenResponse,
      toggleEquationEditor,
      toggleTable,
      toggleCalculator,
      codeCellChecked,
      handleCheckChange,
      noToolbar,
      studentView,
    } = this.props;
    return (
      <div className="text-editor">
        { noToolbar ? null : <CustomToolbar
          quillRef={ this.quillRef }
          toggleCanvas={ toggleCanvas }
          toggleMathlet={ toggleMathlet }
          toggleSuggestedMessages={ toggleSuggestedMessages }
          toggleOpenResponse={ toggleOpenResponse }
          toggleEquationEditor={ toggleEquationEditor }
          toggleTable={ toggleTable }
          toggleCalculator={ toggleCalculator }
          codeCellChecked={ codeCellChecked }
          handleCheckChange={ handleCheckChange }
          studentView={ studentView }/> }
        <ReactQuill
          ref={ this.quillRef }
          className={ quillClasses }
          tabIndex={ 1 }
          onChange={ quillOnChange }
          value={ value }
          onFocus={ quillOnFocus }
          onBlur={ quillOnBlur }
          onKeyUp={ quillOnKeyUp }
          onKeyDown={ quillOnKeyDown }
          placeholder={ placeholder }
          readOnly={ readOnly }
          modules={ this.modules }/>
      </div>
    );
  }

}

export default RichTextEditor;
