import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { extractTextFromStringifiedHocket } from '../utils';

class LessonDrawer extends Component {

    shouldComponentUpdate(nextProps) {
      const { drawerOpen } = this.props;
      if (!drawerOpen && !nextProps.drawerOpen) return false;
      return true;
    }

    renderLessonMessages() {
        const { 
            lesson={}, 
            hockets={}, 
            lastLessonMessageIndex=0,
            selectedLessonMessageRef,
            setActiveHocketId,
        } = this.props;
        if (!lesson.hockets || !lesson.hockets.length) return null;
        const listItems = [];
        for (let i = 0; i < lesson.hockets.length; i++) {
          let id = lesson.hockets[i].hocketId;
          if (!hockets[id]) continue;
          let hocket = hockets[id];
          let response = '';
          if (hocket.responses && hocket.responses[0]) {
            response = extractTextFromStringifiedHocket(hocket.responses[0]);
            response = response.split(' ');
            let n = response.length;
            response = response.slice(0, 50);
            response = response.join(' ');
            if (n > 50) response = response.trim() +'...'
            if (response.trim().startsWith('📝') || response.trim().startsWith('#📝')) continue;
          }
          listItems.push(
            (
              <ListItem
                style={ lastLessonMessageIndex === i ? {background: '#feb'} : {} }
                ref={ lastLessonMessageIndex === i - 1 ? selectedLessonMessageRef : null }
                className={ "lesson-message-preview" }
                onClick={ (e) => {
                  // 'enter' presses register as click 
                  // events, but that conflicts with 
                  // the 'enter' keyboard shortcut:
                  if (e.type === 'keydown') return;
                  setActiveHocketId(hocket.id, i); 
                } }
                key={id}
                button>
                <ListItemText primary={response} />
              </ListItem>
            )
          );
        }
        return (
          <List>
            { listItems }
          </List>
        );
      }

    render() {
        const { 
            drawerOpen=false, 
            lessons=[], 
            selectedLessonId=null,
            selectLesson,
        } = this.props;
        const menuItems = [];
        for (let i = 0; i < lessons.length; i++) {
           menuItems.push(
                <MenuItem
                    key={ lessons[i].id }
                    value={ lessons[i].id }>
                    { lessons[i].title }
                </MenuItem>
           );
        }
        const style = {
            position: 'absolute',
            width: '480px',
            overscrollBehavior: 'contain',
        }
        const modalProps = {
            container: document.getElementById('cluster-block-area'),
            style: {position:'absolute'},
        };
        return (
            <Drawer
              anchor={'left'}
              variant='persistent'
              PaperProps={{ style }}
              BackdropProps={{ style: {position:'absolute'} }}
              ModalProps={ modalProps }
              open={ drawerOpen }>
              <div className="padded">
                  <h3 className="centered">Lessons</h3>
                  <Select
                      fullWidth
                      onChange={ selectLesson }
                      value={ selectedLessonId }>
                      { menuItems }
                  </Select>
                  { this.renderLessonMessages() }
              </div>
          </Drawer>
        );
    }
}

export default LessonDrawer;
