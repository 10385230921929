import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import handlebars from 'handlebars';
import 'react-quill/dist/quill.snow.css';
import SuggestionSuperList from '../SuggestionSuperList';
import QuillSuperList from '../QuillSuperList';
import { now } from '../utils';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Hocket from '../hocket';


function extractChamberIdFromUpdateString(updateString) {
  // so to start it'll be something like activeChamberId = "abc-234-cvjao"
  // so we'll split it and take the id
  let tmp = updateString.split(" ")[2];
  if (tmp[0] !== '"') return tmp;
  // okay, so now tmp is "abc-234-cvjao" and we just need to remove the quotes
  return tmp.substr(1, tmp.length - 2) || '';
}

function Rule(triggerCriteria=[], hocketRef=null) {
  return {
    hocketRef,
    triggerCriteria,
  }
}

handlebars.registerHelper('either', function(first, second, third) {
  return first || second || third;
});

handlebars.registerHelper('or', () => false);

class ClusterMessageForm extends Component {

  constructor(props) {
    super(props);
    this.hocketRef = null;
    this.courseRef = null;
    this.keysPressed = {};
    this.sendHocketMessage = this.sendHocketMessage.bind(this);
    this.markAnswered = this.markAnswered.bind(this);
    this.clearClusterLocally = this.clearClusterLocally.bind(this);
    this.quillRef = React.createRef();
    this.state = {
      hocket: null,
      tags: [],
      chambers: {},
      chamberExit: false,
      followupHocketIds: [],
      triggerCriteria: [],
      responses: [],
      suggestions: [],
      trainingPhrases: [],
      blackboardUpdates: [],
    };
  }

  componentDidMount() {
    //this.setRefs(this.props);
    this.bootstrapHocket(this.props);
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.contents && (prevProps.contents !== this.props.contents)) {
      this.quillRef.current.editor.setContents(JSON.parse(this.props.contents));
    }
  }

  bootstrapHocket(props) {
    let { hocketId } = props;
    let hocket;
    if (!hocketId) {
      hocket = Hocket();
      hocketId = hocket.id;
    }
    if (!hocket) return;
    for (let i = 0; i < hocket.responses.length; i++) {
      if (/ops/.test(hocket.responses[i])) continue;
      if (!hocket.responses[i]) continue;
      hocket.responses[i] = JSON.stringify({ops: JSON.parse(hocket.responses[i])});
    }
    let blackboardUpdates;
    const {
      responses = [],
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
    } = hocket;
    if (!Array.isArray(hocket.blackboardUpdates)) {
      blackboardUpdates = [];
    } else {
      blackboardUpdates = hocket.blackboardUpdates;
    }
    let blackboardUpdateActiveChamberId = '';
    for (let i = 0; i < blackboardUpdates.length; i++) {
      if (blackboardUpdates[i].startsWith('activeChamberId = ')) {
        // the problem is that this thing is going to actually be in quotes
        let updateString = blackboardUpdates.splice(i, 1)[0];
        let chamberId = extractChamberIdFromUpdateString(updateString);
        blackboardUpdateActiveChamberId = chamberId || '';
        break
      }
    }
    let triggerCriteriaActiveChamberId = '';
    for (let i = 0; i < triggerCriteria.length; i++) {
      if (triggerCriteria[i].startsWith('activeChamberId == ')) {
        let updateString = triggerCriteria.splice(i, 1)[0];
        let chamberId = extractChamberIdFromUpdateString(updateString);
        triggerCriteriaActiveChamberId = chamberId || '';
        break;
      }
    }
    const chamberExit = blackboardUpdateActiveChamberId === '"null"';
    this.setState({
      hocket,
      blackboardUpdates,
      blackboardUpdateActiveChamberId,
      chamberExit,
      responses,
      followupHocketIds,
      suggestions,
      trainingPhrases,
      triggerCriteria,
      triggerCriteriaActiveChamberId,
    });
  }

  updateCriteria(triggerCriteria) {
    // parser's going to need to go here
    this.setState({ triggerCriteria });
  }

  updateTrainingPhrases(trainingPhrases) {
    this.setState({ trainingPhrases });
  }

  updateResponses(responses) {
    // going to need to update this to return quill deltas
    this.setState({ responses });
  }

  updateSuggestions(suggestions) {
    this.setState({ suggestions });
  }

  updateFollowupHocketIds(followupHocketIds) {
    // parser's going to need to go here
    this.setState({ followupHocketIds });
  }

  updateUpdates(blackboardUpdates) {
    // parser's going to need to go here
    this.setState({ blackboardUpdates });
  }

  toggleChamberExit() {
    this.setState({
      blackboardUpdateActiveChamberId: null,
      chamberExit: !this.state.chamberExit
    });
  }

  renderSwitch() {
    const { chamberExit } = this.state;
    const label = chamberExit ? 'Exit Chamber' : 'Exit Chamber';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ !!chamberExit }
              onChange={ () => this.toggleChamberExit() }
              />
            }
          label={ label }
        />
      </FormGroup>
    );
  }

  sendHocketMessage() {
    const {
      blackboardUpdates = [],
      hocket,
      responses = [],
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
      blackboardUpdateActiveChamberId = '',
      triggerCriteriaActiveChamberId = '',
    } = this.state;
    if (!responses?.length) return;
    hocket.blackboardUpdates = blackboardUpdates;
    if (blackboardUpdateActiveChamberId) {
      hocket.blackboardUpdates.push('activeChamberId = "' + blackboardUpdateActiveChamberId + '"');
    }
    hocket.responses = responses;
    hocket.suggestions = suggestions;
    hocket.followupHocketIds = followupHocketIds;
    hocket.trainingPhrases = trainingPhrases;
    hocket.triggerCriteria = triggerCriteria;
    hocket.sentFromClusterBlock = true;
    if (triggerCriteriaActiveChamberId) {
      hocket.triggerCriteria.push('activeChamberId == "' + triggerCriteriaActiveChamberId + '"');
    }
    hocket.timestamp = now();
    if (this.props.sendHocketAsMessage) {
      this.props.sendHocketAsMessage(hocket, this.props.clusterIndex);
    }
    this.setState({
      responses: [],
      suggestions: [],
    });
  }

  markAnswered() {
    if (this.props.markAnswered && typeof this.props.clusterIndex === 'number') {
      this.props.markAnswered(this.props.clusterIndex);
    }
  }

  clearClusterLocally() {
    if (this.props.clearClusterLocally && typeof this.props.clusterIndex === 'number') {
      this.props.clearClusterLocally(this.props.clusterIndex);
    }
  }

  markResponseInProgress(inProgress) {
    if (this.props.markResponseInProgress && typeof this.props.clusterIndex === 'number'){
      this.props.markResponseInProgress(this.props.clusterIndex, inProgress);
    }
  }

  saveHocket() {
    const {
      blackboardUpdates = [],
      hocket,
      responses = [],
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
      blackboardUpdateActiveChamberId = '',
      triggerCriteriaActiveChamberId = '',
    } = this.state;
    hocket.blackboardUpdates = blackboardUpdates;
    if (blackboardUpdateActiveChamberId) {
      hocket.blackboardUpdates.push('activeChamberId = "' + blackboardUpdateActiveChamberId + '"');
    }
    hocket.responses = responses;
    hocket.suggestions = suggestions;
    hocket.followupHocketIds = followupHocketIds;
    hocket.trainingPhrases = trainingPhrases;
    hocket.triggerCriteria = triggerCriteria;
    if (triggerCriteriaActiveChamberId) {
      hocket.triggerCriteria.push('activeChamberId == "' + triggerCriteriaActiveChamberId + '"');
    }
    hocket.timestamp = now();
    const rule = Rule(hocket.triggerCriteria, this.hocketRef);
    this.hocketRef.set(hocket, {merge: true});
    // add toast eventually
    if (triggerCriteria.length) {
      this.props.db
        .collection('projects')
        .doc(this.props.projectId)
        .collection('meta')
        .doc('rules')
        .set(
          {[hocket.id]: rule},
          {merge: true}
        );
    }
  }

  renderTriggerCriteriaChamberSelect() {
    let { chambers, triggerCriteriaActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={triggerCriteriaActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ triggerCriteriaActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }


  renderBlackboardUpdateChamberSelect() {
    let { chambers, blackboardUpdateActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={blackboardUpdateActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ blackboardUpdateActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }

  handleChange(event) {
    const { value } = event.target;
    const { hocket } = this.state;
    hocket.tags = value;
    this.setState({ hocket });
  };

  renderTags() {
    let { tags, hocket } = this.state;
    if (!tags || !hocket) return null;
    if (!hocket.tags) hocket.tags = [];
    const tagMap = tags.reduce((acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    }, {});
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-mutiple-chip-label">Tags</InputLabel>
        <Select
          id="demo-mutiple-chip"
          multiple
          value={hocket.tags}
          onChange={(e) => this.handleChange(e)}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => {
            if (!selected || !selected.map) return;
            return (
              <div>
                {selected.map(id => {
                  if (!tagMap[id]) return null;
                  return (
                    <Chip key={id} label={tagMap[id].displayName}/>
                  );
                }
                )}
              </div>
            );
            }
          }
        >
          {tags.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  keyChecker(e) {
    this.keysPressed[e.keyCode] = e.type === 'keydown';
  }

  keyUp(e) {
    this.keyChecker(e);
  }

  keyDown(e, quillRef) {
    this.keyChecker(e);
    if (e.keyCode === 27) {
      quillRef.current.blur();
      return;
    }
    if (e.keyCode !== 13) return;
    // if we're at this point, we know 13 has been pressed
    // so we check if shift has been pressed too
    if (this.keysPressed[16]) return;
    // if it's just enter and not enter + shift, we add the message
    if (!e.target.textContent.trim()) return;
    const editor = quillRef.current.editor;
    const cursorPosition = editor.getSelection().index;
    editor.deleteText(cursorPosition - 1, 1);
    this.sendHocketMessage();
  }

  markStarred() {
    if (this.props.starCluster && typeof this.props.clusterIndex === 'number') {
      this.props.starCluster(this.props.clusterIndex);
    }
  }

  render() {
    if (!this.state.hocket) return null;
    const { 
      projectId, 
      hideSuggestedResponses=false, 
      currentUser, 
      hasBeenStarred, 
      storage, 
      clearLocally,
    } = this.props;
    const starButton = (
      <Tooltip title="star all messages in column">
        <IconButton
          className="mark-starred-button"
          variant="contained"
          onClick={ () => this.markStarred() }
          style={ {padding: "2px", minWidth: "27pt", minHeight: "27pt", marginRight: "-4px"} }
          >
          { hasBeenStarred ?
            <img className="plain-star" src="/yellow-star.svg" alt="star" /> :
            <img className="plain-star" src="/plain-star.svg" alt="star"/> }
        </IconButton>
      </Tooltip>
    );
    const checkButton = clearLocally ? (
      <Tooltip title="clear column">
        <IconButton
          className="mark-answered-button"
          variant="contained"
          onClick={ this.clearClusterLocally }
          style={{padding: 0, minWidth: "1em", minHeight: "1em", marginRight: "-6px"}}
          >
          <HighlightOffIcon style={{fontWeight: "300", color: "#AAA"}}/>
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="delete messages in column">
        <IconButton
          className="mark-answered-button"
          variant="contained"
          onClick={ this.markAnswered }
          style={{padding: 0, minWidth: "1em", minHeight: "1em", marginRight: "-6px"}}
          >
          <DeleteOutlinedIcon style={{fontWeight: "300", color: "#AAA"}}/>
        </IconButton>
      </Tooltip>
    );
    return (
      <div className="">
        <div className="">
          <div>
            <QuillSuperList
              label="Response"
              noToolbar={ true }
              storage={ storage }
              limit={ 1 }
              values={ this.state.responses }
              onFocus={ () => this.markResponseInProgress(currentUser.id) }
              onBlur={ (previousRange) => {
                if (previousRange.index === 0) {
                  this.markResponseInProgress(false);
                }
              }}
              updateValues={ (responses) => this.updateResponses(responses) }
              onKeyUp={ (e) => this.keyUp(e) }
              onKeyDown={ (e) => this.keyDown(e, this.quillRef) }
              quillRef={ this.quillRef }
              fontSize="small"
              />
            { hideSuggestedResponses ? null :
              <>
                <p class="suggested-response-title">Suggested Responses</p>
                <SuggestionSuperList
                  label="Suggestion"
                  showHocketField={ false }
                  limit={ 15 }
                  projectId={ projectId }
                  db={ this.props.db }
                  values={ this.state.suggestions }
                  updateValues={ (suggestions) => this.updateSuggestions(suggestions) }/>
              </>
            }
          </div>
        </div>
        <div className="button-container">
          <Tooltip
            title="Send message to everyone in this column"
            placement="bottom"
            enterDelay={ 1200 }>
            <Button
            style={ {minHeight: "32px", minWidth: "58px"} }
            variant="contained"
            color="primary"
            size="small"
            onClick={ this.sendHocketMessage } >Send</Button>
          </Tooltip>
          { this.props.markAnswered ? checkButton : null }
          { starButton }
        </div>
      </div>
    );
  }

}

export default ClusterMessageForm;
