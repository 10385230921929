import React, { Component } from 'react';

class FrequentlyAskedQuestions extends Component {

  render() {
    return (
      <>
      <div
        className="big-pad extra-padded gray-p text-column"
        style={{marginBottom: "120px"}}>
        <div className="centered">
          <a href="/">
            <img
              src="/prismia-logo-simple.svg"
              alt="prismia logo"
              style={{
                width: "320px",
                marginBottom: "40px",
              }}
            />
          </a>
        </div>
        <h1
          className="centered"
          style={{marginBottom: "80px"}}>
          Frequently asked questions
        </h1>
        <p id="why-prismia">
          <strong>
            <em>Why Prismia?</em>
          </strong>
        </p>
        <p className="indented">
          Since Benjamin Bloom's 1984 article on the <a href="https://en.wikipedia.org/wiki/Bloom%27s_2_sigma_problem">two-sigma problem</a>, we have known that students learn dramatically better when they receive one-on-one instruction. Prismia seeks to improve educational outcomes by bringing some key elements of private tutoring to online learning experiences. Content creators can author lessons in the style of a conversation, and these lessons can be used in a live class context or deployed for learners to complete on their own.
        </p>
        <p className="indented">
          Prismia learners are actively writing throughout class, rather than passively listening or answering multiple-choice questions. The opportunity to synthesize ideas and practice writing makes it easier to pay attention, make connections, and stay inquisitive. The realtime feedback from instructors helps the student promptly address any misapprehensions, and it provides the teacher with valuable insight into how the students are thinking about the questions.
        </p>
        <p id="does-it-work">
          <strong><em>Does Prismia really work?</em></strong>
        </p>
        <p className="indented">
          Yes. Prismia has been used in high schools and at MIT and Brown University. Class sizes have ranged from small (~10) to fairly large (~100). Students commonly interact 15-20 times in a one-hour class. On average, about 12% of the messages sent by students are questions, and about 88% are answers or comments. Prismia users—instructors and students alike—report high levels of satisfaction.
        </p>
        <p id="relate-to-videoconference">
          <strong><em>How does Prismia relate to videoconferencing software (Zoom/Meet/Teams)?</em></strong>
        </p>
        <p className="indented">
          Most instructors run class through videoconferencing software and Prismia at the same time. The videoconferencing software provides the audiovisual components, while Prismia is used for text communication. This allows all students to respond to each question, not just the one or two students who would speak in the videoconference.
        </p>
        <p className="indented">
          However, it is also possible to run a class entirely through Prismia. To make sure that works efficiently, you would typically want to pre-script your messages using the <em>Lessons</em> feature.
        </p>
        <p className="indented">
          If you want to use Prismia for assessment, you can export a CSV file from your Prismia class's <em>Metrics</em> page and import it into your LMS or gradebook. For more convenient data transfer, ask your students to enter their Student IDs at <tt>https://prismia.chat/profile</tt>. You'll get a <em>Student ID</em> column in the downloaded CSV.
        </p>
        <p id="differences-from-multiple-choice">
          <strong><em>How is Prismia different from multiple-choice polling tools?</em></strong>
        </p>
        <p className="indented">
          The bandwidth of the Prismia communication channel is much higher. Imagine that a student you're tutoring is only allowed to express themselves by raising 1, 2, 3, or 4 fingers. Imagine another student who's allowed to converse freely. The second student will have a much richer experience.
        </p>
        <p id="differences-from-general-purpose-chat">
          <strong><em>How is Prismia different from general-purpose chat tools?</em></strong>
        </p>
        <p className="indented">
          The features required of a chat platform are highly dependent on how the platform is used. Communication on social media, class message boards, and work chat are different enough to support distinct app ecosystems.
        </p>
        <p className="indented">
          From this point of view, classroom communication has been unusual in two ways: it has not received dedicated tooling, and it is in <em>better</em> position to benefit from a custom tool because of its unusual many-to-one (or many-to-few) connection structure.
        </p>
        <p className="indented">
          To cite just one example, running class on a shared thread in a typical chat app means that students are able to see everyone else's responses as they're sent. Using private threads requires the instructor to switch rapidly between them to read student messages. Prismia solves this problem by clustering messages for the instructor and putting message sharing in their hands.
        </p>
        <p id="not-plain-text-only">
          <strong><em>Does "chat" mean plain text only? </em></strong>
        </p>
        <p className="indented">
          Prismia's main text boxes support rich text, headers, images, block quotes, math formulas (using LaTeX), code blocks, and inline code. Multiple choice answers are plain text only.
        </p>
        <p className="indented">
          For more information on supported features and keyboard shortcuts, check out <a href="/guide#shortcuts">the detailed guide</a>.
        </p>
        <p id="use-with-teaching-assistants">
          <strong><em>Can Prismia be used with teaching assistants?</em></strong>
        </p>
        <p className="indented">
          Yes! In fact, the classroom view provides tools to make it easy for instructors and TAs to see which messages have received or are receiving a response from other instructional staff.
        </p>
        <p className="indented">
          If you have a teaching assistant (and maintain a comfortable pace), it is quite feasible to provide a response to every message from every student in the class. However, even without a TA, it is often manageable to answer most student messages.
        </p>
        <p id="challenge">
          <strong>
            <em>
              How challenging is it to teach a Prismia class?
            </em>
          </strong>
        </p>
        <p className="indented">
          Prismia is highly adaptable to your needs as an instructor. You can start by using it just to glance over student responses and questions. Ease into usage of additional features when you find them helpful and manageable.
        </p>
        <p className="indented">
          Prismia can also help to substantially <em>reduce</em> cognitive burden for the instructor. For example, if you pre-script your lesson, your messages will help you keep track of your sequencing and recall the details of the exposition you planned.
        </p>
        <p className="indented">
          Also, you can use Prismia messages to share links to other resources as you go. This makes Prismia useful as an organizational tool for the day's activities.
        </p>
        <p id="cost">
          <strong><em>How much does Prismia cost?</em></strong>
        </p>
        <p className="indented">
          Prismia is currently free, and the core classroom features will remain free. We built Prismia to make our own classes better, and we want everyone to be able to use it to improve their classes too.
        </p>
        <p className="indented">
          We are working on more advanced AI assistance for the instructor, and those features will be available for instructors with premium accounts. Pricing is not yet determined, but we expect to be able to offer these features at a modest cost.
        </p>
        <p id="export">
          <strong><em>Can I export the content I create?</em></strong>
        </p>
        <p className="indented">
          You can export a lesson as Markdown by highlighting its messages (click the first one, then shift-click the last one), copying, and then pasting into a plain text editor. You can export as rich text by pasting into a rich text editor instead.
        </p>
        <p id="import">
          <strong><em>How can I author my lessons in a different environment and import them into Prismia?</em></strong>
        </p>
        <p className="indented">
          Just to say first: the authoring environment provided in the <em>Lessons</em> page is pretty convenient. For example, you can just drop images into the text box, center them with a click, and drag to re-size. Support is provided for formulas, inline code, and code blocks, and you can format them using the toolbar or using Markdown shortcuts.
        </p>
        <p className="indented">
          However, we understand that some folks do really prefer creating content in their favorite text editor, and we have a solution for that as well:
          <ol>
            <li>Export your content in Markdown format (for example, using <a href="https://pandoc.org">Pandoc</a> or <a href="https://jupytext.readthedocs.io/en/latest/">Jupytext</a>). Use horizontal rules (---) to separate messages.</li>
            <li>Paste your Markdown content into an empty text box on the Lessons page and click outside of the text box so that the editor stays active but the box loses its cursor. </li>
            <li>Hold <tt>shift</tt> and press <tt>m</tt> to parse format the entire currently selected message as Markdown. </li>
            <li>Click <em>Split</em> to split the current message at each horizontal rule.</li>
          </ol>
        </p>
        <p className="indented">
          The biggest challenge with this workflow is how to deal with images. Since the images won't be in the Markdown text that you paste into Prismia, you might want to host the images on the internet somewhere. For example, <a href="https://imgur.com">imgur</a> works well and doesn't require a login. Go to <tt>https://imgur.com</tt>, click "New Post" in the top left, drop your image into the window, and right-click it to select "Copy Image Address". Then use the usual Markdown syntax (<tt>![alt-text](url)</tt>) in Prismia to include the image by URL. Alternatively, you can just drag-and-drop them into Prismia individually.
        </p>
        <p className="indented">
          Another option to consider is to paste rich text into the text boxes in your lesson. For example, if you write in Google Docs or Word, you can paste your content directly into a Prismia lesson message, save it, and split into messages by doing <tt>shift+s</tt>.
        </p>
        <p id="browser-support">
          <strong><em>Which browsers are supported?</em></strong>
        </p>
        <p className="indented">
          Prismia is tested on Chrome, Firefox, and Safari.
        </p>
        <p id="name-meaning">
          <strong><em>What does the name mean?</em></strong>
        </p>
        <p className="indented">
          The prism is a metaphor for what Prismia does: replace a single beam of undifferentiated instruction with a colorful array of tailored communications.
        </p>
      </div>
      </>
    );
  }

}

export default FrequentlyAskedQuestions;
