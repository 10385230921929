import React, { Component } from 'react';

import ReactQuill from 'react-quill';
import { addStyles, EditableMathField } from 'react-mathquill'
import { JupyterCell } from './JupyterCell';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import SendIcon from '@material-ui/icons/Send';
import FunctionsIcon from '@material-ui/icons/Functions';
import GestureIcon from '@material-ui/icons/Gesture';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Fabric from './Fabric';
import * as Mousetrap from 'mousetrap';
import toPlaintext from 'quill-delta-to-plaintext';
import { chatWidgetConfig } from './quill-config';
import { imageUploader, hasImage, svgUploader, codeBlockDelta } from './utils';
import { binderKernels, allBinderKernels } from './jupyter';
addStyles(); // React MathQuill

class ChatWidget extends Component {

  constructor(props) {
    super(props);
    this.keysPressed = {};
    this.canvasRef = props.canvasRef || React.createRef();
    this.widgetRef = React.createRef();
    this.addMessageFromSendButton = this.addMessageFromSendButton.bind(this);
    this.toggleCanvas = this.toggleCanvas.bind(this);
    this.toggleEquationEditor = this.toggleEquationEditor.bind(this);
    this.toggleCodeCell = this.toggleCodeCell.bind(this);
    this.saveSketch = this.saveSketch.bind(this);
    this.state = {
      latex: "",
      latexOpen: false,
      canvasOpen: false,
      juniperOpen: false,
      juniperHasRendered: false,
      quillDelta: null,
      messageValue: null,
      watermark: null,
      watermarkClearPromise: true,
      showEditorTool: true,
      cursorPosition: 0,
    };
  }

  setMousetrap() {
    Mousetrap.bind("mod+shift+u", () => this.toggleCanvas());
    Mousetrap.bind("ctrl+j", () => this.toggleCodeCell());
    Mousetrap.bind("ctrl+m", () => this.toggleEquationEditor());
  }

  unsetMousetrap() {
    Mousetrap.unbind('mod+shift+u');
    Mousetrap.unbind('ctrl+j');
    Mousetrap.unbind('ctrl+m');
  }

  componentDidMount() {
    this.setMousetrap();
  }

  componentWillUnmount() {
    this.unsetMousetrap();
  }

  keyChecker(e) {
    this.keysPressed[e.keyCode] = e.type === 'keydown';
  }

  keyDown(e, quillRef) {
    if (!this.props.addMessage) return;
    this.keyChecker(e);
    if (e.keyCode !== 13) return;
    // if we're at this point, we know 13 has been pressed
    // so we check if shift has been pressed too
    if (!this.keysPressed[16]) return;
    // if it enter + shift, we add the message
    if (!e.target.textContent.trim()) return;
    const editor = quillRef.current.editor;
    const cursorPosition = editor.getSelection().index;
    editor.deleteText(cursorPosition - 1, 1);
    setTimeout( () => {
      const { quillDelta } = this.state;
      this.props.addMessage(quillDelta);
      this.setState({ messageValue: null, quillDelta: null, svg: null });
    }, 40);
  }

  keyUp(e) {
    this.keyChecker(e);
  }

  handleChange(content, delta, source, editor) {
    const text = editor.getText();
    const isEmpty = text.trim() === "";
    const quillDelta = editor.getContents();
    this.setState({
      quillDelta,
      messageValue: content,
    }, () => {
      if (this.props.setTyping) {
        this.props.setTyping({isEmpty, quillDelta, svg: null});
      }
      if (this.props.setWidgetHeight) {
        this.props.setWidgetHeight(this.widgetRef.current.clientHeight);
      }
    });
  }

  setQuillDelta(quillDelta) {
    this.setState({ quillDelta });
  }

  setLang(lang) {
    if (this.props.setLang) {
      this.props.setLang(lang);
    }
  }

  insertFromMathEditor() {
    const { latex, cursorPosition } = this.state;
    if (latex.length) {
      this.props.quillRef.current.editor.insertEmbed(cursorPosition, 'formula', latex);
    }
    this.props.quillRef.current.focus();
    this.props.quillRef.current.editor.setSelection(cursorPosition + (latex.length ? 1 : 0))
    this.setState({ latex: "", latexOpen: false });
  }

  renderJuniper() {
    const { codeCell } = this.props;
    const { juniperOpen, juniperHasRendered } = this.state;
    const style = {};
    if (!juniperOpen && !juniperHasRendered) return null;
    if (!juniperHasRendered) this.setState({ juniperHasRendered: true });
    if (!juniperOpen) style.display = "none";
    return (
      <div 
        className={"juniper-container" + (juniperOpen ? " halo-shadow" : "")} style={ style }>
        <JupyterCell 
          language={codeCell}
          setTyping={(code) => {
            if (!this.props.setTyping) return;
            const isEmpty = code.length === 0;
            const quillDelta = isEmpty ? null : codeBlockDelta(code);
            this.props.setTyping({isEmpty, quillDelta, svg: null});
          }}
          setLang={(lang) => this.setLang(lang)}/>
      </div>
    );
  }

  renderMathQuill() {
    const { latex, latexOpen, canvasOpen } = this.state;
    if (!latexOpen || canvasOpen ) return null;
    return (
      <div className="math-quill-container halo-shadow">
        <EditableMathField
          className="editable-math-field"
          mathquillDidMount={mathField => {
            mathField.focus()
          }}
          latex={latex}
          onChange={ (mathField) => {
            this.setState({latex: mathField.latex()});
            console.log(mathField.latex());
          } }
        />
        <div className="insert-button-container">
          <div className="math-insert-button">
            <Button
              onClick={ this.toggleEquationEditor }>
              { latex.length ? "Insert" : "Exit" }
            </Button>
          </div>
        </div>
      </div>
    );
  }

  kickOpenCanvas(img) {
    this.setState({ watermark: img }, () => {
      this.setState({ canvasOpen: true }, () => this.props.scrollDown ? this.props.scrollDown() : null );
    });
  }

  renderFabric() {
    const { canvasOpen, watermark } = this.state;
    if (!canvasOpen) return null;
    return <div className="fabric-canvas-tool halo-shadow">
      <Fabric
        saveSketch={ (svg) => this.saveSketch(svg) }
        watermark={ watermark }
        setTyping={ ({svg, isEmpty}) => {
          if (!this.props.setTyping) return;
          this.props.setTyping({ svg, isEmpty });
        }}
        setMousetrap={() => {
          if (this.props.setMousetrap) {
            this.props.setMousetrap();
          }
          this.setMousetrap();
        }}
        rawSvg/>
    </div>;
  }

  toggleCanvas() {
    const { canvasOpen } = this.state;
    if (canvasOpen && this.props.setTyping) {
      this.props.setTyping({
        isEmpty: true,
        quillDelta: null,
        svgPatch: null,
      });
      this.currentSvgGroups = [];
    }
    this.setState({ canvasOpen: !canvasOpen,
                    latexOpen: false,
                    juniperOpen: false }, () => {
      if (this.props.setWidgetHeight) {
        //this.props.setWidgetHeight(this.state.canvasOpen ? "39.5em" : "7.25em");
        this.props.setWidgetHeight(this.widgetRef.current.clientHeight);
      }
    });
  }

  toggleEquationEditor() {
    const { latexOpen, latex } = this.state;
    if (latex.length) {
      this.insertFromMathEditor();
    }
    const range = this.props.quillRef.current.editor.getSelection();
    if (range) this.setState({ cursorPosition: range.index });
    this.setState({canvasOpen: false,
                   juniperOpen: false,
                   latex: "",
                   latexOpen: !latexOpen}, () => {
      if (this.props.setWidgetHeight) {
        setTimeout( () => this.props.setWidgetHeight(this.widgetRef.current.clientHeight), 10);
      }
    });
  }

  createImage(url) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    return img;
  }

  clearWatermark() {
    this.setState({ watermarkClearPromise: true });
  }

  saveSketch(svg, toCanvas=false) {
    const { storage, currentUser } = this.props;
    svgUploader(storage, currentUser.id, svg).then(url => {
      if (toCanvas) {
        this.setState({ watermark: { url, fabric: null } });
      } 
      const editor = this.props.quillRef.current.editor;
      const currentPosition = (editor.getSelection() || {index: 0}).index;
      editor.insertEmbed(currentPosition, 'image', url);
    }).then(() => this.setState({ canvasOpen: false })).catch(console.error);
  }

  toggleCodeCell() {
    const { juniperOpen } = this.state;
    this.setState({canvasOpen: false,
                   latexOpen: false,
                   juniperOpen: !juniperOpen}, () => {
      if (this.props.setWidgetHeight) {
        this.props.setWidgetHeight(this.widgetRef.current.clientHeight);
      }
    });
  }

  imageCb(url) {
    this.setState({ url, fabric: null });
  }

  addMessageFromSendButton() {
    const { quillDelta } = this.state;
    if (this.textFieldEmpty()) return;
    this.props.addMessageFromSendButton(quillDelta);
    this.setState({ messageValue: null, quillDelta: null });
  }

  textFieldEmpty() {
    const { quillDelta } = this.state;
    if (!quillDelta) return true;
    return toPlaintext(quillDelta.ops).trim() === "" && !hasImage(quillDelta.ops);
  }

  render() {
    const { canvasOpen, juniperOpen, latexOpen } = this.state;
    const { showEditorTool, storage, codeCell } = this.props;
    const textFieldEmpty = this.textFieldEmpty();
    const sendIconClassName = !textFieldEmpty ? 'send-icon active' : 'send-icon inactive';
    const sendIcon = <Tooltip title="send message" enterDelay={ 800 }><SendIcon className={ sendIconClassName } onClick={ this.addMessageFromSendButton } /></Tooltip>;
    const canvasOpenIcon = canvasOpen ? <Tooltip title="close drawing tool" enterDelay={ 800 }><KeyboardArrowDownIcon className="canvas-open-icon" onClick={ this.toggleCanvas }/></Tooltip> : <Tooltip title="open drawing tool" enterDelay={ 800 }><GestureIcon className="canvas-open-icon" onClick={ this.toggleCanvas }/></Tooltip>;
    const mathQuillIcon = showEditorTool ? (latexOpen ? <Tooltip title="close equation editor" enterDelay={ 800 }><KeyboardArrowDownIcon className="canvas-open-icon" onClick={ () => this.toggleEquationEditor() }/></Tooltip> : <Tooltip title="open equation editor" enterDelay={ 800 }><FunctionsIcon className={ "canvas-open-icon" } onClick={ this.toggleEquationEditor } /></Tooltip>) : null;
    const codeIcon = (codeCell && codeCell !== 'none') ? (juniperOpen ? <KeyboardArrowDownIcon style={{marginLeft: "0"}} className="canvas-open-icon" onClick={ () => this.toggleCodeCell() }/> : <Tooltip title="open code cell" enterDelay={ 800 }><img src={allBinderKernels[codeCell]?.icon} alt="code logo" className="canvas-open-icon" style={{width: "20px", height: "21px"}} onClick={ this.toggleCodeCell } /></Tooltip>) : null;
    chatWidgetConfig.imageUploader = imageUploader(storage, url => this.imageCb(url));
    return (
      <div 
        ref={ this.widgetRef } 
        className={"chat-widget" + (juniperOpen || canvasOpen || latexOpen ? "" : " top-shadow")}>
        { this.renderMathQuill() }
        { this.renderFabric() }
        { this.renderJuniper() }
        <div className="chat-input-container">
          <div className="chat-quill-container">
            <div className="chat-input-area">
              <ReactQuill
                ref={ this.props.quillRef }
                modules={ chatWidgetConfig }
                className={ "editor" }
                onKeyUp={ e => this.keyUp(e) }
                onKeyDown={ e => this.keyDown(e, this.props.quillRef) }
                placeholder="Type a message..."
                value={ this.state.messageValue }
                onChange={(...args) => this.handleChange(...args)}>
              </ReactQuill>
              <div className="chat-controls">
                { codeIcon }
                { mathQuillIcon }
                { canvasOpenIcon }
                { sendIcon }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatWidget;
