import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { updateTitleBar, now } from '../utils';
import './style.css';

class PrivacyView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deletionRequestSent: false,
      updateRequestSent: false,
      exportDataRequestSent: false,
    };
  }

  componentDidMount() {
    updateTitleBar('Privacy');
  }

  requestData() {
    this.sendRequest('export-account');
    this.setState({ exportDataRequestSent: true });
  }

  requestUpdate() {
    this.sendRequest('update-account');
    this.setState({ updateRequestSent: true });
  }

  requestDeletion() {
    this.sendRequest('delete-account');
    this.setState({ deletionRequestSent : true });
  }

  sendRequest(requestType) {
    const { db, currentUser } = this.props;
    const request = {
      timestamp: now(),
      id: uuid(),
      userDisplayName: currentUser.displayName,
      userId: currentUser.id,
      userPhotoUrl: currentUser.photoUrl,
      requestType,
    };
    db.collection('users')
      .doc(currentUser.id)
      .collection('meta')
      .doc('triggers')
      .collection('privacy')
      .doc(request.id)
      .set(request);
  }

  renderRequestDataArea() {
    const { exportDataRequestSent=false } = this.state;
    const button = (
      <Button
        variant="contained"
        color="secondary"
        onClick={ () => this.requestData() } >
        Request Account Data 
      </Button>
    );
    const message = (
      <p>Your request has been sent. Expect an email from us on the next steps within 30 days</p>
    );
    return (
      <div className='request-area flex-container'>
        <div className="flex-child">
          <p>
            We'll email you a zip file with all the
            data we have on you. Manage your
            expectations though, we really just have
            your name, email address, the list of
            courses you joined, and all the messages
            you've sent through Prismia.
          </p>
        </div>
        <div className="flex-child centered">
          { exportDataRequestSent ? message : button }
        </div>
      </div>
    );
  }

  renderRequestUpdateArea() {
    const { updateRequestSent=false } = this.state;
    const message = (
      <p>Your request has been sent. Expect an email from us on the next steps within 30 days</p>
    );
    const button = (
      <Button
        variant="contained"
        color="secondary"
        onClick={ () => this.requestUpdate() } >
        Request Account Update 
      </Button>
    );
    return (
      <div className='request-area flex-container'>
        <div className="flex-child">
          <p>
            We'll contact you about what data you
            want changed.
          </p>
        </div>
        <div className="flex-child centered">
          { updateRequestSent ? message : button }
        </div>
      </div>
    );
  }

  renderRequestDeletionArea() {
    const { deletionRequestSent=false } = this.state;
    const button = (
      <Button
        variant="contained"
        color="secondary"
        onClick={ () => this.requestDeletion() } >
        Request Account Deletion
      </Button>
    );
    const message = (
      <p>Your request has been sent. Expect an email from us on the next steps within 30 days</p>
    );
    return (
      <div className='request-area flex-container'>
        <div className="flex-child">
          <p>
            Deleting your account is irreversible,
            but we'll email you first to make sure
            you meant to do it.
          </p>
        </div>
        <div className="flex-child centered">
        { deletionRequestSent ? message : button }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="privacy-view padded">
        <Link to='/'>
          <h1 className="centered">
            Prismia
          </h1>
        </Link>
        { this.renderRequestUpdateArea() }
        { this.renderRequestDataArea() }
        { this.renderRequestDeletionArea() }
      </div>
    );
  }

}

export default PrivacyView;
