import React, { Component } from 'react';
import CommentsArea from '../comments-area/CommentsArea';
import MessageForm from '../MessageForm';
import { saveLessonMessage } from '../analytics';
import { Draggable } from 'react-beautiful-dnd';
import CardContents from '../CardContents';

const getItemStyle = (isDragging, draggableStyle, clusterStyle={}) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? '#ffe' : clusterStyle.background,
  // styles we need to apply on draggables
  ...draggableStyle
});

class MessageCard extends Component {

  renderHocketForm() {
    const { db, storage, projectId, currentUser } = this.props;
    const { activeHocketId, codeCell } = this.props;
    if (!activeHocketId) return <></>;
    const hocketRef = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(activeHocketId);
    return (
      <div
        className="message-form-container"
        key={ activeHocketId }>
        <MessageForm
          db={ db }
          currentUser={ currentUser }
          storage={ storage }
          projectId={ projectId }
          hocketId={ activeHocketId }
          clearActiveHocketId={ () => this.props.setActiveHocketId(null) }
          deleteMessages={ this.props.deleteMessages }
          mainQuillRef={ this.props.mainQuillRef }
          toggleJuniper={ this.props.toggleJuniper }
          splitActiveCell={ this.props.splitActiveCell }
          createHocket={ this.props.createHocket }
          codeCell={ codeCell }
          setMousetrap={ this.props.setMousetrap }
          saveMessage={ saveLessonMessage }
          hocketRef={ hocketRef }
          setLang={ (lang) => this.setLang(lang) }
          />
      </div>
    );
  }

  setLang(lang) {
    if (this.props.setLang) {
      this.props.setLang(lang);
    }
  }

  render() {
    const { hocket, index, width } = this.props;
    if (!hocket) return null;
    const { db, currentUser } = this.props;
    const { activeHocketId, comments, codeCell,
            showComments, activeCommentHocket } = this.props;
    const style = {background: '#fff'};
    if (this.props.reOrder && hocket.id === activeHocketId) {
      style.background = 'rgb(169, 216, 225)'; // light teal
    }
    if (this.props.inSelectionRange) {
      style.background = '#fff3a8' // light yellow
    }
    const card = (<div
              className="lesson-card"
              key={hocket.id}
              id={hocket.id}
              style={ style }
              onClick={ (e) => {
                  if (e.shiftKey) {
                    if (activeHocketId) {
                      this.props.setSelectionEnd(hocket.id);
                    } else {
                      this.props.setActiveHocketId(hocket.id);
                    }
                  } else {
                    this.props.setActiveHocketId(hocket.id);
                  }
              } }>
      { showComments ? <CommentsArea
        db={ db }
        width={ width }
        currentUser={ currentUser }
        comments={ comments[hocket.id] }
        parentId={ hocket.id }
        commentRef={ this.props.commentRef }
        activeCommentHocket={ activeCommentHocket }
        setActiveCommentHocket={ (id) => this.props.setActiveCommentHocket(id) }
        /> : null }
      <CardContents 
        db={ db } 
        hocket={hocket} 
        index={index}
        codeCell={ codeCell }
        setLang={(lang) => this.setLang(lang)}
        showIndex
        showSuggestions
        showOpenResponse/>
    </div>);
    if (!this.props.locked && !this.props.reOrder && activeHocketId === hocket.id) {
      return <div
        style={ style }
        key={ hocket.id }
        className={ 'message-form-card' } >
        { showComments ? <CommentsArea
          db={ db }
          width={ width }
          currentUser={ currentUser }
          comments={ comments[hocket.id] }
          parentId={ hocket.id }
          commentRef={ this.props.commentRef }
          activeCommentHocket={ activeCommentHocket }
          setActiveCommentHocket={ (id) => this.props.setActiveCommentHocket(id) }
          /> : null }
          { this.renderHocketForm() }
        </div>;
    }
    if (!this.props.reOrder) return card;
    return (
      <Draggable
        draggableId={ hocket.id }
        index={ index }
        key={ hocket.id }>
      {(provided, snapshot) => {
        return (
            <div
              ref={ provided.innerRef }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="lesson-card"
              key={ hocket.id }
              style={ getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                style
              )}
              onClick={ (e) => {
                  if (this.hocketsAreaRef) {
                    this.hocketsAreaRef.focus()
                  }
                  if (e.shiftKey) {
                    this.props.setSelectionEnd(hocket.id);
                  } else {
                    this.props.setActiveHocketId(hocket.id);
                  }
              } }>
              <CardContents 
                codeCell={ codeCell }
                hocket={ hocket }
                index={ index } 
                setLang={(lang) => this.setLang(lang)}
                showIndex
                showSuggestions
                showOpenResponse
              />
            </div>
        );
      }}
      </Draggable>
    );
  }

}

export default MessageCard;