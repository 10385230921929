import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Project } from '../project';
import {
  createCourse,
  loginToCreateCourse
} from '../analytics';
import "./style.css";

class CreateProjectView extends Component {
  
  constructor(props) {
    super(props);
    this.unsub = {
      project: null,
    };
    this.state = {
      project: {},
    };
  }

  componentDidMount() {
  }

  createProject() {
    const { currentUser, db, router } = this.props;
    if (!currentUser) return console.log('no user');
    const project = Project(currentUser);
    const { id } = project;
    createCourse(id);
    return db.collection('projects')
      .doc(id)
      .set(project)
      .then(() => {
        return db.collection('users')
          .doc(currentUser.id)
          .set({ activeProjectId: id }, { merge: true });
      }).then(() => {
        // We need some time for this change to be visible 
        // from the database; otherwise, annoying bugs come
        // up on the following project page:
        setTimeout(() => router.history.push('/projects/' + id), 
                   2500);
      }).catch(console.error);
  }

  loginThenCreate(paymentPlan) {
    if (!this.props.login) return null;
    this.props.login(null)
      .then(() => {
        loginToCreateCourse();
        return this.createProject();
      }).catch(console.error);
  }

  renderClassCreateArea() {
    const { currentUser } = this.props;
    const createFreePlanButton = (
        <div>
          <Button
            variant="outlined"
            onClick={ () => this.createProject('free') }
            >
            Create
          </Button>
        </div>
    );
    const createPremiumPlanButton = (
        <div>
          <Button
            variant="outlined"
            disabled={ true }
            >
            Create
          </Button>
        </div>
    );
    const createFreePlanButtonWithLogin = (
        <div>
          <Button
            onClick={ () => this.loginThenCreate('free') }
            >
            Create
          </Button>
        </div>
    );
    // const createPremiumPlanButtonWithLogin = (
    //     <div>
    //       <Button
    //         onClick={ () => this.loginThenCreate('pandemic-special') }
    //         >
    //         Create
    //       </Button>
    //     </div>
    // );
    return (
      <div className="flex-container flex-wrap">
        <div className="flex-child card">
          <h2>Free</h2>
          <p className="exposition">
            All of the features we have. <br/>
            Free for you and your students.
          </p>
          { currentUser ? createFreePlanButton : createFreePlanButtonWithLogin }
        </div>
        <div className="flex-child card">
          <h2>Premium</h2>
          <p className="exposition">
            With an AI classroom assistant. <br/> Coming soon.
          </p>
          { createPremiumPlanButton }
        </div>
      </div>
    );
  }

  render() {
    const style = {
      opacity: 0,
      padding: '40px',
      fontSize: '1.5em',
      fontWeight: '400',
      textAlign: 'center',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    return (
      <div className="pricing-view" id="create" style={ style }>
        <p>Select a Plan</p>
        { this.renderClassCreateArea() }
      </div>
    );
  }

}

export default CreateProjectView;
