import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './style.css';
const uuid = require('uuid/v4');

class MailingListView extends Component {
  
  constructor(props) { 
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      submitted: false,
    }
  }
 
  submit() {
    const { db } = this.props;
    const { firstName, lastName, email } = this.state;
    if (firstName === '' || lastName === '' || email === '') {
      window.confirm('Please enter your name and email address!');
      return;
    }
    const timestamp = (new Date()).toISOString();
    return db.collection('mailing-list')
            .doc(uuid())
            .set({
              firstName, lastName, email, timestamp,
            })
            .then(
              () => this.setState({ submitted: true })
            )
            .catch(console.error);
  }
 
  renderForm() {
    const { firstName, lastName, email } = this.state;
    return (
      <>
        <TextField 
          fullWidth
          key='first-name-input'
          value={ firstName || '' }
          className='email-input'
          variant="outlined"
          placeholder="First name..."
          onChange = { (e) => {
            this.setState({firstName: e.target.value});
          }}
          />
        <TextField 
          fullWidth
          key='last-name-input'
          value={ lastName || '' }
          className='email-input'
          variant="outlined"
          placeholder="Last name..."
          onChange = { (e) => {
            this.setState({lastName: e.target.value});
          }}
          />
        <TextField 
          fullWidth
          key='email-input'
          value={ email || '' }
          className='email-input'
          variant="outlined"
          placeholder="Email..."
          onChange = { (e) => {
            this.setState({email: e.target.value});
          }}
          />
        <Button
          className='submit-button'
          variant='outlined'
          onClick={ () => this.submit() }>
          Submit
        </Button>
      </>
    );
  }
  
  renderThanks() {
    const { router } = this.props;
    return (
      <>
        <p className="mailing-list-thanks">Thanks!</p>
        <Button
          className='submit-button'
          variant='outlined'
          onClick={ () => router.history.push("/") }>
          Home
        </Button>
      </>
    );
  }
  
  render() {
    const { submitted } = this.state; 
    const style = {
      opacity: 0,
      fontWeight: '400',
      textAlign: 'center',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    return (
      <div style={ style } className="profile-view">
        <img className="logo-image" src="/prismia-logo-simple.svg" alt="logo"/>
        { submitted ? this.renderThanks() : this.renderForm() }
      </div>
    );
  }

}

export default MailingListView;
