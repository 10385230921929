import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Editor from 'react-simple-code-editor';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Fabric from './Fabric';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import handlebars from 'handlebars';
import 'react-quill/dist/quill.snow.css';
import SuggestionSuperList from './SuggestionSuperList';
import InteractiveGraph from './InteractiveGraph';
import QuillSuperList from './QuillSuperList';
import { CollapsibleJupyterCell } from './JupyterCell';
import CsvTable from './CsvTable';
import OpenResponseList from './OpenResponseList';
import { now, isNote, hashString, svgUploader, extractCodeBlock, arraySafeNow } from './utils';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Hocket from './hocket';
import * as Mousetrap from 'mousetrap';
import { addStyles, EditableMathField } from 'react-mathquill'
import { starterCode } from './constants';
addStyles();

const theme = createMuiTheme({
  palette: {
    primary: { main: '#237882'},
  }
});

function extractChamberIdFromUpdateString(updateString) {
  // so to start it'll be something like activeChamberId = "abc-234-cvjao"
  // so we'll split it and take the id
  let tmp = updateString.split(" ")[2];
  if (tmp[0] !== '"') return tmp;
  // okay, so now tmp is "abc-234-cvjao" and we just need to remove the quotes
  return tmp.substr(1, tmp.length - 2) || '';
}

handlebars.registerHelper('either', function(first, second, third) {
  return first || second || third;
});

handlebars.registerHelper('or', () => false);

class MessageForm extends Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.fabricRef = React.createRef();
    this.keysPressed = {};
    this.state = {
      hocket: null,
      tags: [],
      chambers: {},
      chamberExit: false,
      followupHocketIds: [],
      triggerCriteria: [],
      responses: [],
      suggestions: [],
      fabric: null,
      trainingPhrases: [],
      blackboardUpdates: [],
      canvasOpen: false,
      watermark: null,
      watermarkClearPromise: false,
      latexOpen: false,
      calculatorOpen: false,
      jcOpen: false,
      latex: "",
      cursorPosition: 0,
      juniperCode: '',
      juniperHasRendered: false,
      jessieCode: '',
      jessieCodeDraft: '',
      aspectRatio: "100%",
      aspectRatioInput: 1,
      mathjs: '',
      openSuggestedResponses: false,
      codeCell: false,
      showTable: false,
      tableData: {string: ''},
      saveOnExit: true,
      urlIFrame: '',
      heightIFrame: 200,
    };
    this.jessieCodeRef = React.createRef();
    import('mathjs').then( math => {
      this.math = math;
    });
  }

  componentDidMount() {
    this.setRefs(this.props);
    this.mounted = true;
    for (let delay of [100, 500]) {
      setTimeout(() => {
        if (this.props.mainQuillRef && this.props.mainQuillRef.current) {
          this.props.mainQuillRef.current.focus();
        }
      }, delay);
    }
    this.setMousetrap();
  }

  setMousetrap() {
    Mousetrap.bind('ctrl+l', () => this.toggleCalculator());
    Mousetrap.bind('ctrl+m', () => this.toggleEquationEditor());
    Mousetrap.bind('ctrl+g', () => this.toggleJessieCode());
    Mousetrap.bind('ctrl+i+f', () => this.toggleIFrame());
    Mousetrap.bind('ctrl+s', () => this.toggleSuggestedMessages());
    Mousetrap.bind('ctrl+d', () => this.toggleCanvas());
    Mousetrap.bind('ctrl+h', () => this.insertHorizontalRule());
    Mousetrap.bind('esc', () => this.saveHocket());
  }

  //getDerivedStateFromProps(newProps) {
  UNSAFE_componentWillReceiveProps(newProps) {
    const propsToWatch = ["db", "projectId", "hocketId"];
    for (let prop of propsToWatch) {
      if (newProps[prop] !== this.props[prop]) {
        this.setRefs(newProps);
        return;
      }
    }
  }

  componentWillUnmount() {
    if (this.state.saveOnExit) {
      try {
        this.saveHocket(true);
      } catch (err) {
        console.log(err);
      };
    }
    this.unsetMousetrap();
  }

  unsetMousetrap() {
    Mousetrap.unbind('ctrl+m');
    Mousetrap.unbind('ctrl+l');
    Mousetrap.unbind('ctrl+g');
    Mousetrap.unbind('ctrl+s');
    Mousetrap.unbind('ctrl+d');
    Mousetrap.unbind('ctrl+i+f');
    Mousetrap.unbind('esc');
  }

  setRefs(props) {
    let { hocketId } = props;
    let hocket;
    if (!hocketId) {
      hocket = Hocket();
      hocketId = hocket.id;
    }
    this.props.hocketRef
      .get()
      .then(snap => {
        if (this.props.regradeRequest) {
          const thread = snap.data().regradeRequestThread;
          if (!thread) return;
          this.setState({ regradeRequestThread: thread });
          hocket = thread.filter(hocket => hocket.id === hocketId)[0];
        } else if (this.props.inCommentsField) {
          hocket = snap.data().comments;
        } else {
          hocket = snap.data();
        }
        if (!hocket) return;
        for (let i = 0; i < hocket.responses.length; i++) {
          if (/ops/.test(hocket.responses[i])) continue;
          if (!hocket.responses[i]) continue;
          hocket.responses[i] = JSON.stringify({ops: JSON.parse(hocket.responses[i])});
        }
        let blackboardUpdates, tableData={ string: '' };
        const {
          responses = [],
          suggestions = [],
          openResponse = [],
          fabric = 'null',
          followupHocketIds = [],
          trainingPhrases = [],
          triggerCriteria = [],
          jessieCode = '',
          juniperCode = '',
          codeCell = false,
          aspectRatio = "100%",
          urlIFrame = '',
          heightIFrame = 0,
        } = hocket;
        if (typeof hocket.tableData === 'string') {
          tableData = { string: hocket.tableData };
        } else if (typeof hocket.tableData === 'object') {
          tableData = hocket.tableData;
        }

        if (!Array.isArray(hocket.blackboardUpdates)) {
          blackboardUpdates = [];
        } else {
          blackboardUpdates = hocket.blackboardUpdates;
        }
        if (typeof hocket.tableData === 'string') {
          tableData = { string: hocket.tableData };
        } else if (typeof hocket.tableData === 'object') {
          tableData = hocket.tableData;
        }
        let blackboardUpdateActiveChamberId = '';
        for (let i = 0; i < blackboardUpdates.length; i++) {
          if (blackboardUpdates[i].startsWith('activeChamberId = ')) {
            // the problem is that this thing is going to actually be in quotes
            let updateString = blackboardUpdates.splice(i, 1)[0];
            let chamberId = extractChamberIdFromUpdateString(updateString);
            blackboardUpdateActiveChamberId = chamberId || '';
            break
          }
        }
        let triggerCriteriaActiveChamberId = '';
        for (let i = 0; i < triggerCriteria.length; i++) {
          if (triggerCriteria[i].startsWith('activeChamberId == ')) {
            let updateString = triggerCriteria.splice(i, 1)[0];
            let chamberId = extractChamberIdFromUpdateString(updateString);
            triggerCriteriaActiveChamberId = chamberId || '';
            break;
          }
        }
        const chamberExit = blackboardUpdateActiveChamberId === '"null"';
        this.setState({
          hocket,
          codeCell,
          juniperCode,
          tableData,
          showTable: tableData?.string?.length > 0,
          jessieCode,
          jessieCodeDraft: jessieCode,
          aspectRatio,
          aspectRatioInput: parseFloat(aspectRatio.slice(0, -1))/100,
          blackboardUpdates,
          blackboardUpdateActiveChamberId,
          chamberExit,
          responses,
          followupHocketIds,
          suggestions,
          openResponse,
          fabric: JSON.parse(fabric),
          urlIFrame,          
          heightIFrame,
          trainingPhrases,
          triggerCriteria,
          triggerCriteriaActiveChamberId,
        });
      });
  }

  updateCriteria(triggerCriteria) {
    // parser's going to need to go here
    this.setState({ triggerCriteria });
  }

  updateTrainingPhrases(trainingPhrases) {
    this.setState({ trainingPhrases });
  }

  updateResponses(responses) {
    // going to need to update this to return quill deltas
    this.setState({ responses });
  }

  updateSuggestions(suggestions) {
    this.setState({ suggestions });
  }

  updateOpenResponse(intents) {
    this.setState({ openResponse: intents });
  }

  updateFollowupHocketIds(followupHocketIds) {
    // parser's going to need to go here
    this.setState({ followupHocketIds });
  }

  updateUpdates(blackboardUpdates) {
    // parser's going to need to go here
    this.setState({ blackboardUpdates });
  }

  toggleChamberExit() {
    this.setState({
      blackboardUpdateActiveChamberId: null,
      chamberExit: !this.state.chamberExit
    });
  }

  toggleIFrame() {
    this.setState({ showIFrame: !this.state.showIFrame });
  }

  createImage(url) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    return img;
  }

  saveFabric(fabric) {
    this.setState({ fabric });
  }

  saveSketch(svg) {
    const { storage, currentUser } = this.props;
    svgUploader(storage, currentUser.id, svg).then(url => {
      const editor = this.props.mainQuillRef.current.editor;
      const currentPosition = (editor.getSelection() || {index: 0}).index;
      editor.insertEmbed(currentPosition, 'image', url);
    }).then(() => this.setState({ 
      canvasOpen: false,
      watermark: null,
    })).catch(console.error);
  }

  renderFabric() {
    const { canvasOpen, watermark } = this.state;
    if (!canvasOpen) return null;
    return <div ref={this.fabricRef} className="fabric-canvas-tool halo-shadow">
      <Fabric
        width={ 716 }
        parentRef={this.fabricRef}
        saveSketch={ (svg) => this.saveSketch(svg) }
        saveFabric={ (json) => this.saveFabric(json) }
        initialJson={ this.state.fabric }
        watermark={ watermark }
        setMousetrap={ () => {
          if (this.props.setMousetrap) {
            this.props.setMousetrap();
          }
          this.setMousetrap();
        } }/>
    </div>;
  }

  renderSwitch() {
    const { chamberExit } = this.state;
    const label = chamberExit ? 'Exit Chamber' : 'Exit Chamber';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ !!chamberExit }
              onChange={ () => this.toggleChamberExit() }
              />
            }
          label={ label }
        />
      </FormGroup>
    );
  }

  sendHocketMessage() {
    const {
      blackboardUpdates = [],
      hocket,
      responses = [],
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
      blackboardUpdateActiveChamberId = '',
      triggerCriteriaActiveChamberId = '',
    } = this.state;
    hocket.blackboardUpdates = blackboardUpdates;
    if (blackboardUpdateActiveChamberId) {
      hocket.blackboardUpdates.push('activeChamberId = "' + blackboardUpdateActiveChamberId + '"');
    }
    hocket.responses = responses;
    hocket.suggestions = suggestions;
    hocket.followupHocketIds = followupHocketIds;
    hocket.trainingPhrases = trainingPhrases;
    hocket.triggerCriteria = triggerCriteria;
    if (triggerCriteriaActiveChamberId) {
      hocket.triggerCriteria.push('activeChamberId == "' + triggerCriteriaActiveChamberId + '"');
    }
    hocket.timestamp = now();
    if (this.props.sendHocketAsMessage) {
      this.props.sendHocketAsMessage(hocket);
    }
    this.setState({
      responses: [],
      suggestions: [],
    });
  }

  markAnswered() {
    if (this.props.markAnswered) {
      this.props.markAnswered();
    }
  }

  saveHocket(stay = false) {
    const {
      blackboardUpdates = [],
      hocket,
      responses = [],
      suggestions = [],
      openResponse = [],
      fabric = null,
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
      blackboardUpdateActiveChamberId = '',
      triggerCriteriaActiveChamberId = '',
      jessieCodeDraft = '',
      aspectRatio = '100%',
      codeCell = false,
      tableData = {string: ''},
      urlIFrame = '',
      heightIFrame = 0,
    } = this.state;
    hocket.blackboardUpdates = blackboardUpdates;
    if (blackboardUpdateActiveChamberId) {
      hocket.blackboardUpdates.push('activeChamberId = "' + blackboardUpdateActiveChamberId + '"');
    }
    hocket.responses = responses;
    hocket.suggestions = suggestions;
    hocket.openResponse = openResponse;
    hocket.fabric = JSON.stringify(fabric);
    hocket.followupHocketIds = followupHocketIds;
    hocket.trainingPhrases = trainingPhrases;
    hocket.triggerCriteria = triggerCriteria;
    hocket.codeCell = codeCell;
    hocket.aspectRatio = aspectRatio;
    hocket.tableData = tableData;
    hocket.note = isNote(hocket);
    hocket.urlIFrame = urlIFrame;
    hocket.heightIFrame = heightIFrame;
    if (triggerCriteriaActiveChamberId) {
      hocket.triggerCriteria.push('activeChamberId == "' + triggerCriteriaActiveChamberId + '"');
    }
    hocket.timestamp = (this.props.inCommentsField || this.props.regradeRequest) ? arraySafeNow() : now();
    if (typeof jessieCodeDraft === 'string') hocket.jessieCode = jessieCodeDraft;
    hocket.tableData = this.state.tableData;
    if (this.props.saveMessage) this.props.saveMessage();
    if (!stay && this.props.clearActiveHocketId) {
      this.props.clearActiveHocketId();
    }
    let data = hocket;
    if (this.props.regradeRequest) {
      const { regradeRequestThread } = this.state;
      const idx = regradeRequestThread.map(hocket => hocket.id).indexOf(this.props.hocketId);
      regradeRequestThread[idx] = hocket;
      data = { regradeRequestThread };
    } else if (this.props.inCommentsField) {
      data = {comments: hocket};
    }
    return this.props.hocketRef.set(data, {merge: true}).catch(console.error);
  }

  renderTriggerCriteriaChamberSelect() {
    let { chambers, triggerCriteriaActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={triggerCriteriaActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ triggerCriteriaActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }


  renderBlackboardUpdateChamberSelect() {
    let { chambers, blackboardUpdateActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={blackboardUpdateActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ blackboardUpdateActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }

  handleChange(event) {
    const { value } = event.target;
    const { hocket } = this.state;
    hocket.tags = value;
    this.setState({ hocket });
  };

  renderTags() {
    let { tags, hocket } = this.state;
    if (!tags || !hocket) return null;
    if (!hocket.tags) hocket.tags = [];
    const tagMap = tags.reduce((acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    }, {});
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-mutiple-chip-label">Tags</InputLabel>
        <Select
          id="demo-mutiple-chip"
          multiple
          value={hocket.tags}
          onChange={(e) => this.handleChange(e)}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => {
            if (!selected || !selected.map) return;
            return (
              <div>
                {selected.map(id => {
                  if (!tagMap[id]) return null;
                  return (
                    <Chip key={id} label={tagMap[id].displayName}/>
                  );
                }
                )}
              </div>
            );
            }
          }
        >
          {tags.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  keyChecker(e) {
    this.keysPressed[e.keyCode] = e.type === 'keydown';
  }

  keyDown(e) {
    const { mainQuillRef } = this.props;
    this.keyChecker(e);
    if (e.keyCode !== 13) return;
    // if we're at this point, we know 13 has been pressed
    // so we check if shift has been pressed too
    if (this.keysPressed[16]) {
    // if it's shift+enter we save and create a new message
      const editor = mainQuillRef.current.editor;
      const cursorPosition = editor.getSelection().index;
      editor.deleteText(cursorPosition - 1, 1);
      this.saveHocket();
      this.props.createHocket();
    }
  }

  keyUp(e) {
    this.keyChecker(e);
  }

  toggleCanvas() {
    const { canvasOpen } = this.state;
    this.setState( {canvasOpen: !canvasOpen, latexOpen: false} );
  }

  toggleJessieCode() {
    const { jcOpen, jessieCode, jessieCodeDraft } = this.state;
    if (jcOpen) {
      if (jessieCode === jessieCodeDraft) {
        this.setState({jcOpen: false});
      } else {
        this.setState({jessieCode: jessieCodeDraft},
          () => this.saveHocket(true) // stay = true
        );
      }
    } else {
      if (jessieCodeDraft === '') {
        this.setState({jessieCodeDraft: starterCode, jessieCode: starterCode},
          () => this.toggleJessieCode()
        );
      } else {
        this.setState({jcOpen: true});
      }
    }
  }

  toggleCalculator() {
    const { calculatorOpen } = this.state;
    const { mainQuillRef } = this.props;
    if (calculatorOpen) {
      this.insertFromCalculator();
    }
    const range = mainQuillRef.current.editor.getSelection();
    if (range) this.setState({ cursorPosition: range.index });
    this.setState({ mathjs: "", calculatorOpen: !calculatorOpen });
  }

  toggleSuggestedMessages() {
    this.setState({ openSuggestedResponses: !this.state.openSuggestedResponses });
  }

  toggleOpenResponse() {
    this.setState({ openOpenResponse: !this.state.openOpenResponse })
  }

  toggleEquationEditor() {
    const { latexOpen } = this.state;
    const { mainQuillRef } = this.props;
    if (latexOpen) {
      this.insertFromMathEditor();
    }
    const range = mainQuillRef.current.editor.getSelection();
    if (range) this.setState({ cursorPosition: range.index });
    this.setState({ latex: "", latexOpen: !latexOpen });
  }

  insertFromCalculator() {
    const { mathjs, cursorPosition } = this.state;
    const { mainQuillRef } = this.props;
    let mathtext = ''
    if (mathjs.length) {
      mathtext = this.processMathJs(mathjs)
      mainQuillRef.current.editor.insertText(cursorPosition,
                            '$' + mathtext + '$');
    }
    this.setState({ calculatorOpen: false });
    mainQuillRef.current.focus();
    mainQuillRef.current.editor.setSelection(cursorPosition + (mathjs.length ? mathtext.length + 2 : 0));
  }

  insertHorizontalRule() {
    const { mainQuillRef } = this.props;
    const editor = mainQuillRef.current.editor;
    const cursorPosition = editor.getSelection().index;
    editor.insertEmbed(cursorPosition+1, 'hr', true);
  }

  insertFromMathEditor() {
    const { latex, cursorPosition } = this.state;
    const { mainQuillRef } = this.props;
    if (latex.length) {
      mainQuillRef.current.editor.insertEmbed(cursorPosition, 'formula', latex);
    }
    this.setState({ latexOpen: false });
    mainQuillRef.current.focus();
    mainQuillRef.current.editor.setSelection(cursorPosition + (latex.length ? 1 : 0))
  }

  renderJessieCodeEditor() {
    let { jessieCodeDraft, aspectRatioInput } = this.state;
    return (
      <>
        <Editor
         ref={this.jessieCodeRef}
         className="jessiecode-editor"
         value={ jessieCodeDraft }
         onValueChange={ code => this.setState({ jessieCodeDraft: code })}
         highlight={ code => highlight(code, languages.js) }
         padding={10}
         style={{
           fontFamily: '"Menlo", "Monaco", "Fira code", "Fira Mono", monospace',
           fontSize: 14,
         }}
       />
       <TextField
         label="Aspect ratio"
         className="aspect-ratio-input"
         variant="outlined"
         value={ aspectRatioInput }
         onChange={ (e) => this.setState( {aspectRatioInput: e.target.value} )}
         onBlur={ () => {
            this.setState({ aspectRatio: (100 * parseFloat(aspectRatioInput) + "%") || "100%" });
         } }/>
     </>
   );
  }

  renderTable() {
    if (!this.state.showTable) return null;
    if (this.state.showTable === 'editor') {
      return <>
        <Editor
          className="jessiecode-editor"
          value={ this.state.tableData }
          onValueChange={ code => this.setState({ tableData: code }) }
          highlight={ code => code }
          padding={10}
          style={{
            fontFamily: '"Menlo", "Monaco", "Fira code", "Fira Mono", monospace',
            fontSize: 14,
          }}/>
    </>
    }
    return (
      <CsvTable
        data={ this.state.tableData }
        setData={ (tableData, Cb) => { this.setState({ tableData }, Cb) }}
        hideTable={ () => this.toggleTable() }/>
    );
  }

  toggleTable() {
    if ( this.state.tableData?.string.length ) return;
    this.setState({ showTable: !this.state.showTable });
  }

  renderJSXGraph() {
    const { jessieCode, aspectRatio } = this.state;
    return (
      <InteractiveGraph
        key={ 'interactive-graph-' + hashString(jessieCode + aspectRatio) }
        code={ jessieCode }
        ratio={ aspectRatio }/>
    );
  }

  renderMathQuill() {
    const { latex, latexOpen } = this.state;
    if (!latexOpen) return null;
    return (
      <div className="message-form-mathquill-tool math-quill-container">
        <EditableMathField
          className="editable-math-field"
          mathquillDidMount={mathField => {
            mathField.focus()
          }}
          latex={ latex }
          onChange={ (mathField) => {
            this.setState({latex: mathField.latex()});
          } }
        />
        <div className="insert-button-container">
          <div className="math-insert-button">
            <Button
              onClick={ () => this.insertFromMathEditor() }>
              { latex.length ? "Insert" : "Exit" }
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderIFrame() {
    const { urlIFrame='', heightIFrame=0 } = this.state;
    return <>
      <div className="message-form-mathquill-tool math-quill-container">
        <TextField
          autoFocus
          className="editable-math-field"
          variant="outlined"
          placeholder="url"
          value={ urlIFrame }
          onChange={ (e) => this.setState({urlIFrame: e.target.value})}/>
        </div>               
        <div className="message-form-mathquill-tool math-quill-container"> 
        <TextField
          autoFocus
          className="editable-math-field"
          variant="outlined"
          placeholder="height"
          value={ heightIFrame }
          onChange={ (e) => this.setState({heightIFrame: e.target.value})}/>
        <div className="insert-button-container">
          <div className="math-insert-button">
            <Button
              onClick={ () => this.toggleIFrame() }>
              { urlIFrame.length ? "Save" : "Exit" }
            </Button>
          </div>
        </div>
      </div>
    </>
  }

  renderCalculator() {
    const { mathjs, calculatorOpen } = this.state;
    if (!calculatorOpen) return null;
    return (
      <div className="message-form-mathquill-tool math-quill-container">
        <TextField
          autoFocus
          className="editable-math-field"
          variant="outlined"
          onChange={ (e) => this.setState({mathjs: e.target.value})}/>
        <div className="insert-button-container">
          <div className="math-insert-button">
            <Button
              onClick={ () => this.toggleCalculator() }>
              { mathjs.length ? "Insert" : "Exit" }
            </Button>
          </div>
        </div>
      </div>
    );
  }

  processMathJs(mathjs) {
    try {
      return this.math.parse(mathjs).toTex() + ' = ' + this.math.parse(String(this.math.evaluate(mathjs))).toTex();
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  renderButtonPanel() {
    return <div className="flex-container space-between align-center">
      <div className="flex-container padded-bottom space-between flex-child">
      <ThemeProvider theme={ theme }>
          <Button
            variant="outlined"
            size="small"
            onClick={ () => this.toggleSuggestedMessages()}
            className="flex-auto">
            Answer Choices
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={ () => this.toggleEquationEditor()}
            className="flex-auto">
            Equation Editor
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={ () => this.toggleJessieCode()}
            className="flex-auto">
            Graph
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={ () => this.toggleCanvas()}
            className="flex-auto">
            Drawing Canvas
          </Button>
        </ThemeProvider>
      </div>
      { this.props.codeCell ? <div className="flex-auto" style={{marginLeft: "16px", transform: "translate(24%, -13%)"}}> <Tooltip
        title="include an executable code cell with this message"
        enterDelay={ 400 }><Checkbox className="code-cell-checkbox" checked={this.state.codeCell} color="default" onChange={(e) => this.handleCheckChange(e)} inputProps={{ 'aria-label': 'include code cell with message' }}/></Tooltip></div> : null }
    </div>;
  }

  handleCheckChange(event) {
    this.setState({ codeCell: event.target.checked });
  }

  setLang(lang) {
    if (this.props.setLang) {
      this.props.setLang(lang);
    }
  }

  render() {
    if (!this.state.hocket) return null;
    const { projectId, hideSuggestedResponses=false,
            mainQuillRef=null, storage=null} = this.props;
    const { latexOpen, calculatorOpen, jcOpen, showIFrame, urlIFrame,
            heightIFrame, jessieCode, canvasOpen, codeCell } = this.state;
    let codeText;
    if (codeCell && this.state.responses?.[0]) {
      codeText = extractCodeBlock(JSON.parse(this.state.responses[0]));
    } else if (codeCell) {
      codeText = '';
    }
    const optionalToggles = {};
    if (!this.props.hideSuggestedMessages) {
      optionalToggles.toggleSuggestedMessages = () => this.toggleSuggestedMessages();
    }
    if (!this.props.hideOpenResponse) {
      optionalToggles.toggleOpenResponse = () => this.toggleOpenResponse();
    }
    return (
      <div className="message-form-container-container">
        <div className="">
          <div>
            <QuillSuperList
              storage={ storage }
              label="Response"
              noToolbar={ false }
              limit={ 1 }
              values={ this.state.responses }
              fullEditor={ true }
              imageCb={ url => {
                this.setState({ watermark: {url, fabric: null} });
              }}
              {...optionalToggles}
              studentView={ this.props.studentView }
              toggleTable={ () => this.toggleTable() }
              toggleCanvas={ () => this.toggleCanvas() }
              toggleMathlet={ () => this.toggleJessieCode() }
              toggleEquationEditor={ () => this.toggleEquationEditor() }
              toggleCalculator={ () => this.toggleCalculator() }
              codeCellChecked={ this.state.codeCell }
              handleCheckChange={ e => this.handleCheckChange(e) }
              quillRef={ mainQuillRef }
              onKeyDown={ (e) => this.keyDown(e, mainQuillRef) }
              onKeyUp={ (e) => this.keyUp(e) }
              updateValues={ (responses) => this.updateResponses(responses) }/>
            { urlIFrame ? <iframe width="100%" frameborder="0" height={heightIFrame + "px"}
                                  src={urlIFrame}/> : null }
            { showIFrame ? this.renderIFrame() : null }
            { calculatorOpen ? this.renderCalculator() : null }
            { latexOpen ? this.renderMathQuill() : null }
            { this.renderTable() }
            { jessieCode.length ? this.renderJSXGraph() : null }
            { jcOpen ? this.renderJessieCodeEditor() : null }
            { canvasOpen ? this.renderFabric() : null }
            { ((hideSuggestedResponses || this.state.suggestions.length === 0) &&
               !this.state.openSuggestedResponses) ? null :
              <>
              <SuggestionSuperList
                label="Suggestion"
                includeSuggestionReplies
                showHocketField={ false }
                limit={ 15 }
                projectId={ projectId }
                db={ this.props.db }
                values={ this.state.suggestions }
                updateValues={ (suggestions) => this.updateSuggestions(suggestions) }
                keyDownCb={() => this.saveHocket()}/>
              </>
            }
            { this.state.openResponse.length === 0 && !this.state.openOpenResponse ? null : 
              <OpenResponseList
                label="Student intent"
                limit={ 15 }
                projectId={ projectId }
                values={ this.state.openResponse }
                updateValues={ (intents) => this.updateOpenResponse(intents) }
                keyDownCb={ () => this.saveHocket() }/>
            }
          </div>
        </div>
        { typeof codeText === 'string' ?
          <div 
            className="on-card-juniper-container"
            onClick={ e => e.stopPropagation() }>
            <CollapsibleJupyterCell
              content={ codeText }
              language={ this.props.codeCell }
              setLang={(lang) => this.setLang(lang)}/>
          </div> : null }
        {this.props.hideButtons ? null : <div className="save-delete-container">
          <Tooltip
            title="delete this message [delete/backspace]"
            enterDelay={ 400 }>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={ () => this.props.deleteMessages() } >Delete</Button>
          </Tooltip> 
          <Tooltip
            title="save the message [esc]"
            enterDelay={ 400 }>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="save-button"
              onClick={ () => this.saveHocket() } >Save</Button>
          </Tooltip>
          { this.props.singleForm ? null : 
          <ThemeProvider theme={ theme }>
            <Tooltip
              title="split message at each horizontal rule (---)"
              enterDelay={ 400 }>
              <Button
                variant="outlined"
                size="small"
                className="save-button"
                style={{marginRight: "8px"}}
                onClick={ () => {
                  this.saveHocket(true).then( () =>
                    this.setState( { saveOnExit: false }, () => {
                      if (this.props.splitActiveCell) {
                        this.props.splitActiveCell();
                      }
                    }))
                } }>Split</Button>
            </Tooltip>
            <Tooltip
              title="add a new message after this one"
              enterDelay={ 400 }>
              <Button
                variant="outlined"
                size="small"
                className="save-button"
                style={{marginRight: "8px"}}
                onClick={ () => this.props.createHocket() }>+</Button>
            </Tooltip> 
          </ThemeProvider> }
        </div>
        }
      </div>
    );
  }

}

export default MessageForm;
