import uuid from 'uuid/v4';
import { arraySafeNow, now } from './utils';

function Hocket(trainingPhrases=[], responses=[], safe=false) {
  return {
    id: uuid(),
    draft: false,
    isChamber: false,
    parentChamberRef: null,
    isQuestion: false,
    parentQuestionRef: null,
    isCorrect: false,
    responses,
    suggestions: [],
    trainingPhrases,
    triggerCriteria: [],
    blackboardUpdates: [],
    timestamp: safe ? arraySafeNow() : now(),
    lastTrained: null,
  }
}

export default Hocket;
