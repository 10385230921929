import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class SwitchRenderer extends Component {

  render() {
    if (!this.props.value) return null;
    const [checked, studentId, role] = this.props.value.split(",");
    let Cb;
    if (role === 'student') {
      Cb = () => this.props.context.toggleStudent(studentId);
    } else if ( role === 'ta') {
      Cb = () => this.props.context.toggleTa(studentId); 
    } else if ( role === 'observer' ) {
      Cb = () => this.props.context.toggleObserver(studentId);
    } else {
      Cb = () => this.props.context.toggleInstructor(studentId);
    }
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ checked === 'true'}
              onChange={ Cb }
              color="primary"
            />
            }
          label=""
        />
      </FormGroup>
    );
  }
}