const firebaseConfig = {
  apiKey: "AIzaSyBZTWXWYi-CTYERqycXWvwk20gDvg26T4Q",
  authDomain: "prismia.firebaseapp.com",
  databaseURL: "https://prismia.firebaseio.com",
  projectId: "prismia",
  storageBucket: "prismia.appspot.com",
  messagingSenderId: "784905097862",
  appId: "1:784905097862:web:cf119ccc21a8d354d87707",
  measurementId: "G-EES4YJL553"
};

export default firebaseConfig;
