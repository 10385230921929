import React, { Component } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Chat from '@material-ui/icons/Chat';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import ClusterMessageForm from './ClusterMessageForm';
import StackedBarGraph from './StackedBarGraph';
import ClusteredMessageCard from './ClusteredMessageCard';
import ClusteredMessageCardList from './ClusteredMessageCardList';
import SeedMessageCard from './SeedMessageCard';
import uuid from 'uuid/v4';
import toPlaintext from 'quill-delta-to-plaintext';
import { removeTerminalNewlinesFromQuillDelta, hashString, stringifyMessage,
         timeString, now } from '../utils';
import { hocket2Message, text2message } from '../message';
import { cluster } from '../cluster';
import {
  clusterMessages,
  manuallySortClusteredMessage,
  messageCluster
} from '../analytics';
import './style.css';

const PEER_ICON_URL = "https://firebasestorage.googleapis.com/v0/b/prismia.appspot.com/o/app-images%2Fpeer-icon.svg?alt=media&token=c9eea22c-b084-4c2e-9e1f-07ce13984eaf";

function valuetext(value) {
  return `${value}°C`;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
  },
});

const hueValues = [214, 44, 175, 85, 150, 255, 100, 0, 62];

const MessageCard = ({ message,
                       chatCb,
                       replyToUnansweredMessages, 
                       reviveMessage }) => {
  const chatButton = (
      <IconButton
        className="chat-icon"
        onClick={ chatCb }>
        <Chat />
      </IconButton>
  );
  let classList = [];
  classList.push('message-card');
  if (message.sharedFromClass) {
    classList.push('shared-from-class');
  } else if (message.answered ||
             message.respondedTo) {
    classList.push("answered");
  }
  if (message.author === '000-0000-000') classList.push('instructor-answer');
  classList = classList.join(' ');
  return (
    <div
      className={ classList }>
      <div className="message-card-arrow-container">
        <Tooltip
          title="Send this message to unanswered messages in column"
          enterDelay = { 600 }>
          <Button
            size="small"
            style={{minWidth: "8px", zIndex: 10}}
            onClick={() => replyToUnansweredMessages()}>
            <ArrowUpwardIcon
              fontSize="small"
              style={{color: "white",
                      minWidth: "8px"}} />
          </Button>
        </Tooltip>
        <Tooltip
          title="Put this message into the text box"
          enterDelay = { 600 }>
          <Button
            size="small"
            style={{minWidth: "8px", zIndex: 10}}
            onClick={ reviveMessage }>
            <ArrowDownwardIcon
              fontSize="small"
              style={{color: "white",
                      minWidth: "8px"}} />
          </Button>
        </Tooltip>
      </div>
      { message.author === '000-0000-000' ? null : chatButton }
      <ReactQuill 
        readOnly 
        modules={ { toolbar: false } }
        value={message.quillDelta ? JSON.parse(message.quillDelta) : null}
      />
    </div>
  );
};

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#f4c430' : '#fff',
});

class ClusterBlockDragDropContext extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.changeDetectionString !== this.props.changeDetectionString;
  }

  render() {
    const { onDragEnd, messageClusters } = this.props;
    return (
      <>
        <DragDropContext
          onDragEnd={ onDragEnd }>
          { messageClusters }
        </DragDropContext>
      </>
    );
  }

}

class ClusterBlock extends Component {

  constructor(props) {
    super(props);
    this.db = null;
    this.unsub = {
      messages: null,
      hockets: null,
      activity: null,
    };
    // cluster functions
    this.markResponseInProgress = this.markResponseInProgress.bind(this);
    this.markAnswered = this.markAnswered.bind(this);
    this.sendHocketAsMessage = this.sendHocketAsMessage.bind(this);
    this.starCluster = this.starCluster.bind(this);
    this.setPrivateChat = this.setPrivateChat.bind(this);
    // clustered message card functions:
    this.flagMessage = this.flagMessage.bind(this);
    this.starMessage = this.starMessage.bind(this);
    this.shareMessage = this.shareMessage.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.setMessageAsAnswered = this.setMessageAsAnswered.bind(this);
    this.setMessagesAsAnswered = this.setMessagesAsAnswered.bind(this);
    this.clearMessageLocally = this.clearMessageLocally.bind(this);
    this.clearMessagesLocally = this.clearMessagesLocally.bind(this);
    this.setMessagesResponseInProgress = this.setMessagesResponseInProgress.bind(this);
    this.setStudentChatMessage = this.setStudentChatMessage.bind(this);
    this.clearClusterLocally = this.clearClusterLocally.bind(this);
    this.openLiveStudentTyping = this.openLiveStudentTyping.bind(this);
    this.hydrated = false;;
    this.state = {
      activeStudentChatId: null,
      clusterResponses: [],
      presetClusterResponses: [],
      clusteredMessages: [],
      clusteredMessageIds: new Set(),
      clusterMessageFormContents: [],
      emailMessageCount: 0,
      emailInProgress: false,
      numberOfClusters: 5,
      selectedHocketId: null,
      hocketFilter: '',
      messageFilter: '',
      messages: [],
      sharedMessageIds: {},
      mostRecentQuestion: '',
      hockets: [],
      hocket: null,
      hasBeenStarred: false,
      scored: false,
      deltaKeys: new Set(),
      hideInbox: props.hideInbox,
      locallyClearedMessages: new Set(),
      peerAnswer: {unsent: [], authors: new Set()},
    };
    this.lastUpdate = {};
  }

  componentDidMount() {
    this.subMessages(this.props);
    this.subActivity();
    this.checkScored();
    this.autoScore();
    this.setState({ numberOfClusters: this.props.defaultClusterCount || 5 }, () => {
      this.setSeedMessages();
    });
  }

  setSeedMessages() {
    const { openResponse, currentUser, suggestions } = this.props;
    const L = suggestions?.length || 0;
    if (!openResponse) {
      if (L > 0) this.setState({numberOfClusters: Math.max(this.state.numberOfClusters, L + 1)});
      return;
    }
    const seedMessages = Array.from({length: openResponse.length }, () => []);
    const presetClusterResponses = [];
    if (openResponse.length > this.numberOfClusters) {
      this.setState({ numberOfClusters: openResponse.length });
    }
    for (let i = 0; i < openResponse.length; i++) {
      seedMessages[L + i] = openResponse[i].value
        .split("\n")
        .map(s => s.trim())
        .filter(Boolean)
        .map(text2message);
        if (openResponse[i].reply) {
          presetClusterResponses[L + i] = [{
            author: "000-0000-000",
            authorDisplayName: currentUser.displayName,
            authorPhotoUrl: currentUser.photoUrl,
            id: uuid(),
            quillDelta: openResponse[i].reply,
            textContent: toPlaintext(JSON.parse(openResponse[i].reply).ops),
            timestamp: now(),
        }];
        } else {
          presetClusterResponses[L + i] = [];
        }
    }
    this.setState({ 
      numberOfClusters: Math.max(this.state.numberOfClusters, L + openResponse.length + 1),
      seedMessages,
      presetClusterResponses,
    });
  }

  autoScore() {
    const { title } = this.props;
    if (title.includes('®')) {
      this.score();
    }
  }

  componentDidUpdate(prevProps) {
    const { stopTime, hideCallOnMe, hideObservers,
            hideMessagesHandledByOtherInstructors,
            fadeMessagesHandledByOtherInstructors,
            surfaceFlaggedMessages} = this.props;
    if ( prevProps.hideObservers !== hideObservers ||
         prevProps.hideCallOnMe !== hideCallOnMe ||
         prevProps.stopTime !== stopTime ||
         prevProps.hideMessagesHandledByOtherInstructors !==
           hideMessagesHandledByOtherInstructors ||
           prevProps.fadeMessagesHandledByOtherInstructors !==
                fadeMessagesHandledByOtherInstructors) {
      this.subMessages(this.props);
    }
    if (prevProps.surfaceFlaggedMessages !== surfaceFlaggedMessages) {
      let { clusteredMessages } = this.state;
      clusteredMessages = this.sortMessages(clusteredMessages);
      this.setState({ clusteredMessages });
    }
  }

  checkScored() {
    const db = this.db;
    const { projectId, startTime } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('metrics-daily-activity')
      .doc(startTime.slice(0, 10))
      .get()
      .then( snap => {
        const doc = snap.data();
        if (doc && doc.clusters && doc.clusters[startTime]) {
          this.setState({ scored: true });
        }
      });
  }

  subActivity() {
    const db = this.db;
    const { projectId } = this.props;
    if (this.unsub.activity) this.unsub.activity();
    const handleSnap = (snap) => {
      let numberTyping = 0;
      snap.forEach(doc => {
        const data = doc.data();
        if (!data) return null;
        if (!data.typing) return null;
        if (data.typing && data.lastActive > this.props.startTime) {
          numberTyping += 1;
        }
      });
      this.setState({ numberTyping });
    };
    const oneDayBack = (moment()
          .subtract(1,'days')).toDate().toISOString();
    let activityRef = db
        .collection('projects')
        .doc(projectId)
        .collection('realtime-activity')
        .where('lastActive', '>', oneDayBack);
    this.unsub.activity = activityRef
      .onSnapshot( snap => handleSnap(snap) );
  }

   subMessages(props) {
    const db = this.db = this.props.db;
    let {
      projectId,
      startTime,
      stopTime,
      setStudentsRespondedLatestClusterBlock,
      flaggedMessagesOnly,
    } = props;
    // why do this weird thing?
    // because firebase won't let me store
    // timestamps as items in an array
    // so I'm storing it as an ISO string
    startTime = new Date(startTime);
    if (this.unsub.messages) {
      this.unsub.messages();
    }
    let collectionRef;
    if (flaggedMessagesOnly) {
      collectionRef = db
      .collection('projects')
      .doc(projectId)
      .collection('messages')
      .where('flagged', '==', true)
      .limit(100)
    } else if (stopTime) {
      stopTime = new Date(stopTime);
      collectionRef = db
      .collection('projects')
      .doc(projectId)
      .collection('messages')
      .orderBy('timestamp', 'desc')
      .where('timestamp', '>', startTime)
      .where('timestamp', '<=', stopTime);
    } else {
      collectionRef = db
      .collection('projects')
      .doc(projectId)
      .collection('messages')
      .orderBy('timestamp', 'desc')
      .where('timestamp', '>', startTime);
    }
    const handleSnap = (snap) => {
      let { clusteredMessageIds,
            clusteredMessages=[],
            numberOfClusters,
            scored,
            peerAnswer,
      } = this.state;
      const { multipleChoice, peerResponseActive } = this.props;
      if (clusteredMessages.length === 0) {
        for (let i = 0; i < numberOfClusters + 1; i++) {
          clusteredMessages.push([]);
        }
      }
      const messages = [];
      const removedMessageIds = new Set();
      const messageMap = new Map();
      const peerResponsesToSend = [];
      snap.forEach(doc => {
        const message = doc.data();
        if (this.removable(message)) removedMessageIds.add(message.id);
        messages.push(message);
        messageMap.set(message.id, message);
        // check whether we're doing peer responses from this cluster block instance
        if (peerResponseActive) {
          if (message.peerRecipient && !message.peerResponseSent) {
            peerResponsesToSend.push({message, recipient: message.peerRecipient});
          }
          // hasn't already been sent to the peer reviewer, and
          // isn't a peer review, as indicated by lack of peerRecipient field:
          if (!message.peerSent && !message.peerRecipient &&
                message.author !== "000-0000-000" &&
                !peerAnswer.authors.has(message.author)) {
            peerAnswer.unsent.push(message.id);
            peerAnswer.authors.add(message.author);
          }
        }
      });
      // actually send the peer review responses
      const peerResponsesSent = [];
      for (let peerResponse of peerResponsesToSend) {
        const message = peerResponse.message;
        const recipient = message.peerRecipient;
        const newMessage = {...message};
        newMessage.id = uuid();
        newMessage.authorDisplayName += " (privately to you)";
        newMessage.timestamp = now();
        delete newMessage.peerRecipient;
        this.sendMessageToUser(newMessage, recipient);
        peerResponsesSent.push(message.id);
      }
      this.setMessagesAsPeerResponseSent(peerResponsesSent);
      if (peerResponseActive) {
        const L = peerAnswer.unsent.length;
        if (L > 2) {
          const newlySent = [];
          for (let k = 0; k < L; k++) {
            const message = messageMap.get(peerAnswer.unsent[k]);
            const newMessage = {...message};
            const nextMessage = messageMap.get(peerAnswer.unsent[(k + 1) % L]);
            const recipient = nextMessage.author;
            newMessage.id = uuid();
            newMessage.authorDisplayName = "Anonymous Peer (their answer)";
            newMessage.authorPhotoUrl = PEER_ICON_URL;
            newMessage.peerAnswer = true;
            newMessage.timestamp = now();
            this.sendMessageToUser(newMessage, recipient);
            newlySent.push(message.id); // OLD one gets marked as handled
          }
          this.setMessagesAsPeerSent(newlySent);
          peerAnswer.unsent = [];
        }
        this.setState({ peerAnswer });
      } // end peer response stuff
      const unclustered = messages.filter(
        m => !this.removable(m) && !clusteredMessageIds.has(m.id)
      );
      if (unclustered.length) {
        if (clusteredMessages.length <= numberOfClusters) {
          clusteredMessages = clusteredMessages.concat([unclustered]); // I think this never happens anymore
        } else {
          clusteredMessages[clusteredMessages.length - 1].push(...unclustered);
        }
      }
      // keep clusteredMessageIds in sync
      unclustered.forEach(m => clusteredMessageIds.add(m.id));

      // replace each message already in the clusters with the new
      // message from the database:
      for (let i = 0; i < clusteredMessages.length; i++) {
        if (!clusteredMessages[i]) continue;
        for (let j = 0; j < clusteredMessages[i].length; j++) {
          let message = clusteredMessages[i][j];
          if (messageMap.has(message.id)) {
            clusteredMessages[i][j] = messageMap.get(message.id);
          }
        }
      }

      // pull the messages which should be removed out of the
      // clusters:
      for (let i = 0; i < clusteredMessages.length; i++) {
        if (!clusteredMessages[i]) continue;
        for (let j = 0; j < clusteredMessages[i].length; j++) {
          let message = clusteredMessages[i][j];
          if (removedMessageIds.has(message.id)) {
            //console.log('removing message from cluster ', i)
            //console.log('---', j, message.textContent);
            clusteredMessages[i].splice(j, 1);
            clusteredMessageIds.delete(message.id);
            j--;
          }
        }
      }

      // pull flagged messages and questions to the top:
      clusteredMessages = this.sortMessages(clusteredMessages, false);

      this.setState({
          messages,
          clusteredMessages,
          clusteredMessageIds
        }, () => {
        if (!this.hydrated) {
          this.hydrated = true;
          this.clusterMessages();
        }
        this.updateSharedMessageIds();
        if (scored || multipleChoice) {
          this.scoreThrottled();
        }
        if (setStudentsRespondedLatestClusterBlock) {
          const students = new Set(messages.map(m => m.author).filter(author => author !== '000-0000-000'));
          setStudentsRespondedLatestClusterBlock(students);
        }
      });
    }
    this.unsub.messages = collectionRef
      .onSnapshot(snap => {
        handleSnap(snap);
      });
  }

  removable(message) {
    // A message is removable if it's been cleared out of the
    // clusterblock or if it's being handled by a different
    // instructor AND the hideMessagesHandledByOtherInstructors
    // setting is engaged
    const { surfaceFlaggedMessages, hideCallOnMe, observers,
            hideMessagesHandledByOtherInstructors, hideObservers,
            currentUser, flaggedMessagesOnly } = this.props;
    const { locallyClearedMessages } = this.state;
    if (hideObservers && observers[message.author]) return true;
    if (flaggedMessagesOnly) return !message.flagged;
    if (message.answered || message.peerRecipient ||
          locallyClearedMessages.has(message.id)) {
      return true;
    } else if (surfaceFlaggedMessages && message.flagged) {
      return false;
    } else if (hideCallOnMe && message.callOnMe === false) {
      return true;
    } else {
      return (hideMessagesHandledByOtherInstructors &&
              ((message.respondedTo && message.respondedTo !== currentUser.id) ||
               (typeof message.responseInProgress === 'string' && !message.responseInProgress.startsWith(currentUser.id))));
    }
  }

  setUserNames(ids, role) {
    const { db } = this.props;
    const idsArray = Object.keys(ids);
    const itemRefs = idsArray.map( id => {
      return db.collection('users')
               .doc(id)
               .get();
    });
    Promise.all(itemRefs).then( (docs) => {
      const map = new Map();
      const items = docs.map(doc => doc.data());
      items.forEach( (item, idx) => {
        map.set(idsArray[idx], item.displayName);
      });
      return map;
    }).then( (map) => {
      if (role === 'ta') {
        this.setState({ taMap: map });
      } else {
        this.setState({ instructorMap: map });
      }
    });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (typeof this.unsub[key] === 'function') this.unsub[key]();
    }
  }

  updateSharedMessageIds() {
    const { messages, sharedMessageIds } = this.state;
    messages.forEach( message => {
      if (message.sharedFromClass) {
        sharedMessageIds[message.id] = true;
      }
    });
    this.setState({ sharedMessageIds });
  }

  setActiveStudentChatId(activeStudentChatId) {
    if (activeStudentChatId === '000-0000-000') return null;
    if (!activeStudentChatId) return null;
    if (!this.props.setActiveStudentChatId) return null;
    this.props.setActiveStudentChatId(activeStudentChatId);
  }

  setStudentChatMessage(message) {
    if (!this.props.setStudentChatMessage) return null;
    this.props.setStudentChatMessage(message);
  }

  scoreThrottled(followup=false) {
    const currentTime = new Date();
    if (followup && 
          this.timeLastScored && 
          currentTime - this.timeLastScored < 30000) {
      return;
    }
    if (!this.timeLastScored || currentTime - this.timeLastScored > 10000) {
      this.score();
    } else {
      setTimeout( () => this.scoreThrottled(true), 10050)
    }
  }

  score() {
    // loop through the block's messages to determine metrics data
    // and write it to the metrics-daily-activity collection
    const { db, startTime, projectId, question, suggestions,
            timeZoneOffset, title, multipleChoice } = this.props;
    const { messages, deltaKeys } = this.state;
    this.timeLastScored = new Date();
    const students = new Set(messages.map(m => m.author));
    // need to convert because startTime is ISO
    // while data are assigned to a date based on
    // local time:
    const responses = new Map();
    const responseDeltas = new Map();
    const scores = new Map();
    for (let message of messages) {
      const author = message.author;
      const currentResponses = responses.get(author);
      if (currentResponses) {
        currentResponses.push(message.textContent);
        responseDeltas.get(author).push(message.quillDelta);
      } else {
        responses.set(author, [message.textContent]);
        responseDeltas.set(author, [message.quillDelta]);
      }
      const currentScore = scores.get(author) || 0;
      const thisMessageScore = message.score || 0;
      scores.set(author, Math.max(currentScore, thisMessageScore));
    }
    const dayId = moment(startTime)
                  .add(timeZoneOffset, 'hours')
                  .toISOString()
                  .slice(0, 10);
    const starredStudents = new Set(messages.filter(m => m.starred).map(m => m.author));
    const counts = {};
    students.forEach( (studentId) => {
      const status = starredStudents.has(studentId) ? "starred" : true;
      const response = {
        questionText: title,
        questionDelta: question,
        answers: responses.get(studentId),
        answerDeltas: responseDeltas.get(studentId),
      };
      if (multipleChoice) {
        response.multipleChoice = multipleChoice;
      }
      if (suggestions) {
        response.suggestions = suggestions;
      }
      if (multipleChoice === 'scored') {
        response.score = scores.get(studentId);
      }
      if (starredStudents.has(studentId)) {
        response.starred = true;
      }
      if (studentId !== '000-0000-000') {
        counts[studentId] = {
          responses: {[startTime]: response},
        };
        if (!multipleChoice) {
          counts[studentId]['clusters'] = {[startTime]: status};
        }
      }
    });
    const doc = {};
    if (Object.keys(counts).length > 0) {
      doc.counts = counts;
    }
    if (!multipleChoice) {
      doc.clusters = {[startTime]: true};
      this.setState({ scored: true });
    }
    if (Object.keys(doc).length === 0) return;
    const dayRef = db.collection('projects')
       .doc(projectId)
       .collection('metrics-daily-activity')
       .doc(dayId);
    const deltaKey = hashString(question);
    const batch = db.batch();
    batch.set(dayRef, doc, {merge: true});
    if (!deltaKeys.has(deltaKey)) {
      batch.set(dayRef
        .collection('deltas')
        .doc(deltaKey),
        { delta: question, hash: deltaKey }
      );
    }
    return batch.commit().then( () => {
      deltaKeys.add(deltaKey);
      this.setState({ deltaKeys });
    }).catch(console.error);
  }

  summarize() {
    const { clusteredMessages } = this.state;
    const { mainQuillRef } = this.props;
    const filteredClusters = clusteredMessages
          .filter(cluster => cluster.length > 0);
    const prettify = (map, key) => {
      if (map.get(key) === 1) {
        const ops = removeTerminalNewlinesFromQuillDelta(JSON.parse(key)).ops;
        ops.push({insert: "\n"});
        ops.unshift({insert: "• "});
        return ops;
      } else {
        const countstring = ` (✕${map.get(key)})`;
        const newOps = removeTerminalNewlinesFromQuillDelta(JSON.parse(key)).ops
        newOps.push({insert: countstring,
                     attributes: {color: '#CCC'}});
        newOps.push({insert: "\n"});
        newOps.unshift({insert: "• "});
        return newOps;
      }
    }
    const tally = array => {
      const tallyMap = array.reduce(
        (t, v) => ((t.set(v, (t.get(v) || 0) + 1), t)), new Map()
      );
      return [...tallyMap.keys()].map(
        (key) => prettify(tallyMap, key)
      );
    }
    const deltas = filteredClusters.map(
      (cluster) => cluster.map(message => message.quillDelta)
    );
    const summarizedClusters = deltas.map(tally);
    const summary = {"ops": summarizedClusters
      .map((cluster, i) => {
      const opslist = cluster.flat();
      if (i < summarizedClusters.length - 1) {
        opslist.push({insert: {hr: true}}); // horizontal rule
      }
      return opslist;
    }).flat()}
    const editor = mainQuillRef.current.editor;
    summary.ops.unshift({insert: "Class Response Summary:\n\n", attributes: {bold: true}});
    if (this.props.messageClassRef) this.props.messageClassRef.current.clear();
    editor.setContents(summary);
  }

  clearBlock() {
    if (this.props.clearClusterBlock) {
      this.props.clearClusterBlock(this.props.startTime);
    }
  }

  getCurrentClusterIndices() {
    const { clusteredMessages=[], numberOfClusters=5 } = this.state;
    const numMessagesToRelease = 12;
    const indices = {};
    for (let i = 0; i < clusteredMessages.length; i++) {
      for (let j = 0; j < clusteredMessages[i].length; j++) {
        if (i >= numberOfClusters && j < numMessagesToRelease) continue;
        const messageId = clusteredMessages[i][j].id || null;
        if (!messageId) continue;
        indices[messageId] = i;
      }
    }
    return indices;
  }

  getClusterIndicesForAllMessages(messages) {
    const indices = [];
    const currentClusterIndices = this.getCurrentClusterIndices();
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id in currentClusterIndices) {
        indices[i] = currentClusterIndices[messages[i].id];
      } else {
        indices[i] = -1;
      }
    }
    return indices;
  }

  async addMessagesToExistingClusters({cloud=false}={}) {
    const { suggestions } = this.props;
    let { messages, seedMessages=[], numberOfClusters=5, clusterResponses } = this.state;
    messages = messages.filter(m => !this.removable(m)); // remove *cleared* messages
    const clusterIndices = this.getClusterIndicesForAllMessages(messages);
    const isMultipleChoice = suggestions && suggestions.length < numberOfClusters;
    let clusteredMessages = [];
    if (isMultipleChoice) {
      for (let i = 0; i < suggestions.length; i++) {
        for (let j = 0; j < messages.length; j++) {
          if (messages[j].textContent === suggestions[i].value) {
            clusterIndices[j] = i;
          }
        }
      }
      const filteredMessages = [];
      const filteredClusterIndices = [];
      for (let i = 0; i < suggestions.length; i++) {
        clusteredMessages.push([]);
      }
      for (let i = 0; i < messages.length; i++) {
        const idx = clusterIndices[i];
        if (idx === -1 || idx >= suggestions.length) {
          filteredMessages.push(messages[i]);
          const filteredIndex = Math.max(-1, idx - suggestions.length);
          filteredClusterIndices.push(filteredIndex);
        } else {
          clusteredMessages[idx].push(messages[i]);
        }
      }
      const newClusteredMessages = await cluster(
        filteredMessages,
        seedMessages,
        'textContent',
        numberOfClusters - suggestions.length,
        filteredClusterIndices,
        false,
        cloud,
      ) || [];
      clusteredMessages = clusteredMessages.concat(newClusteredMessages);
      const L = numberOfClusters + 1 - clusteredMessages.length;
      for (let i = 0; i < L ; i++) {
        clusteredMessages.push([]);
      }
    } else {
      try {
        clusteredMessages = await cluster(
          messages,
          seedMessages,
          'textContent',
          numberOfClusters,
          clusterIndices,
          false,
          cloud,
        ) || [];
        const L = numberOfClusters + 1 - clusteredMessages.length;
        for (let i = 0; i < L ; i++) {
          clusteredMessages.push([]);
        }
      } catch(err) {
        console.error(err);
        //numberOfClusters = messages.length;
        clusteredMessages = messages.map(m => [m]);
        for (let i = 0; i < numberOfClusters + 1 - messages.length; i++) {
          clusteredMessages.push([]);
        }
      }
    }
    clusteredMessages = this.sortMessages(clusteredMessages, !isMultipleChoice);
    clusterMessages(); // (analytics)
    this.setState({
      clusteredMessages,
      clusterResponses: clusterResponses.slice(0, numberOfClusters),
      hasBeenStarred: false,
    });
  }

  sortMessages(clusteredMessages, groupByAuthor = true) {
    const { surfaceFlaggedMessages, autoSortMessages } = this.props;
    if (!clusteredMessages.length) return;
    const sortString = (message) => {
      let result = "";
      if (surfaceFlaggedMessages && message.flagged) {
        result += "zz"; // flagged messages get top priority if we're surfacing flagged messages
      }
      if (autoSortMessages && message.textContent.includes('?')) {
        result += "z"; // questions get secondary priority if we're autosorting
      }
      if (autoSortMessages) {
        result += timeString(message);
      }
      return result;
    }
    for (let i = 0; i < clusteredMessages.length - 1; i++) {
      if (!clusteredMessages[i]) continue;
      clusteredMessages[i].sort( (m1, m2) => {
        if (sortString(m1) < sortString(m2)) {
          return 1;
        } else if (sortString(m1) === sortString(m2)) {
          return 0;
        } else {
          return -1;
        }
      });
    }
    clusteredMessages[clusteredMessages.length - 1].sort( (m1, m2) => timeString(m1) < timeString(m2) ? -1 : 1);
    if (groupByAuthor) return this.combineByAuthors(clusteredMessages);
    return clusteredMessages;
  }

  combineByAuthors(clusteredMessages) {
    if (clusteredMessages.length === 0) return clusteredMessages;
    const authors = new Map();
    const originals = new Set();
    // separate inbox:
    for (let messages of clusteredMessages.slice(0, -1)) {
      for (let message of messages) {
        if (authors.has(message.author)) {
          authors.get(message.author).push(message);
        } else {
          authors.set(message.author, [message]);
          originals.add(message.id);
        }
      }
    }
    if (authors.size < 2) return clusteredMessages;
    const clustersOriginals = clusteredMessages.slice(0, -1).map(
        messages => messages.filter(m => originals.has(m.id))
    );
    let newClusters = [];
    for (let cluster of clustersOriginals) {
      newClusters.push([]);
      for (let message of cluster) {
        const allMessagesFromThisAuthor = (
          authors
            .get(message.author)
            .sort( (m1, m2) => timeString(m1) < timeString(m2) ? -1 : 1 )
        );
        newClusters[newClusters.length - 1].push(...allMessagesFromThisAuthor);
      }
    }
    const [inboxColumn] = clusteredMessages.slice(-1);
    newClusters.push(inboxColumn);
    return newClusters;
  }

  fuseMessagesByAuthor(messages) {
    const authors = new Map();
    const timeString = (message) => {
      if (!message.timestamp) return "";
      return message.timestamp.seconds + "" + message.timestamp.nanoseconds;
    }
    for (let message of messages) {
        if (authors.has(message.author)) {
            authors.get(message.author).push(message);
        } else {
            authors.set(message.author, [message]);
        }
    }
    const newMessages = Array(...authors).map( (messageGroup) => {
        let messages = messageGroup[1];
        messages.sort( (m1, m2) => timeString(m1) < timeString(m2) ? -1 : 1 );
        if (messages.length === 1) return messages[0];
        const message = {...messages[0]};
        message.textContent = messages.map( m => m.textContent ).join("\n\n");
        message.trailingMessages = messages.slice(1);
        return message;
    });
    return newMessages;
  }

  getEmbedding() {
    const { messages } = this.state;
    const remainingMessages = messages.filter(m => !this.removable(m));
    this.props.NotificationManager.info('Getting embedding...', '', 10000);
    fetch('https://us-central1-prismia.cloudfunctions.net/embed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: remainingMessages.map(m => m.textContent),
      }),
    })
    .then(response => console.log(response.json()))
    .catch(console.error);
  }

  pythonClusterMessages() {
    const { messages, numberOfClusters } = this.state;
    const remainingMessages = messages.filter(m => !this.removable(m));
    if (remainingMessages.length < numberOfClusters) {
      this.clusterMessages();
      return;
    }
    this.props.NotificationManager.info('Clustering...', '', 10000);
    fetch('https://us-central1-prismia.cloudfunctions.net/cluster', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: remainingMessages.map(m => m.textContent),
        k: numberOfClusters,
      }),
    })
    .then(response => response.json())
    .then(clusterIndices => {
      let clusteredMessages = Array.from(Array(numberOfClusters + 1), () => []);
      for (let i = 0; i < clusterIndices.length; i++) {
        const clusterIndex = clusterIndices[i];
        clusteredMessages[clusterIndex].push(remainingMessages[i]);
      }
      const clusteredMessageIds = new Set();
      remainingMessages.forEach(m => clusteredMessageIds.add(m.id));
      clusteredMessages = this.combineByAuthors(clusteredMessages);
      clusteredMessages = clusteredMessages.sort((a, b) => a.length < b.length ? 1 : -1);
      // it can take a long time to run, so we need to leave 
      // as unclustered any messages which did not go out in 
      // the original request
      const unclusteredMessages = this.state.messages.filter(
        message => !this.removable(message) && !clusteredMessageIds.has(message.id)
      );
      clusteredMessages[clusteredMessages.length - 1] = unclusteredMessages;
      clusterMessages(); // analytics
      this.setState({ clusteredMessages, clusteredMessageIds,
                      clusterResponses: [], hasBeenStarred: false});
      // clearing the notifications:
      setTimeout( () => {
        this.props.NotificationManager.listNotify.forEach(
          notification => this.props.NotificationManager.remove({id: notification.id})
        );
      }, 500);
    });
  }

  async clusterMessages({preserveInbox=false, fast=false, cloud=false}={}) {
    const { suggestions, openResponse } = this.props;
    let { messages, seedMessages=[], numberOfClusters=5 } = this.state;
    messages = messages.filter(message => !this.removable(message));
    let inbox;
    if (preserveInbox && this.state.clusteredMessages?.length) {
      inbox = this.state.clusteredMessages.slice(-1)[0];
      const inboxIds = new Set(inbox.map(message => message.id));
      messages = messages.filter(message => !inboxIds.has(message.id));
    }
    let clusteredMessages = [];
    if (suggestions && suggestions.length < numberOfClusters) {
      const unclusteredMessageIds = new Set(messages.map(m => m.id));
      for (let i = 0; i < suggestions.length; i++) {
        const suggestion = suggestions[i];
        clusteredMessages[i] = [];
        for (let message of messages) {
          if (message.textContent === suggestion.value) {
            clusteredMessages[i].push(message);
            unclusteredMessageIds.delete(message.id);
          }
        }
      }
      const otherMessages = messages.filter(m => unclusteredMessageIds.has(m.id));
      const clusterIndices = otherMessages.map(m => -1);
      const otherClusteredMessages = await cluster(otherMessages, seedMessages, 'textContent', numberOfClusters - suggestions.length, clusterIndices, fast, cloud);
      if (otherClusteredMessages) {
        clusteredMessages = clusteredMessages.concat(
          otherClusteredMessages.sort(
            (a, b) => a.length < b.length ? 1 : -1
          )
        );
      }
      const L = clusteredMessages.length;
      for (let i = 0; i < numberOfClusters + 1 - L; i++) {
        clusteredMessages.push([]);
      }
      clusteredMessages = this.sortMessages(clusteredMessages, false);
      // (false means don't group by author)
    }
    else {
      try {
        const clusterIndices = messages.map(m => -1);
        clusteredMessages = await cluster(messages, seedMessages, 'textContent', numberOfClusters, clusterIndices, fast, cloud) || [];
        clusteredMessages = this.combineByAuthors(clusteredMessages);
        const preserve = (suggestions?.length || 0) + (openResponse?.length || 0);
        clusteredMessages = clusteredMessages.slice(0, preserve).concat(clusteredMessages.slice(preserve).sort((a, b) => a.length < b.length ? 1 : -1));
        const L = clusteredMessages.length;
        for (let i = 0; i < numberOfClusters + 1 - L; i++) {
          clusteredMessages.push([]);
        }
      } catch(err) {
        console.error(err);
        clusteredMessages = messages.map(m => [m]);
        for (let i = 0;
             i < numberOfClusters + 1 - messages.length;
             i++) {
          clusteredMessages.push([]);
        }
      }
      clusteredMessages = this.sortMessages(clusteredMessages);
    }
    const clusteredMessageIds = new Set();
    messages.forEach(m => clusteredMessageIds.add(m.id));
    if (preserveInbox) {
      inbox.forEach(m => clusteredMessageIds.add(m.id));
      clusteredMessages[clusteredMessages.length - 1] = inbox;
    }
    clusterMessages(); // analytics
    this.setState({ clusteredMessages, clusteredMessageIds,
                    clusterResponses: [], hasBeenStarred: false});
  }

  tooltipTitle(message) {
    const { instructorMap=new Map(), taMap=new Map() } = this.state;
    let title = message.authorDisplayName;
    if (message.callOnMe) {
      title = '✓ ' + title;
    } else if (message.callOnMe === false) {
      title = '× ' + title;
    }
    if (!title) return '';
    let responder = null;
    if (typeof message.responseInProgress === 'string') {
      if (instructorMap.has(message.responseInProgress)) {
          responder = instructorMap.get(message.responseInProgress);
      } else if (taMap.has(message.responseInProgress)) {
          responder = taMap.get(message.responseInProgress);
      }
    }
    return { title, responder };
  }

  setPrivateChat(message) {
    const { currentUser } = this.props;
    const id = currentUser.id + '-private-chat';
    this.setActiveStudentChatId(message.author);
    this.setMessagesResponseInProgress([message], id).then( () => { // ugly:
      message.responseInProgress = id;
      this.setStudentChatMessage(message);
    });
  }

  removeMessageByIdFromCluster(messageId) {
    const { clusteredMessages=[], clusteredMessageIds } = this.state;
    for (let i = 0; i < clusteredMessages.length; i++) {
      if (!clusteredMessages[i]) continue;
      if (!clusteredMessages[i].length) continue;
      for (let j = 0; j < clusteredMessages[i].length; j++) {
        const message = clusteredMessages[i][j];
        if (message.id === messageId) {
          clusteredMessages[i].splice(j, 1);
          clusteredMessageIds.delete(messageId);
          break;
        }
      }
    }
    this.setState({ clusteredMessages, clusteredMessageIds });
  }

  sendMessageToUsers(message, userIds) {
    if (!message || !userIds) return;
    const { db, projectId } = this.props;
    const batch = db.batch();
    userIds.forEach( userId =>
      batch.set(
        db.collection('users')
          .doc(userId)
          .collection('chats')
          .doc(projectId)
          .collection('messages')
          .doc(message.id),
          message, {merge: true})
    );
    return batch.commit();
  }

  sendMessagesToUsers(messageMap, userIds) {
    const { db, projectId } = this.props;
    const batch = db.batch();
    userIds.forEach( userId => {
      const message = messageMap.get(userId);
      batch.set(
        db.collection('users')
          .doc(userId)
          .collection('chats')
          .doc(projectId)
          .collection('messages')
          .doc(message.id),
          message, {merge: true});
    });
    return batch.commit();
  }

  sendMessageToUser(message, userId) {
    const { db, projectId } = this.props;
    if (!userId) return null;
    if (!message) return null;
    db.collection('users')
      .doc(userId)
      .collection('chats')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set(message, {merge:true});
  }

  setMessagesResponseInProgress(messages, inProgress) {
    if (this.props.setMessagesResponseInProgress) {
      return this.props.setMessagesResponseInProgress(
        messages, inProgress
      );
    }
  }

  setMessagesAsPeerResponseSent(messageIds) {
    const { db, projectId } = this.props;
    const batch = db.batch()
    messageIds.forEach( messageId => {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('messages')
          .doc(messageId),
        {peerResponseSent: true}, {merge: true}
      );
    });
    return batch.commit();
  }

  setMessagesAsPeerSent(messageIds) {
    const { db, projectId } = this.props;
    const batch = db.batch()
    messageIds.forEach( messageId => {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('messages')
          .doc(messageId),
        {peerSent: true}, {merge: true}
      );
    });
    return batch.commit();
  }

  setMessagesAsAnswered(messages) {
    const { db, projectId } = this.props;
    const batch = db.batch()
    messages.forEach( message => {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('messages')
          .doc(message.id),
        {answered: true}, {merge: true}
      );
    });
    return batch.commit();
  }

  setMessageAsAnswered(message) {
    const { db, projectId } = this.props;
    return db.collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set({ answered: true }, {merge: true});
  }

  clearMessagesLocally(messages) {
    const { locallyClearedMessages } = this.state;
    for (let message of messages) {
      locallyClearedMessages.add(message.id);
    }
    this.setState({ locallyClearedMessages });
    this.subMessages(this.props);
  }

  clearMessageLocally(message) {
    this.clearMessagesLocally([message]);
  }

  starMessages(messages) {
    const { db, projectId } = this.props;
    const batch = db.batch();
    messages.forEach( message => {
      const userId = message.author;
      if ( !message.starred ) {
        batch.set(
          db.collection('users')
            .doc(userId)
            .collection('chats')
            .doc(projectId)
            .collection('messages')
            .doc(message.id),
            { starred: true }, { merge: true }
        );
        batch.set(
          db.collection('projects')
            .doc(projectId)
            .collection('messages')
            .doc(message.id),
            { starred: true }, { merge: true }
        );
      }
    });
    batch.commit()
  }


  starMessage(message) {
    const { db, projectId } = this.props;
    // this part is not really necessary;
    // it just makes the UI slightly faster
    // const { messages } = this.state;
    // const currentMessageIndex = messages.findIndex( m => m.id ===  message.id );
    // messages[currentMessageIndex].starred = true;
    // this.setState({ messages });
    // ---------------------------------
    if (message.starred) return;
    const userId = message.author;
    db.collection('users')
      .doc(userId)
      .collection('chats')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set({ starred: true }, { merge: true });
    db.collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set({ starred: true }, { merge: true });
  }

  flagMessage(message, flagged=true) {
    const { db, projectId, flaggedMessagesOnly } = this.props;
    if (message.flagged === flagged) return;
    if (flaggedMessagesOnly && !flagged) {
      // we have to remove these manually since they get
      // omitted from the query and thus don't initiate
      // deletion through the snap handler
      const { messages, clusteredMessages } = this.state;
      const currentMessageIndex = messages.findIndex( m => m.id ===  message.id );
      if (currentMessageIndex > -1) messages.splice(currentMessageIndex, 1);
      for (let i = 0; i < clusteredMessages.length; i++) {
        const idx = clusteredMessages[i].findIndex( m => m.id ===  message.id );
        if (idx > -1) {
          clusteredMessages[i].splice(idx, 1);
          break;
        }
      }
      this.setState({ messages, clusteredMessages });
    }
    db.collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set({ flagged }, { merge: true });
  }

  markAnswered(clusterIndex) {
    // to the clusters
    const { clusteredMessages,
            clusteredMessageIds,
            clusterResponses=[] } = this.state;
    const messages = clusteredMessages[clusterIndex];
    this.setMessagesAsAnswered(messages);
    messages.forEach( m => clusteredMessageIds.delete(m) );
    clusteredMessages.splice(clusterIndex, 1, []);
    clusterResponses.splice(clusterIndex, 1, []);
    this.setState({
      clusteredMessages,
      clusteredMessageIds,
      clusterResponses
    });
  }

  clearClusterLocally(clusterIndex) {
    const { clusteredMessages } = this.state;
    const messages = clusteredMessages[clusterIndex];
    this.clearMessagesLocally(messages);
  }

  markResponseInProgress(clusterIndex, inProgress) {
    const { clusteredMessages } = this.state;
    const { currentUser } = this.props;
    const messages = clusteredMessages[clusterIndex];
    const messagesToUpdate = [];
    messages.forEach(message => {
      const responderWasMe = message.responseInProgress === currentUser.id;
      if (inProgress && !message.respondedTo &&
            (!message.responseInProgress || responderWasMe)) {
        message.responseInProgress = inProgress;
        messagesToUpdate.push(message);
      } else if (!inProgress && responderWasMe) {
        message.responseInProgress = inProgress;
        messagesToUpdate.push(message);
      }
    });
    this.setMessagesResponseInProgress(messagesToUpdate, inProgress);
    clusteredMessages[clusterIndex] = messages;
    this.setState({ clusteredMessages });
  }

  unbundle(baseMessages) {
    let messages = [];
    for (let baseMessage of baseMessages) {
      messages.push(baseMessage);
      if (baseMessage.trailingMessages) {
        for (let trailingMessage of baseMessage.trailingMessages) {
          messages.push(trailingMessage);
        }
      }
    }
    return messages;
  }

  sendHocketAsMessage(hocket, clusterIndex, {reSending=false}={}) {
    // to a cluster!
    const { currentUser={}, db, projectId, title } = this.props;
    const { clusteredMessages, clusterResponses=[] } = this.state;
    let messages = clusteredMessages[clusterIndex];
    let message = reSending ? hocket :
                  hocket2Message(hocket, true);
    //message.author = currentUser.id;
    if (message) {
      message.answered = true;
      message.authorDisplayName = currentUser.displayName || '';
      message.authorPhotoUrl = currentUser.photoUrl || '';
    }
    const authors = new Set();
    const batch = db.batch();
    for (let i = 0; i < messages.length; i++) {
      if (!messages[i].respondedTo || !reSending) {
        authors.add(messages[i].author);
        batch.set(
          db.collection('projects')
            .doc(projectId)
            .collection('messages')
            .doc(messages[i].id),
          {respondedTo: currentUser.id || true},
          {merge: true},
        );
      }
    }
    batch.commit();
    this.sendMessageToUsers(message, authors);
    if (!message) return;
    if (!reSending) {
      if (!clusterResponses[clusterIndex]) {
        clusterResponses[clusterIndex] = [message];
      } else {
        clusterResponses[clusterIndex].push(message);
        messageCluster(message.id);
        db.collection('projects')
          .doc(projectId)
          .collection('messages')
          .doc(message.id)
          .set(message)
          .catch(console.error);
      }
    }
    if (this.props.messageId) {
      db.collection('projects')
        .doc(projectId)
        .collection('instructor-responses')
        .doc(this.props.messageId)
        .set({
          id: this.props.messageId,
          title,
          private: false,
          cluster: true,
          intent: JSON.stringify(messages),
          response: JSON.stringify(message),
          authorDisplayName: message.authorDisplayName,
          authorId: currentUser.id,
          timestamp: now(),
        })
        .catch(console.error);
    }
    this.setState({ clusteredMessages, clusterResponses });
  }

  onDragEnd(res) {
    const { destination } = res;
    if (!destination) return;
    if (!res.destination) return;
    const { clusteredMessages=[] } = this.state;
    const sourceClusterIndex = parseInt(res.source.droppableId.substr(8));
    const sourceMessageIndex = res.source.index;
    const destinationClusterIndex = parseInt(res.destination.droppableId.substr(8));
    const destinationMessageIndex = res.destination.index;
    const movedMessage = clusteredMessages[sourceClusterIndex].splice(sourceMessageIndex, 1)[0];
    clusteredMessages[destinationClusterIndex].splice(destinationMessageIndex, 0, movedMessage);
    setTimeout( () => manuallySortClusteredMessage(), 100);
    this.setState({ clusteredMessages });
  }

  starCluster(clusterIndex) {
    let { hasBeenStarred, numberOfClusters, clusteredMessages } = this.state;
    if (!hasBeenStarred) {
      hasBeenStarred = [...Array(numberOfClusters).keys()].map(x=>false);
    }
    hasBeenStarred[clusterIndex] = true;
    this.setState({ hasBeenStarred });

    const messages = clusteredMessages[clusterIndex];
    this.starMessages(messages);
  }

  openLiveStudentTyping() {
    if (this.props.openLiveStudentTyping) {
      this.props.openLiveStudentTyping();
    }
  }

  renderGraph() {
    const { numberOfClusters, numberTyping } = this.state;
    const { clusteredMessages=[] } = this.state;
    const { stopTime } = this.props;
    const isLatestBlock = !stopTime;
    return (
      <StackedBarGraph
        numberOfClusters={ numberOfClusters }
        numberTyping={ numberTyping }
        clusteredMessages={ clusteredMessages }
        isLatestBlock={ isLatestBlock }
        hueValues={ hueValues }
        openLiveStudentTyping={this.openLiveStudentTyping}
        />
    );
  }

  shareMessage(message) {
    if (this.props.shareCb) {
      this.props.shareCb(message);
    }
  }

  renderSeedCard(message, columnIndex, messageIndex) {
    return (
      <ClusteredMessageCard
        message={ message }
        key={ 'seed-message-card-' + message.id }
        columnIndex={ columnIndex }
        messageIndex={ messageIndex }
        seedMessage={ true }
        />
    );
  }

  renderClusteredMessageCard(message, columnIndex, messageIndex) {
    const { numberOfClusters } = this.state;
    const { currentUser, fadeMessagesHandledByOtherInstructors, drawerMode, clearLocally } = this.props;
    const hue = hueValues[columnIndex];
    return (
      <ClusteredMessageCard
        message={ message }
        key={ 'clustered-message-card-' + message.id }
        columnIndex={ columnIndex }
        messageIndex={ messageIndex }
        numberOfClusters ={ numberOfClusters }
        currentUser={ currentUser }
        fadeMessagesHandledByOtherInstructors={ fadeMessagesHandledByOtherInstructors }
        hue={ hue }
        tooltipTitle={ this.tooltipTitle(message) }
        setPrivateChat={ this.setPrivateChat }
        flagMessage={ this.flagMessage }
        starMessage={ this.starMessage }
        shareMessage={ this.shareMessage }
        clearMessage={ this.setMessageAsAnswered }
        drawerMode={ drawerMode }
        clearLocally={ clearLocally }
        clearMessageLocally={ this.clearMessageLocally }
        />
    );
  }

  numOccupiedClusters() {
    const { clusteredMessages, clusterResponses } = this.state;
    const messageLast = clusteredMessages.slice(0, -1).map(cluster => cluster.length > 0).lastIndexOf(true);
    const responseLast = clusterResponses.map(Boolean).lastIndexOf(true);
    return Math.max(messageLast, responseLast);
  }

  handleSliderChange(event, numberOfClusters) {
    let shouldReCluster = false;
    if (numberOfClusters - 1 < this.numOccupiedClusters()) shouldReCluster = true;
    this.setState({ numberOfClusters });
    if (this.props.updateDefaultClusterCount) {
      this.props.updateDefaultClusterCount(numberOfClusters);
    }
    if (shouldReCluster) {
      this.clusterMessages({preserveInbox: true, fast: true});
    } else {
      let { clusteredMessages } = this.state;
      const [lastCluster] = clusteredMessages.slice(-1);
      clusteredMessages = clusteredMessages.slice(0, -1);
      if (numberOfClusters > clusteredMessages.length) {
        for (let i = clusteredMessages.length; i < numberOfClusters; i++) {
          clusteredMessages.push([]);
        }
      } else {
        clusteredMessages = clusteredMessages.slice(0, numberOfClusters);
      }
      clusteredMessages = clusteredMessages.concat([lastCluster]);
      this.setState({ clusteredMessages });
    }
  }

  reviveMessage(message, i) {
    const { clusterMessageFormContents=[] } = this.state;
    if (clusterMessageFormContents[i] === message.quillDelta) {
      clusterMessageFormContents[i] += " "; // little hack to force update
    } else {
      clusterMessageFormContents[i] = message.quillDelta;
    } 
    this.setState({ clusterMessageFormContents });
  }

  render() {
    let { clusteredMessages=[], messages, mostRecentQuestion, numberOfClusters, clusterMessageFormContents, presetClusterResponses=[], clusterResponses=[], hasBeenStarred, hideInbox, seedMessages } = this.state;
    const { projectId, db, title='', currentUser, fadeMessagesHandledByOtherInstructors, hideCallOnMe, hideMessagesHandledByOtherInstructors, surfaceFlaggedMessages, autoSortMessages, storage, drawerMode, clearLocally, startTime, stopTime } = this.props;
    const messageClusters = [];
    const changeDetectionStrings = clusteredMessages.map(
      (messageList, idx) => messageList.map(stringifyMessage).join(' ') + String(fadeMessagesHandledByOtherInstructors) + String(clearLocally) +  String(hideMessagesHandledByOtherInstructors) + String(surfaceFlaggedMessages) + String(autoSortMessages) + String(hideInbox) + String(drawerMode) + String(presetClusterResponses) + String(clusterResponses[idx]) + String(hideCallOnMe) + String(clusterMessageFormContents)
    );
    for (let i = 0; i < clusteredMessages.length; i++) {
      const finalColumn = i >= numberOfClusters && i === clusteredMessages.length - 1;
      let responses = [];
      if (presetClusterResponses[i] && !finalColumn) {
        for (let j = 0; j < presetClusterResponses[i].length; j++) {
          const message = presetClusterResponses[i][j];
          responses.push(<MessageCard key={ message.id } message={ message } chatCb={console.log} replyToUnansweredMessages={ () => this.sendHocketAsMessage(message, i, {reSending: true}) } reviveMessage={ () => this.reviveMessage(message, i)}/>);
        }
      }
      if (clusterResponses[i] && !finalColumn) {
        for (let j = 0; j < clusterResponses[i].length; j++) {
          const message = clusterResponses[i][j];
          responses.push(<MessageCard key={ message.id } message={ message } chatCb={console.log} replyToUnansweredMessages={ () => this.sendHocketAsMessage(message, i, {reSending: true}) } reviveMessage={ () => this.reviveMessage(message, i)}/>);
        }
      }
      const addToClustersButton = (
        <Tooltip title="add to existing clusters [shift A]">
          <Button
            className="add-to-existing-clusters-button"
            fullWidth
            variant="contained"
            onClick={ () => this.addMessagesToExistingClusters() }
            >
            <CallSplitIcon/>
          </Button>
        </Tooltip>
      );
      const addToClustersCloudButton = (
        <Tooltip title="add to existing clusters using Cloud AI">
          <Button
            className="add-to-existing-clusters-button cloud-button"
            fullWidth
            variant="contained"
            onClick={ () => this.addMessagesToExistingClusters({cloud: true}) }
            >
            <CloudUploadIcon/>
          </Button>
        </Tooltip>
      );
      messageClusters.push(
        (
          <div key={ 'outer-' + i } className="padded flex-child">
            <div className="add-to-clusters-container">
              { finalColumn ? addToClustersButton : null }
              { finalColumn ? addToClustersCloudButton : null }
            </div>
            { (finalColumn && hideInbox) ?
              <Tooltip
                title="Click to show inbox messages"
                enterDelay={ 300 }>
                <Button
                  className="inbox-card"
                  variant="contained"
                  fullWidth
                  onClick={() => this.setState({ hideInbox: false })}>
                    { "Inbox: " + (clusteredMessages[i] ? clusteredMessages[i].length : 0) }
                </Button>
              </Tooltip> :
              <Droppable
                droppableId={'cluster-' + i}
                key={ 'droppable-' + i }>
              { (provided, snapshot) => (
                <div
                  className="pad-bottom"
                  key={i}
                  style={getListStyle(snapshot.isDraggingOver)}
                  ref={provided.innerRef}
                  {...provided.innerProps}
                    >
                {
                  (clusteredMessages[i].length || seedMessages?.[i]?.length) ?
                  <ClusteredMessageCardList
                    key={ 'clustered-message-card-list-' + i }
                    messages={ clusteredMessages[i] }
                    changeDetectionString={ changeDetectionStrings[i] }
                    renderFunction={ (message, messageIndex) => this.renderClusteredMessageCard(message, i, messageIndex) }
                  /> : (i < numberOfClusters ?
                  <div className="brick-container hidden">
                  </div>
                  : null )
                }
                { provided.placeholder }
                </div>
              )}
              </Droppable>
            }
            { !seedMessages?.[i] ? null :
                <SeedMessageCard
                  key={ 'clustered-message-card-seeds-list-' + i }
                  seedMessages={ seedMessages[i] }
                  seedRenderFunction={ (m, idx) => this.renderSeedCard(m, i, idx) }
                  renderFunction={ () => null }
                />
            }
            { responses.length ? responses : null }
            {(finalColumn && hideInbox) ? null :
              <ClusterMessageForm
              key={ 'cluster-message-form-' + i }
              contents={ clusterMessageFormContents?.[i] }
              hideSuggestedResponses
              clusterIndex={ i }
              markResponseInProgress={ this.markResponseInProgress }
              markAnswered={ this.markAnswered }
              clearLocally={ clearLocally }
              clearClusterLocally={ this.clearClusterLocally }
              sendHocketAsMessage={ this.sendHocketAsMessage }
              projectId={ projectId }
              db={ db }
              storage={ storage }
              currentUser={ currentUser }
              hasBeenStarred= { hasBeenStarred ?
                                hasBeenStarred[i] :
                                false }
              starCluster = { this.starCluster } />
            }
          </div>
        )
      );
    }
    const students = new Set(messages.map(m => m.author));
    students.delete('000-0000-000');
    const studentResponseCount = students.size;
    let studentWord = " participants";
    if (studentResponseCount === 1) {
      studentWord = " participant";
    }
    let scoredWord = ""
    if ( this.state.scored || (this.props.multipleChoice === 'scored' )) {
      scoredWord = " [scored]"
    }
    let timeString = moment(startTime).format('MMMM Do YYYY, HH:mm:ss');
    if (stopTime) timeString += " to " + moment(stopTime).format('MMMM Do YYYY, HH:mm:ss');
    return (
      <div className="padded message-clusters white">
        <div className="padded">
      { mostRecentQuestion ? <p className="subtext">most recent question:</p> : null }
        <Tooltip
          title={this.props.multipleChoice === 'scored' ? "Scored as multiple choice" : (this.state.scored ? "Scored as open response" : (this.props.multipleChoice === 'unscored' ? "Unscored multiple choice" : "Click to score block as open response question"))}
          placement="top"
          enterDelay={ 300 }>
            <p
            className="student-response-count"
            onClick={() => this.props.multipleChoice === 'scored' ? null : this.score()}>
            { studentResponseCount + studentWord } responded { scoredWord }
            </p>
        </Tooltip>
        <Tooltip
          title={ timeString }
          enterDelay={ 250 }>
          <h3 style={{visibility: title ? "visible" : "hidden"}}>
          { title || "title" }
          </h3>
        </Tooltip>
        <div className="flex-container align-center">
          <div className="flex-child flex-auto left-button-container cluster-block-button">
            <Tooltip
              title="Regroup all messages in this block [shift R]"
              placement="top"
              enterDelay={ 1200 }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ () => this.clusterMessages() }
                >
                Re-Cluster
              </Button>
            </Tooltip>
          </div>
          <div className="flex-auto cloud-button-container cluster-block-button">
            <Tooltip
              title="Regroup using Cloud AI (can take longer)"
              placement="top"
              enterDelay={ 1200 }>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={ () => this.clusterMessages({ cloud: true }) }
                >
                <CloudUploadIcon/>
              </Button>
            </Tooltip>
          </div>
          { this.renderGraph() }
          <div className="flex-child right-button-container flex-container">
            <div className="cluster-block-button flex-child">
            <Tooltip
              title="Put a summary of this block into your text box [shift S]"
              placement="top"
              enterDelay={ 1200 }>
              <Button
                className="float-right"
                variant="contained"
                size="small"
                color="primary"
                style={{marginLeft: "3px"}}
                onClick={ () => this.summarize() }
                >
               Summarize
               </Button>
            </Tooltip>
            </div>
            <div className="cluster-block-button flex-child"
                style={{marginLeft: "2px"}}>
              <Tooltip
                title="Delete this cluster block (messages will go to the next one below)"
                placement="top"
                enterDelay={ 1200 }>
                <Button
                  className="float-right"
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{marginLeft: "3px"}}
                  onClick={ () => this.clearBlock() }
                  >
                 Clear
                 </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <ThemeProvider theme={ theme }>
          <Slider
            value={ numberOfClusters }
            color="primary"
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            onChange={ this.handleSliderChange }
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={9}
          />
        </ThemeProvider>
        </div>
        <div className="flex-container">
          <ClusterBlockDragDropContext
            onDragEnd = { (res) => this.onDragEnd(res) }
            messageClusters = { messageClusters }
            changeDetectionString = { changeDetectionStrings.join('//') }/>
        </div>
      </div>
    );
  }
}

export default ClusterBlock;
