import uuid from 'uuid/v4';
import { now } from './utils';

export function Thread(title='', creator={}, messages=[], privateThread=false) {
  return {
    id: uuid(),
    creatorId: creator.id,
    creatorDisplayName: creator.displayName,
    creatorPhotoUrl: creator.photoUrl,
    messages: messages,
    timestamp: now(),
    title: title,
    privateThread: privateThread,
    archived: false,
    resolved: false,
  }
}
