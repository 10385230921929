import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import SimpleAdminNav from '../SimpleAdminNav';
import Button from '@material-ui/core/Button';
import ReadOnlyQuill from '../ReadOnlyQuill';
import CommentsArea from '../comments-area/CommentsArea';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MessageCard from '../messages/MessageCard';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import { updateTitleBar } from '../utils';
import "./style.css";

class TabPanel extends Component {
  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          children
        )}
      </div>
    );
  }
}

class SharedResponseArea extends Component {

  render() {
    const { db, currentUser, selectedSharedLesson, sharedLessonResponses, 
            hockets, language, width, comments, activeCommentHocket, setActiveCommentHocket, commentRef } = this.props;
    const messageCards = []; 
    for (let studentId in sharedLessonResponses) {
      const studentResponses = sharedLessonResponses[studentId];
      let firstMessage = true;
      for (let message of studentResponses) {
        const hocket = hockets[message.parent];
        if (!hocket) continue;
        if (firstMessage) {
          messageCards.push(
            <h3 key={ message.user + "-header" } className="centered">
              { message.user }
            </h3>
          );
          firstMessage = false;
        }
        const questionContent = hocket.responses[0];
        messageCards.push(
          <MessageCard
            key={ studentId + message.parent + "-question"}
            quillDelta={ JSON.parse(questionContent) }
            classes={ "report-question" }
            language={ language }
            codeCell={ hocket.codeCell }
            jessieCode={ hocket.jessieCode }
            aspectRatio={ hocket.aspectRatio }
            suggestions={ hocket.suggestions }
          />
        );
        let theseComments = comments[studentId + message.parent + '-reply'];
        if (theseComments) theseComments = Object.values(theseComments);
        const commentsArea = <CommentsArea
          db={ db }
          width={ width }
          currentUser={ currentUser }
          comments={ theseComments }
          parentId={ message.parent + '-reply' }
          commentRef={ commentRef.doc(studentId).collection('comments') }
          activeCommentHocket={ activeCommentHocket }
          setActiveCommentHocket={ (id) => setActiveCommentHocket(id) }
        />;
        messageCards.push(
          <MessageCard
            key={ studentId + message.parent }
            quillDelta={ JSON.parse(message.quillDelta) }
            authorDisplayName={ message.user }
            photoUrl={ message.photoUrl }
            classes="student-answer"
            commentsArea={ commentsArea }
          />
        );
      }
    }
    if (messageCards.length === 0 && selectedSharedLesson) {
      messageCards.push(<div key="none-to-show" className="centered">
        No student responses to show...
      </div>);
    }
    return <div className="hockets-area"> { messageCards } </div>;
  }
}

class ClassReportView extends Component {

  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.unsub = {};
    this.openMenu = this.openMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      dates: [],
      date: null,
      reportData: null,
      tabValue: 0,
      sharedLessons: [],
      selectedSharedLesson: null,
      sharedLessonResponses: {},
      lesson: {},
      hockets: {},
      menuOpenRef: null,
      activeCommentHocket: null,
      showComments: true,
      comments: {},
      commentRefUpdated: false,
    };
  }

  componentDidMount() {
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
    this.setRefs(this.props);
  }

  setRefs(props) {
    this.subMetrics(props);
    this.getLessons(props);
  }

  subComments(lessonId, userId) {
    const key = 'comment-' + lessonId + userId;
    if (this.unsub[key]) this.unsub[key]();
    const { currentUser } = this.props;
    if (!currentUser || !lessonId) return;
    this.unsub[key] = this.commentRef
      .doc(userId)
      .collection('comments')
      .where('archived', '==', false)
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const docs = snap.docs.map(doc => doc.data());
        const { comments } = this.state;
        for (let doc of docs) {
          if (!doc.parentId.endsWith('-reply')) continue;
          if (comments[userId + doc.parentId]) {
            comments[userId + doc.parentId][doc.id] = doc;
          } else {
            comments[userId + doc.parentId] = {[doc.id]: doc};
          }
        }
        this.setState({ comments });
      });
  }

  getLessons(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .collection('lesson-order')
      .doc('ids')
      .get()
      .then((snap) => {
        const data = snap.data();
        this.setState({ lessonOrder: data.lessonOrder });
      })
      .then( () => {
        this.unsub.lessonOrder = db.collection('projects')
          .doc(projectId)
          .collection('lessons')
          .where('shared', '==', true)
          .get()
          .then((snap) => {
            const lessonIdMap = new Map();
            snap.docs.forEach(doc => {
              const data = doc.data();
              lessonIdMap.set(data.sharedLessonId, {
                title: data.title,
                parentId: data.id,
                timestamp: data.timestamp,
              });
            });
            this.setState({ lessonIdMap }, () => {
              this.getSharedLessons(props);
            });
          })
      })
      .catch(console.error);
  }

  getSharedLessons(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .collection('shared-chats')
      .get()
      .then( (snap) => {
        const { lessonIdMap } = this.state;
        this.setState({ sharedLessons:
          snap.docs.map(doc => {
            const data = doc.data();
            return data.id;
          })
          .sort((l1, l2) => lessonIdMap.get(l1)?.timestamp < lessonIdMap.get(l2)?.timestamp ? -1 : 1)
          .map(sharedId => ({
            id: sharedId,
            title: lessonIdMap.get(sharedId)?.title || sharedId,
          }))
        });
      })
      .catch(console.error);
  }

  subHocket(hocketId) {
    const { db, projectId } = this.props;
    if (!hocketId) return;
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('shared-hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
      });
  }

  subSharedLesson(lessonId) {
    const { db, projectId } = this.props;
    this.commentRef = db
      .collection('projects')
      .doc(projectId)
      .collection('shared-chats')
      .doc(lessonId)
      .collection('users');
    this.setState({ commentRefUpdated: true });
    this.commentRef
      .get()
      .then( snap => {
        return snap.docs.map(doc => doc.data()?.id).filter(Boolean)
      })
      .then(ids => {
        const promises = [];
        ids.forEach(id => {
          this.subComments(lessonId, id);
          promises.push(this.commentRef
            .doc(id)
            .collection('messages')
            .get()
            .then( (snap) => {
              return snap.docs.map( doc => doc.data());
            })
            .catch(console.error));
        });
        Promise.all(promises).then( (results) => {
          const sharedLessonResponses = {};
          ids.forEach( (id, k) => {
            sharedLessonResponses[id] = results[k];
          })
          this.setState({ sharedLessonResponses });
        });
      })
      .catch(console.error);

      this.sharedLessonRef = db
        .collection('projects')
        .doc(projectId)
        .collection('shared-lessons')
        .doc(lessonId);
      this.unsub.lessonData = this.sharedLessonRef.onSnapshot(snap => {
        const sourceLesson = snap.data();
        if (!sourceLesson) {
          return;
        };
        const lesson = {...sourceLesson};
        lesson.hockets = sourceLesson.hockets.filter( hocket => !hocket.note );
        const hockets = lesson.hockets;
        for (let i = 0; i < hockets.length; i++) {
          if (this.unsub[hockets[i].hocketId]) continue;
          this.subHocket(hockets[i].hocketId);
        }
        this.setState({ lesson });
      });
  }

  subMetrics(props) {
    if (this.unsub.metrics) this.unsub.metrics();
    const { db, projectId } = props;
    const { date } = this.state;
    this.unsub.metrics = db
      .collection('projects')
      .doc(projectId)
      .collection('metrics-daily-activity')
      .orderBy('id', 'desc')
      .onSnapshot(snap => {
        const docs = snap.docs.map(doc => doc.data());
        this.setState({dates: docs.map(doc => doc.id).reverse() });
        if (date) {
          const selectedData = docs.filter(doc => doc.id === date);
          if (selectedData.length) {
            this.setState({ reportData: selectedData[0] });
          }
          this.getDeltas(date);
        }
      });
  }

  getDeltas(date) {
    const { db, projectId } = this.props;
    const deltaMap = new Map();
    return db.collection('projects')
      .doc(projectId)
      .collection('metrics-daily-activity')
      .doc(date)
      .collection('deltas')
      .get()
      .then(snap => {
        if (!snap.size) return;
        const docs = snap.docs.map(doc => doc.data());
        for (let doc of docs) {
          deltaMap.set(doc.hash, doc.delta);
        }
        this.setState({ deltaMap });
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
  }

  handleTabChange(event, newValue) {
    this.setState({ tabValue: newValue });
  }

  tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  setActiveCommentHocket(id) {
    this.setState({ activeCommentHocket: id })
  }

  renderClasstimeArea() {
    const { reportData, deltaMap } = this.state;
    let key = 0;
    const responseCards = [];
    if (reportData && reportData.counts) {
      const sortedStudents = [...Object.keys(reportData.counts)].sort( (id1, id2) => reportData.counts[id1].displayName < reportData.counts[id2].displayName ? -1 : 1)
      for (let studentId of sortedStudents) {
        const studentRecord = reportData.counts[studentId];
        if (studentRecord) {
          responseCards.push(<h3 className="centered" key={ key }>{ studentRecord.displayName }</h3>);
          key++;
          if (studentRecord.responses) {
            const clusterIds = [...Object.keys(studentRecord.responses)].sort();
            for (let cluster of clusterIds) {
              const response = studentRecord.responses[cluster];
              let question = null;
              if (response.questionDelta.startsWith("{")) {
                question = <ReadOnlyQuill quillDelta={ JSON.parse(response.questionDelta) } />
              } else if (deltaMap) {
                question = <ReadOnlyQuill quillDelta={ JSON.parse(deltaMap.get(response.questionDelta)) } />
              } else {
                question = response.questionText;
              }
              responseCards.push(<div key={ key } className="report-question">{ question }</div>);
              key++;
              for (let i = response.answers.length - 1; i >= 0; i--) {
                const answer = response.answers[i];
                if (response.answerDeltas) {
                  const answerDelta = response.answerDeltas[i];
                  responseCards.push(<div key={ key } className="student-answer"> <ReadOnlyQuill quillDelta={ JSON.parse(answerDelta) } /></div>);
                } else {
                  responseCards.push(<div key={ key } className="student-answer">{ answer }</div>);
                }
                key++;
              }
            }
          }
        }
      }
    }
    return responseCards;
  }

  openMenu(event) {
    this.setState({ menuOpenRef: event.currentTarget });
  }

  handleClose() {
    this.setState({ menuOpenRef: null });
  }

  render() {
    const { currentUser, projectId, db, router } = this.props;
    const { menuOpenRef, date, sharedLessons, lessonIdMap, selectedSharedLesson, dates, tabValue } = this.state;
    updateTitleBar('Class Report');
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({ width }) => {
        return <div className="report-view full-height">
          <SimpleAdminNav
            currentUser={ currentUser }
            projectId={ projectId }
            db={ db }
            router={ router } />
          <AppBar color="transparent" position="relative">
            <Tabs value={tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
              <Tab label="Classtime" {...this.tabProps(0)} />
              <Tab label="Shared lessons" {...this.tabProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel style={{position: "relative", height: "calc(100% - 108px)"}} value={tabValue} index={0}>
              <div className="y-scrollable">
                <div style={{
                  paddingLeft: "2em", 
                  paddingRight: "2em",
                  marginBottom: "8em",
                }}>
                <Button
                  onClick={ this.openMenu }
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "4em",
                    marginBottom: "4em",
                    display: "block",
                  }}>
                  { date ? date : "Choose a date" }
                </Button>
              <Menu
                open={ Boolean(menuOpenRef) }
                anchorEl={ menuOpenRef }
                onClose={ this.handleClose }>
                { dates.map(d => {
                  return (
                    <MenuItem
                      key={'menu-item-' + d}
                      onClick={ () => {
                        NotificationManager.info("Loading...")
                        this.handleClose();
                        this.setState({ deltaMap: null}, () =>
                          this.setState({date: d}, () =>
                            this.subMetrics(this.props)
                          )
                        )
                      } }>
                      { d }
                    </MenuItem>
                  );
                }) }
              </Menu>
              { this.renderClasstimeArea() }
              </div>
              </div>
          </TabPanel>
          <TabPanel style={{position: "relative", height: "calc(100% - 108px)"}} value={tabValue} index={1}>
            <div className="y-scrollable">
            <div style={{paddingLeft: "2em", paddingRight: "2em", marginBottom: "8em"}}>
              <Button
                onClick={(e) => this.openMenu(e) }
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "4em",
                  marginBottom: "4em",
                  display: "block",
                }}>
                { selectedSharedLesson ? lessonIdMap.get(selectedSharedLesson).title : "Choose a lesson" }
              </Button>
              <Menu
                open={ Boolean(menuOpenRef) }
                anchorEl={ menuOpenRef }
                onClose={ () => this.handleClose() }>
                { sharedLessons.map( lesson => {
                  return (<MenuItem
                            key={'menu-item-' + lesson.id }
                            onClick={ () => {
                              NotificationManager.info("Loading...")
                                this.handleClose();
                                this.setState({
                                  sharedLessonResponses: {},
                                  selectedSharedLesson: lesson.id,
                                  commentRefUpdated: false,
                                }, () => this.subSharedLesson(lesson.id));
                            } }>
                            { lesson.title }
                          </MenuItem>);
                }) }
              </Menu>
              <SharedResponseArea
                sharedLessonResponses={ this.state.sharedLessonResponses }
                selectedSharedLesson={ this.state.selectedSharedLesson }
                hockets={ this.state.hockets }
                language={ this.state.lesson?.codeCell || 'julia' }
                db={ this.props.db }
                width={ width }
                comments={ this.state.comments }
                activeCommentHocket={ this.state.activeCommentHocket }
                setActiveCommentHocket={ (id) => this.setActiveCommentHocket(id) }
                currentUser={ this.props.currentUser }
                commentRef={ this.commentRef }
                />
              </div>
              </div>
            </TabPanel>
          <NotificationContainer/>
      </div>;
    } }
    </ReactResizeDetector>);
  }
}

export default ClassReportView;
