import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { getDocumentButtonListStyle, getDocumentButtonStyle, DocumentButton } from '../mui-themes';
import { updateTitleBar, isAdmin, reorder } from '../utils';
import { Lesson } from '../lesson';
import { createAssignment } from '../analytics';
import './style.css';

const settingsThemeColor = '#FFF';
const theme = createMuiTheme({
  palette: {
    common: { black: settingsThemeColor, white: settingsThemeColor },
    primary: { main: settingsThemeColor, dark: settingsThemeColor, light: settingsThemeColor },
    text: { primary: settingsThemeColor, secondary: settingsThemeColor }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
        }
      }
    }
  }
});

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

class AssignmentsView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      assignments: null,
    };
    this.state = {
      instructors: {},
      tas: {},
      hideMode: false,
      gradeMode: true,
      showHelp: false,
      showSettings: false,
      newAssignmentBoxOpen: false,
      regradeMap: {},
    };
  }

  componentDidMount() {
    this.getRoles();
    this.subProject(this.props);
    this.subAssignmentOrder(this.props);
    this.subAssignments(this.props);
  }

  subProject(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const doc = snap.data();
        if (doc.courseLanguage) this.setState({ codeCell: doc.courseLanguage });
      });
  }

  getRoles() {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('instructors')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ instructors: data.userIds });
      }).catch(console.error);
    db.collection('projects')
      .doc(projectId)
      .collection('roles')
      .doc('tas')
      .get()
      .then(snap => {
        const data = snap.data() || {};
        if (!data.userIds) return null;
        this.setState({ tas: data.userIds });
      }).catch(console.error);
  }

  subAssignments(props, Cb) {
    if (this.unsub.assignments) this.unsub.assignments();
    const { assignmentOrder } = this.state;
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.assignments = db
      .collection('projects')
      .doc(projectId)
      .collection('assignments')
      .orderBy("timestamp", "desc")
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const assignments = snap.docs.map(doc => doc.data());
        if (assignmentOrder) {
          assignments.sort( (assignment1, assignment2) => {
            if (assignmentOrder.indexOf(assignment1.id) < assignmentOrder.indexOf(assignment2.id)) {
              return -1;
            } else {
              return 1;
            }
          });
        }
        this.setState({ assignments });
        if (Cb) Cb(assignments);
      });
  }

  subAssignmentOrder(props) {
    if (this.unsub.assignmentOrder) this.unsub.assignmentOrder();
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.assignmentOrder = db
      .collection('projects')
      .doc(projectId)
      .collection('assignment-order')
      .doc('ids')
      .onSnapshot(doc => {
        const ids = doc.data();
        if (!ids || !ids.assignmentOrder) {
          this.subAssignments(this.props, (assignments) =>
          this.setAssignmentOrder(assignments.map(l => l.id)));
        } else {
          this.setState({ assignmentOrder: ids.assignmentOrder }, () => this.subAssignments(this.props));
        }
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
  }

  nameAssignment(assignmentTitle='') {
    this.setState({ assignmentTitle });
  }

  describeAssignment(assignmentDescription) {
    this.setState({ assignmentDescription });
  }

  createAssignment() {
    const { db, projectId, currentUser } = this.props;
    const { assignmentDescription, assignmentTitle, assignmentOrder } = this.state;
    const creator = currentUser || {};
    const assignment = Lesson(assignmentTitle, assignmentDescription, '', creator);
    if (this.state.codeCell) assignment.codeCell = this.state.codeCell;
    createAssignment(assignment.id);
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignment.id)
      .set(assignment, {merge: true})
      .then(() => {
        this.setState({
          assignmentTitle: '',
          assignmentDescription: '',
        });
        this.setAssignmentOrder([assignment.id].concat(assignmentOrder));
      }).catch(console.error);
    this.setState( { newAssignmentBoxOpen: false });
  }

  hideAssignment(assignmentId, hide=true) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ hidden: hide }, {merge: true})
      .catch(console.error);
  }

  renderAssignmentCard(assignment, index) {
    const { projectId, router } = this.props;
    const { hideMode, gradeMode } = this.state;
    const link = '/projects/' + projectId + '/edit-assignment/' + assignment.id;
    let gradeLink = '/projects/' + projectId + '/grade-assignment/' + assignment.id;
    if (this.state.regrades) gradeLink += '/regrades';
    return (
      <Draggable
        key={assignment.id}
        draggableId={assignment.id}
        index={index}>
        {(provided, snapshot) => (
            <DocumentButton
              key={"list-item-" + assignment.id}
              onClick={ () => router.history.push(link) }
              button
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getDocumentButtonStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}>
              {( hideMode ?
                <Button
                  style={{color: "rgb(35, 120, 130)"}}
                  onClick={ (event) => {
                    event.stopPropagation();
                    this.hideAssignment(assignment.id, !assignment.hidden);
                  }}>
                  { assignment.hidden ? "Unhide" : "Hide" }
                </Button> : null)}
              {( gradeMode ?
                <Button
                  style={{color: "rgb(35, 120, 130)"}}
                  onClick={ (event) => {
                    event.stopPropagation();
                    router.history.push(gradeLink);
                  }}>
                  {"Grade" + (!this.state.regrades ? "" : (
                              this.state.regradeMap[assignment.id] ? 
                    " (" + Object.keys(this.state.regradeMap[assignment.id]).length + ")" : " (0)"))}
                </Button> : null)}
              <ListItemText
                primary={ assignment.title }
                secondary={ assignment.description }
                className= "assignment-card-text" />
            </DocumentButton>)
        }
      </Draggable>
    );
  }

  onDragEnd(result) {
    const { assignments, assignmentOrder, hideMode } = this.state;
    // dropped outside the list
    if (!result.destination || !assignmentOrder) {
      return;
    }
    const visibleAssignments = hideMode ? assignments : assignments.filter(l => !l.hidden);
    const indexMap = (i) => assignmentOrder.indexOf(visibleAssignments[i].id);
    const start = indexMap(result.source.index);
    const end = indexMap(result.destination.index);
    const newAssignments = reorder(assignments, start, end);
    this.setState({ assignments: newAssignments }, () =>
      this.setAssignmentOrder(newAssignments.map( l => l.id ))
    );
  }

  setAssignmentOrder(assignmentOrder) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('assignment-order')
      .doc('ids')
      .set({ assignmentOrder })
      .catch(console.error)
  }

  renderAssignmentCards() {
    const { hideMode } = this.state;
    const { assignments=[] } = this.state;
    const cards = assignments.filter(
        l => hideMode || !l.hidden
      ).map( (l, i) => this.renderAssignmentCard(l, i));
    return (
        <DragDropContext onDragEnd={ result => this.onDragEnd(result) }>
          <Droppable droppableId="droppable">
          { (provided, snapshot) => (
              <List
                ref={provided.innerRef}
                style={getDocumentButtonListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}>
                { cards }
                { provided.placeholder }
              </List>
            ) }
          </Droppable>
        </DragDropContext>
    );
  }

  renderNewAssignmentCreationArea() {
    const { assignmentTitle='', assignmentDescription='' } = this.state;
    return (
      <div className='assignment-creation-area'>
        <h2 className='centered'>Create new Assignment</h2>
        <p className="assignment-creation-note">
          Note: If you want to start a assignment with an introduction to Prismia for your students, feel free to copy <a href="https://prismia.chat/shared/prismia-introduction">this shared assignment</a> into your class.
        </p>
        <p className="subtext">Assignment Title:</p>
        <Input
          fullWidth
          className="assignments-creation-title"
          placeholder="assignment title"
          value={ assignmentTitle || '' }
          onChange={ (e) => this.nameAssignment(e.target.value) } />
        <p className="subtext">Description:</p>
        <Input
          fullWidth
          className="assignment-description"
          placeholder="description"
          value={ assignmentDescription || '' }
          onChange={ (e) => this.describeAssignment(e.target.value) } />
        <Button
          variant="contained"
          color="secondary"
          style={{marginLeft: "50%", transform: "translate(-50%, 0)"}}
          onClick={() => this.createAssignment() }
          >
          Create Assignment
        </Button>
      </div>
    );
  }

  renderAssignmentsArea() {
    const { newAssignmentBoxOpen } = this.state;
    const plusButton = <Tooltip title="Create a new assignment" enterDelay={ 200 } placement="top">
      <Button
        fullWidth
        variant="outlined"
        style={{marginTop: "20px", marginBottom: "0"}}
        onClick={ () => {
          this.setState({ newAssignmentBoxOpen: true });
        }}>
        +
      </Button>
    </Tooltip>;
    return (
      <div className="y-scrollable">
        <div className="assignment-buttons-area extra-padded">
          <h1 className="assignments-title centered padded-bottom">Assignments</h1>
          { newAssignmentBoxOpen ? this.renderNewAssignmentCreationArea() : plusButton }
          { this.renderAssignmentCards() }
        </div>
      </div>
    );
  }

  hide() {
    const { currentUser={} } = this.props;
    const { instructors={}, tas={} } = this.state;
    if (isAdmin(currentUser.id)) return false;
    if (instructors[currentUser.id]) return false;
    if (tas[currentUser.id]) return false;
    return true;
  }

  renderHelp() {
    return (<div className="help-info">
      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move selected message up one</li>
      <li><tt>down</tt> move selected message down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia lessons or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>

      <h1>Emoji features</h1>
      <ul className="documentation-list">
        <li>Sending a message including the text <span role="img" aria-label="silhouette">👥</span> sends students' messages to each other anonymously and allows each student to respond directly to the person whose answer they see. Use <tt>\peer</tt> followed by tab to get the silhouette character.</li>
        <li>Sending a message including the text <span role="img" aria-label="clock">🕔90s</span> sets a 90-second timer (visible to you and to students). Use <tt>\clock</tt> or <tt>\timer</tt> followed by tab to get the clock character.</li>
        <li>Sending a message including the text <span role="img" aria-label="pencil">✏</span> opens each student's drawing tool and sets the image contained in the message as the background image. Use <tt>\draw</tt> followed by tab to get the pencil character.</li>
        <li>Sending a message including the text <span role="img" aria-label="registered">®</span> marks the question as required, meaning that it will be tracked as an open response question on the <em>Metrics</em> page. Use <tt>\RR</tt> or <tt>\required</tt> followed by tab to get the registered symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="pushpin">📌</span> will pin the message to the top of each student's window. Use <tt>\pin</tt> followed by tab to get the pushpin character.</li>
        <li>Sending a message including the text <span role="img" aria-label="recycle">♻</span> will clear every student's pinned messages. Use <tt>\clear</tt> followed by tab to get the recycling symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="otimes">⊗</span> will remove one pinned message. The character can be included more than once to remove multiple pinned messages. Use <tt>\unpin</tt> followed by tab to get the <span role="img" aria-label="otimes">⊗</span> symbol.</li>
      </ul>
    </div>);
  }

  showRegrades() {
    const { db, projectId } = this.props;
    this.setState({ regrades: true });
    for (let assignment of this.state.assignments) {
      db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignment.id)
      .collection('regrade-requests')
      .where('resolved', '==', false)
      .get()
      .then(snap => {
        const { regradeMap={} } = this.state;
        for (let doc of snap.docs) {
          const data = doc.data();
          if (!regradeMap[assignment.id]) {
            regradeMap[assignment.id] = {}
          }
          regradeMap[assignment.id][data.userId] = true;
        }
        this.setState({ regradeMap });
      })
      .catch(console.error);
    }
  }

  renderSettings() {
    const { lesson={} } = this.state;
    if (!lesson) return null;
    return (
      <ThemeProvider theme={ theme }>
        <div className="help-info">
          <h1>Settings</h1>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={
                <Tooltip
                  title="Grade assignments"
                  enterDelay={ 400 }>
                  <WhiteCheckbox
                    className="code-cell-setting"
                    checked={this.state.gradeMode}
                    color="default"
                    onChange={ (e) => this.setState({ gradeMode: !this.state.gradeMode }) } inputProps={{ 'aria-label': 'lesson info' }}/>
                </Tooltip>
              }
              label="Grade assignments"/>
            <FormControlLabel
              control={ <Tooltip
              title="Show/hide assignments"
              enterDelay={ 400 }>
              <WhiteCheckbox
                className="code-cell-setting"
                checked={this.state.hideMode}
                color="default"
                onChange={(e) => this.setState({ hideMode: !this.state.hideMode })}
                inputProps={{ 'aria-label': 'show/hide lesson' }}/>
              </Tooltip>
            }
            label="Show/hide assignments"/>
            <FormControlLabel
              control={ <Tooltip
              title=""
              enterDelay={ 400 }>
              <WhiteCheckbox
                className="code-cell-setting"
                checked={!!this.state.regrades}
                color="default"
                onChange={(e) => this.showRegrades() }
                inputProps={{ 'aria-label': 'show/hide regrade information' }}/>
              </Tooltip>
            }
            label="Regrade requests"/>
          </FormGroup>
          </div>
    </ThemeProvider>);
  }

  exitHelpOrSettings() {
    this.setState({ showHelp: false, showSettings: false });
  }

  render() {
    updateTitleBar('Assignments');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings } = this.state;
    if (this.hide()) return null;
    const tools = [{
      icon: <HelpOutlineOutlinedIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help screen",
      disabled: false,
      hide: false,
    }, {
      icon: <SettingsIcon/>,
      onClick: () => this.setState({ showSettings: true }),
      tooltipTitle: "Settings",
      disabled: false,
      hide: false,
    }];
    const maskCover = (showHelp || showSettings) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    const helpCard = showHelp ? this.renderHelp() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    const blur = showHelp || showSettings ? " blur" : "";
    return (
      <div className="full-height">
        { maskCover }
        { helpCard }
        { settingsCard }
        <div className={ "assignments-creation-view" + blur }
          style={{
            position: "relative",
            height: "calc(100% - 62px)",
          }}>
          <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
          <div className="flow-root">
            <SidebarButtonPanel
            tools={ tools }/>
          </div>
            { this.renderAssignmentsArea() }
        </div>
      </div>
    );
  }

}

export default AssignmentsView;
