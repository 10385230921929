
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MessageForm from '../MessageForm';
import CardContents from '../CardContents';
import Hocket from '../hocket';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import { isRegistered, isNote, touchUpMarkdown, copyTextToClipboard,
         splitQuillDeltaOnHorizontalRule, extractCodeBlock, now,
         isValidDelta, } from '../utils';
import * as Mousetrap from 'mousetrap';

import CommentsArea from '../comments-area/CommentsArea';

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#efd' : '#fff',
});

const getItemStyle = (isDragging, draggableStyle, clusterStyle={}) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? '#ffe' : clusterStyle.background,
  // styles we need to apply on draggables
  ...draggableStyle
});

const MESSAGE_RENDER_MAX = 40;
const MESSAGE_RENDER_STEP = 10;

class DrillProposal extends Component {

  constructor(props) {
    super(props);
    this.deleteMessages = this.deleteMessages.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.proposalRef = {};
    this.unsub = {};
    this.mainQuillRef = React.createRef();
    this.state = {
      manualScroll: navigator.vendor === "Apple Computer, Inc.",
      hockets: {},
      activeHocketId: null,
      messageLimit: 40,
      showComments: false,
      activeCommentHocket: null,
      locked: false,
      reOrder: false,
    }
  }

  componentDidMount() {
    this.setRefs();
    this.subDrillProposal();
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    this.setMousetrap();
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    window.removeEventListener('copy', this.handleCopy);
    window.removeEventListener('paste', this.handlePaste);
    this.unsetMousetrap();
  }

  handleCopy(event) {
    const { locked, hocketSelectionRange } = this.state;
    if (hocketSelectionRange ||
          (locked && document.activeElement === this.hocketsAreaRef)) {
      const { json, html, md } = this.copyHockets();
      event.clipboardData.setData('application/json',
      json);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', md);
      event.preventDefault();
    }
  }

  copyHockets(all = false) {
    const {
      activeHocketId,
      hockets,
      hocketSelectionRange,
      proposal,
    } = this.state;
    const hocketIds = proposal.hockets;
    const messagesToCopy = [];
    const jupyterCells = [];
    let start, stop;
    let html = "";
    let md = "";
    if (hocketSelectionRange || all) {
      if (all) {
        start = 0;
        stop = hocketIds.length - 1;
      } else {
        [start, stop] = [...hocketSelectionRange].sort((a,b) => a-b);
      }
      for (let i = start;
               i <= stop;
               i++) {
        const currentHocket = hockets[hocketIds[i].hocketId];
        if (currentHocket && currentHocket.responses.length > 0) {
          const message = currentHocket.responses[0];
          const suggestions = currentHocket.suggestions;
          const jessieCode = currentHocket.jessieCode;
          const aspectRatio = currentHocket.aspectRatio || "100%";
          const codeCell = currentHocket.codeCell;
          const tableData = currentHocket.tableData;
          const urlIFrame = currentHocket.urlIFrame;
          const heightIFrame = currentHocket.heightIFrame;
          messagesToCopy.push({ message, suggestions, tableData, jessieCode, aspectRatio, codeCell, urlIFrame, heightIFrame });

          const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
          html += converter.convert();

          const quillDelta = JSON.parse(message);
          const thisCellMarkdown = touchUpMarkdown(deltaToMarkdown(quillDelta.ops));
          md += thisCellMarkdown + "\n---\n\n";

          jupyterCells.push({
            cell_type: "markdown",
            metadata: {},
            source: [thisCellMarkdown],
          })
          if (codeCell) {
            jupyterCells.push({
              cell_type: "code",
              metadata: {},
              source: [extractCodeBlock(quillDelta)],
              execution_count: null,
              outputs: [],
            })
          }
        }
      }
      md = md.slice(0, md.length - 7); // 7 is the length of \n\n---\n\n
    } else {
      const activeHocket = hockets[activeHocketId];
      if (activeHocket && activeHocket.responses.length > 0) {
        const message = activeHocket.responses[0];
        const suggestions = activeHocket.suggestions;
        const jessieCode = activeHocket.jessieCode || '';
        const aspectRatio = activeHocket.aspectRatio || "100%";
        messagesToCopy.push({ message, suggestions, jessieCode, aspectRatio });

        const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
        html += converter.convert();

        md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
      }
    }
    const json = JSON.stringify(messagesToCopy);
    const ipynb = JSON.stringify({
      metadata: {},
      nbformat: 4,
      nbformat_minor: 0,
      cells: jupyterCells,
    });
    return {ipynb, json, html, md};
  }

  pasteHockets(clipText) {
    const { proposal={}, activeHocketId } = this.state;
    const { hockets=[] } = proposal;
    let messages;
    try {
      console.log(clipText);
      messages = JSON.parse(clipText);
      console.log(messages);
    } catch (err) {
      console.error("Only messages from other Prismia drills can be pasted");
      return
    }
    messages
      .forEach( (message, loopCounter) => {
        const hocket = Hocket();
        if (!('message' in message) ||
            !isValidDelta(message.message) ||
            !('suggestions' in message)) {
          console.error("Only messages from other Prismia drills can be pasted");
          return;
        }
        hocket.responses.push(message.message);
        hocket.suggestions = message.suggestions;
        hocket.jessieCode = message.jessieCode || '';
        hocket.aspectRatio = message.aspectRatio || '';
        hocket.codeCell = message.codeCell || false;
        hocket.tableData = message.tableData || {string: ''};
        hocket.urlIFrame = message.urlIFrame || '';
        hocket.heightIFrame = message.heightIFrame || 0;
        const hocketStub = {
          hocketId: hocket.id,
        };
        const idx = (activeHocketId ?
            hockets.findIndex(
              hocket => hocket.hocketId === activeHocketId
            ) : hockets.length - 1);
        hockets.splice(idx + loopCounter + 1, 0, hocketStub);
        this.proposalRef
          .collection('hockets')
          .doc(hocket.id)
          .set(hocket, {merge: true})
          .catch(console.error);
      });
      this.proposalRef
        .set({ hockets }, { merge: true });
    }

  handlePaste(event) {
    const plainText = event.clipboardData.getData('text/plain');
    let clipText = event.clipboardData.getData('application/json');
    if (plainText.startsWith("[{\"message")) {
      clipText = plainText;
    }
    if ((document.activeElement === this.hocketsAreaRef ||
         document.activeElement.tagName.toLowerCase() === 'body') && 
         !window.prismiaDrawingCanvasOpen) {
      this.pasteHockets(clipText);
      event.preventDefault();
    }
  }

  setRefs() {
    this.proposalRef = this.props.db
    .collection('users')
    .doc(this.props.currentUser.id)
    .collection('drill-projects')
    .doc(this.props.projectId)
    .collection('proposals')
    .doc(this.props.drillId)
  }

  bootstrapProposal() {
    this.proposalRef.set(
      { hockets: [] },
    ).catch(console.error);
  }

  subDrillProposal() {
    this.unsub.proposal = this.proposalRef
      .onSnapshot(snap => {
      const proposal = snap.data();
      if (!proposal) {
        this.bootstrapProposal();
        return;
      }
      const { hockets=[] } = proposal;
      for (let i = 0; i < hockets.length; i++) {
        if (this.unsub[hockets[i].hocketId]) continue;
        this.subHocket(hockets[i].hocketId);
      }
      this.setState({ proposal });
      this.setState({ codeCell: proposal.codeCell || null });
      this.setState({ published: proposal.published || null});
    });
  }

  subHocket(hocketId) {
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = this.proposalRef
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
      });
  }

  setActiveHocketId(id) {
    this.setState({ activeHocketId: id,
      hocketSelectionRange: null 
    }, () => {
      if (this.mainQuillRef.current) {
        this.mainQuillRef.current.blur();
      }
    });
  }

  deleteMessages() {
    const {
      proposal={},
      activeHocketId,
      hocketSelectionRange,
    } = this.state;
    const { hockets=[] } = proposal;
    if (!activeHocketId) {
      console.log("No hocket selected");
      return null;
    }
    let range, answer, currentIndex;
    if (hocketSelectionRange) {
      answer = window.confirm("Are you sure you want to delete the selected messages?")
      range = [...hocketSelectionRange];
    } else {
      answer = window.confirm("Are you sure you want to delete the selected message?")
      currentIndex = hockets.findIndex(hocket => hocket.hocketId === activeHocketId);
      range = [currentIndex, currentIndex];
    }
    range = range.sort((a,b) => a-b);
    if (answer) {
      const remainingHockets = hockets.filter((hocket, i) => {
        return (i < range[0]) || (i > range[1]);
      });
      return this.proposalRef.set(
          { hockets: remainingHockets },
          { merge: true }
      );
    }
  }

  splitActiveCell() {
    const { db, projectId } = this.props;
    const { drill={}, activeHocketId } = this.state;
    const { hockets=[] } = drill;
    const idx = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    if (idx === -1) return;
    const delta = JSON.parse(this.state.hockets[hockets[idx].hocketId].responses[0]);
    const newDeltas = splitQuillDeltaOnHorizontalRule(delta);
    const answer = window.confirm(`Split current messages into ${newDeltas.length} new messages?`);
    if (!answer) return;
    const newHockets = newDeltas.map((delta) => {
      const hocket = Hocket();
      hocket.responses.push(JSON.stringify(delta));
      return hocket;
    });
    const batch = db.batch();
    newHockets.forEach( (hocket) => {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocket.id),
          hocket,
          {merge: true});
    });
    batch
      .commit()
      .then( () => {
        const newHocketStubs = newHockets.map(
          (hocket) => { return { hocketId: hocket.id }; }
        );
        hockets.splice(idx, 1, ...newHocketStubs);
        this.drillRef.set({ hockets }, {merge: true});
        return newHocketStubs;
      })
      .then( (hockets) => {
        const [lastHocket] = hockets.slice(-1);
        this.setState({activeHocketId: lastHocket.hocketId});
      })
      .catch(console.error);
  }

  createHocket() {
    const { proposal={}, activeHocketId } = this.state;
    const { hockets=[] } = proposal;
    const hocket = Hocket();
    const hocketStub = {
      hocketId: hocket.id,
    };
    const idx = (activeHocketId ?
        hockets.findIndex(
          hocket => hocket.hocketId === activeHocketId
        ) : hockets.length - 1);
    hockets.splice(idx + 1, 0, hocketStub);
    this.proposalRef
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.proposalRef
          .set({ hockets }, { merge: true });
      }).catch(console.error);
  }

  setSubmittedStatus() {

  }

  adjustActiveHocket(increment) {
    const { activeHocketId, proposal } = this.state;
    const { hockets=[] } = proposal;
    const currentIndex = this.hocketIndex(activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < hockets.length)) {
      this.setActiveHocketId(hockets[newIndex].hocketId);
    }
  }

  adjustSelectionEnd(increment) {
    const { activeHocketId,
            proposal,
            hocketSelectionRange
    } = this.state;
    const { hockets=[] } = proposal;
    const currentIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < hockets.length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  setMousetrap() {
    Mousetrap.bind("down", () => this.adjustActiveHocket(1));
    Mousetrap.bind("up", () => this.adjustActiveHocket(-1));
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+s", () => this.splitActiveCell());
    Mousetrap.bind("shift+r", () => this.toggleReorder());
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind("shift+=", () => this.createHocket());
    Mousetrap.bind("ctrl+j", () => this.toggleJuniper());
    Mousetrap.bind(["del", "backspace"], () => {
      this.deleteMessages();
    });
    Mousetrap.bind("esc", () => {
      this.setState({ activeHocketId: null });
      this.setState({
        hocketSelectionRange: null,
        showHelp: false,
        showSettings: false,
      });
    });
    Mousetrap.bind("shift+m", () => this.convertEditorContents());
    Mousetrap.bind(["mod+/", "shift+/"], () => this.toggleHelp());
  }

  unsetMousetrap() {
    Mousetrap.unbind("down");
    Mousetrap.unbind("up");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("shift+s");
    Mousetrap.unbind("shift+r");
    Mousetrap.unbind("shift+=");
    Mousetrap.unbind("del");
    Mousetrap.unbind("backspace");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("shift+/");
    Mousetrap.unbind("mod+/");
    Mousetrap.unbind("ctrl+j");
  }

  toggleReorder() {
    const setting = !this.state.reOrder;
    this.setState({ reOrder: setting });
    this.props.setReOrder(setting);
  }

  copyProblem(id) {
    const { drill, hockets } = this.props;
    const { statementHockets, solutionHockets } = drill.drillProblems[id];
    const messagesToCopy = [];
    for (let id of statementHockets.concat(solutionHockets)) {
      const currentHocket = hockets[id];
      if (currentHocket && currentHocket.responses.length > 0) {
        const message = currentHocket.responses[0];
        const suggestions = currentHocket.suggestions;
        const jessieCode = currentHocket.jessieCode;
        const aspectRatio = currentHocket.aspectRatio || "100%";
        const codeCell = currentHocket.codeCell;
        const tableData = currentHocket.tableData;
        messagesToCopy.push({ message, suggestions, tableData, jessieCode, aspectRatio, codeCell });
      }
    }
    copyTextToClipboard(JSON.stringify(messagesToCopy));
    this.props.NotificationManager.info('Copied! Now paste to insert this exercise statement and solution in the "Proposed Exercises" section');
  }

  renderExistingProblem(id) {
    const { drill, hockets } = this.props;
    const { statementHockets, solutionHockets } = drill.drillProblems[id]
    const copyIcon = <div
      style={{position: 'absolute', zIndex: 10, cursor: "pointer",
              top: "2px", right: "2px"}}
      onClick={ () => this.copyProblem(id) }>
        <FileCopyIcon size="small" style={{
          color: '#CCC', transform: "scale(0.75)",
        }}/>
      </div>;
    return <div key={id} style={{position: 'relative'}}>
      { copyIcon }
      { statementHockets.map(statementId => 
        <div 
          className="assignment-card"
          key={statementId}>  
          <CardContents
            db={ this.props.db }
            projectId={ this.props.projectId }
            codeCell={ this.state.codeCell }
            hocket={hockets[statementId]}
            setLang={(lang) => this.setLang(lang)}
            />
        </div>) }
      { solutionHockets.map(solutionId => 
        <div 
          className="assignment-card"
          key={solutionId}>  
          <CardContents
            db={ this.props.db }
            projectId={ this.props.projectId }
            hocket={hockets[solutionId]}
            codeCell={ this.state.codeCell }
            setLang={(lang) => this.setLang(lang)}
            />
        </div>) }
    </div>;
  }

  renderSeenExercises() {
    const { drill, submittedSolutions } = this.props;
    return (
      <>
      { Object.keys(drill.drillProblems).filter(id => submittedSolutions.has(id))
        .map(id => this.renderExistingProblem(id) ) }
      </>
    )
  }

  isInSelectionRange(hocketId) {
    const { proposal, hocketSelectionRange } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = proposal;
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  setActiveCommentHocket(id) {
    this.setState({ activeCommentHocket: id })
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, proposal } = this.state;
    const { hockets } = proposal;
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  renderHocketCard(hocket, index, width) {
    if (!hocket) return null;
    const { db, currentUser } = this.props;
    const { activeHocketId, comments, showComments, activeCommentHocket } = this.state;
    const style = {background: '#fff'};
    if (this.state.reOrder && hocket.id === activeHocketId) {
      style.background = 'rgb(169, 216, 225)'; // light teal
    }
    if (this.isInSelectionRange(hocket.id)) {
      style.background = '#fff3a8' // light yellow
    }
    const commentsArea = showComments ? <CommentsArea
      db={ db }
      width={ width }
      currentUser={ currentUser }
      comments={ comments[hocket.id] }
      parentId={ hocket.id }
      commentRef={ this.commentRef }
      activeCommentHocket={ activeCommentHocket }
      setActiveCommentHocket={ (id) => this.setActiveCommentHocket(id) }
      /> : null;
    const card = (<div
              className="assignment-card"
              key={ hocket.id }
              style={ style }
              onClick={ (e) => {
                  if (e.shiftKey) {
                    if (activeHocketId) {
                      this.setSelectionEnd(hocket.id);
                    } else {
                      this.setActiveHocketId(hocket.id);
                    }
                  } else {
                    this.setActiveHocketId(hocket.id);
                  }
              } }>
      { commentsArea }
      <CardContents 
          db={ this.props.db } 
          projectId={ this.props.projectId } 
          hocket={ hocket }
          codeCell={ this.state.codeCell }
          setLang={(lang) => this.setLang(lang)}
          showIndex/>
    </div>);
    if (!this.state.locked && !this.state.reOrder && activeHocketId === hocket.id) {
      return <div
        style={ style }
        key={ hocket.id }
        className={ 'message-form-card' }>
          { commentsArea }
          { this.renderHocketForm() }
        </div>;
    }
    if (!this.state.reOrder) return card;
    return (
      <Draggable
        draggableId={ hocket.id }
        index={ index }
        key={ hocket.id }>
      {(provided, snapshot) => {
        return (
            <div
              ref={ provided.innerRef }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="assignment-card"
              key={ hocket.id }
              style={ getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                style
              )}
              onClick={ (e) => {
                  if (this.hocketsAreaRef) {
                    this.hocketsAreaRef.focus()
                  }
                  if (e.shiftKey) {
                    this.setSelectionEnd(hocket.id);
                  } else {
                    this.setActiveHocketId(hocket.id);
                  }
              } }>
              <CardContents 
                db={ this.props.db } 
                projectId={ this.props.projectId } 
                hocket={ hocket }
                codeCell={ this.state.codeCell }
                setLang={(lang) => this.setLang(lang)}
                showIndex/>
            </div>
        );
      }}
      </Draggable>
    );
  }

  setLang(lang) {
    this.setState({ codeCell: lang });
  }

  onDragEnd(res) {
    const { destination, source } = res;
    if (!destination || !source) return;
    const { proposal={} } = this.state;
    const { hockets=[] } = proposal;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const hocketStub = hockets.splice(sourceIndex, 1)[0];
    hockets.splice(destinationIndex, 0, hocketStub);
    proposal.hockets = hockets;
    this.proposalRef
      .set(proposal, {merge:true});
  }

  renderHocketsArea(width) {
    const { proposal={}, hockets={}, messageLimit } = this.state;
    let hocketStubs = proposal.hockets;
    if (!hocketStubs) return null;
    const numMessages = hocketStubs.length;
    const hocketCards = [];
    let idx = 0;
    let numExtraCards = 0;
    let numExtraHiddenCards = 0;
    let start = Math.max(0, messageLimit - MESSAGE_RENDER_MAX);
    let messagesSinceNoteOrRegistered = 1;
    for (let i = 0; i < hocketStubs.length; i++) {
      const hocket = hockets[hocketStubs[i].hocketId];
      hocketCards.push(this.renderHocketCard(hocket, i, width));
      if (isRegistered(hocket)) {
        idx++;
        numExtraCards++;
        if (i < start) numExtraHiddenCards++;
        hocketCards.splice(-messagesSinceNoteOrRegistered, 0,
          <h3 className="centered" style={{color: "gray"}} key={`problem-title-${idx}`}>
            {"Problem " + idx}
          </h3>
        );
      }
      if (isNote(hocket) || isRegistered(hocket)) {
        messagesSinceNoteOrRegistered = 1;
      } else {
        messagesSinceNoteOrRegistered += 1;
      }
    }
    start += numExtraHiddenCards;
    const stop = Math.min(messageLimit, hocketCards.length) + numExtraCards;
    return (
      <div
        className="hockets-area"
        style={{width: "100%"}}
        tabIndex={ -1 }
        ref={ (node) => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
          {this.state.manualScroll && messageLimit > MESSAGE_RENDER_MAX ? <Tooltip
          title="Show earlier messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px",
              marginTop: "30px",
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.max(MESSAGE_RENDER_MAX, messageLimit - MESSAGE_RENDER_STEP)
              });
            }}
            >
            Earlier Messages
          </Button>
        </Tooltip> : null }
        <DragDropContext
          onDragEnd={res => this.onDragEnd(res)}>
          <Droppable
            droppableId={'list'}
            key={ 'droppable' }>
          { (provided, snapshot) => (
            <div
              className="pad-bottom"
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.innerProps}
                >
            { hocketCards.slice(start, stop) }
            { provided.placeholder }
            </div>
          )}
          </Droppable>
        </DragDropContext>
        {this.state.manualScroll && messageLimit < numMessages ? <Tooltip
          title="Show more messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px"
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.min(numMessages, messageLimit + MESSAGE_RENDER_STEP)
              });
            }}
            >
            More Messages...
          </Button>
        </Tooltip> : null }
        <Tooltip
          title="Add a new message after the currently selected message [shift +]"
          enterDelay={ 500 }>
          <Button
            fullWidth
            variant="outlined"
            className="add-hocket-button"
            onClick={() => this.createHocket()}
            >
            +
          </Button>
        </Tooltip>
      </div>
    );
  }

  renderHocketForm() {
    if (this.state.activeHocketId) {
      const hocketRef = this.proposalRef
      .collection('hockets')
      .doc(this.state.activeHocketId);
    return <div
            className="message-form-container"
            key={ this.state.activeHocketId }>
              <MessageForm
                db={ this.props.db }
                currentUser={ this.props.currentUser }
                storage={ this.props.storage }
                projectId={ this.props.projectId }
                hocketId={ this.state.activeHocketId }
                clearActiveHocketId={ () => this.setActiveHocketId(null) }
                deleteMessages={ this.deleteMessages }
                mainQuillRef={ this.mainQuillRef }
                splitActiveCell={ () => this.splitActiveCell() }
                createHocket={ () => this.createHocket() }
                codeCell={ this.props.codeCell }
                setSubmittedStatus={ (status) => this.setSubmittedStatus(status) }
                setMousetrap={ () => this.setMousetrap() }
                hocketRef={ hocketRef }
                hideSuggestedMessages
                hideOpenResponse
                studentView
                />
      </div>;
    } else return null;
  }

  renderPlusButton() {
    return <Tooltip
      title="Add a new message after the currently selected message [shift +]"
      enterDelay={ 500 }>
      <Button
        fullWidth
        variant="outlined"
        className="add-hocket-button"
        onClick={() => this.createHocket()}
        >
        +
      </Button>
    </Tooltip>;
  }

  collectProblems() {
    const { proposal={}, hockets={} } = this.state;
    const hocketsToCopy = [];
    let hocketStubs = proposal.hockets;
    const problems = {};
    let latestId, messagesSinceNoteOrRegistered = 1;
    for (let i = 0; i < hocketStubs.length; i++) {
      const id = hocketStubs[i].hocketId;
      const hocket = hockets[id];
      hocketsToCopy.push(hocket);
      if (isRegistered(hocket)) {
        latestId = id
        problems[id] = {};
        problems[id]['statementHockets'] = hocketStubs.slice(
          i + 1 - messagesSinceNoteOrRegistered, 
          i + 1,
        ).map(stub => stub.hocketId);
      }
      if (isNote(hocket) && latestId) {
        if (!problems[latestId].solutionHockets) problems[latestId].solutionHockets = [];
        problems[latestId].solutionHockets.push(hocketStubs[i].hocketId);
      }
      if (isNote(hocket) || isRegistered(hocket)) {
        messagesSinceNoteOrRegistered = 1;
      } else {
        messagesSinceNoteOrRegistered += 1;
      }
    }
    return {problems, hockets: hocketsToCopy};
  }

  propose() {
    const { db, projectId, drillId, currentUser } = this.props;
    const drillRef = db.collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .collection('proposed-problems')
      .doc(currentUser.id);
    const {problems, hockets} = this.collectProblems();
    const batch = db.batch();
    for (let hocket of hockets) {
      const hocketRef = drillRef.collection('hockets').doc(hocket.id);
      batch.set(hocketRef, hocket);
    }
    batch.set(drillRef, { 
      author: currentUser.id,
      authorPhotoUrl: currentUser.photoUrl,
      authorDisplayname: currentUser.displayName,
      timestamp: now(), 
      drillProblems: problems 
    }, {merge: true});
    batch.commit().then( () => 
      this.props.NotificationManager.success(
        "Your exercises have been proposed!"
    )).catch(console.error)
  }

  renderProposeButton() {
    return <Button
      onClick={() => this.propose() }
      variant="contained"
      color="primary"
      style={{
          display: 'block', 
          marginLeft: 'auto', 
          marginRight: 'auto',
          marginBottom: '30px',
        }}>
        Propose
    </Button>;
  }

  render() {
    return <div className="y-scrollable">
      <div className="hockets-area">
        <h2 className="centered"> Original Exercises </h2>
        { this.renderSeenExercises() }
        <h2 className="centered" style={{marginTop: "2em"}}> Proposed Exercises </h2>
        { this.renderHocketsArea(this.props.width) }
        { this.renderProposeButton() }
      </div>
    </div>;
  }

}

export default DrillProposal;