import React, { Component } from 'react';

import 'codemirror/lib/codemirror.css';
import {Controlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/mode/python/python.js';
import 'codemirror/mode/julia/julia.js';
import 'codemirror/mode/r/r.js';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/shell/shell.js';
import 'codemirror/mode/sql/sql.js';
import cm from 'codemirror';
import { Pos } from 'codemirror';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CodeIcon from '@material-ui/icons/Code';
import { withStyles } from '@material-ui/core/styles';
//import { connectWebIOToWebSocket } from '@webio/generic-http-provider/dist/generic-http.bundle.js';

import { Widget } from '@phosphor/widgets';
import { KernelManager, ServerConnection } from '@jupyterlab/services';
import { OutputArea, OutputAreaModel } from '@jupyterlab/outputarea';
import { 
  RenderMimeRegistry, 
  standardRendererFactories 
} from '@jupyterlab/rendermime';
import { 
  HTMLManager,
  requireLoader 
} from "@jupyter-widgets/html-manager";
import {
  WIDGET_MIMETYPE,
  WidgetRenderer,
} from "@jupyter-widgets/html-manager/lib/output_renderers";

import { MathJaxTypesetter } from "@jupyterlab/mathjax2";

import { tabCompletionMap } from 'quill-markdown-shortcuts';

import { allBinderKernels } from './jupyter';

const CodeButton = withStyles({
  root: {
    user_ect: "none",
    cursor: "pointer",
    padding: 0,
    border: "1px solid rgb(35, 120, 130)",
    backgroundColor: "rgba(35, 120, 130, 0.03)",
    borderRadius: "10px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    color: "rgb(35, 120, 130)",
    marginBottom: "16px",
    height: "28px",
    width: "120px",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.07), 0px 1px 5px 0px rgba(0,0,0,0.06)",
  }
})(Button);

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  if (s === 'sagemath') return 'SageMath';
  if (s === 'cpp') return "C++";
  if (s.toLowerCase() === 'mlj') {
    return 'MLJ'
  }
  if (s === 'py2neo') {
    return s;
  }
  if (s === 'bigdata') {
    return 'Spark + Kafka';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

class JupyterCell extends Component {

  constructor (props) {
    super(props);
    this.inputRef = React.createRef();
    this.buttonRef = React.createRef();
    this.outputRef = React.createRef();
    this.theme = props.theme || 'default';
    this.useStorage = false;
    this.storageExpire = 60;
    this.eventName = 'jupyter';
    this.url = 'https://mybinder.org';
    this.msgLoading = "Executing..."
    this.msgError = 'Connecting failed. Please reload and try again.';
    this.hashLangs = [];
    for (let [id, kernel] of Object.entries(allBinderKernels)) {
      for (let hashLang of kernel.hashLang) {
        this.hashLangs[hashLang] = id;
      }
    }
    this.runFailures = 0;
    this.resetFailures = 0;
    this.stopPressed = 0;
    this.state = {
      code: null,
      hasChanged: false,
      kernelFailed: false,
      kernelRunning: false,
      language: 'python',
    };
  } 

  setLanguage(language) {
    language = language || 'python';
    const repo = this.props.repo || allBinderKernels[language]?.repoName;
    this.branch = this.props.branch || allBinderKernels[language]?.branch || 'main';
    const storageKey = 'prismia-jupyter-' + language;
    const kernelKey = 'prismia-' + language + '-kernel';
    const kernelType = this.props.kernelType || allBinderKernels[language]?.kernelName;

    this.setState({language, repo, storageKey, kernelKey, kernelType});
  }
  
  _event(status, data) {
    console.log(status);
    const ev = new CustomEvent(this.eventName, { detail: { status, data } });
    document.dispatchEvent(ev);
  }

  componentDidMount() {
    const outputArea = new OutputArea({
      model: new OutputAreaModel({ trusted: true }),
      rendermime: this.getRenderMime(),
    });

    Widget.attach(outputArea, this.outputRef.current);

    const runCode = (ev) => {
      if (ev.stopPropagation) ev.stopPropagation();
      const { code } = this.state;
      if (this.props.onRun) {
        this.props.onRun(code);
      }
      const lang = this.getLang(code);
      if (lang) {
        this.setLanguage(lang);
      } else if (this.state.language !== this.props.language) {
        this.setLanguage(this.props.language);
      }
      if (this.state.kernelFailed) {
        if (this.resetFailures > 2) {
          this.resetFailures = 0;
          this.useStorage = false;
        } else {
          this.resetFailures++;
        }
        window[this.state.kernelKey] = null;
        this.setState({kernelFailed: false});
        return;
      }
      this.execute(outputArea, code);
    }

    const onTab = (ev) => {
      const currentPosition = this.editor.getCursor();
      let lookBack = currentPosition.ch;
      while ( lookBack >= 0) {
        lookBack--;
        const pos = Pos(currentPosition.line, lookBack);
        const match = this.editor.getRange(pos, currentPosition);
        if (match[0] === '\\') {
          if (tabCompletionMap.has(match)) {
            this.editor.replaceRange(tabCompletionMap.get(match), pos, currentPosition);
          }
          return;
        }
      }
      if (this.editor.getSelection().length === 0) {
        const spaces = Array(this.editor.getOption("indentUnit") + 1).join(" ");
        this.editor.replaceRange(spaces, currentPosition);
        return;
      }
      return cm.Pass;
    }

    const extraKeys = {
      'Shift-Enter': runCode,
      'Tab': onTab,
    }

    let lang = this.props.language;
    if (this.props.content) {
      const contentLang = this.getLang(this.props.content);
      if (contentLang) lang = contentLang;
      this.setState({ code: this.props.content });
    }
    this.setLanguage(lang);

    if (lang === 'python' || lang === 'sage') {
      extraKeys['Shift-Tab'] = 'indentLess';
    }
    this.buttonRef.current.addEventListener('click', runCode);
    this.editor.setOption('extraKeys', extraKeys);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content === this.props.content) return;
    const { content=null } = this.props;
    const { hasChanged } = this.state;
    // if we've had a change in the editor, we need to NOT update from props: 
    if (hasChanged) return;
    this.setState({ code: content.trim() });
  }

  getLang(code) {
    if (!code) return;
    for (let [hashLang, lang] of Object.entries(this.hashLangs)) {
      if (code.trim().startsWith("#lang " + hashLang) || 
            code.trim().startsWith("#repo " + hashLang)) {
        return lang;
      }
      if (code.trim().startsWith("#setlang " + hashLang) || 
          code.trim().startsWith("#setrepo " + hashLang)) {
        if (this.props.setLang) {
          this.props.setLang(lang);
        }
        return lang;
      }
    }
  }

  renderOutput(outputArea, code) {
    try {
      if (code.trim().startsWith('#lang')) code = code.trim().split('\n').slice(1).join('\n');
      if (allBinderKernels[this.state.kernelType]?.codePrefix) {
        code = allBinderKernels[this.state.kernelType].codePrefix + code;
      }
      const future = window[this.state.kernelKey].requestExecute({ code });
      future.done.then(() => {
        this.setState({ kernelRunning: false });
        if (outputArea?.model?.list?._array?.[0]?._raw?.name === "loading") {
          outputArea.model.add({
            output_type: 'stream',
            name: 'done',
            text: 'done',
          });
        }
      });
      outputArea.future = future;
      outputArea.model.add({
          output_type: 'stream',
          name: 'loading',
          text: this.msgLoading,
      });
      outputArea.model.clear(true);
      this.setState({kernelRunning: true});
      this.runFailures = 0;
    } catch(err) {
      console.error(err);
      this.runFailures += 1;
      if (this.runFailures < 3) {
        window[this.state.kernelKey] = null;
        outputArea.model.add({
          output_type: 'stream',
          name: 'restarting kernel',
          text: this.msgLoading,
        });
        outputArea.model.clear(true);
        setTimeout(() => this.execute(outputArea, code), 800);
      } else {
        this.setState({ kernelFailed: true });
      }
    }
  }

  getKernel() {
    if (this.useStorage && typeof window !== 'undefined') {
      const stored = window.localStorage.getItem(this.state.storageKey);
      if (stored) {
          this._fromStorage = true;
          const { settings, timestamp } = JSON.parse(stored);
          if (timestamp && new Date().getTime() < timestamp) {
              return this.requestKernel(settings);
          }
          window.localStorage.removeItem(this.state.storageKey);
      }
    }
    return this.requestBinder(this.state.repo, this.branch, this.url)
               .then(settings => {
                 return this.requestKernel(settings)
               })
               .catch(console.error);
  }

  execute(outputArea, code) {
    if (window[this.state.kernelKey]) {
      if (this.state.kernelRunning) {
        if (this.stopPressed < 3) {
          window[this.state.kernelKey].interrupt();
          this.stopPressed++;
        } else {
          this.setState({ kernelFailed: true, kernelRunning: false });
          this.stopPressed = 0;
        }
      } else {
        this.renderOutput(outputArea, code);
        this.stopPressed = 0;
      }
    } else if (window['requesting-' + this.state.kernelKey]) {
      outputArea.model.clear();
      outputArea.model.add({
          output_type: 'stream',
          name: 'stdout',
          text: `Wait for kernel to load and then try again...`
      });
      return;
    } else {
      this._event('requesting-kernel');
      window['requesting-' + this.state.kernelKey] = true;
      const url = this.url.split('//')[1];
      const action = !this._fromStorage ? 'Launching' : 'Reconnecting to';
      outputArea.model.clear();
      outputArea.model.add({
          output_type: 'stream',
          name: 'stdout',
          text: `${action} your ${capitalize(this.state.language)} session on ${url}...`
      });
      new Promise((resolve, reject) =>
          this.getKernel().then(resolve).catch(reject))
          .then(kernel => {
              window['requesting-' + this.state.kernelKey] = false;
              window[this.state.kernelKey] = kernel;
              this.renderOutput(outputArea, code);
          })
          .catch((err) => {
              window['requesting-' + this.state.kernelKey] = false;
              console.error(err)
              this._event('failed');
              window[this.state.kernelKey] = null;
              if (this.useStorage && typeof window !== 'undefined') {
                  this._fromStorage = false;
                  window.localStorage.removeItem(this.state.storageKey);
              }
              outputArea.model.clear();
              outputArea.model.add({
                  output_type: 'stream',
                  name: 'failure',
                  text: this.msgError
              });
              this.setState({ kernelFailed: true });
          })
    }
  }

  requestBinder(repo, branch, url) {
    const binderUrl = `${url}/build/gh/${repo}/${branch}`;
    console.log(binderUrl)
    this._event('building', { binderUrl });
    return new Promise((resolve, reject) => {
        const es = new EventSource(binderUrl);
        es.onerror = err => {
            es.close();
            this._event('failed');
            reject(new Error(err));
        }
        let phase = null;
        es.onmessage = ({ data }) => {
            const msg = JSON.parse(data);
            if (msg.phase && msg.phase !== phase) {
                phase = msg.phase.toLowerCase();
                this._event(phase === 'ready' ? 'server-ready' : phase);
            }
            if (msg.phase === 'failed') {
                es.close();
                reject(new Error(msg));
            }
            else if (msg.phase === 'ready') {
                es.close();
                const settings = {
                    baseUrl: msg.url,
                    wsUrl: `ws${msg.url.slice(4)}`,
                    token: msg.token,
                    appendToken: true,
                };
                resolve(settings);
            }
        }
    });
  }

  requestKernel(settings) {
    if (this.useStorage && typeof window !== 'undefined') {
        const timestamp = new Date().getTime() + this.storageExpire * 60 * 1000;
        const json = JSON.stringify({ settings, timestamp });
        window.localStorage.setItem(this.state.storageKey, json);
    }
    const serverSettings = ServerConnection.makeSettings(settings);
    const kernelOptions = {path: "/", type: this.state.kernelType, name: this.state.kernelType, serverSettings};
    const kernelManager = new KernelManager({serverSettings});
    return kernelManager
          .ready
          .then(() => {
            return kernelManager.startNew(kernelOptions)
          }).then(kernel => {
            this._event('ready');
            console.log(this.state.kernelType);
            //if (this.state.kernelType.startsWith('julia')) {
            //  // this part is here to make Julia widgets to work
            //  try {
            //    const _webIOWebSocketURL = kernel._ws.url + "/webio-socket";
            //    connectWebIOToWebSocket(window.WebIO, _webIOWebSocketURL);
            //  } catch (err) {
            //    console.error(err);
            //  }
            //}
            return kernel;
          })
          .catch(console.error);
  }

  getRenderMime() {
    if(!this._renderMime) {
        const renderers = standardRendererFactories;
          const registry = new RenderMimeRegistry({
            initialFactories: renderers,
            latexTypesetter: new MathJaxTypesetter({
              url: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js",
              config: "TeX-AMS_CHTML-full,Safe",
            }),
          });
          let manager = new HTMLManager({
            loader: requireLoader,
          });
          registry.addFactory({
              safe: false,
              mimeTypes: [WIDGET_MIMETYPE],
              createRenderer: (options) => new WidgetRenderer(options, manager),
          }, 1);
          this._renderMime = registry;
    }
    return this._renderMime;
  }

  getMode() {
    const { code } = this.state; 
    if (code && code.startsWith("%%sql")) return "sql";
    return allBinderKernels[this.state.language]?.mode;
  }

  render() {
    const { code, kernelType } = this.state;
    return (
      <div className="juniper-cell">
        <div className="juniper-input">
        <CodeMirror 
          editorDidMount={editor => this.editor = editor }
          options={{
            mode: this.getMode(),
            theme: 'default',
            indentUnit: (kernelType?.startsWith && (kernelType.startsWith('python') || kernelType.startsWith('sage')) ? 4 : 2),
          }}
          value={ code }
          onBeforeChange={(editor, data, value) => {
            this.setState({ code: value });
            if (this.props.setTyping) {
              this.props.setTyping(value);
            }
            if (value !== this.props.content) {
              this.setState({ hasChanged: true });
            }
          }}
          />
        <button
          ref={ this.buttonRef } className="juniper-button">
            { this.state.kernelFailed ? (this.resetFailures > 2 ? "hard reset" : "reset") : 
              (this.state.kernelRunning ? "stop" : (this.props.buttonLabel || "run")) }
          </button>
        </div>
        <div ref={ this.outputRef } className="juniper-output">
        </div>
      </div>
    );
  }
}

class CollapsibleJupyterCell extends Component {

  constructor(props) {
    super(props);
    this.hydrate = this.hydrate.bind(this);
    this.state = { open: false };
  }

  hydrate() {
    this.setState({open: false});
  }

  render() {
    const { open } = this.state;
    if (open) {
      return <JupyterCell {...this.props}/>
    }
    return (
      <Tooltip
        title="open executable code cell"
        enterDelay={ 400 }>
        <CodeButton 
          onClick={ () => this.setState({open: true}) }>
            <CodeIcon/>
        </CodeButton>
      </Tooltip>
    );
  }
}

export { JupyterCell, CollapsibleJupyterCell };