import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CommentIcon from '@material-ui/icons/Comment';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotificationManager } from 'react-notifications';
import ReactQuill from 'react-quill';
import { mdShortcutsOnly } from '../quill-config';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty, now, timestampOrder } from '../utils';
import uuid from 'uuid/v4';
import './style.css';

const MOBILE_THRESHOLD = 1175;
const defaultDelta = {ops: [{insert: "\n"}]};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const lpad = (n) => {
  if (n < 10) {
    return "0" + n;
  }
  else {
    return String(n);
  }
}

class CommentsArea extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMessageId: null,
      quillDelta: defaultDelta,
      editorOpen: false,
      deletionMode: false,
      commentsToDelete: new Set(),
    }
  }

  deleteComments() {
    NotificationManager.info("Select the comments you want to delete");
    this.setState({ deletionMode: true });
  }

  deleteSelected() {
    const { db, comments, commentRef } = this.props;
    const { commentsToDelete } = this.state;
    confirmAlert({
      title: "Confirm",
      message: `Do you want to delete the ${commentsToDelete.size} selected comments?`,
      buttons: [
        {label: "Yes, delete", onClick: () => {
          const batch = db.batch();
          for (let comment of comments) {
            if (!commentsToDelete.has(comment.id)) continue;
            batch.set(commentRef.doc(comment.id),
              { archived: true }, { merge: true }
            );
          }
          batch.commit().catch(console.error);
          this.setState({ deletionMode: false, commentsToDelete: new Set() });
        }},
        {label: "No", onClick: () => null},
      ],
    });
  }

  createComment() {
    const { parentId, commentRef, currentUser } = this.props;
    const { quillDelta } = this.state;
    if (isEmpty(quillDelta)) return;
    const id = uuid();
    const comment = {
      timestamp: now(),
      author: currentUser.id,
      authorDisplayName: currentUser.displayName,
      authorPhotoUrl: currentUser.photoUrl,
      id,
      parentId,
      quillDelta: JSON.stringify(quillDelta),
      archived: false,
    };
    commentRef.doc(id).set({...comment});
    this.setState({ quillDelta: defaultDelta });
  }

  commentStyle(visible, width) {
    if (!visible) {
      if (width > 650) return {};
      return {style: {right: "5px"}};
    }
    if (width > MOBILE_THRESHOLD) {
      return { style:
        { width: Math.round((width - 760 - 150) / 2) }
      };
    } else {
      // mobile version; overlaps the original message:
      return { style: {
          width: "200px",
          transform: "unset",
          right: "3px",
          zIndex: 12,
          borderRadius: "3px",
          background: "white",
          paddingRight: "20px",
          boxShadow: "0px 3px 4px -2px rgba(22,60,74,0.2), 0px 4px 4px -2px rgba(22,60,74,0.14), 0px 4px 5px 0px rgba(22,60,74,0.12), 0px -3px 4px -2px rgba(22,60,74,0.2), 3px 3px 4px -2px rgba(22,60,74,0.2), -3px 3px 4px -2px rgba(22,60,74,0.2)",
        }
      };
    }
  }

  render() {
    const { comments, parentId, width,
            activeCommentHocket, setActiveCommentHocket } = this.props;
    if (activeCommentHocket && activeCommentHocket !== parentId) return null;
    const visible = activeCommentHocket && activeCommentHocket === parentId;
    const { quillDelta, commentsToDelete,
            editorOpen, deletionMode } = this.state;
    let commentsSection, messageForm;
    if (visible && comments && comments.length) {
        const sortedComments = [...comments].sort((c1, c2) => timestampOrder(c1.timestamp, c2.timestamp));
        commentsSection = <>
          { sortedComments.map(comment => {
            const timestamp = comment?.timestamp?.toDate();
            const dateString = timestamp && (timestamp.getDate()  + " " +
              (months[timestamp.getMonth()]) + " " +
              timestamp.getFullYear() + " " +
              timestamp.getHours() + ":" + lpad(timestamp.getMinutes())
            );
          return (<div
            key={ comment.id }
            style={{ width: "100%" }}
            className={"comments" + (commentsToDelete.has(comment.id) ? " selected": "")}
            onClick={e => {
              e.stopPropagation();
              if (deletionMode) {
                if (commentsToDelete.has(comment.id)) {
                  commentsToDelete.delete(comment.id);
                } else {
                  commentsToDelete.add(comment.id);
                }
                this.setState({ commentsToDelete });
              }
            }}>
            <Tooltip title={
              <div
                style={{whiteSpace: "pre-line"}}>
                { comment.authorDisplayName + "\n" + dateString }
              </div>}>
              <img
                alt={ comment.authorDisplayName + " profile icon" }
                className="comment-photo"
                src={ comment.authorPhotoUrl }/>
            </Tooltip>
            <ReactQuill
              readOnly
              modules={ { toolbar: false } }
              value={ JSON.parse(comment.quillDelta) }>
            </ReactQuill>
        </div>);
      }) }
      </>;
    }
    if (visible && (editorOpen || !comments)) {
      messageForm = (
        <div
          className="comments"
          style={{ width: "100%" }}
          onClick={e => e.stopPropagation()}>
          <ReactQuill
            modules={ mdShortcutsOnly }
            value={ quillDelta }
            onChange={ (content, delta, source, editor) => {
              const fullDelta = editor.getContents();
              this.setState({ quillDelta: fullDelta });
            } }>
          </ReactQuill>
        </div>
      );
    }
    let plusButton;
    if (visible) {
      if (!editorOpen) {
        plusButton = <IconButton
          className="plus-button"
          onClick={(e) => {
            this.setState({ editorOpen: true });
            e.stopPropagation();
          }}>
          <Tooltip title="add a comment">
            <AddIcon
              size="small"
              style={{ color: '#CCC' }}/>
          </Tooltip>
        </IconButton>;
      } else {
        plusButton = <Button
            size="small"
            color="primary"
            className="plus-button"
            variant={ isEmpty(quillDelta) ? "outlined" : "contained" }
            onClick={ (e) => {
              e.stopPropagation();
              this.createComment();
              this.setState({ editorOpen: false });
              if (isEmpty(quillDelta) && !comments) {
                setActiveCommentHocket(null);
              }
            }}>
          { isEmpty(quillDelta) ? "Done" : "Save" }
        </Button>;
      }
    }
    return (<div
        className="comment-area"
        {...this.commentStyle(visible, width)}
        onClick={ e => e.stopPropagation() }>
      { visible && comments && comments.length > 0 ?
        ( deletionMode ?
          <Tooltip title="delete selected comments">
            { commentsToDelete.size > 0 ? <Button
              className="delete-selected-button"
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => this.deleteSelected() }>
              Delete
            </Button> :
            <Button
              className="delete-selected-button"
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => this.setState({ deletionMode: false }) }>
              Done
            </Button>
           }
          </Tooltip> :
        <Tooltip title="select comments to delete">
          <IconButton
            className="delete-button"
            onClick={ () => this.deleteComments() }>
              <DeleteIcon
                style={ {color: '#da544dcc'} }
                size="small"/>
          </IconButton>
        </Tooltip>) : null }
      <IconButton
        className="comment-button"
        onClick={ (e) => {
          e.stopPropagation();
          if (!activeCommentHocket) {
            setActiveCommentHocket(parentId);
          } else {
            setActiveCommentHocket(null);
          }
          if (!comments) {
            this.setState({ editorOpen: true });
          }
        } }>
        { visible ? <Tooltip
            title="close message comments"
            enterDelay={ 100 }>
              { width < MOBILE_THRESHOLD ? <KeyboardArrowRightIcon
                style={{color: "#CCC", transform: "scale(1.5)"}}/> :
                <KeyboardArrowLeftIcon
                  style={{color: "#CCC", transform: "scale(1.65)"}}/>
              }
            </Tooltip> :
          <CommentIcon
            size="small"
            {...(comments && comments.length ? {color: "primary"} : {style: {color: '#DDD'}})}/> }
      </IconButton>
      { commentsSection }
      { messageForm }
      { plusButton }
    </div>);
  }

}

export default CommentsArea;
