import React, { Component } from 'react';
import SimpleAdminNav from '../SimpleAdminNav';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { updateTitleBar, reorder } from '../utils';
import moment from 'moment-timezone';
import { getDocumentButtonStyle, DocumentButton } from '../mui-themes';
import './style.css';


class AssignmentsView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      assignments: null,
    };
    this.state = {
      instructors: {},
      tas: {},
      hideMode: false,
    };
  }

  componentDidMount() {
    this.subProject(this.props);
    this.subAssignmentOrder(this.props);
    this.subAssignments(this.props);
    this.subProject(this.props);
  }

  subProject(props) {
    const { db, projectId } = props;
    db.collection('projects')
      .doc(projectId)
      .onSnapshot(snap => {
        const doc = snap.data();
        if (doc.timeZone) moment.tz.setDefault(doc.timeZone);
        this.setState({ project: doc });
      });
  }

  subAssignments(props, Cb) {
    if (this.unsub.assignments) this.unsub.assignments();
    const { assignmentOrder } = this.state;
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.assignments = db
      .collection('projects')
      .doc(projectId)
      .collection('published-assignments')
      .orderBy('timestamp', 'desc')
      .onSnapshot(snap => {
        console.log(snap.docs);
        if (!snap.docs) return;
        let assignments = snap.docs.map(doc => doc.data());
        if (assignmentOrder) {
          assignments.sort( (assignment1, assignment2) => {
            if (assignmentOrder.indexOf(assignment1.id) < assignmentOrder.indexOf(assignment2.id)) {
              return -1;
            } else {
              return 1;
            }
          });
        }
        this.setState({ assignments });
        if (Cb) Cb(assignments);
      });
  }

  subAssignmentOrder(props) {
    if (this.unsub.assignmentOrder) this.unsub.assignmentOrder();
    const { db, currentUser, projectId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.unsub.assignmentOrder = db
      .collection('projects')
      .doc(projectId)
      .collection('assignment-order')
      .doc('ids')
      .onSnapshot(doc => {
        const ids = doc.data();
        if (!ids || !ids.assignmentOrder) {
          this.subAssignments(this.props, (assignments) =>
          this.setAssignmentOrder(assignments.map(l => l.id)));
        } else {
          this.setState({ assignmentOrder: ids.assignmentOrder }, () => this.subAssignments(this.props));
        }
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
  }

  nameAssignment(assignmentTitle='') {
    this.setState({ assignmentTitle });
  }

  describeAssignment(assignmentDescription) {
    this.setState({ assignmentDescription });
  }

  hideAssignment(assignmentId, hide=true) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('assignments')
      .doc(assignmentId)
      .set({ hidden: hide }, {merge: true})
      .catch(console.error);
  }

  renderAssignmentCard(assignment, index) {
    const { projectId, router } = this.props;
    const link = '/projects/' + projectId + '/assignments/' + assignment.id;
    const deadline = () => moment(assignment.deadline.toDate()).format('Do MMMM YYYY @ HH:mm').replace("@", "at");
    return (
      <DocumentButton
        key={"list-item-" + assignment.id}
        onClick={ () => router.history.push(link) }
        button
        style={getDocumentButtonStyle()}>
        <ListItemText
          primary={ assignment.title }
          secondary={ assignment && assignment.deadline ? deadline() : null }
          className= "assignment-card-text" />
      </DocumentButton>
    );
  }

  onDragEnd(result) {
    const { assignments, assignmentOrder, hideMode } = this.state;
    // dropped outside the list
    if (!result.destination || !assignmentOrder) {
      return;
    }
    const visibleAssignments = hideMode ? assignments : assignments.filter(l => !l.hidden);
    const indexMap = (i) => assignmentOrder.indexOf(visibleAssignments[i].id);
    const start = indexMap(result.source.index);
    const end = indexMap(result.destination.index);
    const newAssignments = reorder(assignments, start, end);
    this.setState({ assignments: newAssignments }, () =>
      this.setAssignmentOrder(newAssignments.map( l => l.id ))
    );
  }

  setAssignmentOrder(assignmentOrder) {
    const { db, projectId } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('assignment-order')
      .doc('ids')
      .set({ assignmentOrder })
      .catch(console.error)
  }

  renderAssignmentCards() {
    const { hideMode } = this.state;
    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? "lightblue" : "white",
      marginTop: "40px",
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    });
    const { assignments=[] } = this.state;
    const cards = assignments.filter(
        l => hideMode || !l.hidden
      ).map( (l, i) => this.renderAssignmentCard(l, i));
    return (
      <List
        style={getListStyle(false)}>
        { cards }
      </List>
    );
  }

  renderAssignmentsArea() {
    return (
      <div>
        <div className="padded">
          { this.renderAssignmentCards() }
        </div>
      </div>
    );
  }

  render() {
    updateTitleBar('Assignments');
    const { db, router, projectId, currentUser={} } = this.props;
    const { project } = this.state;
    return (
      <div className="assignments-view">
        <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } studentView={ true }/>
        <div className="y-scrollable">
          <div className="assignment-buttons-area"> 
            <h1
              className="assignment-title centered">
              { project && project.displayName ? project.displayName : null }
            </h1>
            <h2 className="centered">
              Assignments
            </h2>
            { project && project.description && project.description.length ? <h2
              className="description-text centered">
              { project.description }
            </h2> : null }
            { this.renderAssignmentsArea() }
          </div>
        </div>
      </div>
    );
  }

}

export default AssignmentsView;
