import React, { Component } from 'react';

class DownView extends Component {
 
  render() {
    const height = window.innerHeight;
    const style = {
      opacity: 0,
      padding: '40px',
      fontSize: '4em',
      fontWeight: '400',
      textAlign: 'center',
      marginTop: (0.33 * height) + 'px',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    return (
      <div className="landing-view" style={ style }>
          Prismia
        <p className="subtext">is down for scheduled maintenance.</p>
      </div>
    );
  }

}

export default DownView;
