import React, { Component } from 'react';
import zenscroll from 'zenscroll';
import ReactResizeDetector from 'react-resize-detector';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import MessageCard from './MessageCard';
import { JupyterCell } from '../JupyterCell';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import CodeIcon from '@material-ui/icons/Code';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ReorderIcon from '@material-ui/icons/Reorder';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import toPlaintext from 'quill-delta-to-plaintext';
import { updateTitleBar, chunk, splitQuillDeltaOnHorizontalRule, 
        pingBinderKernel, getOutline, slidesUploader, now,
        copyTextToClipboard, touchUpMarkdown, peelOffSuggestions, markdownToDelta, mousetrapStopCallback, tryUntilSuccess, isInViewport, isNote } from '../utils';
import { binderKernels, exportJupyterNotebook, cells2hockets } from '../jupyter';
import Hocket from '../hocket';
import { SharedLesson } from '../lesson'
import { shortUID, isValidDelta } from '../utils'
import {
  manuallySortLessonMessage
} from '../analytics';
import 'react-quill/dist/quill.snow.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
//import 'react-notifications/lib/notifications.css';
import './style.css';
import * as Mousetrap from 'mousetrap';
import moment from 'moment-timezone'; // eslint-disable-line no-unused-vars
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { settingsTheme, settingsClockTheme } from '../mui-themes';
import uuid from 'uuid/v4';

// for message virutalization: only render a
// window of 50 messages, stepping in increments
// of 10 when the window scroll position gets
// near the top or bottom:
const MESSAGE_RENDER_MAX = 40;
const MESSAGE_RENDER_STEP = 10;

const mobileThreshold = 920;

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#efd' : '#fff',
});

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

class LessonView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      lesson: null,
      project: null,
      settings: null,
    };
    this.lessonRef = {};
    this.commentRef = {};
    this.mainQuillRef = React.createRef();
    this.juniperRef = React.createRef();
    this.jupyterInputRef = React.createRef();
    this.slidesInputRef = React.createRef();
    this.deleteMessages = this.deleteMessages.bind(this);
    this.hocketsAreaRef = null;
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.setSelectionEnd = this.setSelectionEnd.bind(this);
    this.setActiveHocketId = this.setActiveHocketId.bind(this);
    this.setActiveCommentHocket = this.setActiveCommentHocket.bind(this);
    this.toggleJuniper = this.toggleJuniper.bind(this);
    this.splitActiveCellRequest = this.splitActiveCellRequest.bind(this);
    this.createHocket = this.createHocket.bind(this);
    this.setMousetrap = this.setMousetrap.bind(this);
    this.state = {
      manualScroll: navigator.vendor === "Apple Computer, Inc.",
      hockets: {},
      lesson: null,
      instructors: {},
      tas: {},
      exportFormat: 'HTML',
      activeHocketId: null,
      hocketSelectionRange: null,
      showHelp: false,
      showSettings: false,
      showOutline: false,
      locked: false,
      reOrder: false,
      juniperOpen: false,
      juniperHasRendered: false,
      linkClicked: false,
      sharedDisplayMode: false,
      lastBinderPing: new Date(),
      messageLimit: MESSAGE_RENDER_MAX,
      activeCommentHocket: null,
      showComments: true,
      lectureDate: null,
    };
  }

  componentDidMount() {
    this.subLesson(this.props);
    this.subComments(this.props);
    this.subSettings();
    this.setMousetrap();
    this.setActiveHocketId(null, true); // true means do update db
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
  }

  hocketIndex(hocketId) {
    const { lesson } = this.state;
    const { hockets=[] } = lesson;
    return hockets.findIndex(hocket => hocket.hocketId === hocketId);
  }

  adjustActiveHocket(increment) {
    const { activeHocketId, lesson } = this.state;
    const { hockets=[] } = lesson;
    const currentIndex = this.hocketIndex(activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < hockets.length)) {
      this.setActiveHocketId(hockets[newIndex].hocketId);
    }
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }


  adjustSelectionEnd(increment) {
    const { activeHocketId,
            lesson,
            hocketSelectionRange
    } = this.state;
    const { hockets=[] } = lesson;
    const currentIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < hockets.length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  splitActiveCellRequest() {
    const { lesson={}, activeHocketId } = this.state;
    const { hockets=[] } = lesson;
    const idx = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    if (idx === -1) return;
    const delta = JSON.parse(this.state.hockets[hockets[idx].hocketId].responses[0]);
    const newDeltas = splitQuillDeltaOnHorizontalRule(delta);
    confirmAlert({
      title: "Confirm",
      message: `Split current messages into ${newDeltas.length} new messages?`,
      buttons: [
        { label: "Yes", onClick: () => this.splitActiveCell(newDeltas, idx) },
        { label: "No", onClick: () => null },
      ],
    });
  }

  splitActiveCell(newDeltas, idx) {
    const { db, projectId } = this.props;
    const { lesson={} } = this.state;
    const { hockets=[] } = lesson;
    const newHockets = newDeltas.map((delta) => {
      const hocket = Hocket();
      hocket.responses.push(JSON.stringify(delta));
      return peelOffSuggestions(hocket);
    });
    if (newHockets.length > 0) {
      const hocket = this.state.hockets[hockets[idx].hocketId];
      // this is important for preserving comments when messages are split:
      newHockets[0].id = hockets[idx].hocketId;
      // this is for preserving interactive figures:
      const end = newHockets.length - 1;
      newHockets[end].jessieCode = hocket.jessieCode;
      newHockets[end].aspectRatio = hocket.aspectRatio;
      if (hocket.suggestions) {
        newHockets[end].suggestions = hocket.suggestions;
      }
      if (hocket.fabric) {
        newHockets[end].fabric = hocket.fabric;
      }
    }
    const batch = db.batch();
    newHockets.forEach( (hocket, idx) => {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocket.id),
          hocket,
      );
    });
    batch
      .commit()
      .then( () => {
        const newHocketStubs = newHockets.map(
          (hocket) => ({ hocketId: hocket.id })
        );
        hockets.splice(idx, 1, ...newHocketStubs);
        this.lessonRef.set({ hockets }, {merge: true});
      })
      .then( () => {
        this.setState({ activeHocketId: null });
      })
      .catch(console.error);
  }

  toggleReorder() {
    this.setState({ reOrder: !this.state.reOrder });
  }

  setMousetrap() {
    Mousetrap.bind("down", () => this.adjustActiveHocket(1));
    Mousetrap.bind("up", () => this.adjustActiveHocket(-1));
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+s", () => this.splitActiveCellRequest());
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind("shift+=", () => this.createHocket());
    Mousetrap.bind("ctrl+j", () => this.toggleJuniper());
    Mousetrap.bind("shift+r", () => this.toggleReorder());
    Mousetrap.bind(["del", "backspace"], () => {
      this.deleteMessages();
    });
    Mousetrap.bind("esc", () => {
      if (this.state.locked) this.setState({ activeHocketId: null });
      this.setState({
        hocketSelectionRange: null,
        showHelp: false,
        showSettings: false,
        activeCommentHocket: null,
      });
    });
    Mousetrap.bind('enter', () => {
      if (!this.state.reOrder || !this.state.activeHocketId) return;
      this.triggerClassroomSend();
    });
    Mousetrap.bind("shift+m", () => this.convertEditorContents());
    Mousetrap.bind(["mod+/", "shift+/"], () => this.toggleHelp());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  exitHelpOrSettings() {
    this.setState({ showHelp: false, showSettings: false, showOutline: false });
  }

  toggleHelp() {
    const { showHelp } = this.state;
    this.setState({ showHelp: !showHelp});
  }

  toggleSettings() {
    const { showSettings } = this.state;
    this.setState({ showSettings: !showSettings, linkClicked: false });
  }

  setCodeCell(language) {
    this.lessonRef.set({ codeCell: language }, {merge: true});
  }

  setDisplayMode(setting) {
    this.lessonRef.set({ sharedDisplayMode: setting }, {merge: true});
  }

  handleLectureDateChange(date) {
    const { db, lessonId, projectId } = this.props;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId)
      .set({ lectureDate: date.toDate() }, {merge:true})
      .catch(console.error);
  }

  renderOutline() {
    const { lesson, hockets } = this.state;
    const hocketSequence = lesson.hockets
      .map(hocketStub => hockets[hocketStub.hocketId])
      .filter(Boolean)
    const { outline } = getOutline(hocketSequence);
    return <div className="help-info">
      <ul>
        { outline.map( heading => {
          return <li key={heading.id} className={"cursor h" + heading.depth} onClick={() => {
            this.setState({ messageLimit: Math.max(heading.index + 0.5*MESSAGE_RENDER_MAX, MESSAGE_RENDER_MAX) });
            this.exitHelpOrSettings();
            tryUntilSuccess( () => {
              const element = document.getElementById(heading.id);
              if (element && this.scroller) {
                this.scroller.center(element);
                return isInViewport(element);
              }
            });
          }}>
            { heading.title } <span className="message-number">({heading.index + 1})</span>
          </li>
        }) }
  </ul>
    </div>;
  }

  renderSettings() {
    const { lesson={}, linkClicked, lectureDate } = this.state;
    if (!lesson) return null;
    const { sharedLessonId, shared, youTubeId,
            title='', description=''} = lesson;
    const sharedLessonUrl = "https://prismia.chat/shared/" + sharedLessonId;
    return (
      <ThemeProvider theme={ settingsTheme }>
        <div className="help-info">
          <h1>Settings</h1>
          <h2>Title and Description</h2>
          <div className="save-title-description-container">
            <Input
              key={ 'title-' + lesson.id }
              fullWidth
              className="lesson-title"
              placeholder="Lesson Title"
              value={ title || '' }
              onChange={ (e) => this.nameLesson(e.target.value) } />
            <Input
              key={ 'description-' + lesson.id }
              fullWidth
              className="lesson-description"
              placeholder="description"
              value={ description || '' }
              onChange={ (e) => this.describeLesson(e.target.value) } />
          </div>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.saveLessonDetails() }>
              Save Title and Description
            </Button>
          </div>
          <h2>Sharing</h2>
          <div className="title-button-container">
            <Tooltip
            title={ <div>Create or update sharable <br/> public view of lesson </div> }
            enterDelay={ 600 }>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                className="save-title-description"
                onClick={() => this.shareLesson()}
                >
                Share Lesson
              </Button>
            </Tooltip>
          </div>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={ <Tooltip
              title="Readers see all messages at once when they visit the public page, rather than being able to step through them one at a time"
              enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={this.state.sharedDisplayMode} color="default" onChange={(e) => this.setDisplayMode(!this.state.sharedDisplayMode)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
            label="Static Lesson"/>
          </FormGroup>
          { shared ? <p style={{marginLeft: "20px", marginTop: "0px"}}>
          <strong>
            <a href={ "/shared/" + sharedLessonId } style={{color: "rgb(255, 255, 255, 0.9)"}}>
              { sharedLessonUrl }
            </a>
          </strong>
          <Tooltip
            title={ linkClicked ? "copied!": "copy link to clipboard"}
            placement="top">
            <Button
              color="primary"
              style={ {marginBottom: "5px", color: 'white'} }
              onClick={ () => {
                copyTextToClipboard(sharedLessonUrl);
                this.setState({ linkClicked: true });
              } }>
              { linkClicked ? <CheckIcon/> : <FileCopyIcon size="small"/>}
            </Button>
          </Tooltip>
          </p> : null }
          <h2>Lecture Date</h2>
          <ThemeProvider theme={ settingsClockTheme }>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="margin-below" style={{marginLeft: "20px"}}>
                <KeyboardDatePicker
                  id="lecture-date"
                  ampm={ false }
                  value={ lectureDate }
                  onChange={(date) => this.handleLectureDateChange(date)}
                />
              </div>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
          <h2> Message settings </h2>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={ <WhiteCheckbox checked={ !!(this.state.locked) } color="primary" inputProps={{ 'aria-label': 'Lock messages' }} onChange={ e => this.setState({ locked: e.target.checked })}/> }
              label={ "Lock messages" }/>
            <FormControlLabel
              control={ <WhiteCheckbox checked={ !!(this.state.showComments) } color="primary" inputProps={{ 'aria-label': 'Show comments' }} onChange={ e => this.setState({ showComments: e.target.checked })}/> }
              label={ "Show comments" }/>
          </FormGroup>
          <h2>Code cells</h2>
          <FormGroup style={{marginLeft: "20px"}}>
            { Object.keys(binderKernels).map(lang => 
                  <FormControlLabel
                    key={lang + 'checkbox'}
                    control={ <Tooltip
                      title={`Use ${binderKernels[lang]?.name} for code cells in this lesson`}
                      enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={this.state.codeCell === lang} color="default" onChange={(e) => this.setCodeCell(lang)} inputProps={{ 'aria-label': 'code cells' }}/></Tooltip> }
                  label={binderKernels[lang]?.name}/>
                ) }
            <FormControlLabel
              control={ <Tooltip
              title="Don't use code cells in this lesson"
              enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!this.state.codeCell} color="default" onChange={(e) => this.setCodeCell(null)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
            label="none"/>
          </FormGroup>
          <h2>Slides</h2>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.slidesInputRef.current.click() }>
              Import PDF
            </Button>
            <input
              type="file"
              ref={ this.slidesInputRef }
              onChange={ (e) => this.handleSlidesImport(e.target.files[0]) }
              onClick={ e => e.target.value = null }
              multiple={ false }
              style={{opacity: 0, tabIndex: -1, position: "absolute"}}/>
          </div>
          <h2>Jupyter</h2>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.exportJupyterNotebook() }>
              Export
            </Button>
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              style={{marginLeft: "12px"}}
              onClick={ () => this.jupyterInputRef.current.click() }>
              Import
            </Button>
            <input
              type="file"
              ref={ this.jupyterInputRef }
              onChange={ (e) => this.handleJupyterImport(e.target.files[0]) }
              onClick={ e => {e.target.value = null} }
              multiple={ false }
              style={{opacity: 0, tabIndex: -1, position: "absolute"}}/>
          </div>
          <h2>YouTube Video ID</h2>
            <div className="save-title-description-container">
              <Input
                key={ 'title-' + lesson.id }
                fullWidth
                className="lesson-description"
                placeholder="YouTube video ID (z0aojYxjF-A, for example)"
                value={ youTubeId || '' }
                onChange={ (e) => this.saveYouTubeId(e.target.value) } />
            </div>
            <div className="title-button-container">
              <Button
                className="save-title-description"
                size="small"
                color="primary"
                variant="outlined"
                onClick={ () => this.saveLessonDetails() }>
                Save Video ID
              </Button>
            </div>
          <h2>Autoscrolling</h2>
          <FormGroup style={{marginLeft: "20px"}}>
              <FormControlLabel
                control={ <Tooltip
                  title="Toggle autoscrolling on this page"
                  enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!this.state.manualScroll} color="default" onChange={(e) => this.setState({ manualScroll: !this.state.manualScroll })} inputProps={{ 'aria-label': 'Autoscrolling' }}/></Tooltip> }
                label="Render more messages automatically when scroll bar reaches bottom"/>
          </FormGroup>
          <h2>Danger zone</h2>
          <div className="title-button-container">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{marginBottom: "30px"}}
              className="delete-lesson-message-button"
              onClick={ () => this.deleteLesson() }>
              Delete Lesson
            </Button>
          </div>
        </div>
    </ThemeProvider>);
  }

  helpInfo() {
    return (<div className="help-info">
      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move selected message up one</li>
      <li><tt>down</tt> move selected message down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia lessons or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>

      <h1>Emoji features</h1>
      <ul className="documentation-list">
        <li>Sending a message including the text <span role="img" aria-label="silhouette">👥</span> sends students' messages to each other anonymously and allows each student to respond directly to the person whose answer they see. Use <tt>\peer</tt> followed by tab to get the silhouette character.</li>
        <li>Sending a message including the text <span role="img" aria-label="clock">🕔90s</span> sets a 90-second timer (visible to you and to students). Use <tt>\clock</tt> or <tt>\timer</tt> followed by tab to get the clock character.</li>
        <li>Sending a message including the text <span role="img" aria-label="pencil">✏</span> opens each student's drawing tool and sets the image contained in the message as the background image. Use <tt>\draw</tt> followed by tab to get the pencil character.</li>
        <li>Sending a message including the text <span role="img" aria-label="registered">®</span> marks the question as required, meaning that it will be tracked as an open response question on the <em>Metrics</em> page. Use <tt>\RR</tt> or <tt>\required</tt> followed by tab to get the registered symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="pushpin">📌</span> will pin the message to the top of each student's window. Use <tt>\pin</tt> followed by tab to get the pushpin character.</li>
        <li>Sending a message including the text <span role="img" aria-label="recycle">♻</span> will clear every student's pinned messages. Use <tt>\clear</tt> followed by tab to get the recycling symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="otimes">⊗</span> will remove one pinned message. The character can be included more than once to remove multiple pinned messages. Use <tt>\unpin</tt> followed by tab to get the <span role="img" aria-label="otimes">⊗</span> symbol.</li>
      </ul>
    </div>);
  }

  unsetMousetrap() {
    Mousetrap.unbind("down");
    Mousetrap.unbind("up");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("shift+s");
    Mousetrap.unbind("shift+r");
    Mousetrap.unbind("shift+=");
    Mousetrap.unbind("del");
    Mousetrap.unbind("backspace");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("shift+/");
    Mousetrap.unbind("mod+/");
    Mousetrap.unbind("ctrl+j");
  }

  convertEditorContents() {
    if (!this.mainQuillRef) return;
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);
    const editor = this.mainQuillRef.current.editor;
    const text = toPlaintext(editor.getContents());
    const ops = text.split("\n---\n").map(cellText => markdownToDelta(cellText).concat([{insert: {hr: true}}]));
    editor.setContents({ops: flatten(ops).slice(0, -1)});
    setTimeout( () => this.mainQuillRef.current.focus(), 50 );
  }

  subComments(props) {
    if (this.unsub.comments) this.unsub.comments();
    const { db, projectId, lessonId } = props;
    this.commentRef = db
      .collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId)
      .collection('comments');
    this.unsub.comments = this.commentRef
      .where('archived', '==', false)
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const docs = snap.docs.map(doc => doc.data());
        const comments = {};
        for (let doc of docs) {
           if (comments[doc.parentId]) {
             comments[doc.parentId].push(doc);
           } else {
             comments[doc.parentId] = [doc];
           }
        }
        this.setState({ comments });
      });
  }

  subLesson(props) {
    if (this.unsub.lesson) this.unsub.lesson();
    const { db, currentUser, projectId, lessonId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.lessonRef =  db
      .collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId);
    this.unsub.lesson = this.lessonRef
      .onSnapshot(snap => {
        const lesson = snap.data();
        if (!lesson) return;
        const { hockets=[] } = lesson;
        for (let i = 0; i < hockets.length; i++) {
          if (this.unsub[hockets[i].hocketId]) continue;
          this.subHocket(hockets[i].hocketId);
        }
        this.setState({ lesson });
        this.setState({ codeCell: lesson.codeCell || null });
        this.setState({ lectureDate: lesson.lectureDate?.toDate() || null })
        this.setState({ sharedDisplayMode: lesson.sharedDisplayMode || null });
      });
  }

  subSettings() {
    const { db, projectId, currentUser } = this.props;
    const { activeHocketId, lesson } = this.state;
    if (this.unsub.settings) this.unsub.settings();
    this.unsub.settings = db.collection('projects')
      .doc(projectId)
      .collection('settings')
      .doc(currentUser.id)
      .onSnapshot( snap => {
        const doc = snap.data();
        if (doc && doc.activeHocketId !== activeHocketId && lesson && lesson.hockets && (activeHocketId in lesson.hockets)) {
          this.setActiveHocketId(doc.activeHocketId, false);
        }
      });
  }

  subHocket(hocketId) {
    const { db, projectId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    window.removeEventListener('copy', this.handleCopy);
    window.removeEventListener('paste', this.handlePaste);
    this.unsetMousetrap();
  }

  nameLesson(title='') {
    const { lesson } = this.state;
    lesson.title = title;
    this.setState({ lesson });
  }

  describeLesson(description='') {
    const { lesson } = this.state;
    lesson.description = description;
    this.setState({ lesson });
  }

  saveYouTubeId(id='') {
    const { lesson } = this.state;
    lesson.youTubeId = id;
    this.setState({ lesson });
  }

  saveLessonDetails() {
    const { db, lessonId, projectId } = this.props;
    const { lesson } = this.state;
    const { title, description, youTubeId='' } = lesson;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId)
      .set({ title, description, youTubeId },
           {merge:true})
      .then(() => this.toggleSettings())
      .catch(console.error);
  }

  setActiveHocketId(activeHocketId, dbUpdate=true) {
    const { db, projectId, currentUser, lessonId } = this.props;
    this.setState({ activeHocketId,
                    hocketSelectionRange: null }, () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.blur();
        }
    });
    // update this info on the database, so it can be reflected on the
    // classroom side if desired
    if (dbUpdate) {
    const selectedLessonId = lessonId;
    const lastLessonMessageIndex = activeHocketId === null ? null : this.hocketIndex(activeHocketId);
      setTimeout(() => db.collection('projects')
        .doc(projectId)
        .collection('settings')
        .doc(currentUser.id)
        .set({ selectedLessonId, activeHocketId, lastLessonMessageIndex }, {merge: true}));
    }
  }

  triggerClassroomSend() {
    const { db, projectId, currentUser } = this.props;
    db.collection('projects')
      .doc(projectId)
      .collection('settings')
      .doc(currentUser.id)
      .collection('triggers')
      .doc('triggerClassroomSend')
      .set({triggerClassroomSend: true}, {merge: true});
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, lesson } = this.state;
    const { hockets } = lesson;
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  exportLesson() {
    const { exportedLesson } = this.state;
    this.setState({ exportedLesson: !exportedLesson});
  }

  deleteLesson() {
    const { projectId, router } = this.props;
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete the whole Lesson?",
      buttons: [
        {label: "Yes, delete", onClick: () => {
          this.lessonRef.delete();
          router.history.push("/projects/" + projectId + "/lessons");
        }},
        {label: "No", onClick: () => null},
      ]
    });
  }

  deleteTail() {
    // not for production use; it's just because
    // I accidentally copied a ton of superfluous messages
    // and need to delete them
    let input = window.prompt("enter n:");
    const n = parseFloat(input);
    this.lessonRef.set(
       { hockets: this.state.lesson.hockets.slice(0, n) },
       { merge: true }
    );
  }

  deleteMessages() {
    const {
      lesson={},
      activeHocketId,
      hocketSelectionRange
    } = this.state;
    const { hockets=[] } = lesson;
    if (!activeHocketId) {
      console.log("No hocket selected");
      return null;
    }
    let range, currentIndex;
    if (hocketSelectionRange) {
      range = [...hocketSelectionRange];
    } else {
      currentIndex = hockets.findIndex(hocket => hocket.hocketId === activeHocketId);
      range = [currentIndex, currentIndex];
    }
    range = range.sort((a,b) => a-b);
    const numMessages = range[1] - range[0] + 1;
    const word = numMessages !== 1 ? "messages" : "message";
    confirmAlert({
      title: "Confirm",
      message: `Are you sure you want to delete ${numMessages} ${word}?`,
      buttons: [
        {label: "Yes, delete", onClick: () => {
          let nextHocketId = null;
          if (currentIndex < hockets.length - 1) {
            nextHocketId = hockets[currentIndex + 1].hocketId;
          }
          const remainingHockets = hockets.filter((hocket, i) => {
            return ((i < range[0]) || (i > range[1]));
          });
          this.setState({ activeHocketId: nextHocketId });
          return this.lessonRef.set(
               { hockets: remainingHockets },
               { merge: true }
          );
        }},
        {label: "No", onClick: () => null},
      ]
    });
  }

  handleCopy(event) {
    const { locked, hocketSelectionRange, reOrder } = this.state;
    if (hocketSelectionRange ||
          ((locked || reOrder) && document.activeElement === this.hocketsAreaRef)) {
      let numMessages = 1;
      if (hocketSelectionRange) {
        numMessages = Math.abs(hocketSelectionRange[1] - hocketSelectionRange[0]) + 1;
      }
      const [jsonData, html, md] = this.copyHockets();
      event.clipboardData.setData('application/json',
      jsonData);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', md);
      NotificationManager.info(`${numMessages} message${numMessages === 1 ? "" : "s"} copied!`);
      event.preventDefault();
    }
  }

  handlePaste(event) {
    const clipText = event.clipboardData.getData('application/json');
    if (document.activeElement === this.hocketsAreaRef ||
        document.activeElement.tagName.toLowerCase() === 'body') {
      this.pasteHockets(clipText);
      event.preventDefault();
    }
  }

  exportJupyterNotebook() {
    const { hockets, lesson, codeCell='code' } = this.state;
    exportJupyterNotebook(
      lesson.hockets.map(hocketStub => hockets[hocketStub.hocketId]),
      codeCell,
      lesson.title && lesson.title.length > 0 ? lesson.title : "prismia",
    );
  }

  lessonHockets() {
    const { lesson={}, hockets={} } = this.state;
    let hocketStubs = lesson.hockets;
    return hocketStubs.map(stub => hockets[stub.hocketId]);
  }

  async handleSlidesImport(file) {
    if (!file.name.endsWith('.pdf')) {
      NotificationManager.error('Please upload your slides as a PDF!');
      return;
     } else {
      this.exitHelpOrSettings(); 
      NotificationManager.success('Please wait while your PDF is being converted!');
    }
    const { db, currentUser, projectId, lessonId, storage } = this.props;
    const blobName = await slidesUploader(storage, file);
    const request = {
      id: uuid(),
      authorDisplayName: currentUser.displayName,
      userId: currentUser.id,
      blobName,
      projectId,
      lessonId,
      timestamp: now(),
    }
    db.collection('triggers')
      .doc('image-conversion')
      .collection('requests')
      .doc(request.id)
      .set(request)
      .catch(console.error);
  }

  handleJupyterImport(file) {
    const { db, projectId } = this.props;
    const { codeCell } = this.state;
    file.text().then(text => {
      const jupyterDocument = JSON.parse(text);
      const prismiaHockets = this.lessonHockets();
      const { hockets, newHockets } = cells2hockets(
        jupyterDocument.cells,
        prismiaHockets,
        codeCell
      );
      const batch = db.batch();
      for (let hocket of hockets) {
        if (newHockets.has(hocket.id)) {
          batch.set(
            db.collection('projects')
              .doc(projectId)
              .collection('hockets')
              .doc(hocket.id),
            hocket
          );
        }
      }
      batch.set(this.lessonRef, { hockets: hockets.map(h => {return {hocketId: h.id};}) }, {merge: true});
      batch.commit().catch(console.error);
    })
    this.setState({ showSettings: false });
  }

  copyHockets() {
    const {
      activeHocketId,
      hockets,
      hocketSelectionRange,
      lesson,
    } = this.state;
    const hocketIds = lesson.hockets;
    const messagesToCopy = []
    let start, stop;
    let html = "";
    let md = "";
    if (hocketSelectionRange) {
      [start, stop] = [...hocketSelectionRange].sort((a,b) => a-b);
      for (let i = start;
               i <= stop;
               i++) {
        const currentHocket = hockets[hocketIds[i].hocketId];
        if (currentHocket && currentHocket.responses.length > 0) {
          const message = currentHocket.responses[0];
          const suggestions = currentHocket.suggestions;
          const jessieCode = currentHocket.jessieCode;
          const tableData = currentHocket.tableData;
          const openResponse = currentHocket.openResponse || null;
          const aspectRatio = currentHocket.aspectRatio || "100%";
          const codeCell = currentHocket.codeCell;
          const urlIFrame = currentHocket.urlIFrame;
          const heightIFrame = currentHocket.heightIFrame;
          messagesToCopy.push({ message, suggestions, openResponse, tableData, jessieCode, aspectRatio, codeCell, urlIFrame, heightIFrame });

          const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
          html += converter.convert();

          md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n---\n\n";
        }
      }
      md = md.slice(0, md.length - 7); // 7 is the length of \n\n---\n\n
    } else {
      const activeHocket = hockets[activeHocketId];
      if (activeHocket && activeHocket.responses.length > 0) {
        const message = activeHocket.responses[0];
        const suggestions = activeHocket.suggestions;
        const openResponse = activeHocket.openResponse || null;
        const jessieCode = activeHocket.jessieCode || '';
        const aspectRatio = activeHocket.aspectRatio || "100%";
        messagesToCopy.push({ message, openResponse, suggestions, jessieCode, aspectRatio });

        const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
        html += converter.convert();

        md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
      }
    }
    return [JSON.stringify(messagesToCopy), html, md];
  }

  pasteHockets(clipText) {
    const { db, projectId } = this.props;
    const { lesson={}, activeHocketId } = this.state;
    const { hockets=[] } = lesson;
    let messages;
    try {
      console.log(clipText);
      messages = JSON.parse(clipText);
      console.log(messages);
    } catch (err) {
      console.error("Only messages from other Prismia lessons can be pasted");
      return
    }
    messages
      .forEach( (message, loopCounter) => {
        const hocket = Hocket();
        if (!('message' in message) ||
            !isValidDelta(message.message) ||
            !('suggestions' in message)) {
          console.error("Only messages from other Prismia lessons can be pasted");
          return;
        }
        hocket.responses.push(message.message);
        hocket.suggestions = message.suggestions;
        if (message.openResponse) hocket.openResponse = message.openResponse;
        hocket.jessieCode = message.jessieCode || '';
        hocket.aspectRatio = message.aspectRatio || '';
        hocket.codeCell = message.codeCell || false;
        hocket.tableData = message.tableData || '';
        hocket.urlIFrame = message.urlIFrame || '';
        hocket.heightIFrame = message.heightIFrame || 0;
        const hocketStub = {
          hocketId: hocket.id,
        };
        const idx = (activeHocketId ?
            hockets.findIndex(
              hocket => hocket.hocketId === activeHocketId
            ) : hockets.length - 1);
        hockets.splice(idx + loopCounter + 1, 0, hocketStub);
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocket.id)
          .set(hocket, {merge: true})
          .catch(console.error);
      });
      this.lessonRef
        .set({ hockets }, { merge: true });
    }

  createHocket() {
    const { db, projectId } = this.props;
    const { lesson={}, activeHocketId } = this.state;
    const { hockets=[] } = lesson;
    const hocket = Hocket();
    const hocketStub = {
      hocketId: hocket.id,
    };
    const idx = (activeHocketId ?
        hockets.findIndex(
          hocket => hocket.hocketId === activeHocketId
        ) : hockets.length - 1);
    hockets.splice(idx + 1, 0, hocketStub);
    db.collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.lessonRef
          .set({ hockets }, { merge: true });
      }).catch(console.error);
    {
      // if we don't manually set the state as well,
      // then the createHocket button doesn't
      // work after the page loses its connection
      // to the snapshot
      const { hockets } = this.state;
      hockets[hocket.id] = hocket;
      this.setState({ hockets });
    }
    this.pingBinderKernel();
  }

  onDragEnd(res) {
    const { db, projectId, lessonId } = this.props;
    const { destination, source } = res;
    if (!destination || !source) return;
    const { lesson={} } = this.state;
    const { hockets=[] } = lesson;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const hocketStub = hockets.splice(sourceIndex, 1)[0];
    hockets.splice(destinationIndex, 0, hocketStub);
    lesson.hockets = hockets;
    this.setState({ lesson });
    manuallySortLessonMessage();
    db
      .collection('projects')
      .doc(projectId)
      .collection('lessons')
      .doc(lessonId)
      .set(lesson, {merge:true});
  }

  handleScroll(event) {
    if (this.state.manualScroll) return;
    const node = event.target;
    const { messageLimit, lesson } = this.state;
    const scrollTopFraction = node.scrollTop / node.scrollHeight;
    const scrollBottomFraction = (node.scrollTop + node.clientHeight) / node.scrollHeight;
    if (scrollBottomFraction > 0.85) {
      const numMessages = lesson.hockets.length;
      this.setState({
        messageLimit: Math.min(numMessages, messageLimit + MESSAGE_RENDER_STEP)
      });
    } else if (scrollTopFraction === 0 && messageLimit - MESSAGE_RENDER_MAX > 0) {
      this.setState({ messageLimit: MESSAGE_RENDER_MAX });
    } else if (scrollTopFraction < 0.15 && messageLimit - MESSAGE_RENDER_MAX > 0) {
      this.setState({ messageLimit: messageLimit - MESSAGE_RENDER_STEP });
    }
  }

  paneDidMount(node) {
    if (node && !this.state.manualScroll) {
      this.unsub.scrollListener = () => {
        node.removeEventListener("scroll", this.handleScroll);
      }
      node.addEventListener("scroll", this.handleScroll);
      const defaultDuration = 600;
      const edgeOffset = 100;
      this.scroller = zenscroll.createScroller(node, defaultDuration, edgeOffset);
    }
  }

  renderLessonArea(width) {
    const classNames = width < mobileThreshold ? "border-top" : "";
    const { lesson={} } = this.state;
    if (!lesson) return null;
    const { title='', description='' } = lesson;
    return (
      <div
        ref={ node => {
          this.messagesAreaRef = node;
          this.paneDidMount(node);
        }}
        className={"y-scrollable x-contained " + classNames}>
        <h1
          className="lesson-title centered"
          onClick={() => this.toggleSettings()}>
          { title || '' }
        </h1>
        <div
          className="centered description-text"
          onClick={() => this.toggleSettings()}>
          { description || '' }
        </div>
        { this.renderHocketsArea(width) }
      </div>
    );
  }

  isInSelectionRange(hocketId) {
    const { lesson, hocketSelectionRange } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = lesson;
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  setActiveCommentHocket(id) {
    this.setState({ activeCommentHocket: id })
  }

  shareLesson() {
    const { db, projectId, currentUser } = this.props;
    const { 
      hockets, lesson, codeCell, 
      sharedDisplayMode, lectureDate,
    } = this.state;
    const { description, title, id, youTubeId='' } = lesson;
    const hocketIds = lesson.hockets;
    let { sharedLessonId=null } = lesson;

    if (Object.keys(hockets).length === 0) {
      NotificationManager.error("Add at least one message to share the lesson");
      return;
    }

    const lessonRef = db.collection('projects')
                        .doc(projectId)
                        .collection('lessons')
                        .doc(id);
    lessonRef.set({ shared: true }, { merge: true });

    // Deal with old lessons that don't have
    // sharedLessonId fields (by adding one):
    if (!sharedLessonId) {
      sharedLessonId = shortUID();
      lessonRef.set({ sharedLessonId }, { merge: true });
    }
    //-----------------------------
    const newHockets = [];
    for (let idObj of hocketIds) {
      const id = idObj.hocketId;
      if (!hockets[id]) {
        NotificationManager.warning("Still loading data. Try again in a few seconds");
        return;
      }
      let hocket = Hocket([], hockets[id].responses);
      //hocket.parentLesson = sharedLessonId;
      hocket.id = id;
      if (hockets[id].suggestions) {
        hocket.suggestions = [...hockets[id].suggestions];
      }
      if (hockets[id].jessieCode) {
        hocket.jessieCode = hockets[id].jessieCode;
      }
      if (hockets[id].codeCell) {
        hocket.codeCell = hockets[id].codeCell;
      }
      if (hockets[id].tableData) {
        hocket.tableData = hockets[id].tableData;
      }
      if (hockets[id].aspectRatio) {
        hocket.aspectRatio = hockets[id].aspectRatio;
      }
      if (hockets[id].openResponse) {
        hocket.openResponse = hockets[id].openResponse;
      }
      if (hockets[id].note) {
        hocket.note = hockets[id].note;
      }
      if (hockets[id].fabric) {
        hocket.fabric = hockets[id].fabric;
      }
      newHockets.push(hocket);
    }
    const chunks = chunk(newHockets, 100);
    let hocketpromises = [];
    for (let hocket_chunk of chunks) {
      const batch = db.batch();
      hocket_chunk.forEach( (hocket) =>
        batch.set(db.collection('projects')
        .doc(projectId)
        .collection('shared-hockets')
        .doc(hocket.id),
          hocket
        )
      );
      hocketpromises.push(batch.commit());
    }
    const { outline, minHeaderDepth } = getOutline(newHockets.filter(hocket => !isNote(hocket)));
    const batch = db.batch();
    const newHocketIds = newHockets.map(hocket => {
      const hocketStub = { hocketId: hocket.id };
      if (hocket.note) {
        hocketStub.note = true;
      }
      return hocketStub;
    });
    const creator = currentUser || {};
    const newLesson = SharedLesson(
      title,
      description,
      youTubeId,
      creator,
      projectId,
      newHocketIds,
      sharedLessonId,
      codeCell,
      sharedDisplayMode,
      lectureDate,
      outline,
      minHeaderDepth,
    );
    batch.set(db.collection('projects')
      .doc(projectId)
      .collection('shared-lessons')
      .doc(newLesson.id),
      newLesson);
    batch.set(db.collection('meta')
      .doc('lessons-table')
      .collection('ids')
      .doc(newLesson.id),
      { id: newLesson.id, project: projectId }, { merge: true });
    Promise.all(hocketpromises).then(() => {
      return batch.commit();
    }).then( () => {
      NotificationManager.success("Lesson shared!")
    }).catch(console.error);
  }

  toggleJuniper() {
    const { juniperOpen } = this.state;
    this.setState({ juniperOpen: !juniperOpen }, () => {
      //if (juniperOpen) this.juniperRef.current.codeMirror.focus();
    });
  }

  renderJuniper() {
    const { 
      juniperOpen, 
      juniperHasRendered, 
      lang,
      codeCell 
    } = this.state;
    const style = {};
    if (!juniperOpen && !juniperHasRendered) return null;
    if (!juniperHasRendered) this.setState({ juniperHasRendered: true });
    if (!juniperOpen) style.display = "none";
    return <div className="juniper-container" style={ style }>
      <JupyterCell
        ref={ this.juniperRef }
        language={ lang || codeCell }
        setLang={ (lang) => this.setLang(lang) }/>
    </div>;
  }

  setLang(lang) {
    this.setState({ lang });
  }

  renderHocketsArea(width) {
    const { lesson={}, hockets={}, messageLimit } = this.state;
    let hocketStubs = lesson.hockets;
    const numMessages = hocketStubs.length;
    const hocketCards = [];
    const start = Math.max(0, messageLimit - MESSAGE_RENDER_MAX);
    const stop = Math.min(messageLimit, hocketStubs.length);
    for (let i = start; i < stop; i++) {
      const id = hocketStubs[i].hocketId;
      hocketCards.push(
        <MessageCard
          key={id}
          hocket={hockets[id]}
          index={i}
          width={width}
          id={id}
          db={ this.props.db }
          setLang={ (lang) => this.setLang(lang) }
          projectId={ this.props.projectId }
          currentUser={ this.props.currentUser }
          storage={ this.props.storage }
          activeHocketId={ this.state.activeHocketId }
          codeCell={ this.state.lang || this.state.codeCell }
          comments={this.state.comments}
          showComments={this.state.showComments}
          commentRef={this.commentRef}
          activeCommentHocket={this.state.activeCommentHocket}
          setActiveCommentHocket={this.setActiveCommentHocket}
          reOrder={ this.state.reOrder }
          inSelectionRange={ this.isInSelectionRange(id) }
          setSelectionEnd={ this.setSelectionEnd }
          setActiveHocketId={ this.setActiveHocketId }
          deleteMessages={ this.deleteMessages }
          mainQuillRef={ this.mainQuillRef }
          toggleJuniper={ this.toggleJuniper }
          splitActiveCell={ this.splitActiveCellRequest }
          createHocket={ this.createHocket }
          setMousetrap={ this.setMousetrap }/>
      );
    }
    return (
      <div
        className="hockets-area"
        tabIndex={ -1 }
        ref={ (node) => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
        <p className="centered" style={{color: "#AAA"}}>
          { lesson.hockets.length + " message" + (hocketCards.length === 1 ? "" : "s") }
        </p>
        {this.state.manualScroll && messageLimit > MESSAGE_RENDER_MAX ? <Tooltip
          title="Show earlier messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px",
              marginTop: "30px",
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.max(MESSAGE_RENDER_MAX, messageLimit - MESSAGE_RENDER_STEP)
              });
            }}
            >
            Earlier Messages
          </Button>
        </Tooltip> : null }
        <DragDropContext
          onDragEnd={res => this.onDragEnd(res)}>
          <Droppable
            droppableId={'list'}
            key={ 'droppable' }>
          { (provided, snapshot) => (
            <div
              className="pad-bottom"
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.innerProps}
                >
            { hocketCards }
            {provided.placeholder}
            </div>
          )}
          </Droppable>
        </DragDropContext>
        {this.state.manualScroll && messageLimit < numMessages ? <Tooltip
          title="Show more messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px"
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.min(numMessages, messageLimit + MESSAGE_RENDER_STEP)
              });
            }}
            >
            More Messages...
          </Button>
        </Tooltip> : null }
        <Tooltip
          title="Add a new message after the currently selected message [shift +]"
          enterDelay={ 500 }>
          <Button
            fullWidth
            variant="outlined"
            className="add-hocket-button"
            onClick={() => this.createHocket()}
            >
            +
          </Button>
        </Tooltip>
      </div>
    );
  }

  render() {
    updateTitleBar('Lessons');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings, showOutline, codeCell, reOrder } = this.state;
    const classes = showHelp || showSettings ? "full-height blur" : "full-height";
    const tools = [{
        icon: <HelpOutlineOutlinedIcon/>,
        onClick: () => this.setState({ showHelp : true }),
        tooltipTitle: "Open help screen",
        disabled: false,
        hide: false,
      }, {
        icon: <BookmarkBorderIcon/>, 
        onClick: () => this.setState({ showOutline: true }),
        tooltipTitle: 'Outline',
        disabled: false,
        hide: false,
      }, {
        icon: <SettingsIcon/>,
        onClick: () => this.setState({ showSettings: true }),
        tooltipTitle: "Settings",
        disabled: false,
        hide: false,
      }, {
        icon: reOrder ? <ReorderIcon style={{color: "orange"}}/> : <ReorderIcon/>,
        onClick: () => this.setState({ reOrder: !reOrder }),
        tooltipTitle: reOrder ? "Done reordering [shift r]" : "Reorder messages [shift r]",
        disabled: false,
        hide: false,
      }, {
        icon: <CodeIcon/>,
        onClick: () => this.toggleJuniper(),
        tooltipTitle: "Toggle code cell",
        disabled: false,
        hide: !codeCell,
      }, {
        icon: this.state.locked ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>,
        onClick: () => this.setState({locked: !this.state.locked}),
        tooltipTitle: this.state.locked ? "Unlock messages" : "Lock messages",
        disabled: false,
        hide: !this.state.locked,
      },
    ];
    const navHeight = (width) => 62 + (width < mobileThreshold ? 48 : 0);
    const table = width => (<div className={ classes }>
      <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
      <div className="flow-root">
        <SidebarButtonPanel
        mobile={ width < mobileThreshold }
        tools={ tools }/>
      </div>
      <div style={{position: "relative", height: `calc(100% - ${navHeight(width)}px)`}}>
        { this.renderLessonArea(width) }
        { this.renderJuniper() }
      </div>
    </div>);
    const maskCover = (showHelp || showSettings || showOutline) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    const outlineCard = showOutline ? this.renderOutline() : null;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({ width }) => {
            return <div className="lesson-view">
              { maskCover }
              { helpCard || settingsCard || outlineCard }
              { table(width) }
              <NotificationContainer/>
            </div>;
        }}
      </ReactResizeDetector>
    );
  }

}

export default LessonView;
