import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ImageUploader from 'react-images-upload';
import { randomProfilePic } from '../profile-emojis';
import './style.css';
const uuid = require('uuid/v4');

class ProfileData extends Component {
  
  constructor(props) { 
    super(props)
    this.state = {
      displayName: '',
      studentId: '',
      photoUrl: null
    }
    this.onDrop = this.onDrop.bind(this);
    this.genNewPhoto = this.genNewPhoto.bind(this);
  }
  
  componentDidMount() {
    const { currentUser={} } = this.props;
    const { id, displayName, studentId, photoUrl } = currentUser;
    this.setState({ id, displayName, studentId, photoUrl });
    if (!photoUrl) {
      this.genNewPhoto();
    }
  }
 
  submit() {
    const { updateUser, router } = this.props;
    const { id, displayName, studentId, photoUrl } = this.state;
    if (displayName === '') {
      window.confirm('Please enter a display name')
      return;
    }
    const user = { uid: id, displayName, studentId, photoUrl };
    console.log(user);
    return updateUser(user, true).then(
      () => router.history.push("/chat")
    );
  }
  
  onDrop(picture) {
    const { storage } = this.props;
    if (picture.length === 0) {
      window.confirm('The file should be less than 250 kilobytes, and the extension should be jpg, jpeg, gif, png, gif, or svg');
      return;
    }
    const fileNameParts = picture[0].name.split(".");
    const ext = fileNameParts[fileNameParts.length - 1];
    const imageId = uuid();
    const imageRef = storage
      .ref()
      .child('images')
      .child(imageId + '.' + ext);
    imageRef.put(picture[0])
      .then( () => {
        imageRef.getDownloadURL().then( (url) => {
          this.setState({
            photoUrl: url
          });
        });
      })
      .catch(console.error);
  }
  
  genNewPhoto() {
    const { storage } = this.props;
    storage
      .ref()
      .child('profile-emojis')
      .child(randomProfilePic())
      .getDownloadURL()
      .then( (url) => {
        this.setState({ photoUrl: url });
      })
      .catch(console.error);
  }
 
  render() {
    const { displayName, photoUrl, studentId } = this.state;
    const style = {
      opacity: 0,
      fontWeight: '400',
      textAlign: 'center',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    return (
      <div style={ style } className="profile-view">
        <img className="logo-image" src="/prismia-logo-simple.svg" alt="logo"/>
        <TextField 
          fullWidth
          key='display-name-input'
          value={ displayName || '' }
          className='email-input'
          variant="outlined"
          placeholder="Display name..."
          onChange = { (e) => {
            this.setState({displayName: e.target.value});
          }}
          />
        <TextField 
          fullWidth
          key='student-id-input'
          value={ studentId || '' }
          className='email-input'
          variant="outlined"
          placeholder="Student ID..."
          onChange = { (e) => {
            this.setState({studentId: e.target.value});
          }}
          />
          <div className="profile-container">
            {photoUrl ? <img className="profile-pic" alt='profile' src={ photoUrl }/> : null}
            <div className="fileUploader profile-button new-profile-button"><div className="fileContainer"><div className="errorsContainer"></div><button type="button" onClick={ this.genNewPhoto }className="chooseFileButton ">New</button></div></div>
            <ImageUploader
              className="profile-button"
              withIcon={ false }
              withLabel={ false }
              buttonText='Upload'
              onChange={ picture => this.onDrop(picture) }
              imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif', '.svg']}
              maxFileSize={250000}
            />
          </div>
        <Button
          className='submit-button'
          variant='outlined'
          onClick={ () => this.submit() }>
          Save
        </Button>
      </div>
    );
  }

}

export default ProfileData;
