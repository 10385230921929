import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import toPlaintext from 'quill-delta-to-plaintext';
import handlebars from 'handlebars';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import StarIcon from '@material-ui/icons/Star';
import CheckIcon from '@material-ui/icons/Check';
import EmailIcon from '@material-ui/icons/Email';
import 'react-quill/dist/quill.snow.css';
import Hocket from '../hocket';
import ReadOnlyQuill from '../ReadOnlyQuill';
import { serializeHocket } from '../serialization';
import { now, removeTerminalNewlinesFromQuillDelta, blockQuote} from '../utils';
import {
  messageStudent,
  openMicroChat
} from '../analytics';
import { sparseFormQuillModules } from '../quill-config';
import { safePhotoUrl } from '../profile-emojis';
import { Message } from '../message';
import './style.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const defaultDelta = "{\"ops\":[{\"insert\":\"\\n\"}]}";

function d2html(deltaOps) {

  if (!deltaOps)  return '';
  if (typeof deltaOps === 'string') {
    deltaOps = JSON.parse(deltaOps);
  }
  if (deltaOps.ops) {
    deltaOps = deltaOps.ops;
  }
  const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
  const html = converter.convert();
  return html;
}

/*
function CombinationButton({combine, student}) {
  const classNames = "combine-messages " + (student ? "student-side" : '');
  return (
    <div className={ classNames } >
      <div onClick={ combine } className="combine-button">
        combine
      </div>
    </div>
  );
}
*/

handlebars.registerHelper('either', function(first, second, third) {
  return first || second || third;
});

handlebars.registerHelper('or', () => false);

const ELI_CHAT_ID = '000-0000-000';

function processResponse(quillDelta, blackboard={}) {
  const response = quillDelta.ops.map(insertion => {
    if (typeof insertion.insert !== 'string') return insertion;
    const template = handlebars.compile(insertion.insert);
    insertion.insert = template(blackboard);
    return insertion;
  });
  return { ops: response };
}

class InstructorChatView extends Component {
  constructor(props) {
    super(props);
    this.studentId = null;
    this.messagesAreaRef = null;
    this.keysPressed = {};
    this.chatRef = null;
    this.unsub = {
      chat: null,
      user: null,
    };
    this.state = {
      instructorDriven: false,
      student: null,
      studentId: null,
      hocketId: null,
      messages: {},
      quillDelta: {ops: [{insert: ''}]},
      blockScrolling: 0,
      messageLimit: 8,
    };
  }

  componentDidMount() {
    openMicroChat();
    this.setRefs(this.props);
    this.subChat(this.props);
    //this.setReplyMessage();
  }

  focus() {
    if (this.refs && this.refs.quillRef) {
      this.refs.quillRef.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
  //getDerivedStateFromProps(newProps) {
    if (!newProps.studentId) return console.log('no student id');
    const { studentId, clickedMessage } = this.props;
    if (newProps.studentId !== studentId) {
      this.setRefs(newProps);
    }
    if (newProps.clickedMessage && newProps.clickedMessage !== clickedMessage) {
      const delta = JSON.parse(newProps.clickedMessage.quillDelta);
      this.setState({ quillDelta: blockQuote(delta) }, () => {
        setTimeout( () => {
          if (this.refs && this.refs.quillRef) {
            const endPosition = this.refs.quillRef.editor.getLength() - 1;
            this.refs.quillRef.editor.setSelection(endPosition);
          }
        }, 250);
      });
    }
  }

  checkThenScroll() {
    const { blockScrolling } = this.state;
    if (this.messagesAreaRef && !blockScrolling) {
      this.messagesAreaRef.scrollTop = this.messagesAreaRef.scrollHeight;
    } else {
      this.setState({ blockScrolling: blockScrolling - 1 });
    }
  }

  subChat(props) {
    const { db, studentId, projectId } = props;
    if (this.unsub.chat) this.unsub.chat();
    this.chatRef = db
      .collection('users').doc(studentId)
      .collection('chats').doc(projectId)
      .collection('messages');
    this.unsub.chat = this
      .chatRef
      .orderBy('timestamp', 'desc')
      .limit(this.state.messageLimit)
      .onSnapshot(snap => {
        const messages = {};
        snap.forEach(doc => {
          const m = doc.data();
          if (!(m.sharedFromClass &&
                m.author === studentId) &&
                !(m.recipient === studentId
                  && m.sharedFromClass)) {
            messages[doc.id] = m;
          }
        });
        this.setState({ messages });
        this.focus();
        this.checkThenScroll();
      });
  }

  setRefs(props) {
    if (this.unsub.user) this.unsub.user();
    const { db, currentUser, firestore, studentId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    if (!studentId) return console.log('no student id');
    this.studentId = studentId;
    this.firestore = firestore;
    this.unsub.user = db.collection('users').doc(studentId)
      .onSnapshot(doc => {
        const student = doc.data();
        if (!student) return;
        const { instructorDriven } = student;
        this.setState({ instructorDriven, student, studentId });
      });
  }

  increaseMessageLimit() {
    const { messageLimit } = this.state;
    this.setState({
      messageLimit: messageLimit + 4,
      blockScrolling: 1,
    }, () => {
      this.props.setMessageLimit(messageLimit + 4);
      this.subChat(this.props);
    });
  }

  componentWillUnmount() {
    if (this.unsub.chat) this.unsub.chat();
    if (this.unsub.user) this.unsub.user();
  }

  handleChange(content, delta, source, editor) {
    this.setState({
      quillDelta: editor.getContents(),
    });
  }

  getMostRecentMessage() {
    const { messages } = this.state;
    const messagesList = Object.values(messages).sort( 
      (m1, m2) => m1.timestamp < m2.timestamp ? 1 : -1
    ).reverse();
    let [priorMessage] = messagesList.slice(-1);
    return priorMessage;
  }

  starMessage(message) {
    const { db, projectId } = this.props;
    if (message.starred) return;
    const userId = message.author;
    db.collection('users')
      .doc(userId)
      .collection('chats')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set({ starred: true }, { merge: true });
    db.collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(message.id)
      .set({ starred: true }, { merge: true });
  }

  addMessage(textContent) {
    let { quillDelta, student, messages } = this.state;
    const { currentUser, db, projectId, clickedMessage } = this.props;
    quillDelta = removeTerminalNewlinesFromQuillDelta(quillDelta);
    const userId = ELI_CHAT_ID;
    const mostRecentMessage = this.getMostRecentMessage();
    const message = Message(
      processResponse(quillDelta, {}),
      textContent,
      userId
    );
    message.authorDisplayName = currentUser.displayName;
    message.authorPhotoUrl = currentUser.photoUrl;
    message.instructorVerified = true;
    message.sentFromInstructorChatArea = true;
    message.recipient = student.id;
    messageStudent(message.id);
    this.chatRef.doc(message.id)
      .set(message)
      .then(snap => {
        this.setState({ quillDelta: null });
        if (this.props.removeMessageByIdFromCluster) {
          this.props.removeMessageByIdFromCluster(mostRecentMessage.id);
        }
        //this.setAllUnansweredMessagesAsAnswered();
        return null;
      })
      .then( () => {
        const batch = db.batch();
        Object.values({...messages}).sort( 
          (m1, m2) => m1.timestamp < m2.timestamp ? 1 : -1
          ).slice(0, 8).reverse()
          .filter(message =>
            message.author === student.id)
          .forEach(message => {
            batch.set(
              db.collection('projects')
                .doc(projectId)
                .collection('messages')
                .doc(message.id),
              {respondedTo: true}, {merge: true});
          });
          if (clickedMessage) {
            batch.set(
              db.collection('projects')
                .doc(projectId)
                .collection('messages')
                .doc(clickedMessage.id),
              {respondedTo: true}, {merge: true});
          }
        batch.commit();
      })
      .catch(console.error);
    db.collection('projects')
      .doc(projectId)
      .collection('instructor-responses')
      .doc(message.id)
      .set({
        id: message.id,
        title: null,
        private: true,
        cluster: false,
        intent: clickedMessage ? JSON.stringify(clickedMessage) : "",
        response: JSON.stringify(message),
        authorDisplayName: currentUser.displayName,
        authorId: currentUser.id,
        timestamp: now(),
      })
      .catch(console.error);
  }

  markAnswered(messageId) {
    const { db, projectId } = this.props;
    db
      .collection('projects')
      .doc(projectId)
      .collection('messages')
      .doc(messageId)
      .set({answered: true}, {merge: true});
  }

  setAllUnansweredMessagesAsAnswered() {
    const { db, projectId } = this.props;
    const { messages = [] } = this.state;
    const unanswered = messages.filter(m => !m.answered);
    for (let i = 0; i < unanswered.length; i++) {
      let message = unanswered[i];
      db
        .collection('projects')
        .doc(projectId)
        .collection('messages')
        .doc(message.id)
        .set({answered: true}, {merge: true});
    }
  }

  keyChecker(e) {
    this.keysPressed[e.keyCode] = e.type === 'keydown';
  }

  keyUp(e) {
    this.keyChecker(e);
  }

  keyDown(e) {
    this.keyChecker(e);
    // key 27 is escape
    if (e.keyCode === 27) {
      this.props.buttonCallback();
      return;
    }
    // key 13 is enter
    if (e.keyCode !== 13) return;
    // if we're at this point, we know 13 has been pressed
    // so we check if shift has been pressed too
    if (this.keysPressed[16]) return;
    // if it's just enter and not enter + shift, we add the message
    if (!e.target.textContent.trim()) return;
    const editor = this.refs.quillRef.editor;
    const cursorPosition = editor.getSelection().index;
    editor.deleteText(cursorPosition - 1, 1);
    // trick to force copy of string:
    const textContent = (' ' + e.target.textContent.trim()).slice(1);
    setTimeout( () => {
      this.addMessage(textContent);
    });
  }

  deleteMessage(id) {
    this.chatRef.doc(id).delete();
  }

  addSuggestedMessage(id) {
    this.chatRef
      .doc(id)
      .set({
        responseSuggestion: false,
        instructorVerified: true,
      }, {merge: true}).then(() => {
      }).catch(console.error);
  }

  getMessageBefore(id) {
    let { messages } = this.state;
    if (!messages.length) return;
    if (messages[0].id === id) return;
    let priorMessage = messages[0];
    for (let i = 0; i < messages.length; i++) {
      if (i === 0) continue;
      if (messages[i].id === id) {
        if (priorMessage.author === ELI_CHAT_ID) return;
        return priorMessage;
      }
      priorMessage = messages[i];
    }
  }

  emailMessage(message) {
    const { db, projectId } = this.props;
    const { student } = this.state;
    if (!message) return;
    if (!message.id) return;
    if (!student) return;
    const priorMessage = this.getMessageBefore(message.id);
    let body = "You've received a message from an instructor on Prismia.";
    if (priorMessage) {
      body += "<h2>You:</h2>";
      body += d2html(priorMessage.quillDelta);
    }
    body += "<h2>Your instructor:</h2>";
    body += d2html(message.quillDelta);
    body += "[<a href='https://prismia.chat/chat/" + projectId +  "'>link to chat</a>]";
    const payload = {
      id: message.id,
      studentId: student.id,
      subject: 'Prismia: private message from instructor',
      body,
      timestamp: now(),
    };
    db.collection('triggers')
      .doc('email-student')
      .collection('messages')
      .doc(message.id)
      .set(payload);
    return this.chatRef.doc(message.id)
      .set({hasBeenEmailed: true}, {merge: true});
  }


  createHocket(tfMessage, resMessage) {
    const { db } = this.props;
    const trainingPhrases = [];
    if (tfMessage) trainingPhrases.push(tfMessage.textContent);
    const responses = [];
    if (resMessage) responses.push(JSON.parse(resMessage.quillDelta));
    const hocket = Hocket(trainingPhrases, responses);
    const parentHocketRef = db
      .collection('hockets')
      .doc(hocket.id);
    parentHocketRef.set(serializeHocket(hocket))
      .then(() => {
        if (tfMessage && tfMessage.id) {
          this.chatRef.doc(tfMessage.id)
            .set(
              { parentHocketRef },
              { merge: true });
        }
        if (resMessage && resMessage.id) {
          this.chatRef.doc(resMessage.id)
            .set(
              { parentHocketRef },
              { merge: true });
        }
      }).catch(console.error);
  }

  combineMessages(m1, m2) {
    if (!m1 || !m2) return console.log('missing a message');
    if (!m1.quillDelta || !m2.quillDelta) return console.log('missing a delta');
    //if (m1.parentHocketRef || m2.parentHocketRef) return console.log('no parents allowed');
    // the quill deltas start stringified, so we need to undo that
    let parsedM1Delta = JSON.parse(m1.quillDelta);
    let parsedM2Delta = JSON.parse(m2.quillDelta);
    // then we'll add a newline in the middle.
    parsedM1Delta.ops = parsedM1Delta.ops.concat(JSON.parse(defaultDelta).ops);
    // then add the second message to the first
    parsedM1Delta.ops = parsedM1Delta.ops.concat(parsedM2Delta.ops);
    // get the new plaintext
    m1.textContent = toPlaintext(parsedM1Delta.ops);
    // and re-stringify the results
    m1.quillDelta = JSON.stringify(parsedM1Delta);
    // and finally, save the updated first message
    this.chatRef.doc(m1.id).set(m1, { merge: true })
      .then(() => {
        // and delete the second
        return this.chatRef.doc(m2.id).delete();
      }).catch(console.error);
  }

  addTrainingPhraseToHocket(tfMessage, resMessage) {
    if (!resMessage || !tfMessage) return;
    if (!resMessage.parentHocketRef) return this.createHocket(tfMessage, resMessage);
    // okay with all those guard clauses out of the way, now we can do the thing
    const { db } = this.props;
    const tf = toPlaintext(JSON.parse(tfMessage.quillDelta).ops);
    this.chatRef.doc(tfMessage.id).set(
      { parentHocketRef: resMessage.parentHocketRef}, { merge: true });
    db.collection('hockets').doc(resMessage.parentHocketRef.id)
      .update({ trainingPhrases: firebase.firestore.FieldValue.arrayUnion(tf) });
  }

  renderAddTrainingPhraseToHocket(tfMessage, resMessage, index) {
    const addIcon = (<AddIcon className="add-icon" onClick={ () => this.addTrainingPhraseToHocket(tfMessage, resMessage) } />);
    const checkIcon = (<CheckIcon className="check-icon"/>);
    let connected = false;
    if (tfMessage.parentHocketRef && resMessage.parentHocketRef) {
      connected = tfMessage.parentHocketRef.id === resMessage.parentHocketRef.id;
    }
    return (
      <div className="add-training-phrase-to-hocket-button" key={ index } >
        { connected ? checkIcon : addIcon }
      </div>
    );
  }

  renderMessage(message) {
    let initial = (
        <Tooltip title={ message.authorDisplayName || ''} >
          <span>
            <img className="instructor-bubble" alt="profile" src={ safePhotoUrl(message.authorPhotoUrl) } />
          </span>
        </Tooltip>
    );
    //let deleteMessageButton = null;
    //let hocketBadge = null;
    let initialBubble = null;
    let messageClassBubble = null;
    let starBubble = null;
    let questionBadge = null;
    //let chamberBadge = null;
    let fromStudent = '';
    const className = message.responseSuggestion ? 'suggestion' : null;
    if (message.author === ELI_CHAT_ID) {
      const hocketClassNames = ['bottom-badge', 'hocket-badge'];
      if (message.parentHocketRef) {
        hocketClassNames.push('active');
        //hocketBadge = <div className={ hocketClassNames.join(" ") } onClick={ () => this.displayHocketFormInSidebar(message.parentHocketRef.id) }>H</div>;
      } else {
        hocketClassNames.push('inactive');
        //hocketBadge = <div onClick={() => this.createHocket(null, message)} className={ hocketClassNames.join(" ") }>H</div>;
      }
      if (message.hasBeenEmailed) {
        questionBadge = <div className="bottom-badge question-badge active"><EmailIcon /></div>
      } else {
        questionBadge = (
          <Tooltip title="trigger email notification">
            <div
              className="bottom-badge question-badge inactive"
              onClick={ () => this.emailMessage(message) }>
              <EmailIcon />
            </div>
          </Tooltip>
        );
      }
      if (this.props.shareCb && !message.sharedWithClass) {
        messageClassBubble = (
          <Tooltip title="send message to entire class" aria-label="message class" >
            <IconButton
              onClick={ () => {
                this.setState({ blockScrolling: 3 }, () =>
                  this.props.shareCb(message)
                );
              }}
              className="message-class message-bubble bottom-badge instructor-message"
              ml={ 2 }
              style={ {padding: 0, minWidth: "1em",
                       minHeight: "1em"} }>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        );
      }
      //chamberBadge = <div className="bottom-badge chamber-badge">C</div>;
      fromStudent =  'instructor-message';
      initial = (
        <img className="instructor-bubble" alt="profile" src="/instructor-bubble.svg" />
      );
      if (message.authorPhotoUrl) {
        initial = <img className="instructor-bubble" alt="profile" src={ message.authorPhotoUrl } />
      }
      initialBubble = (
        <div className="message-bubble">
          { initial }
        </div>
      );
      /*
      deleteMessageButton = ( <div className="delete-button" onClick={() => this.deleteMessage(message.id) } >
          <CloseIcon />
        </div>
      );
      */
    } else {
      fromStudent = 'student-message';
      initialBubble = (
        <div className="message-bubble">
          { initial }
        </div>
      );
      if (this.props.shareCb) {
        if (!message.sharedWithClass) {
          messageClassBubble = (
            <Tooltip title="send message to entire class" aria-label="message class" >
              <IconButton
                onClick={ () => {
                  this.setState({ blockScrolling: 3 }, () =>
                    this.props.shareCb(message)
                  );
                }}
                className="message-class message-bubble"
                ml={ 2 }
                style={ {padding: 0, minWidth: "1em",
                         minHeight: "1em"} }>
                <ShareIcon />
              </IconButton>
            </Tooltip>
          );
        }
        starBubble = (
          <Tooltip title="star message" aria-label="star message" >
            <IconButton
              onClick={ () => {
                this.setState({ blockScrolling: 1 }, () => {
                  this.starMessage(message);
                });
              }}
              className="star-message message-bubble"
              ml={ 2 }
              style={ {padding: 0, minWidth: "1em",
                       minHeight: "1em"} }>
              <StarIcon />
            </IconButton>
          </Tooltip>
        );
      }
    }
    let timestamp;
    try {
      timestamp = message.timestamp.toDate();
    } catch (err) {
      // console.log('old timestamp format');
      timestamp = message.timestamp;
    }
    let classList = [];
    if (className) classList.push(className);
    if (fromStudent) classList.push(fromStudent);
    // if (message.textContent.includes('?')) classList.push('student-question-quill');
    if (message.sharedFromClass) classList.push('shared-from-class-quill');
    if (message.sharedWithClass) classList.push('shared-from-class-quill');
    classList = classList.join(' ');
    const starBadge = (
      <img className="star-badge expand-from-center" src="/badge-star.png" alt="star badge"/>
    );
    let timeMessage = moment(timestamp).fromNow();
    if (timeMessage === "Invalid date") {
      timeMessage = "just now";
    }
    return (
      <div key={ message.id } className={ classList }>
        { message.starred ? starBadge : null }
        <p className="message-time-ago">{ timeMessage }</p>
        <div className="quill-wrapper" >
          { <ReadOnlyQuill quillDelta={ JSON.parse(message.quillDelta) } /> }
        </div>
        { initialBubble }
        { this.props.shareCb ? messageClassBubble : null }
        { !message.starred ? starBubble : null }
        { questionBadge }
        { className ? <Button variant="contained" color="primary" onClick={ () => this.addSuggestedMessage(message.id) }>Accept Suggested Message</Button> : null }
        { className ? <Button variant="contained" color="secondary" onClick={ () => this.deleteMessage(message.id) }>Delete</Button> : null }
      </div>
    );
  }

  getPlaceholderString() {
    const { student } = this.state;
    return student ? "Message " + student.displayName + "..." : "";
  }

  render() {
    const { currentUser, classMessages } = this.props;
    const { instructorDriven, messages,
            student } = this.state;
    const allMessages = Object.values({...classMessages, ...messages}).sort( 
      (m1, m2) => m1.timestamp < m2.timestamp ? 1 : -1
    ).slice(0, this.state.messageLimit).reverse();
    // we have to do this to get the placeholder string right:
    if (!student) return <></>;
    //let control = instructorDriven ? 'You are posing as SamBot' : null;
    if (instructorDriven && currentUser.displayName !== instructorDriven) {
      //control = instructorDriven + ' is posing as SamBot';
    }
    const renderedMessages = [];
    let priorMessageIsFromStudent = false;
    //let tfMessage = null;
    //let resMessage = null;
    //let combinationButton = null;
    for (let i = 0; i < allMessages.length; i++) {
      const isEli = allMessages[i].author === ELI_CHAT_ID;
      //resMessage = messages[i];
      if (priorMessageIsFromStudent && isEli) {
        //renderedMessages.push(this.renderAddTrainingPhraseToHocket(tfMessage, resMessage, i));
      } else if (priorMessageIsFromStudent && !isEli) {
        // two student messages
        //combinationButton = <CombinationButton student combine={() => this.combineMessages(messages[i - 1], messages[i]) } key={ i + 'combo' } />;
        //renderedMessages.push(combinationButton);
      } else if (i > 0 && !priorMessageIsFromStudent && isEli) {
        // two Elijah messages
        //combinationButton = <CombinationButton combine={() => this.combineMessages(messages[i - 1], messages[i]) } key={ i + 'combo' } />;
        //renderedMessages.push(combinationButton);
      }
      priorMessageIsFromStudent = allMessages[i].author !== ELI_CHAT_ID;
      if (!allMessages[i]) continue;
      if (!student) continue;
      if (allMessages[i].sharedFromClass && allMessages[i].author === student.id) {
        continue;
      }
      renderedMessages.push(this.renderMessage(allMessages[i]));
      //tfMessage = messages[i];
    }
    let back = null;
    let bottomBack = null;
    if (this.props.buttonCallback) {
      back = (
        <Tooltip
          title="close private chat [esc]"
          enterDelay={ 600 }>
          <span className="back-button">
            <Button
              variant="outlined"
              onClick={ () => this.props.buttonCallback() }>
              Back
            </Button>
          </span>
        </Tooltip>
      );
      bottomBack = (
        <Tooltip
          title="close private chat [esc]"
          enterDelay={ 600 }>
          <Button
            className="tab-focus"
            fullWidth
            variant="contained"
            onClick={ () => this.props.buttonCallback() }>
            Back
          </Button>
        </Tooltip>
      );
    }
    const moreMessagesButton = (
      <Button
        fontSize="small"
        variant="outlined"
        onClick={ () => this.increaseMessageLimit() }>
        More
      </Button>
    );
    let classList = [];
    classList.push('live-chat');
    classList.push('instructor-chat-area');
    classList.push('flex-child');
    classList = classList.join(' ');
    return (
      <div className={ classList } ref={ node => this.messagesAreaRef = node } >
        <div className="flex-container name-and-back-box">
          { back }
          { moreMessagesButton }
        </div>
        { renderedMessages }
        <div className="instructor-chat-input-area">
          <ReactQuill
            value={ this.state.quillDelta }
            ref="quillRef"
            className="tab-focus"
            modules={ sparseFormQuillModules }
            onChange={(...args) => this.handleChange(...args)}
            onKeyUp={ e => this.keyUp(e) }
            onKeyDown={ e => this.keyDown(e) }
            placeholder={ this.getPlaceholderString() }
          />
          <div className="bottom-button-container">
            { bottomBack }
          </div>
        </div>
      </div>
    )
  }

}

export default InstructorChatView;
