import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import handlebars from 'handlebars';
import 'react-quill/dist/quill.snow.css';
import SuggestionSuperList from '../SuggestionSuperList';
import QuillSuperList from '../QuillSuperList';
import { now, hashString} from '../utils';
import { completionChars } from '../constants';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Hocket from '../hocket';
import InteractiveGraph from '../InteractiveGraph';
import CsvTable from '../CsvTable';
import * as Mousetrap from 'mousetrap';


function extractChamberIdFromUpdateString(updateString) {
  // so to start it'll be something like activeChamberId = "abc-234-cvjao"
  // so we'll split it and take the id
  let tmp = updateString.split(" ")[2];
  if (tmp[0] !== '"') return tmp;
  // okay, so now tmp is "abc-234-cvjao" and we just need to remove the quotes
  return tmp.substr(1, tmp.length - 2) || '';
}

function Rule(triggerCriteria=[], hocketRef=null) {
  return {
    hocketRef,
    triggerCriteria,
  }
}

handlebars.registerHelper('either', function(first, second, third) {
  return first || second || third;
});

handlebars.registerHelper('or', () => false);

class MessageForm extends Component {

  constructor(props) {
    super(props);
    this.hocketId = null;
    this.hocketRef = null;
    this.courseRef = null;
    this.keysPressed = {};
    this.keyUp = this.keyUp.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.updateResponses = this.updateResponses.bind(this);
    this.updateSuggestions = this.updateSuggestions.bind(this);
    this.sendHocketMessage = this.sendHocketMessage.bind(this);
    this.clear = this.clear.bind(this);
    this.state = {
      hocket: null,
      tags: [],
      chambers: {},
      chamberExit: false,
      followupHocketIds: [],
      triggerCriteria: [],
      jessieCode: '',
      tableData: '',
      codeCell: false,
      aspectRatio: "100%",
      responses: [],
      suggestions: [],
      trainingPhrases: [],
      blackboardUpdates: [],
      suggestionFieldRef: React.createRef(),
      suggestionField: null,
    };
  }

  componentDidMount() {
    this.setRefs(this.props);
    this.setSuggestionField(this.props);
    this.mounted = true;
    Mousetrap.bind('shift+c', this.clear);
  }

  componentWillUnmount() {
    Mousetrap.unbind('shift+c');
  }

  setSuggestionField(props) {
    const { suggestionFieldRef } = this.state;
    this.setState({ suggestionField:
                    suggestionFieldRef.current });
  }

  componentDidUpdate(prevProps) {
    if (this.hocketId === this.props.hocketId) return;
    this.hocketId = this.props.hocketId;
    this.setRefs(this.props);
    if (prevProps.projectId !== this.props.projectId) {
      this.subCourse(this.props);
    }
  }

  subCourse(props) {
    let { db, projectId } = props;
    this.courseRef = db
      .collection('projects').doc(projectId);
    this.courseRef.get()
      .then(snap => {
        const project = snap.data();
        if (!project) return console.log('no project with id ' + projectId);
        this.setState({ chambers: project.chambers || {} });
      }).catch(console.error);
  }

  setRefs(props) {
    let { db, projectId, hocketId } = props;
    if (!hocketId) return this.bootstrapHocket(props);
    this.hocketRef = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId);
    this.hocketRef.get()
      .then(snap => {
        const hocket = snap.data();
        if (!hocket) return;
        for (let i = 0; i < hocket.responses.length; i++) {
          if (/ops/.test(hocket.responses[i])) continue;
          if (!hocket.responses[i]) continue;
          hocket.responses[i] = JSON.stringify({ops: JSON.parse(hocket.responses[i])});
        }
        let blackboardUpdates, tableData = {string: ''};
        const {
          responses = [],
          jessieCode = '',
          codeCell = false,
          aspectRatio = "100%",
          suggestions = [],
          openResponse,
          followupHocketIds = [],
          trainingPhrases = [],
          triggerCriteria = [],
          fabric,
          heightIFrame,
          urlIFrame,
        } = hocket;
        if (!Array.isArray(hocket.blackboardUpdates)) {
          blackboardUpdates = [];
        } else {
          blackboardUpdates = hocket.blackboardUpdates;
        }
        if (typeof hocket.tableData === 'string') {
          tableData = { string: hocket.tableData };
        } else if (typeof hocket.tableData === 'object') {
          tableData = hocket.tableData;
        }
        let blackboardUpdateActiveChamberId = '';
        for (let i = 0; i < blackboardUpdates.length; i++) {
          if (blackboardUpdates[i].startsWith('activeChamberId = ')) {
            // the problem is that this thing is going to actually be in quotes
            let updateString = blackboardUpdates.splice(i, 1)[0];
            let chamberId = extractChamberIdFromUpdateString(updateString);
            blackboardUpdateActiveChamberId = chamberId || '';
            break
          }
        }
        let triggerCriteriaActiveChamberId = '';
        for (let i = 0; i < triggerCriteria.length; i++) {
          if (triggerCriteria[i].startsWith('activeChamberId == ')) {
            let updateString = triggerCriteria.splice(i, 1)[0];
            let chamberId = extractChamberIdFromUpdateString(updateString);
            triggerCriteriaActiveChamberId = chamberId || '';
            break;
          }
        }
        const chamberExit = blackboardUpdateActiveChamberId === '"null"';
        this.setState({
          hocket,
          blackboardUpdates,
          blackboardUpdateActiveChamberId,
          chamberExit,
          responses,
          followupHocketIds,
          jessieCode,
          tableData,
          codeCell,
          aspectRatio,
          suggestions,
          openResponse,
          trainingPhrases,
          triggerCriteria,
          triggerCriteriaActiveChamberId,
          fabric,
          heightIFrame,
          urlIFrame,
        });
      });
  }

  bootstrapHocket(props) {
    let { hocketId } = props;
    let hocket;
    if (!hocketId) {
      hocket = Hocket();
      hocketId = hocket.id;
    }
    if (!hocket) return;
    for (let i = 0; i < hocket.responses.length; i++) {
      if (/ops/.test(hocket.responses[i])) continue;
      if (!hocket.responses[i]) continue;
      hocket.responses[i] = JSON.stringify({ops: JSON.parse(hocket.responses[i])});
    }
    let blackboardUpdates;
    const {
      responses = [],
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
    } = hocket;
    if (!Array.isArray(hocket.blackboardUpdates)) {
      blackboardUpdates = [];
    } else {
      blackboardUpdates = hocket.blackboardUpdates;
    }
    let blackboardUpdateActiveChamberId = '';
    for (let i = 0; i < blackboardUpdates.length; i++) {
      if (blackboardUpdates[i].startsWith('activeChamberId = ')) {
        // the problem is that this thing is going to actually be in quotes
        let updateString = blackboardUpdates.splice(i, 1)[0];
        let chamberId = extractChamberIdFromUpdateString(updateString);
        blackboardUpdateActiveChamberId = chamberId || '';
        break
      }
    }
    let triggerCriteriaActiveChamberId = '';
    for (let i = 0; i < triggerCriteria.length; i++) {
      if (triggerCriteria[i].startsWith('activeChamberId == ')) {
        let updateString = triggerCriteria.splice(i, 1)[0];
        let chamberId = extractChamberIdFromUpdateString(updateString);
        triggerCriteriaActiveChamberId = chamberId || '';
        break;
      }
    }
    const chamberExit = blackboardUpdateActiveChamberId === '"null"';
    this.setState({
      hocket,
      blackboardUpdates,
      blackboardUpdateActiveChamberId,
      chamberExit,
      responses,
      followupHocketIds,
      suggestions,
      trainingPhrases,
      triggerCriteria,
      triggerCriteriaActiveChamberId,
    });
  }

  updateCriteria(triggerCriteria) {
    // parser's going to need to go here
    this.setState({ triggerCriteria });
  }

  updateTrainingPhrases(trainingPhrases) {
    this.setState({ trainingPhrases });
  }

  updateResponses(responses) {
    // going to need to update this to return quill deltas
    this.setState({ responses });
  }

  updateSuggestions(suggestions) {
    this.setState({ suggestions });
  }

  updateFollowupHocketIds(followupHocketIds) {
    // parser's going to need to go here
    this.setState({ followupHocketIds });
  }

  updateUpdates(blackboardUpdates) {
    // parser's going to need to go here
    this.setState({ blackboardUpdates });
  }

  toggleChamberExit() {
    this.setState({
      blackboardUpdateActiveChamberId: null,
      chamberExit: !this.state.chamberExit
    });
  }

  renderSwitch() {
    const { chamberExit } = this.state;
    const label = chamberExit ? 'Exit Chamber' : 'Exit Chamber';
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={ !!chamberExit }
              onChange={ () => this.toggleChamberExit() }
              />
            }
          label={ label }
        />
      </FormGroup>
    );
  }

  clear() {
    this.setState({
      responses: [],
      suggestions: [],
      openResponse: null,
      jessieCode: '',
      codeCell: false,
      tableData: '',
      fabric: null,
      urlIFrame: '',
      heightIFrame: 0,
    });
  }

  sendHocketMessage() {
    const {
      blackboardUpdates = [],
      hocket,
      responses = [],
      openResponse = null,
      jessieCode = '',
      codeCell = false,
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
      blackboardUpdateActiveChamberId = '',
      triggerCriteriaActiveChamberId = '',
      fabric,
      urlIFrame,
      heightIFrame,
    } = this.state;
    if (!responses.length) return;
    hocket.blackboardUpdates = blackboardUpdates;
    if (blackboardUpdateActiveChamberId) {
      hocket.blackboardUpdates.push('activeChamberId = "' + blackboardUpdateActiveChamberId + '"');
    }
    hocket.responses = responses;
    hocket.jessieCode = jessieCode;
    hocket.codeCell = codeCell;
    hocket.suggestions = suggestions;
    hocket.openResponse = openResponse;
    hocket.fabric = fabric;
    hocket.followupHocketIds = followupHocketIds;
    hocket.trainingPhrases = trainingPhrases;
    hocket.triggerCriteria = triggerCriteria;
    hocket.sentFromMessageEntireClass = true;
    hocket.heightIFrame = heightIFrame;
    hocket.urlIFrame = urlIFrame;
    if (triggerCriteriaActiveChamberId) {
      hocket.triggerCriteria.push('activeChamberId == "' + triggerCriteriaActiveChamberId + '"');
    }
    hocket.timestamp = now();
    if (this.props.sendHocketAsMessage) {
      this.props.sendHocketAsMessage(hocket);
    }
    this.clear();
  }

  markAnswered() {
    if (this.props.markAnswered) {
      this.props.markAnswered();
    }
  }

  saveHocket() {
    const {
      blackboardUpdates = [],
      hocket,
      responses = [],
      suggestions = [],
      followupHocketIds = [],
      trainingPhrases = [],
      triggerCriteria = [],
      blackboardUpdateActiveChamberId = '',
      triggerCriteriaActiveChamberId = '',
    } = this.state;
    hocket.blackboardUpdates = blackboardUpdates;
    if (blackboardUpdateActiveChamberId) {
      hocket.blackboardUpdates.push('activeChamberId = "' + blackboardUpdateActiveChamberId + '"');
    }
    hocket.responses = responses;
    hocket.suggestions = suggestions;
    hocket.followupHocketIds = followupHocketIds;
    hocket.trainingPhrases = trainingPhrases;
    hocket.triggerCriteria = triggerCriteria;
    if (triggerCriteriaActiveChamberId) {
      hocket.triggerCriteria.push('activeChamberId == "' + triggerCriteriaActiveChamberId + '"');
    }
    hocket.timestamp = now();
    const rule = Rule(hocket.triggerCriteria, this.hocketRef);
    this.hocketRef.set(hocket, {merge: true});
    // add toast eventually
    if (triggerCriteria.length) {
      this.props.db
        .collection('projects')
        .doc(this.props.projectId)
        .collection('meta')
        .doc('rules')
        .set(
          {[hocket.id]: rule},
          {merge: true}
        );
    }
  }

  renderTriggerCriteriaChamberSelect() {
    let { chambers, triggerCriteriaActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={triggerCriteriaActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ triggerCriteriaActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }


  renderBlackboardUpdateChamberSelect() {
    let { chambers, blackboardUpdateActiveChamberId } = this.state;
    chambers = Object.keys(chambers).map(id => {
      return (
        <MenuItem key={id} value={id}>{ chambers[id].displayName }</MenuItem>
      );
    });
    chambers.unshift(<MenuItem key="last" value=''><em>None</em></MenuItem>);
    return (
      <Select
        autoWidth
        value={blackboardUpdateActiveChamberId || ''}
        className="full-width"
        onChange={(e) => {
            const val = e.target.value || '';
            this.setState({ blackboardUpdateActiveChamberId: val });
          }
        }
      >
        { chambers }
      </Select>
    );
  }

  handleChange(event) {
    const { value } = event.target;
    const { hocket } = this.state;
    hocket.tags = value;
    this.setState({ hocket });
  };

  renderTags() {
    let { tags, hocket } = this.state;
    if (!tags || !hocket) return null;
    if (!hocket.tags) hocket.tags = [];
    const tagMap = tags.reduce((acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    }, {});
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-mutiple-chip-label">Tags</InputLabel>
        <Select
          id="demo-mutiple-chip"
          multiple
          value={hocket.tags}
          onChange={(e) => this.handleChange(e)}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => {
            if (!selected || !selected.map) return;
            return (
              <div>
                {selected.map(id => {
                  if (!tagMap[id]) return null;
                  return (
                    <Chip key={id} label={tagMap[id].displayName}/>
                  );
                }
                )}
              </div>
            );
            }
          }
        >
          {tags.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderTable(data) {
    return <CsvTable data={ data }/>;
  }

  renderJSXGraph(jessieCode, ratio) {
    return (
      <InteractiveGraph
        key={ 'interactive-graph-' + hashString(jessieCode + ratio) }
        code={ jessieCode }
        width={ "90%" }
        ratio={ ratio || "100%" }/>
    );
  }

  render() {
    if (!this.state.hocket) return null;
    const { projectId, hideSuggestedResponses=false, placeholder=null, mainQuillRef=null, storage=null } = this.props;
    const { suggestionFieldRef } = this.state;
    const checkButton = (
      <Tooltip title="clear column">
        <IconButton
          className="mark-answered-button"
          variant="contained"
          onClick={ () => this.markAnswered() }
          >
          <HighlightOffIcon />
        </IconButton>
      </Tooltip>
    );
    return (
      <div className="">
        <div className="">
          <div>
            <QuillSuperList
              storage={ storage }
              label="Response"
              noToolbar={ false }
              limit={ 1 }
              values={ this.state.responses }
              placeholder={ placeholder }
              quillRef={ mainQuillRef }
              onKeyDown={ this.keyDown }
              onKeyUp={ this.keyUp }
              updateValues={ this.updateResponses }/>
            { (this.state.tableData?.string?.length > 0) ? this.renderTable(this.state.tableData) : null }
            { this.state.jessieCode ? this.renderJSXGraph(this.state.jessieCode, this.state.aspectRatio) : null }
            { hideSuggestedResponses ? null :
              <>
              <p></p>
            <SuggestionSuperList
              label="Suggested response"
              ref = { suggestionFieldRef }
              showHocketField={ false }
              showSwitches={ false }
              limit={ 15 }
              projectId={ projectId }
              db={ this.props.db }
              values={ this.state.suggestions }
              updateValues={ this.updateSuggestions }/>
              </>
            }
          </div>
        </div>
        <div className="button-container">
          <Tooltip title="Send message to everyone in the class [enter]"
            placement="top"
            enterDelay={ 1200 }>
              <Button
              variant="contained"
              style={{marginTop: "-12px"}}
              color="primary"
              size="small"
              onClick={ this.sendHocketMessage }> Send</Button>
          </Tooltip>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{marginLeft: "4px", marginTop: "-12px"}}
            onClick={ this.clear }
            >
           Clear
           </Button>
          { this.props.markAnswered ? checkButton : null }
        </div>
      </div>
    );
  }

  keyChecker(e) {
    this.keysPressed[e.keyCode] = e.type === 'keydown';
  }

  keyUp(e) {
    this.keyChecker(e);
  }

  keyDown(e) {
    const { mainQuillRef } = this.props;
    this.keyChecker(e);
    const tabCompleting = (text) => {
      // this part is weird because of Unicode code-point issues:
      // seems to work OK though
      if (completionChars.has(text.slice(-1)) ||
          completionChars.has(text.slice(-2).trim()) ||
          completionChars.has(text.slice(-3).trim()) ) return true;
      let k = text.length - 2;
      while (k >= 0) {
        if (k < text.length - 1 && text[k] === '\\') {
          return true
        } else if (/[a-zA-Z]/.test(text[k]) || text[k] === ':') {
          k -= 1;
        } else {
          return false;
        }
      }
      return false;
    }
    if (e.keyCode === 27) { // escape key
      mainQuillRef.current.blur();
      return;
    } else if (e.keyCode === 9) { // tab key
      const selection = mainQuillRef.current.editor.getSelection();
      const text = mainQuillRef.current.editor.getText(0, selection.index);
      if (this.keysPressed[16] || tabCompleting(text)) return; // only proceed if not pressing shift
      setTimeout( () => {
        const tabFocusElements = document.getElementsByClassName("suggested-response-tab-focus");
        if (tabFocusElements && tabFocusElements[0] &&
            tabFocusElements[0].childNodes &&
            tabFocusElements[0].childNodes[0]) {
          tabFocusElements[0].childNodes[0].focus();
        }
      }, 3); // 3 milliseconds
      return;
    }
    if (e.keyCode !== 13) return;
    // if we're at this point, we know 13 has been pressed
    // so we check if shift has been pressed too
    if (this.keysPressed[16]) return;
    // if it's just enter and not enter + shift, we add the message
    if (!e.target.textContent.trim()) return;
    const editor = mainQuillRef.current.editor;
    const cursorPosition = editor.getSelection().index;
    editor.deleteText(cursorPosition - 1, 1);
    this.sendHocketMessage();
    this.clear();
  }

}

export default MessageForm;
