import React, { Component } from 'react';
import { Link } from "react-router-dom";
import blackboardUpdates from '../blackboard-updates';
import selectRule from '../select-rule';

function specialKeys(key) {
  // takes a blackboard key and returns a className
  if (key.startsWith('interestedIn')) return 'interest';
  if (key.startsWith('hasSeen')) return 'has-seen';
  const keys = {
    activeChamberId: 'active-chamber-id',
    activeQuestionId: 'active-question-id',
    turn: 'turn',
  };
  return keys[key] || null;
}

class StudentData extends Component {
  constructor(props) {
    super(props);
    this.unsub = {
      user: null,
    };
    this.state = {
      blackboardUpdate: '',
      blackboard: {},
      student: null,
    };
  }
  
  componentDidMount() {
    this.setRefs(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
  //getDerivedStateFromProps(newProps) {
    if (this.props.studentId === newProps.studentId) return;
    this.setRefs(newProps);
  }

  componentWillUnmount() {
    if (this.unsub.user) this.unsub.user();
  }
 
  setRefs(props) {
    const { db, projectId, studentId } = props;
    if (this.unsub.user) this.unsub.user();
    this.unsub.user = db.collection('users').doc(studentId)
      .onSnapshot(doc => {
        const student = doc.data();
        this.setState({ student });
      });
    this.bbRef = db
      .collection('users')
      .doc(studentId)
      .collection('blackboards')
      .doc(projectId);
    db
      .collection('projects')
      .doc(projectId)
      .collection('meta') 
      .doc('rules')
      .get()
      .then(snap => {
        const rules = snap.data();
        this.setState({ rules });
      }).catch(console.error);
    //this.subBlackboard();
  }

  subBlackboard() {
    if (this.unsub.blackboard) this.unsub.blackboard();
    this.unsub.blackboard =  this.bbRef.onSnapshot(snap => {
      const blackboard = snap.data() || {};
      this.setState({ blackboard });
    });
  }

  updateBlackboardUpdate(event) {
    this.setState({ blackboardUpdate: event.target.value });
  }

  saveToBlackboard() {
    const { blackboardUpdate, student } = this.state; 
    if (!blackboardUpdate.trim()) return;
    const blackboard = blackboardUpdates(student.blackboard, [ blackboardUpdate ]);
    this.bbRef.set(blackboard, {merge: true})
    .then(() => {
      this.setState({ blackboardUpdate: '' });
    }).catch(console.error);
  }
  
  deleteBlackboardItem(key) {
    const { blackboard } = this.state;
    delete blackboard[key];
    this.bbRef.set(blackboard);
  }

  renderBlackboardData() {
    const { blackboard } = this.state;
    const { projectId } = this.props;
    const bestRule = selectRule(this.state.rules, blackboard);
    let bestRuleArea = <div />;
    if (bestRule) {
      let id = bestRule.hocketRef.id;
      let url = "/projects/" + projectId + '/hockets/' + id;
      bestRuleArea = (
        <div>
          <h3><Link to={ url }>Best Rule</Link></h3>
          <p>Trigger Criteria</p>
          <pre>{ JSON.stringify(bestRule.triggerCriteria, null, 2) }</pre>
        </div>
      );
    }
    return (
      <div>
        <h3>Blackboard</h3>
        <table className='blackboard-data full-width'><tbody>
        { Object.keys(blackboard).map(key => {
            const className = specialKeys(key) || null;
            return (
              <tr key={ key } >
                <td
                  className="deletion-cell"
                  onClick={ () => this.deleteBlackboardItem(key) }>
                    x
                </td>
                <td className={ className }>
                  { key }
                </td>
                <td> 
                  { JSON.stringify(blackboard[key]) }
                </td>
              </tr>
            );
          })
        }
        </tbody></table>
        { bestRuleArea }
      </div>
    );
  }

  render() {
    const { student } = this.state;
    if (!student) return null;
    const {
      displayName = '',
      photoUrl = '',
    } = student;
    return (
      <div className="student-data-area padded">
        <img alt="student" className="float-left" src={ photoUrl } />
        <div className="title-area">
          <h2>{ displayName }</h2>
          <p className='subtext'>
            student data
          </p>
        </div>
      </div>
    );
  }

}

export default StudentData;
