import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './style.css';

class EmailSignIn extends Component {
  
  constructor(props) { 
    super(props)
    this.state = {
      email: '',
      submitted: false,
    }
  }
 
  submit() {
    const { email } = this.state;
    if (this.props.sendLoginEmail) {
      this.props.sendLoginEmail(email);
    }
    this.setState({ submitted: true });
  }
 
  render() {
    const { submitted } = this.state;
    const style = {
      opacity: 0,
      fontWeight: '400',
      textAlign: 'center',
      animationName: 'appear',
      animationDuration: '0.6s',
      animationFillMode: 'both',
    };
    if (submitted) {
      return (
        <div style={ style } className="email-sign-in">
          <p>
            <img className="logo-image" src="/prismia-logo-simple.svg" alt="logo"/>
          </p>
          <p>
            We've emailed you! Sign in by following the link in the email.
          </p>
        </div>
      );
    } else {
      return (
        <div style={ style } className="email-sign-in">
          <p>
            <img className="logo-image" src="/prismia-logo-simple.svg" alt="logo"/>
          </p>
          <TextField 
            key='email-input'
            className='email-input'
            variant="outlined"
            placeholder="Email Address..."
            onChange = { (e) => {
              this.setState({email: e.target.value});
            }}
            />
          <Button
            className='submit-button'
            variant='outlined'
            onClick={ () => this.submit() }>
            Send Email with Sign-in Link
          </Button>
        </div>
      );
    }
  }

}

export default EmailSignIn;
