import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Project } from '../project';
import { createCourseFromTopOfLandingPage, loginToCreateCourse } from '../analytics';
import './style.css';
import { renderCarousel, allSlides } from '../carousel';

class DocumentationView extends Component {

  logIn() {
    if (this.props.currentUser) {
      if (this.props.currentUser.activeProjectId) {
        let url = '/chat/' + this.props.currentUser.activeProjectId;
        return this.props.router.history.push(url);
      }
      return this.props.router.history.push('/chat');
    }
    this.props.login('/chat');
  }

  createProject() {
    const { currentUser, db, router } = this.props;
    if (!currentUser) return console.log('no user');
    const project = Project(currentUser);
    const { id } = project;
    createCourseFromTopOfLandingPage(id);
    return db.collection('projects')
      .doc(id)
      .set(project)
      .then(() => {
        return db.collection('users')
          .doc(currentUser.id)
          .set({ activeProjectId: id }, {merge:true});
      }).then(() => {
        router.history.push('/projects/' + id);
      }).catch(console.error);
  }

  loginThenCreate(paymentPlan) {
    if (!this.props.login) return null;
    this.props.login(null)
      .then(() => {
        loginToCreateCourse();
        return this.createProject();
      }).catch(console.error);
  }

  createCourseNow() {
    const { currentUser } = this.props;
    if (!currentUser) {
      return this.loginThenCreate('free');
    }
    this.createProject('free');
  }

  renderOverview() {
    return (
      <div className="expo">
        <div className="centered">
          <h1>
            Prismia Guide
          </h1>
        </div>
        <div>
          <ul className="documentation-list">
            <li> Prismia is a chat app built for the classroom. You can run your whole class through it, or you can use it alongside videoconferencing software or in a face-to-face classroom. </li>
            <li> Prismia scales up the efforts of the instructional staff to achieve a one-on-one conversational dynamic for each student in class. Thus a Prismia class combines the communal benefits of a group meeting with the responsiveness of private tutoring. </li>
            <li> Adoption is easy: students don't face a learning curve, since their interface is a simple chat window. Instructors can get started by learning just a few moves in the classroom view. And all of the communication features of Prismia are free. </li>
            <li> Sign-in is preserved between sessions, so instructors and students can sign in quickly the first time and effortlessly thereafter. </li>
          </ul>
        </div>
      </div>
    );
  }

  renderStudents() {
    return (
      <div className="expo rtl">
        <div className="centered">
          <h2 id="students">
            For Students
          </h2>
        </div>
        <div>
          <ul className="documentation-list">
            <li>Students join a Prismia class via a join link distributed by the course instructor.</li>
            <li>Joining a course takes you to your chat view. You see messages from the instructor, your own messages, and messages from other students which the instructor has shared with the class.</li>
            <li>Messages sent from instructors have white backgrounds, and messages sent from other students have light orange backgrounds.</li>
            <li>Students can send messages to ask questions, to answer questions asked by the instructor.</li>
            <li>Information about who sent each message is available by hovering over the profile image on the left edge of the chat bubble.</li>
            <li>Messages from instructors or other students may be pinned to the top of the screen by clicking on the small gray icon (⊕) next to the message. </li>
            <li>You can use the Courses panel (available from the icon in the top left corner) to switch classes. </li>
            <li>The menu in the top left corner of the Courses panel lets you open a help screen, produce a transcript for a specified date range, drop or hide a course, select options, or log out. </li>
            <li>Use the menu to choose "call on me" or "don't call on me", to let your instructor know whether you'd like to be called on in class.</li>
            <li>If you're an instructor or TA for a class, you'll see a CLASSROOM button for accessing the instructor view.</li>
            <li>An instructor message can be copied to your clipboard by clicking the instructor's profile image in the bottom left corner of that message. If the message contains code, only the code will be copied. </li>
          </ul>
        </div>
      </div>
    );
  }

  renderInstructorsGettingStarted() {
    return (
      <div className="expo">
        <div className="centered">
          <h2>
            For instructors: <br/> getting started
          </h2>
          <img
            alt="teacher chat ui"
            src="/carousel-images/carousel-6.png" />
        </div>
        <div>
          <ul className="documentation-list">
          <li>When you first create a class, your instructor view will be prepopulated with example messages for you to experiment with. When class begins, this is where messages sent by students will appear.</li>

          <li> The messages are arranged into columns based on an analysis of the text contents, with the goal of putting similar messages together.</li>

          <li> You can change the arrangement either by dragging messages or by hitting the RE-CLUSTER button. The clustering algorithm is stochastic, so you can click the button again for a fresh clustering. You can alternatively click the Cloud Cluster button, which performs the same clustering operation but using a very sophisticated algorithm. You should expect the cloud clustering to take a long time on the first click. Once it's warmed up, it only takes a few seconds.</li>

          <li> The number of columns is controlled by the slider just above the messages.</li>

          <li> New messages that arrive appear in a new inbox column on the right of the block. These messages may be incorporated into the other columns either by clicking RE-CLUSTER (to rearrange all of the messages) or by clicking the black button at the top of the inbox column (to preserve the existing clusters and incorporate the new messages into them). </li>
          <li>The colorful bars to the right of the RE-CLUSTER button indicate how many student messages are in each column. A striped bar will appear if some students are actively typing.</li>
          <li>You can click the striped bar (or hold shift and press p) to anonymously display what all the students are typing prior to sending their messages (you have to turn this feature on in Settings). </li>
          <li>The <em>Summarize</em> button puts a summary of the current student responses into your <em>Message the Class</em> text box. </li>
          <li> You can hit the long "+" button at the top of the window to start a new block. Subsequent messages sent by students will appear in this block.</li>
          </ul>
        </div>
      </div>
    );
  }

  renderInstructorsChattingWithStudents() {
    return (
      <div className="expo rtl">
        <div className="centered">
          <h2>
            Chatting with students
          </h2>
        </div>
        <div>
          <ul className="documentation-list">
            <li><img alt="arrow to send message to column" src="/message-the-class.png" className="inline-image"/>
            While Prismia can be used for its clustering technology using only the Re-cluster button, the column-count slider, and the + button, the primary benefits come from messaging students.</li>
            <li>
              To send a message to everyone, type into the text box in the top left corner of the window.</li>
            <li>If you'd like to suggest responses for the students, you can type those into blanks below the message text box. As you type, a new blank will appear for an additional suggested response, as well as a toggle switch to (optionally) mark some of the responses as correct.</li>
            <li>Type into the text box at the bottom of a column and press enter or click SEND to respond to every student in that column.</li>
            <li>
              <img alt="arrow to send message to column" src="/arrow-to-column.png" className="inline-image"/>
              After you send a message to a column, the message will appear at the bottom of the column. The arrow button on that message brick will send the message again to any newly added student messages in that column.
            </li>
            <li>Click the star icon at the bottom of a column to star each message in that column. Starred messages are badged with a star in the student's chat view, and starred message counts are recorded. </li>
            <li>Click the clear icon at the bottom of a column to clear the messages in that column.</li>
            <li>Hover over a message to display the student's name and open a drawer with buttons for sharing the message with the class or clearing the message from the block.</li>
            <li>Click on a message to open a private chat. Instructor messages in the private chat have a mail button which can be clicked to notify the student by email (this is useful for after-class followups).</li>
            <li>Click "Settings" or "Participants" to toggle the lower left display between a settings panel (along with a class message history) and view of who's in class and when they were last active. The participants list shows total message and starred message counts for the day, and you can see three dots next to the student's name if they are currently typing. A checkmark is displayed if the student has a message in the current cluster block. </li>
            <li>Multiple-choice questions (questions with at least one suggested response marked as correct) are scored automatically and presented on the <em>Metrics</em> page. If you'd like to ask an open response question and have it scored, star the student messages with correct answers and click the "<em>n</em>participants responded" message in the top-right corner of the cluster block. Student responses will be counted even if they have been cleared from the block or if they arrive or are starred after you click to mark the block as an open response question.</li>
            <li>Click the light gray "participants" text under the "Classroom Settings" button to send each student their personal stats for the day.</li>
            <li>The options in the settings panel provide flexibility in how instructional staff coordinates their responses to messages in the cluster block. You can choose to fade out messages which are being responded to by other TAs or hide them all together. You can also choose to send flagged messages immediately to the top of their columns. This is useful for an instructor looking to get help from TAs in surfacing important messages.</li>
          </ul>
        </div>
      </div>
    );
  }

  renderInstructorsAndTas() {
    return (
      <div className="expo">
        <div className="centered">
          <h2>
            Instructors and TAs
          </h2>
        </div>
        <div>
          <ul className="documentation-list">
            <li>Prismia supports coordination between instructors and TAs. </li>

            <li>When an instructor or TA clicks in the text box at the bottom of a column, all other instructors and TAs see those messages faded and with a dashed border. The border becomes solid again if the person responding clears their text box and clicks elsewhere in their window.</li>

            <li>When a message receives a response from any instructor or TA, it grays out for all instructional staff.</li>

            <li>Messages which include a question mark display with an orange background in the message log (one of the options for the area in the bottom left of the screen). This helps TAs ensure that all questions are addressed.</li>
          </ul>
        </div>
      </div>
    );
  }

  renderLessons() {
    return (
      <div className="expo rtl">
        <div className="centered">
          <h2>
            Lessons
          </h2>
          <img
            alt="lesson view"
            src="/carousel-images/carousel-lesson.png" />
        </div>
        <div>
          <ul className="documentation-list">
            <li>The <em>Lessons</em> feature of Prismia allows you to pre-script messages and retrieve them conveniently during class or deploy them for students to read on their own.</li>
            <li>To prepare a lesson, click the LESSONS link in the navigation bar at the top of the window.</li>
            <li>Enter a name for your new lesson and click CREATE LESSON.</li>
            <li>Click the + button to add a new messages, and script messages using the text box. Using the text box controls, you can include links, images, mathematical expressions, code blocks, inline code, mathlets, tables, or executable code cells.</li>
            <li>Individual messages can be dragged to re-order them. To move around messages in bulk, highlight them (shift-click or shift-up/down) and copy-paste. If you want to export your content, you can copy messages from Prismia and paste them into a plain text text box as Markdown, or into a rich text text box as rich text. You can also export your lessons as a Jupyter notebook.</li>
            <li>Click the Share link to make a publicly accessible page for your lesson. Other instructors can import your lesson or copy messages into their own classes, and students can work through your lesson one message at a time. </li>
            <li>To use your lesson in class, go back to the classroom view and pop open your lessons drawer (the gray button in the left panel).</li>
            <li>Select a message to place its contents into your <i>Message Entire Class</i> text box. Click SEND or press enter to send the message to the whole class. To edit the message before sending, first close the Lessons drawer.</li>
            <li>Alternatively, you can send lesson messages directly from the lessons view. Put your session into drag mode (the three-horizontal-line icon in the toolbar) and click on a message to send it to the text box in your classroom. Then press enter to send it to the class.</li>
          </ul>
        </div>
      </div>
    );
  }

  renderOtherFeatures() {
    return (
      <div className="expo">
        <div className="centered" id="otherfeatures">
          <h2>
            Other features
          </h2>
        </div>
        <div>
          <ul className="documentation-list">
            <li>To designate a user as a TA or instructor, visit the <i>Students</i> page in the navigation bar at the top of the window. You can also click on a student's name in this view to open their personal chat history.</li>

            <li>To view statistics on student interactions in class, visit the <i>Metrics</i> page in the navigation bar. For each student and each date, there are eight data available: how many starred and total messages the student sent on the given day, the number of multiple choice questions answered correctly, the number answered, and the number asked, and the number of open response stars, answers, and questions asked. You can use the input bar to filter the rows by date or student name, and you can click the "Export CSV" button to export the data.</li>

            <li>When a message receives a response from any instructor or TA, it grays out for all instructional staff.</li>

            <li>Messages which include a question mark display with an orange background in the message log (which appears in the bottom of the left panel). This helps TAs ensure that all questions are addressed.</li>

            <li>Mouse over a button to see whether a keyboard shortcut is available for that button's action. Particularly useful are <tt>shift+up</tt> and <tt>shift+down</tt> for navigating lessons in the classroom view (even when the drawer is closed!), <tt>shift+m</tt> for moving the cursor to the message-the-class text box, and <tt>escape</tt> for closing a private chat. If a cursor is active in an text box, press <tt>escape</tt> to exit the text box before using keyboard shortcuts.</li>

            <li>To see all available keyboard shortcuts in the classroom or in the lesson editor, do <tt>shift+?</tt>.</li>

            <li id="shortcuts">Instructor and student text boxes provide the following Markdown shortcuts:
              <ul className="documentation-list">
                <li><tt>**boldface**</tt></li>
                <li><tt>*italic*</tt></li>
                <li><tt># Headers</tt></li>
                <li><tt>$math$</tt></li>
                <li><tt>$$centered math$$</tt></li>
                <li><tt>`inline code`</tt></li>
                <li><tt>```code blocks</tt></li>
                <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
                <li><tt>[links](https://mylink.com)</tt></li>
                <li><tt>---horizontal rule</tt></li>
              </ul>
            To use a shortcut, just press the space bar after entering appropriate syntax for that shortcut. For example, three backticks followed by a space gives you a code block.
            </li>

            <li id="bulkimport">
              The keyboard shortcut <tt>shift+s</tt> splits the currently active message into multiple messages. Use a horizontal rule to separate messages. Checkbox lists at the end of the message will be converted to suggested responses, with checked items marked as correct.
            </li>

            <li>
              The keyboard shortcut <tt>shift+m</tt> parses the plain-text contents of the current message as standard Markdown. This feature can be used in conjunction with the message-splitting feature to import messages in bulk from another source. For example, here's an open response question followed by a multiple choice question:
              <pre style={{fontSize: 18, marginLeft: "20px"}}>
                  Calculate 16.2 - 4.9.<br/><br/>
                  ---<br/><br/>
                  Round 11.27 to the nearest tenth.<br/>
                  - [ ] 11<br/>
                  - [ ] 11.2<br/>
                  - [x] 11.3<br/>
              </pre>
              To import this text as two questions, create a new message, paste the text above into the text box (as plain text), and save the message. Then do <tt>shift+m</tt> to parse the Markdown and save the resulting rich-text message. Finally, do <tt>shift+m</tt> to split the message into two messages and save the choices in the checkbox list as suggested responses.
            </li>
          </ul>
        </div>
      </div>
    );
  }

  renderMoreInformation () {
    return (
      <div className="expo rtl">
        <div className="centered">
          <h2>
            More Information
          </h2>
        </div>
        <div>
          <ul className="documentation-list last-panel">
            <li>
              <a href="https://theliveclassroom.org">The Live Classroom</a> discusses the logistics of using Prismia and other technologies in your class.
            </li>
            <li>
              <a href="https://medium.com/@samuel.s.watson/using-babylon-house-for-your-virtual-classroom-1ace3bc1eaa8">This Medium article</a> walks through the early features of a precursor to Prismia.
            </li>
            <li>
              Here's <a href="/shared/prismia-introduction">a shared lesson</a> that you can import or copy-paste into your own course to introduce your students to Prismia. <a href="/shared/0rjz-05g3">Here's</a> a shared lesson from an actual live videoconference seminar.
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    window.document.title = 'Prismia';
    const imageStyle = {
      width: '160px',
      height: '40px',
      marginTop: '-2px',
      float: 'left',
    };
    return (
      <div className="documentation-view" >
        <div className="landing-top-bar">
          <a href="/">
            <img src="/prismia-logo-simple.svg" style={ imageStyle } alt="logo"/>
          </a>
          <a href="/faq">
            <Button>
              FAQ
            </Button>
          </a>
          <a href="/pricing">
            <Button>
              Create a New Course
            </Button>
          </a>
          <Button onClick={() => this.logIn() }>
            { this.props.currentUser ? "My Courses" : "Sign In" }
          </Button>
        </div>
        <div>
          <div className="expo" id="walkthrough">
            <div className="centered">
              <h2>Features</h2>
              <p className="normalsize">
                Welcome to the detailed Prismia guide! Let's begin with a feature walkthrough (this is an extended version of the gallery on the landing page).
              </p>
              { renderCarousel(allSlides) }
              <p className="normalsize">
                Next, we'll describe how Prismia works in more detail, from the student point of view and for the instructor.
              </p>
            </div>
          </div>
          { this.renderStudents() }
          { this.renderInstructorsGettingStarted() }
          { this.renderInstructorsChattingWithStudents() }
          { this.renderInstructorsAndTas() }
          { this.renderLessons() }
          { this.renderOtherFeatures() }
          { this.renderMoreInformation() }
        </div>
      </div>
    );
  }

}

export default DocumentationView;
