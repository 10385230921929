import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CardContents from '../CardContents';
import {v4 as uuid} from 'uuid';
import './style.css';

const studentNames = [
  'Yash Bafna',
  "Katie O'Leary",
  'Stephanie Vartany',
  'Mark Adut',
  'Shubham Makharia',
  'Junyi Zhu',
  'Jiatao Yuan',
  'Sang Uk Park',
  'Jason Hooker',
  'Megan Sindhi',
  'Guanjie Linghu',
  'Yifei Song',
  'Bochen Fu',
  'Hanjun Wei',
  'Yun Li',
  'Kevin Ren',
  'Hardy Bright',
  'Harrison Cho',
  'Yurui Zhang',
  'Nicholas Tolley',
  'Emre Toner',
  'Yuetian Li',
  'Pooja Barai',
  'Yingfei Hong',
  'Zhirui Li',
  'Lukas Kania',
  'Aryaman Dutta',
  'Manlin Li',
  'Sagarika Ramesh',
  'Andrew Wang',
  'Tongtong Zhao',
  'Xufan Wang',
  'Zhuoran Han',
  'Yihuan Hu',
  'Youn Kyeong Chang',
  'Mao',
  'Dongjun Shin',
  'Nange Li',
  'Samaye Lohan',
  'Ying Sun',
  'Radhika Mehrotra',
  'William Jaekle',
  'Ramya Harikrishnan',
  'Jacob Wang',
  'Keying Gong',
  'Ran An',
];

const studentIds = ['09Tmem7k8eW5nfRKG4xK1qhZEIw2',
'3pFFejzwu5e4xyx6LNdUBb0OdPZ2',
'43ATX8VsFdOzDQwx9TKdDXbZHIU2',
'55G3Xy0x3AVDAyK4GhRvBsIdt6q2',
'5axuDKKV5AV6RBubgDVZRmwKJH93',
'75k0YUv2ODXTRiYARwbDBsrBtuk1',
'8eFA0Cx3vHgpcp2bUAykQzYqPLN2',
'AXWdANrZeUOGSc5mOdZXw9BsNuE2',
'DEzeq2W0rPMy8FvqZcjnxRhHs6k1',
'De1GXYw3hZOzFF5k94d4lAc1XHD3',
'DwcJyMuOVlM3GQf3dmHIbEWpRYp2',
'ELjg2QT7qPPBOcBTrcNXl1O71TF2',
'GvvkwBEBKaNgSmx8tsvOK71VRqu1',
'I5zgytPy0ISOh6V4uKbQ1q8GQz63',
'K0Uabk3RNkbOgu9gzqOMR9BnFDz1',
'KDLcjeMCELP0WgPcuatvcH5UOOg2',
'KzSVUPyYTDThyb5lIuATNRvICYX2',
'MNeVADXbk5RUKPDNhZICIdyWZOw1',
'MVLO544mPuY0GdV7EucqZ0WTkqv1',
'MwsyzuAmT8SHSK2OnCO0YSKRYc33',
'NYjHNrI73hfQinqOkjceG5NbnHE3',
'OGKzrrqperRwXniKqtOL0oCM9jC2',
'OnSdkCNyeraktrQT3PDIlS9Xsbz1',
'PbszJ8azQdgShQXbQTihFYPrYb53',
'Qcx84uw0ovdZYTZIyco0SgKjAyi1',
'QyWCKiZelNaICIJa10lLhqaY7XK2',
'VGTflEZbi1Ya1AGDf9chKQdCIiA3',
'VsRr4il3ntSRnevyX2OlJ5N9TYq2',
'XM9b2uyVB9QLfQxT1G13OCU04qT2',
'XPFwQmNqoZXMGdYP1flOT6Mb9sp2',
'ZAFqPEGwSWSurJVVfmgzxvIyBRp2',
'bbtnwZcGMUTBw5qkpCUKVBlumJg1',
'dCU10jZPGuS3OXT1KUvwsbfohh73',
'drakfICVRfN0FZe98k27J87iSoF2',
'dyJv9PA4yHS0NKJSbNknwPFhyei1',
'iOsouyI29vhqJmNegkeL9hJDea12',
'j0ejUhPKlOXPX9eX6ieVf3dqllb2',
'j0nfsL3Erye7uG0USt21z9ctj6m1',
'kAsg0tArZbUlxUKxQvdPI6NOrc93',
'n71tr369cpWRKPLjfewGMHf7vRg2',
'o6POnK6oyPeGooYulvNL0GxoMDa2',
'q603dCiFSBXwE8Bt22hTBO9ys4V2',
'qSnOTlImO3UYgPnxgWfGhh1i5NV2',
'sMkmusEP33QqhDMXfUXHVDiHDDk1',
'tzsIYtCSyJhgFXw6v5mhKxgUXX23',
'wwZ1bAuoLfcztBtrVWDe53JvGIZ2'];

class LessonSharingView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      studentName: null,
      studentId: null,
      hockets: null,
      feedback: '',
    }
  }

  subHockets(studentId) {
    const { db } = this.props;
    db.collection('projects')
      .doc('d685f682-068e-4f8d-b3b3-19fa6073ab23')
      .collection('assignments')
      .doc('b4fdc1f4-6563-4295-bece-cbb5caa95a74')
      .collection('students')
      .doc(studentId)
      .collection('problems')
      .doc('b55d9e27-9f59-476f-b53e-8ea225209889')
      .get()
      .then((snap) => {
        const doc = snap.data();
        const { hockets } = doc;
        this.setState({ hockets })
      })
      .catch(console.error);
  }

  renderHocket(hocket) {
    return <div key={hocket.id}
      style={{
        width: '750px',
        maxWidth: '90%',
        border: 'none',
      }}
      className='project-gallery'>
      <CardContents hocket={hocket}/>
    </div>
  }

  renderCard(i) {
    return (
      <Button 
        variant="outlined"
        key={studentNames[i]}
        style={{
          width: '200px',
          height: '60px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        onClick={ () => {
          this.setState({ studentName: studentNames[i]});
          this.setState({ studentId: studentIds[i]});
          this.subHockets(studentIds[i]);
        }}>
          { studentNames[i] }
      </Button>
    );
  }

  async submitFeedback() {
    const { db, currentUser } = this.props;
    const feedback = this.state.feedback;
    if (!currentUser) this.setState({feedback: 'not logged in!'});
    const id = uuid();
    await db.collection('projects')
      .doc('d685f682-068e-4f8d-b3b3-19fa6073ab23')
      .collection('assignments')
      .doc('b4fdc1f4-6563-4295-bece-cbb5caa95a74')
      .collection('students')
      .doc(this.state.studentId)
      .collection('feedback')
      .doc(currentUser?.id || id)
      .set({ 
        feedback,
        reviewer: currentUser?.id || id,
        reviewerDisplayName: currentUser?.displayName || 'Anonymous',
      })
      .catch(console.error);
    setTimeout(() => this.setState({ feedback: 'Submitted!'}), 
    currentUser ? 0 : 1500);
    setTimeout(() => this.setState({ feedback: '' }), 3000);
  }

  renderArticle() {
    return <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '40px',
        paddingBottom: '40px',
        gap: '8px',
      }}>
      <h1 className="centered">{this.state.studentName}</h1>
      { this.state.hockets.map(hocket => 
          this.renderHocket(hocket)
        ) }
      <TextField
        style={{
          marginBottom: "20px",
          width: "500px",
          maxWidth: "90%",
        }}
        variant="outlined"
        label="Feedback"
        rows={6}
        rowsMax={60}
        value={this.state.feedback}
        onChange={event => this.setState({feedback: event.target.value})}
        multiline/>
      <Button 
        className="centered"
        variant="outlined"
        onClick={() => {
          if (this.state.feedback.length) {
            this.submitFeedback();
          } else {
            this.setState({
              studentId: null,
              studentName: null,
              hockets: null,
            });
          }
        }}>
          { this.state.feedback.length ? 'Submit' : 'Back' }
      </Button>
    </div>;
  }

  renderButtons() {
    return <>
      <h1 className="centered">DATA 1010 Project Gallery</h1>
      <div style={{
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: "30px"}}>
      <div style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          columnGap: '20px',
          rowGap: '20px',
          paddingBottom: '40px',
        }}>
          {[...Array(studentNames.length).keys()]
            .map((i) => this.renderCard(i))}
      </div>
    </div>
  </>;
  }

  render() {
    return this.state.hockets ? this.renderArticle() : this.renderButtons();
  }

}

export default LessonSharingView;
