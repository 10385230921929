import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SimpleAdminNav from '../SimpleAdminNav';
import SidebarButtonPanel from '../sidebar-buttons';
import { JupyterCell } from '../JupyterCell';
import CommentsArea from '../comments-area/CommentsArea';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReorderIcon from '@material-ui/icons/Reorder';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import CodeIcon from '@material-ui/icons/Code';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import SuggestionSuperList from '../SuggestionSuperList';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import toPlaintext from 'quill-delta-to-plaintext';
import { updateTitleBar, splitQuillDeltaOnHorizontalRule,
         touchUpMarkdown, peelOffSuggestions,
         extractCodeBlock, markdownToDelta, arrayUnion,
         mousetrapStopCallback, isNote, isRegistered,
         isValidDelta, pingBinderKernel } from '../utils';
import { binderKernels } from '../jupyter';
import { mobileThreshold } from '../constants';
import { exportJupyterNotebook, cells2hockets } from '../jupyter';
import Hocket from '../hocket';
import MessageForm from '../MessageForm';
import CardContents from '../CardContents';
import {
  manuallySortDrillMessage,
  saveDrillMessage
} from '../analytics';
import 'react-quill/dist/quill.snow.css';
import * as Mousetrap from 'mousetrap';
import { settingsTheme } from '../mui-themes';

// for message virutalization: only render a
// window of 40 messages, stepping in increments
// of 10 when the window scroll position gets
// near the top or bottom:
const MESSAGE_RENDER_MAX = 40;
const MESSAGE_RENDER_STEP = 10;

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#efd' : '#fff',
});

const getItemStyle = (isDragging, draggableStyle, clusterStyle={}) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#ffe' : clusterStyle.background,
    // styles we need to apply on draggables
    ...draggableStyle
});

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
checked: {},
})(Checkbox);

class DrillCreationView extends Component {

  constructor(props) {
    super(props);
    this.unsub = {
      drill: null,
      project: null,
      settings: null,
    };
    this.drillRef = {};
    this.commentRef = {};
    this.timeLastScrolled = new Date();
    this.mainQuillRef = React.createRef();
    this.juniperRef = React.createRef();
    this.jupyterInputRef = React.createRef();
    this.deleteMessages = this.deleteMessages.bind(this);
    this.hocketsAreaRef = null;
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      manualScroll: navigator.vendor === "Apple Computer, Inc.",
      hockets: {},
      drill: null,
      instructors: {},
      tas: {},
      activeHocketId: null,
      hocketSelectionRange: null,
      showHelp: false,
      showSettings: false,
      locked: false,
      reOrder: false,
      juniperOpen: false,
      juniperHasRendered: false,
      published: false,
      lastBinderPing: new Date(),
      timeZone: null,
      messageLimit: MESSAGE_RENDER_MAX,
      activeCommentHocket: null,
      showComments: true,
    };
  }

  componentDidMount() {
    this.getProjectDetails().then( (lang) => {
      this.subDrill(this.props, lang);
    });
    this.subComments(this.props);
    this.setMousetrap();
    this.setActiveHocketId(null, true); // true means do update db
    window.addEventListener('copy', this.handleCopy);
    window.addEventListener('paste', this.handlePaste);
    NotificationManager.listNotify.forEach(notification => NotificationManager.remove({id: notification.id}));
  }

  toggleReorder() {
    this.setState({ reOrder: !this.state.reOrder });
  }

  hocketIndex(hocketId) {
    const { drill } = this.state;
    const { hockets=[] } = drill;
    return hockets.findIndex(hocket => hocket.hocketId === hocketId);
  }

  adjustActiveHocket(increment) {
    const { activeHocketId, drill } = this.state;
    const { hockets=[] } = drill;
    const currentIndex = this.hocketIndex(activeHocketId);
    const newIndex = currentIndex + increment;
    if ((0 <= newIndex) && (newIndex < hockets.length)) {
      this.setActiveHocketId(hockets[newIndex].hocketId);
    }
  }

  pingBinderKernel() {
    const update = pingBinderKernel(this.state.lastBinderPing);
    if (update) this.setState({ lastBinderPing: update });
  }

  adjustSelectionEnd(increment) {
    const { activeHocketId,
            drill,
            hocketSelectionRange
    } = this.state;
    const { hockets=[] } = drill;
    const currentIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    let endIndex;
    if (hocketSelectionRange) {
      endIndex = hocketSelectionRange[1];
    } else {
      endIndex = currentIndex;
    }
    const newEndIndex = endIndex + increment;
    if ((0 <= newEndIndex) && (newEndIndex < hockets.length)) {
      endIndex = newEndIndex;
    }
    this.setState({ hocketSelectionRange: [currentIndex, endIndex] });
  }

  splitActiveCell() {
    const { db, projectId } = this.props;
    const { drill={}, activeHocketId } = this.state;
    const { hockets=[] } = drill;
    const idx = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    if (idx === -1) return;
    const delta = JSON.parse(this.state.hockets[hockets[idx].hocketId].responses[0]);
    const newDeltas = splitQuillDeltaOnHorizontalRule(delta);
    const answer = window.confirm(`Split current messages into ${newDeltas.length} new messages?`);
    if (!answer) return;
    const newHockets = newDeltas.map((delta) => {
      const hocket = Hocket();
      hocket.responses.push(JSON.stringify(delta));
      return peelOffSuggestions(hocket);
    });
    const batch = db.batch();
    newHockets.forEach( (hocket) => {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocket.id),
          hocket,
          {merge: true});
    });
    batch
      .commit()
      .then( () => {
        const newHocketStubs = newHockets.map(
          (hocket) => { return { hocketId: hocket.id }; }
        );
        hockets.splice(idx, 1, ...newHocketStubs);
        this.drillRef.set({ hockets }, {merge: true});
        return newHocketStubs;
      })
      .then( (hockets) => {
        const [lastHocket] = hockets.slice(-1);
        this.setState({activeHocketId: lastHocket.hocketId});
      })
      .catch(console.error);
  }

  setMousetrap() {
    Mousetrap.bind("down", () => this.adjustActiveHocket(1));
    Mousetrap.bind("up", () => this.adjustActiveHocket(-1));
    Mousetrap.bind("shift+down", () => this.adjustSelectionEnd(1));
    Mousetrap.bind("shift+s", () => this.splitActiveCell());
    Mousetrap.bind("shift+up", () => this.adjustSelectionEnd(-1));
    Mousetrap.bind("shift+=", () => this.createHocket());
    Mousetrap.bind("ctrl+j", () => this.toggleJuniper());
    Mousetrap.bind("shift+r", () => this.toggleReorder());
    Mousetrap.bind(["del", "backspace"], () => {
      this.deleteMessages();
    });
    Mousetrap.bind("esc", () => {
      if (this.state.locked) this.setState({ activeHocketId: null });
      this.setState({
        hocketSelectionRange: null,
        showHelp: false,
        showSettings: false,
      });
    });
    Mousetrap.bind('enter', () => {
      setTimeout( () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.focus();
          const editor = this.mainQuillRef.current.editor;
          editor.setSelection(editor.getLength(), 0);
        }
      }, 40);
    });
    Mousetrap.bind("shift+m", () => this.convertEditorContents());
    Mousetrap.bind(["mod+/", "shift+/"], () => this.toggleHelp());
    Mousetrap.prototype.stopCallback = mousetrapStopCallback;
  }

  exitHelpOrSettings() {
    this.setState({ showHelp: false, showSettings: false});
  }

  toggleHelp() {
    const { showHelp } = this.state;
    this.setState({ showHelp: !showHelp});
  }

  toggleSettings() {
    const { showSettings } = this.state;
    this.setState({ showSettings: !showSettings });
  }

  setCodeCell(language) {
    this.drillRef.set({ codeCell: language }, {merge: true});
  }

  publish(setting) {
    if (setting) {
      const drillProblems = JSON.stringify(this.collectProblems());
      this.drillRef.set({ drillProblems, published: true }, {merge: true}).then(() => NotificationManager.success("Drill published!")).catch(console.error);
    } else {
      this.drillRef.set({ drillProblems: null, published: false }, {merge: true}).then(() => NotificationManager.success("Drill unpublished!")).catch(console.error);
    }
  }

  getProjectDetails() {
    const { db, projectId } = this.props;
    return db.collection('projects')
      .doc(projectId)
      .get()
      .then(snap => {
        const data = snap.data();
        const { timeZone, courseLanguage } = data;
         this.setState( { timeZone }, () => moment.tz.setDefault(timeZone) );
        return courseLanguage;
      })
      .catch(console.error);
  }

  handleJupyterImport(file) {
    const { db, projectId } = this.props;
    const { drill } = this.state;
    const { codeCell='code' } = drill;
    file.text().then(text => {
      const jupyterDocument = JSON.parse(text);
      const prismiaHockets = this.drillHockets();
      const { hockets, newHockets } = cells2hockets(
        jupyterDocument.cells,
        prismiaHockets,
        codeCell
      );
      const batch = db.batch();
      for (let hocket of hockets) {
        if (newHockets.has(hocket.id)) {
          batch.set(
            db.collection('projects')
              .doc(projectId)
              .collection('hockets')
              .doc(hocket.id),
            hocket
          );
        }
      }
      batch.set(this.drillRef, { hockets: hockets.map(h => {return {hocketId: h.id};}) }, {merge: true});
      batch.commit().catch(console.error);
    });
    this.setState({ showSettings: false });
  }

  subProposals() {
    this.exitHelpOrSettings();
    NotificationManager.info("Scroll down to see exercises that have been proposed");
    const { db, projectId, drillId } = this.props;
    this.proposalRef = db.collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .collection('proposed-problems');
    this.unsub.proposals = this.proposalRef
      .onSnapshot(snap => {
        const proposalAuthors = {};
        snap.docs.forEach(doc => {
          const proposal = doc.data();
          const processed = new Set();
          proposalAuthors[proposal.author] = proposal;
          for (let problemId of Object.keys(proposal.drillProblems)) {
            for (let statementHocket of proposal.drillProblems[problemId].statementHockets) this.getProposalHocket(proposal.author, statementHocket);
            for (let solutionHocket of proposal.drillProblems[problemId].solutionHockets) this.getProposalHocket(proposal.author, solutionHocket);
            for (let id of (proposal.processed || [])) {
              processed.add(id);
            }
          }
          this.setState({ processed, proposalAuthors });
        });
      });
  }

  getProposalHocket(author, id) {
    this.proposalRef
      .doc(author)
      .collection('hockets')
      .doc(id)
      .get()
      .then(snap => { 
        const { proposalHockets={} } = this.state;
        const data = snap.data();
        proposalHockets[id] = data;
        this.setState({ proposalHockets });
      })
      .catch(console.error);
  }

  renderSettings() {
    const { drill={} } = this.state;
    if (!drill) return null;
    const { title='', description=''} = drill;
    return (
      <ThemeProvider theme={ settingsTheme }>
        <div className="help-info">
          <h1>Settings</h1>
          <h2>Title and Description</h2>
          <div className="save-title-description-container">
            <Input
              key={ 'title-' + drill.id }
              fullWidth
              className="assignment-creation-title"
              placeholder="Drill Title"
              value={ title || '' }
              onChange={ (e) => this.nameDrill(e.target.value) } />
            <Input
              key={ 'description-' + drill.id }
              fullWidth
              className="assignment-description"
              placeholder="description"
              value={ description || '' }
              onChange={ (e) => this.describeDrill(e.target.value) } />
          </div>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.saveDrillDetails() }>
              Save Title and Description
            </Button>
          </div>
          <h2>Publish</h2>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={ <Tooltip
              title="Publish drill"
              enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!!this.state.published} color="default" onChange={(e) => this.publish(!this.state.published)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
            label="Published"/>
          </FormGroup>
          { this.state.published ? <Button
            className="save-title-description"
            style={{marginLeft: '20px'}}
            size="small"
            color="primary"
            variant="outlined"
            onClick={ () => this.publish(true) }>
            Update
          </Button> : null }
          <h2>Proposed exercises</h2>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.subProposals() }>
              Get Proposals from Students
            </Button>
          </div>
          <h2>Jupyter</h2>
          <div className="title-button-container">
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              onClick={ () => this.exportJupyterNotebook() }>
              Export
            </Button>
            <Button
              className="save-title-description"
              size="small"
              color="primary"
              variant="outlined"
              style={{marginLeft: "12px"}}
              onClick={ () => this.jupyterInputRef.current.click() }>
              Import
            </Button>
            <input
              type="file"
              ref={ this.jupyterInputRef }
              onChange={ (e) => this.handleJupyterImport(e.target.files[0]) }
              onClick={ e => {e.target.value = null} }
              multiple={ false }
              style={{opacity: 0, tabIndex: -1, position: "absolute"}}/>
          </div>
          <h2> Message settings </h2>
          <FormGroup style={{marginLeft: "20px", marginTop: "9px"}}>
            <FormControlLabel
              control={ <WhiteCheckbox checked={ !!(this.state.locked) } color="primary" inputProps={{ 'aria-label': 'Lock messages' }} onChange={ e => this.setState({ locked: e.target.checked })}/> }
              label={ "Lock messages" }/>
            <FormControlLabel
              control={ <WhiteCheckbox checked={ !!(this.state.showComments) } color="primary" inputProps={{ 'aria-label': 'Show comments' }} onChange={ e => this.setState({ showComments: e.target.checked })}/> }
              label={ "Show comments" }/>
          </FormGroup>
          <h2>Code cells</h2>
          <FormGroup style={{marginLeft: "20px"}}>
            { 
              Object.keys(binderKernels).map(lang => 
                <FormControlLabel
                  key={lang + 'checkbox'}
                  control={ <Tooltip
                    title={`Use ${binderKernels[lang]?.name} for code cells in this drill`}
                    enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={this.state.codeCell === lang} color="default" onChange={(e) => this.setCodeCell(lang)} inputProps={{ 'aria-label': 'code cells' }}/></Tooltip> }
                label={binderKernels[lang]?.name}/>
              )
            }
            <FormControlLabel
              control={ <Tooltip
              title="Don't use code cells in this drill"
              enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!this.state.codeCell} color="default" onChange={(e) => this.setCodeCell(null)} inputProps={{ 'aria-label': 'no code cells' }}/></Tooltip>}
            label="none"/>
          </FormGroup>
          <h2>Autoscrolling</h2>
          <FormGroup style={{marginLeft: "20px"}}>
              <FormControlLabel
                control={ <Tooltip
                  title="Toggle autoscrolling on this page"
                  enterDelay={ 400 }><WhiteCheckbox className="code-cell-setting" checked={!this.state.manualScroll} color="default" onChange={(e) => this.setState({ manualScroll: !this.state.manualScroll })} inputProps={{ 'aria-label': 'Autoscrolling' }}/></Tooltip> }
                label="Render more messages automatically when scroll bar reaches bottom"/>
          </FormGroup>
          <h2>Danger zone</h2>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className="delete-assignment-message-button"
            onClick={ () => this.deleteDrill() }>
            Delete Drill
          </Button>
        </div>
    </ThemeProvider>);
  }

  helpInfo() {
    return (<div className="help-info">
      <h1>Keyboard shortcuts</h1>

      <ul className="documentation-list">
      <li><tt>escape</tt> save current message</li>
      <li><tt>shift+plus</tt> add a new message following the currently selected message</li>
      <li><tt>up</tt> move selected message up one</li>
      <li><tt>down</tt> move selected message down one</li>
      <li><tt>shift+up</tt> move up end of selected message range</li>
      <li><tt>shift+down</tt> move down end of selected message range</li>
      <li><tt>delete/backspace</tt> delete selected message(s) </li>
      <li><tt>shift+m</tt> (after clicking on the edge of the message to de-select the text box) format contents of selected message (which should be plaintext Markdown) </li>
      <li><tt>⌘+C</tt> copy selected message(s) for pasting into other Prismia drills or into a Markdown file </li>
      <li><tt>shift+?</tt> show this keyboard shortcut help page </li>
      </ul>

      <h1>Markdown shortcuts</h1>
      <p><em>Press space after appropriate syntax to apply formatting)</em></p>
      <ul className="documentation-list">
        <li><tt>**boldface**</tt></li>
        <li><tt>*italic*</tt></li>
        <li><tt># Header</tt></li>
        <li><tt>$math$</tt></li>
        <li><tt>$$centered math$$</tt></li>
        <li><tt>`inline code`</tt></li>
        <li><tt>```code block</tt></li>
        <li><tt>![alt-text-required](https://imgur.com/example-image-to-insert.jpg)</tt></li>
        <li><tt>[links](https://mylink.com)</tt></li>
        <li><tt>---horizontal rule</tt></li>
      </ul>

      <h1>Emoji features</h1>
      <ul className="documentation-list">
        <li>Sending a message including the text <span role="img" aria-label="silhouette">👥</span> sends students' messages to each other anonymously and allows each student to respond directly to the person whose answer they see. Use <tt>\peer</tt> followed by tab to get the silhouette character.</li>
        <li>Sending a message including the text <span role="img" aria-label="clock">🕔90s</span> sets a 90-second timer (visible to you and to students). Use <tt>\clock</tt> or <tt>\timer</tt> followed by tab to get the clock character.</li>
        <li>Sending a message including the text <span role="img" aria-label="pencil">✏</span> opens each student's drawing tool and sets the image contained in the message as the background image. Use <tt>\draw</tt> followed by tab to get the pencil character.</li>
        <li>Sending a message including the text <span role="img" aria-label="registered">®</span> marks the question as required, meaning that it will be tracked as an open response question on the <em>Metrics</em> page. Use <tt>\RR</tt> or <tt>\required</tt> followed by tab to get the registered symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="pushpin">📌</span> will pin the message to the top of each student's window. Use <tt>\pin</tt> followed by tab to get the pushpin character.</li>
        <li>Sending a message including the text <span role="img" aria-label="recycle">♻</span> will clear every student's pinned messages. Use <tt>\clear</tt> followed by tab to get the recycling symbol.</li>
        <li>Sending a message including the text <span role="img" aria-label="otimes">⊗</span> will remove one pinned message. The character can be included more than once to remove multiple pinned messages. Use <tt>\unpin</tt> followed by tab to get the <span role="img" aria-label="otimes">⊗</span> symbol.</li>
      </ul>
    </div>);
  }

  mousetrapUnset() {
    Mousetrap.unbind("down");
    Mousetrap.unbind("up");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("shift+s");
    Mousetrap.unbind("shift+r");
    Mousetrap.unbind("shift+=");
    Mousetrap.unbind("del");
    Mousetrap.unbind("backspace");
    Mousetrap.unbind("esc");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("shift+/");
    Mousetrap.unbind("mod+/");
    Mousetrap.unbind("ctrl+j");
  }

  convertEditorContents() {
    if (!this.mainQuillRef) return;
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);
    const editor = this.mainQuillRef.current.editor;
    const text = toPlaintext(editor.getContents());
    const ops = text.split("\n---\n").map(cellText => markdownToDelta(cellText).concat([{insert: {hr: true}}]));
    editor.setContents({ops: flatten(ops).slice(0, -1)});
    setTimeout( () => this.mainQuillRef.current.focus(), 50 );
  }

  subComments(props) {
    if (this.unsub.comments) this.unsub.comments();
    const { db, projectId, drillId } = props;
    this.commentRef = db
      .collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .collection('comments');
    this.unsub.comments = this.commentRef
      .where('archived', '==', false)
      .onSnapshot(snap => {
        if (!snap.docs) return;
        const docs = snap.docs.map(doc => doc.data());
        const comments = {};
        for (let doc of docs) {
           if (comments[doc.parentId]) {
             comments[doc.parentId].push(doc);
           } else {
             comments[doc.parentId] = [doc];
           }
        }
        this.setState({ comments });
      });
  }

  subDrill(props, lang) {
    if (this.unsub.drill) this.unsub.drill();
    const { db, currentUser, projectId, drillId } = props;
    if (!currentUser) return console.log('no current user', currentUser, props.currentUser);
    this.drillRef = db
      .collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId);
    this.unsub.drill = this.drillRef
      .onSnapshot(snap => {
        const drill = snap.data();
        if (!drill) return;
        const { hockets=[] } = drill;
        for (let i = 0; i < hockets.length; i++) {
          if (this.unsub[hockets[i].hocketId]) continue;
          this.subHocket(hockets[i].hocketId);
        }
        this.setState({ drill });
        this.setState({ codeCell: drill.codeCell || lang || null });
        this.setState({ published: drill.published || null});
      });
  }

  subHocket(hocketId) {
    const { db, projectId } = this.props;
    if (this.unsub[hocketId]) {
      this.unsub[hocketId]();
    }
    this.unsub[hocketId] = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocketId)
      .onSnapshot(snap => {
        const hocket = snap.data();
        if (!hocket) return null;
        const { hockets } = this.state;
        hockets[hocket.id] = hocket;
        this.setState({ hockets });
      });
  }

  componentWillUnmount() {
    for (let key in this.unsub) {
      if (this.unsub[key] && typeof this.unsub[key] === 'function') {
        this.unsub[key]();
      }
    }
    window.removeEventListener('copy', this.handleCopy);
    window.removeEventListener('paste', this.handlePaste);
    this.mousetrapUnset();
  }

  nameDrill(title='') {
    const { drill } = this.state;
    drill.title = title;
    this.setState({ drill });
  }

  describeDrill(description='') {
    const { drill } = this.state;
    drill.description = description;
    this.setState({ drill });
  }

  saveDrillDetails() {
    const { db, drillId, projectId } = this.props;
    const { drill } = this.state;
    const { title, description } = drill;
    if (!projectId) return;
    db.collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .set({ title, description },
           {merge:true})
      .then(() => this.toggleSettings())
      .catch(console.error);
  }

  setActiveHocketId(activeHocketId, dbUpdate=true) {
    const { db, projectId, currentUser, drillId } = this.props;
    this.setState({ activeHocketId,
                    hocketSelectionRange: null }, () => {
        if (this.mainQuillRef.current) {
          this.mainQuillRef.current.blur();
        }
    });
    // update this info on the database, so it can be reflected on the
    // classroom side if desired
    if (dbUpdate) {
    const selectedDrillId = drillId;
    const lastDrillMessageIndex = activeHocketId === null ? null : this.hocketIndex(activeHocketId);
      setTimeout(() => db.collection('projects')
        .doc(projectId)
        .collection('settings')
        .doc(currentUser.id)
        .set({ selectedDrillId, activeHocketId, lastDrillMessageIndex }, {merge: true}));
    }
  }

  setSelectionEnd(hocketId) {
    const { activeHocketId, drill } = this.state;
    const { hockets } = drill;
    const activeHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === activeHocketId
    );
    const givenHocketIndex = hockets.findIndex(
      hocket => hocket.hocketId === hocketId
    );
    if (activeHocketIndex > -1 && givenHocketIndex > -1)
    this.setState({ hocketSelectionRange: [activeHocketIndex, givenHocketIndex] });
  }

  exportDrill() {
    const { exportedDrill } = this.state;
    this.setState({ exportedDrill: !exportedDrill});
  }

  deleteDrill() {
    const { projectId, router } = this.props;
    let answer = window.confirm("Are you sure you want to delete the whole Drill?")
    if (answer) {
      this.drillRef.delete();
      router.history.push("/projects/" + projectId + "/drills");
    }
  }

  deleteMessages() {
    const {
      drill={},
      activeHocketId,
      hocketSelectionRange,
    } = this.state;
    const { hockets=[], publishedHockets } = drill;
    if (!activeHocketId) {
      console.log("No hocket selected");
      return null;
    }
    let range, answer, currentIndex;
    if (hocketSelectionRange) {
      answer = window.confirm("Are you sure you want to delete the selected messages?")
      range = [...hocketSelectionRange];
    } else {
      answer = window.confirm("Are you sure you want to delete the selected message?")
      currentIndex = hockets.findIndex(hocket => hocket.hocketId === activeHocketId);
      range = [currentIndex, currentIndex];
    }
    range = range.sort((a,b) => a-b);
    if (answer) {
      let answer2;
      let conflictHockets = [];
      if (publishedHockets) {
          conflictHockets = hockets.filter((hocket, i) => {
          const inRange = (range[0] <= i) && (i <= range[1]);
          const id = hocket.hocketId;
          const published = publishedHockets.map(stub => stub.hocketId).includes(id);
          return inRange && published && isRegistered(this.state.hockets[id]);
        });
        if (conflictHockets.length > 0) {
          answer2 = window.prompt(`You're attempting to delete ${conflictHockets.length} message${conflictHockets.length > 1 ? "s" : ""} which have been published and are marked as required. If you proceed, students might lose work. If you are sure, type the word delete`);
        }
      }
      if (answer2 === "delete" || conflictHockets.length === 0) {
        const remainingHockets = hockets.filter((hocket, i) => {
          return (i < range[0]) || (i > range[1]);
        });
        return this.drillRef.set(
            { hockets: remainingHockets },
            { merge: true }
        );
      }
    }
  }

  exportJupyterNotebook() {
    const { hockets, drill, codeCell='code' } = this.state;
    exportJupyterNotebook(
      drill.hockets.map(hocketStub => hockets[hocketStub.hocketId]),
      codeCell,
      drill.title && drill.title.length > 0 ? drill.title : "prismia",
    );
  }

  handleCopy(event) {
    const { locked, hocketSelectionRange } = this.state;
    if (hocketSelectionRange ||
          (locked && document.activeElement === this.hocketsAreaRef)) {
      const { json, html, md } = this.copyHockets();
      event.clipboardData.setData('application/json',
      json);
      event.clipboardData.setData('text/html', html);
      event.clipboardData.setData('text/plain', md);
      event.preventDefault();
    }
  }

  handlePaste(event) {
    const clipText = event.clipboardData.getData('application/json');
    if ((document.activeElement === this.hocketsAreaRef ||
         document.activeElement.tagName.toLowerCase() === 'body') && 
         !window.prismiaDrawingCanvasOpen) {
      this.pasteHockets(clipText);
      event.preventDefault();
    }
  }

  copyHockets(all = false) {
    const {
      activeHocketId,
      hockets,
      hocketSelectionRange,
      drill,
    } = this.state;
    const hocketIds = drill.hockets;
    const messagesToCopy = [];
    const jupyterCells = [];
    let start, stop;
    let html = "";
    let md = "";
    if (hocketSelectionRange || all) {
      if (all) {
        start = 0;
        stop = hocketIds.length - 1;
      } else {
        [start, stop] = [...hocketSelectionRange].sort((a,b) => a-b);
      }
      for (let i = start;
               i <= stop;
               i++) {
        const currentHocket = hockets[hocketIds[i].hocketId];
        if (currentHocket && currentHocket.responses.length > 0) {
          const message = currentHocket.responses[0];
          const suggestions = currentHocket.suggestions;
          const jessieCode = currentHocket.jessieCode;
          const aspectRatio = currentHocket.aspectRatio || "100%";
          const codeCell = currentHocket.codeCell;
          const tableData = currentHocket.tableData;
          const urlIFrame = currentHocket.urlIFrame;
          const heightIFrame = currentHocket.heightIFrame;
          messagesToCopy.push({ message, suggestions, tableData, jessieCode, aspectRatio, codeCell, urlIFrame, heightIFrame });

          const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
          html += converter.convert();

          const quillDelta = JSON.parse(message);
          const thisCellMarkdown = touchUpMarkdown(deltaToMarkdown(quillDelta.ops));
          md += thisCellMarkdown + "\n---\n\n";

          jupyterCells.push({
            cell_type: "markdown",
            metadata: {},
            source: [thisCellMarkdown],
          })
          if (codeCell) {
            jupyterCells.push({
              cell_type: "code",
              metadata: {},
              source: [extractCodeBlock(quillDelta)],
              execution_count: null,
              outputs: [],
            })
          }
        }
      }
      md = md.slice(0, md.length - 7); // 7 is the length of \n\n---\n\n
    } else {
      const activeHocket = hockets[activeHocketId];
      if (activeHocket && activeHocket.responses.length > 0) {
        const message = activeHocket.responses[0];
        const suggestions = activeHocket.suggestions;
        const jessieCode = activeHocket.jessieCode || '';
        const aspectRatio = activeHocket.aspectRatio || "100%";
        messagesToCopy.push({ message, suggestions, jessieCode, aspectRatio });

        const converter = new QuillDeltaToHtmlConverter(JSON.parse(message).ops, {});
        html += converter.convert();

        md += touchUpMarkdown(deltaToMarkdown(JSON.parse(message).ops)) + "\n\n";
      }
    }
    const json = JSON.stringify(messagesToCopy);
    const ipynb = JSON.stringify({
      metadata: {},
      nbformat: 4,
      nbformat_minor: 0,
      cells: jupyterCells,
    });
    return {ipynb, json, html, md};
  }

  pasteHockets(clipText) {
    const { db, projectId } = this.props;
    const { drill={}, activeHocketId } = this.state;
    const { hockets=[] } = drill;
    let messages;
    try {
      console.log(clipText);
      messages = JSON.parse(clipText);
      console.log(messages);
    } catch (err) {
      console.error("Only messages from other Prismia drills can be pasted");
      return
    }
    messages
      .forEach( (message, loopCounter) => {
        const hocket = Hocket();
        if (!('message' in message) ||
            !isValidDelta(message.message) ||
            !('suggestions' in message)) {
          console.error("Only messages from other Prismia drills can be pasted");
          return;
        }
        hocket.responses.push(message.message);
        hocket.suggestions = message.suggestions;
        hocket.jessieCode = message.jessieCode || '';
        hocket.aspectRatio = message.aspectRatio || '';
        hocket.codeCell = message.codeCell || false;
        hocket.tableData = message.tableData || {string: ''};
        hocket.urlIFrame = message.urlIFrame || '';
        hocket.heightIFrame = message.heightIFrame || 0;
        const hocketStub = {
          hocketId: hocket.id,
        };
        const idx = (activeHocketId ?
            hockets.findIndex(
              hocket => hocket.hocketId === activeHocketId
            ) : hockets.length - 1);
        hockets.splice(idx + loopCounter + 1, 0, hocketStub);
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocket.id)
          .set(hocket, {merge: true})
          .catch(console.error);
      });
      this.drillRef
        .set({ hockets }, { merge: true });
    }

  createHocket() {
    const { db, projectId } = this.props;
    const { drill={}, activeHocketId } = this.state;
    const { hockets=[] } = drill;
    const hocket = Hocket();
    const hocketStub = {
      hocketId: hocket.id,
    };
    const idx = (activeHocketId ?
        hockets.findIndex(
          hocket => hocket.hocketId === activeHocketId
        ) : hockets.length - 1);
    hockets.splice(idx + 1, 0, hocketStub);
    db.collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(hocket.id)
      .set(hocket, {merge: true})
      .then(() => {
        this.setState({ activeHocketId: hocket.id });
        return this.drillRef
          .set({ hockets }, { merge: true });
      }).catch(console.error);
    this.pingBinderKernel();
  }

  onDragEnd(res) {
    const { db, projectId, drillId } = this.props;
    const { destination, source } = res;
    if (!destination || !source) return;
    const { drill={} } = this.state;
    const { hockets=[] } = drill;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const hocketStub = hockets.splice(sourceIndex, 1)[0];
    hockets.splice(destinationIndex, 0, hocketStub);
    drill.hockets = hockets;
    this.setState({ drill });
    manuallySortDrillMessage();
    db
      .collection('projects')
      .doc(projectId)
      .collection('drills')
      .doc(drillId)
      .set(drill, {merge:true});
  }

  handleScroll(event) {
    if (this.state.manualScroll) return;
    const { messageLimit } = this.state;
    const { drill={} } = this.state;
    const { hockets=[] } = drill;
    const node = event.target;
    const scrollFractionTop = node.scrollTop / node.scrollHeight;
    const scrollFractionBottom = (node.scrollTop + node.clientHeight) / node.scrollHeight
    if (scrollFractionBottom > 0.85) {
      this.setState({ messageLimit: Math.min(
        hockets.length,
        messageLimit + MESSAGE_RENDER_STEP 
      )});
    } else if (scrollFractionTop < 0.15) {
      this.setState({ messageLimit: Math.max(MESSAGE_RENDER_MAX, messageLimit - MESSAGE_RENDER_STEP) });
    }
  }

  paneDidMount(node) {
    if (node && !this.state.manualScroll) {
      this.unsub.scrollListener = () => {
        node.removeEventListener("scroll", this.handleScroll);
      }
      node.addEventListener("scroll", this.handleScroll);
    }
  }

  approveProposal(authorId, problemId) {
    const  {db, projectId} = this.props;
    const { drill, proposalHockets, proposalAuthors } = this.state;
    if (!proposalHockets) return;
    const { statementHockets=[], solutionHockets=[] } = proposalAuthors[authorId].drillProblems[problemId];
    if (!statementHockets || !solutionHockets) return null;
    const hocketIds = statementHockets.concat(solutionHockets);
    const batch = db.batch();
    for (let hocketId of hocketIds) {
      batch.set(
        db.collection('projects')
          .doc(projectId)
          .collection('hockets')
          .doc(hocketId), 
        proposalHockets[hocketId], {merge: true}
      );
    }
    let { hockets } = drill;
    batch.set(this.drillRef, { hockets: hockets.concat(
      hocketIds.map(hocketId => ({ hocketId }))
    ) }, { merge: true });
    batch.commit().then( () => {
      this.markProcessed(authorId, problemId);
    });
  }

  markProcessed(authorId, problemId) {
    this.proposalRef.doc(authorId).set(
      { processed: arrayUnion(problemId) }, { merge: true }
    ).catch(console.error);
  }

  renderAuthorProblem(authorId, problemId) {
    const { proposalHockets, proposalAuthors, processed } = this.state;
    if (!proposalHockets) return null;
    if (processed.has(problemId)) return null;
    const { statementHockets=[], solutionHockets=[] } = proposalAuthors[authorId].drillProblems[problemId];
    if (!statementHockets || !solutionHockets) return null;
    const hocketIds = statementHockets.concat(solutionHockets);
    const checkIcon = <IconButton
        size="small"
        style={{
          position: 'absolute', 
          top: "-10px", 
          right: "-34px",
        }}
        onClick={ () => this.approveProposal(authorId, problemId) }>
      <CheckIcon
        style={{ 
          color: '#AAA',
          cursor: 'pointer',
        }}/>
      </IconButton>;
    const cancelIcon = <IconButton
        size="small"
        style={{
          position: 'absolute', 
          top: "18px", 
          right: "-34px",
        }}
        onClick={ () => this.markProcessed(authorId, problemId) }>
        <HighlightOffIcon 
          style={{ 
            color: '#AAA',
            cursor: 'pointer',
          }}/>
      </IconButton>;
    return <div key={ authorId + problemId } style={{position: 'relative'}}>
      { checkIcon }
      { cancelIcon }
      { hocketIds.map( hocketId => {
        const hocket = proposalHockets[hocketId];
        if (!hocket) return null;
        return <div key={ hocketId } className="assignment-card">
          <CardContents
            db={ this.props.db } 
            projectId={ this.props.projectId } 
            codeCell={ this.state.codeCell }
            hocket={ hocket }
            setLang={(lang) => this.setLang(lang)}/>
        </div>;
      }) }
    </div>;
  }

  renderAuthorProposals(authorId) {
    const { proposalAuthors } = this.state;
    return <div key={authorId}>
      { Object.keys(proposalAuthors[authorId]?.drillProblems || {}).map((problemId) => this.renderAuthorProblem(authorId, problemId)) }
    </div>;
  }

  renderProposalArea(width) {
    const { proposalAuthors } = this.state;
    if (!proposalAuthors || Object.keys(proposalAuthors).length === 0) {
      return null;
    }
    return <div className="hockets-area">
      <h2 className="centered">Proposals</h2>
      { Object.keys(proposalAuthors).map( 
        (authorId) => this.renderAuthorProposals(authorId) 
      ) }
    </div>;
  }

  renderDrillArea(width) {
    const { drill={} } = this.state;
    const classNames = width < mobileThreshold ? "border-top" : "";
    if (!drill) return null;
    const { title='', description='' } = drill;
    return (
      <div
        ref={ node => {
            this.messagesAreaRef = node;
            this.paneDidMount(node);
        }}
        className={"y-scrollable x-contained " + classNames}>
        <h1
          className="assignment-title centered"
          onClick={() => this.toggleSettings()}>
          { title || '' }
        </h1>
        <div
          className="centered description-text"
          onClick={() => this.toggleSettings()}>
          { description || '' }
        </div>
        { this.renderHocketsArea(width) }
        { this.renderProposalArea(width) }
      </div>
    );
  }

  isInSelectionRange(hocketId) {
    const { drill, hocketSelectionRange } = this.state;
    if (!hocketSelectionRange) return false;
    const { hockets=[] } = drill;
    const currentIndex = hockets.findIndex(hocket => hocket.hocketId === hocketId);
    const [start, stop] = [...hocketSelectionRange].sort((a,b)=>a-b);
    return ((start <= currentIndex) && (currentIndex <= stop));
  }

  setActiveCommentHocket(id) {
    this.setState({ activeCommentHocket: id })
  }

  renderHocketCard(hocket, index, width) {
    if (!hocket) { return null };
    const { db, currentUser } = this.props;
    const { activeHocketId, comments, showComments, activeCommentHocket } = this.state;
    const style = {background: '#fff'};
    if (this.state.reOrder && hocket.id === activeHocketId) {
      style.background = 'rgb(169, 216, 225)'; // light teal
    }
    if (this.isInSelectionRange(hocket.id)) {
      style.background = '#fff3a8' // light yellow
    }
    const commentsArea = showComments ? <CommentsArea
      db={ db }
      width={ width }
      currentUser={ currentUser }
      comments={ comments[hocket.id] }
      parentId={ hocket.id }
      commentRef={ this.commentRef }
      activeCommentHocket={ activeCommentHocket }
      setActiveCommentHocket={ (id) => this.setActiveCommentHocket(id) }
      /> : null;
    const card = (<div
      className="assignment-card"
      key={ hocket.id }
      style={ style }
      onClick={ (e) => {
          if (e.shiftKey) {
            if (activeHocketId) {
              this.setSelectionEnd(hocket.id);
            } else {
              this.setActiveHocketId(hocket.id);
            }
          } else {
            this.setActiveHocketId(hocket.id);
          }
      } }>
      { commentsArea }
      <CardContents 
          db={ this.props.db } 
          projectId={ this.props.projectId } 
          codeCell={ this.state.codeCell }
          hocket={ hocket }
          showIndex
          setLang={(lang) => this.setLang(lang)}/>
    </div>);
    if (!this.state.locked && !this.state.reOrder && activeHocketId === hocket.id) {
      return <div
        style={ style }
        key={ hocket.id }
        className={ 'message-form-card' }>
          { commentsArea }
          { this.renderHocketForm() }
        </div>;
    }
    if (!this.state.reOrder) return card;
    return (
      <Draggable
        draggableId={ hocket.id }
        index={ index }
        key={ hocket.id }>
      {(provided, snapshot) => {
        return (
            <div
              ref={ provided.innerRef }
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="assignment-card"
              key={ hocket.id }
              style={ getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                style
              )}
              onClick={ (e) => {
                  if (this.hocketsAreaRef) {
                    this.hocketsAreaRef.focus()
                  }
                  if (e.shiftKey) {
                    this.setSelectionEnd(hocket.id);
                  } else {
                    this.setActiveHocketId(hocket.id);
                  }
              } }>
              <CardContents 
                db={ this.props.db } 
                projectId={ this.props.projectId } 
                hocket={ hocket }
                codeCell={ this.state.codeCell }
                showIndex
                setLang={(lang) => this.setLang(lang)}/>
            </div>
        );
      }}
      </Draggable>
    );
  }

  setLang(lang) {
    this.setState({ codeCell: lang });
  }

  renderSuggestions(suggestions) {
    return <div className="padded-sides"><SuggestionSuperList
      includeSuggestionReplies
      hidePlaceholder={ true }
      label="Suggestion"
      showHocketField={ false }
      readOnly={ true }
      hideLast={ true }
      limit={ 15 }
      projectId={ this.props.projectId }
      db={ this.props.db }
      values={ suggestions }
      updateValues={ (suggestions) => null }/></div>;
  }

  renderHocketForm() {
    const { db, storage, projectId, currentUser } = this.props;
    const { activeHocketId, codeCell } = this.state;
    if (!activeHocketId) return <></>;
    const hocketRef = db
      .collection('projects')
      .doc(projectId)
      .collection('hockets')
      .doc(activeHocketId);
    return (
      <div
        className="message-form-container"
        key={ activeHocketId }>
        <MessageForm
          db={ db }
          currentUser={ currentUser }
          storage={ storage }
          projectId={ projectId }
          hocketId={ activeHocketId }
          clearActiveHocketId={ () => this.setActiveHocketId(null) }
          deleteMessages={ this.deleteMessages }
          mainQuillRef={ this.mainQuillRef }
          hideSuggestedMessages
          hideOpenResponse
          toggleJuniper={ () => this.toggleJuniper() }
          splitActiveCell={ () => this.splitActiveCell() }
          createHocket={ () => this.createHocket() }
          codeCell={ codeCell }
          setMousetrap={ () => this.setMousetrap() }
          saveMessage={ saveDrillMessage }
          hocketRef={ hocketRef }/>
      </div>
    );
  }

  toggleJuniper() {
    const { juniperOpen } = this.state;
    this.setState({ juniperOpen: !juniperOpen }, () => {
      //if (juniperOpen) this.juniperRef.current.codeMirror.focus();
    });
  }

  renderJuniper() {
    const { juniperOpen, juniperHasRendered, codeCell } = this.state;
    const style = {};
    if (!juniperOpen && !juniperHasRendered) return null;
    if (!juniperHasRendered) this.setState({ juniperHasRendered: true });
    if (!juniperOpen) style.display = "none";
    return <div className="juniper-container" style={ style }>
      <JupyterCell
        ref={ this.juniperRef }
        language={ codeCell }/>
    </div>;
  }

  drillHockets() {
    const { drill={}, hockets={} } = this.state;
    let hocketStubs = drill.hockets;
    return hocketStubs.map(stub => hockets[stub.hocketId]);
  }

  collectProblems() {
    const { drill={}, hockets={} } = this.state;
    let hocketStubs = drill.hockets;
    const problems = {};
    let latestId, messagesSinceNoteOrRegistered = 1;
    for (let i = 0; i < hocketStubs.length; i++) {
      const id = hocketStubs[i].hocketId;
      const hocket = hockets[id];
      if (isRegistered(hocket)) {
        latestId = id
        problems[id] = {};
        problems[id]['statementHockets'] = hocketStubs.slice(
          i + 1 - messagesSinceNoteOrRegistered, 
          i + 1,
        ).map(stub => stub.hocketId);
      }
      if (isNote(hocket) && latestId) {
        if (!problems[latestId].solutionHockets) problems[latestId].solutionHockets = [];
        problems[latestId].solutionHockets.push(hocketStubs[i].hocketId);
      }
      if (isNote(hocket) || isRegistered(hocket)) {
        messagesSinceNoteOrRegistered = 1;
      } else {
        messagesSinceNoteOrRegistered += 1;
      }
    }
    return problems;
  }

  renderHocketsArea(width) {
    const { drill={}, hockets={}, messageLimit } = this.state;
    let hocketStubs = drill.hockets;
    const numMessages = hocketStubs.length;
    const hocketCards = [];
    let idx = 0;
    let numExtraCards = 0;
    let numExtraHiddenCards = 0;
    let start = Math.max(0, messageLimit - MESSAGE_RENDER_MAX);
    let messagesSinceNoteOrRegistered = 1;
    for (let i = 0; i < hocketStubs.length; i++) {
      const hocket = hockets[hocketStubs[i].hocketId];
      hocketCards.push(this.renderHocketCard(hocket, i, width));
      if (isRegistered(hocket)) {
        idx++;
        numExtraCards++;
        if (i < start) numExtraHiddenCards++;
        hocketCards.splice(-messagesSinceNoteOrRegistered, 0,
          <h3 className="centered" style={{color: "gray"}} key={`problem-title-${idx}`}>
            {"Problem " + idx}
          </h3>
        );
      }
      if (isNote(hocket) || isRegistered(hocket)) {
        messagesSinceNoteOrRegistered = 1;
      } else {
        messagesSinceNoteOrRegistered += 1;
      }
    }
    start += numExtraHiddenCards;
    const stop = Math.min(messageLimit, hocketCards.length) + numExtraCards;
    return (
      <div
        className="hockets-area"
        tabIndex={ -1 }
        ref={ (node) => {
          if (node) {
            this.hocketsAreaRef = node;
          }
        }}>
          {this.state.manualScroll && messageLimit > MESSAGE_RENDER_MAX ? <Tooltip
          title="Show earlier messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px",
              marginTop: "30px",
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.max(MESSAGE_RENDER_MAX, messageLimit - MESSAGE_RENDER_STEP)
              });
            }}
            >
            Earlier Messages
          </Button>
        </Tooltip> : null }
        <DragDropContext
          onDragEnd={res => this.onDragEnd(res)}>
          <Droppable
            droppableId={'list'}
            key={ 'droppable' }>
          { (provided, snapshot) => (
            <div
              className="pad-bottom"
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.innerProps}
                >
            { hocketCards.slice(start, stop) }
            { provided.placeholder }
            </div>
          )}
          </Droppable>
        </DragDropContext>
        {this.state.manualScroll && messageLimit < numMessages ? <Tooltip
          title="Show more messages"
          enterDelay={ 500 }>
          <Button
            variant="contained"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px"
            }}
            onClick={() => {
              this.setState({
                messageLimit: Math.min(numMessages, messageLimit + MESSAGE_RENDER_STEP)
              });
            }}
            >
            More Messages...
          </Button>
        </Tooltip> : null }
        <Tooltip
          title="Add a new message after the currently selected message [shift +]"
          enterDelay={ 500 }>
          <Button
            fullWidth
            variant="outlined"
            className="add-hocket-button"
            onClick={() => this.createHocket()}
            >
            +
          </Button>
        </Tooltip>
      </div>
    );
  }

  render() {
    updateTitleBar('Drills');
    const { db, router, projectId, currentUser={} } = this.props;
    const { showHelp, showSettings, codeCell, reOrder } = this.state;
    const classes = showHelp || showSettings ? "full-height blur" : "full-height";
    const tools = [{
      icon: <HelpOutlineOutlinedIcon/>,
      onClick: () => this.setState({ showHelp : true }),
      tooltipTitle: "Open help screen",
      disabled: false,
      hide: false,
    }, {
      icon: <SettingsIcon/>,
      onClick: () => this.setState({ showSettings: true }),
      tooltipTitle: "Settings",
      disabled: false,
      hide: false,
    }, {
      icon: reOrder ? <ReorderIcon style={{color: "orange"}}/> : <ReorderIcon/>,
      onClick: () => this.setState({ reOrder: !reOrder }),
      tooltipTitle: reOrder ? "Done reordering [shift r]" : "Reorder messages [shift r]",
      disabled: false,
      hide: false,
    }, {
      icon: <CodeIcon/>,
      onClick: () => this.toggleJuniper(),
      tooltipTitle: "Toggle code cell",
      disabled: false,
      hide: !codeCell,
    }, {
      icon: this.state.locked ? <LockOutlinedIcon/> : <LockOpenOutlinedIcon/>,
      onClick: () => this.setState({locked: !this.state.locked}),
      tooltipTitle: this.state.locked ? "Unlock messages" : "Lock messages",
      disabled: false,
      hide: !this.state.locked,
    }];
    const navHeight = (width) => 62 + (width < mobileThreshold ? 48 : 0);
    const table = width => (<div className={classes}>
        <SimpleAdminNav currentUser={ currentUser } projectId={ projectId } db={ db } router={ router } />
        <div className="flow-root">
          <SidebarButtonPanel
            mobile={ width < mobileThreshold }
            tools={ tools }/>
        </div>
          <div style={{position: "relative", height: `calc(100% - ${navHeight(width)}px)`}}>
          { this.renderDrillArea(width) }
          { this.renderJuniper() }
        </div>
      </div>
    );
    const maskCover = (showHelp || showSettings) ? <div className="masking-cover" onClick={ () => this.exitHelpOrSettings() }></div> : null;
    const helpCard = showHelp ? this.helpInfo() : null;
    const settingsCard = showSettings ? this.renderSettings() : null;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        { ({ width }) => {
          return <div className="assignment-creation-view">
            { maskCover }
            { helpCard }
            { settingsCard }
            { table(width) }
            <NotificationContainer/>
          </div>;
        }}
      </ReactResizeDetector>
    );
  }

}

export default DrillCreationView;
