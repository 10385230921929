import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const allSlides = [
  [
    "/carousel-images/carousel-1.png",
    <>
      Click the <em>Create a New Course</em> button.
    </>,
    [106.5, 6.5, 25, 5.5]
  ],
  [
    "/carousel-images/carousel-2.png",
    <>
      Select a login option.
    </>,
    [50, 52, 42, 20]
  ],
  [
    "/carousel-images/carousel-3.png",
    <>
      Enter a course title and description and save.
    </>,
    [16, 61, 33, 6]
  ],
  [
    "/carousel-images/carousel-3.png",
    <>
      Copy your course join link and share it with your students.
    </>,
    [15.5, 88.5, 78.5, 6]
  ],
  [
    "/carousel-images/carousel-4.png",
    <>
      Add a welcome message for your students.
    </>,
    [12, 6, 118, 67]
  ],
  [
    "/carousel-images/carousel-3.png",
    <>
      Click the <em>Go To Your Classroom</em> button.
    </>,
    [45, 20.75, 52, 10.5]
  ],
  [
    "/carousel-images/carousel-5a.png",
    <>
      Enter text in the <em>Message the class</em> box. Click the <em>Send</em> button or press the <em>Enter</em> key to send.
    </>,
    [0.5, 12, 35, 14]
  ],
  [
    "/carousel-images/carousel-5b.png",
    <>
      Use the slider to change the number of columns.
    </>,
    [37, 31.5, 104, 3]
  ],
  [
    "/carousel-images/carousel-5b.png",
    <>
      Click the <em>Re-cluster</em> button to arrange incoming messages into columns.
    </>,
    [38, 25.25, 17.25, 7]
  ],
  [
    "/carousel-images/carousel-7.png",
    <>
      Hover over a message to share it with the class, star it, or clear it.
    </>,
    [58.25, 33.5, 20, 19]
  ],
  [
    "/carousel-images/carousel-8.png",
    <>
      Provide suggested responses to accompany your messages.
    </>,
    [0.5, 35, 35, 56.5]
  ],
  [
    "/carousel-images/carousel-6.png",
    <>
      Visit the chat page for the student perspective.
    </>,
    [30.25, 6, 7, 5]
  ],
  [
    "/carousel-images/carousel-9.png",
    <>
      Students see other students' messages
    </>,
    [71, 48, 67, 10]
  ],
  [
    "/carousel-images/carousel-9.png",
    <>
      ...and suggestion chips.
    </>,
    [71, 70, 67, 16]
  ],
  [
    "/carousel-images/code-cells-and-graphics.png",
    <>
      Messages can include interactive graphics and executable code cells.
    </>,
    [71, 18, 70, 71]
  ],
  [
    "/carousel-images/carousel-9.png",
    <>
      Use the student menu to get a transcript, drop a course, or change your profile data.
    </>,
    [1.75, 6.5, 6, 6]
  ],
  [
    "/carousel-images/carousel-10.png",
    <>
      Use the text box at the bottom of a column to send a message to every student in that column.
    </>,
    [38, 72, 25, 23]
  ],
  [
    "/carousel-images/carousel-11.png",
    <>
      Click the <em>Add to Existing Columns</em> button to put new messages into existing columns.
    </>,
    [113, 40.5, 27, 7]
  ],
  [
    "/carousel-images/carousel-12.png",
    <>
      Click the arrow icon to re-send a message to the new students in that column.
    </>,
    [56.1, 66, 7, 7]
  ],
  [
    "/carousel-images/carousel-14.png",
    <>
      Students see their starred messages
    </>,
    [73, 46, 7, 7]
  ],
  [
    "/carousel-images/carousel-12.png",
    <>
      Check numbers of messages in each column and the number of students still typing.
    </>,
    [54, 21.5, 62.75, 6.6]
  ],
  [
    "/carousel-images/carousel-8.png",
    <>
      Start a new cluster block.
    </>,
    [36.45, 13.48, 104, 6]
  ],
  [
    "/carousel-images/carousel-8b.png",
    <>
      Dashed borders indicate another instructor's answer in progress. <br/> Faded messages have received responses.
    </>,
    [58, 38, 20, 15.25]
  ],
  [
    "/carousel-images/carousel-15.png",
    <>
      Click on a message or participant card to open a private chat.
    </>,
    [0, 50.5, 36, 50]
  ],
  [
    "/carousel-images/carousel-lesson.png",
    <>
      Prepare messages in advance using <em>Lessons</em>.
    </>,
    [46.25, 6.25, 10.7, 5.75]
  ],
  [
    "/carousel-images/carousel-shared.png",
    <>
      Publicly share your lessons.
    </>,
    [0, 0, 144, 100]
  ],
  [
    "/carousel-images/carousel-20.png",
    <>
      Export lessons by copying messages and pasting them into a plain text editor.
    </>,
    [52, 38, 38, 16]
  ],
  [
    "/carousel-images/carousel-17.png",
    <>
      Retrieve them by opening the classroom Lessons drawer.
    </>,
    [21.4, 37, 14.25, 7.45]
  ],
  [
    "/carousel-images/carousel-18.png",
    <>
      View a class roster.
    </>,
    [60.75, 5.5, 13, 5.75]
  ],
  [
    "/carousel-images/carousel-19.png",
    <>
      Export class statistics.
    </>,
    [72.7, 5.5, 11, 5.75]
  ],
]

const shortSlides = [6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 22, 23, 24, 27].map(i => allSlides[i]);

function renderCarouselSlide(triple, i) {
  const [image, caption, [x, y, w, h]] = triple;
  return (
    <React.Fragment
      key={ i }>
      <div
        className="slide-container">
        <img
          alt="teacher chat ui"
          src={ image }/>
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 142 100"
             className="image-overlay">
           <defs>
             <marker id="arrowhead"
                     markerWidth="10" markerHeight="7"
                     refX="0"
                     refY="3.5"
                     orient="auto">
             <polygon
               fill="#777"
               points="0 0, 10 3.5, 0 7"
              />
             </marker>
           </defs>
          <path
            opacity="0.8"
            fill="#bbb"
            stroke="#8694e4"
            strokeWidth=".7"
            d={ `M 0 0 h 142 v 100 h -142 L 0 0 Z M ${x} ${y} v ${h} h ${w} v -${h} L ${x} ${y} Z` }/>
        </svg>
      </div>
      <p
        className="legend">
        { caption }
      </p>
    </React.Fragment>
  );
}

function renderCarousel(slideTriples, lastSlide) {
  return (
    lastSlide?
    <Carousel
      key={ 101 }
      showStatus={ false }
      showThumbs={ false }
      showArrows={ window.document.body.clientWidth > 500 }
      showIndicators={ window.document.body.clientWidth > 500 }
      useKeyboardArrows>
      { slideTriples.map(
          (t, i) => renderCarouselSlide(t, i)
      )}
      { lastSlide }
    </Carousel> :
    <Carousel
      key={ 101 }
      showStatus={ false }
      showThumbs={ false }
      showArrows={ window.document.body.clientWidth > 500 }
      showIndicators={ window.document.body.clientWidth > 500 }
      swipeScrollTolerance={ 50 }
      useKeyboardArrows>
      { slideTriples.map(
          (t, i) => renderCarouselSlide(t, i)
      )}
    </Carousel>
  );
}

export {
  allSlides,
  shortSlides,
  renderCarousel,
};
